import React from 'react'

import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { API_URL, authHeader } from '../../../services/service.api'
import { DATEFORMAT } from '../../../services/service.values'

import { types, flow } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import InfiniteLoader from 'react-window-infinite-loader'
import moment from 'moment-timezone'
import NumberFormat from 'react-number-format'

import fallbackColumns from './c.fx.exchange.columns.json'
import fallbackFeatureFlags from './c.fx.exchange.feature_flags.json'
import fallbackCountries from './util.countries.json'
import fallbackTranslations from './c.fx.translations.en.json'
import Preloader from '../../components/preloader/Preloader'

import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import RefreshIcon from '@material-ui/icons/Refresh'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SearchIcon from '@material-ui/icons/Search'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { viewExchangePermission } from '../../../services/service.permission'

const renderPrice = ({ price, currency, bestPrice }) =>
  bestPrice ? (
    'Best Price'
  ) : (
    <NumberFormat
      value={price}
      decimalScale={2}
      fixedDecimalScale
      displayType={'text'}
      thousandSeparator={true}
      prefix={currency === 'USD' || currency === 'US$' ? 'USD ' : 'EUR '}
    />
  )

const renderInteger = (value) => (
  <NumberFormat
    value={value}
    decimalScale={0}
    fixedDecimalScale
    displayType={'text'}
    thousandSeparator={true}
  />
)

window.moment = moment

const Feature = types.model({
  name: types.identifier,
  enabled: types.boolean,
})

const Store = types
  .model({
    showModal: false,
    features: types.map(Feature),
    loading: false,
    interest: '',
    bookmark: '',
    pagesize: 50,
    tendersOnly: false,
    expiryOnly: false,
    statusFilter: 'NEW',
    fertilizerTypeFilter: '',
    sortColumn: '',
    sortDirection: 1,
    language: 'en',
    width: window.innerWidth,
    height: window.innerHeight,
  })
  .views((_) => ({
    get data() {
      // remove duplicates
      let data = [...new Map(_.rawData.map((item) => [item.id, item])).values()]

      if (_.tendersOnly) {
        data = data.filter((d) => d.privacyType === 'TENDER')
      }

      if (_.expiryOnly) {
        // filter on not yet expired
        data = data.filter(({ validateDate }) =>
          moment(new Date()).isBefore(validateDate)
        )
        // filter on expiring in 12 hours or less
        data = data.filter(({ validateDate }) =>
          moment(new Date())
            .add(12, 'h')
            .isAfter(validateDate)
        )
      }

      if (_.statusFilter.length) {
        data = data.filter((d) => d.status === _.statusFilter)
      }

      if (_.fertilizerTypeFilter.length) {
        data = data.filter((d) => d.fertilizerType === _.fertilizerTypeFilter)
      }

      if (_.sortEnabled) {
        if (_.sortDirection > 0) {
          data = [
            ...data.sort((a, b) =>
              a[_.sortColumn] > b[_.sortColumn] ? 1 : -1
            ),
          ]
        } else {
          data = [
            ...data.sort((a, b) =>
              a[_.sortColumn] > b[_.sortColumn] ? -1 : 1
            ),
          ]
        }
      }

      return data
    },
    get tableWidth() {
      return Math.max(_.width, 1280)
    },
    get sortEnabled() {
      return _.sortColumn.length && _.sortDirection !== 0
    },
    get statusList() {
      return ['', ...[...new Set(store.rawData.flatMap((e) => e.status))]]
    },
    get fertilizerTypeList() {
      return [
        '',
        ...[
          ...new Set(
            store.rawData
              .filter((d) => d.status.includes(_.statusFilter))
              .map((d) => d.fertilizerType)
          ),
        ],
      ].sort()
    },
    get columns() {
      if (!_.rawColumns) return []
      let re = [..._.rawColumns]

      // remove the inverse of current tab
      if (_.interest === 'sell') re = re.filter((c) => c.field !== 'buyer')
      if (_.interest === 'buy') re = re.filter((c) => c.field !== 'seller')

      return re
    },

    get colWidth() {
      // pixel rounded width of columns
      return (
        (_.tableWidth - (_.tableWidth % _.columns.length)) /
        (_.columns.length + 1)
      )
    },
  }))
  .volatile((_) => ({
    rawData: [],
    rawColumns: [],
    translations: [],
    countries: {},
  }))
  .actions((_) => ({
    afterCreate() {
      _.fetchColumnConfig()
      _.fetchTranslations()
      _.fetchFeatureFlags()
      _.fetchCountries()
      _.setInterest(localStorage.getItem('interest') ?? 'buy')
    },

    addFeature(name, enabled = true) {
      _.features.put({ name, enabled })
    },

    addFeatures(data) {
      return Object.entries(data).forEach(([name, enabled]) =>
        _.addFeature(name, enabled)
      )
    },

    toggleFeature(name) {
      return (_.features.get(name).enabled = !_.features.get(name).enabled)
    },

    setSortColumn(value) {
      // if clicking again on the sorted column
      if (_.sortColumn === value) {
        // change direction on second click; turn off on third
        _.sortDirection = _.sortDirection === 1 ? -1 : _.sortDirection + 1
      } else {
        // if new column, reset direction
        _.sortDirection = 1
      }

      _.sortColumn = value
    },

    set(field, value) {
      if (field === 'sortColumn') return _.setSortColumn(value)
      if (field === 'interest') return _.setInterest(value)

      _[field] = value

      if (field === 'language') {
        // load new language
        _.fetchTranslations()
      }
    },

    translate(value) {
      return _.translations[value] ?? value
    },

    country(value) {
      return value
        .split(',')
        .map((countryCode) => store.countries[countryCode] ?? value)
        .join(', ')
    },

    port(index) {
      const { incoterm, destCountry } = _.data[index]

      if (incoterm === 'FOB') return '-'

      return destCountry ? `${_.country(destCountry)}` : '-'
    },

    toggleTenders() {
      _.tendersOnly = !_.tendersOnly
    },

    toggleExpiry() {
      _.expiryOnly = !_.expiryOnly
    },

    setInterest(value) {
      localStorage.setItem('interest', value)
      _.interest = value
      _.rawData = []
      _.fertilizerTypeFilter = ''
      _.sortColumn = ''
      _.fetchData()
    },

    fetchColumnConfig() {
      flow(function*() {
        // try {
        //   const re = yield fetch(
        //     'https://cdb2063.redledger.eu/channel1_lcx/c.fx.exchange.columns'
        //   )
        //   const { data } = yield re.json()
        //   _.rawColumns = data ?? fallbackColumns
        // } catch (error) {
        _.rawColumns = fallbackColumns
        // }
      })()
    },

    fetchFeatureFlags() {
      flow(function*() {
        // try {
        //   const re = yield fetch(
        //     'https://cdb2063.redledger.eu/channel1_lcx/c.fx.exchange.feature_flags'
        //   )
        //   const { data } = yield re.json()
        //   _.addFeatures(data ?? fallbackFeatureFlags)
        // } catch (error) {
        _.addFeatures(fallbackFeatureFlags)
        // }
      })()
    },

    fetchCountries() {
      flow(function*() {
        // try {
        //   const re = yield fetch(
        //     'https://cdb2063.redledger.eu/channel1_lcx/util.countries'
        //   )
        //   const { data } = yield re.json()
        //   _.countries = data
        // } catch (error) {
        _.countries = fallbackCountries
        // }
      })()
    },

    fetchTranslations() {
      flow(function*() {
        // const re = yield fetch(
        //   'https://cdb2063.redledger.eu/channel1_lcx/c.rx.translations.' +
        //     _.language
        // )
        // const { data } = yield re.json()
        // _.translations = data
        _.translations = fallbackTranslations
      })()
    },

    fetchMore() {
      flow(function*() {
        if (_.loading) return

        _.loading = true
        const re = yield fetch(
          `${API_URL}/trade/pagination?pageSize=${_.pagesize}&requestType=${_.interest}&privacy=public&bookMark=${_.bookmark}`,
          authHeader()
        )
        const data = yield re.json()
        _.rawData = [..._.rawData, ...data.items]
        _.bookmark = data.bookMark
        _.loading = false
        return _.rawData
      })()
    },

    fetchData() {
      flow(function*() {
        if (_.loading) return
        _.loading = true

        let data
        let re

        try {
          re = yield fetch(
            `${API_URL}/trade/pagination?pageSize=${_.pagesize}&requestType=${_.interest}&privacy=public`,
            authHeader()
          )
          data = yield re.json()
        } catch (error) {
          _.loading = false
          return []
        }

        _.rawData = [..._.rawData, ...data.items]
        _.bookmark = data.bookMark
        _.loading = false
        return _.rawData
      })()
    },
  }))

const store = Store.create()

window.store = store

window.addEventListener('resize', (e) => {
  store.set('width', window.innerWidth)
  store.set('height', window.innerHeight)
})

const buy = () => store.set('interest', 'buy')
const sell = () => store.set('interest', 'sell')

const inactiveStyle = {
  display: 'table-cell',
  lineHeight: '1em',
  verticalAlign: 'middle',
  height: '45px',
  fontSize: store.width < 425 ? '1.2em' : '1.4em',
  border: 0,
  width: '35%',
  fontWeight: 'bold',
  color: '#BBB',
  backgroundColor: '#EEE',
}

const activeStyle = {
  ...inactiveStyle,
  color: 'black',
  backgroundColor: 'white',
}

const refreshSpanStyle = {
  display: 'inline-block',
  width: '30%',
  textAlign: 'right',
}

const refreshStyle = {
  border: '0',
  marginTop: '1.1em',
  marginRight: '1em',
  textDecoration: 'underline',
  fontWeight: 'bold',
  color: '#0000b3',
}

const loadingStyle = {
  display: 'inline-block',
  position: 'relative',
  top: '8px',
  right: '6px',
}

const Tabs = observer(() => {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '0.2em',
        background: 'transparent',
      }}>
      <button
        onClick={buy}
        style={store.interest === 'buy' ? activeStyle : inactiveStyle}>
        Public Request: Buying Interest
      </button>
      <button
        onClick={sell}
        style={store.interest === 'sell' ? activeStyle : inactiveStyle}>
        Public Request: Selling Interest
      </button>
      <span style={refreshSpanStyle}>
        {store.loading && (
          <span style={loadingStyle}>
            <Preloader loadingStyle="spinner" loading />
          </span>
        )}
        <button
          style={refreshStyle}
          onClick={() => (store.interest === 'buy' ? buy : sell)()}>
          <RefreshIcon style={{ marginRight: '3px' }} />
          Refresh
        </button>
      </span>
    </div>
  )
})

const colStyle = {
  a: {
    color: 'black',
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '45px',
    tableLayout: 'fixed',
  },
  b: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: 'black',
    textAlign: 'left',
    paddingLeft: '3px',
    marginRight: '2px',
  },
  span: {
    fontSize: '0.9em',
    display: 'block',
  },
}

const Col = observer(({ index, config, width }) => {
  const {
    field,
    type,
    prepend,
    append,
    dateformat,
    timeformat,
    onEmpty,
  } = config
  let tvalue = null
  let value = store.translate(store.data[index][field])

  if (type === 'date')
    value = moment.utc(value).format(dateformat ?? DATEFORMAT)

  if (type === 'datetime') {
    const mv = moment
      .utc(value)
      .clone()
      .tz(moment.tz.guess())
    value = (
      <>
        {mv.format(dateformat ?? DATEFORMAT)}
        <span style={colStyle.span}>{mv.format(timeformat ?? 'HH:mm z')}</span>
      </>
    )
    tvalue = moment
      .utc(store.data[index][field])
      .clone()
      .tz(moment.tz.guess())
      .format(dateformat + ' ' + timeformat)
  }

  if (type === 'number') value = parseFloat(value).toFixed(2)

  if (type === 'price') {
    value = renderPrice(store.data[index])
    tvalue = store.data[index][field]
  }

  if (type === 'integer') {
    value = renderInteger(value)
    tvalue = store.data[index][field] | 0
  }

  if (type === 'country') {
    value = store.country(value)
  }

  // override empty values
  if (onEmpty && !value.length) {
    value = onEmpty
  }

  if (type === 'port') {
    value = store.port(index)
  }

  if (prepend)
    value = (
      <>
        <span>{prepend}</span>
        {value}
      </>
    )

  if (append)
    value = (
      <>
        {value}
        <span>{append}</span>
      </>
    )

  return (
    <div
      title={tvalue ?? value}
      style={{ ...colStyle.a, width: store.colWidth - 2 + 'px' }}>
      <div style={{ ...colStyle.b, width: store.colWidth - 2 + 'px' }}>
        {value}
      </div>
    </div>
  )
})

const _rowStyle = {
  fontSize: '100%',
  display: 'unset',
  cursor: 'pointer',
  borderBottom: '1px solid #DDD',
  height: '45px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

const Row = observer(({ width, index, style, navigate, user }) => {
  if (!store.data[index]) return null
  const { ownerId, buyerId, sellerId } = store.data[index]
  const { companyId } = user
  const rowStyle = { ..._rowStyle }

  let className = 'exchange__row'

  if (
    ownerId === companyId ||
    buyerId === companyId ||
    sellerId === companyId
  ) {
    className += ' exchange__row__highlight'
  }

  width = width - 32 //subtract scrollbar and borders

  return (
    <div
      className={className}
      onClick={() => navigate(`/exchange/details/${store.data[index].id}`)}
      style={{ ...rowStyle, ...style }}>
      {store.columns.map((config) => (
        <Col key={config.field} {...{ index, config, width }} />
      ))}
    </div>
  )
})

const iconStyle = {
  offset: {
    position: 'relative',
    left: 'calc(50vw - 200px)',
    top: '15vh',
  },
  txt: {
    width: '50vw',
    fontSize: '25px',
    fontWeight: 'bold',
    position: 'relative',
    left: '74px',
    top: '-145px',
  },
  a: {
    fontSize: '62px',
    color: '#808183',
  },
  b: {
    position: 'relative',
    fontSize: '50px',
    top: '-31px',
    left: '30px',
    color: '#808183',
    zIndex: 100,
  },
  c: {
    position: 'relative',
    fontSize: '50px',
    top: '-86px',
    left: '24px',
    color: 'white',
    zIndex: 50,
  },
}

const loadMoreItems = (row) => {
  if (row > store.data.length - 300) {
    store.fetchMore()
    console.log('fetchMore', row)
  }
}

const renderModal = () => (
  <div className="modal__container">
    <form
      className="modal__wrapper px-0 py-0 authcode"
      onSubmit={(e) => {
        e.preventDefault()
        store.set('showModal', false)
      }}>
      <div className="modal-content">
        <div className="modal-header">
          <Link
            to="/"
            className="close"
            data-dismiss="modal"
            onClick={() => store.set('showModal', false)}
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Link>
        </div>
        <div className="modal-body">
          <p className="center-text">
            <h6>You are not authorized to perform this action.</h6>
          </p>
        </div>
        <div className="modal-footer">
          <Link
            to="/"
            className="btn-popup btn-cancel "
            onClick={() => store.set('showModal', false)}>
            <FontAwesomeIcon icon={faTimes} />
            Cancel
          </Link>
        </div>
      </div>
    </form>
  </div>
)

const AutoWidth = observer(({ navigate, user }) => {
  if (!store.columns.length) return null

  if (store.showModal) return renderModal()

  return (
    <InfiniteLoader
      isItemLoaded={(p) => store.loading}
      //emulate extra screen of items to enable overscroll which triggers fetch
      itemCount={
        store.data.length
          ? store.data.length + ((window.innerHeight / 50) | 0)
          : 0
      }
      loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <AutoSizer>
          {() => (
            <>
              <Headers />
              {store.data.length === 0 && !store.loading ? (
                <div>
                  <div style={iconStyle.offset}>
                    <ListAltIcon style={iconStyle.a} />
                    <SearchIcon style={iconStyle.b} />
                    <FiberManualRecordIcon style={iconStyle.c} />
                    <div style={iconStyle.txt}>No records to display</div>
                  </div>
                </div>
              ) : (
                <List
                  style={{ overflowX: 'hidden' }}
                  height={store.height - 385}
                  //emulate extra screen of items to enable overscroll which triggers fetch
                  itemCount={
                    store.data.length
                      ? store.data.length + ((window.innerHeight / 50) | 0)
                      : 0 //reset when empty to autoscroll to the start of the list
                  }
                  itemSize={45}
                  width={store.tableWidth - 96}
                  onItemsRendered={onItemsRendered}
                  ref={ref}>
                  {({ index, style }) => (
                    <Row
                      key={index}
                      width={store.tableWidth - 96}
                      {...{
                        index,
                        style,
                        navigate,
                        user,
                      }}
                    />
                  )}
                </List>
              )}
            </>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
})

const languages = ['en', 'jp', 'de', 'fr']

const Language = observer(() => (
  <select
    value={store.language}
    onChange={(e) => store.set('language', e.target.value)}>
    {languages.map((lang) => (
      <option>{lang}</option>
    ))}
  </select>
))

const switchLabelStyle = {
  fontWeight: 'bold',
  paddingTop: '.5em',
  lineHeight: '1em',
}

const switchStyle = { marginRight: '3em' }

const TenderSwitch = observer(() => (
  <div
    style={switchStyle}
    className="custom-control custom-switch"
    onClick={() => store.toggleTenders()}>
    <input
      type="checkbox"
      className="custom-control-input"
      checked={store.tendersOnly}
    />
    <label
      style={switchLabelStyle}
      className="custom-control-label"
      htmlFor="customSwitch1">
      Tenders Only
    </label>
  </div>
))

const ExpirySwitch = observer(() => (
  <div
    style={switchStyle}
    className="custom-control custom-switch"
    onClick={() => store.toggleExpiry()}>
    <input
      type="checkbox"
      className="custom-control-input"
      checked={store.expiryOnly}
    />
    <label
      style={switchLabelStyle}
      className="custom-control-label"
      htmlFor="customSwitch2">
      Expiring validity in 12h
    </label>
  </div>
))

const StatusFilter = observer(() => (
  <>
    <span style={{ fontWeight: 'bold', padding: '1em' }}>Status</span>
    <select
      value={store.statusFilter}
      onChange={(e) => {
        store.set('statusFilter', e.target.value)
      }}>
      {store.statusList.map((status) => (
        <option key={status} value={status}>
          {status.length ? store.translate(status) : 'Select Status'}
        </option>
      ))}
    </select>
  </>
))

const FertilizerTypeFilter = observer(() => (
  <div>
    <span
      style={{
        fontWeight: 'bold',
        lineHeight: '1.9em',
        paddingRight: '0.5em',
      }}>
      Fertilizer Type
    </span>
    <select
      style={{ backgroundColor: 'white' }}
      value={store.fertilizerTypeFilter}
      onChange={(e) => {
        store.set('fertilizerTypeFilter', e.target.value)
      }}>
      {store.fertilizerTypeList.map((fertilizerType) => (
        <option key={fertilizerType} value={fertilizerType}>
          {fertilizerType.length
            ? store.translate(fertilizerType)
            : 'Select Fertilizer Type'}
        </option>
      ))}
    </select>
  </div>
))

const titles = {
  deliveryStartDate: (
    <>
      <b>Delivery</b>
      <br />
      <small>start date</small>
    </>
  ),
  deliveryEndDate: (
    <>
      <b>Delivery</b>
      <br />
      <small>end date</small>
    </>
  ),
}

const Header = observer(({ field, title }) => {
  const rootStyle = {
    color:
      store.sortEnabled && store.sortColumn === field
        ? 'rgb(0 0 179)'
        : 'black',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }

  const wrapStyle = {
    boxSizing: 'border-box',
    width: store.colWidth + 'px',
    paddingLeft: '3px',
  }

  const headerStyle = {
    display: 'inline-block',
    textAlign: 'left',
    marginRight: '1em',
  }

  return (
    <div
      onClick={() => store.set('sortColumn', field)}
      key={field}
      className="sort_header_wrap"
      style={wrapStyle}>
      <span
        className="MuiButtonBase-root MuiTableSortLabel-root"
        style={rootStyle}>
        <div style={headerStyle}>{titles[field] ?? title}</div>
        {store.sortDirection === -1 ? <ArrowDownward /> : <ArrowUpward />}
      </span>
    </div>
  )
})

const Headers = observer(() => {
  const style = {
    backgroundColor: 'white',
    width: store.tableWidth - 96 + 'px',
  }

  return (
    <>
      <div {...{ style }}>
        {store.columns.map(({ field, title }) => (
          <Header
            key={field}
            width={store.tableWidth - 40}
            {...{ field, title }}
          />
        ))}
      </div>
    </>
  )
})

const horizontallyScrollable = {
  overflowY: 'hidden',
  overflowX: 'auto',
  backgroundColor: 'white',
}

const Exchange = observer(({ navigate, user }) => {
  React.useEffect(() => {
    // if no data is available
    if (!store.rawData || store.rawData.length === 0) {
      console.log('no data available, loading...')
      //initialize store to load data
      store.afterCreate()
    }
  })

  React.useEffect(() => {
    // initialize on first render
    if (viewExchangePermission() === 0) store.set('showModal', true)
  }, [])

  const styles = {
    display: 'flex',
    flexWrap: 'wrap',
  }

  return (
    <div className="trades">
      <div style={styles}>
        {store.features.get('languageSelect')?.enabled && <Language />}
        <TenderSwitch />
        <ExpirySwitch />
        {store.features.get('statusSelect')?.enabled && <StatusFilter />}
        {store.features.get('fertilizerTypeSelect')?.enabled && (
          <FertilizerTypeFilter />
        )}
      </div>
      <Tabs />
      <div
        style={{
          ...horizontallyScrollable,
          height: store.width < 425 ? store.height - 100 : store.height - 320,
        }}>
        <AutoWidth {...{ navigate, user }} />
      </div>
    </div>
  )
})

export default Exchange
