import React from 'react';

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertModal = function (props) {

    let { isCancelButtonEnabled = true, isSubmitButtonEnabled = true,
        cancelButtonContent = 'Reset', submitButtonContent = 'Submit',
        cancelFontIcon, submitFontIcon,
        modalContent, onCancel, onSubmit,styles='' } = props;
    return (
        <div className="modal__container">
            <div className={` modal__wrapper px-0 py-0 ${styles}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <p className="modal-title">
                                 {modalContent}
                            </p>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        </div>
                    </div>
                    <div className="modal-footer pt-0">
                    {isCancelButtonEnabled ?
                    <button className={`btn-popup btn-cancel `} type="button" onClick={onCancel} >
                        {cancelFontIcon ? <FontAwesomeIcon icon={faTimes} /> : null}&nbsp;{cancelButtonContent}
                    </button> : null
                    }
                    {isSubmitButtonEnabled ?
                    <button className={`btn-popup btn-submit`} type="button" onClick={onSubmit} >
                        {submitFontIcon ? <FontAwesomeIcon icon={faCheck} /> : null}&nbsp;{submitButtonContent}
                    </button> : null}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertModal;