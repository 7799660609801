import React from 'react';
import './FormTextareaField.scss';



export default ({
    name,
    value,
    validation,
    onChange,
    max,
    customStyle = {
        isValidClassName: "",
        isInvalidClassName: "input_error"
    },
    maxlength,
    placeholder = '',
    inlineStyle = {},
    disabled = false,
    rows,
    cols
}) => {
    const isInvalid = validation && validation.hasOwnProperty(name);
    return (
        <div className="form-textarea">
            {value.label &&
                <label htmlFor={name} className="label">
                    {value.label}
                    <span className="req-field">&nbsp;{value.required ? '*' : ''}</span>
                </label>}
            <textarea
                placeholder={placeholder}
                value={value.value}
                disabled={disabled}
                className={`textarea form-control ${isInvalid ? customStyle.isInvalidClassName : value.touched || value.validated ? customStyle.isValidClassName : ''}`}
                id={name}
                name={name}
                onChange={onChange}
                max={max}
                maxLength={maxlength}
                required={!!value.required}
                rows={rows}
                cols={cols}
                style={inlineStyle}
            />
        </div>
    )
};