import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FaFilePdfO from 'react-icons/lib/fa/file-pdf-o';
import FaEdit from 'react-icons/lib/fa/edit';
import html2pdf from 'html2pdf.js';
import moment from 'moment-timezone';
import { DATEFORMATHOURS } from '../../../../services/service.values';
import TemplateVesselFOB from './contract/TemplateVesselFOB'
import TemplateContainerFOB from './contract/TemplateContainerFOB'
import TemplateVesselCIF from './contract/TemplateVesselCIF'
import TemplateContainerCIF from './contract/TemplateContainerCIF'
import TemplateVesselCFR from './contract/TemplateVesselCFR'
import TemplateContainerCFR from './contract/TemplateContainerCFR'
import TemplateTender from './contract/TemplateTender'
import RequestContract from './contract/RequestContract'
import TermDetailsModal from './contract/TermDetailsModal'
import { SubmitContractTerm, LoadContractTerms, AcceptContractTerm, DeclineContractTerm } from '../../../../modules/module.contract';
import { AutoupdateContract } from '../../../../modules/module.trade';
import Preloader from '../../../components/preloader/Preloader';
import DeclineContractModal from '../../trades/components/contract/DeclineContractModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { createTradeRequestPermission } from '../../../../services/service.permission';
import PermissionModal from '../../../../utils/permissionModal';
import ErrorModal from '../../requests/components/ErrorModal';

class SmartContract extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            marginTop: 0,
            marginRight: 0,
            marginBot: 0,
            marginLeft: 0,
            forceSave: 0,
            openEditables: {
                prodAndSpec: false,
                contractualInspect: false,
            },
            editAll: false,
            contractModal: {
                show: false,
                term: {}
            },
            termDetails: false,
            showRequest: true,
            termDetailModal: {
                show: false
            },
            title: "Review Contract Amendment Request",
            isSentContract: false,
            isSubmitContract: true,
            isRecivedContract: false,
            declineModal: false,
            permissionModal: false,
            ValidationMessageModal: false,
            isDeclaration: false,
            showError: false
        };
        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleExport = this.handleExport.bind(this);

    }


    componentDidMount() {
        if (this.props.isVisible) {
            this.props.LoadContractTerms(this.props.trade.id);
        }
        this.setState({
            latestTerm: this.getLatestNegotiation()
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = new URLSearchParams(window.location.search);
        const forceUpdateParam = params.get('forceUpdate');
        if (forceUpdateParam) {
            const forceUpdate = forceUpdateParam === 'true';
            if (forceUpdate) {
                //  this.loadRequestData();
            }
            const newLocationSearch = this.props.location.search.replace(/(&|\\?)forceUpdate=(true|false)/g, '');
            this.props.navigate(window.location.pathname + newLocationSearch);
        }

        if (!prevProps.triggerContractUpdate && this.props.triggerContractUpdate) {
            this.props.AutoupdateContract();
            this.props.LoadContractTerms(this.props.trade.id);
            this.closeTermDetailModal();
        }

        if ((this.props.trade !== prevProps.trade || prevProps.contract.terms !== this.props.contract.terms) && this.props.trade && this.props.contract.terms) {
            this.setState({ latestTerm: this.getLatestNegotiation() });
        }

        if (this.props.contract.terms !== prevProps.contract.terms) {
            this.setState({ lastAmendment: this.getLastAmendment(this.props.contract.terms) })
        }
    }

    get isEditing() {
        for (const editable in this.state.openEditables) {
            if (this.state.openEditables[editable]) {
                return true;
            }
        }
        return false;
    }

    onEdit = (name) => {
        this.setState(prev => {
            prev.openEditables[name] = true;
            return { openEditables: prev.openEditables }
        });
    }

    onSave = (content, name) => {
        this.setState(prev => {
            prev.openEditables[name] = false;
            return { openEditables: prev.openEditables }
        });
    }

    onSaveAll = (name) => {
        this.setState(prev => {
            return {
                openEditables: {},
                forceSave: prev.forceSave++
            }
        });
    }

    onCancel = (name) => {
        this.setState(prev => {
            prev.openEditables[name] = false;
            return { openEditables: prev.openEditables }
        });
    }

    closeContractModal = () => {
        this.setState((state) => ({ contractModal: { ...state.contractModal, show: false } }));
    }

    closeTermDetailModal = () => {
        this.setState((state) => ({ termDetailModal: { ...state.termDetailModal, show: false }, showError: false, isDeclaration: false, }));
    }

    createContract = () => {
        if (createTradeRequestPermission() === 0) {
            this.setState({ permissionModal: true });
        }
        else {
            let term = this.props.trade;
            this.setState((state, props) => {
                return {
                    contractModal: {
                        ...state.contractModal,
                        show: true,
                        term: term,
                        type: "CREATE"
                    }
                }
            })
        }
    }

    editContract = () => {
        this.closeTermDetailModal();
        if (this.state.requestLoaded) {
            this.setState((state) => ({
                contractModal: {
                    ...this.state.contractModal,
                    show: true,
                    term: state.requestLoaded,
                    type: "EDIT"
                }
            }))
        }
    }

    counterContract = () => {
        let term = this.state.latestTerm && this.state.latestTerm[0];
        this.setState((state) => {
            return {
                contractModal: {
                    ...state.contractModal,
                    show: true,
                    term: term,
                    type: "COUNTER"
                }
            }
        })
        this.closeTermDetailModal();
    }

    acceptContract = () => {
        const downPaymentRequired = this.props.trade.downPaymentPercentage ? true : false;
        var Counterparty = this.getCounterparty(this.props.trade);
        if (downPaymentRequired) {
            if (this.state.isDeclaration) {
                this.props.AcceptContractTerm(this.props.trade.id, Counterparty, (response) => {
                    if (response.data.success) {
                        this.setState((state) => ({
                            termDetailModal: { ...state.termDetailModal, show: false }
                        }));
                    } else {
                        if (response.data.errorCode === "CONTRACT_TERM_EXPIRED") {
                            this.setState({ ValidationMessageModal: true });
                        }
                    }
                });
            }
            else {
                this.setState({
                    showError: true,
                });
            }
        }
        else {
            this.props.AcceptContractTerm(this.props.trade.id, Counterparty, (response) => {
                if (response.data.success) {
                    this.setState((state) => ({
                        termDetailModal: { ...state.termDetailModal, show: false }
                    }));
                } else {
                    if (response.data.errorCode === "CONTRACT_TERM_EXPIRED") {
                        this.setState({ ValidationMessageModal: true });
                    }
                }
            });
        }
    }

    declineContract(term, declineReason) {
        term.declineReason = declineReason.declineReason;
        term.declinedByCompanyId = this.props.account.user.companyId;
        term.declinedByCompanyName = this.props.account.user.companyName;
        var Counterparty = this.getCounterparty(this.props.trade);
        this.props.DeclineContractTerm(this.props.trade.id, Counterparty, term, () => {
            this.setState({ declineModal: false });
            this.closeTermDetailModal();
        });
    }

    declineContractModal = (e) => {
        this.setState({ declineModal: true });
    }

    onSubmitContract = () => {
        const downPaymentRequired = this.props.trade.downPaymentPercentage ? true : false;
        var Counterparty = this.getCounterparty(this.props.trade);
        var requestLoaded = this.state.requestLoaded;
        if (downPaymentRequired) {
            if (this.state.isDeclaration) {
                requestLoaded.status = "NEW";
                this.props.SubmitContractTerm(this.props.trade.id, Counterparty, requestLoaded, (err, data) => {
                    if (err) {
                        //Handle error submit
                    } else {
                        this.setState(state => delete state.requestLoaded);
                    }
                });
                this.closeTermDetailModal();
                this.setState({ termDetails: true, showRequest: false, });
            } else {
                this.setState({
                    showError: true,
                });
            }
        }
        else {
            requestLoaded.status = "NEW";
            this.props.SubmitContractTerm(this.props.trade.id, Counterparty, requestLoaded, (err, data) => {
                if (err) {
                    //Handle error submit
                } else {
                    this.setState(state => delete state.requestLoaded);
                }
            });
            this.closeTermDetailModal();
            this.setState({ termDetails: true, showRequest: false, });
        }
    }

    getCounterparty(trade) {
        if (trade.buyerId === this.props.account.token.companyId) {
            return trade.sellerId;
        }
        if (trade.sellerId === this.props.account.token.companyId) {
            return trade.buyerId;
        } else {
            return "";
        }
    }

    // Review term for change in amendment Terms
    reviewTerms = (term) => {
        term.status = "REVIEW";
        this.setState((state) => ({
            termDetailModal: { ...state.termDetailModal, show: true },
            requestLoaded: term,
            isSentContract: false,
            isSubmitContract: true,
            isRecivedContract: false,
            isDeclineContract: false,
            title: "Review Contract Amendment Request"
        }));
        this.closeContractModal();
    }


    getTimeLine(trade, arrayOfTerms) {
        console.log("Timeline")
        if (!Array.isArray(arrayOfTerms)) {
            console.warn('Recieved non Array object as parameter arrayOfTerm')
            return [trade]
        }
        if (arrayOfTerms.length === 0)
            return [trade]

        arrayOfTerms = arrayOfTerms
            .filter(item => item.status !== 'ORIG_CONTRACT_DATA')
            .sort((a, b) => moment.utc(b.createdAt).isBefore(moment.utc(a.createdAt)) ? -1 : 1);

        //If the first element is an acceptance mark or a rejection then the timeline is starting
        if (arrayOfTerms[0].acceptedByCompanyId !== '' || arrayOfTerms[0].declinedByCompanyId !== '') {
            let previousDeal = arrayOfTerms.find(item => item.acceptedByCompanyId !== '') || trade;
            return [previousDeal];
        } else {
            let previousDeal = arrayOfTerms.find(item => item.acceptedByCompanyId !== '') || trade;
            const indexOfStart = arrayOfTerms.findIndex(item => (item.acceptedByCompanyId !== '' || item.declinedByCompanyId !== ''));
            if (indexOfStart !== -1)
                arrayOfTerms = arrayOfTerms.slice(0, indexOfStart);

            return [...arrayOfTerms, previousDeal]

        }
    }

    getLastAmendment(contractTerms) {
        let previousDeal = contractTerms.find(item => item.acceptedByCompanyId !== '');
        return previousDeal;
    }

    getSelectedTerm() {
        // var counterpartyId=  this.getCounterparty(this.props.trade);
        let selected = [];
        if (this.props.contract.terms && this.props.contract.terms.length !== 0) {
            selected = this.props.contract.terms;
            selected[0].selected = true;
        }
        return selected;
    }

    //Get the latest Term for the contract
    getLatestNegotiation() {
        // const userCompId = this.props.account.token.companyId;
        // let counterpartyList = [];
        var latestTerm = [];
        if (this.props.contract.terms && this.props.contract.terms.length !== 0) {
            var Term = this.props.contract.terms[0];
            latestTerm.push(Term);
        }
        return latestTerm
    }

    isTermOwner() {
        var latestTerm = this.getLatestNegotiation();
        if (latestTerm && latestTerm.length !== 0) {
            return latestTerm[0].fromCompanyID.trim() === this.props.account.user.companyId.trim();
        } else {
            return undefined
        }
    }

    handleExport = () => {
        window.scrollTo(0, 0);
        var worker = html2pdf();
        const element = document.getElementById('inner-capture');
        let options = {
            margin: [6, 12, 6, 12],
            filename: `${this.props.trade.incoterm} PURCHASE CONTRACT ${this.props.trade.id}.pdf`,
            html2canvas: { scale: 3 },
            pagebreak: { mode: ['avoid-all'], avoid: "section" },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        return worker.from(element)
            .set(options)
            .save();
    };

    renderContract() {
        const { trade } = this.props;
        if (trade.privacyType === "TENDER") {
            return <TemplateTender {...this.state} {...this.props} />;
        }
        switch (trade.incoterm) {
            case "FOB": if (trade.shipping === "CONTAINER") {
                return <TemplateContainerFOB {...this.state} {...this.props} />;
            } else {
                return <TemplateVesselFOB {...this.state} {...this.props} />;
            }
            case "CIF": if (trade.shipping === "CONTAINER") {
                return <TemplateContainerCIF {...this.state} {...this.props} />;
            } else {
                return <TemplateVesselCIF {...this.state} {...this.props} />;
            }
            case "CFR": if (trade.shipping === "CONTAINER") {
                return <TemplateContainerCFR {...this.state} {...this.props} />;
            } else {
                return <TemplateVesselCFR {...this.state} {...this.props} />;
            }
            default: return "Template could not be found"
        }
    }
    getCounterPartyName = () => {
        var trade = this.props.trade;
        if (trade.buyerId === this.props.account.token.companyId) {
            return trade.seller;
        }
        if (trade.sellerId === this.props.account.token.companyId) {
            return trade.buyer;
        } else {
            return "";
        }

    }
    //View Contract Request on the basis of Term Owner and status
    viewContract() {
        var latestTerm = this.getLatestNegotiation();
        var isSentContract = this.isTermOwner() ? true : false;
        var isRecivedContract = this.isTermOwner() ? false : true;
        var isDeclineContract = false;
        var title = this.isTermOwner() ? "You sent contract amendment request" : "You have received contract amendment request";
        if (latestTerm[0].status === "DECLINED") {
            title = this.isTermOwner() ? "Your contract amendment request declined" : "You declined contract amendment request";
            isDeclineContract = true;
            isSentContract = false;
            isRecivedContract = false;
        }
        this.setState((state) => ({
            termDetailModal: { ...state.termDetailModal, show: true },
            title: title,
            isSentContract: isSentContract,
            isSubmitContract: false,
            isRecivedContract: isRecivedContract,
            isDeclineContract: isDeclineContract
        }));
    }

    closeDeclineModal() {
        this.setState({ declineModal: false });
    }
    closePermissionModal() {
        this.setState({ permissionModal: false });
    }

    closeValidationMessageModal = () => {
        this.setState({ ValidationMessageModal: false });
    }

    rendertermStatus() {
        var title = this.isTermOwner() ? "Contract Amendment Request Sent" : "Contract Amendment Request Received";
        if (this.state.latestTerm && this.state.latestTerm.length !== 0) {
            if (this.state.latestTerm[0].status === "DECLINED") {
                title = `Contract Amendment Request  Decline by ${this.state.latestTerm[0].declinedByCompanyName}`;
            }
        }
        return title;
    }

    isDeclarationCheck() {
        const downPaymentRequired = this.props.trade.downPaymentPercentage ? true : false;
        if (downPaymentRequired) {
            return (
                <div className="pt-2">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="declaration" className="custom-control-input" id="declaration" checked={this.state.isDeclaration}
                            onChange={val => { this.setState({ isDeclaration: val.target.checked, showError: false }); }} />
                        <label className="custom-control-label text-left" htmlFor="declaration">
                            I acknowledge and accept that the agreed down payment amount will not be adjusted even if the total contract value is increased or decreased as a result of changes proposed in this amendment request.
                        </label>
                        {this.state.showError &&
                            <div className="counterparty__error text-left">
                                Please review the above terms and conditions and check the checkbox that you acknowledge them prior to confirming
                        </div>}
                    </div>
                </div>)
        }
    }



    render() {

        const isAccessible = ["SIGNED", "PROFORMA_INVOICE", "VESSEL_NOMINATED", "INSTRUCTION", "INSTRUCTIONS"].includes(this.props.trade.status) && this.props.user.companyType !== "INSPECTION"
        const { request, contract } = this.props;
        const contractTerms = contract.terms;
        return (
            <React.Fragment>
                {this.props.trade ?
                    <Preloader loadingStyle="swirl" loading={this.props.contractLoading}>
                        {/* {(this.props.trade.state==="DEAL" || this.props.trade.state==="SIGNED" || this.props.trade.state==="PROFORMA_INVOICE" || this.props.trade.state==="VESSEL_NOMINATED") &&   */}
                        <div className="request-dtls__status-sections sub-header">
                            {this.props.isVisible && isAccessible && this.state.latestTerm && this.state.latestTerm.length !== 0 && this.state.latestTerm[0].status === 'NEW' &&
                                <div className="mb-4">
                                    <h3 className="request-dtls__card-heading pt-3 pl-4">Contract Amendment Negotiation Details</h3>
                                    <div className="col-md-12">
                                        <div className="table-responsive p-2">
                                            <table className="table table-hover latest-negociation-table">
                                                <thead>
                                                    <tr>
                                                        <th>Counterparty Name</th>
                                                        <th>Expires</th>
                                                        <th>Status</th>
                                                        {this.state.latestTerm[0].status === "DECLINED" &&
                                                            <th>Decline Reason</th>}
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.getCounterPartyName(this.state.latestTerm[0])}</td>
                                                        <td>{moment(this.state.latestTerm[0].contractValidateDate).format(DATEFORMATHOURS)} </td>
                                                        <td>
                                                            <span className={this.state.latestTerm[0].status === "DECLINED" ? "outline-status-warning" : "outline-status-success"}>{this.rendertermStatus()}</span>
                                                        </td>
                                                        {this.state.latestTerm[0].status === "DECLINED" &&
                                                            <td>{this.state.latestTerm[0].declineReason}</td>}
                                                        <td>
                                                            <button className="bc-counter mr-3" onClick={(e) => (this.viewContract())}>
                                                                <FontAwesomeIcon icon={faCheck} /> View Contract Amendment Request </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        <div className="trades-dtls__contract">
                            <div className="row justify-content-end">
                                {this.props.isVisible && <div>
                                    {this.state.contractModal.show && (
                                        <RequestContract
                                            onReview={this.reviewTerms}
                                            onCreate={this.createTerms}
                                            onClose={this.closeContractModal}
                                            trade={{ ...this.props.trade }}
                                            //pov={this.state.pov}
                                            type={this.state.contractModal.type}
                                            term={this.state.contractModal.term}
                                            inspectionCompanies={this.props.inspectionCompanies}
                                        />)}
                                    {this.state.termDetailModal.show && (
                                        <TermDetailsModal
                                            onClose={this.closeTermDetailModal}
                                            trade={this.props.trade}
                                            requestLoaded={this.state.requestLoaded}
                                            editContract={this.editContract}
                                            onSubmitContract={this.onSubmitContract}
                                            counterContract={this.counterContract}
                                            declineContractModal={this.declineContractModal}
                                            acceptContract={this.acceptContract}
                                            terms={this.getTimeLine(request, contractTerms)}
                                            title={this.state.title}
                                            isSentContract={this.state.isSentContract}
                                            isSubmitContract={this.state.isSubmitContract}
                                            isRecivedContract={this.state.isRecivedContract}
                                            isDeclineContract={this.state.isDeclineContract}
                                            getCounterPartyName={this.getCounterPartyName()}
                                            isDeclarationCheck={this.isDeclarationCheck()}
                                        />)}
                                    {this.state.declineModal && this.state.latestTerm && (
                                        <DeclineContractModal
                                            onConfirm={declineReason => { this.declineContract(this.state.latestTerm[0], { declineReason }) }}
                                            onClose={(e) => { this.closeDeclineModal() }}
                                            fromCompanyName={this.getCounterPartyName()} />)}

                                    {this.state.permissionModal && (
                                        <PermissionModal
                                            onClose={(e) => { this.closePermissionModal() }} />)}


                                    {this.state.ValidationMessageModal &&
                                        <ErrorModal onClose={e => this.closeValidationMessageModal()}
                                            errorTitle={"Validation Error"}
                                            errorMessage={<React.Fragment><div style={{ textAlign: 'center' }}>Contract amendment date has been expired. Kindly propose new amendment for accepting terms</div></React.Fragment>} />}

                                    {isAccessible && this.state.latestTerm && (this.state.latestTerm.length === 0 || this.state.latestTerm[0].status !== 'NEW') &&
                                        <div>
                                            <FaEdit className="trades-dtls__contract-export-img" />
                                            <span className="trades-dtls__contract-export" onClick={(e) => (this.createContract())}>
                                                Request Amendments to Contract

                                    </span>
                                        </div>}
                                </div>}
                                <FaFilePdfO className="trades-dtls__contract-export-img" />
                                {!this.isEditing &&
                                    <span className="trades-dtls__contract-export" onClick={(e) => (this.handleExport())}>
                                        Export PDF
                            </span>}
                                {this.isEditing && <span className="trades-dtls__contract-edit" onClick={this.onSaveAll}>
                                    <button >Save all</button>
                                </span>}
                            </div>
                            <div className="trades-dtls__contract-area" id="capture">
                                <div id="inner-capture" className="m-0 p-0" ref={this.myRef}>{this.renderContract()}</div>
                            </div>
                        </div>
                    </Preloader>
                    :
                    <div>Loading...</div>}
            </React.Fragment>);
    }
}

const mapStateToProps = state => {
    return {
        triggerContractUpdate: state.trade.triggerContractUpdate,
        request: state.trade.items.single,
        contract: state.contract,
        account: state.account,
        contractLoading: state.loading.contractLoading,
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({
    SubmitContractTerm,
    LoadContractTerms,
    AcceptContractTerm,
    DeclineContractTerm,
    AutoupdateContract
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SmartContract);
