import React from 'react';
import PropTypes from 'prop-types';
import './FormInputField.scss';

const FormInputField = ({
        name,
        value,
        prepValue,
        apValue,
        type,
        validation,
        onChange,
        max,
        maxlength,
        placeholder = '',
        disabled=false,
        readOnly=false,
        customStyle = {
            wrapperClassName : "form-input",
            inputClassName : "input form-control",
            isValidClassName: "",
            isInvalidClassName: "input_error"

        },
        ...rest
    }) => {
        const isInvalid = validation && validation.hasOwnProperty(name);
        return (<div className={customStyle.wrapperClassName}>
                {value.label &&
                <label htmlFor={name} className={customStyle.labelClassName}>
                    {value.label}
                    {value.required && <span className="req-field">*</span>}
                </label>}
                <div className="input-group">
                    { prepValue && 
                    <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">{prepValue}</span>
                    </div> }
                    <input
                        type={type}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        value={value.value}
                        className={`${customStyle.inputClassName} 
                                    ${isInvalid ? customStyle.isInvalidClassName :  value.touched || value.validated ? customStyle.isValidClassName : ''}`}
                        id={name}
                        name={name}
                        onChange={onChange}
                        max={max}
                        maxLength={maxlength}
                        required={!!value.required}
                        disabled={disabled}
                        {...rest}
                    />
                    { apValue && 
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon1">{apValue}</span>
                        </div> }
                </div>
                
            </div>)
        };

FormInputField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    prepValue: PropTypes.any,
    apValue: PropTypes.any,
    type: PropTypes.string,
    validation: PropTypes.object,
    onChange: PropTypes.func,
    max: PropTypes.any,
    maxlength: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    customStyle: PropTypes.object
}

export default FormInputField;