import React from 'react';
import { TradeApi } from '../../../../../services/service.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import './BagmarkingPreview.scss'
import PreviewPDF from '../../../../components/previewPDF/PreviewPDF';

class BagmarkingPreview extends React.Component {

    constructor(props) {
        super(props);
        this.downLoadPreview(this.props.image);
        this.state = {
        }
    }

    downLoadPreview = (image) => {
        TradeApi.getTradeDocument(image).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            this.setState({
                url: url
            });
        })
            .catch(e => {
                console.error(e)
            })
    }

    handlePreviewClick = () => {
        if (this.props.image.type === "application/pdf") {
            this.setState({ showModal: true, isPDF: true });
        } else {
            this.setState({ showModal: true, isImage: true });
        }
    }

    render() {
        return <React.Fragment>
            <div >
                {this.state.url ?
                    <React.Fragment>
                        <div onClick={e => this.handlePreviewClick()}>

                            <FontAwesomeIcon icon={faSearchPlus} />
                        </div>
                    </React.Fragment> :
                    <div >
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </div>
                }
            </div>
            {(this.state.showModal && this.state.isImage) && <div className="modal__container">
                <div className="modal__wrapper px-0 py-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close" data-dismiss="modal" onClick={e => this.setState({ showModal: false })} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img className="rounded mx-auto d-block" width="700" src={this.state.url} alt="Fertx Img" />
                        </div>
                        <div className="modal-footer">
                            <button className="btn-popup btn-cancel" onClick={e => this.setState({ showModal: false })}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>
                    </div>
                </div>
            </div>}
            {(this.state.showModal && this.state.isPDF) && (
                <div className="modal__container">
                    <div className="modal__wrapper">
                        <span className="modal__close" onClick={e => this.setState({ showModal: false })} />
                        <PreviewPDF file={this.state.url} />
                        <div className="modal-footer">
                            <button className="btn-popup btn-cancel" onClick={e => this.setState({ showModal: false })}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    }
}

export default BagmarkingPreview;