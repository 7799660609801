import React from 'react';
import moment from 'moment';
import DetailTable from '../../requests/detail/DetailTable';
import ReviewTradeImage from './ReviewTradeImage';
import DownloadDocument from '../components/DownloadDocument';
import {
    DATEFORMAT,
    DATEFORMATHOURS,
    PAYMENTPERIODOPT,
    DISCHARGE_VALUES,
    PACKAGING,
    SHIPPINGTYPES,
    getDischargeRateLabel,
    getDischargeLabel,
    REQUEST_PRIVACY_TYPE,
    REGION_OF_ORIGIN,
    OFFER_TYPES
} from '../../../../services/service.values';
import { FertilizerService } from '../../../../services/service.fertilizer';
import NumberFormat from 'react-number-format';
import { PortService, getLocalTimezone } from '../../../../services/service.utils';
import { 
    renderDocuments, 
    renderDocumentsTitle, 
    renderFertilizerSpecifications, 
    renderInspectionCompany,
    renderCountry, 
    renderGoverningCountry, 
    renderTolerance,
    renderPrice 
} from './../detail/detailTable/Renderers';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';

const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

class DetailTradeForm extends React.Component {
    constructor(props) {
        super(props);
        const { series } = this.props;

        this.fieldList = [
            { key: "requestType", label: "Request Type", tdClass: this.tdClass },
            { key: "privacyType", label: "Privacy Type",format:value=> (REQUEST_PRIVACY_TYPE[value] || value), tdClass: this.tdClass },
            ...(series[0].data.privacyType === "TENDER" ?
                [{ key: "dutchAuction", label: "Dutch Auction", format: value => value ? "Yes" : "No", tdClass: this.tdClass },
                { key: "whitelistOnlyTender", label: "Tender Participants", format: value => value ? "Tender Whitelist Group" : "All Companies", tdClass: this.tdClass }]
                :
                [{ key: "counterpartyName", label: "Counterparty Name", format: value => value.map(item => <div>{item}</div>), tdClass: this.tdClass }]),
            { key: "price", label: "Price", "customStyle": "row-price", format: renderPrice, tdClass: this.tdClass },
            { key: "measure", label: "Quantity(MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data} />, tdClass: this.tdClass },
            { key: "tolerance", label: "Contractual Tolerance", format: renderTolerance, tdClass: this.tdClass },
            ...(series[0].data.privacyType === "TENDER" ? [{ key: "tenderStartDate", label: `Tender Opening On (${getLocalTimezone()})`, format: value => moment(value).format(DATEFORMATHOURS) , tdClass: this.tdClass }] : []),
            { key: "validateDate", label: (series[0].data.privacyType === "TENDER")?`Tender Closing On (${getLocalTimezone()})`:`Valid Until (${getLocalTimezone()})`, format: this.formatExpiryDate, tdClass: this.tdClass },
            { key: "shipping", label: "Shipping", format: value => (SHIPPINGTYPES[value] || value), tdClass: this.tdClass },
            { key: "regionOfOrigin", label: "Region of Origin", format: value => (REGION_OF_ORIGIN[value] || value), tdClass: this.tdClass },
            ...(series[0].data.regionOfOrigin !== "OPEN" ? [{ key: "origin", label: "Origin", format: renderCountry, tdClass: this.tdClass }] : []),
            { key: "fertilizerType", label: "Fertilizer Type", format: value => (FERTILIZER_TYPE[value] || value) },
            { key: "specifications", label: "Fertilizer Specifications", format: (specs, serie) => renderFertilizerSpecifications(specs, serie.data.fertilizerType, false), tdClass: this.tdClass},
            ...(series[0].data.requestType === "SELL" ? [{ key: "offerType", label: "Offer Type", tdClass: this.tdClass, format: value => OFFER_TYPES[value] }] : []),
            { key: "incoterm", label: "Incoterms", tdClass: this.tdClass },
            ...((series[0].data.privacyType === "TENDER" && series[0].data.incoterm !== "FOB") ? [
                { key: "optionalIncoterm", label: "Also accepting offers for incoterm", tdClass: this.tdClass}
            ] : []),
            { key: "destCountry", label: "Country of Destination", format: renderCountry, tdClass: this.tdClass },
            { key: "destPort", label: "Port of Destination", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "loadCountry", label: "Country of Load", format: renderCountry, tdClass: this.tdClass },
            { key: "loadPort", label: "Port of Load", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "inspection", label: "Inspection Company",format:(value, serie)=> renderInspectionCompany("inspection", this.props.trade.inspections, value, serie), tdClass: this.tdClass },
            { key: "packaging", label: "Packaging", format: this.renderPackaging, tdClass: this.tdClass },
        ];
        if (series[0].data.discharge) {
            this.fieldList = [...this.fieldList,
            { key: "discharge", label: getDischargeLabel(series[0].data.incoterm), format: this.renderDischarge, tdClass: this.tdClass },
            { key: "dischargeRate", label: getDischargeRateLabel(series[0].data.incoterm, series[0].data.discharge), format: this.renderDischargeRate, tdClass: this.tdClass },
            ];
        }
        this.fieldList = [...this.fieldList,
        { key: "payment", label: "Payment Terms", tdClass: this.tdClass },
        { key: "paymentPeriod", label: "Payment Period", format: (value => PAYMENTPERIODOPT[value]), tdClass: this.tdClass },
        ...((series[0] && ["Letter of credit", "Confirmed letter of credit"].includes(series[0].data.payment)) ? [{ key: "LCOpeningDate", label: "Letter of Credit to be Opened Latest On", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass }] : []),
        ...((series[0] && series[0].data.payment === "Avalised Draft") ? [{ key: "ADIssuingDate", label: "Avalised Draft to be issued latest on", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass }] : []),
        { key: "nameOfBank", label: "Name of Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "locationOfBank", label: "Location of Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "swiftCodeOfBank", label: "Swiftcode of Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "nameOfConfirmingBank", label: "Name of Confirming Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "locationOfConfirmingBank", label: "Location of Confirming Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "swiftCodeOfConfirmingBank", label: "Swiftcode of Confirming Bank", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO", tdClass: this.tdClass },
        { key: "downPaymentPeriod", label: "Down Payment to be effected within (days counting from proforma invoice date)", format: (value) => (value) ? value : "-", tdClass: this.tdClass },
        { key: "Documents", label: "Documents", format: value => "", tdClass: "bg-light font-weight-bold" },
        { key: "optionalDocuments", highlightDiff: false, label: renderDocumentsTitle(), format: renderDocuments, tdClass: this.tdClass },
        ...(series[0].data.privacyType === "TENDER" ? [
            { key: "tenderTermsDoc", label: "Tender Terms & Conditions", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "tenderTermsDoc", label: "Terms & Conditions Document", format: value => <DownloadDocument doc={value[0]} />, tdClass: this.tdClass }
        ] : []),
        ...(series[0].data.privacyType !== "TENDER" ? [{key : "governingCountry", label: "Governing law and Arbitration Country", format: renderGoverningCountry, tdClass: this.tdClass}] : []),
        { key: "SpecialRequest", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
        { key: "specialRequest", label: "Content", format: value => value ? value : "NO", tdClass: this.tdClass },
        ];
    }

    formatExpiryDate = (value, serie) => {
        if (serie.data.validateDate) {
            return moment(serie.data.validateDate).format(DATEFORMATHOURS);
        } else {
            return "";
        }
    };

    renderPortLabel = value => {
        let label = "-";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }


    renderDischarge = (value) => {
        const formatedValue = DISCHARGE_VALUES[value];
        return formatedValue;
    }

    renderDischargeRate = (value, serie) => {
        if (value) {
            if(["loadrate", "Discharge rate"].includes(serie.data.discharge)){
                return value
            }
            return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        }
        return "";
    }
    renderPackaging = (value, serie) => {
        //Format default
        if (PACKAGING[value]) {
            return PACKAGING[value]
        } else {
            let prefix = '';
            let packagingQuantity = '';
            if (value.includes('BAGGED_PALLET_OTHER')) {
                prefix = 'BAGGED_PALLET_OTHER - '
                packagingQuantity = value.slice(prefix.length)
                return `Bagged and Pallet - ${packagingQuantity}`
            }
            if (value.includes('BAGGED_OTHER')) {
                prefix = 'BAGGED_OTHER - ';
                packagingQuantity = value.slice(prefix.length)
                return `Bagged - ${packagingQuantity}`
            }
            return '-';
        }
    }

    render() {
        const { series } = this.props;
        return <React.Fragment>
            <div className="request-dtls__status-sections sub-header">
                <div className="card-body">
                    <h3 className="request-dtls__card-heading">Trade Infos</h3>
                    <div className="table-responsive mt-1 trade-dtls__table-section">
                        <DetailTable
                            header={false}
                            series={series}
                            fieldList={this.fieldList}>
                        </DetailTable>
                    </div>
                    {(series[0].data.images && series[0].data.images.length !== 0) &&
                        <React.Fragment>
                            <h3 className="request-dtls__card-heading">Images</h3>
                            <div className="container" style={{ display: 'flex', overflowX: 'auto' }}>
                                {series[0].data.images.map((image, index) => (
                                    <div key={`preview-image-${index}`}>
                                        <ReviewTradeImage key={image.hash} image={image} />

                                        <div>{image.name}</div>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>}
                </div>
            </div>
        </React.Fragment>
    }
}
export default DetailTradeForm;