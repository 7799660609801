import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

// import AccessControl  from '../../../components/AccessControl';
import DetailTable from './DetailTable';
import moment from 'moment';
import {
    DATEFORMAT,
    DATEFORMATHOURS,
    PAYMENTPERIODOPT,
    DISCHARGE_VALUES,
    getDischargeRateLabel,
    getDischargeLabel,
    BID_STATUS
} from '../../../../services/service.values';
import { FertilizerService } from '../../../../services/service.fertilizer';
import { 
    EnumsService, 
    PortService, 
    getLocalTimezone,
    renderPackaging 
} from '../../../../services/service.utils';
import { formatIncoterm, formatShipping} from '../../../../services';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../components/preloader/Preloader';
import DownloadDocument from '../components/DownloadDocument';
import TradeDocPreviewModal from '../components/TradeDocPreviewModal';

//STYLE
import './detail.scss';
import ConfirmModalSubmitCounter from '../components/confirmModals/ConfirmModalSubmitCounter.js';
import { DocumentFactory } from './../../trades/services/documents.service';
import { 
    renderDocumentsTitle, 
    renderDocumentsForm, 
    renderFertilizerSpecifications, 
    renderInspectionCompany,
    renderMultipleOriginPrice,
    renderCountry, 
    renderGoverningCountry, 
    renderTolerance,
    renderMultiOriginLoadPort,
    renderPrice
} from './detailTable/Renderers';

//Quotes
//import QuoteOptions from '../quotes/QuoteOptions';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';

/**
 * Short description. (use period)
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {type} key Description.
 *
 * @member   {type} realName
 * @memberof className
 */
const Countries = EnumsService.countries();
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

class BidDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            fieldList: []
        };
    }

    updateFields = (trade) => {
        const isTender = trade && trade.privacyType === 'TENDER';
        
        const origins = trade.origin.split(',');
        const isMultiOrigin = trade && origins.length > 1;
        const isFOB = trade && trade.incoterm === 'FOB';

        const multiOriginPrices = [{ key: "price title", label: "Price per MT(Origin)", tdClass: "bg-light font-weight-bold" }];
        const multiOriginLoadPorts = [{ key: "port of load", label: "Country of Load (Port of Load)", tdClass: "bg-light font-weight-bold" }]
        if(isTender && isMultiOrigin){
            for(let i = 0; i < origins.length; i++){
                multiOriginPrices.push({
                    key: `price${origins[i]}`, 
                    label: `${i+1}. ${Countries[origins[i]]}`, 
                    format: (value, serie, index) => renderMultipleOriginPrice(origins[i], value, serie, index), tdClass: this.tdClass})
                multiOriginLoadPorts.push({
                    key:  `loadPort${origins[i]}`,
                    label: `${i+1}. ${Countries[origins[i]]}`,
                    format: (port, serie, index) => renderMultiOriginLoadPort(origins[i], port, serie, index)
                }) 
            }
        }

        let fieldList = [
            ...((isTender && isMultiOrigin) ?
                [...multiOriginPrices] :
                [{ key: "price", label: "Price", "customStyle": "row-price", format: renderPrice, tdClass: this.tdClass }]),
            ...((isTender && !isMultiOrigin) ? [{ key: "origin", label: "Origin", format: renderCountry, tdClass: this.tdClass }] : []),
            { key: "fertilizerType", label: "Fertilizer Type", format: value => (FERTILIZER_TYPE[value] || value), tdClass: this.tdClass },
            { key: "specifications", label: "Fertilizer Specifications", format: (specs, serie) => renderFertilizerSpecifications(specs, serie.data.fertilizerType, false), tdClass: this.tdClass},
            { key: "incoterm", label: "Incoterms", format: trade.privacyType === "TENDER" ? formatIncoterm : null, tdClass: this.tdClass },
            ...((isTender && !isFOB) ? [
                { key: "optionalIncoterm", label: "Also accepting offers for incoterm", highlightDiff: false, format: () => trade.optionalIncoterm, tdClass: this.tdClass}
            ] : []),
            { key: "shipping", label: "Shipping", format: trade.privacyType === "TENDER" ? formatShipping : null, tdClass: this.tdClass },
            { key: "inspection", label: "Inspection Company", format:(value, serie)=> renderInspectionCompany("inspection", this.props.trade.inspections, value, serie), tdClass: this.tdClass },
            { key: "measure", label: "Quantity (MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data} />, tdClass: this.tdClass },
            { key: "tolerance", label: "Contractual Tolerance", format: renderTolerance, tdClass: this.tdClass },
            { key: "packaging", label: "Packaging", format: renderPackaging , tdClass: this.tdClass },
            ...((isTender && isMultiOrigin) ? [...multiOriginLoadPorts] : []),
            ...(!isTender ? [
                { key: "origin", label: "Origin", format: renderCountry, tdClass: this.tdClass }
            ] : []),
            ...(!(isTender && isMultiOrigin) ? [
                    { key: "loadCountry", label: "Country of Load", format: renderCountry, tdClass: this.tdClass },
                    { key: "loadPort", label: "Port of Load", format: this.renderPortLabel, tdClass: this.tdClass }
            ] : []),
            { key: "destCountry", label: "Country of Destination", format: renderCountry, tdClass: this.tdClass },
            { key: "destPort", label: "Port of Destination", format: this.renderPortLabel, tdClass: this.tdClass },
            { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT), tdClass: this.tdClass },
            { key: "discharge", label: "Load or Discharge Terms", format: this.renderDischarge, tdClass: this.tdClass },
            { key: "dischargeRate", label: "Load or Discharge Rate", format: this.renderDischargeRate, tdClass: this.tdClass },
            { key: "payment", label: "Payment Terms", tdClass: this.tdClass },
            { key: "paymentPeriod", label: "Payment Period", format: (value => PAYMENTPERIODOPT[value]), tdClass: this.tdClass },
            { key: "LCOpeningDate", label: "Letter of Credit to be Opened Latest On", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass },
            { key: "ADIssuingDate", label: "Avalised Draft to be issued latest on", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass },
            { key: "nameOfBank", label: "Name of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "locationOfBank", label: "Location of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "swiftCodeOfBank", label: "Swiftcode of Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "nameOfConfirmingBank", label: "Name of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "locationOfConfirmingBank", label: "Location of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "swiftCodeOfConfirmingBank", label: "Swiftcode of Confirming Bank", format: this.renderBankDetails, tdClass: this.tdClass },
            { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO", tdClass: this.tdClass },
            { key: "downPaymentPeriod", label: "Down Payment to be effected within (days counting from proforma invoice date)", tdClass: this.tdClass },
            { key: "bidValidateDate", label: `Valid Until (${getLocalTimezone()})`, format: this.formatExpiryDate, tdClass: this.tdClass },
            { key: "Documents", label: "Documents", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "", label: "Mandatory Documents", format: this.renderMandatoryDocument, tdClass: this.tdClass },
            { key: "optionalDocuments", label: renderDocumentsTitle(), format: renderDocumentsForm, tdClass: this.tdClass },
            ...(trade && trade.privacyType === "TENDER" ? [
                { key: "tenderTermsDoc", label: "Tender Terms & Conditions", format: value => "", tdClass: "bg-light font-weight-bold" },
                { key: "tenderTermsDoc", label: "Terms & Conditions Document", format: () => <DownloadDocument doc={this.state.series[0].data.tenderTermsDoc[0]} />, tdClass: this.tdClass }
            ] : []),
            ...(trade && trade.privacyType !== "TENDER" ? [{key : "governingCountry", label: "Governing law and Arbitration Country", format: renderGoverningCountry, tdClass: this.tdClass}] : []),
            { key: "SpecialRequest", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "specialRequest", label: "Content", format: value => value ? value : "NO", tdClass: this.tdClass },
        ]
        this.setState({ fieldList });
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    renderDischarge = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const formatedValue = DISCHARGE_VALUES[value];
            return `${getDischargeLabel(incoterm)}: ${formatedValue}`
        } else {
            return "";
        }

    }

    renderDischargeRate = (value, serie) => {
        if (value) {
            const incoterm = serie.data.incoterm;
            const discharge = serie.data.discharge;
            if(["loadrate", "Discharge rate"].includes(serie.data.discharge)){
                return `${getDischargeRateLabel(incoterm, discharge)} : ${value}`;
            }
            return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={getDischargeRateLabel(incoterm, discharge) + ": "} />
        }
        return "";
    }

    renderMandatoryDocument = (value, serie) => {
        const docs = DocumentFactory.getMandatoryFEDocumentList(serie.data)
        if (Array.isArray(docs) && docs.length !== 0) {
            return (docs.map((doc, index) => `${doc.title}${(index < docs.length - 1) ? ', ' : ''}`
            ))
        } else {
            return "-";
        }
    }

    componentDidUpdate(props, state) {
        //Update when the trade is loaded
        if (props.trade.items.single !== this.props.trade.items.single && this.props.trade.items.single !== null) {
            this.updateFields(this.props.trade.items.single);
            this.updateColumns();
            this.getCounterpartyCompany();
        }
        //Update if the selection of bids changes
        if (props.bids !== this.props.bids && this.props.trade && this.props.trade.items.single !== null) {
            this.updateColumns();
        }
        //Update to review the counter
        if (props.counterLoaded !== this.props.counterLoaded && this.props.trade.items.single !== null) {
            this.updateColumns();
        }
    }

    get isTradeOwner() {
        if (this.props.trade && this.props.trade.items.single && this.props.user) {
            return this.props.trade.items.single.ownerId.trim() === this.props.user.trim();
        } else {
            return undefined;
        }
    }

    isBidOwner(bid) {
        if (bid && bid.fromCompanyID) {
            return bid.fromCompanyID.trim() === this.props.user.trim();
        } else {
            return undefined
        }
    }

    getColumnTitle(index, bid, bidArray) {
        const RTB_BUYER_NO_PRICE = bidArray[0].requestType === 'BUY' && !bidArray[0].price && this.isTradeOwner;
        const RTB_BUYER_WITH_PRICE = bidArray[0].requestType === 'BUY' && bidArray[0].price && this.isTradeOwner;
        const RTB_SELLER_NO_PRICE = bidArray[0].requestType === 'BUY' && !bidArray[0].price && !this.isTradeOwner;
        const RTB_SELLER_WITH_PRICE = bidArray[0].requestType === 'BUY' && bidArray[0].price && !this.isTradeOwner;
        const RTS_SELLER = bidArray[0].requestType === 'SELL' && this.isTradeOwner;
        const RTS_BUYER = bidArray[0].requestType === 'SELL' && !this.isTradeOwner;

        const isBidOwner = this.isBidOwner(bid);
        const isReview = bid.status === BID_STATUS.REVIEW;
        const isBankOnly = bid.status === BID_STATUS.BANK_ONLY_NEW || bid.status === BID_STATUS.BANK_ONLY_COUNTERED;
        const previousBid = this.props.bids.find(item => item.ID === bid.previousBidId);
        let isPriceImproved = false;
        if(bid.previousBidId !== "0"){
            if([BID_STATUS.NEW, BID_STATUS.COUNTERED].includes(bid.status)) isPriceImproved = previousBid && previousBid.status === BID_STATUS.IMPROVED;
            if(bid.status === BID_STATUS.IMPROVED) isPriceImproved = previousBid && previousBid.status !== BID_STATUS.COUNTERED;
        }
        const isSubjectConfirmed = bid.status === BID_STATUS.NEW || bid.status === BID_STATUS.COUNTERED;

        let title = "";
        let reviewAndCounterTitle = isReview ? bid.reCounterInd ? "Review Improved Price" : "Review Counter" : isBidOwner ? "Counter Sent" : "Counter Received";
        let reviewTitle = isReview ? bid.reCounterInd ? "Review Improved Price" : "Review Counter" : "Counter Sent";

        if (RTB_BUYER_NO_PRICE) {
            switch (index) {
                case 0: title = "Initial Bid"; break;
                case 1: title = "Offer Received"; break;
                case 2: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : reviewTitle; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : reviewAndCounterTitle
            }
        } else if (RTB_BUYER_WITH_PRICE) {
            switch (index) {
                case 0: title = "Initial Bid"; break;
                case 1: title = "Counter Received"; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received": reviewAndCounterTitle
            }
        } else if (RTB_SELLER_NO_PRICE) {
            switch (index) {
                case 0: title = "Initial Bid Received"; break;
                case 1: title = isReview ? "Review Offer" : "Offer Sent"; break;
                case 2: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : (isReview && bid.reCounterInd) ? "Review Improved Price" : "Counter Received"; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : (isReview && bid.reCounterInd) ? "Review Improved Price" : reviewAndCounterTitle
            }
        } else if (RTB_SELLER_WITH_PRICE) {
            switch (index) {
                case 0: title = "Initial Bid Received"; break;
                case 1: title = reviewTitle; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received": reviewAndCounterTitle
            }
        } else if (RTS_SELLER) {
            switch (index) {
                case 0: title = "Initial Offer"; break;
                case 1: title = isBankOnly ? "Bank Details Received" : isSubjectConfirmed ? "Acceptance Received Pending Confirmation" : "Counter Received"; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : isBankOnly ? isBidOwner ? "Bank Details Rejected" : "Bank Details Received" : reviewAndCounterTitle
            }
        } else if (RTS_BUYER) {
            switch (index) {
                case 0: title = "Initial Offer"; break;
                case 1: title = isBankOnly ? "Bank Details Sent" : isSubjectConfirmed ? "Acceptance Sent Pending Confirmation" : reviewTitle; break;
                default: title = isPriceImproved ? isBidOwner ? "Improved Price Sent" : "Improved Price Received" : isBankOnly ? isBidOwner ? "Bank Details Sent" : "Bank Details Rejected" : reviewAndCounterTitle
            }
        }

        return title;
    }


    updateColumns() {
        const trade = this.props.trade.items.single;
        const nbDynamic = 2;
        let nbFixed = 2;
        if (!trade.price) {
            nbFixed = 3;
        }
        let bids = [];
        if (this.props.bids) {
            bids = [...this.props.bids];
            bids.reverse();
        }
        if (trade) {
            bids.unshift(trade);
        }
        if (this.props.counterLoaded) {
            bids.push(this.props.counterLoaded);
        }
        const fix = bids.splice(0, nbFixed);
        const dyn = bids.splice(-nbDynamic, nbDynamic);
        bids = fix.concat(dyn);
        const series = bids.map((bid, index, bidArray) => {
            const title = this.getColumnTitle(index, bid, bidArray);
            let updatedBid = {...bid};
            const origins = trade ? trade.origin.split(',') : [];
            if(trade && trade.privacyType==="TENDER" && origins.length > 1){           
                if(index === 0){
                    origins.forEach((origin) => {
                        updatedBid[`price${origin}`] = updatedBid.price;
                        updatedBid[`loadPort${origin}`] = '';
                    })
                }else{
                    updatedBid.multipleOriginPrice.forEach(x => {
                        updatedBid[`price${x.origin}`] = x.price;
                        updatedBid[`loadPort${x.origin}`] = x.loadPort;
                    })  
                }
            }
            return { title: title, data: updatedBid }
        });
        this.setState({ series: series });
    }

    /** Render functions **/
    tdClass(value, serie) {
        let className = "";
        if (serie.data.status === BID_STATUS.REVIEW) {
            className = "cell-review";
        }
        return className;
    }

    tdClassOption(value, serie) {
        let className = this.tdClass(value, serie);
        if (className !== "") {
            className = "bg-light";
        }
        return className;
    }

    formatExpiryDate = (value, serie) => {
        if (serie.data.bidValidateDate) {
            return moment(serie.data.bidValidateDate).format(DATEFORMATHOURS);
        } else if (serie.data.validateDate) {
            return moment(serie.data.validateDate).format(DATEFORMATHOURS);
        } else {
            return "";
        }

    }

    renderYesNo = value => {
        let res = "";
        if (typeof value === "undefined" || value === null || value === '') {
            res = "-"
        } else {
            res = (value) ? "Yes" : "No";
        }
        return res;
    }


    getCounterpartyCompany() {
        let counterparyCompany = "";
        const trade = this.props.trade.items.single;
        if (trade.requestType === "BUY") {
            if (this.isTradeOwner) {
                counterparyCompany = trade.seller;
            } else {
                counterparyCompany = trade.buyer;
            }
        } else {
            if (this.isTradeOwner) {
                counterparyCompany = trade.buyer;
            } else {
                counterparyCompany = trade.seller;
            }
        }
        this.setState({ counterparyCompany: counterparyCompany })

    }

    renderBankDetails = (bankName, serie) => {
        const isBankOnly = serie.data.status === BID_STATUS.BANK_ONLY_NEW || serie.data.status === BID_STATUS.BANK_ONLY_COUNTERED;
        const RTS_SELLER = this.props.trade.items.single && this.props.trade.items.single.requestType === 'SELL' && this.isTradeOwner;
        const RTS_BUYER = this.props.trade.items.single && this.props.trade.items.single.requestType === 'SELL' && !this.isTradeOwner;
        const isBidOwner = this.isBidOwner(serie.data);
        const isRejectedBank = isBankOnly && ((RTS_SELLER && isBidOwner) || (RTS_BUYER && !isBidOwner));
        if (bankName && isRejectedBank) {
            return (
                <>
                    <del>{bankName}</del> <FontAwesomeIcon className="text-danger" icon={faExclamationCircle} />
                </>
            )
        }
        return bankName;
    }

    render() {
        const {
            availableActions,
            onCancelCounter,
            onEditCounter,
            onSubmitCounter,
            counterLoaded,
            requestLoading,
        } = this.props;

        const trade = (this.props.trade) ? this.props.trade.items.single : null;

        let type = "counter";
        if ((trade && (trade.price === undefined || trade.price === 0 || trade.price === '0')) && this.props.bids.length === 0) {
            type = "offer";
        }
        if(counterLoaded && counterLoaded.reCounterInd) type = "improve";

        return (
            <React.Fragment>
                <div className="row">
                    {this.state.showModal && this.renderModal(this.state.modal)}
                    {this.state.showModalTwo && this.renderModalTwo(this.state.modalTwo)}
                    <div className="col">
                        {this.props.heading && <h3 className="request-dtls__card-heading">{this.props.heading.main}</h3>}
                    </div>
                    {/* {(trade && trade.privacyType !== "TENDER") && (
                        <QuoteOptions 
                            isDeclined={this.props.isDeclined} 
                            selectedBids={this.props.bids} 
                            series={this.state.series}
                            isTradeOwner={this.isTradeOwner}/>)} */}
                </div>
                <div className="row mb-3">
                    {this.props.heading && this.props.heading.flag && !this.props.heading.flag.includes('Bank Details') &&
                        <div className="col-sm-12 col-md">
                            {counterLoaded && <div><strong>In order to validate it please click the 'Submit' button</strong></div>}
                            {!counterLoaded && <div>Counter Sent and Counter Received always show the latest sent or received value</div>}
                        </div>}
                    <div className="col-sm-12 col-md-6 mt-2">
                        <div className="d-flex flex-row-reverse ">
                            {(availableActions.submitCounter && this.props.counterLoaded) &&
                                <ConfirmModalSubmitCounter 
                                    loading={requestLoading}
                                    type={type}
                                    toCompanyName={type === 'COUNTER' ? this.state.counterparyCompany : ''}
                                    onConfirm={onSubmitCounter}
                                />}
                            {availableActions.editCounter && <button className="btn btn-action bc-btn__small bc-btn-radius btn--orange mx-1 " onClick={onEditCounter}>Edit</button>}
                            {availableActions.cancelCounter && <button className="btn btn-action bc-btn__small bc-btn-radius btn--grey ml-2" onClick={onCancelCounter}>Cancel</button>}
                        </div>
                    </div>
                </div>

                <div className="table-responsive mt-1 trade-dtls__table-section" style={{ minHeight: '700px' }}>
                    <Preloader loadingStyle="swirl" loading={this.state.series.length === 0}>
                        <DetailTable
                            series={this.state.series}
                            fieldList={this.state.fieldList}>
                        </DetailTable>
                    </Preloader>
                    {(this.state.series[0] && this.state.showTenderTerms) &&
                        <TradeDocPreviewModal doc={this.state.series[0].data.tenderTermsDoc[0]} onClose={e => this.setState({ showTenderTerms: false })} />}
                </div>
                <div className="d-flex flex-row-reverse mt-2">
                    {(availableActions.submitCounter && this.props.counterLoaded) &&
                        <ConfirmModalSubmitCounter 
                            loading={requestLoading}
                            type={type}
                            toCompanyName={this.state.counterparyCompany}
                            onConfirm={onSubmitCounter}
                        />}
                    {availableActions.editCounter && <button className="btn btn-action bc-btn__small bc-btn-radius btn--orange mx-1 " onClick={onEditCounter}>Edit</button>}
                    {availableActions.cancelCounter && <button className="btn btn-action bc-btn__small bc-btn-radius btn--grey ml-2" onClick={onCancelCounter}>Cancel</button>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        requestLoading: state.loading.requestLoading,
        account: state.account
    };
};

export default connect(mapStateToProps, null)(BidDetail);