import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {
    year = new Date().getFullYear();

    render() {
        return (
            <React.Fragment>
                <div className="footer">
                    <div> Copyright &copy; Fertilizer Exchange {this.year}</div>
                    <div className="footer-link">
                    <span><a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/6/topic/125a7529-dff8-40b1-a386-7eb7a0a39eaf" target="_blank" rel="noopener noreferrer" >FAQ</a></span> | <span><a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/6/topic/71a44d10-0317-4475-a5a6-367ebad4a219" target="_blank" rel="noopener noreferrer">How To</a></span> 
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Footer;

