import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import { EnumsService } from '../../../services';
import FormAsyncSelectPort from './FormAsyncSelectPort';

const FormMultipleOriginPrice = ({value, name, onChange, currency, customStyle, disabled, showPortAndCountries=false, defaultDisabledOrigins=[]}) => {
    if(value.value && value.value.length > 1){
        const Countries = EnumsService.countries();      
        return(
            <React.Fragment>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className={customStyle.wrapperClassName}>
                        <label htmlFor={name} className={customStyle.labelClassName}>
                            {value.label}
                            {value.required && <span className="req-field">&nbsp;*</span>}
                        </label>
                        {value.value.map(item => (
                        <div className="input-group mb-3" key={'price'+item.origin}>
                            <span className="input-group-text input-group-text-no-radius">
                                <input 
                                    type="checkbox"
                                    style={{cursor: 'pointer'}} 
                                    name={item.origin} 
                                    value={item.isPriceCheck}
                                    checked={item.isPriceCheck} 
                                    onChange={e => onChange('isPriceCheck', e.target.checked, item.origin, {price: '', loadCountry: '', loadPort: ''})}
                                    disabled={disabled || defaultDisabledOrigins.includes(item.origin)}/>&nbsp;
                                {Countries[item.origin]}
                            </span>
                            <NumberFormat 
                                type="text" 
                                className={`${customStyle.inputClassName} ${item.isInvalidPrice ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                                name={'price'+item.origin}
                                value={item.price} 
                                onValueChange={values => onChange('price', values.value, item.origin, {})}
                                displayType={'input'} 
                                required={false}
                                disabled={disabled || !item.isPriceCheck}
                                thousandSeparator={true}
                                decimalScale={2}
                                allowEmptyFormatting={false}
                                isNumericString={true}
                                allowNegative={false}
                                isAllowed={(values) => {
                                    // RDEV-1962 - The variable 'value' is a field of the object 'values'. Please don't rename it!
                                    const {floatValue, value  } = values;
                                    return value  === "" || (floatValue>0 && floatValue <= 1000000);
                                }}/>
                                <span className="input-group-text input-group-text-no-radius">{currency}</span>
                        </div>))}
                    </div>
                </div>
                {showPortAndCountries && (
                <React.Fragment>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                        <div className={customStyle.wrapperClassName}>
                            <label htmlFor="loadCountry" className={customStyle.labelClassName}>
                                Country of Load <span className="req-field">&nbsp;*</span>
                            </label>
                            {value.value.map(item => (
                                <div className="input-group mb-3" key={'loadCountry'+item.origin} id="loadCountry">
                                    <select
                                        className={`${customStyle.selectClassName} ${item.isInvalidLoadCountry ? customStyle.isInvalidClassName : customStyle.isValidClassName}`}
                                        name={"country" + item.origin}
                                        value={item.loadCountry}
                                        id={"country" + item.origin}
                                        disabled={disabled|| !item.isPriceCheck || defaultDisabledOrigins.includes(item.origin)}
                                        required={false}
                                        onChange={e => onChange('loadCountry', e.target.value, item.origin, {loadPort: ''})}>
                                            <option value="" disabled={true} hidden={true} defaultValue="">Select</option>
                                            {Object.keys(Countries).map(isoCode => (
                                                <option key={isoCode} value={isoCode}>{Countries[isoCode]}</option>
                                            ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                        <div className={customStyle.wrapperClassName}>
                            <label htmlFor="loadPort" className={customStyle.labelClassName}>
                                Port of Load <span className="req-field">&nbsp;*</span>
                            </label>
                            {value.value.map(item => {
                                const validation = {};
                                if(item.isInvalidLoadPort){
                                    validation["port"+item.origin] = true;
                                }
                                return(
                                    <div className={"mb-3"} key={'loadPort'+item.origin} id="loadPort">
                                        <FormAsyncSelectPort 
                                            fieldData={{
                                                name: "port"+item.origin,
                                                value: item.loadPort,
                                                touched: true
                                            }}
                                            onChange={(field, entry, fromDropdown) => {
                                                if(fromDropdown){
                                                    onChange('loadPort', entry, item.origin, {loadCountry: entry.slice(0,2)})
                                                }else{
                                                    onChange('loadPort', entry, item.origin, {})
                                                } 
                                            }}
                                            country={item.loadCountry}
                                            validation={validation}
                                            styles={{
                                                indicatorsContainer: (provided) => ({...provided, height: 31})
                                            }}
                                            disabled={disabled || !item.isPriceCheck || defaultDisabledOrigins.includes(item.origin)}
                                        />       
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment>)}
            </React.Fragment>
        )
    }
    return null
}

FormMultipleOriginPrice.propTypes = {
    value: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    currency: PropTypes.string,
    customStyle: PropTypes.object,
    countries: PropTypes.object,
    showPortAndCountries: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultDisabledOrigins: PropTypes.arrayOf(PropTypes.any)
}

export default FormMultipleOriginPrice;