import React from 'react';
import './FormSelectDropdown.scss';
import { orderByValue } from '../../../services/service.utils';
import { Tooltip } from '@material-ui/core';

class FormSelectDropdown extends React.Component {


  defaultStyle = {
    wrapperClassName: "form-group",
    labelClassName :"label",
    inputClassName : "input",
    isInvalidClassName: "select_error select_error--dropdown",
    isValidClassName: ''
  } 

  render() {
    const { 
      name,  
      value, 
      items, 
      onSelect, 
      validation, 
      disabled, 
      showDefault,
      captionText,
      disableDefault=true,
      keyLabel="",
      defaultValue ="",
      defaultLabel = "Select",
      customStyle,
      sortKeys=true,
      isShowToolTipLabel,
      toolTipTitle
    } = this.props;


    const style = {...this.defaultStyle, ...customStyle };
    const isInvalid = validation && validation.hasOwnProperty(name);

    if(!items){
      return false;
    }else{
      
      return (
        <div className={style.wrapperClassName}>
          <label className={style.labelClassName} 
            htmlFor={name}>
            {isShowToolTipLabel ? <span><Tooltip title={toolTipTitle} placement="top-start" arrow>
              <span>{value.label} {value.required && <span className="req-field">&nbsp;*</span>}&nbsp;
                        </span>
            </Tooltip></span>
              : <span> {value.label} {value.required && <span className="req-field">&nbsp;*</span>}</span>}
          </label>
          <select
            className={`${style.selectClassName}
                        ${value.value ? "value" : "placeholder"} 
                        ${isInvalid ? customStyle.isInvalidClassName : (value.touched || value.validated) ? customStyle.isValidClassName : ''}`}
            name={name}
            id={name}
            required = {!!value.required}
            disabled={disabled}
            onChange={e => onSelect(e)}
            value={(value.value !== null && value.value !== undefined) ? value.value : ''}
          >
            <option value="" disabled={disableDefault} hidden={!showDefault} defaultValue={defaultValue} >{defaultLabel}</option>
            {
              sortKeys ? Object.keys(orderByValue(items)).map(i => {
                return ( <option key={i} value={i}>{(keyLabel)?items[i][keyLabel] :items[i]}</option> );
              }) : Object.keys(items).map(i => {
                return ( <option key={i} value={i}>{(keyLabel)?items[i][keyLabel] :items[i]}</option> );
              })
            }
          </select>
          { captionText && <small id={`${name}Help`} className="form-text text-muted">{captionText}</small>}
        </div>
      );
    }

  }
}

export default FormSelectDropdown;
