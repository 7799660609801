import React from 'react';
import {Bar} from 'react-chartjs-2';

export const MONTHS = ["Jan","February","March","April","May","June","July", "Aug","Sep", "Oct","Nov","Dec"];

class RiceIndexGraph extends React.Component {

	 render() {
 
    const months = [];
    const monthSale = [];
    const monthPurchase = [];

    if(this.props.dashboard){
    let date = new Date();
    date.setMonth(new Date().getMonth()-5);
    let dateObj = date.getMonth() +1 ; 
      if(this.props.dashboard.monthlySale) {
        Object.keys(this.props.dashboard.monthlySale).map(key => {
          monthSale.push(this.props.dashboard.monthlySale[dateObj]);
          months.push(MONTHS[dateObj-1]); 
          date.setMonth(date.getMonth()+1);
          dateObj = date.getMonth()+1;
          return false;
        });
      }
    }

  if(this.props.dashboard){
    let date = new Date();
    date.setMonth(new Date().getMonth()-5);
    let dateObj = date.getMonth() +1 ;
    if(this.props.dashboard.monthlyPurchase) {
      Object.keys(this.props.dashboard.monthlyPurchase).map(key => {
        monthPurchase.push(this.props.dashboard.monthlyPurchase[dateObj]);
        date.setMonth(date.getMonth()+1);
        dateObj = date.getMonth()+1;
        return false;
      });
    }
  }
    const data = {
      labels:  months,
      datasets: [{
          label: 'Sales',
          type:'line',
          data: monthSale,
          fill: false,
          borderColor: '#EC932F',
          backgroundColor: '#EC932F',
          pointBorderColor: '#EC932F',
          pointBackgroundColor: '#EC932F',
          pointHoverBackgroundColor: '#EC932F',
          pointHoverBorderColor: '#EC932F',
          yAxisID: 'y-axis-2'
        },{
          type: 'line',
          label: 'Purchase',
          data: monthPurchase,
          fill: '+2',
          backgroundColor: '#71B37C',
          borderColor: '#71B37C',
          hoverBackgroundColor: '#71B37C',
          hoverBorderColor: '#71B37C',
          yAxisID: 'y-axis-1'
        }]
    };
    
    const options = {
      responsive: true,
      tooltips: {
        mode: 'label'
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
              display: false
            }
          },
          {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
              display: false
            }
          }
        ]
      }
    };
    
    const plugins = [{
        afterDraw: (chartInstance, easing) => {
            const ctx = chartInstance.chart.ctx;
            ctx.fillText("", 100, 100);
        }
    }];

		return (
	  <div>
        <Bar
          data={data}
          options={options}
          plugins={plugins} width={450} height={130}
        />
      </div>
		);
	}
}
export default RiceIndexGraph; 