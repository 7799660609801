import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../../components/preloader/Preloader';
import FormTextareaField from '../../../components/form/FormTextareaField';
import MaterialTable from 'material-table';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import moment from 'moment';
import { DATEFORMATHOURS } from '../../../../services/service.values';
import { LOADING_PT_PROCESS } from '../services/documents.service';

class PaymentTermDocumentModal extends React.Component {

    get draftType() {
        return this.props.trade.payment === 'Letter of credit' ? 'L/C Draft' : this.props.trade.payment === 'Confirmed letter of credit' ? 'Confirmed L/C Draft' : 'Avalised Draft'
    }

    render() {
            return (
                <div className="modal__container">
                    <div className="modal__wrapper px-0 py-0">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title w-100">
                                    {`${this.draftType}`}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onClose} >
                                    <span aria-hidden="true" >&times;</span>
                                </button>
                            </div>
                            <div className="modal-body py-0">
                                <div id="lc-image-content">
                                    {this.props.showRequestedChanges && (
                                       <MaterialTable
                                            columns={[
                                                { title: 'Change Request Date', field: 'createdAt',defaultSort: 'desc', render: rowData => <span>{moment(rowData.createdAt).format(DATEFORMATHOURS)}</span> },
                                                { title: 'Change Request', field: 'text' }
                                            ]}
                                            data={this.props.ptDocDetails.requestedChanges}
                                            title=""
                                            icons={{
                                                SortArrow: ArrowUpward
                                            }}
                                            options={{
                                                search: false,
                                                paging: false,
                                                disabled: true,
                                                sorting: true,
                                                thirdSortClick: false
                                            }}
                                        />
                                    )}
                                    {(this.props.ptDocDetails.ptType === 'text' && !this.props.showRequestedChanges) && (
                                            <MaterialTable
                                                columns={[
                                                    {
                                                        title: 'Created_At', field: 'createdAt', defaultSort: 'desc',
                                                        render: rowData => <span>{moment(rowData.createdAt).format(DATEFORMATHOURS)}</span>
                                                    },
                                                    { title: 'Additional Info', field: 'additionalInformation',
                                                    render: rowData => <span>{rowData.additionalInformation}</span>
                                                     },
                                                    { title: 'Draft Text', field: 'text',
                                                    render: rowData => <span>{rowData.text}</span> }

                                                ]}
                                                data={this.props.ptDocDetails.ptText}
                                                title=""
                                                icons={{
                                                    SortArrow: ArrowUpward
                                                }}
                                                options={{
                                                    search: false,
                                                    paging: false,
                                                    disabled: true,
                                                    sorting: true,
                                                    thirdSortClick: false
                                                }}
                                            />
                                    )}
                                    {(this.props.ptDocDetails.ptType === 'document' && !this.props.showRequestedChanges) && (
                                        <React.Fragment>
                                            <div className="form-group">
                                                <FormTextareaField
                                                    name="ptText"
                                                    type="text"
                                                    value={{
                                                        label: "Current Document Name",
                                                        required: false,
                                                        value: `${this.props.ptDocDetails.files[0].fileName}` === "undefined" ?
                                                            `${this.props.ptDocDetails.files[0].name}` :
                                                            `${this.props.ptDocDetails.files[0].fileName}`

                                                    }}
                                                    onChange={() => { }}
                                                    rows="4"
                                                    disabled={true} />
                                            </div>
                                            <div className="form-group">
                                                <FormTextareaField
                                                    name="additionalInformation"
                                                    type="text"
                                                    value={{
                                                        label: 'Additional information',
                                                        required: false,
                                                        value: `${this.props.ptDocDetails.files[0].remark}`


                                                    }}
                                                    onChange={() => { }}
                                                    rows="4"
                                                    disabled={true} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className={`btn-popup btn-cancel`} type="button" onClick={this.props.onClose} >
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Cancel
                            </button>
                            <button className={`btn-popup btn-success`} type="button" onClick={() => this.props.downloadPaymentTermDoc('preview')}>
                                <Preloader loadingStyle="dots" loading={this.props.loading === LOADING_PT_PROCESS.PREVIEW}>
                                    <FontAwesomeIcon icon={faEye} />&nbsp;Click to Preview Details
                                </Preloader>
                            </button>

                        </div>
                    </div>
                </div>
            );
    }
}

export default PaymentTermDocumentModal;