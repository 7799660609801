import React from 'react';
import {renderToString} from 'react-dom/server'
import moment from 'moment';
import _ from 'lodash';

import { 
    DATEFORMATHOURS,
    DATEFORMAT,
    DISCHARGE_VALUES,
    getDischargeLabel,
    getDischargeRateLabel,
    CURRENCYOPT,
    SHIPPINGTYPES,
    PAYMENTPERIODOPT,
    PACKAGING,
    NON_INTEGRATED_INSPECTORS

} from '../../../../../services/service.values';

import {
    PortService,
    renderSelectedOrigins,
    EnumsService
} from '../../../../../services/service.utils';

import { DocumentFactory } from './../../../trades/services/documents.service';
import Tooltip from '@material-ui/core/Tooltip';
import { RxXsFab } from './../../../../components/custom/customFab';
import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';
import { FertilizerService } from '../../../../../services/service.fertilizer';
import NumberFormat from 'react-number-format';
import OtherInspectorFormatter from '../../../../components/Formatter/OtherInspectorFormatter';
import TradePriceFormatter from '../../../../components/Formatter/TradePriceFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PriceBreakdownFormat from '../../../../components/Formatter/PriceBreakdownFormat';

/**
 * RDEV-1813 Restructure detail table to be generic accross the screens Info, TenderTenmplate, Review, AuditLog ....
 * Find here the default renderers for the bid detail table
  * Render functions receive 4 args 
  * @param {string} 
  * @param {array} serie of object  title<string>, data: <data of the serie>}}
  * @param {number} index of the serie
  * @param {array} series, array of series
  */

const COUNTRIES = EnumsService.countries();
/* export const renderBankDetails = (bankName, serie, array, options) => {
    const isBankOnly = serie.data.status === 'BANK_ONLY_COUNTERED';
    const RTS_SELLER = trade.requestType === 'SELL' && isTradeOwner;
    const RTS_BUYER = trade.requestType === 'SELL' && !isTradeOwner;
    const isBidOwner = serie.data.fromCompanyID === user.companyId;
    const isRejectedBank = isBankOnly && ((RTS_SELLER && isBidOwner) || (RTS_BUYER && !isBidOwner));
    if(bankName && isRejectedBank){
        return(
            <>
                <del>{bankName}</del>
            </>
        )
    }
    return bankName;
} */


export const renderDate = value => value?moment(value).format(DATEFORMAT):"" 
export const renderDateTime = value => value?moment(value).format(DATEFORMATHOURS):"" 

/** Render company name. 
* For the bid objects: The company name of the initiator of the bid
* For the trade objects: The company name of the initiator of the trade
*/
export const renderFromCompanyName =  (value, serie) => {
    if(serie.data.fromCompanyName)
        return serie.data.fromCompanyName
    else
        return serie.data.requestType === "BUY"?serie.data.buyer:serie.data.seller
}

/** Render company of the recipient. 
* For the bid objects: The company name of the counterparty to witch the bid is addressed
* For the trade objects: The company name of the counterparty of the trade
*/
export const renderToCompanyName =  (value, serie) => {
    if(serie.data.toCompanyName)
        return serie.data.toCompanyName
    else
        return serie.data.requestType === "SELL"?serie.data.buyer:serie.data.seller
}

export const renderCountry = (isoCodes) => isoCodes ? renderSelectedOrigins(isoCodes) : "-";

export const renderPaymentPeriod = (value => PAYMENTPERIODOPT[value]) 

export const renderPrice =  (value, serie, index, series) => {
    if(!value && serie.data.bestPrice) return 'Best Price';

    const serieData = {...serie.data};
    serieData.price = serieData.price || "0";
    let currencyChange = false;
    if (index > 0) {
        currencyChange = series[index - 1].data.currency !== serie.data.currency;
    }

    let breakDownDetails = '';
    if(serieData.priceBreakdown){
        const priceBreakdown = serieData.priceBreakdown;
        if((priceBreakdown.fob + priceBreakdown.freight + priceBreakdown.insurance) > 0){
            if(serieData.incoterm !== "FOB"){
                const fob = renderToString(<NumberFormat displayType="text" value={priceBreakdown.fob} thousandSeparator renderText={text => text}/>)
                const freight = renderToString(<NumberFormat displayType="text" value={priceBreakdown.freight} thousandSeparator renderText={text => text}/>)
                if(serieData.incoterm === "CFR"){
                    breakDownDetails = ` (${fob}+ ${freight})`
                }
                if(serieData.incoterm === "CIF"){
                    const insurance = renderToString(<NumberFormat displayType="text" value={priceBreakdown.insurance} thousandSeparator renderText={text => text}/>)
                    breakDownDetails = ` (${fob}+${freight}+${insurance})`
                }
            }
        }
    }
    return (
        <>
            <TradePriceFormatter 
                trade={serieData}
                prefix={`${breakDownDetails} ${CURRENCYOPT[serieData.currency] || "USD"} `} />&nbsp;
            {currencyChange &&
                <Tooltip title="Currency has changed">
                    <span>
                        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />
                    </span>
                </Tooltip>}
        </>
    )
} 

export const renderShipping = value => (SHIPPINGTYPES[value]||value)

export const renderMeasure =  (value, serie) => <TradeQuantityFormatter trade={serie.data} />;

export const renderPackaging = value => (PACKAGING[value] || value )

export const renderAcceptedAt = (value, serie) => {
    if(serie.data.acceptedByCompanyId){
        return moment(value).format(DATEFORMATHOURS)
    }else{
        return "-"
    }
}

export const renderDeclineAt = (value, serie) => {
    if(serie.data.declinedByCompanyId){
        return moment(value).format(DATEFORMATHOURS)
    }else{
        return "-"
    }
}

export const renderPort = value => {
    let label = "";
    if (value) {
        label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
    }
    return label;
}

export const renderDischarge = (value, serie) => {
    if (value) {
        const incoterm = serie.data.incoterm;
        const formatedValue = DISCHARGE_VALUES[value];
        return `${getDischargeLabel(incoterm)}: ${formatedValue}`
    } else {
        return "";
    }
}

export const renderDischargeRate = (value, serie) => {
    if (value) {
        const incoterm = serie.data.incoterm;
        const discharge = serie.data.discharge;
        return `${getDischargeRateLabel(incoterm, discharge)}: ${value}`
    } else {
        return "";
    }
}

// Format the list of document, return an array of div containing the document's title
export const genericRenderDocument = (value, serie, documentSelector) => {
    const docs = documentSelector(serie.data)
    if (Array.isArray(docs) && docs.length !==0) {  
        return ( docs.map(doc => 
            <div className='row m-0' key={doc.title}>
                <div className='col p-0'>
                    {doc.title}
                </div>
                <div className='col-auto p-0'>
                    { doc.uploadedBy === 'SELLER' &&
                    <Tooltip title='To be uploaded by Seller' aria-label='To be uploaded by Seller' placement="top-start" arrow>
                        <RxXsFab classes={{root: 'bg-primary text-white'}}>
                            <i>S</i>
                        </RxXsFab>
                    </Tooltip>}
                    { doc.uploadedBy === 'CONTRACTUAL_INSPECTOR' && 
                    <Tooltip title="To be uploaded by Contractual Inspector" placement="top-start" arrow>
                        <RxXsFab classes={{root: 'bg-danger text-white'}}>
                        <i>I</i>
                        </RxXsFab>
                    </Tooltip>}
                </div>
            </div>))
    } else {
        return "-";
    }  
}

export const renderDocuments = (value, serie) => {
    return genericRenderDocument(value, serie, DocumentFactory.getSelectedFEDocumentList)
}

export const renderDocumentsForm = (value, serie) => {
    return genericRenderDocument(value, serie, DocumentFactory.getSelectedFormFEDocumentList)
}



// Format the title of the documents section with an index
export const renderDocumentsTitle = () => {
    return <div>Document/Uploader Selection
        <div><RxXsFab classes={{root: 'bg-primary text-white mx-2'}}><i>S</i></RxXsFab> <small>(Uploaded by Seller)</small></div>
        <div><RxXsFab classes={{root: 'bg-danger text-white mx-2'}}><i>I</i></RxXsFab> <small>(Uploaded by Contractual Inspector)</small></div>
    </div>
}

// Format the options value adding the discount price with the sign and the curency
export const formatOption = (optionTYpe, discountValue, discountCurrency) => {
    let sign = (discountValue > 0) ? '+' : '';
    return optionTYpe?<React.Fragment><div>{optionTYpe}</div><div>{`${sign}${discountValue} ${CURRENCYOPT[discountCurrency] || "USD"}`}</div></React.Fragment>:"-";
}
    
// Display the Expiry date. Expiry date is set in the field 'validateDate' in the trade object, and in 'bidValidateDate' in the bid object
export const formatExpiryDate = (value, serie) => {
    if (serie.data.contractValidateDate) {
        return moment(serie.data.contractValidateDate).format(DATEFORMATHOURS);
    }
    if (serie.data.bidValidateDate) {
        return moment(serie.data.bidValidateDate).format(DATEFORMATHOURS);
    }
    if (serie.data.validateDate) {
        return moment(serie.data.validateDate).format(DATEFORMATHOURS);
    }
    return "";
    
};

export const renderFertilizerSpecifications = (specs, fertilizerType, isContract) => {
    const initialSpecs = FertilizerService.getSpecifications(fertilizerType);

    const withoutCustoms = _.chain(specs)
                        .map(item => {
                            const defaultSpec = _.find(initialSpecs, spec => spec.name === item.name);
                            if(defaultSpec){
                                return {
                                    ...item, 
                                    label: defaultSpec.label, 
                                    inputType: defaultSpec.inputType
                                }
                            }
                            return {
                                ...item,
                                inputType: isNaN(+item.value) ? "input" : "number"
                            }
                        })
                        .filter(item => !_.includes(['custom', 'minParticleSize', 'maxParticleSize', 'maxInhibitor', 'maxPH', 'maxFreeAmmonia'], item.name))
                        .reduce((init, item) => {
                            let specLabel = item.label;
                            if(item.inputType === 'number'){
                                let suffix = item.unit ? ` ${FertilizerService.enums.UNITS[item.unit]}` : '';
                                switch(item.name){
                                    case 'granulometry':{
                                            const minParticle = _.find(specs, min => min.name === 'minParticleSize');
                                            const maxParticle = _.find(specs, max => max.name === 'maxParticleSize');
                                            const minParticleValue = renderToString(
                                                <NumberFormat 
                                                    value={minParticle.value} 
                                                    thousandSeparator={true} 
                                                    displayType="text"
                                                    renderText={text => text}
                                                />)
                                            const maxParticleValue = renderToString(
                                                <NumberFormat 
                                                    value={maxParticle.value} 
                                                    thousandSeparator={true} 
                                                    displayType="text"
                                                    renderText={text => text}
                                                />)
                                            suffix = suffix + ` (${minParticleValue} - ${maxParticleValue} ${FertilizerService.enums.UNITS[minParticle.unit]})`
                                        }
                                        break;
                                    case 'minInhibitor':
                                    case 'minPH':
                                    case 'minFreeAmmonia':{
                                            specLabel = item.name === 'minFreeAmmonia' ? 'Free Ammonia' : item.name === 'minInhibitor' ? 'Inhibitor' : item.name === 'minPH' ? 'pH Value' : item.label
                                            const maxSpec = _.find(specs, max => max.name === ('max' + item.name.slice(3)));
                                            const maxSpecValue = renderToString(
                                                <NumberFormat 
                                                    value={maxSpec.value} 
                                                    thousandSeparator={true} 
                                                    displayType="text"
                                                    renderText={text => text}
                                                />
                                            )
                                            suffix = ` - ${maxSpecValue}${item.unit ? ` ${FertilizerService.enums.UNITS[item.unit]}` : ''}`;
                                        }
                                        break;
                                    default:
                                        break;     
                                }
                                init.push({
                                    specLabel,
                                    specValue: (
                                        <NumberFormat 
                                            value={item.value}
                                            thousandSeparator={true}
                                            displayType="text"
                                            renderText={text => text}
                                            suffix={suffix}
                                        />
                                    )
                                })
                            }else{
                                init.push({specLabel, specValue: item.value});
                            }
                        return init;
                    }, [])
                    .value();

    const withCustoms = _.chain(specs)
                        .filter(item => item.name === 'custom')
                        .reduce((init, item) => {
                            init.push({specLabel: item.label, specValue: item.value});
                            return init;
                        }, [])
                        .value();

    const allSpecs = [...withoutCustoms, ...withCustoms];
    if(allSpecs.length > 0){
        return(
            <React.Fragment>
                <ul className={`mb-0 ${!isContract ? 'pl-3' : ''}`}>
                    {_.map(allSpecs, (item, index) => (
                        <li key={index}>
                            <strong>{item.specLabel}:&nbsp;</strong>{item.specValue}
                        </li>
                    ))}
                </ul>
                {(fertilizerType !== "UREA_AMMONIUM_NITRATE") && (
                    <div className={isContract ? 'pl-4' : ''}>
                        <strong>Free flowing, free from harmful substances</strong>
                    </div>
                )}
            </React.Fragment>    
        )
    }
    return "-"
}

export const renderInspectionCompany = (key, inspections, value, serie) => {
    let inspectionCompany = null;
    if(serie.data.inspection === "1"){
        inspectionCompany = <OtherInspectorFormatter inspector={NON_INTEGRATED_INSPECTORS[serie.data.inspectionOther]}/>
    }else{
        inspectionCompany = key === 'inspectionName' ? value : inspections[value.trim()];
    }
    
    return inspectionCompany;
}

export const renderMultipleOriginPrice = (origin, value, serie, index) => {
    if(!value && serie.data.bestPrice) return 'Best Price';
    
    const currency = `${CURRENCYOPT[serie.data.currency] || "USD"}`;
    let priceBreakDown = null;
    let originPrice = value;
    if(index > 0){
        let originData = serie.data.multipleOriginPrice.find(item => item.origin === origin);
        originPrice = originData.isPriceCheck ? value : "-";
        const showBreakdown = serie.data.incoterm !== 'FOB' && originData.priceBreakdown && Object.entries(originData.priceBreakdown).reduce((sum, entry) => sum + entry[1], 0) > 0;
        if(showBreakdown){
            const fobAndFreight = (
                <>
                    <NumberFormat
                        prefix={`FOB: ${currency} `} 
                        displayType="text"
                        value={originData.priceBreakdown.fob}
                        renderText={text => <div>{text}</div>}
                        thousandSeparator={true}
                    />
                    <NumberFormat
                        prefix={`Freight: ${currency} `} 
                        displayType="text"
                        value={originData.priceBreakdown.freight}
                        renderText={text => <div>{text}</div>}
                        thousandSeparator={true}
                    />
                </>
            )
            if(serie.data.incoterm === 'CFR'){
                priceBreakDown = fobAndFreight;
            }else{
                priceBreakDown = (
                    <>
                        {fobAndFreight}
                        <NumberFormat
                            prefix={`Insurance: ${currency} `} 
                            displayType="text"
                            value={originData.priceBreakdown.insurance}
                            thousandSeparator={true}
                            renderText={text => <div>{text}</div>}
                        />
                    </>
                )
            }
        }
    }

    return(
        <PriceBreakdownFormat 
            price={<NumberFormat 
                displayType="text"
                value={originPrice}
                prefix={`${currency} `}
                thousandSeparator={true}
            />}
            breakdown={priceBreakDown}
        />
    )
}

export const renderMultiOriginLoadPort = (origin, port, serie, index) => {
    if(index === 0) return "-";

    let originData = serie.data.multipleOriginPrice.find(item => item.origin === origin);
    return originData.isPriceCheck && serie.data.incoterm === "FOB" ? `${COUNTRIES[originData.loadCountry]} (${renderPort(port)})` : '-';
}

export const renderGoverningCountry = (country) => {
    return COUNTRIES[country] || '-';
}

export const renderTolerance = (value, serie) => {
    if(value === 0) return `Min/Max in option of ${serie.data.incoterm === "FOB" ? "Buyer" : "Seller"}`;
    return `${value}% more or less in option of ${serie.data.incoterm === "FOB" ? "Buyer" : "Seller"}` 
}