import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActiveUserList, insertUserToGroup, getGroupUserList } from '../../modules/module.adminManagement';
import { push } from 'react-router-redux';

import './adminManagement-style.scss';
import '../components/form/FormInputField.scss';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import createTheme from "@material-ui/core/styles/createTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";



class AddUserList extends Component {
  constructor(props) {
    super(props);
    this.theme = createTheme({
      palette: {
        primary: {
          main: '#b6bdf0',
        },
        secondary: {
          main: '#5465dd',
        },
      },

    });

  }

  UNSAFE_componentWillMount = () => {
    this.fetchFunction();
  };

  fetchFunction = () => {
    this.props.getActiveUserList();
    this.props.getGroupUserList(this.props.id);
  };

  getItems = () => {
    let filterUser = this.props.usermanagment.groupUserList;
    let activeUser = this.props.usermanagment.activeUserList;
    let userList = activeUser.filter(u => !(filterUser.some(eu => eu.email === u.email)));
    return userList;
  };

  render() {
    let gropuId = this.props.id;
    return (
      <React.Fragment>
        <section className="rbac-body">
          <MuiThemeProvider theme={this.theme}>
            <MaterialTable
              columns={[

                {
                  title: 'User Name', field: 'firstName',
                  render: rowData => <span >{rowData.firstName} {rowData.lastName} </span>

                },
                { title: 'Email', field: 'email' },

              ]}
              //data={this.getItems()}
              data={this.getItems()}
              title=" "
              actions={[
                {
                  tooltip: 'Save All Selected Users',
                  icon: SaveIcon,
                  onClick: (evt, rowData) => {
                    let users = [];
                    users = rowData.map((user) => ({
                      userID: user.userID,
                      userName: user.firstName + '' + user.lastName,
                      email: user.email
                    }));
                    let postData = {
                      users: users
                    }
                    this.props.insertUserToGroup(gropuId, postData);
                  }
                }
              ]}

              icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                SortArrow: ArrowUpward,
                Add: Add,
                Edit: Edit,
                DeleteOutline: DeleteOutline,
                ResetSearch: Clear,
                SaveIcon: SaveIcon

              }}
              options={{
                selection: true,
                pageSize: 5,
                rowStyle: (rowData, index) => {
                  const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                  return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                },
                thirdSortClick: false
              }}
            />
          </MuiThemeProvider>
        </section>
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    usermanagment: state.usermanagment,
    // user: state.account.user,
    loading: state.loading.requestLoading
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupUserList,
      getActiveUserList,
      insertUserToGroup,
      navigate: path => push('/addUserlist' + path)
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(AddUserList);
