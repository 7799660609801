import React from 'react';
import FaInfoCircle from 'react-icons/lib/fa/info-circle';
import { Link } from 'react-router-dom';
import Preloader from '../../../components/preloader/Preloader';

const dealBar = (trade, trader, action, loading, isTradeOwner) => {
    let render = () => {
        let signed = (trader === 'seller' && trade.requestInfo.signSeller) || (trader === 'buyer' && trade.requestInfo.signBuyer);
        if(trade.items.single.multipleOriginAccept){
            let notSignedByBoth = !trade.requestInfo.signSeller && !trade.requestInfo.signBuyer;
            return notSignedByBoth ? (
                <button 
                    onClick={action}
                    className={`trades-dtls__button ${!isTradeOwner ? 'trades-dtls__button_disabled' : ''}`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{isTradeOwner ? 'Select Origin & Sign Contract' : 'Pending for Signature'}</span>
                        </Preloader>
                </button>
            ) : (
                <button 
                    onClick={action}
                    className={`trades-dtls__button ${isTradeOwner ? 'trades-dtls__button_success' : ''}`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{isTradeOwner ? 'Pending Other Signature' : 'Sign Contract'}</span>
                        </Preloader>
                </button>
            )
        }else{  
            return (
                <button 
                    onClick={action}
                    className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>{signed ? 'Pending Other Signature' : 'Sign Contract'}</span>
                        </Preloader>
                </button>
            );
        }
    };
    return {
        Buyer: render,
        Seller: render
    };
};

const vesselBar = (trade, info, nominate, acceptingNomination, onApproveByBuyer, loading) => {
    const isNominated = info && info.vesselNominated;
    const isLaycanDateChanged = info && info.isLaycanDateChanged;
    const nominateButton = (
        <button onClick={nominate} className={`trades-dtls__button ${isNominated && 'trades-dtls__button_success'}`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Nominate Vessel</span>
            </Preloader>
        </button>
    );

    const nominateAcceptingButton = (
        <button onClick={acceptingNomination} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Manage Vessel Nomination</span>
            </Preloader>
        </button>
    );

    const approveByBuyer = (
        <button onClick={onApproveByBuyer} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Manage Vessel Nomination</span>
            </Preloader>
        </button>
    );

    const waitNominationButton = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait Vessel Nomination</button>);

    const waitApprovalButton = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait Vessel Nomination Approval</button>);

    return {
        Buyer: () => {
            // return trade.incoterm === 'FOB'
            //     ? isNominated
            //         ? waitApprovalButton
            //         : nominateButton
            //     : waitNominationButton;
            if (trade.incoterm === 'FOB') {
                if (isNominated && !isLaycanDateChanged) {
                    return waitApprovalButton;
                } else if (isNominated && isLaycanDateChanged) {
                    return approveByBuyer;
                } else {
                    return nominateButton
                }
            } else {
                return waitNominationButton
            }
        },
        Seller: () => {
            // return trade.incoterm === 'CIF' || trade.incoterm === 'CFR'
            //     ? nominateButton
            //     : isNominated
            //         ? nominateAcceptingButton
            //         : waitNominationButton;
            if (trade.incoterm === 'CIF' || trade.incoterm === 'CFR') {
                return nominateButton
            } else {
                if (isNominated && !isLaycanDateChanged) {
                    return nominateAcceptingButton;
                } else if (isNominated && isLaycanDateChanged) {
                    return waitApprovalButton;
                } else {
                    return waitNominationButton;
                }
            }
        }
    };
};

const proformaInvoiceBar = (openProformaInvoiceModal, navigateToDocuments, trade, loading) => {
    const proformaInvoiceButton = (
        <button onClick={openProformaInvoiceModal} className={`trades-dtls__button`}>
            <Preloader loadingStyle="dots" loading={loading}>
                <span>Fill Out Proforma Invoice</span>
            </Preloader>
        </button>
    );

    const waitProfomraInvoice = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait For Proforma Invoice</button>
    );

    const uploadPaymentReciept = (
        <button onClick={navigateToDocuments} className={`trades-dtls__button`}>
            <span>Confirm Down Payment</span>
        </button>
    );

    const waitPaymentReciept = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait For Down Payment Proof</button>
    );

    const confirmPayment = (
        <button onClick={navigateToDocuments} className={`trades-dtls__button`}>
            <span>View and Confirm Down Payment</span>
        </button>
    );

    const waitPaymentConfirmation = (
        <button className="trades-dtls__button trades-dtls__button_info">Wait for Down Payment Confirmation</button>
    );

    return {
        Buyer: () => {
            if (trade.items.single.status === 'SIGNED') {
                if (!trade.proformaInvoice) {
                    return waitProfomraInvoice;
                } else if (trade.proformaInvoice.status === 'NEW' || trade.proformaInvoice.status === 'EDIT') {
                    return uploadPaymentReciept;
                } else if (trade.proformaInvoice.status === 'UPLOADED' || trade.proformaInvoice.status === 'EFFECTED') {
                    return waitPaymentConfirmation;
                } else if (trade.proformaInvoice.status === 'PAYMENT_NOT_CONFIRMED') {
                    return uploadPaymentReciept;
                }
            }

        },
        Seller: () => {
            if (trade.items.single.status === 'SIGNED') {
                if (!trade.proformaInvoice) {
                    return proformaInvoiceButton;
                } else if (trade.proformaInvoice.status === 'NEW' || trade.proformaInvoice.status === 'EDIT'  || trade.proformaInvoice.status === "PAYMENT_NOT_CONFIRMED") {
                    return waitPaymentReciept
                } else if (trade.proformaInvoice.status === 'UPLOADED' || trade.proformaInvoice.status === 'EFFECTED') {
                    return confirmPayment;
                }
            }

        }
    }
}

const docInstBar = (trade, action, loading) => {
    return {
        Buyer: () => {
            return (
                <button onClick={() => action()} className="trades-dtls__button">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>Send Documentary Instructions</span>
                    </Preloader>
                </button>
            );
        },
        Seller: () => (
            <button className="trades-dtls__button trades-dtls__button_info">Wait For Documentary Instructions</button>
        )
    };
};

const shippingAdvice = (trade, action, loading) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">Wait For Shipping Advice</button>,
        Seller: () => {
            return (
                <button onClick={() => action()} className="trades-dtls__button">
                    <Preloader loadingStyle="dots" loading={loading}>
                        <span>Send Shipping Advice</span>
                    </Preloader>
                </button>
            );
        }
    };
};

const documentsFill = url => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">Documents being prepared</button>,
        Seller: () => (
            <Link to={url}>
                <button className="trades-dtls__button trades-dtls__button_info">
                    <FaInfoCircle className="trades-dtls__info-sign" />Please fill all documents
                </button>
            </Link>
        )
    };
};

const documentsFull = (url, loading) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">Documents filled in</button>,
        Seller: () => (
            <button className="trades-dtls__button trades-dtls__button_info">
                <Preloader loadingStyle="dots" loading={loading}>
                    <React.Fragment>
                        <FaInfoCircle className="trades-dtls__info-sign" />Please fill all{' '}
                        <span>
                            <Link to={url}>documents</Link>
                        </span>
                    </React.Fragment>
                </Preloader>
            </button>
        )
    };
};

const paymentRequired = (trade, action, loading) => {
    return {
        Buyer: () => {
            if (!trade.requestInfo.payed) {
                return (
                    <button onClick={() => action(true)} className={`trades-dtls__button`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>Confirm Payment</span>
                        </Preloader>
                    </button>
                );
            } else {
                return <button className="trades-dtls__button trades-dtls__button_info">Wait for Confirmation</button>;
            }
        },
        Seller: () => {
            if (trade.requestInfo.payed) {
                return (
                    <button onClick={() => action(false)} className={`trades-dtls__button`}>
                        <Preloader loadingStyle="dots" loading={loading}>
                            <span>View and Confirm Payment</span>
                        </Preloader>
                    </button>
                );
            } else {
                return <button className="trades-dtls__button trades-dtls__button_info">Wait for Payment</button>;
            }
        }
    };
};

const waitCloseTrade = (action, loading) => {
    return {
        Buyer: () => <button className="trades-dtls__button trades-dtls__button_info">Waiting 90 days</button>,
        Seller: () => <button className="trades-dtls__button trades-dtls__button_info">Waiting 90 days</button>
    };
};

const closeTrade = (action, loading, trader, trade) => {
    let signed =
        (trader === 'seller' && trade.requestInfo.sellerClose) || (trader === 'buyer' && trade.requestInfo.buyerClose);
    return {
        Buyer: () => (
            <button onClick={signed ? undefined : () => action()}
                className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                <Preloader loadingStyle="dots" loading={loading}>
                    <span>{signed ? 'Trade Closed' : 'Close Trade'}</span>
                </Preloader>
            </button>
        ),
        Seller: () => (
            <button onClick={signed ? undefined : () => action()}
                className={`trades-dtls__button ${signed ? 'trades-dtls__button_success' : ''}`}>
                <Preloader loadingStyle="dots" loading={loading}>
                    <span>{signed ? 'Trade Closed' : 'Close Trade'}</span>
                </Preloader>
            </button>
        )
    };
};

export const ControlBar = {
    Deal: dealBar,
    VesselBar: vesselBar,
    ProformaInvoiceBar: proformaInvoiceBar,
    DocInst: docInstBar,
    ShippingAdvice: shippingAdvice,
    DocumentsFill: documentsFill,
    DocumentsFull: documentsFull,
    PaymentRequired: paymentRequired,
    Wait: waitCloseTrade,
    Close: closeTrade
};