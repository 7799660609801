import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_TITLE } from '../../../services/service.values';
import { Tooltip } from '@material-ui/core';

const OtherInspectorFormatter = ({inspector}) => {
    return(
        <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_TITLE} placement="top-start" arrow>
            <span>{inspector}&nbsp;</span>
        </Tooltip>
    )
}

OtherInspectorFormatter.propTypes = {
    inspector: PropTypes.string
}

export default OtherInspectorFormatter;