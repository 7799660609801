import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft, faRedoAlt, faSave } from "@fortawesome/free-solid-svg-icons";

//API service
import {
    getCompany,
    changeCompanyType,
    changeRegNumber,
    holdKYC,
    unholdKYC,
    sendKYCReminder,
    approveKYC,
    rejectKYC,
    resubmitKYC
} from '../../../modules/module.platformAdmin';


import AdminNav from './AdminNav';
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';
import FormTextareaField from '../../components/form/FormTextareaField';
import CompanyDetails from '../../components/kyc/CompanyDetails';
import ShareholderDetails from '../../components/kyc/ShareholderDetails';
import UboDetails from '../../components/kyc/UboDetails';
import DirectorDetails from '../../components/kyc/DirectorDetails';
import AssociatedCompnies from '../../components/kyc/AssociatedCompnies';
import TradeReferences from '../../components/kyc/TradeReferences';
import CompanyAdminDetails from '../../components/kyc/CompanyAdminDetails';
import BankDetails from '../../components/kyc/BankDetails';
import CompanyDocuments from '../../components/kyc/CompanyDocuments';

class CompanyKYCDetail extends Component {
    state = {
        showModal: false,
        modal: "",
        saveData: {
            reason: ""
        },
        errors: {},
        inProgress: false,
        reminderInProgress: false,
        companyType: "",
        regNumber: "",
        showCompanyType: false,
        showRegNumber: false
    }

    componentDidMount = () => {
        this.props.getCompany(this.props.match.params.id, (company) => {
            this.updateState(company);
        });
    }

    updateState(company) {
        if (company && company.companyKYC) {
            this.setCompanyType(company.companyKYC.companyType);
        }
    }

    openModal = (modal) => {
        this.setState({ showModal: true, modal: modal });
    };

    setCompanyType(value) {
        this.setState({ companyType: value });
    }

    setRegNumber(value) {
        this.setState({ regNumber: value });
    }

    setCompanyTypeDisplay(value) {
        this.setState({ showCompanyType: value });
    }

    setRegNumberDisplay(value) {
        this.setState({ showRegNumber: value, regNumber: '' });
    }

    renderModal = (modalName) => {
        switch (modalName) {
            case 'REJECT_REASON':
                return (
                    <div className="modal__container">

                        <form className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                if (this.validate()) {
                                    this.rejectKYC();
                                }
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title w-100">Reject Registration</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="row mb-4 mt-4">
                                        <div className="col-md-3 offset-md-1">
                                            <label className="control-label">Enter Rejection Reason<span className="reqfield">*</span></label>
                                        </div>
                                        <div className="col-md-7">
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="reason"
                                                maxLength="300"
                                                rows="10"
                                                value={this.state.saveData.reason}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <a href="#/" className="btn btn-reject lh-1" data-dismiss="modal" onClick={this.closeModal} ><FontAwesomeIcon icon={faTimes} /> Close</a>
                                    <button type="submit" disabled={this.state.inProgress} className="btn btn-approve lh-1">
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Reject</span>
                                        </Preloader> </button>
                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'RESUBMIT_REASON':
                return (
                    <div className="modal__container">

                        <form className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                if (this.validateResubmit()) {
                                    this.resubmitKYC();
                                }
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title w-100">Resubmit Registration Details</h5>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="row mb-4 mt-4">
                                        <div className="col-md-3 offset-md-1">
                                            <label className="control-label">Enter Resubmit Reason<span className="reqfield">*</span></label>
                                        </div>
                                        <div className="col-md-7">
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="resubmitReason"
                                                maxLength="300"
                                                rows="10"
                                                value={this.state.saveData.reason}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <a href="#/" className="btn btn-reject lh-1" data-dismiss="modal" onClick={this.closeModal} ><FontAwesomeIcon icon={faTimes} /> Close</a>
                                    <button type="submit" disabled={this.state.inProgress} className="btn btn-approve lh-1">
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Resubmit</span>
                                        </Preloader> </button>
                                </div>
                            </div>
                        </form>

                    </div>
                );

            case 'CHECK_COMPANYTYPE':

                return (

                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>

                                <div className="modal-body">
                                    <p className="center-text">Please set Company Type .
                                    </p>
                                </div>

                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                );
            default:
                return null;

        }
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            saveData: { reason: "" },
            errors: {}
        });
    };

    saveReason = e => {
        this.setState({ saveData: { reason: e.target.value } });
    };

    validate = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { reason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    validateResubmit = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { resubmitReason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    changeCompanyType = () => {
        this.setState({ inProgress: true });
        this.props.changeCompanyType(this.props.match.params.id, { companyType: this.state.companyType }, response => {
            this.setState({ inProgress: false });
            if (!response || !response.success) {

            } else {
                this.setCompanyTypeDisplay(false);
                this.props.getCompany(this.props.match.params.id, (company) => {
                    this.updateState(company);
                });
            }
        });
    }

    changeRegNumber = (e) => {
        e.preventDefault();
        this.setState({ inProgress: true });
        this.props.changeRegNumber(this.props.match.params.id, { regNumber: this.state.regNumber }, response => {
            this.setState({ inProgress: false });
            if (!response || !response.success) {
                alert("This registration number already exists.");
            } else {
                this.setRegNumberDisplay(false);
                this.props.getCompany(this.props.match.params.id, (company) => {
                    this.updateState(company);
                });
            }
        });
    }

    holdKYC = () => {
        this.setState({ inProgress: true });
        this.props.holdKYC(this.props.match.params.id, response => {
            this.setState({ inProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        })
    }

    unholdKYC = () => {
        this.setState({ inProgress: true });
        this.props.unholdKYC(this.props.match.params.id, response => {
            this.setState({ inProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        })
    }

    sendKYCReminder = () => {
        this.setState({ reminderInProgress: true });
        this.props.sendKYCReminder(this.props.match.params.id, response => {
            this.setState({ reminderInProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        })
    }

    approveKYC = () => {
        if (this.props.company && this.props.company.company.companyKYC.companyType === "OTHER") {
            this.openModal("CHECK_COMPANYTYPE")
            return;
        }
        this.setState({ inProgress: true });
        this.props.approveKYC(this.props.match.params.id, response => {
            this.setState({ inProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        });
    }

    rejectKYC = () => {
        this.setState({ inProgress: true });
        this.props.rejectKYC(this.props.match.params.id, this.state.saveData, response => {
            this.setState({ inProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        })
    }

    resubmitKYC = () => {
        this.setState({ inProgress: true });
        this.props.resubmitKYC(this.props.match.params.id, this.state.saveData, response => {
            this.setState({ inProgress: false });
            if (response && response.success) {
                this.props.navigate('/company/kycPendingList');
            }
        })
    };

    render() {
        let classOnDasboardKyc = '';
        if (!this.props.showIconLabel) {
            classOnDasboardKyc = 'maximum';
        }
        let company = this.props.company ? this.props.company.company : this.props.company;
        let companyKYC = company ? company.companyKYC : undefined;
        return (
            <React.Fragment>
                <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                    {this.state.showModal && this.renderModal(this.state.modal)}
                    <AdminNav />
                    {companyKYC ? (
                        <div className={"dashboard-kyc " + classOnDasboardKyc}>
                            <div className="content">
                                <h3 className="mb-1">Company Registration Detail</h3>
                                <nav className="mb-2">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink exact to="/" id="drop-menu">
                                                Dashboard
                                    </NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink exact to="/company/kycPendingList" id="drop-menu">
                                                Registration Pending List
                                    </NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Company Registration Detail</li>
                                    </ol>
                                </nav>
                                <div className="content-wrappper box-shadow">
                                    <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                                        <CompanyDetails company={companyKYC}
                                            companyType={this.state.companyType}
                                            regNumber={this.state.regNumber}
                                            setCompanyType={(value) => this.setCompanyType(value)}
                                            setRegNumber={(value) => this.setRegNumber(value)}
                                            canEdit={company.kycStatus === "SUBMITTED" || company.kycStatus === "EDITING"}
                                            saveCompanyType={e => this.changeCompanyType()}
                                            saveRegNumber={e => this.changeRegNumber(e)}
                                            showCompanyType={this.state.showCompanyType}
                                            showRegNumber={this.state.showRegNumber}
                                            setCompanyTypeDisplay={(value) => this.setCompanyTypeDisplay(value)}
                                            setRegNumberDisplay={(value) => this.setRegNumberDisplay(value)}>
                                        </CompanyDetails>
                                        <ShareholderDetails company={companyKYC}></ShareholderDetails>
                                        <UboDetails company={companyKYC}></UboDetails>
                                        <DirectorDetails company={companyKYC}></DirectorDetails>
                                        <AssociatedCompnies company={companyKYC}></AssociatedCompnies>
                                        <TradeReferences company={companyKYC}></TradeReferences>
                                        <CompanyAdminDetails admin={companyKYC.companyAdmin} ></CompanyAdminDetails>
                                        <BankDetails bank={companyKYC.bank}></BankDetails>
                                        <CompanyDocuments company={companyKYC}></CompanyDocuments>
                                        <div className="text-center mt-3 mb-3">
                                            <button className="btn btn-back lh-1" onClick={() => { this.props.history.goBack() }} id="drop-menu">
                                                <FontAwesomeIcon icon={faAngleLeft} /> Back
                                            </button>
                                            {company.kycStatus === "DRAFT" &&
                                                (
                                                    <React.Fragment>
                                                        <button onClick={() => this.holdKYC()} disabled={this.state.inProgress} className="btn btn-reject lh-1">
                                                            <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                                <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Put on Hold</span>
                                                            </Preloader>
                                                        </button>
                                                        <button onClick={() => this.sendKYCReminder()} disabled={this.state.reminderInProgress} className="btn btn-resubmit lh-1">
                                                            <Preloader loadingStyle="dots" loading={this.state.reminderInProgress}>
                                                                <span><FontAwesomeIcon icon={faSave} className="mr-0" /> Send Reminder</span>
                                                            </Preloader>
                                                        </button>
                                                    </React.Fragment>)
                                            }
                                            {company.kycStatus === "ON_HOLD" &&
                                                (
                                                    <React.Fragment>
                                                        <button onClick={() => this.unholdKYC()} disabled={this.state.inProgress} className="btn btn-reject lh-1">
                                                            <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                                <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Change to Draft</span>
                                                            </Preloader>
                                                        </button>
                                                    </React.Fragment>)
                                            }
                                            {company.kycStatus === "SUBMITTED" &&
                                                (
                                                    <React.Fragment>
                                                        <a href="#/" onClick={() => this.openModal("REJECT_REASON")} className="btn btn-reject lh-1">
                                                            <FontAwesomeIcon icon={faTimes} /> Reject
                                                        </a>
                                                    </React.Fragment>)
                                            }
                                            {(company.kycStatus === "SUBMITTED" || company.kycStatus === "REJECTED") &&
                                                (
                                                    <React.Fragment>
                                                        <a href="#/" onClick={() => this.openModal("RESUBMIT_REASON")} className="btn btn-resubmit lh-1">
                                                            <FontAwesomeIcon icon={faRedoAlt} className="mr-0" /> Resubmit
                                                        </a>
                                                    </React.Fragment>)
                                            }
                                            {company.kycStatus === "SUBMITTED" &&
                                                (
                                                    <React.Fragment>
                                                        <button disabled={this.state.inProgress} onClick={this.approveKYC} className="btn btn-approve lh-1">
                                                            <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                                <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Approve</span>
                                                            </Preloader>
                                                        </button>
                                                    </React.Fragment>)
                                            }
                                        </div>
                                    </Preloader>
                                </div>
                            </div>
                        </div>

                    ) : (
                        <React.Fragment>
                            <div className={"dashboard-kyc " + classOnDasboardKyc}>
                                <div className="content">
                                    <div className="row">Registration Details not found</div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Preloader>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        company: state.platformAdmin.company,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompany,
            changeCompanyType,
            changeRegNumber,
            holdKYC,
            unholdKYC,
            sendKYCReminder,
            approveKYC,
            rejectKYC,
            resubmitKYC,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyKYCDetail);
