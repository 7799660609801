import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

//api service
import { getKycPendingList } from '../../../modules/module.platformAdmin';

//style

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

//component
import AdminNav from './AdminNav';
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';
import { EnumsService } from "../../../services/service.utils";
import { DATEFORMATHOURS } from '../../../services/service.values';

const Countries = EnumsService.countries();

class KycPendingList extends Component {

    componentWillMount = () => {
        this.props.getKycPendingList();
    }

    render() {
        let classOnDasboardKyc = '';
        if(!this.props.showIconLabel){
            classOnDasboardKyc = 'maximum';
        }
        return (
            <React.Fragment>
                <AdminNav />
                <div className={"dashboard-kyc " + classOnDasboardKyc}>
                    <div className="content">
                        <h3 className="mb-1">Registration Pending List</h3>
                        <nav className="mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">

                                    <NavLink exact to="/" id="drop-menu">
                                        <a href="#/">Dashboard</a>
                                    </NavLink>

                                </li>
                                <li className="breadcrumb-item active">Registration Pending List</li>
                            </ol>
                        </nav>
                        <div className="content-wrappper-kyc box-shadow">

                            <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                                <MaterialTable
                                    columns={[
                                        { title: 'Registered Name', field: 'name' },
                                        { title: 'Registration No', field: 'regNumber' },
                                        {
                                            title: 'Country', field: 'country', render: rowData =>
                                                <span className="exchange__overflow">{`${Countries[rowData.country]}`}</span>
                                        },
                                        // { title: 'Email', field: 'email' },
                                        // { title: 'Telephone No.', field: '' },
                                        { title: 'Status', field: 'kycStatus' },
                                        {
                                            title: 'Submitted', field: 'companyKYC.createdAt', defaultSort: 'desc',
                                            render: rowData =>
                                                <div >
                                                    <span
                                                        className="trades__overflow">{rowData.companyKYC ? (rowData.companyKYC.createdAt === "0001-01-01T00:00:00Z" || !rowData.companyKYC.createdAt ? '' : moment(rowData.companyKYC.createdAt).format(DATEFORMATHOURS)) : ''}
                                                    </span>
                                                </div>
                                        },
                                        {
                                            title: 'Approved', field: 'companyKYC.approvedAt',
                                            render: rowData =>
                                                <div >
                                                    <span
                                                        className="trades__overflow">{rowData.companyKYC ? (rowData.companyKYC.approvedAt === "0001-01-01T00:00:00Z" || !rowData.companyKYC.approvedAt ? '' : moment(rowData.companyKYC.approvedAt).format(DATEFORMATHOURS)) : ''}
                                                    </span>
                                                </div>
                                        },
                                        { title: 'Rejection Reason', field: 'rejectionReason', render: rowData => rowData.companyKYC ? rowData.companyKYC.rejectionReason : '' },
                                        { title: 'Resubmit Reason', field: 'resubmitReason', render: rowData => rowData.companyKYC ? rowData.companyKYC.resubmitReason : '' },
                                        {
                                            title: 'Action', sorting: false, render: rowData => <span>

                                                <a href="#/" className="btn-circle-trasnsparent transparent" data-toggle="tooltip"
                                                    data-placement="top" title="View KYC"><FontAwesomeIcon icon={faEllipsisV} /></a>

                                            </span>
                                        }
                                    ]}
                                    data={this.props.kycPendingList ? this.props.kycPendingList.companies : []}
                                    onRowClick={(event, rowData) => this.props.navigate(`/company/${rowData.ID}/kycDetails`)}
                                    icons={{
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward,
                                    }}
                                    options={{
                                        pageSize: 10,
                                        search: false,
                                        pageSizeOptions: [10, 20, 30, 40, 50],
                                        rowStyle: (rowData, index) => {
                                            const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                            return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                        },
                                        thirdSortClick: false
                                    }}
                                />
                            </Preloader>
                        </div>
                    </div>

                </div>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        kycPendingList: state.platformAdmin.kycPendingList,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getKycPendingList,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(KycPendingList);