import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';



const ValidateLoadCountryAndOrigin = function ({ requestType, onCancel }) {

    return   <div className="modal__container">
        <div className="modal__wrapper px-0 py-0 warningCode" >
        <div className="modal-content">
            <div className="modal-header">
                <div className="modal-header-center">
                    <h5 className="modal-title">Warning Message:</h5>
                </div>
            </div>
            <div className="modal-body py-0">
                <p>The Product Origin differs from the Country of Load.</p>
        <p>Incase it's correct please continue otherwise select correct Country of Load.
        </p>
            </div>
            <div className="modal-footer">
                    <button type="button" className="btn btn-btn-popup btn-cancel" id="btnclose" data-dismiss="modal" onClick={onCancel} >
                        <FontAwesomeIcon icon={faTimes} /> Close
                <span aria-hidden="true"></span>
            </button>
            </div>
        </div>
    </div>
</div>

}

export default ValidateLoadCountryAndOrigin;