import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment-timezone';

import { DATEFORMAT, CURRENCYOPT, NON_INTEGRATED_INSPECTORS } from '../../../../services/service.values';
import { FertilizerService } from '../../../../services/service.fertilizer';
import { renderSelectedOrigins, EnumsService } from '../../../../services/service.utils';
import CardOfferField from './CardOfferField';
import CardMultiOriginPrice from './CardMultiOriginPrice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from 'react-number-format';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';
import OtherInspectorFormatter from '../../../components/Formatter/OtherInspectorFormatter';
const Countries = EnumsService.countries();
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

class CardOffer extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.renderTotalPrice = this.renderTotalPrice.bind(this);
        this.renderMultiOriginPrice = this.renderMultiOriginPrice.bind(this);
    }

    renderTotalPrice() {
        return this.props.bid.price.toFixed(2);
    }

    renderSingleOriginPrice = () => {
        const {price, bestPrice, currency} = this.props.bid;
        const currencySign = (currency === "EURO")?"EUR":"USD";
        if(!price && bestPrice) return "Best Price";
        return(
            <NumberFormat 
                value={this.renderTotalPrice()} 
                displayType={'text'} 
                thousandSeparator={true} 
                prefix={currencySign +' '} />
        )
    }

    renderMultiOriginPrice = () => {
        const {multipleOriginPrice, origin, currency, price, bestPrice} = this.props.bid;
        if(multipleOriginPrice && multipleOriginPrice.length > 1){
            return origin.split(',').map(item => {
                let isExist = multipleOriginPrice.find(x => x.origin === item);
                return isExist.isPriceCheck ? (
                    <NumberFormat
                        key={item}
                        displayType="text" 
                        value={isExist.price}  
                        renderText={text => <div>{text}</div>} 
                        thousandSeparator={true}
                        prefix={currency === 'USD' || currency === 'US$' ? 'USD ' : 'EUR '}
                        suffix={` (${Countries[isExist.origin]})`}/>
                ):(
                    <div key={item}>-</div>
                ) 
            })
        }else{
            return origin.split(',').map(item => {
                if(!price && bestPrice){
                    return <div key={item}>Best Price ({Countries[item]})</div>
                }
                return(
                    <NumberFormat
                        key={item}
                        displayType="text" 
                        value={price}  
                        renderText={text => <div>{text}</div>} 
                        thousandSeparator={true}
                        prefix={currency === 'USD' || currency === 'US$' ? 'USD ' : 'EUR '}
                        suffix={` (${Countries[item]})`}
                    />
                )
            })
        }
    }

    handleOnClick() {
        this.props.onClick(this.props.bid.ID);
    }
    
    getInspectionCompanyName = (companyId, inspectionOther) => {
        let companyName = "";
        if (this.props.inspections && companyId) {
            companyName = this.props.inspections[companyId.trim()];
            if (companyId === "1") {
                return <OtherInspectorFormatter inspector={NON_INTEGRATED_INSPECTORS[inspectionOther]}/>
            }
            return companyName;
        }
    }

    render() {

        const {
            currency,
            origin,
            fromCompanyID,
            toCompanyID,
            ownerId,
            price,
            shipping,
            fertilizerType,
            incoterm,
            deliveryStartDate,
            deliveryEndDate,
            sellerId,
            seller,
            buyerId,
            buyer,
            fromCompanyName,
            toCompanyName,
            status,
            inspection,
            inspectionOther
        } = this.props.bid;

        const { linkToTrade, companyId } = this.props;
        const currencySign = (currency === "EURO") ? "EUR" : "USD";

        let counterparty = "";
        let counterpartyId = "";
        let recieved;
        //Case Trade
        if (ownerId) {
            if (ownerId === sellerId) {
                counterparty = seller;
                counterpartyId = sellerId;
            }
            if (ownerId === buyerId) {
                counterparty = buyer;
                counterpartyId = buyerId;
            }
        }
        //Case Bid
        if (companyId) {
            if (companyId === fromCompanyID) {
                counterparty = toCompanyName;
                counterpartyId = toCompanyID;
                recieved = false;
            } else {
                recieved = true;
                counterparty = fromCompanyName;
                counterpartyId = fromCompanyID
            }
        }



        return <div className="compare-offer">
            <div className="compare-wrapper px-3 py-3" >
                {status === "DECLINED" ?
                    <span style={{ backgroundColor: '#ff3223' }} className="status">{'declined'}</span>
                    :
                    <>
                        {(!ownerId && recieved) &&
                            <span style={{ backgroundColor: '#36af47' }} className="status">{'received from'}</span>}
                        {(!ownerId && !recieved) &&
                            <span style={{ backgroundColor: '#7b7b7b' }} className="status">{'sent to'}</span>}

                    </>
                }




                <div className="text-right">
                    {this.props.onClick &&
                        <button className="btn btn--blue btn-sm" onClick={this.handleOnClick}>
                            <FontAwesomeIcon icon={faEyeSlash} /> Minimize
                        </button>}
                    {linkToTrade &&
                        <Link to={linkToTrade}>
                            <button className="btn btn--blue btn-sm">Initial request</button>
                        </Link>}
                </div>
                {this.props.showMultiOriginPrice ? (
                    <CardMultiOriginPrice heading={true}
                        fieldName={counterparty}
                        fieldValue={this.renderMultiOriginPrice()}
                        counterpartyId={counterpartyId} />
                ) : (
                        <CardOfferField heading={true}
                            fieldName={counterparty}
                            fieldValue={this.renderSingleOriginPrice()}
                            counterpartyId={counterpartyId} 
                        />
                    )}

                <CardOfferField fieldName="Vessel or Container"
                    fieldValue={shipping} />
                <CardOfferField fieldName="Quantity (expressed in MT)"
                    fieldValue={<TradeQuantityFormatter trade={this.props.bid} />} />
                <CardOfferField fieldName="Fertilizer Type"
                    fieldValue={FERTILIZER_TYPE[fertilizerType] || fertilizerType} />
                <CardOfferField fieldName="Inspection Company"
                    fieldValue={this.getInspectionCompanyName(inspection, inspectionOther)} />
                {!this.props.showMultiOriginPrice &&
                    <CardOfferField fieldName="Origin"
                        fieldValue={renderSelectedOrigins(origin)} />}
                <CardOfferField fieldName="Incoterm"
                    fieldValue={incoterm} />
                <CardOfferField fieldName="Shipping Period"
                    fieldValue={
                        <React.Fragment>
                            <span>From {moment(deliveryStartDate).format(DATEFORMAT)}</span>
                            <span>&nbsp;</span>
                            <span>To {moment(deliveryEndDate).format(DATEFORMAT)}</span>
                        </React.Fragment>} />
                {this.props.tradePrivacyType !== "TENDER" &&
                    <>
                        <CardOfferField fieldName="Total Price"
                            fieldValue={<React.Fragment><NumberFormat value={this.renderTotalPrice()} displayType={'text'} thousandSeparator={true} prefix={currencySign + ' '} /></React.Fragment>} />
                        <div className="card mt-3">
                            <div className="card-body">
                                <CardOfferField className="mt-0"
                                    fieldName="Base Price"
                                    fieldValue={
                                        <React.Fragment>
                                            <div>
                                                {`${currencySign} ${price > 0 ? "+" : ""}`}
                                                <React.Fragment>
                                                    <NumberFormat
                                                        value={price}
                                                        displayType={'text'}
                                                        thousandSeparator={true} />
                                                </React.Fragment>
                                            </div>
                                        </React.Fragment>} />
                            </div>
                        </div>
                    </>}
            </div>
        </div>

    }
}

export default CardOffer;
