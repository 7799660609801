export const TradeAPIErrors = {
    WRONG_TRADE_STATUS: "Unfortunately this trade is no longer available.",
    ACCESS_DENIED: "Unfortunately you don't have access to this trade.",
    OWNER_CANT_COUNTER: "You still have a valid counter outstanding and you cannot make a new counter,nor accept another proposal , until your outstanding counter expired or until it is declined by the counter party",
    WRONG_BID_STATUS_COUNTERED: "This trade is already countered.",
    WRONG_BID_STATUS_ACCEPTED: "This trade is already accepted.",
    WRONG_BID_STATUS_DECLINED: "This trade is already declined.",
    INVALID_COUNTERPARTY: "This request is not valid.",
    OWNER_CANT_ACCEPT_TRADE: "You still have a valid counter outstanding and you cannot make a new counter,nor accept another proposal , until your outstanding counter expired or until it is declined by the counter party",
    COUNTERPARTY_CANT_ACCEPT_TRADE: "Unfortunately the creator of the trade is under negotiation with another party at this time,  please try later again or send a counter proposal.",
    BID_EXPIRED: "The validity of this counter has expired and therefore you can only create a counter or decline.",
    PRICE_IMPROV_ONLY_ALLOWED_IN_VALIDITY: "The validity of your offer/counter has expired and therefore the price can no longer be improved.",
    INVALID_PRICE: "The trade can't be accepted without price.",
    TRADE_EXPIRED: "The validity of this trade has expired and therefore you can only create a counter.",
    HTTP_404: "404 not found.",
    HTTP_500: "Internal server error occured.",
    DEFAULT_ERROR: "An error occured while saving"
}

export const TradeQuotesError = {
    INSPECTION_COMPANY_NOT_APPROVED: "The inspection company is not on the list of approved service providers. Please contact your company administrator to add service providers.",
    SHIPPING_COMPANY_NOT_ON_LIST: "The Shipping company is not on the list of approved service providers. Please contact your company administrator to add service providers.",
    NO_SERVICE_AVAILABLE: "No service available for the selected country/port."
}