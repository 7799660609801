import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getInspectionDetails, updateQuoteStatus, inspectionDocumentUpload, postInspectionMessage, getInspectionMessages, ReplyMessage, ClearQuoteState, getInpectionStatus,
    downloadInspectionDoc
} from '../../../../modules/module.inspectionQuote';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import moment from 'moment';
import { DATEFORMAT, QUOTESTATUS } from '../../../../services/service.values';
import { EnumsService,PortService,renderPackaging } from '../../../../services/service.utils';
import AccessControl, { INSPECTION_COMPANY } from '../../../components/AccessControl';
import { UserTypes } from '../../../../utils/userTypes';
import NumberFormat from 'react-number-format';
//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import DetailTable from '../../requests/detail/DetailTable';
import { faTimes, faCheck, faUpload, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuoteMessage from '../../../admin/trades/components/quotes/QuoteMessage';
import '../trades.scss';
import Preloader from '../../../components/preloader/Preloader';
import InspectionUpload from '../components/InspectionUpload';
import FormInputField from '../../../components/form/FormInputField';
import PaginationCustom from '../../../components/pagination/PaginationCustom';
import PreviewQuoteModal from '../../../admin/trades/components/quotes/PreviewQuoteModal';
import { viewInspectionQuotePermission, negotiateInspectionQuotePermission } from '../../../../services/service.permission';

class InspectionQuoteDetails extends Component {
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }
    constructor(props) {
        super(props);
        this.textArea = React.createRef();
        let isEmpty = value => !value || value === undefined || value === '';
        let uploadDoc = {};
        this.initField(uploadDoc, 'inspectionUpload', 'Upload Document', '', isEmpty);
        this.initField(uploadDoc, 'referenceId', 'Quote Reference Id', '', isEmpty);
        this.initField(uploadDoc, 'rate', 'Rate in USD', '', isEmpty);
        this.initField(uploadDoc, 'eventualAdditionalCondition', 'Eventual Additional Conditions', '', '');
        this.state = {
            input: '',
            showModal: false,
            modal: '',
            modalData: {},
            quoteDetails: {},
            provisionalQuote: {},
            companyName: "",
            statusReason: {
                id: "",
                name: "",
                reason: "",
                addtionalComments: "",
            },
            uploadDoc: uploadDoc,
            canSubmit: false,
            serverError: '',
            required: {
                uploadDoc: {}
            },
            files: {},
            shippingType: '',
            messagesPerPage: [],
            currentMessageIndex: 0,
            messageNoPerPage: 10,
            series: [],
            quoteDetailFinal: {},
            previewDocuments: null,
            inspectionCompany: "",
            previewQuote: null,
            inProgress: false,
            showModalTwo: true,
        };
        this.getCountryName = this.getCountryName.bind(this);
        this.showReplyButton = this.showReplyButton.bind(this);
        // this.props.loadRequestDetails(this.props.match.params.id);

        this.props.getInpectionStatus(this.props.match.params.id, "PROVISIONAL", this.props.account.user.companyId);

    }
   
    fieldList = [
        { key: "OriginCountry", label: "Origin Country" },
        { key: "destinationCountry", label: "Destination Country" },
        { key: "destinationPort", label: "Destination Port",format: (value) => {  return (value === "") ? ("NA") : ( value) } },
        { key: "shippingType", label: "Shipping Type" },
        { key: "incoterm", label: "Incoterm" },
        { key: "packaging", label: "Packaging" },
        { key: "tonnage", label: "Quantity(expressed in MT)", format: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix = {" MT"}/> },
        { key: "deliveryDateTo", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT) },
        { key: "deliveryDateFrom", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT) },
        { key: "preShipmentInfo", label: "PSI (pre-shipment inspection)", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "preShipmentLocation", label: "Location of PSI" },
        { key: "draftSurvey", label: "Draft Survey", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "tally", label: "Tally", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "sampling", label: "Sampling", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "markingsCheck", label: "Markings Check", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "sealing", label: "Sealing", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "hoseTestRequired", label: "Hose Test Required", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "holdsInspectionReport", label: "Holds Inspection Report", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "fumigation", label: "Fumigation", format: (value) => { return (value) ? "Yes" : "No" } },
        { key: "sampleDistribution", label: "Sample Distribution" },
        { key: "location", label: "Location" },
        { key: "sampleRetention", label: "Sample Retention" },
        { key: "requiredAnalysis", label: "Required Analysis" },
        { key: "desiccants", label: "Desiccants", format: (value) => { return (value === "") ? ("NA") : (value) } },
        { key: "preparationKraftPaper", label: "Preparation: Kraft Paper", format: (value) => { return (value === "") ? ("NA") : (value) }, },
        { key: "others", label: "Special Request/Special Documents" },
        // { key: "rate", label: "Rate",format: (value) => {  return (value === "") ? ("NA") : ("$ " + value) } },
        // { key: "eventualAdditionalCondition", label: "Eventual Additional Conditions",format: (value) => {  return (value === "") ? ("NA") : (value) } },
        { key: "rejectReason", label: "User Additional Information",format: (value) => {  return (value === "") ? ("NA") : ( value) } },

    ];

    UNSAFE_componentWillMount = () => {
        this.props.getInpectionStatus(this.props.match.params.id, "PROVISIONAL", this.props.account.user.companyId);
        this.fetchFunction();
        // this.props.loadRequestDetails(this.props.match.params.id);
        this.props.getInspectionMessages(this.props.match.params.inspectionQuoteID, () => {
            let messages = [...this.props.messages];
            this.setState({
                messagesPerPage: messages.splice(this.state.currentMessageIndex, this.state.messageNoPerPage)
            })
        });

    };

    componentWillUnmount = () => {
        this.props.ClearQuoteState();
    };

    componentDidMount(){
        this.props.getInspectionMessages(this.props.match.params.inspectionQuoteID, () => {
            let messages = [...this.props.messages];
            this.setState({
                messagesPerPage: messages.splice(this.state.currentMessageIndex, this.state.messageNoPerPage)
            })
        });
    }

    fetchFunction = () => {
        this.inspectionDetails();
    };

    inspectionDetails = () => {
        this.props.getInspectionDetails(this.props.match.params.id, this.props.match.params.inspectionQuoteID, response => {
            this.setState({
                quoteDetails: {
                    preShipmentInfo: response.reports.inspectionInfo.preShipmentInfo,
                    preShipmentLocation: response.reports.inspectionInfo.preShipmentLocation,
                    draftSurvey: response.reports.inspectionInfo.draftSurvey,
                    tally: response.reports.inspectionInfo.tally,
                    sampling: response.reports.inspectionInfo.sampling,
                    markingsCheck: response.reports.inspectionInfo.markingsCheck,
                    sealing: response.reports.inspectionInfo.sealing,
                    hoseTestRequired: response.reports.inspectionInfo.hoseTestRequired,
                    holdsInspectionReport: response.reports.inspectionInfo.holdsInspectionReport,
                    fumigation: response.reports.inspectionInfo.fumigation,
                    sampleDistribution: response.reports.inspectionInfo.sampleDistribution,
                    location: response.reports.inspectionInfo.location,
                    sampleRetention: response.reports.inspectionInfo.sampleRetention,
                    requiredAnalysis: response.reports.inspectionInfo.requiredAnalysis,
                    desiccants: response.reports.inspectionInfo.desiccants,
                    preparationKraftPaper: response.reports.inspectionInfo.preparationKraftPaper,
                    others: response.reports.inspectionInfo.others,
                    shippingType: response.reports.shippingType,
                    incoterm: response.reports.incoterm,
                    tonnage: response.reports.tonnage,
                    vatNumber: response.reports.vatNumber,
                    status: response.reports.status,
                    inspectionQuoteType: response.reports.inspectionQuoteType,
                    toCompanyId: response.reports.toCompanyId,
                    fromCompanyId: response.reports.fromCompanyId,
                    OriginCountry: this.getCountryName(response.reports.OriginCountry),
                    destinationCountry: response.reports.destinationCountry===""?"NA":this.getCountryName(response.reports.destinationCountry),
                    quoteRequestDate: response.reports.quoteRequestDate,
                    tradeRequestId: response.reports.tradeRequestId,
                    comments: response.reports.comments,
                    rejectReason: response.reports.rejectReason,
                    rate: response.reports.rate,
                    referenceId: response.reports.referenceId,
                    tradeType: response.reports.tradeType,
                    tradeRefId:response.reports.tradeRefID,
                    deliveryDateTo:response.reports.deliveryDateTo,
                    deliveryDateFrom:response.reports.deliveryDateFrom,
                    destinationPort: this.renderPortLabel(response.reports.destinationPort),
                    packaging:renderPackaging(response.tradeDetails.packaging),
                    eventualAdditionalCondition:response.reports.eventualAdditionalCondition
                    
                },
                companyName: response.reports.fromCompany.companyName,
                inspectionCompany: response.reports.toCompany.companyName
            });
            let quotes = [];
            quotes = [this.state.quoteDetails];
            // if(this.state.quoteDetails.inspectionQuoteType==="FINAL"){
            // quotes.push(this.state.provisionalQuote);}
            const series = quotes.map((quote, index, quoteArray) => {
                const title = this.getColumnTitle(index, quote);
                return { title: title, data: quote }
            });
            this.setState({ series: series });

        });
        this.props.downloadInspectionDoc(this.props.match.params.id, this.props.match.params.inspectionQuoteID, response => {
            this.setState({
                previewDocuments: response.docUrl
            });
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = new URLSearchParams(window.location.search);
        const forceUpdateParam = params.get('forceUpdate');
        if (forceUpdateParam) {
            const forceUpdate = forceUpdateParam === 'true';
            if (forceUpdate) {
                this.props.getInspectionMessages(this.props.match.params.inspectionQuoteID);
                this.inspectionDetails();
            }
            const newLocationSearch = this.props.location.search.replace(/(&|\\?)forceUpdate=(true|false)/g, '');
            this.props.navigate(window.location.pathname + newLocationSearch);
        }
        if (this.props.messages!== prevProps.messages) {
                    let messages = [...this.props.messages];
                    this.setState({
                        messagesPerPage: messages.splice(this.state.currentMessageIndex, this.state.messageNoPerPage)
                    })
        }
    }

    renderPortLabel = value => {
        let label = "";
        if(value){
            label = PortService.getJSON()[value]?PortService.getJSON()[value].name : value;
        }
        return label;    
    }



    getColumnTitle(index, quote) {
        let title = "";
        switch (index) {
            case 0: title = quote.inspectionQuoteType; break;
            case 1: title = quote.inspectionQuoteType; break;
            default: 
            break;
        }
        return title;
    }

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }
    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
                if (key === "inspectionUpload") {
                    this.fileUploadValidate("Please upload the file before 'Submit'");
                }
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }

        return true;
    }


    getCountryName(isoCode) {

       if(isoCode!==undefined){
        const countryName = EnumsService.countries()[isoCode.trim()];
        return countryName;
      }
    }

    postRootMessage = (e, value) => {
        e.preventDefault();
        if (value !== '') {
            let message = {
                parentId: null,
                text: value,
                tradeRefID: this.state.quoteDetails.tradeRefId,
                tradeID: this.state.quoteDetails.tradeRequestId
            };
            var counterpartyId = this.props.account.token.companyId === this.state.quoteDetails.toCompanyId ? this.state.quoteDetails.fromCompanyId : this.state.quoteDetails.toCompanyId;
            this.props.postInspectionMessage(this.props.match.params.inspectionQuoteID, counterpartyId, message, () => {
                let messages = [...this.props.messages];
                this.setState({
                    messagesPerPage: messages.splice(this.state.currentMessageIndex, this.state.messageNoPerPage)
                })
            });
            this.textArea.current.value = '';
        }
    };

    getReply = value => {
        this.setState({
            input: value
        });
    };
    reply = (messageID) => {
        this.props.ReplyMessage(messageID, this.props.account.user.id);
        this.setState({});
    };


    showReplyButton(message) {
        if (message.toCompanyID === "0") {
            return false;
        }
        return true;
    }

    postInspectionMessage = (e, messageID, fromCompanyID, toCompanyID) => {
        e.preventDefault();
        if (this.state.input !== '') {
            let message = {
                parentId: messageID,
                text: this.state.input,
                tradeRefID: this.state.quoteDetails.tradeRefId,
                tradeID: this.state.quoteDetails.tradeRequestId
            };
            var counterpartyId = this.props.account.token.companyId === this.state.quoteDetails.toCompanyId ? this.state.quoteDetails.fromCompanyId : this.state.quoteDetails.toCompanyId;
            this.props.postInspectionMessage(this.props.match.params.inspectionQuoteID, counterpartyId, message, () => {
                let messages = [...this.props.messages];
                this.setState({
                    messagesPerPage: messages.splice(this.state.currentMessageIndex, this.state.messageNoPerPage)
                })
            });

        }
    };

    handleChangeInput = e => {
        this.setState({ statusReason: { ...this.state.statusReason, [e.target.name]: e.target.value } });
    };
    updateCancelStatus = () => {
        const data = {
            comment: this.state.statusReason.reason,
            quoteType: this.state.quoteDetails.inspectionQuoteType,
            id: this.state.quoteDetails.tradeRequestId
        }
        this.props.updateQuoteStatus(this.props.match.params.inspectionQuoteID, QUOTESTATUS.CANCELLED, data, response => {
            if (response.res.status === 200) {
                this.inspectionDetails();
            }
        });
        this.closeModal();
    }


    updateRejectStatus = e => {
        const data = {
            comment: this.state.statusReason.reason,
            quoteType: this.state.quoteDetails.inspectionQuoteType,
            id: this.state.quoteDetails.tradeRequestId
        }
        this.props.updateQuoteStatus(this.props.match.params.inspectionQuoteID, QUOTESTATUS.REJECTED, data, response => {
            if (response.res.status === 200) {
                this.inspectionDetails();
            }
        });
        this.closeModal();

    }
    // Complete status for quote
    updateCompleteStatus = e => {
        const data = {
            comment: this.state.statusReason.reason,
            quoteType: this.state.quoteDetails.inspectionQuoteType,
            id: this.state.quoteDetails.tradeRequestId
        }

        this.props.updateQuoteStatus(this.props.match.params.inspectionQuoteID, QUOTESTATUS.COMPLETED, data, response => {
            if (response.res.status === 200) {
                this.inspectionDetails();
            }
        });
        this.closeModal();
    }

    setUploadField(name, value, state, index, subState) {
        if (name !== '') {
            let required = {
                uploadDoc: {}
            };

            if (Object.keys(required).length > 0) {
                this.setState({ required: required });
            }
            let uploadDoc = this.state.uploadDoc;
            let field;
            if (state) {
                if (index !== undefined) {
                    if (subState) {
                        field = uploadDoc[state][index][subState][name];
                    } else {
                        field = uploadDoc[state][index][name];
                    }
                } else {
                    field = uploadDoc[state][name];
                }
            } else {
                field = uploadDoc[name];
            }
            if (name === 'percentage' && value.includes('-', 0)) {
                field.value = ''
            } else if (value < 0) {
                field.value = Math.abs(value);
            } else {
                field.value = value;
            }
            this.setState({ uploadDoc: uploadDoc });
        }
    }
    fileUploadValidate = errorMessage => {
        this.setState({
            serverError: errorMessage
        });
    }


    fileUploadData = formdata => {
        this.setState({
            files: formdata,
            canSubmit: true
        });
    }

    onSubmitUpload = (e) => {
        e.preventDefault();
        if (this.validate(this.state.uploadDoc)) {
            const formData = new FormData();
            formData.append("upload", this.state.files[0]);
            formData.append("inspectionQuoteId", this.props.match.params.inspectionQuoteID);
            formData.append("referenceId", this.state.uploadDoc.referenceId.value);
            formData.append("comments", this.state.statusReason.addtionalComments);
            formData.append("rate", this.state.uploadDoc.rate.value);
            formData.append("eventualAdditionalCondition", this.state.uploadDoc.eventualAdditionalCondition.value);
            this.props.inspectionDocumentUpload(this.state.quoteDetails.tradeRequestId, this.state.quoteDetails.inspectionQuoteType, this.state.quoteDetails.fromCompanyId, formData, response => {
                if (response.res.status === 200) {
                    this.inspectionDetails();
                }
                else {
                    this.fileUploadValidate("There is an error while uploading file");
                }
            });
            this.closeModal();
        }
    }
    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'CANCEL_REQUEST':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Cancel Request </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote py-0">
                                    <p className="mb-3 px-2 text-center">
                                        You are about to cancel the request to service provider.
                                        Please provide a reason for this cancellation and click 'Confirm' or click 'Back' to return previous screen.
                    </p>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="reason" onChange={this.handleChangeInput} placeholder="Please provide your reason.." className="form-control text-color " rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                                <button type="submit" onClick={() => this.updateCancelStatus()} className="btn-popup btn-submit">
                                    <Preloader loadingStyle="dots" loading={this.state.loadingStatus}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Confirm
                                </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );

            case 'REJECT_REQUEST':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Reject Request </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote py-0">
                                    <p className="mb-3 px-2 text-center">
                                        You are about to reject the request to service provider.
                                        Please provide a reason for this rejection and click 'Confirm' or click 'Back' to return previous screen.
                    </p>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="reason" onChange={this.handleChangeInput} placeholder="Please provide your reason.." className="form-control text-color " rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                                <button type="submit" onClick={() => this.updateRejectStatus()} className="btn-popup btn-submit">
                                    <Preloader loadingStyle="dots" loading={this.state.loadingStatus}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Confirm
                                </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );
            case 'COMPLETE_REQUEST':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Accept Quote </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote">
                                    <p className="mt-2 px-2 text-center">
                                        You are about to accept the quote to service provider.
                                        click 'Confirm' or click 'Back' to return previous screen.
                                   </p>
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="reason" onChange={this.handleChangeInput} placeholder="Please provide your reason.." className="form-control text-color " rows="5"></textarea>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                                <button type="submit" onClick={() => this.updateCompleteStatus()} className="btn-popup btn-submit">
                                    <Preloader loadingStyle="dots" loading={this.state.loadingStatus}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Confirm
                                </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );
            case 'UPLOAD_DOC':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                this.onSubmitUpload(e)
                            }
                            } encType="multipart/form-data">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Respond for  {(this.state.quoteDetails.inspectionQuoteType === "PROVISIONAL" ? "Provisional" : "Final")}  Quote for Inspection of {this.state.quoteDetails.shippingType === "VESSEL" ? "Vessel" : "Container"} </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote1">
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <FormInputField
                                                name={'referenceId'}
                                                validation={this.state.required}
                                                value={this.state.uploadDoc.referenceId}
                                                onChange={e => this.setUploadField("referenceId", e.target.value)}
                                                placeholder="Reference Id"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInputField
                                                type="number"
                                                min="0.01"
                                                step="any"
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'rate'}
                                                // validation={`form-control input-field ${this.state.required}`}
                                                validation={this.state.required}
                                                value={this.state.uploadDoc.rate}
                                                onChange={e => this.setUploadField("rate", e.target.value)}
                                                placeholder="Rate"
                                            />

                                        </div>
                                    </div>
                                    {this.state.quoteDetails.shippingType==="CONTAINER" &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInputField
                                                step="any"
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'eventualAdditionalCondition'}
                                                // validation={`form-control input-field ${this.state.required}`}
                                                validation={this.state.required}
                                                value={this.state.uploadDoc.eventualAdditionalCondition}
                                                onChange={e => this.setUploadField("eventualAdditionalCondition", e.target.value)}
                                                placeholder="Eventual additional conditions"
                                            />

                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <InspectionUpload
                                                value={this.state.uploadDoc.inspectionUpload}
                                                name="inspectionUpload"
                                                validation={this.state.required}
                                                onChange={(name, value, state) => this.setUploadField(name, value, state)}
                                                subState=""
                                                canSubmit={this.state.canSubmit}
                                                fileUploadValidate={this.fileUploadValidate}
                                                fileUploadData={this.fileUploadData}
                                            />
                                            {this.state.serverError &&
                                                <div className="counterparty__error">{this.state.serverError}</div>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pt-2">
                                            {/* <div className="form-group"> */}
                                                <textarea name="addtionalComments" onChange={this.handleChangeInput} placeholder="Additional Information" className="form-control text-color" rows="5"></textarea>
                                                {/* <div className="counterparty__error">eroror</div> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Back
                        </button>
                                <button type="submit" className="btn-popup btn-submit">
                                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;Submit
                                </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );
            case 'PERMISSION_REQUIRED':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            default:
                break;
        }
    };

    renderModalTwo = () => {
                return (<div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0 authcode"
                        onSubmit={e => {
                            e.preventDefault();
                            this.closeModalTwo();
                        }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModalTwo} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div className="modal-body">
                                <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                            </div>
                            <div className="modal-footer">
                                <Link to="/" className="btn-popup btn-cancel"><FontAwesomeIcon icon={faTimes} />Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>);
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, data: data })

    };

    closeModal = () => {
        let isEmpty = value => !value || value === undefined || value === '';
        let uploadDoc = {};
        this.initField(uploadDoc, 'inspectionUpload', 'Upload Document', '', isEmpty);
        this.initField(uploadDoc, 'referenceId', 'Quote Reference Id', '', isEmpty);
        this.initField(uploadDoc, 'rate', 'Rate', '', isEmpty);
        this.initField(uploadDoc, 'eventualAdditionalCondition', 'Eventual Additional Conditions', '', '');
        this.setState({
            showModal: false,
            modal: '',
            input: '',
            modalData: {},
            uploadDoc: uploadDoc,
            serverError: '',
        });
    };

    closeModalTwo = () => {
        this.setState({
            showModalTwo: false,
            modal: '',
            modalData: {}
        });
    };

    renderStatusLabel = () => {
        let label = "";
        if (this.props.account.token.companyId === this.state.quoteDetails.fromCompanyId) {
            switch (this.state.quoteDetails.status) {
                case QUOTESTATUS.NEW: label = this.state.quoteDetails.inspectionQuoteType === "PROVISIONAL" ? "Quote Requested" : "Offer Requested"; break;
                case QUOTESTATUS.INPROGRESS: label = this.state.quoteDetails.inspectionQuoteType === "PROVISIONAL" ? "Quote Received" : "Offer Received"; break;
                case QUOTESTATUS.COMPLETED: label = "Completed"; break;
                case QUOTESTATUS.CANCELLED: label = "Cancelled"; break;
                case QUOTESTATUS.REJECTED: label = "Rejected"; break;
                default: 
                break;
            }
        }
        if (this.props.account.token.companyId === this.state.quoteDetails.toCompanyId) {
            switch (this.state.quoteDetails.status) {
                case QUOTESTATUS.NEW: label = this.state.quoteDetails.inspectionQuoteType === "PROVISIONAL" ? "New Request" : "New Final Request"; break;
                case QUOTESTATUS.INPROGRESS: label = this.state.quoteDetails.inspectionQuoteType === "PROVISIONAL" ? "Quote Sent" : "Offer Sent"; break;
                case QUOTESTATUS.COMPLETED: label = "Completed"; break;
                case QUOTESTATUS.CANCELLED: label = "Cancelled"; break;
                case QUOTESTATUS.REJECTED: label = "Rejected"; break;
                default:
                break;
            }
        }
        return label;

    }

    returnStatusNumber = () => {
        switch (this.state.quoteDetails.status) {
            case QUOTESTATUS.NEW:
                return "quote-status pending";
            case QUOTESTATUS.CANCELLED:
                return "quote-status orange";
            case QUOTESTATUS.COMPLETED:
                return "quote-status green";
            case QUOTESTATUS.REJECTED:
                return "quote-status primary";
            case QUOTESTATUS.INPROGRESS:
                return "quote-status gray";
            default:
                break;
        }
    };

    UpdateColumn = (ProvisionalDetails) => {
        //var  ProvisionalDetails={};
        var provisionalQuote = {};
        let quotes = [];
        quotes = [this.state.quoteDetails];
        if (ProvisionalDetails !== undefined) {
            if (Object.keys(ProvisionalDetails).length !== 0 && ProvisionalDetails.quoteType !== "NOTFOUND") {
                provisionalQuote = {
                    preShipmentInfo: ProvisionalDetails.data.inspectionInfo.preShipmentInfo,
                    preShipmentLocation: ProvisionalDetails.data.inspectionInfo.preShipmentLocation,
                    draftSurvey: ProvisionalDetails.data.inspectionInfo.draftSurvey,
                    tally: ProvisionalDetails.data.inspectionInfo.tally,
                    sampling: ProvisionalDetails.data.inspectionInfo.sampling,
                    markingsCheck: ProvisionalDetails.data.inspectionInfo.markingsCheck,
                    sealing: ProvisionalDetails.data.inspectionInfo.sealing,
                    hoseTestRequired: ProvisionalDetails.data.inspectionInfo.hoseTestRequired,
                    holdsInspectionReport: ProvisionalDetails.data.inspectionInfo.holdsInspectionReport,
                    fumigation: ProvisionalDetails.data.inspectionInfo.fumigation,
                    sampleDistribution: ProvisionalDetails.data.inspectionInfo.sampleDistribution,
                    location: ProvisionalDetails.data.inspectionInfo.location,
                    sampleRetention: ProvisionalDetails.data.inspectionInfo.sampleRetention,
                    requiredAnalysis: ProvisionalDetails.data.inspectionInfo.requiredAnalysis,
                    desiccants: ProvisionalDetails.data.inspectionInfo.desiccants,
                    preparationKraftPaper: ProvisionalDetails.data.inspectionInfo.preparationKraftPaper,
                    others: ProvisionalDetails.data.inspectionInfo.others,
                    shippingType: ProvisionalDetails.data.shippingType,
                    incoterm: ProvisionalDetails.data.incoterm,
                    tonnage: ProvisionalDetails.data.tonnage,
                    vatNumber: ProvisionalDetails.data.vatNumber,
                    status: ProvisionalDetails.data.status,
                    inspectionQuoteType: ProvisionalDetails.data.inspectionQuoteType,
                    toCompanyId: ProvisionalDetails.data.toCompanyId,
                    fromCompanyId: ProvisionalDetails.data.fromCompanyId,
                    OriginCountry:(ProvisionalDetails.data.OriginCountry==="" || ProvisionalDetails.data.OriginCountry===undefined)?"NA":this.getCountryName(ProvisionalDetails.data.OriginCountry), 
                    destinationCountry:(ProvisionalDetails.data.destinationCountry==="" || ProvisionalDetails.data.destinationCountry===undefined)?"NA":this.getCountryName(ProvisionalDetails.data.destinationCountry), 
                    quoteRequestDate: ProvisionalDetails.data.quoteRequestDate,
                    comments: ProvisionalDetails.data.comments,
                    rejectReason: ProvisionalDetails.data.rejectReason,
                    rate:ProvisionalDetails.data.rate,
                    deliveryDateTo:ProvisionalDetails.data.deliveryDateTo,
                    deliveryDateFrom:ProvisionalDetails.data.deliveryDateFrom,
                    destinationPort: this.renderPortLabel(ProvisionalDetails.data.destinationPort),
                    packaging:renderPackaging(ProvisionalDetails.data.packaging),
                    eventualAdditionalCondition:ProvisionalDetails.data.eventualAdditionalCondition
                }
                quotes.push(provisionalQuote);
            }
        }
        const series = quotes.map((quote, index, quoteArray) => {
            const title = this.getColumnTitle(index, quote);
            return { title: title, data: quote }
        });
        return series;
    }

    openPreviewDocument = (file, quoteDetails) => {
        this.setState({ inProgress: true });
        this.props.downloadInspectionDoc(this.props.match.params.id, this.props.match.params.inspectionQuoteID, response => {
            this.setState({
                previewQuote: {
                    name: "Preview Quote",
                    file: {
                        url: response.docUrl

                    },
                    rate: quoteDetails.rate,
                    referenceId: quoteDetails.referenceId,
                    additionalMessage: quoteDetails.comments

                },
                inProgress: false
            });
        });
    };

    closePreviewModal = () => {
        this.setState({
            previewQuote: null
        });
    };

    render() {

        if (viewInspectionQuotePermission() === 0) {
            return (
                <div>
                    {this.state.showModalTwo && this.renderModalTwo()}
                </div>
            );
        }
        else {

            var series = this.UpdateColumn();
            if (this.props.inspectionQuote.quoteType !== undefined) {
                if (this.state.quoteDetails.inspectionQuoteType === "FINAL") {
                    series = this.UpdateColumn(this.props.inspectionQuote.quoteType);
                }
            }

            var isNegotiateInspection = false;
            if (negotiateInspectionQuotePermission() === 1) {
                isNegotiateInspection = true;
            }

            return (
                <React.Fragment>
                    <Header />
                    <div className="trades-req-quotes-Container">
                        {/* <div classNameName="Content-Container"> */}
                        {this.state.showModal && this.renderModal(this.state.modal)}
                        <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                            <div className="Container">
                                <div className="trades-req-quotes__heading"> <h3 className="mb-1">Requests for Inspection Quotes: Details </h3></div>
                                <nav className="mb-1">
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><a href="Request for Final Quotes.html">
                                        <FontAwesomeIcon icon={faAngleLeft} /> Back</a></li> */}

                                    </ol>
                                </nav>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div id="trade-info" className="request-dtls__status-sections sub-header">
                                            <div className="card-body">
                                                <div className="row trade-detail">
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Request Received
                                                            </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {moment(this.state.quoteDetails.quoteRequestDate).format(DATEFORMAT)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 mb-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Request by Trader
                                                             </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {this.state.companyName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                                            <div className="row mt-2 mb-2">
                                                                <div className="col-md-5">
                                                                    <span className="field-name">
                                                                        Trade Ref Id </span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <span className="field-value">
                                                                        <Link className="trades-dtls__audit-cell__link trades-dtls__audit-cell__link--underline" to={`/requests/details/${this.props.match.params.id}`}>
                                                                            {this.state.quoteDetails.tradeRefId || this.state.quoteDetails.tradeRequestId}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </AccessControl>
                                                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} companyTypes={[INSPECTION_COMPANY]}>
                                                            <div className="row mt-2 mb-2">
                                                                <div className="col-md-5">
                                                                    <span className="field-name">
                                                                        Trade Ref Id </span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <span className="field-value">
                                                                        {this.state.quoteDetails.tradeRefId || this.state.quoteDetails.tradeRequestId}</span>
                                                                </div>
                                                            </div>
                                                        </AccessControl>
                                                        <div className="row mt-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Inspection Company   </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {this.state.inspectionCompany}  </span>
                                                            </div>
                                                        </div>
                                                      

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Status </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className={this.returnStatusNumber()}>{this.renderStatusLabel()}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2 mb-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Trade type </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {this.state.quoteDetails.tradeType} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Type</span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {this.state.quoteDetails.inspectionQuoteType}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                    Rate</span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {/* {this.state.quoteDetails.rate === "" ? "NA" : "$ " + this.state.quoteDetails.rate} */}
                                                                    <NumberFormat 
                                                                    value={this.state.quoteDetails.rate === "" ? "NA" : "USD " + this.state.quoteDetails.rate}
                                                                    displayType={'text'} 
                                                                    thousandSeparator={true}
                                                                    renderText={value => value === "" ? "NA" : "USD " + value} >
                                                                    </NumberFormat>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        { this.state.quoteDetails.shippingType ==="CONTAINER" &&
                                                        <div className="row mt-2">
                                                            <div className="col-md-5">
                                                                <span className="field-name">
                                                                Eventual Additional Conditions   </span>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <span className="field-value">
                                                                    {this.state.quoteDetails.eventualAdditionalCondition==="" ? "NA":this.state.quoteDetails.eventualAdditionalCondition}  </span>
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <div className="col-md-4 ">
                                                        <div className="d-flex flex-column text-right">
                                                            <div className="p-2"> </div>
                                                            <div className="iq-actions">
                                                                <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY]}>

                                                                    {this.state.quoteDetails.status === QUOTESTATUS.NEW && <div>
                                                                        {isNegotiateInspection ? (
                                                                            <button className="btn btn-quote-cancel float-right" onClick={() => this.openModal("CANCEL_REQUEST")}  >
                                                                                <Preloader loadingStyle="dots" loading={this.state.loadingStatus}>
                                                                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Cancel Request
                                                                 </Preloader>
                                                                            </button>
                                                                        ) : (
                                                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-cancel float-right">
                                                                                    <FontAwesomeIcon icon={faTimes} />&nbsp;Cancel Request  </a>
                                                                            )}
                                                                    </div>
                                                                    }

                                                                    {(this.state.quoteDetails.status === QUOTESTATUS.INPROGRESS || this.state.quoteDetails.status === QUOTESTATUS.COMPLETED) && <div>
                                                                        <div className="row">
                                                                            <div className="p-1 col-md-4">
                                                                                {isNegotiateInspection ? (
                                                                                    <button className="btn btn-quote-req btn-width" onClick={() => this.openPreviewDocument(this.state.previewDocuments, this.state.quoteDetails)} >
                                                                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                                                            <span><FontAwesomeIcon icon={faEye} />&nbsp;Preview Quote</span>
                                                                                        </Preloader>
                                                                                    </button>
                                                                                ) : (
                                                                                        <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-req btn-width">
                                                                                            <FontAwesomeIcon icon={faEye} />&nbsp;Preview Quote</a>
                                                                                    )}
                                                                            </div>
                                                                            <div className="p-1 col-md-4">
                                                                                {isNegotiateInspection ? (
                                                                                    <a className="btn btn-quote-req btn-width" href={this.state.previewDocuments}><FontAwesomeIcon icon={faDownload} />&nbsp;Dowload </a>
                                                                                ) : (
                                                                                        <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-req btn-width">
                                                                                            <FontAwesomeIcon icon={faDownload} />&nbsp;Dowload</a>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                    {this.state.quoteDetails.status === QUOTESTATUS.INPROGRESS && <div>
                                                                        <div className="row">
                                                                            {this.state.quoteDetails.inspectionQuoteType === "FINAL" &&
                                                                                <div className="p-1 col-md-4">
                                                                                    {isNegotiateInspection ? (
                                                                                        <button className="btn btn-quote-req btn-width" onClick={() => this.openModal("COMPLETE_REQUEST")}><FontAwesomeIcon icon={faCheck} />&nbsp;Accept Offer</button>
                                                                                    ) : (
                                                                                            <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-req btn-width">
                                                                                                <FontAwesomeIcon icon={faCheck} />&nbsp;Accept Offer </a>
                                                                                        )}
                                                                                </div>}

                                                                            <div className="p-1 col-md-4">
                                                                                {isNegotiateInspection ? (
                                                                                    <button className="btn btn-quote-cancel btn-width" onClick={() => this.openModal("REJECT_REQUEST")}><FontAwesomeIcon icon={faTimes} />&nbsp;Reject</button>
                                                                                ) : (
                                                                                        <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-cancel btn-width">
                                                                                            <FontAwesomeIcon icon={faTimes} />&nbsp;Reject</a>
                                                                                    )}
                                                                            </div>

                                                                        </div>
                                                                    </div>}
                                                                </AccessControl>
                                                                <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} companyTypes={[INSPECTION_COMPANY]}>
                                                                    {this.state.quoteDetails.status === QUOTESTATUS.NEW && <div>
                                                                        {isNegotiateInspection ? (
                                                                            <button className="btn btn-quote-respond float-right" onClick={() => this.openModal("UPLOAD_DOC")}><FontAwesomeIcon icon={faUpload} />&nbsp;Respond</button>
                                                                        ) : (
                                                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-respond float-right">
                                                                                    <FontAwesomeIcon icon={faUpload} />&nbsp;Respond</a>
                                                                            )}
                                                                    </div>
                                                                    }


                                                                    {(this.state.quoteDetails.status === QUOTESTATUS.INPROGRESS || this.state.quoteDetails.status === QUOTESTATUS.COMPLETED) && <div>
                                                                        {isNegotiateInspection ? (
                                                                            <button className="btn btn-quote-preview float-right" onClick={() => this.openPreviewDocument(this.state.previewDocuments, this.state.quoteDetails)} >
                                                                                <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                                                    <span><FontAwesomeIcon icon={faEye} />&nbsp;Preview Quote</span>
                                                                                </Preloader>
                                                                            </button>
                                                                        ) : (
                                                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="btn btn-quote-req float-right">
                                                                                    <FontAwesomeIcon icon={faEye} />&nbsp;Preview Quote  </a>
                                                                            )}
                                                                    </div>
                                                                    }

                                                                </AccessControl>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6  mt-4">
                                        <div className="request-dtls__status-sections sub-header">
                                            <div className="trade-title">
                                                <h3 className="request-dtls__card-heading">Details of your request </h3>
                                            </div>
                                            <div className="pl-3 pr-3 pb-3">
                                                <DetailTable
                                                    fixWidth={false}
                                                    //  series={[{ title: 'Field Value', data: trade }]}
                                                    // series={this.state.series}
                                                    series={series}
                                                    fieldList={this.fieldList}>
                                                </DetailTable>
                                            </div>

                                        </div>
                                        {this.state.previewQuote && (
                                            <PreviewQuoteModal
                                                file={this.state.previewQuote.file}
                                                name={this.state.previewQuote.name}
                                                rate={this.state.previewQuote.rate}
                                                referenceId={this.state.previewQuote.referenceId}
                                                additionalMessage={this.state.previewQuote.additionalMessage}
                                                eventualAdditionalCondition ={this.state.quoteDetails.eventualAdditionalCondition}
                                                onClose={this.closePreviewModal}
                                            />
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mt-4">
                                        <div className="request-dtls__comments-wrapper comments-box">
                                            <div className="comments_trade-title">
                                                <h3 >Comments / Inspection Quote </h3>
                                            </div>
                                            <React.Fragment>
                                                <textarea
                                                    className="request-dtls__comment-area"
                                                    name="comment"
                                                    id="comment"
                                                    placeholder="Add your message..."
                                                    ref={this.textArea}
                                                />
                                                {isNegotiateInspection ? (
                                                    <button
                                                        className="request-dtls__comment-send mt-2"
                                                        disabled={this.props.loadingTradeMessages}
                                                        onClick={e => this.postRootMessage(e, this.textArea.current.value)}
                                                    >  Send </button>
                                                ) : (
                                                        <button
                                                            className="request-dtls__comment-send mt-2"
                                                            onClick={e => this.openModal('PERMISSION_REQUIRED')}
                                                        >
                                                            Send
                                                        </button>
                                                    )}
                                            </React.Fragment>
                                            <Preloader loadingStyle="swirl" loading={this.props.loadingTradeMessages} />
                                            {this.state.messagesPerPage.map((message, i) => {
                                                return (
                                                    <QuoteMessage
                                                        key={i}
                                                        userId={this.props.account.user.id}
                                                        message={message}
                                                        onSubmit={(e, messageID, fromCompanyID, toCompanyID, messages) => this.postInspectionMessage(e, messageID, fromCompanyID, toCompanyID, messages)}
                                                        onClick={messageID => this.reply(messageID)}
                                                        onChange={value => this.getReply(value)}
                                                        showReplyButton={this.showReplyButton}
                                                        loading={this.props.loadingTradeMessages}
                                                    />
                                                );
                                            })}
                                            {this.props.messages.length > 0 ? <PaginationCustom
                                                fetchFunction={(index, itemsPerPage) => {
                                                    let messages = [...this.props.messages];
                                                    this.setState({
                                                        messagesPerPage: messages.splice(index, itemsPerPage),
                                                        currentMessageIndex: index,
                                                        messageNoPerPage: itemsPerPage
                                                    });
                                                }}
                                                totalItemsCount={this.props.messages.length}
                                                itemsPerPage={this.state.messageNoPerPage}
                                                pagesAtOnce={10} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Preloader>
                    </div>

                    {/* </div> */}
                    <Footer />
                </React.Fragment>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        user: state.account.user,
        trade: state.trade,
        account: state.account,
        inspectionQuote: state.inspectionQuote,
        loading: state.loading.requestLoading,
        messages: state.inspectionQuote.messages,
        loadingTradeMessages: state.loading.loadingTradeMessages,
        loadingStatus: state.loading.groupActionLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInspectionMessages,
            ReplyMessage,
            postInspectionMessage,
          //  loadRequestDetails,
            getInspectionDetails,
            updateQuoteStatus,
            inspectionDocumentUpload,
            ClearQuoteState,
            getInpectionStatus,
            downloadInspectionDoc,
            navigate: path => push(path),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InspectionQuoteDetails);