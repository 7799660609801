import React from "react";
import PropTypes from 'prop-types';

import moment from 'moment';
import converter from 'number-to-words';
import NumberFormat from 'react-number-format';
import Editable from './Editable';
import AccessControl, { INSPECTION_COMPANY } from '../../../../components/AccessControl';

import { EnumsService, PortService, renderSelectedOrigins, renderPackaging } from '../../../../../services/service.utils';
import TradeQuantityFormatter from '../../../../components/Formatter/TradeQuantityFormatter';

import {
    DATEFORMAT,
    DATEFORMATHOURS,
    SHIPPINGTYPES,
    PAYMENTTERMSOPT,
    PAYMENTPERIODOPT,
    CURRENCYOPT,
    DOWNPAYMENTPERIODOPT,
    getDischargeLabel,
    DISCHARGE_VALUES,
    getDischargeRateLabel
} from '../../../../../services/service.values';
import { FertilizerService } from '../../../../../services/service.fertilizer';
import { DocumentFactory, DOCUMENT_UPLOADER } from '../../../trades/services/documents.service';

import { renderFertilizerSpecifications, renderGoverningCountry } from "../../../requests/detail/detailTable/Renderers";
import OtherInspectorFormatter from "../../../../components/Formatter/OtherInspectorFormatter";


//const GAFTA_CIF_CONTRACT_NO = '122 ';
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);
const Countries = EnumsService.countries();
//const PROD_AND_SPEC_DEFAULT = "Fertilizer to be of sound loyal and merchantable quality, free from foreign and /or bad odor, free from live weevils/live insects and practically free of dead weevils, fit for direct human consumption. Otherwise as per export standards of the country of origin valid at time of shipment.";
const CONTRACTUAL_INSPECT_COMP_DEFAULT = "Quality and Quantity inspection issued by an independent Inspection agency at load port. The quantity and quality as determined by the Independent inspection agency at load port shall be final and binding on both the parties cost of such inspection agency is at Seller’s account.";
//const QUANTITY_DEFAULT = "5 pct. more or less in option of Seller and at contract price."
const PACKING_DEFAULT = "Bag markings to be printed on two sides of the bags at sellers costs with the marking as agreed between the parties and apart from these marks no other counter markings to appear apart from exporters code and/or other counter markings as imposed by regulator in country of origin, ; but same always to be approved by Buyer prior to printing of the bags. \n\nAll bags to have full and non-slack appearance and to be double stitched at the top and bottom and suitable for ocean transportation of fertilizer.\n\n Ink used for marking to be suitable for markings of foodstuffs bags. \n\nSellers to supply 2 pct of empty marked spare bags per each shipment free of charge."
//const PRICE_DEFAULT = "(Incoterms 2020) , destination THC ( Terminal Handling Charges) and other eventual costs at destination to be for Buyer’s account ."
const FUMIGATION_DEFAULT = "To be effected after completion of loading at sellers costs and risk product to be used is aluminum phosphide or aluminum phosphide at 2 gram/m3 for 120 hours or as per recommendation of the inspection company.";
const DISCHARGE_TERMS_DEFAULT = "Buyer is responsible for returning the empty containers within the time permitted by the shipping line.\n Buyer shall be required to pay all the charges associated with containerized cargo at the discharge port. This would include not only the THC, but any destination documentation fee, import services, demurrage and detention costs outside of the free period and container cleaning costs. Should Buyer fail to do so than any costs/consequences resulting from the same will be for Buyer’s account.";
const TOLARANCE_MINMAX = "Min/Max in option of Seller";
const TOLARANCE_WITHOUT_MINMX = "more or less in option of Seller";

function TemplateContainerCIF({ trade, openEditables, onEdit, onSave, onCancel, user, bankRejected, lastAmendment }) {
    const onPallets = trade.packaging.includes('BAGGED_PALLET');
    const isInspection = user.companyType === INSPECTION_COMPANY;

    return (
        <div className="PDF-wrapper mx-3">
            <div>
                <span className="trades-dtls__contract-text">CIF contract template for Container</span>
                <span className="trades-dtls__contract-date text-right">{`${(trade.acceptedAt && trade.acceptedAt !== "0001-01-01T00:00:00Z") ? moment(trade.acceptedAt).format(DATEFORMAT) : ""}`}</span>
            </div>
            <br />
            <br />
            <h4 className="trades-dtls__contract-heading">
                Contract REF ID : {trade.tradeRefId || trade.id}
            </h4>
            {lastAmendment && <div className="trades-dtls__contract-subheading">
                {`(Amended in Mutual Agreement on ${moment(lastAmendment.acceptedAt).format(DATEFORMATHOURS)})`}
            </div >}
            <br />
            <section className="section">
                <span className="trades-dtls__contract-item">1. Seller</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Company : </strong></span>{trade.seller}<br />
                    <span><strong>Address : </strong></span><br />
                    <span>&nbsp;Street : </span>
                    {trade.sellerAddress1 && <span>
                        {trade.sellerAddress1.line1 ? trade.sellerAddress1.line1 : ''}<br />
                        {trade.sellerAddress1.line2 ? trade.sellerAddress1.line2 : ''}<br />
                    </span>}
                    <span>&nbsp;City : </span>{trade.sellerAddress1 ? trade.sellerAddress1.city : ''}<br />
                    <span>&nbsp;Zip Code : </span>{trade.sellerAddress1 ? trade.sellerAddress1.postal : ''}<br />
                    <span>&nbsp;Country : </span>{trade.sellerAddress1 ? Countries[trade.sellerAddress1.country] : ''}<br />
                    <br />
                    <span><strong>User : </strong></span>{trade.sellerUser}<br />
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">2. Buyer</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Company : </strong></span>{trade.buyer}<br />
                    <span><strong>Address : </strong></span><br />
                    <span>&nbsp;Street : </span>
                    {trade.sellerAddress1 && <span>
                        {trade.buyerAddress1.line1 ? trade.buyerAddress1.line1 : ''}<br />
                        {trade.buyerAddress1.line2 ? trade.buyerAddress1.line2 : ''}<br />
                    </span>}
                    <span>&nbsp;City : </span>{trade.buyerAddress1 ? trade.buyerAddress1.city : ''}<br />
                    <span>&nbsp;Zip Code : </span>{trade.buyerAddress1 ? trade.buyerAddress1.postal : ''}<br />
                    <span>&nbsp;Country : </span>{trade.buyerAddress1 ? Countries[trade.buyerAddress1.country] : ''}<br />
                    <br />
                    <span><strong>User : </strong></span>{trade.buyerUser}<br />
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">3. Product and Specifications</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Fertilizer Type : </strong></span>{FERTILIZER_TYPE[trade.fertilizerType] || trade.fertilizerType}<br />
                    <span><strong>Fertilizer Specifications : </strong></span><br/>
                    {renderFertilizerSpecifications(trade.specifications, trade.fertilizerType, true)}
                </div>
            </section>
            {/* <section className="section">
                <Editable isEditing={openEditables.prodAndSpec}
                    name="prodAndSpec"
                    onEdit={onEdit}
                    onSave={onSave}
                    onCancel={onCancel}>{PROD_AND_SPEC_DEFAULT}</Editable>
            </section> */}
            <AccessControl user={user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                <section className="section">
                    <span className="trades-dtls__contract-item">4. Price</span>
                    <div className="trades-dtls__contract-text">
                        <span><strong>Price : </strong></span>{' '}
                        {(!trade.price && trade.bestPrice) ? (
                            <span>Best Price</span>
                        ) : (
                            <NumberFormat
                                prefix={`${trade.currency === "USD" ? "USD " : CURRENCYOPT[trade.currency]} `} 
                                value={trade.price} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                suffix={` - (${trade.currency === "USD" ? "USD " : CURRENCYOPT[trade.currency]} ${(trade.price) ? converter.toWords(trade.price) : "zero"}) per Metric Ton`}
                            />
                        )}<br />
                        <span>
                            <strong>Incoterm: </strong>
                        </span>{' '}
                        {trade.incoterm}<br />
                        <span>
                            <strong>Destination Port: </strong>
                        </span>{' '}
                        {(PortService.getJSON()[trade.destPort]) ? PortService.getJSON()[trade.destPort].name : trade.destPort}, ({Countries[trade.destCountry]})
                    </div>
                </section>
                {/* <section className="section">
                    <Editable isEditing={openEditables.price} onEdit={onEdit}>{PRICE_DEFAULT}</Editable>
                </section> */}
            </AccessControl>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '4.' : '5.'} Quantity</span>
                <div className="trades-dtls__contract-text">
                    <span>
                        <strong>Quantity : </strong>
                    </span>{' '}
                    <TradeQuantityFormatter 
                        trade={trade} 
                        suffix={` (${trade.measure && converter.toWords(trade.measure)}) Mts. ${trade.tolerance === 0 ? TOLARANCE_MINMAX : `${trade.tolerance}% ${TOLARANCE_WITHOUT_MINMX}`}`}/><br/>
                    {/* <span><strong>Contractual Tolerance: </strong></span>Contractual Tolerance : {`${TOLERANCE[trade.tolerance]} and at contract price`}<br /> */}
                </div>
                {/* <div className="trades-dtls__contract-text">
                    <Editable isEditing={openEditables.quantityEditable} onEdit={onEdit}>{QUANTITY_DEFAULT}</Editable>
                </div> */}
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '5.' : '6.'} Packing</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Packaging : </strong></span>{renderPackaging(trade.packaging)}
                </div>
            </section>
            {(trade.packaging === "BAGGED_OTHER" || trade.packaging === "BAGGED_PALLET_OTHER") &&
                <section className="section">
                    <Editable isEditing={openEditables.packingEditable} onEdit={onEdit}>{PACKING_DEFAULT}</Editable>
                </section>}
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '6.' : '7.'} Shipping Type</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Shipping : </strong></span>{SHIPPINGTYPES[trade.shipping] || trade.shipping}<br />
                </div>
                <div className="trades-dtls__contract-text">
                    In one lot by 20 FT containers at seller’s option. 14 days free demurrage and 7 days free detention at least at discharge port. 
                    After that demurrage detention incurred as per relevant shipping  Line’s demurrage/detention rate to buyer’s account. 
                    THC and other charges, insurance, local taxes and duties, etc at discharge port to Buyer’s account.
                    <br/>
                    Incoterms 2020 to apply.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '7.' : '8.'} Contractual Appointed Inspection Company </span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Inspection Company : </strong></span>
                    {trade.inspection === "1" ? <OtherInspectorFormatter inspector={trade.inspectionName}/> : trade.inspectionName}<br />
                </div>
            </section>
            <section className="section">
                <Editable isEditing={openEditables.contractualInspect}
                    name="contractualInspect"
                    onEdit={onEdit}
                    onSave={onSave}
                    onCancel={onCancel}>{CONTRACTUAL_INSPECT_COMP_DEFAULT}</Editable>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '8.' : '9.'} Marine Insurance</span>
                <div className="trades-dtls__contract-text">
                    Buyers to cover the Marine Insurance on 110 pct of the value of the goods including war risk, civil riots and commotion. 
                    Buyers to provide proof of coverage on sellers first demand.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '9.' : '10.'} Origin</span>
                <div className="trades-dtls__contract-text">
                    <span><strong>Origin : </strong></span>{renderSelectedOrigins(trade.origin)}<br />
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '10.' : '11.'} Loading/delivery</span>
                <div className="trades-dtls__contract-text">
                    From the origin, during the below mentioned period
                </div>
                <div className="trades-dtls__contract-text">
                    <span><strong>From : </strong></span>{moment(trade.deliveryStartDate).format(DATEFORMAT)}<br />
                    <span><strong>To : </strong></span>{moment(trade.deliveryEndDate).format(DATEFORMAT)}
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{isInspection ? '11.' : '12.'} Payment</span>
                <div className="trades-dtls__contract-text">
                    {trade.downPaymentPercentage ? (
                        <>
                            <span><strong>Down Payment : </strong></span>{trade.downPaymentPercentage}%<br />
                            <span><strong>Down payment to be effected within : </strong></span>{DOWNPAYMENTPERIODOPT[trade.downPaymentPeriod]} from proforma invoice date.<br />
                            <span><strong>Balance Payment : </strong></span>{PAYMENTTERMSOPT[trade.payment]}<br />
                            <span><strong>Balance Payment Period : </strong></span>{PAYMENTPERIODOPT[trade.paymentPeriod]}
                        </>
                    ) : (
                        <>
                            <span><strong>Payment : </strong></span>{PAYMENTTERMSOPT[trade.payment]}<br />
                            <span><strong>Payment Period : </strong></span>{PAYMENTPERIODOPT[trade.paymentPeriod]}
                        </>
                    )}
                    {trade.ADIssuingDate && (
                        <>
                            <br /><span><strong>Avalised Draft to be issued latest on : </strong></span>{moment(trade.ADIssuingDate).format(DATEFORMAT)}
                        </>
                    )}
                </div>
                {(trade.LCOpeningDate) && (
                    <div className="trades-dtls__contract-text">
                        100% value in {trade.currency} by irrevocable Letter of Credit from a first class bank payable at sight in seller’s acceptable format. 
                        The workable L/C should be established by the buyer and received by seller’s bank latest by {moment(trade.LCOpeningDate).format(DATEFORMAT)}. 
                        Beneficiary to allow the seller to present the documents directly to L/C applicant bank counters L/C should be unrestricted for negotiation 
                        and should allow 21 days for negotiation and freely negotiable with any bank in {trade.locationOfBank}
                    </div>
                )}
                <div className="trades-dtls__contract-text">
                    <span><strong>Currency : </strong></span>{CURRENCYOPT[trade.currency] || "USD"}
                </div>

                {trade.nameOfBank &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Name of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.nameOfBank}</del> : trade.nameOfBank}<br />
                        <span><strong>Location of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.locationOfBank}</del> : trade.locationOfBank}<br />
                        <span><strong>Swift Code of Buyer's Bank : </strong></span>{bankRejected ? <del>{trade.swiftCodeOfBank}</del> : trade.swiftCodeOfBank}
                    </div>}
                {trade.nameOfConfirmingBank &&
                    <div className="trades-dtls__contract-text">
                        <span><strong>Name of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.nameOfConfirmingBank}</del> : trade.nameOfConfirmingBank}<br />
                        <span><strong>Location of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.locationOfConfirmingBank}</del> : trade.locationOfConfirmingBank}<br />
                        <span><strong>Swift Code of Confirming Bank : </strong></span>{bankRejected ? <del>{trade.swiftCodeOfConfirmingBank}</del> : trade.swiftCodeOfConfirmingBank}
                    </div>}
            </section>
            <section className="section">
                The original doc to consist of:<br />
                <br />
                <ul>
                    {DocumentFactory.getSelectedFEDocumentList(trade).map((doc) => (
                        <li key={doc.type}>
                            {`${doc.title} ${doc.description ? doc.description : ''}`} <small>{` (Uploaded by ${DOCUMENT_UPLOADER[doc.uploadedBy]})`}</small>
                        </li>))}
                </ul>
                <div className="trades-dtls__contract-text">
                    Except for the commercial invoice, third party documents are acceptable. Letters of indemnity for missing documents are not acceptable.
                    </div>
            </section>
            <section className="section">
            <span className="trades-dtls__contract-item">{isInspection ? '12.' : '13.'} {getDischargeLabel(trade.incoterm)}</span>
                <div className="trades-dtls__contract-text">
                    {DISCHARGE_VALUES[trade.discharge] === "CQD" ? (
                        <>
                            <span><strong>{`${getDischargeLabel(trade.incoterm)}: `}</strong></span>{DISCHARGE_VALUES[trade.discharge] || trade.discharge}<br />
                            <span><strong>{`${getDischargeRateLabel(trade.incoterm, trade.discharge)}: `}</strong></span><NumberFormat value={trade.dischargeRate} displayType={'text'} thousandSeparator={true} /><br />
                        </>):(
                        <>
                            <Editable isEditing={openEditables.dischargeTerms} onEdit={onEdit}>{DISCHARGE_TERMS_DEFAULT}</Editable>
                            <div className="trades-dtls__contract-text">
                                The Buyer shall be responsible for obtaining and maintaining in force any necessary import license(s) and the seller shall be responsible
                                for obtaining and maintaining in force any necessary export license(s). The failure to obtain and or to maintain in force such
                                license(s) shall not be sufficient grounds for a claim of Force Majeure if the regulations in force, at the time when the contract was made,
                                called for such license(s) to be obtained.
                            </div>
                            <div className="trades-dtls__contract-text">
                                Title to goods sold and purchased under this contract shall pass from Seller to Buyer upon receipt by Seller of full payment.
                            </div>
                        </>
                        )
                    }
                </div>
            </section>
            {(onPallets) && (
                <section className="section">
                    <span className="trades-dtls__contract-item">{isInspection ? '13.' : '14.'} Fumigation</span>
                    <Editable isEditing={openEditables.fumigation} onEdit={onEdit}>{FUMIGATION_DEFAULT}</Editable>
                </section>
            )}
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '13' : '14.' : isInspection ? '14' : '15.'} Governing Law and Arbitration</span>
                {trade.governingCountry ? (
                    <React.Fragment>
                        <div className="trades-dtls__contract-text">
                            This contract shall be governed and construed as per {renderGoverningCountry(trade.governingCountry)} law.
                        </div>
                        <div className="trades-dtls__contract-text">
                            Any dispute connection with this agreement shall be settled by mutual agreement or in a friendly manner, 
                            if no settlement can be reached within 30 days of commencement of such consultation, 
                            the arbitration case shall be submitted to the international Chamber of Commerce in {renderGoverningCountry(trade.governingCountry)}&nbsp;
                            and this decision shall be deemed final and binding on both parties.
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="trades-dtls__contract-text">
                        The contract shall be governed and construed as per Singapore Laws and place of jurisdiction at Singapore.
                        Dispute, if any, arising out of or in connection with this contract shall be resolved either by arbitration in Singapore,
                        in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (SIAC Rules)
                        and any amendments thereof, or in the Court of Law at Singapore at seller’s option.
                    </div>
                )}
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '14.' : '15.' : isInspection ? '15.' : '16.'} Other Terms</span>
                <div className="trades-dtls__contract-text">
                    a) Third party documents acceptable except commercial invoice conditions. <br />
                    b) All buyer’s bank charges to buyers account and all seller’s bank charges to seller’s account. <br />
                    c) All duties, tariffs, levies, flag waivers, permits, port duties, taxes, royalties, actual custom duty, THC and similar charges at discharge port to buyer’s account. <br />
                    d) Non-negotiable set of documents to be made available to buyer within 04 (four) working days of shipment. <br />
                    e) The seller will make every endeavor to negotiate the documents at the earliest but in any case if the same is not available before the arrival of the vessel, then the buyer has to arrange the discharge of cargo immediately against Bank Guarantee issued by the Letter of credit issuing bank. <br />
                    f) Partial shipment allowed / not allowed.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '15.' : '16.' : isInspection ? '16.' : '17.'} Miscellaneous</span>
                <div className="trades-dtls__contract-text">
                    (a) Entire Agreement: This contract constitutes the entire agreement between the parties relating to the purchase of the product 
                        in the quantities and during the period specified herein. All prior and contemporaneous representations, understandings and agreements are 
                        superseded and merged herein.
                        <br /> 
                    (b) Modifications: This contract cannot be modified except in a written form signed by both parties to this contract. 
                        No usage of trade or prior course of dealing or performance between the parties shall be deemed to modify the terms of this contract.
                        <br />
                    (c) Waiver: No delay or failure on Seller's or Buyer's part to force any right or claim which either of them 
                        may have hereunder shall constitute a waiver of such right or claim. Any waiver by Seller or Buyer
                        of any term, provision or condition hereof or of any default hereunder in any one or more instances shall
                        not be deemed to be a further or continuing waiver of such term, provision, or condition or of any subsequent default hereunder.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '16.' : '17.' : isInspection ? '17.' : '18.'} Force Mejeure</span>
                <div className="trades-dtls__contract-text">
                    Both Buyer and Seller shall not be liable for delay or nonperformance in whole or in part of his contractual obligations 
                    in consequence of war (whether declare or not declare or war like situation), blockades, revolution, insurrection, civil commotion, riot, 
                    mobilization, act of Government or public enemy, act of God, plague or other epidemic, fire, flood, sabotage, quarantine, restriction, 
                    explosion or embargo, including any changes made by the government of exporting country such as changes in taxes, duties, 
                    imposition of restriction on export, etc., any change/modification in commercial laws rules and regulations by the Government.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '17.' : '18.' : isInspection ? '18.' : '19.'} Warranty</span>
                <div className="trades-dtls__contract-text">
                    Unless otherwise specified, seller warrants that the goods will be fit for the ordinary purposes for which such goods are normally used. 
                    Seller does not warrant that the goods are suitable for any particular purpose for which they may be required, 
                    whether or not seller has reason to know of any such requirements. 
                    There are no warranties, which extend beyond the description of the face hereof. 
                    Except as expressly set forth herein, seller makes no warranties expressed or implied. 
                    Seller will assist in obtaining compliance with any warranties of the Manufacturer and in effecting such settlement. 
                    Seller shall not be liable for consequential damages.
                </div>
            </section>
            <section className="section">
                <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '18.' : '19.' : isInspection ? '19.' : '20.'} Acceptance</span>
                <div className="trades-dtls__contract-text">
                    No term in the Buyers' purchase order, acknowledgement form or other document which
                    conflicts with the
                    terms hereof shall be binding on the Seller unless accepted in writing by the Seller.
                    </div>
            </section>
            { trade.specialRequest &&
                <section className="section">
                    <span className="trades-dtls__contract-item">{!onPallets ? isInspection ? '19.' : '20.' : isInspection ? '20.' : '21.'} Special Request</span>
                    <div className="trades-dtls__contract-text">
                        {trade.specialRequest}
                    </div>
                </section>}
            <section className="section">
                <div className="row">
                    <div className="col-6 trades-dtls__contract-signatures">
                        <span>Digitally signed</span>
                        <br />
                        {trade.seller}
                        <br />
                    </div>
                    <div className="col-6 trades-dtls__contract-signatures">
                        <span>Digitally signed</span>
                        <br />
                        {trade.buyer}
                    </div>
                </div>
            </section>
        </div >
    )
}

TemplateContainerCIF.propTypes = {
    trade: PropTypes.object, 
    openEditables: PropTypes.object, 
    onEdit: PropTypes.func, 
    onSave: PropTypes.func, 
    onCancel: PropTypes.func, 
    user: PropTypes.object, 
    bankRejected: PropTypes.bool, 
    lastAmendment: PropTypes.bool
}

export default TemplateContainerCIF;