import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProviderListByComapny, deactivateProvider, activateProvider } from '../../modules/module.adminManagement';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus, faUserSlash, faCheck } from '@fortawesome/free-solid-svg-icons';

import './adminManagement-style.scss';
import Preloader from '../components/preloader/Preloader';
import '../admin/requests/create/create-trade-request.scss'
import '../components/form/FormInputField.scss';
import AddProvider from './AddProvider';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Tooltip from '@material-ui/core/Tooltip';

class ProviderList extends Component {

    state = {
        showModal: false,
        modalData: {
            companyID: ""
        }
    };

    componentWillMount = () => {
        this.fetchFunction();
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            input: '',
            modalData: {}
        });
    };

    fetchFunction = () => {
        this.props.getProviderListByComapny(this.props.company.companyId);
        this.setState({});
    };

    getItems = () => {
        let providers = this.props.usermanagment.companyProviders;
        return providers;
    };

    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'ADD_PROVIDER':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Access to services of selected Third Party Service Provider and share your Company details for their approval</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <AddProvider companyID={this.props.company.companyId} companyProviders={this.props.usermanagment.companyProviders} />
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'DEACTIVATE_PROVIDER':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.deactivateProvider(this.props.company.companyId, { providerCompanyID: this.state.modalData.companyID });
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-header-center"><h5 className="modal-title">Inactivate provider</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text">Are you sure you want to deactivate this provider?
                                        <br />
                                        Press back to return to previous screen or press confirm to continue.
                                        </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                        </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        {/* <img src={image} width="17px" /> */}
                                        <FontAwesomeIcon icon={faCheck} /> Confirm
            </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'ACTIVATE_PROVIDER':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.activateProvider(this.props.company.companyId, { providerCompanyID: this.state.modalData.companyID });
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-header-center"><h5 className="modal-title">Activate provider</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text">Are you sure you want to activate this provider?
                                            <br />
                                        Press back to return to previous screen or press confirm to continue.
                                            </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                            </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        {/* <img src={image} width="17px" /> */}
                                        <FontAwesomeIcon icon={faCheck} /> Confirm
                                            </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            default:
                break;
        }
    };

    render() {
        return (
            <React.Fragment>

                <section className="rbac-body">
                    {this.state.showModal && this.renderModal(this.state.modal)}

                    <button type="button" className="trades-dtls__verify-docs" onClick={() => this.openModal('ADD_PROVIDER')}>Access Service Providers</button>
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                        <MaterialTable

                            columns={[
                                { title: 'Company Name', field: 'companyName', },
                                { title: 'Company Type', field: 'companyType' },
                                { title: 'Company Approval Status by Service Provider', field: 'kycStatus' },
                                {
                                    title: 'Service',
                                    render: rowData => <span className={rowData.isActive ? 'badge badge-accepted' : 'badge badge-rejected'}>
                                        {rowData.isActive ? 'ACTIVE' : 'INACTIVE'}
                                    </span>
                                },
                                {
                                    title: 'Action',
                                    sorting: false,
                                    render: rowData => <div className="action-btn">
                                        {rowData.kycStatus === "APPROVED" ? (rowData.isActive ? (
                                            <Tooltip title="Inactivate Provider" placement="top-start" arrow >
                                                <span className="btn-icon btn-circle-icon btn-warning btn-icon-res" onClick={() => this.openModal('DEACTIVATE_PROVIDER', rowData)}><FontAwesomeIcon icon={faUserSlash} /></span>
                                            </Tooltip>
                                        ) : (
                                                <Tooltip title="Activate Provider" placement="top-start" arrow >
                                                    <span className="btn-icon btn-circle-icon btn-accept btn-icon-res" onClick={() => this.openModal('ACTIVATE_PROVIDER', rowData)}><FontAwesomeIcon icon={faUserPlus} /></span>
                                                </Tooltip>)
                                        ) : ''
                                        }
                                    </div>
                                }
                            ]}
                            data={this.getItems()}
                            title="Manage Service Providers"

                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                ResetSearch: Clear,
                            }}
                            options={{
                                pageSize: 5,
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </section>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        usermanagment: state.usermanagment,
        loading: state.loading.groupLoading,
        company: state.account.token
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            activateProvider,
            getProviderListByComapny,
            deactivateProvider,
            navigate: path => push('/group-userlist' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProviderList);
