import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFinalProviderQuoteList} from '../../../../modules/module.shipmentQuote';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { viewShippingQuotePermission } from '../../../../services/service.permission';
import '../trades.scss';
import { DATEFORMATHOURS,QUOTETYPE,QUOTESTATUS } from '../../../../services/service.values';
import Preloader from '../../../components/preloader/Preloader';
import MaterialTable from 'material-table';
import { EnumsService,PortService } from '../../../../services/service.utils';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { faEye, faTimes  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
class FinalShippingList extends Component {
    state = {
       
        showModal: true,
        modal: '',
        modalData: {},
    };

    UNSAFE_componentWillMount = () => {
        this.fetchFunction();
    };

    fetchFunction = () => {
            this.props.getFinalProviderQuoteList(QUOTETYPE.FINAL);
        
        this.setState({});
    };

    getCountryName(isoCode) {
        
        const countryName = EnumsService.countries()[isoCode.trim()];
        return countryName;
    }

    renderPortLabel = value => {
        let label = "";
        if(value){
            label = PortService.getJSON()[value]?PortService.getJSON()[value].name : value;
        }
        return label;    
    }
   

    getItems = () => {
        var quoteFinalList = this.props.shipmentQuote.finalProvderList.quotes;
        let filterFinalList=[];
        if( quoteFinalList!== undefined){
        for(var i = 0;i < quoteFinalList.length;i++){
            if(quoteFinalList[i].status !== QUOTESTATUS.REJECTED)
            {
                let data={
                    tradeRequestId:quoteFinalList[i].tradeRequestId,
                    fromCompanyName:quoteFinalList[i].fromCompany.companyName,
                    OriginCountry:quoteFinalList[i].OriginCountry,
                    destinationCountry:quoteFinalList[i].destinationCountry,
                    tonnage:quoteFinalList[i].quantity,
                    status:quoteFinalList[i].status,
                    quoteRequestDate:quoteFinalList[i].quoteRequestDate,
                    quoteResponseDate:quoteFinalList[i].quoteResponseDate,
                    mode:quoteFinalList[i].mode,
                    shippingQuoteId:quoteFinalList[i].shippingQuoteId,
                    tradeRefID:quoteFinalList[i].tradeRefID,
                    rate:quoteFinalList[i].rate,
                    destinationPort:quoteFinalList[i].destinationPort,
                    shipping:quoteFinalList[i].shipping
                };

                
                filterFinalList.push(data);
            }
        }
     }
        
        return filterFinalList;
    };
    getItemsTwo = () => {
        let quoteFilterList = this.props.shipmentQuote.finalProvderList.quotes; 
        let filterFinalList=[];
        if( quoteFilterList!== undefined){
        for(var i = 0;i < quoteFilterList.length;i++){
            if(quoteFilterList[i].status === QUOTESTATUS.REJECTED)
            {
                let data={
                    tradeRequestId:quoteFilterList[i].tradeRequestId,
                    fromCompanyName:quoteFilterList[i].fromCompany.companyName,
                    OriginCountry:quoteFilterList[i].OriginCountry,
                    destinationCountry:quoteFilterList[i].destinationCountry,
                    tonnage:quoteFilterList[i].quantity,
                    status:quoteFilterList[i].status,
                    quoteRequestDate:quoteFilterList[i].quoteRequestDate,
                    quoteResponseDate:quoteFilterList[i].quoteResponseDate,
                    mode:quoteFilterList[i].mode,
                    shippingQuoteId:quoteFilterList[i].shippingQuoteId,
                    tradeRefID:quoteFilterList[i].tradeRefID,
                    rate:quoteFilterList[i].rate,
                    destinationPort:quoteFilterList[i].destinationPort,
                    shipping:quoteFilterList[i].shipping
                };
                filterFinalList.push(data);
            }
        }
     }
        return filterFinalList;
    };
    

    returnStatusNumber = (status) => {
        switch (status) {
            case QUOTESTATUS.NEW:
                return "quote-status pending";
            case QUOTESTATUS.CANCELLED:
                return "quote-status orange";
            case QUOTESTATUS.COMPLETED:
                return "quote-status green";
            case QUOTESTATUS.REJECTED:
                return "quote-status primary";
            case QUOTESTATUS.INPROGRESS:
                 return "quote-status gray";
            case  QUOTESTATUS.WAITING_CONFIRMATION:
                 return "quote-status pending";       
            default:
                break;
        }
    };

    renderStatusLabel=(status,shipping)=>{
        let label="";
        switch(status){
            case QUOTESTATUS.NEW :label= "New Final Request"; break;
            case QUOTESTATUS.INPROGRESS :label= shipping==="CONTAINER"?"Booking Requested":"Offer Sent"; break;
            case QUOTESTATUS.COMPLETED :label=shipping==="CONTAINER"? "Booked":"Completed"; break;
            case QUOTESTATUS.CANCELLED :label= "Cancelled"; break;
            case QUOTESTATUS.REJECTED :label= "Rejected"; break;
            case QUOTESTATUS.WAITING_CONFIRMATION:label="Waiting Confirmation"; break;
            default : 
            break;
        }
    return label;
       
}

renderModal = () => {
            return (
                <div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0 authcode"
                        onSubmit={e => {
                            e.preventDefault();
                            this.closeModal();
                        }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div className="modal-body">
                                <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                            </div>
                            <div className="modal-footer">
                                <Link to="/" className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>
            );
};

openModal = (name, data) => {
    this.setState({ showModal: true, modal: name, modalData: data });
};

closeModal = () => {
    this.setState({
        showModal: false,
        modal: '',
        modalData: {},
      
    });
};
    
    render() {
        if (viewShippingQuotePermission() === 0 ) {
            return (
                <div>
                    {this.state.showModal && this.renderModal()}
                </div>
            );
        }
        return (
            <React.Fragment>
                <Header />
                <div className="trades-req-quotes-Container">
        <div className="Container">
       <div className="trades-req-quotes__heading"> <h3 className=" mb-1">Requests for Final Offer</h3></div>
            <nav className="mb-1">
                <ol className="breadcrumb">
                </ol>
            </nav>
        
            <div className="content-wrappper trades-req-quotes">
                <p>The following companies have requested a final offer for the shipment.
                </p>
                <div className="table-responsive-md">
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                        <MaterialTable

                            columns={[
                                { title: 'Trade Ref Id', field: 'tradeRefID',render: rowData => rowData.tradeRefID || rowData.tradeRequestId},
                                { title: 'Name', field: 'fromCompanyName'},
                                { title: 'Type', field: 'mode',
                                render: rowData => <span className="trades__overflow">{rowData.mode==="CY"?"Container":rowData.mode}    
                                </span>
                            },
                                { title: 'Origin', field: 'OriginCountry',
                                render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.OriginCountry)}    
                                </span>
                            },
                                { title: 'Destination Port', field: 'destinationPort',
                                render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--":this.renderPortLabel(rowData.destinationPort)}    
                                </span>
                            },
                                { title: 'Tonnage', field: 'tonnage',
                            
                                render: rowData => <NumberFormat 
                                value={rowData.tonnage} 
                                displayType={'text'} 
                                thousandSeparator={true}
                                suffix = {" MT"} >
                                </NumberFormat>},
                                { title: 'Rate', field: 'rate',
                                // render: rowData => <span
                                // className="trades__overflow">{(rowData.rate!==""? "$ "+ rowData.rate :"--")}</span>
                                render: rowData => <NumberFormat 
                                value={rowData.rate} 
                                displayType={'text'} 
                                thousandSeparator={true}
                                 renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                                 </NumberFormat>
                            },
                                { title: 'Status', field: 'status',
                                render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusLabel(rowData.status,rowData.shipping)}    
                                </span> 
                                 },
                                { title: 'Requested', field: 'quoteRequestDate',defaultSort: 'desc',
                                render: rowData => <span
                                className="trades__overflow">{moment(rowData.quoteRequestDate).format(DATEFORMATHOURS)}</span>},
                                { title: 'Response', field: 'quoteResponseDate',
                                render: rowData => <span
                                className="trades__overflow">{(rowData.quoteResponseDate!==""? moment(rowData.quoteResponseDate).format(DATEFORMATHOURS):"--")}</span>},
                                { title: 'Action', sorting: false, render: rowData => <div className="action-btn">
                                <Tooltip title="Details of Shipments " placement="top-start" arrow >
                                <Link className="btn-circle circle-btn admin" to={`/ShippingQuoteDetails/${rowData.tradeRequestId}/${rowData.shippingQuoteId}`}> 
                                        <FontAwesomeIcon icon={faEye} />
                                        </Link>
                                </Tooltip>
                            </div>},
                            ]}
                            data={this.getItems()}
                            title=""
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SaveIcon: SaveIcon
                            }}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: {
                                    borderColor: '#c3e6cb',
                                    backgroundColor: '##00ff301c'
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </div>
            </div>
            <div className="content-wrappper trades-req-quotes">
                <p>The following companies have requested a final offer for the shipment however you have rejected the request
                    this is can not be undone and status is final.
                </p>
                <div className="table-responsive-md">
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                        <MaterialTable
                            columns={[
                                { title: 'Trade Ref Id', field: 'tradeRefID',render: rowData => rowData.tradeRefID || rowData.tradeRequestId},
                               { title: 'Name', field: 'fromCompanyName'},
                               { title: 'Type', field: 'mode',
                                render: rowData => <span className="trades__overflow">{rowData.mode==="CY"?"Container":rowData.mode}    
                                </span>
                            },
                               { title: 'Origin', field: 'OriginCountry',
                               render: rowData => <span className="trades__overflow">{this.getCountryName(rowData.OriginCountry)}    
                               </span>
                            },
                               { title: 'Destination Port', field: 'destinationPort',
                               render: rowData => <span className="trades__overflow">{rowData.destinationPort==="" ?"--":this.renderPortLabel(rowData.destinationPort)}    
                               </span>
                            },
                               { title: 'Tonnage', field: 'tonnage',
                            
                               render: rowData => <NumberFormat 
                               value={rowData.tonnage} 
                               displayType={'text'} 
                               thousandSeparator={true}
                               suffix = {" MT"} >
                               </NumberFormat>},
                               { title: 'Rate', field: 'rate',
                            //    render: rowData => <span
                            //    className="trades__overflow">{(rowData.rate!==""? "$ "+ rowData.rate :"--")}</span>
                            render: rowData => <NumberFormat 
                            value={rowData.rate} 
                            displayType={'text'} 
                            thousandSeparator={true}
                             renderText={value => <span className="trades__overflow">{value!=="" ? '$ '+value : '--'}</span>} >
                             </NumberFormat>
                            },
                               { title: 'Status', field: 'status',
                               render: rowData => <span className={this.returnStatusNumber(rowData.status)} >{this.renderStatusLabel(rowData.status,rowData.shipping)}    
                               </span> 
                                },
                               { title: 'Requested', field: 'requestedDate',defaultSort: 'desc',
                               render: rowData => <span
                               className="trades__overflow">{moment(rowData.quoteRequestDate).format(DATEFORMATHOURS)}</span>},
                               { title: 'Response', field: 'quoteResponseDate',
                               render: rowData => <span
                               className="trades__overflow">{(rowData.quoteResponseDate!==""? moment(rowData.quoteResponseDate).format(DATEFORMATHOURS):"--")}</span>},
                               { title: 'Action', sorting: false, render: rowData => <div className="action-btn">
                               <Tooltip title="Details of Shipments " placement="top-start" arrow >
                               <Link className="btn-circle circle-btn admin" to={`/ShippingQuoteDetails/${rowData.tradeRequestId}/${rowData.shippingQuoteId}`}> 
                                       <FontAwesomeIcon icon={faEye} />
                                       </Link>
                               </Tooltip>
                           </div>},
                           ]}
                            data={this.getItemsTwo()}
                            title=""
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SaveIcon: SaveIcon
                            }}
                            options={{
                                pageSize: 5,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: {
                                    borderColor: '#c3e6cb',
                                    backgroundColor: '##00ff301c'
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </div>
            </div>

        </div>
    </div>
                <Footer />
            </React.Fragment>
        );
      
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        user: state.account.user,
        shipmentQuote:state.shipmentQuote,
        loading: state.loading.groupLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFinalProviderQuoteList,
            navigate: path => push('/finalShippingList' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FinalShippingList);