import React, {useMemo, useState} from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {EnumsService} from '../../../../services/service.utils'
import {getPrices} from '../../../../modules/module.tender';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import {CURRENCYOPT} from '../../../../services/service.values'
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';

const CompareTableMultipleOrigin =  ({trade, bids, account, loadingBids}) => {

    const [inverted, setInverted] = useState(false)
    

    const toggleView = () => setInverted(!inverted);

    const renderPrice = (price, currency) => (<span>
            {price?
            <NumberFormat value={price} 
                displayType={'text'} 
                thousandSeparator={true}
                prefix={(CURRENCYOPT[currency] || "USD").concat(" ")} />
            :'-'}
        </span>)


    // Columns ( companies )
    const columns = useMemo(() => {
        if(trade.items.single){
            const countries = trade.items.single.origin.split(',');
            return [
                {title: 'Comapny', field: 'fromCompanyName'},
                ...countries.map(country => ({ 
                    title: EnumsService.countries()[country],
                    field: 'multipleOriginPrice',
                    customSort: (a, b) => {
                        let priceA = a.multipleOriginPrice.find(item => item.origin === country).price;
                        let priceB = b.multipleOriginPrice.find(item => item.origin === country).price;
                        return priceB - priceA
                    },
                    // eslint-disable-next-line react/display-name
                    render: rowData => {
                        let price = 0
                        if(rowData.multipleOriginPrice && Array.isArray(rowData.multipleOriginPrice)){
                            let originPrice = rowData.multipleOriginPrice.find(item => item.origin === country);
                            if(originPrice)
                                price = originPrice.price;
                        }
                        return renderPrice(price, trade.items.single.currency);
                    }
                }))
            ]
        }else{
            return [];
        }
    }, [trade.items.single])


    // Inverted Columns ( origins )
    const invertedColumns = [
        { title: 'Origin', field: 'country', render: rowData => EnumsService.countries()[rowData.country]},
        ...bids.filter(bid => (bid.fromCompanyID !== account.token.companyId && bid.status === 'NEW' && moment().isBefore(bid.bidValidateDate))).map(item => ({ 
            title: item.fromCompanyName,
            field: item.fromCompanyID,
            customSort: (a,b) => (b[item.fromCompanyID].price - a[item.fromCompanyID].price),
            render: rowData => {
                const price = (rowData[item.fromCompanyID])?rowData[item.fromCompanyID].price:0;
                return renderPrice(price, trade.items.single.currency);
            }
        }))
    ];

    // Inverted Data
    const invertedData = useMemo(() => {
        let _newData = [];
        const countries = (trade.items.single)?trade.items.single.origin.split(','):[];
        countries.forEach((country, index) => {
            let serie = { 
                country,
                lowest: 0,
                highest: 0
            }
            bids.filter(bid => bid.fromCompanyID !== account.token.companyId && bid.status === 'NEW').forEach(bid => {
                let price = bid.multipleOriginPrice[country];
                if(price > bids[serie.lowest].multipleOriginPrice[country])
                    serie.heighest = index;
                if(price < bids[serie.lowest].multipleOriginPrice[country])
                    serie.lowest = index;
    
                serie[bid.fromCompanyID] = bid.multipleOriginPrice.find(item => item.origin === country);  
            })
            _newData.push(serie)
        })
        return _newData;
    }, [bids, trade, account.token.companyId])
        
    return <div>
        <div className="text-right"><button className="btn btn--blue btn-sm" onClick={toggleView}>Toggle View</button></div>
        {inverted ? 
        <MaterialTable
            key="inverted"
            data={invertedData}
            isLoading= {loadingBids}
            icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                SortArrow: ArrowUpward,
            }}
            columns={invertedColumns}
            options= {{
                showTitle:false,
                search: false,
                thirdSortClick: false
            }}
        />
        :
        <MaterialTable
            key="non-inverted"
            data={bids.filter(bid => (bid.fromCompanyID !== account.token.companyId && bid.status === "NEW" && moment().isBefore(bid.bidValidateDate)))}
            isLoading= {loadingBids}
            icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                SortArrow: ArrowUpward,
            }}
            columns={columns}
            options= {{
                showTitle:false,
                search: false,
                thirdSortClick: false
            }}
        />}
    </div>
}


const mapStateToProps = state => {
    return {
        trade: state.trade,
        account: state.account,
        loadingBids: state.loading.bidLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({getPrices}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompareTableMultipleOrigin);
