import React from 'react';
import { DATEFORMAT } from '../../../../services/service.values';
import moment from 'moment-timezone';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailTable from '../../requests/detail/DetailTable';
import Preloader from '../../../components/preloader/Preloader';
import OtherInspectorFormatter from '../../../components/Formatter/OtherInspectorFormatter';

const getInspectionCompanyName = (companyName, serie,inspector) => {
        if (inspector === "1") {
            return <OtherInspectorFormatter inspector={companyName}/>
        }
        return companyName;
};

export const viewSubstitueVesselInfo = ({onApproveWithNewLaycan, onClose, onReject, vesselInfo, inspectionName,whoItIs,incoterm,isLaycanDateChanged,LoadingNominationInfo,vesselAccepted,inspector }) => {
    // let vesselNominationInfo = {...vesselInfo, inspectionName: inspectionName }
let fieldList = [
{key: 'name', label: 'Vessel Name'},
{key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
{key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
{key: 'inspectionName', label: "Inspection Company",format: (value,serie) => getInspectionCompanyName(value,serie,inspector)},
{key: 'message', label: 'Vessel Details'}
]
if(incoterm === "FOB"){
    fieldList.push({key: 'rejectMessage', label: 'Reason to reject vessel'})
}
return (
<React.Fragment>
    <div className='modal__container vessel_modal accpet_with_new_laycan'>
        <form className='modal__wrapper px-0 py-0 laycan-calendar' onSubmit={onApproveWithNewLaycan} noValidate={true}>
        <div className="modal-content">
        <div className="modal-header">
                        <div className="modal-header-center"> <h5 className="modal-title">View Substitute Vessel Nomination</h5>
                        {/* {(incoterm==="FOB") &&
                        <div className="mb-0">
                           Please note that laycan date has been changed by seller. Please approve the vessel.
                        </div>} */}
                        </div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
            <div className='modal-body'>        
            <div className='pt-1'>
            <Preloader loadingStyle="swirl" loading={LoadingNominationInfo}>
                <DetailTable
                    fixWidth={false}
                    series={vesselInfo}
                    fieldList={fieldList}>
                </DetailTable>
                </Preloader>
            </div>
            </div>
            </div>
            <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                <button type="button" className="btn-popup btn-reject-vessel" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            {whoItIs==="buyer" &&
            <div>
              {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&  <button type="button" className="btn-popup btn-reject-vessel" onClick={onReject}>
                    <FontAwesomeIcon icon={faTimes} /> Reject
            </button>}
            {(incoterm==="FOB" && isLaycanDateChanged && !vesselAccepted) &&   <button type="submit" className="btn-popup btn-submit-vessel">
            <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                    <FontAwesomeIcon icon={faCheck} /> Accept
            </Preloader>
            </button>}
            </div>}
            </div>
        </form>
    </div>
</React.Fragment>
)
};