import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Default component renderer for numbers in RicexProject. All modifications that should affect every numbers in the app should be done here.
 * @param  {string} value - Number to format
 * @component
 * @example
 * return (<RxNumberFormat value={'14567'} suffix={'any suffix'}/>)
 */
const RxNumberFormat = ({ value, displayType = 'text', thousandSeparator = true, ...props }) => {
	
	return (
		<NumberFormat
			value={value}
			displayType={displayType}
			decimalScale={2}
			thousandSeparator={thousandSeparator}
			{...props}
		>
		</NumberFormat>)
}

RxNumberFormat.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	displayType: PropTypes.oneOf(['text', 'input']),
	thousandSeparator: PropTypes.bool
}

RxNumberFormat.defaultProps = {
	displayType: 'text',
	thousandSeparator: true
}

export default RxNumberFormat;