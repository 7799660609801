import _ from "lodash";
import { EnumsService } from "./service.utils";

class Fertilizer {
    constructor(name, label, category, abbreviation, specifications){
        this.name = name;
        this.label = label;
        this.category = category;
        this.abbreviation = abbreviation;
        this.specifications = specifications;
    }
}

class Specification {
    constructor(name, label, value, unit, inputType, minValue, maxValue, disabled){
        this.name = name;
        this.label = label;
        this.value = value;
        this.unit = unit;
        this.inputType = inputType;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.disabled = disabled;
    }
}

const UNITS = {
    PCT_MIN: "PCT MIN",
    PCT_MAX: "PCT MAX",
    PPM_MIN: "PPM MIN",
    PPM_MAX: "PPM MAX",
    PPM: "ppm",
    MM: "mm"
}

const FERTILIZERS_MAP = [
    new Fertilizer('UREA_PRILLED', 'Urea Prilled', 'N', 'PU', [
        new Specification('nitrogen', 'Total Nitrogen', 46, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('buiret', 'Buiret', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 0.5, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 2, "MM", 'number', 0.5, 6, false),
    ]),
    new Fertilizer('UREA_GRANULAR', 'Urea Granular', 'N', 'GU', [
        new Specification('nitrogen', 'Total Nitrogen', 46, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('buiret', 'Buiret', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('AMMONIUM_SULPHATE_CAPRO', 'Ammonium Sulphate - Capro', 'N', 'Amsul Capro', [
        new Specification('nitrogen', 'Total Nitrogen', 21, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sulphur', 'Total Sulphur', 24, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'White/Off white', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry', 'Crystalline Form', '', 'input', null, null, false)
    ]),
    new Fertilizer('AMMONIUM_SULPHATE_GRANULAR', 'Ammonium Sulphate - Granular', 'N', 'Amsul Gran', [
        new Specification('nitrogen', 'Total Nitrogen', 21, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sulphur', 'Total Sulphur', 24, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'White color, Compacted', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 5, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('AMMONIUM_SULPHATE_OTHER', 'Ammonium Sulphate - Other', 'N', 'Amsul Other', [
        new Specification('nitrogen', 'Total Nitrogen', 20.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sulphur', 'Total Sulphur', 24, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'White/Off white', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry', 'Crystalline Form', '', 'input', null, null, false)
    ]),
    new Fertilizer('AMMONIUM_NITRATE', 'Ammonium Nitrate', 'N', 'AN', [
        new Specification('nitrogen', 'Total Nitrogen', 34.4, "PCT_MIN", 'number', 0, 100, false),
        new Specification('ammoniacalNitrogen', 'Ammoniacal Nitrogen', 17.2, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrateNitrogen', 'Nitrate Nitrogen', 17.2, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('buiret', 'Buiret', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 1, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('CALCIUM_AMMONIUM_NITRATE', 'Calcium Ammonium Nitrate', 'N', 'CAN', [
        new Specification('nitrogen', 'Total Nitrogen', 27, "PCT_MIN", 'number', 0, 100, false),
        new Specification('ammoniacalNitrogen', 'Ammoniacal Nitrogen', 13.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrateNitrogen', 'Nitrate Nitrogen', 13.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('calciumOxide', 'Calcium Oxide (CaO)', 12, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 1, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('UREA_AMMONIUM_NITRATE', 'Urea Ammonium Nitrate', 'N', 'UAN', [
        new Specification('nitrogen', 'Total Nitrogen', 32, "PCT_MIN", 'number', 0, 100, false),
        new Specification('ammoniacalNitrogen', 'Ammoniacal Nitrogen', 8, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrateNitrogen', 'Nitrate Nitrogen', 8, "PCT_MIN", 'number', 0, 100, false),
        new Specification('ureicNitrogen', 'Ureic Nitrogen', 16, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minInhibitor', 'Inhibitor (min)', 100, "PPM", 'number', 0, 10000, false),
        new Specification('maxInhibitor', 'Inhibitor (max)', 140, "PPM", 'number', 0, 10000, false),
        new Specification('minFreeAmmonia', 'Free Ammonia (min)', 0, 'PPM', 'number', 0, 10000, false),
        new Specification('maxFreeAmmonia', 'Free Ammonia (max)', 200, 'PPM', 'number', 0, 10000, false),
        new Specification('water', 'Water', 20.2, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'Transparent liquid', '', 'input', null, null, false)
    ]),
    new Fertilizer('MONO_AMMONIUM_PHOSPHATE', 'Mono Ammonium Phosphate', 'P', 'MAP', [
        new Specification('phosphate', 'Total Phosphate (P2O5)', 52, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrogen', 'Total Nitrogen', 11, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterSolublePhosphate', 'Water Soluble Phosphate (P2O5)', 47, "PCT_MIN", 'number', 0, 100, false),
        /*
        //RDEV-2514
        new Specification('cadmium', 'Cadmium (Cd)', 25, "PPM_MAX", 'number', 0, 100, false),
        new Specification('pb', 'Pb', 50, 'PPM_MAX', 'number', 0, 100, false),
        */
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('DI_AMMONIUM_PHOSPHATE', 'Di Ammonium Phosphate', 'P', 'DAP', [
        new Specification('phosphate', 'Total Phosphate (P2O5)', 46, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrogen', 'Total Nitrogen', 18, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 2.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterSolublePhosphate', 'Water Soluble Phosphate (P2O5)', 42, "PCT_MIN", 'number', 0, 100, false),
        /*
        //RDEV-2514
        new Specification('cadmium', 'Cadmium (Cd)', 25, "PPM_MAX", 'number', 0, 100, false),
        new Specification('pb', 'Pb', 50, 'PPM_MAX', 'number', 0, 100, false),
        */
        new Specification('appearance', 'Appearance', 'Dark Brown', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('TRIPLE_SUPER_PHOSPHATE', 'Triple Super Phosphate', 'P', 'TSP', [
        new Specification('phosphate', 'Total Phosphate (P2O5)', 46, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrogen', 'Total Nitrogen', 0, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 12, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterSolublePhosphate', 'Water Soluble Phosphate (P2O5)', 42.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('freePhosphoricAcid', 'Free Phosphoric Acid (P2O5)', 3, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('14-34', '14-34', 'N+P', 'NP', [
        new Specification('nitrogen', 'Total Nitrogen', 14, "PCT_MIN", 'number', 0, 100, false),
        new Specification('phosphate', 'Total Phosphate (P2O5)', 34, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('16-44', '16-44', 'N+P', 'DAP Lite', [
        new Specification('nitrogen', 'Total Nitrogen', 16, "PCT_MIN", 'number', 0, 100, false),
        new Specification('phosphate', 'Total Phosphate (P2O5)', 44, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('x-x-x', 'N-P-K', 'N+P+K', 'N-P-K', [
        new Specification('nitrogen', 'Total Nitrogen', '', "PCT_MIN", 'number', 0, 100, false),
        new Specification('phosphate', 'Total Phosphate (P2O5)', '', "PCT_MIN", 'number', 0, 100, false),
        new Specification('potash', 'Total Potash (K2O)', '', "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('MURIATE_OF_POTASH', 'Muriate of Potash', 'K', 'MOP', [
        new Specification('potassium', 'Total Potassium (K2O)', 60, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sodium', 'Sodium (NaCl)', 3.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 4, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('SULPHATE_OF_POTASH', 'Sulphate of Potash', 'K', 'SOP', [
        new Specification('potassium', 'Total Potassium (K2O)', 52, "PCT_MIN", 'number', 0, 100, false),
        new Specification('chlorine', 'Chlorine', 1.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 1.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('sulphur', 'Sulphur', 17, "PCT_MIN", 'number', 0, 100, false),
        new Specification('waterSolubility', 'Water Solubility', 99.8, "PCT_MIN", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry', '', '', 'input', null, null, false)
    ]),
    new Fertilizer('POTASSIUM_NITRATE', 'Potassium Nitrate', 'WSF', 'NOP', [
        new Specification('potassiumNitrate', 'Potassium Nitrate (KNO3)', 98, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.3, "PCT_MAX", 'number', 0, 100, false),
        new Specification('carbonate', 'Carbonate (K2CO3)', 0.01, "PCT_MAX", 'number', 0, 100, false),
        new Specification('sulphate', 'Sulphate (SO4)', 0.01, "PCT_MAX", 'number', 0, 100, false),
        new Specification('chloride', 'Chloride (Cl)', 0.1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterInsolubleMatter', 'Water Insoluble Matter', 0.05, "PCT_MAX", 'number', 0, 100, false),
        new Specification('hygroscopicity', 'Hygroscopicity', 0.3, "PCT_MAX", 'number', 0, 100, false),
        new Specification('minPH', 'pH Value (min)', 6, '', 'number', 1, 14, false),
        new Specification('maxPH', 'pH Value (max)', 7, '', 'number', 1, 14, false)
    ]),
    new Fertilizer('MONO_POTASSIUM_PHOSPHATE', 'Mono Potassium Phosphate', 'WSF', 'MKP', [
        new Specification('waterSolublePhosphate', 'Water Soluble Phosphate (P2O5)', 52, "PCT_MIN", 'number', 0, 100, false),
        new Specification('potassium', 'Total Potassium (K2O)', 34, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minPH', 'pH Value (min)', 4.3, '', 'number', 1, 14, false),
        new Specification('maxPH', 'pH Value (max)', 4.7, '', 'number', 1, 14, false),
        new Specification('moisture', 'Moisture', 0.3, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterInsolubleMatter', 'Water Insoluble Matter', 0.2, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'White Crystal', '', 'input', null, null, false)
    ]),
    new Fertilizer('CALCIUM_NITRATE_GRANULAR', 'Calcium Nitrate - Granular', 'WSF', 'CN Gran', [
        new Specification('nitrogen', 'Total Nitrogen', 15.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('ammoniacalNitrogen', 'Ammoniacal Nitrogen', 1.1, "PCT_MIN", 'number', 0, 100, false),
        new Specification('nitrateNitrogen', 'Nitrate Nitrogen', 14.4, "PCT_MIN", 'number', 0, 100, false),
        new Specification('calciumOxide', 'Calcium Oxide (CaO)', 26, "PCT_MIN", 'number', 0, 100, false),
        new Specification('waterInsolubleMatter', 'Water Insoluble Matter', 0.1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('minPH', 'pH Value (min)', 5, '', 'number', 1, 14, false),
        new Specification('maxPH', 'pH Value (max)', 7, '', 'number', 1, 14, false),
        new Specification('appearance', 'Appearance', 'White', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 5, "MM", 'number', 0.5, 6, false)
    ]),
    new Fertilizer('CALCIUM_NITRATE_CRYSTAL', 'Calcium Nitrate - Crystal', 'WSF', 'CN Crystal', [
        new Specification('nitrogen', 'Total Nitrogen', 11.74, "PCT_MIN", 'number', 0, 100, false),
        new Specification('calciumOxide', 'Calcium Oxide (CaO)', 23.4, "PCT_MIN", 'number', 0, 100, false),
        new Specification('purity', 'Purity', 98, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sulphate', 'Sulphate', 0.03, "PCT_MAX", 'number', 0, 100, false),
        new Specification('chlorides', 'Chlorides', 0.005, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterInsolubleMatter', 'Water Insoluble Matter', 0.1, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'Crystal', '', 'input', null, null, false)
    ]),
    new Fertilizer('MAGNESIUM_SULPHATE', 'Magnesium Sulphate', 'WSF', 'Kieserite', [
        new Specification('magnesiumOxide', 'Total MgO', 27, "PCT_MIN", 'number', 0, 100, false),
        new Specification('waterSolubleMgO', 'Water Soluble MgO', 25, "PCT_MIN", 'number', 0, 100, false),
        new Specification('sulphur', 'Sulphur', 21, "PCT_MIN", 'number', 0, 100, false),
        new Specification('so3', 'SO3', 50, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', 'White/Off White', '', 'input', null, null, false),
        new Specification('granulometry', 'Granulometry Between', 90, "PCT_MIN", 'number', 0, 100, false),
        new Specification('minParticleSize', 'MIN', 2, "MM", 'number', 0.5, 6, false),
        new Specification('maxParticleSize', 'MAX', 5, "MM", 'number', 0.5, 6, false),
    ]),
    new Fertilizer('TECHNICAL_MONO_AMMONIUM_PHOSPHATE', 'Technical Mono Ammonium Phosphate', 'WSF', 'TMAP', [
        new Specification('nitrogen', 'Total Nitrogen', 11.8, "PCT_MIN", 'number', 0, 100, false),
        new Specification('phosphate', 'Total Phosphate (P2O5)', 60.5, "PCT_MIN", 'number', 0, 100, false),
        new Specification('moisture', 'Moisture', 0.5, "PCT_MAX", 'number', 0, 100, false),
        new Specification('waterInsolubleMatter', 'Water Insoluble Matter', 0.2, "PCT_MAX", 'number', 0, 100, false),
        new Specification('appearance', 'Appearance', '', '', 'input', null, null, false)
    ]),
    // RDEV-2618 Add the possibility to select organic as a new product type in the fertilizer type dropdown when a trade is created 
    /*
    RDEV-2618
    Add an empty array to avoid validation error when selecting fertilizer type when we create a trade request  
    */
    new Fertilizer('ORGANIC', 'Organic', 'Organic', 'Organic', [])
];

const isEmpty = value => value === null || value === undefined || value === '';

const isSpecInvalid = ({inputType, minValue, maxValue, value}) => {
    let isInvalid = isEmpty(value);
    if(inputType === 'number'){
        if(minValue !== null && maxValue === null){
            isInvalid = isEmpty(value) || parseFloat(value) < minValue;
        }
        if(minValue === null && maxValue !== null){
            isInvalid = isEmpty(value) || parseFloat(value) > maxValue;
        }
        if(minValue !== null && maxValue !== null){
            isInvalid = isEmpty(value) || parseFloat(value) < minValue || parseFloat(value) > maxValue;
        }
    }
    return isInvalid
}

export const FertilizerService = {
    getAllFertilizers: (withFullName=false) => {
        return _.chain(FERTILIZERS_MAP)
                .reduce((prev, item) => {
                    prev[item.name] = withFullName ? item.label : item.abbreviation;
                        return prev;
                    }, {})
                .value();
    },
    getAvailableCountries: () => {
        let list = Object.entries(EnumsService.countries()).map(([countryCode, value]) => {
            return { label: value, value: countryCode }
        })
        return list.sort();
    },
    getSelectedCountries: (countries = []) => {
        let result = [];
        _.forEach(countries, countryCode => {
            result.push({ label: EnumsService.countries()[countryCode], value: countryCode })
        })
        return result;
    },
    getSpecifications: (fertilizer) => {
        const data = _.chain(FERTILIZERS_MAP).find(item => item.name === fertilizer).value();
        return data ? data.specifications.map(item => ({...item})) : []
    },
    specWrapperClass: (name, inputType) => {
        let wrapperClass = '';
        switch(name){
            case 'minParticleSize':
            case 'maxParticleSize':
                   wrapperClass = 'col-md-2';
                break;
            case 'granulometry':
                wrapperClass = inputType === 'number' ? 'col-md-4' : 'col-md-6';
                break;
            case 'minInhibitor':
            case 'maxInhibitor':
            case 'minPH':
            case 'maxPH':
            case 'maxFreeAmmonia':
            case 'minFreeAmmonia':
                wrapperClass = 'col-md-3';
                break;
            default:
                wrapperClass = 'col-md-6'
                break;
        }
        return wrapperClass;
    },
    validateSpec: (defaultSpec) => isSpecInvalid(defaultSpec),
    areSpecsInvalid: (specs) => {
        let specsInvalid = false;
        for(let i = 0; i < specs.length; i++){
            if(specs[i].name !== 'custom'){
                specsInvalid = isSpecInvalid(specs[i]);
                if(!specsInvalid && _.includes(['maxParticleSize', 'maxInhibitor', 'maxPH', 'maxFreeAmmonia'], specs[i].name)){
                    const minValueName = 'min' + specs[i].name.slice(3);
                    const minObj = _.find(specs, data => data.name === minValueName);
                    specsInvalid = specsInvalid || parseFloat(specs[i].value) < parseFloat(minObj.value) 
                }
            }else{
                specsInvalid = specs[i].name === 'custom' && (isEmpty(specs[i].label) || isEmpty(specs[i].value))
            }
            if(specsInvalid) break;
        }
        return specsInvalid;
    },
    getAvailableOriginCountries: (region) => {
        var list = [];
            list = Object.entries(regionCountries(region)).map(([countryCode, value]) => {
                return { label: value, value: countryCode }
            })
        return list.sort();
    },
    getSelectedOriginCountries: (region, countries = []) => {
        let result = [];
        let filterCountry = [];
        if (region !== "OPEN") {
            let country = Object.keys(regionCountries(region));
            filterCountry = countries.filter(item => country.includes(item));
        } else {
            filterCountry = countries;
        }
        _.forEach(filterCountry, countryCode => {
            result.push({ label: regionCountries(region)[countryCode], value: countryCode })
        })
        return result;
    },
    enums: {
        UNITS: UNITS
    }
}
export const regionCountries = (region) => {
    var countryLits = [];
    switch (region) {
        case 'OPEN':
            countryLits = {...EnumsService.countries()};
            break;
        case 'INDIA_SUB_CONTINENT':
            countryLits = {
                BD: 'Bangladesh',
                BT: 'Bhutan',
                IN: 'India',
                MV: 'Maldives',
                NP: 'Nepal',
                PK: 'Pakistan',
                LK: 'Sri Lanka'
            }
            break;
        case 'NORTH_AMERICA':
            countryLits = {
                CA: 'Canada',
                PM: 'Saint Pierre and Miquelon',
                US: 'United States',
                MX: 'Mexico',
            }
            break;
        case 'SOUTH_AMERICA':
            countryLits = {
                AI: 'Anguilla',
                AG: 'Antigua and Barbuda',
                AR: 'Argentina',
                AW: 'Aruba',
                BS: 'Bahamas, The',
                BB: 'Barbados',
                BZ: 'Belize',
                BM: 'Bermuda',
                BO: 'Bolivia',
                BV: 'Bouvet Island',
                BR: 'Brazil',
                KY: 'Cayman Islands',
                CL: 'Chile',
                CO: 'Colombia',
                CR: 'Costa Rica',
                CU: 'Cuba',
                DM: 'Dominica',
                DO: 'Dominican Republic',
                EC: 'Ecuador',
                SV: 'El Salvador',
                FK: 'Falkland Islands (Islas Malvinas)',
                GF: 'French Guiana',
                GD: 'Grenada',
                GP: 'Guadeloupe',
                GT: 'Guatemala',
                GY: 'Guyana',
                HT: 'Haiti',
                HN: 'Honduras',
                JM: 'Jamaica',
                MQ: 'Martinique',
                MS: 'Montserrat',
                AN: 'Netherlands Antilles',
                NI: 'Nicaragua',
                PA: 'Panama',
                PY: 'Paraguay',
                PE: 'Peru',
                PR: 'Puerto Rico',
                KN: 'Saint Kitts and Nevis',
                LC: 'Saint Lucia',
                VC: 'Saint Vincent and the Grenadines',
                GS: 'South Georgia & South Sandwich Islands',
                SR: 'Suriname',
                TT: 'Trinidad and Tobago',
                TC: 'Turks and Caicos Islands',
                UY: 'Uruguay',
                VE: 'Venezuela',
                VG: 'British Virgin Islands',
                VI: 'U.S. Virgin Islands',
            }
            break;
        case 'RUSSIA_FSU_BALTIC':
            countryLits = {
                AM: 'Armenia',
                AZ: 'Azerbaijan',
                BY: 'Belarus',
                EE: 'Estonia',
                GE: 'Georgia',
                KZ: 'Kazakhstan',
                KG: 'Kyrgyzstan',
                LV: 'Latvia',
                LT: 'Lithuania',
                MD: 'Moldova',
                RU: 'Russia',
                TJ: 'Tajikistan',
                TM: 'Turkmenistan',
                UA: 'Ukraine',
                UZ: 'Uzbekistan',
            }
            break;
        case 'EUROPE':
            countryLits = {
                AM: 'Armenia',
                AZ: 'Azerbaijan',
                BY: 'Belarus',
                EE: 'Estonia',
                GE: 'Georgia',
                KZ: 'Kazakhstan',
                KG: 'Kyrgyzstan',
                LV: 'Latvia',
                LT: 'Lithuania',
                MD: 'Moldova',
                RU: 'Russia',
                TJ: 'Tajikistan',
                TM: 'Turkmenistan',
                UA: 'Ukraine',
                UZ: 'Uzbekistan',
            }
            break;
        case 'AG_MENA':
            countryLits = {
                DZ: 'Algeria',
                BH: 'Bahrain',
                KM: 'Comoros',
                DJ: 'Djibouti',
                EG: 'Egypt',
                IR: 'Iran',
                IQ: 'Iraq',
                IL: 'Israel',
                JO: 'Jordan',
                KW: 'Kuwait',
                LB: 'Lebanon',
                LY: 'Libya',
                MR: 'Mauritania',
                MA: 'Morocco',
                OM: 'Oman',
                PS: 'Palestinian Territory',
                QA: 'Qatar',
                SA: 'Saudi Arabia',
                SO: 'Somalia',
                SD: 'Sudan',
                SY: 'Syria',
                TN: 'Tunisia',
                AE: 'United Arab Emirates',
                YE: 'Yemen'
            }
            break;
        case 'AFRICA':
            countryLits = {
                AO: "Angola",
                BJ: "Benin",
                BW: "Botswana",
                BF: 'Burkina Faso',
                BI: 'Burundi',
                CM: 'Cameroon',
                CV: 'Cape Verde',
                CF: 'Central African Republic',
                TD: 'Chad',
                CD: 'Congo, (Congo  Kinshasa)',
                CG: 'Congo, (Congo  Brazzaville)',
                CI: 'Cote d\'Ivoire (Ivory Coast)',
                GQ: 'Equatorial Guinea',
                ER: 'Eritrea',
                ET: 'Ethiopia',
                GA: 'Gabon',
                GM: 'Gambia, The',
                GH: 'Ghana',
                GN: 'Guinea',
                GW: 'Guinea-Bissau',
                KE: 'Kenya',
                LS: 'Lesotho',
                LR: 'Liberia',
                MG: 'Madagascar',
                MW: 'Malawi',
                ML: 'Mali',
                MU: 'Mauritius',
                YT: 'Mayotte',
                MZ: 'Mozambique',
                NA: 'Namibia',
                NE: 'Niger',
                NG: 'Nigeria',
                RW: 'Rwanda',
                SH: 'Saint Helena',
                ST: 'Sao Tome and Principe',
                SN: 'Senegal',
                SC: 'Seychelles',
                SL: 'Sierra Leone',
                ZA: 'South Africa',
                SZ: 'Swaziland',
                TZ: 'Tanzania',
                TG: 'Togo',
                UG: 'Uganda',
                ZM: 'Zambia',
                ZW: 'Zimbabwe',
            }
            break;
        case 'ASIA':
            countryLits = {
                AF: 'Afghanistan',
                IO: 'British Indian Ocean Territory',
                BN: 'Brunei',
                KH: 'Cambodia',
                CN: 'China, People\'s Republic of',
                CX: 'Christmas Island',
                CC: 'Cocos (Keeling) Islands',
                TF: 'French Southern and Antarctic Lands',
                HM: 'Heard Island and McDonald Islands',
                HK: 'Hong Kong',
                ID: 'Indonesia',
                JP: 'Japan',
                KP: 'Korea, North',
                KR: 'Korea, South',
                LA: 'Laos',
                MO: 'Macau',
                MY: 'Malaysia',
                MN: 'Mongolia',
                MM: 'Myanmar (Burma)',
                NF: 'Norfolk Island',
                PH: 'Philippines',
                PN: 'Pitcairn Islands',
                RE: 'Reunion',
                SG: 'Singapore',
                TW: 'China, Republic of (Taiwan)',
                TH: 'Thailand',
                TL: 'Timor-Leste (East Timor)',
                VN: 'Vietnam',   
            }
            break;
        case 'OCEANIA':
            countryLits = {
                AS: 'American Samoa',
                AU: 'Australia',
                CK: 'Cook Islands',
                FJ: 'Fiji',
                PF: 'French Polynesia',
                GU: 'Guam',
                KI: 'Kiribati',
                MH: 'Marshall Islands',
                FM: 'Micronesia',
                NR: 'Nauru',
                NC: 'New Caledonia',
                NZ: 'New Zealand',
                NU: 'Niue',
                MP: 'Northern Mariana Islands',
                PW: 'Palau',
                PG: 'Papua New Guinea',
                WS: 'Samoa',
                SB: 'Solomon Islands',
                TK: 'Tokelau',
                TO: 'Tonga',
                TV: 'Tuvalu',
                VU: 'Vanuatu',
                WF: 'Wallis and Futuna',
            }
            break;
        default:
          //  countryLits = {...EnumsService.countries(), PS: 'Palestinian Territory'};
            break;
    }
    return countryLits;
}