import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';
import flatten from 'lodash/flatten';
import ReactGA from 'react-ga';
import NumberFormat from 'react-number-format';
import {
    AutoupdateTriggered,
    loadRequestDetails,
    LoadRequestInfo,
    GetTradeDocuments,
    GetTradeInvoice,
    GetTradeInvoiceByShipmentId,
    GetTradePerformaInvoice,
    PostTradeDocument,
    PostTradeDocumentInvoice,
    UploadProformaPaymenntReciept,
    UploadInvoicePaymentProof,
    ReuploadInvoicePaymentProof,
    ConfirmInvoicePayment,
    GetProformaPaymentReciepts,
    GetInvoicePaymentProofs,
    PostDownpaymentEffected,
    UpdateDocument,
    PostDocumentFile,
    OpenDocument,
    PayStatusFlow,
    SmartTrade,
    updateSignedLocally,
    updatePayedLocally,
    updateTradeDocumentLocally,
    updateVesselNominated,
    UpdateCloseLocally,
    sendShippingAdvice,
    sendInstructions,
    GetInspectionReports,
    PostInspectionReport,
    approveInspectionReport,
    rejectInspectionReport,
    getBagmarkings,
    postBagmarking,
    approveBagmarking,
    rejectBagmarking,
    preloadInspectionCompanies,
    GetShipments,
    GetTradeBill,
    ApproveDocument,
    RejectDocument,
    ReleaseDocument,
    GetDocumentComments,
    PostDocumentComment,
    getDocInstructions,
    updatePayedFalse,
    UpdateCommercialInvoiceType,
    ClearSingleTrade, SetTradeStatus, UpdateRequest, sendEmail, updateLaycanDateChange, GetVesselNomination
} from '../../../modules/module.trade';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './trades.scss';
import './modals.scss';
import { getPriceQuoteContainer, ProvisionalQuoteVessel, getShippingCompanyList, getshippingStatus } from '../../../modules/module.shipmentQuote';
import { PostInspectionQuote, getInpectionStatus } from '../../../modules/module.inspectionQuote';
import {ClearSubstituteNominationInfo} from '../../../modules/module.nomination';
//COMPONENTS
import Contract from './components/Contract';
import Documents from './components/Documents';
import AuditLog from './components/Auditlog';
import TradeComments from './components/TradeComments';
import Info from './components/Info';

import Invoice from './components/DocumentInvoice';
import ProformaInvoice from './components/DocumentPerformaInvoice';
import BillOfLading from './components/BillOfLading';
import {
    DATEFORMAT,
    STEPS,
    TRADE_STATUS
} from '../../../services/service.values';
import {
    signContractPermission,
    viewContractPermission,
    viewBagmarkingPermission,
    viewDocInstructionsPermission,
    viewTradeListPermission,
    sendVessalNominationPermission,
    approveVessalNominationPermission,
    sendDocInstructionsPermission,
    issueDocumentPermission,
    paymentPermission,
    confirmPaymentPermission
} from '../../../services/service.permission';
import { TradeApi } from '../../../services/service.api';
import DocumentaryInstructions from './components/DocumentaryInstructions';
import { ControlBar } from './components/TradeControlbar';
import { LoadingStatus } from '../../../modules/module.loading';
import { Link } from 'react-router-dom';
import DocumentaryInstructionsModal from './modals/DocumentaryInstructionsModal';
import {
    approveNominatedVessel,
    nominateVessel,
    rejectNominateVessel,
    acceptNominatVesselWithNewLayacan,
    approveVesselByBuyer
} from './modals/VesselModal';
import InspectionReports from './components/inspectionReports/InspectionReports';
import AccessControl, { INSPECTION_COMPANY } from '../../components/AccessControl';
import Preloader from '../../components/preloader/Preloader';
import DocumentShippingAdvice from './components/DocumentShippingAdvice';
import { faTimes, faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InspectionNotAvailableModal from '../../components/InspectionNotAvailableModal';
import FormRadioField from '../../components/form/FormRadioField';
import FormTextareaField from '../../components/form/FormTextareaField';
import FormInputField from '../../components/form/FormInputField';
import FormDateField from '../../components/form/FormDateField';
import Dropzone from 'react-dropzone';
import FaFilePdfO from 'react-icons/lib/fa/file-pdf-o';
import FaFileWordO from 'react-icons/lib/fa/file-word-o';
import { UserTypes } from '../../../utils/userTypes';
import { CompanyTypes } from '../../../utils/companyTypes';
import NominatedVesselInfo from './components/NominatedVesselInfo';
import PreviewDocumentsModal from './modals/PreviewDocumentsModal';
import { PROFORMA_INVOICE_OPERATIONS, FINAL_PAYMENT_OPERATIONS } from './services/documents.service'
import Bagmarkings from './components/bagmarkings/Bagmarkings';
import SelectOriginAndSignModal from './modals/SelectOriginAndSignModal';

class TradesDetails extends Component {
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showModalTwo: true,
            modal: '',
            modalData: {},
            input: '',
            vesselNomination: '',
            vesselDateFrom: null,
            vesselDateTo: null,
            vesselInspector: '',
            showVesselError: false,
            vesselName: '',
            disabled: false,
            disabledPending: false,
            documentaryInstructions: {
                text: '',
                markings: '',
                multipleSets: false,
                packingAndMarkings: '',
                instructions: [],
                billOfLading: {
                    notify: '',
                    consignee: ''
                },
                certificateOfOrigin: {
                    notify: '',
                    consignee: '',
                    sameAsBillOfLading: true
                }
            },
            signatureModal: {
                file: null,
                privateKey: null,
                loading: false
            },
            vesselRejectReason: '',
            newVesselDateFrom: null,
            newVesselDateTo: null,
            paymentRecieved: 'RECIEVED',
            required: {},
            confirmPayment: {},
            uploadPaymentProof: {},
            downPaymentEffected: {},
            commercialInvoicePayment: {},
            commercialInvInd: 'GLOBAL'
        };

    }

    initializeForm() {
        let isEmpty = value => !value || value === undefined || value === '';
        this.initField('confirmPayment', 'paymentRecieved', '', 'RECIEVED', isEmpty);
        this.initField('confirmPayment', 'remark', '', '', false);
        this.initField('confirmPayment', 'sellerAcceptanceDate', 'Acceptance Date', null, isEmpty);
        this.initField('uploadPaymentProof', 'paymentReciept', '', '', isEmpty);
        this.initField('uploadPaymentProof', 'remark', '', '', false);
        this.initField('uploadPaymentProof', 'buyerDeclarationDate', 'Decalaration Date', null, isEmpty);
        this.initField('downPaymentEffected', 'referenceComment', '', '', false);
        this.initField('downPaymentEffected', 'referenceNo', '', '', false);
        this.initField('downPaymentEffected', 'buyerDeclarationDate', 'Decalaration Date', null, isEmpty);
        this.initField('commercialInvoicePayment', 'commercialInvoicePaymentProof', '', '', false);
        this.initField('commercialInvoicePayment', 'remark', '', '', false);
        this.initField('commercialInvoicePayment', 'buyerDeclarationDate', 'Decalaration Date', null, isEmpty);
    }

    get tradeRequest() {
        return this.props.trade.items.single || {};
    }

    get isTradeOwner() {
        if (this.props.account && this.props.account.token) {
            return this.props.account.token.companyId === this.tradeRequest.ownerId;
        }
        return false;
    }

    componentDidMount() {
        this.initializeForm();
    }

    initField(document, name, label, value, required) {
        this.setState( prevState => {
            prevState[document][name] = {
                name: name,
                label: label,
                required: required,
                value: value
            };
            return prevState

        });
    }

    setField = (name, value, modalName) => {
        let documentValue = this.state[modalName];
        switch (name) {
            case 'sellerAcceptanceDate':
            case 'buyerDeclarationDate':
                if (typeof value === 'object' && value !== null) {
                    documentValue[name] = {
                        ...documentValue[name],
                        value: value
                    };
                    this.setState({
                        [modalName]: documentValue
                    });
                }
                break;
            case 'paymentRecieved':
                let confirmPayment = this.state[modalName];
                confirmPayment[name] = {
                    ...confirmPayment[name],
                    value: value
                };
                if (value === 'RECIEVED') {
                    confirmPayment.sellerAcceptanceDate = {
                        ...confirmPayment.sellerAcceptanceDate,
                        required: valueDate => !valueDate || valueDate === undefined || valueDate === ''
                    }
                } else {
                    confirmPayment.sellerAcceptanceDate = {
                        ...confirmPayment.sellerAcceptanceDate,
                        required: false
                    }
                    confirmPayment.remark = {
                        ...confirmPayment.remark,
                        value: ''
                    }
                }
                this.setState({
                    [modalName]: confirmPayment,
                    required: {}
                });
                break;
            default:
                documentValue[name] = {
                    ...documentValue[name],
                    value: value
                };
                this.setState({
                    [modalName]: documentValue
                });
                break;
        }
    };

    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    // submitForm = e => {
    //     e.preventDefault();
    //     if (this.validate(this.state.billOfLading)) {
    //         this.props.PostTradeDocument(this.props.id, this.props.shipmentId, this.getValueToPost(this.state.billOfLading), () =>
    //             this.props.navigateDocs(this.props.id)
    //         );
    //     }
    // };

    UNSAFE_componentWillMount = () => {
        this.loadTradeData();

    };

    componentWillUnmount = () => {
        this.props.ClearSingleTrade();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = new URLSearchParams(window.location.search);
        const forceUpdateParam = params.get('forceUpdate');
        const shipmentId = params.get('shipmentId');

        if (!prevProps.trade.shouldTriggerTradeUpdate && this.props.trade.shouldTriggerTradeUpdate) {
            this.props.AutoupdateTriggered();
            this.props.loadRequestDetails(this.props.match.params.id);
        }

        if(this.props.trade.shipments && this.props.trade.shipments.length > 0 && this.props.trade.instructions && this.props.trade.instructions !== prevProps.trade.instructions){
            if(this.props.trade.instructions.documentaryInstructions.commercialInvInd === 'GLOBAL'){
                this.props.GetTradeInvoice(this.props.match.params.id);
            }else{
                this.props.GetTradeInvoiceByShipmentId(this.props.match.params.id, shipmentId ? shipmentId : this.props.trade.shipments[0].id);
            }
        }

        if (this.props.trade.items.single && this.props.trade.items.single.status && ['NEW', 'DECLINED', 'CANCELED', 'EXPIRED'].includes(this.props.trade.items.single.status)) {
            this.props.navigateRequests('/details/' + this.props.match.params.id);
        }

        if (forceUpdateParam) {
            const forceUpdate = forceUpdateParam === 'true';
            if (forceUpdate) {
                this.props.ClearSingleTrade();
                this.loadTradeData();
            }
            const newLocationSearch = this.props.locationSearch.replace(/(&|\\?)forceUpdate=(true|false)/g, '');
            this.props.navigate(window.location.pathname + newLocationSearch);
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.ClearSingleTrade();
            this.loadTradeData();
        }
    }


    trackUserAction(action) {
        ReactGA.event({
            category: 'Trade',
            action: action + ' by ' + this.whoItIs()
        });
    }

    loadTradeData = () => {
        this.props.GetShipments(this.props.match.params.id);
        this.props.getDocInstructions(this.props.match.params.id);
        this.props.preloadInspectionCompanies();
        this.props.loadRequestDetails(this.props.match.params.id);
        this.props.LoadRequestInfo(this.props.match.params.id);
        this.props.GetInspectionReports(this.props.match.params.id);
        this.props.getBagmarkings(this.props.match.params.id);
        this.props.GetTradePerformaInvoice(this.props.match.params.id);
        this.props.getShippingCompanyList();
        this.props.getInpectionStatus(this.props.match.params.id, "FINAL", this.props.account.user.companyId);
        this.props.getshippingStatus(this.props.match.params.id, "FINAL", this.props.account.user.companyId);
        this.props.GetVesselNomination(this.props.match.params.id);
    };

    returnStatusNumber = status => {
        if (status === 'CLOSED' || status === 'CLOSING') {
            return 8;//TODO: What is this? Refactor
        }
        if (status === TRADE_STATUS.PAYMENT) {
            status = TRADE_STATUS.DOCUMENTS;
        }
        return STEPS.indexOf(status) + 1; //TODO: Why +1/
    };

    returnStatusCompletement = (step, status) => {
        if (status === TRADE_STATUS.PAYMENT) {
            status = TRADE_STATUS.DOCUMENTS;
        }
        if (step === TRADE_STATUS.PAYMENT) {
            step = TRADE_STATUS.DOCUMENTS;
        }
        if (status === 'CLOSED') {
            return 'trades-dtls__status-stage_completed';
        }
        if (STEPS.indexOf(step) < STEPS.indexOf(status)) {
            return 'trades-dtls__status-stage_completed';
        } else if (step === status && status !== 'CLOSED') {
            return ' trades-dtls__status-stage_current';
        }
    };

    get trade() {
        return this.props.trade.items.single;
    }

    get tradeStatus() {
        const trade = this.trade;
        return (trade && TRADE_STATUS[trade.status]) || 'DEAL';
    }

    postInstructionsStatus = params => {
        this.props.LoadingStatus(true);
        TradeApi.smart(this.props.match.params.id)
            .instructions(params)
            .then(r => {
                this.props.LoadingStatus(false);
                this.updateTradeStatus(r.data.status);
                this.props.UpdateRequest({
                    destPort: params.destPort,
                    destCountry: params.destCountry
                });
                this.props.getDocInstructions(this.props.match.params.id);
                this.props.sendInstructions(params.text);
                this.trackUserAction('Documentary instructions sent');
            })
            .catch(() => this.props.LoadingStatus(false));
    };

    postNominateVesselStatus = () => {
        this.props.LoadingStatus(true);
        const params = {
            message: this.state.vesselNomination,
            laycanDateFrom: this.state.vesselDateFrom,
            laycanDateTo: this.state.vesselDateTo,
            name: this.state.vesselName
        };
        if (this.state.vesselInspector !== null && this.state.vesselInspector !== '' && this.state.vesselInspector !== undefined) {
            params.inspectionCompanyId = this.state.vesselInspector;
        }
        TradeApi.smart(this.props.match.params.id)
            .nominateVessel(params)
            .then(r => {
                this.props.LoadingStatus(false);
                this.props.updateVesselNominated(true);
                this.updateTradeStatus(r.data.status);
                this.trackUserAction('Vessel Nominated');
            })
            .catch(() => this.props.LoadingStatus(false));
    };

    postAcceptVesselStatus = (inspectionCompanyId) => {
        this.props.LoadingStatus(true);
        TradeApi.smart(this.props.match.params.id)
            .vesselAccept({ inspectionCompanyId })
            .then(r => {
                this.props.LoadingStatus(false);
                this.updateTradeStatus(r.data.status);
                this.trackUserAction('Vessel Nomination Accepted');
                if (r.data.status === 'VESSEL_NOMINATED') {
                    this.props.updateVesselNominated(false);
                }
            })
            .catch(() => {
                this.props.LoadingStatus(false);
            });
    };

    postAcceptVesselWithNewLaycanStatus = (inspectionCompanyId) => {
        this.props.LoadingStatus(true);
        TradeApi.smart(this.props.match.params.id)
            .vesselAcceptNewLaycanDates({ inspectionCompanyId, laycanDateFrom: this.state.newVesselDateFrom, laycanDateTo: this.state.newVesselDateTo })
            .then(r => {
                this.props.LoadingStatus(false);
                this.updateTradeStatus(r.data.status);
                this.trackUserAction('Vessel Nomination Accepted');
                this.props.updateLaycanDateChange(true);
                if (r.data.status === 'VESSEL_NOMINATED') {
                    this.props.updateVesselNominated(false);
                }
            })
            .catch(() => {
                this.props.LoadingStatus(false);
            });
    };

    postRejectVesselStatus = () => {
        this.props.LoadingStatus(true);
        TradeApi.smart(this.props.match.params.id)
            .vesselReject({ rejectMessage: this.state.vesselRejectReason })
            .then(r => {
                this.updateTradeStatus(r.data.status);
                this.props.updateVesselNominated(false);
                this.props.LoadingStatus(false);
                this.props.updateLaycanDateChange(false);
                this.trackUserAction('Vessel Nomination Rejected');
            })
            .catch(() => {
                this.props.LoadingStatus(false);
            });
    };


    postAdviceStatus = (params, cb) => {
        this.props.LoadingStatus(true);
        TradeApi.smart(this.props.match.params.id)
            .advice(params)
            .then(r => {
                this.props.LoadingStatus(false);
                this.updateTradeStatus(r.data.status);
                this.props.sendShippingAdvice(this.props.account.user.name);
                this.trackUserAction('Shipping Advice Sent');
                if(cb){
                    cb();
                }
            })
            .catch(() => this.props.LoadingStatus(false));
    };

    whoItIs = () => {
        if (this.props.account.user.companyType === 'INSPECTION') {
            return 'inspection';
        }
        return this.props.account.token.companyId === this.props.trade.requestInfo.sellerId ? 'seller' : 'buyer';
    };

    updateTradeStatus = (status) => {
        this.props.SetTradeStatus(status);
    };

    updateVessel = (vessel) => {
        if (vessel) {
            this.setState({
                vesselNomination: vessel.message,
                vesselDateFrom: vessel.laycanDateFrom,
                vesselDateTo: vessel.laycanDateTo,
                vesselInspector: vessel.inspectionCompanyId,
                vesselName: vessel.name
            });
        }
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    openProformaOperationModal = (modalName) => {
        if (modalName === 'PREVIEW_PAYMENT_RECIEPT') {
            this.props.GetProformaPaymentReciepts(this.trade.id, this.props.trade.proformaInvoice.proformaInvoiceID, () => {
                this.setState({
                    showModal: true,
                    modal: 'PREVIEW_PAYMENT_RECIEPT'
                });
            });
        } else {
            this.setState({
                showModal: true,
                modal: modalName
            })
        }
    }

    onDrop = (filesAccept) => {
        if (filesAccept.length !== 0) {
            this.setState(prevState => ({
                uploadPaymentProof: {
                    ...prevState.uploadPaymentProof,
                    paymentReciept: {
                        ...prevState.uploadPaymentProof.paymentReciept,
                        value: filesAccept
                    }
                }

            }))
        }
    };

    onFinalPaymentDrop = (filesAccept) => {
        if (filesAccept.length !== 0) {
            this.setState(prevState => ({
                commercialInvoicePayment: {
                    ...prevState.commercialInvoicePayment,
                    commercialInvoicePaymentProof: {
                        ...prevState.commercialInvoicePayment.commercialInvoicePaymentProof,
                        value: filesAccept
                    }
                }

            }))
        }
    };

    uploadProformaPaymentReciept = (event) => {
        event.preventDefault();
        if (this.validate(this.state.uploadPaymentProof)) {
            let newData = new FormData();
            newData.append('upload', this.state.uploadPaymentProof.paymentReciept.value[0]); // FORMAT TO POST DOC TO BACK
            newData.append('docType', 'PROFORMAINVOICE');
            newData.append('remark', this.state.uploadPaymentProof.remark.value);
            newData.append('proformaInvoiceID', this.props.trade.proformaInvoice.proformaInvoiceID);
            newData.append('status', 'UPLOADED');
            newData.append('buyerDeclarationDate', new Date(this.state.uploadPaymentProof.buyerDeclarationDate.value).toISOString());
            this.props.UploadProformaPaymenntReciept(this.trade.id, newData, () => {
                this.closeModal();
            });
        }
    }

    uploadCommercialInvoicePaymentProof = event => {
        event.preventDefault();
        if (this.validate(this.state.commercialInvoicePayment)) {
            let newData = new FormData();
            newData.append('upload', this.state.commercialInvoicePayment.commercialInvoicePaymentProof.value[0]);
            newData.append('docType', 'INVOICESPAYMENT');
            newData.append('buyerAdditionalInfo', this.state.commercialInvoicePayment.remark.value ? this.state.commercialInvoicePayment.remark.value : '');
            newData.append('buyerDeclarationDate', new Date(this.state.commercialInvoicePayment.buyerDeclarationDate.value).toISOString());
            newData.append('status', 'UPLOADED');
            newData.append('toCompanyId', this.trade.sellerId);
            newData.append('toCompanyName', this.trade.seller);
            if (this.props.trade.requestInfo.invoicePaymentID) {
                newData.append('invoicePaymentID', this.props.trade.requestInfo.invoicePaymentID);
                this.props.ReuploadInvoicePaymentProof(this.trade.id, newData, () => {
                    this.props.updatePayedLocally();
                    this.updateTradeStatus('PAYMENT');
                    this.closeModal();
                })
            } else {
                this.props.UploadInvoicePaymentProof(this.trade.id, newData, () => {
                    this.props.updatePayedLocally();
                    this.updateTradeStatus('PAYMENT');
                    this.closeModal();
                })
            }

        }
    }

    downloadPaymentReciept = () => {
        this.props.GetProformaPaymentReciepts(this.trade.id, this.props.trade.proformaInvoice.proformaInvoiceID, () => {
            this.setState({
                showModal: true,
                modal: 'PREVIEW_PAYMENT_RECIEPT'
            });
        });
    }

    previewInvoicePaymentProofs = () => {
        this.props.GetInvoicePaymentProofs(this.trade.id, this.props.trade.requestInfo.invoicePaymentID, () => {
            this.setState({
                showModal: true,
                modal: FINAL_PAYMENT_OPERATIONS.PREVIEW_INVOICE_PAYMENT_PROOFS
            })
        })
    }

    downPaymentEffected = (event) => {
        event.preventDefault();
        if (this.validate(this.state.downPaymentEffected)) {
            let jsonBody = {
                referenceNo: this.state.downPaymentEffected.referenceNo.value,
                referenceComment: this.state.downPaymentEffected.referenceComment.value,
                remark: '',
                paymentRecieved: 'false',
                buyerDeclarationDate: new Date(this.state.downPaymentEffected.buyerDeclarationDate.value).toISOString(),
                sellerAcceptanceDate: ''
            }
            this.props.PostDownpaymentEffected(this.trade.id, this.props.trade.proformaInvoice.proformaInvoiceID, 'EFFECTED', jsonBody, () => {
                this.closeModal()
            });
        }
    }

    confirmPayment = (event) => {
        event.preventDefault();
        if (this.validate(this.state.confirmPayment)) {
            let status = 'PAYMENT_NOT_CONFIRMED';
            if (this.state.confirmPayment.paymentRecieved.value === 'RECIEVED') {
                status = 'PAYMENT_CONFIRMED';
            }
            if (this.state.modal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT) {
                let jsonBody = {
                    sellerAdditionalInfo: this.state.confirmPayment.remark.value ? this.state.confirmPayment.remark.value : '',
                    paymentRecieved: this.state.confirmPayment.paymentRecieved.value === 'RECIEVED' ? 'true' : 'false',
                    sellerAcceptanceDate: this.state.confirmPayment.sellerAcceptanceDate.value,
                }

                this.props.ConfirmInvoicePayment(this.trade.id, this.props.trade.requestInfo.invoicePaymentID, status, jsonBody, () => {
                    this.closeModal()
                });
            } else {
                let jsonBody = {
                    referenceNo: '',
                    referenceComment: '',
                    remark: this.state.confirmPayment.remark.value,
                    paymentRecieved: this.state.confirmPayment.paymentRecieved.value === 'RECIEVED' ? 'true' : 'false',
                    sellerAcceptanceDate: this.state.confirmPayment.sellerAcceptanceDate.value,
                    buyerDeclarationDate: this.props.trade.proformaInvoice.buyerDeclarationDate
                }
                this.props.PostDownpaymentEffected(this.trade.id, this.props.trade.proformaInvoice.proformaInvoiceID, status, jsonBody, () => {
                    this.closeModal()
                });
            }
        }
    }

    closeModal = () => {
        this.setState(prevState => ({
            showModal: false,
            modal: '',
            input: '',
            vesselNomination: '',
            vesselDateFrom: null,
            vesselDateTo: null,
            vesselInspector: '',
            vesselName: '',
            showVesselError: false,
            modalData: {},
            signatureModal: {},
            newVesselDateFrom: null,
            newVesselDateTo: null,
            remark: '',
            paymentRecieved: '',
            uploadPaymentReciept: '',
            referenceComment: '',
            referenceNo: '',
            required: {},
            confirmPayment: {
                ...prevState.confirmPayment,
                paymentRecieved: {
                    ...prevState.confirmPayment.paymentRecieved,
                    value: 'RECIEVED'
                },
                remark: {
                    ...prevState.confirmPayment.remark,
                    value: ''
                },
                sellerAcceptanceDate: {
                    ...prevState.confirmPayment.sellerAcceptanceDate,
                    value: null,
                    required: value => !value || value === undefined || value === ''
                }
            },
            uploadPaymentProof: {
                ...prevState.uploadPaymentProof,
                paymentReciept: {
                    ...prevState.uploadPaymentProof.payment,
                    value: ''
                },
                remark: {
                    ...prevState.uploadPaymentProof.remark,
                    value: ''
                },
                buyerDeclarationDate: {
                    ...prevState.uploadPaymentProof.buyerDeclarationDate,
                    value: null
                }
            },
            commercialInvInd: 'GLOBAL'
        }));
    };

    actionsDuringDeal = () => {
        if (signContractPermission() === 1) {
            let notSigned = !this.props.trade.requestInfo.signSeller && !this.props.trade.requestInfo.signBuyer;
            if(this.tradeRequest.multipleOriginAccept && notSigned && this.isTradeOwner){
                this.openModal('SELECT_ORIGIN_AND_SIGN');
            }else{
                this.signContract();
            }
        }else{
            this.openModal('PERMISSION_REQUIRED')
        }
    }

    renderStatusButton = status => {
        let bar;
        switch (status) {
            case TRADE_STATUS.DEAL:
                bar = ControlBar.Deal(
                    this.props.trade,
                    this.whoItIs(),
                    () => this.actionsDuringDeal(),
                    this.props.loadingStatus,
                    this.isTradeOwner
                );
                break;
            case TRADE_STATUS.SIGNED:
                bar = ControlBar.ProformaInvoiceBar(
                    () => this.openModal(PROFORMA_INVOICE_OPERATIONS.FILL_PROFORMA_INVOICE),
                    () => this.openModal('PROFORMA_INVOICE_PAYMENT'),
                    this.props.trade,
                    this.props.loadingProformaInvoice
                );
                break;
            case TRADE_STATUS.PROFORMA_INVOICE:
                bar = ControlBar.VesselBar(
                    this.props.trade.items.single,
                    this.props.trade.requestInfo,
                    () => {
                        if (sendVessalNominationPermission() === 1) {
                            let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === this.props.trade.requestInfo.inspectionId);
                            if (selectedInspectionCompany && this.props.trade.requestInfo.sellerId === this.props.account.user.companyId && selectedInspectionCompany.serviceProviderFlag === false) {
                                this.openModal('INSPECTION_COMPANY_NOT_APPROVED');
                            } else {
                                this.openModal('NOMINATE');
                            }
                        }
                        else {
                            this.openModal('PERMISSION_REQUIRED')
                        }

                    },
                    () => {
                        if (approveVessalNominationPermission() === 1) {
                            let selectedInspectionCompany = this.props.trade.inspectionCompanies.find(company => company.ID === this.props.trade.requestInfo.inspectionId);
                            if (selectedInspectionCompany && selectedInspectionCompany.serviceProviderFlag === false) {
                                this.openModal('INSPECTION_COMPANY_NOT_APPROVED');
                            } else {
                                TradeApi.smart(this.props.match.params.id)
                                    .vesselMessage()
                                    .then(r => {
                                        this.updateVessel(r.data && r.data.vesselNomination);
                                        this.openModal('NOMINATE_ACCEPTING');
                                    });
                            }
                        }
                        else {
                            this.openModal('PERMISSION_REQUIRED')
                        }
                        // TradeApi.smart(this.props.match.params.id)
                        //     .vesselMessage()
                        //     .then(r => {
                        //         this.updateVessel(r.data && r.data.vesselNomination);
                        //         this.openModal('NOMINATE_ACCEPTING');
                        //     });
                    },
                    () => {
                        this.openModal('APPROVE_VESSEL_BY_BUYER')
                    },
                    this.props.loadingStatus
                );
                break;
            case TRADE_STATUS.VESSEL_NOMINATED:
                var checkStatus = TRADE_STATUS.VESSEL_NOMINATED;
                if (sendDocInstructionsPermission() === 1) {
                    checkStatus = TRADE_STATUS.VESSEL_NOMINATED;
                }
                else {
                    checkStatus = 'PERMISSION_REQUIRED';
                    // this.openModal('PERMISSION_REQUIRED') 
                }

                bar = ControlBar.DocInst(
                    this.props.trade, () =>
                    this.openModal(checkStatus),
                    this.props.loadingStatus
                );
                break;
            case TRADE_STATUS.INSTRUCTIONS:
                bar = ControlBar.ShippingAdvice(
                    this.props.trade, () => issueDocumentPermission() === 1 ? this.openModal(TRADE_STATUS.INSTRUCTIONS) : this.openModal('PERMISSION_REQUIRED'),
                    this.props.loadingStatus
                );

                break;
            case TRADE_STATUS.ADVICE:
                const documents = [
                    ...flatten(Object.values(this.props.trade.shipmentDocuments).map(value => Object.values(value))),
                    this.props.trade.invoice,
                    ...Object.values(this.props.trade.bills).map(bill => bill.BillNumber ? bill : null)
                ];

                if (Object.values(documents).includes(null)) {
                    bar = ControlBar.DocumentsFill(`${this.props.match.url}/documents`);
                } else {
                    bar = ControlBar.DocumentsFull(`${this.props.match.url}/documents`, this.props.loadingStatus);
                }
                break;
            case TRADE_STATUS.DOCUMENTS:
                bar = ControlBar.PaymentRequired(
                    this.props.trade,
                    payment => {
                        if (payment) {
                            //this.openModal(TRADE_STATUS.DOCUMENTS);
                            if (paymentPermission() === 1) {
                                //this.openModal(TRADE_STATUS.DOCUMENTS);
                                this.openModal(FINAL_PAYMENT_OPERATIONS.UPLOAD_FINAL_PAYMENT_PROOF);
                            }
                            else {
                                this.openModal('PERMISSION_REQUIRED')
                            }

                        } else {
                            if (confirmPaymentPermission() === 1) {
                                //this.openModal(TRADE_STATUS.PAYED);
                                this.openModal(FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT);
                            }
                            else {
                                this.openModal('PERMISSION_REQUIRED')
                            }

                        }
                    },
                    this.props.loadingStatus
                );
                break;
            case TRADE_STATUS.PAYMENT:
                bar = ControlBar.PaymentRequired(
                    this.props.trade,
                    payment => {
                        // this.openModal(TRADE_STATUS.PAYED);

                        if (confirmPaymentPermission() === 1) {
                            //this.openModal(TRADE_STATUS.PAYED);
                            this.openModal(FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT);
                        }
                        else {
                            this.openModal('PERMISSION_REQUIRED')
                        }
                    },
                    this.props.loadingStatus
                );
                break;
            case TRADE_STATUS.PAYED:
                bar = ControlBar.Wait();
                if (moment(this.props.trade.items.single.completionAt).diff(moment(), 'days') <= 0) {
                    this.props.trade.items.single.status === TRADE_STATUS.PAYED && this.updateTradeStatus('CLOSING');
                }
                break;
            case TRADE_STATUS.CLOSING:
                bar = ControlBar.Close(
                    () => {
                        this.props.LoadingStatus(true);
                        TradeApi.smart(this.props.match.params.id)
                            .closeTrade()
                            .then(r => {
                                this.props.LoadingStatus(false);
                                this.props.UpdateCloseLocally(this.whoItIs());
                                if (r.data.status === TRADE_STATUS.PAYED && moment(this.props.trade.items.single.completionAt).diff(moment(), 'days') <= 0) {
                                    this.updateTradeStatus('CLOSING');
                                } else {
                                    this.updateTradeStatus(r.data.status);
                                }
                            });
                    },
                    this.props.loadingStatus,
                    this.whoItIs(),
                    this.props.trade
                );
                break;
            default: break;
        }

        if (bar) {
            return this.whoItIs() === 'buyer' ? bar.Buyer() : bar.Seller();
        }
    };

    signContract = (signContractBody = {}) => {
        this.props.LoadingStatus(true);
        this.props.Sign(this.props.match.params.id, signContractBody, d => {
            if (d) {
                this.props.updateSignedLocally(this.whoItIs());
                this.trackUserAction('Sign Contract');
                this.setState({
                    status: TRADE_STATUS[d.status]
                });
            }
            this.props.LoadingStatus(false);
            this.props.ClearSubstituteNominationInfo();
        });
    };

    onKeyFileDrop = (filesAccept, filesNotAccept) => {
        const reader = new FileReader();
        const file = filesAccept[0];
        this.setState(prevState => ({
            ...prevState,
            signatureModal: {
                file,
                loading: true,
                privateKey: ''
            }
        }));
        reader.onloadend = event => {
            this.setState(prevState => ({
                ...prevState,
                signatureModal: {
                    ...prevState.signatureModal,
                    privateKey: atob(event.target.result.replace('data:text/plain;base64,', '')),
                    loading: false
                }
            }));
        };
        try {
            reader.readAsDataURL(file);
        } catch (error) {
            this.setState(prevState => ({
                ...prevState,
                signatureModal: {
                    ...prevState.signatureModal,
                    loading: false,
                    error: 'File type is not supported. Make sure you are uploading your private key file.'
                }
            }))
        }
    };


    renderModal = (whatModal) => {
        switch (whatModal) {
            case TRADE_STATUS.DEAL:
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper" onSubmit={(e) => {
                            e.preventDefault();
                            // if (!this.state.signatureModal.privateKey) {
                            //     return;
                            // }
                            this.signContract();
                            this.closeModal();
                        }}>
                            {!this.state.signatureModal.loading && <span className="modal__close" onClick={this.closeModal} />}
                            <h3 className="modal__heading">Sign Contract</h3>
                            <div className="modal__line mb-4 justify-content-center">
                                {/* <Dropzone
                                    className="upload"
                                    activeClassName="upload__active"
                                    accept={'text/plain'}
                                    onDrop={(filesAccept, filesNotAccept) => this.onKeyFileDrop(filesAccept, filesNotAccept)}
                                >
                                    {this.state.signatureModal.file ? <p>{this.state.signatureModal.file.name}</p> :
                                        <p>Choose Keys File (or Drop)</p>}
                                </Dropzone> */}
                                Are you sure to proceed for contract?
                            </div>
                            {/* {this.state.signatureModal.error && (
                                <div className="mb-4 text-danger justify-content-center d-flex">
                                    {this.state.signatureModal.error}
                                </div>
                            )} */}
                            <div className="text-center">

                                <button type="submit"
                                    className="modal__button contract_modalbtn">
                                    Confirm
                                {/* <Preloader loadingStyle="dots" loading={this.state.signatureModal.loading}>
                                    Upload Keys File
                                </Preloader> */}
                                </button>
                                <button type="button" className="modal__button contract_modalbtn ml-3" data-dismiss="modal" onClick={this.closeModal}>Cancel</button>

                            </div>
                        </form>

                    </div>
                );
            case TRADE_STATUS.PAYED:
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.LoadingStatus(true);
                                TradeApi.smart(this.props.match.params.id)
                                    .confirmPayment()
                                    .then(r => {
                                        this.props.LoadingStatus(false);
                                        this.updateTradeStatus(TRADE_STATUS.PAYED);
                                        this.trackUserAction('Payment Confirmed');
                                    });
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">View Payment</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* <span className="modal__close" onClick={this.closeModal} />
                            <h3 className="modal__heading">View Payment</h3> */}
                                    <textarea
                                        className="textarea form-control modal__textarea py-0"
                                        value={this.trade.paymentComment}
                                        disabled
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn-popup btn-submit">
                                        Submit
                            </button>
                                </div></div>
                        </form>
                    </div>
                );
            case TRADE_STATUS.DOCUMENTS:
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.LoadingStatus(true);
                                TradeApi.smart(this.props.match.params.id)
                                    .processPayment(this.state.input)
                                    .then(r => {
                                        this.props.updatePayedLocally();
                                        this.props.LoadingStatus(true);
                                        this.updateTradeStatus(r.data.Status);
                                    });
                            }}>
                            <span className="modal__close" onClick={this.closeModal} />
                            <h3 className="modal__heading">Payment Notification</h3>
                            <textarea
                                onChange={e => this.setState({ input: e.target.value })}
                                className="modal__textarea"
                                placeholder="Your payment notification"
                                required
                            />
                            <button type="submit" className="modal__button">
                                Submit
                            </button>
                        </form>
                    </div>
                );

            case 'NOMINATE':
                return nominateVessel({
                    dateFrom: this.state.vesselDateFrom || null,
                    dateTo: this.state.vesselDateTo || null,
                    name: this.state.vesselName || '',
                    inspector: this.trade.inspection,
                    //Assign value if Non inspector and value for non inspector is 1
                    inspectors: { ...this.props.trade.inspections, 1: `${this.trade.inspection === "1" ? this.trade.inspectionName : ""}` },
                    onClose: this.closeModal,
                    isSeller: this.whoItIs() === 'seller',
                    showError: this.state.showVesselError,
                    updateMessage: e => this.setState({ vesselNomination: e.target.value }),
                    updateDateFrom: date => this.setState({ vesselDateFrom: date }),
                    updateDateTo: date => this.setState({ vesselDateTo: date }), 
                    title:"Nominate a vessel",
                    onSelectInspection: (inspectionCompanyId) => {
                        this.setState({ vesselInspector: inspectionCompanyId })
                    },
                    onChangeName: name => this.setState({ vesselName: name }),
                    onSubmit: (e) => {
                        e.preventDefault();
                        const form = e.target;
                        const isValid = form.checkValidity();
                        if (!isValid) {
                            this.setState({ showVesselError: true });
                            form.reportValidity();
                            return;
                        }
                        this.postNominateVesselStatus();
                        this.closeModal();
                    }
                });
            case 'NOMINATE_ACCEPTING':
                return approveNominatedVessel({
                    inspectors: { ...this.props.trade.inspections, 1: `${this.trade.inspection === "1" ? this.trade.inspectionName : ""}` },
                    inspector: this.trade.inspection,
                    name: this.state.vesselName,
                    nomination: this.state.vesselNomination,
                    dateFrom: this.state.vesselDateFrom,
                    dateTo: this.state.vesselDateTo,
                    title:"Nominate a vessel",
                    onClose: this.closeModal,
                    showError: this.state.showVesselError,
                    onSelectInspection: (inspectionCompanyId) => {
                        this.setState({ vesselInspector: inspectionCompanyId })
                    },
                    onApprove: (e) => {
                        e.preventDefault();
                        const form = e.target;
                        const isValid = form.checkValidity();
                        if (!isValid) {
                            this.setState({ showVesselError: true });
                            form.reportValidity();
                            return;
                        }
                        this.postAcceptVesselStatus(this.state.vesselInspector);
                        this.closeModal();
                    },
                    onReject: () => {
                        this.setState({
                            modal: 'REJECT_VESSEL_WITH_REASON'
                        });
                        this.renderModal(this.state.modal);
                    },
                    onAcceptWithNewLaycan: () => {
                        this.setState({
                            modal: 'ACCEPT_VESSEL_WITH_NEW_LAYCAN'
                        });
                        this.renderModal(this.state.modal);
                    }
                });
            case 'APPROVE_VESSEL_BY_BUYER':
                return approveVesselByBuyer({
                    onApproveWithNewLaycan: (event) => {
                        event.preventDefault();
                        this.postAcceptVesselStatus(this.state.vesselInspector);
                        this.closeModal();
                    },
                    onClose: this.closeModal,
                    onReject: () => {
                        this.setState({
                            modal: 'REJECT_VESSEL_WITH_REASON'
                        });
                        this.renderModal(this.state.modal);
                    },
                    vesselInfo: this.props.trade.vesselNomination,
                    inspectionName: this.props.trade.items.single.inspectionName,
                    inspector: this.trade.inspection,
                });
            case 'REJECT_VESSEL_WITH_REASON':
                return rejectNominateVessel({
                    rejectReason: this.state.vesselRejectReason,
                    onClose: this.closeModal,
                    onRejectReasonChange: (reajectReason) => {
                        this.setState({
                            vesselRejectReason: reajectReason
                        })
                    },
                    onReject: (e) => {
                        e.preventDefault();
                        const form = e.target;
                        const isValid = form.checkValidity();
                        if (!isValid) {
                            this.setState({ showVesselError: true });
                            form.reportValidity();
                            return;
                        }
                        this.postRejectVesselStatus();
                        this.closeModal();
                    },
                    onRejectCancel: () => {
                        if (this.props.trade.requestInfo.isLaycanDateChanged) {
                            this.closeModal();
                        } else {
                            this.setState({
                                modal: 'NOMINATE_ACCEPTING',
                                showVesselError: false
                            })
                            this.renderModal(this.state.modal);
                        }
                    },
                    showError: this.state.showVesselError
                });
            case 'ACCEPT_VESSEL_WITH_NEW_LAYCAN':
                return acceptNominatVesselWithNewLayacan({
                    dateFrom: this.state.newVesselDateFrom || null,
                    dateTo: this.state.newVesselDateTo || null,
                    updateDateFrom: date => this.setState({ newVesselDateFrom: date }),
                    updateDateTo: date => this.setState({ newVesselDateTo: date }),
                    onApproveWithNewLaycan: (e) => {
                        e.preventDefault();
                        const form = e.target;
                        const isValid = form.checkValidity();
                        if (!isValid) {
                            this.setState({ showVesselError: true });
                            form.reportValidity();
                            return;
                        }
                        this.postAcceptVesselWithNewLaycanStatus(this.state.vesselInspector);
                        this.closeModal();
                    },
                    onClose: this.closeModal,
                    onCancel: () => {
                        this.setState({
                            modal: 'NOMINATE_ACCEPTING',
                            showVesselError: false
                        })
                        this.renderModal(this.state.modal);
                    },
                    showError: this.state.showVesselError
                });
            case TRADE_STATUS.VESSEL_NOMINATED:
                return (
                    <DocumentaryInstructionsModal
                        trade={this.trade}
                        onClose={this.closeModal}
                        onSubmit={params => {
                            this.closeModal();
                            this.postInstructionsStatus(params);
                        }}
                    />
                );
            case TRADE_STATUS.INSTRUCTIONS:
                const { trade } = this.props;
                const ti = trade.items.single;
                const bills = trade.bills;
                let billsOfLadingFilled = true;
                for (let i = 0; i < trade.shipments.length; i++) {
                    const bill = bills[trade.shipments[i].id];
                    if (!bill || !bill.document || !bill.BillNumber) {
                        billsOfLadingFilled = false;
                        break;
                    }
                }
                if (!billsOfLadingFilled) {
                    return (
                        <div className="modal__container">
                            <form className="modal__wrapper modal__wrapper--fill">
                                <span className="modal__close" onClick={this.closeModal} />
                                <h1 className="text-center">Please Fill In Bill Of
                                    Lading{trade.shipments.length > 1 ? ' for all sets' : ''} first!</h1>
                                <div
                                    onClick={() => {
                                        this.props.navigateDocs(this.props.match.params.id);
                                        this.closeModal();
                                    }}
                                    className="modal__button"
                                >
                                    Go to documents
                                </div>
                            </form>
                        </div>
                    );
                } else {
                    return (
                        <div className="modal__container">
                            <form className="modal__wrapper  px-0 py-0">

                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="modal-header-center"> <h5 className="modal-title">Shipping Advice</h5></div>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <DocumentShippingAdvice
                                            bills={bills}
                                            trade={ti}
                                            status={this.tradeStatus}
                                            userName={this.props.trade.requestInfo.sellerUserName}
                                            isModal={true}
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="submit"
                                            className="btn btn-save"
                                            onClick={e => {
                                                e.preventDefault();
                                                const postData = {
                                                    text: JSON.stringify(this.props.trade.shipments.map(shipment => ({
                                                        text: document.getElementById(`shipment-advice-text-${shipment.id}`).innerText,
                                                        shipmentId: shipment.id
                                                    })))
                                                };
                                                this.postAdviceStatus(postData, () => {
                                                    if(Object.keys(this.props.trade.bills).length > 1){
                                                        this.setState({
                                                            modal: 'CHANGE_COMMERCIAL_INVOICE_TYPE'
                                                        })
                                                    }else{
                                                        this.closeModal();
                                                    }
                                                });
                                            }}
                                            required
                                        >
                                            <Preloader loadingStyle="dots" loading={this.props.loadingStatus}>
                                                <FontAwesomeIcon icon={faCheck} /> Submit
                                            </Preloader>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    );
                }
            case 'CHANGE_COMMERCIAL_INVOICE_TYPE':
                return(
                    <div className="modal__container">
                            <form className="modal__wrapper  px-0 py-0" onSubmit={(event) => {
                                event.preventDefault();
                                let jsonBody = {
                                    docInsID: this.props.trade.instructions.documentaryInstructions.ID,
                                    commercialInvInd: this.state.commercialInvInd
                                }
                                this.props.UpdateCommercialInvoiceType(this.trade.id, jsonBody, () => {
                                    this.closeModal();
                                });
                                
                            }}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="modal-header-center"> <h5 className="modal-title">Select Commercial Invoice</h5></div>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal__textarea modal__textarea_dark textarea">
                                            <h6 className="text-center">
                                                Currently buyer has choosen to get { this.props.trade.instructions.documentaryInstructions.commercialInvInd === 'GLOBAL' ? 'one global invoice for all B/Ls' : 'one invoice per each set of B/L' }
                                            </h6>
                                            <FormRadioField
                                                customStyle={{
                                                    wrapperClassName: "form-group",
                                                    labelClassName: "label",
                                                    inputClassName: "form-control",
                                                    selectClassName: "custom-select",
                                                    radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
                                                }}
                                                name="commercialInvInd"
                                                items={{
                                                    GLOBAL: 'One Global Invoice For All B/Ls',
                                                    MULTISET: 'One Invoice per Each Set of B/L'
                                                }}
                                                required={true}
                                                onChange={(event) => this.setState({
                                                    commercialInvInd: event.target.value
                                                })}
                                                value={{
                                                    label: 'Please select below to change commercial invoice and submit',
                                                    value: this.state.commercialInvInd,
                                                }} />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn-popup btn-cancel" onClick={this.closeModal}>
                                            <FontAwesomeIcon icon={faTimes}/> <span>Cancel</span>
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-save">
                                                <Preloader loadingStyle="dots" loading={this.props.loadingCommercialInvoiceIndicator}>
                                                    <FontAwesomeIcon icon={faCheck} /> <span>Submit</span>
                                                </Preloader>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                )
            case 'PERMISSION_REQUIRED':

                return (

                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();

                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6> </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                            </button>
                                    {/* <button type="submit" className="btn-popup btn-submit">
                                           
                                              <img src={image} width="17px"/>&nbsp;Confirm
                            </button> */}

                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'INSPECTION_COMPANY_NOT_APPROVED':
                return InspectionNotAvailableModal({
                    onClick: () => {
                        this.closeModal();
                        this.props.sendEmail(this.props.trade.items.single.id);
                    }
                });
            case 'PROFORMA_INVOICE_PAYMENT':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper modal__wrapper--fill">
                            <span className="modal__close" onClick={this.closeModal} />
                            <h4 className="text-center">Go to Documents for Proforma Invoice down payment related actions - </h4>
                            <div
                                onClick={() => {
                                    this.props.navigateDocs(this.props.match.params.id);
                                    this.closeModal();
                                }}
                                className="modal__button"
                            >
                                Go to documents
                        </div>
                        </form>
                    </div>
                )
            case PROFORMA_INVOICE_OPERATIONS.FILL_PROFORMA_INVOICE:
                return (
                    <div className="modal__container">
                        <div className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Proforma Invoice</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body py-0">
                                    <ProformaInvoice
                                        id={this.props.match.params.id}
                                        onCancel={this.closeModal}
                                        isCreate={true}
                                        onFormSubmit={() => {
                                            this.closeModal();
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case PROFORMA_INVOICE_OPERATIONS.CONFIRM_PAYMENT_RECIEPT:
            case FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT:
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode modal__wrapper_show-Calender" onSubmit={(event) => this.confirmPayment(event)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">
                                            {whatModal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT ? 'Payment of Commercial Invoice' : 'Confirm Down Payment Received'}
                                        </h5>
                                    </div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                < div className="modal-body py-0">
                                    <div className="modal__row pb-2">
                                        <FormRadioField
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            name="paymentRecieved"
                                            items={{
                                                RECIEVED: whatModal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT ? 'Payment Received' : 'Down Payment Received',
                                                NOTRECIEVED: whatModal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT ? 'Payment Not Received' : 'Down Payment Not Received'
                                            }}
                                            required={true}
                                            onChange={(event) => this.setField(event.target.name, event.target.value, 'confirmPayment')}
                                            value={{
                                                value: this.state.confirmPayment.paymentRecieved.value,
                                            }} />

                                    </div>
                                    {this.state.confirmPayment.paymentRecieved.value === "RECIEVED" &&
                                        <div className="modal__row modal-height">
                                            <FormDateField
                                                time={false}
                                                dateFormat={DATEFORMAT}
                                                className="trades-dtls__doc-input trades-dtls__doc-input_fluid "
                                                name="sellerAcceptanceDate"
                                                label="Acceptance Date"
                                                validation={this.state.required}
                                                item={{
                                                    label: whatModal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT ? 'Date of Payment (Value Date)' : 'Date of receipt of funds (Value date)',
                                                    value: this.state.confirmPayment.sellerAcceptanceDate.value ? moment(this.state.confirmPayment.sellerAcceptanceDate.value) : null,
                                                    required: this.state.confirmPayment.paymentRecieved.value === "RECIEVED" ? true : false
                                                }}
                                                onSelect={(name, date) => this.setField(name, date, 'confirmPayment')}
                                                isClearable={false}
                                                minDate={moment(new Date(this.trade.createdAt))}
                                                maxDate={moment(new Date())}
                                            />
                                        </div>}
                                    {this.whoItIs() !== 'seller' ? <div className="modal__row" style={{ marginTop: '20px' }}>
                                        <FormTextareaField
                                            name="remark"
                                            type="text"
                                            value={{ label: 'Additional Information', value: this.state.confirmPayment.remark.value }}
                                            onChange={(event) => this.setField(event.target.name, event.target.value, 'confirmPayment')}
                                            inlineStyle={{ 'height': '160px' }} />
                                    </div> : null}
                                </div>
                                {this.state.required && Object.keys(this.state.required).length > 0 && (
                                    <div className="trades-dtls__error-message text-center">
                                        Please, complete all required fields before submitting
                                    </div>
                                )}
                                <div className="modal-footer">
                                    {
                                        whatModal === FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT ?
                                            <button type="button" className="btn-popup btn-cancel" onClick={this.previewInvoicePaymentProofs}>
                                                <Preloader loadingStyle="dots" loading={this.props.loadingCommercialInvoiceProofs}>
                                                    <FontAwesomeIcon icon={faEye} /> <span>Preview Receipt</span>
                                                </Preloader>
                                            </button> : null
                                    }
                                    <button type="button" className="btn-popup btn-cancel" onClick={this.closeModal}>
                                    <   FontAwesomeIcon icon={faTimes} /> <span>Cancel</span>
                                    </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        <Preloader loadingStyle="dots" loading={this.props.loadingProformaInvoice}>
                                            <FontAwesomeIcon icon={faCheck} /> <span>Submit</span>
                                    </Preloader>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case FINAL_PAYMENT_OPERATIONS.UPLOAD_FINAL_PAYMENT_PROOF:
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode custom-pop-modal" encType="multipart/form-data" onSubmit={(event) => this.uploadCommercialInvoicePaymentProof(event)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Payment of Commercial Invoice</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <FormDateField
                                            time={false}
                                            dateFormat={DATEFORMAT}
                                            className="trades-dtls__doc-input trades-dtls__doc-input_fluid "
                                            name="buyerDeclarationDate"
                                            required={false}
                                            label='Declaration Date'
                                            validation={this.state.required}
                                            item={{ label: 'Date of Payment (Value Date)', value: this.state.commercialInvoicePayment.buyerDeclarationDate.value ? moment(this.state.commercialInvoicePayment.buyerDeclarationDate.value) : null, required: true }}
                                            onSelect={(name, date) => this.setField(name, date, 'commercialInvoicePayment')}
                                            isClearable={false}
                                            minDate={moment(new Date(this.trade.createdAt))}
                                            maxDate={moment(new Date())}
                                        />
                                    </div>
                                    <div className="modal__row">
                                        <div className="form-group">
                                            <label className="label">
                                                Upload Payment Proof
                                                </label>
                                            <div className="form-control pointer">
                                                <Dropzone
                                                    className="input-group"
                                                    activeClassName="upload__active"
                                                    accept='application/pdf'
                                                    onDrop={(filesAccept, filesNotAccept) => this.onFinalPaymentDrop(filesAccept, filesNotAccept)}
                                                >
                                                    {this.state.commercialInvoicePayment.commercialInvoicePaymentProof.value ? (
                                                        this.state.commercialInvoicePayment.commercialInvoicePaymentProof.value[0].type === 'application/pdf' ? (
                                                            <FaFilePdfO className="icon" />
                                                        ) : (
                                                                <FaFileWordO className="icon" />
                                                            )
                                                    ) : null}
                                                    {this.state.commercialInvoicePayment.commercialInvoicePaymentProof.value ? <div className="file">{this.state.commercialInvoicePayment.commercialInvoicePaymentProof.value[0].name}</div> : <div className="pointer">Choose File (or Drop)</div>}
                                                </Dropzone>
                                            </div>
                                            <small>
                                                * Only files with *.pdf extension are allowed.
                                                </small>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormTextareaField
                                                name="remark"
                                                type="text"
                                                value={{ label: 'Additional Information', value: this.state.commercialInvoicePayment.remark.value }}
                                                onChange={(event) => this.setField(event.target.name, event.target.value, 'commercialInvoicePayment')}
                                                inlineStyle={{ 'height': '150px' }} />
                                        </div>
                                    </div>
                                    {this.state.required && Object.keys(this.state.required).length > 0 && (
                                        <p className="trades-dtls__error-message">
                                            Please, Complete all required fields before submitting
                                        </p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <React.Fragment>
                                        <button className="btn-popup btn-cancel" onClick={this.closeModal}>
                                            <FontAwesomeIcon icon={faTimes} /> <span>Cancel</span>
                                        </button>
                                        <button className="btn-popup btn-submit" type="submit">
                                            <Preloader loadingStyle="dots" loading={this.props.loadingProformaInvoice}>
                                                <FontAwesomeIcon icon={faCheck} /> <span>Upload Proof</span>
                                            </Preloader>      
                                        </button>
                                    </React.Fragment>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case PROFORMA_INVOICE_OPERATIONS.UPLOAD_PAYMENT_RECIEPT:
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode custom-pop-modal modal__wrapper_show-Calender" encType="multipart/form-data" onSubmit={(event) => this.uploadProformaPaymentReciept(event)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Proforma Invoice: Proof of Down Payment</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                {/* <span className="modal__close" onClick={this.closeModal} />
                            <h3 className="modal__heading modal__heading_upload">Confirm Payment</h3> */}

                                <div className="modal-body pt-0">
                                    <div className="pb-2">
                                        <p>Please Upload the proof of down payment</p>
                                    </div>
                                    <div className="modal__row">
                                        <div className="form-group">
                                            <label className="label" f>
                                                Payment Proof<span className="req-field">&nbsp;*</span>
                                            </label>
                                            <div className="form-control pointer">
                                                <Dropzone
                                                    className="input-group"
                                                    activeClassName="upload__active"
                                                    accept='application/pdf'
                                                    onDrop={(filesAccept, filesNotAccept) => this.onDrop(filesAccept, filesNotAccept)}
                                                >
                                                    {this.state.uploadPaymentProof.paymentReciept.value ? (
                                                        this.state.uploadPaymentProof.paymentReciept.value[0].type === 'application/pdf' ? (
                                                            <FaFilePdfO className="icon" />
                                                        ) : (
                                                                <FaFileWordO className="icon" />
                                                            )
                                                    ) : null}
                                                    {this.state.uploadPaymentProof.paymentReciept.value ? <div className="file">{this.state.uploadPaymentProof.paymentReciept.value[0].name}</div> : <div className="pointer">Choose File (or Drop)</div>}
                                                </Dropzone>
                                            </div>
                                            <small>
                                                * Only files with *.pdf extension are allowed.
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <FormDateField
                                            time={false}
                                            dateFormat={DATEFORMAT}
                                            className="trades-dtls__doc-input trades-dtls__doc-input_fluid "
                                            name="buyerDeclarationDate"
                                            required={false}
                                            label='Declaration Date'
                                            validation={this.state.required}
                                            item={{ label: 'Date of down payment done', value: this.state.uploadPaymentProof.buyerDeclarationDate.value ? moment(this.state.uploadPaymentProof.buyerDeclarationDate.value) : null, required: true }}
                                            onSelect={(name, date) => this.setField(name, date, 'uploadPaymentProof')}
                                            isClearable={false}
                                            minDate={moment(new Date(this.trade.createdAt))}
                                            maxDate={moment(new Date())}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormTextareaField
                                                name="remark"
                                                type="text"
                                                value={{ label: 'Additional Information', value: this.state.uploadPaymentProof.remark.value }}
                                                onChange={(event) => this.setField(event.target.name, event.target.value, 'uploadPaymentProof')}
                                                inlineStyle={{ 'height': '45px' }} />
                                        </div>
                                    </div>
                                    {this.state.required && Object.keys(this.state.required).length > 0 && (
                                        <p className="trades-dtls__error-message">
                                            Please, Complete all required fields before submitting
                                        </p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    {this.state.uploadPaymentProof.paymentReciept.value && (
                                        <React.Fragment>
                                            <button className="btn-popup btn-cancel" onClick={this.closeModal}>
                                                <FontAwesomeIcon icon={faTimes} /> Cancel
                                        </button>
                                            <button className="btn-popup btn-submit" type="submit">
                                                <Preloader loadingStyle="dots" loading={this.props.loadingProformaInvoice}>
                                                    <FontAwesomeIcon icon={faCheck} /> Upload Proof
                                            </Preloader>
                                            </button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case PROFORMA_INVOICE_OPERATIONS.DOWNPAYMENT_EFFECTED:
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0 authcode modal__wrapper_show-Calender" onSubmit={(event) => this.downPaymentEffected(event)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Proforma Invoice: Confirm date of down payment</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormInputField 
                                                name="referenceNo"
                                                type="text" 
                                                value={{label: 'Reference No', value: this.state.downPaymentEffected.referenceNo.value, required: true}}
                                                onChange={(event) => this.setField(event.target.name, event.target.value, 'downPaymentEffected')}/>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <FormDateField
                                            time={false}
                                            dateFormat={DATEFORMAT}
                                            className="trades-dtls__doc-input trades-dtls__doc-input_fluid "
                                            name="buyerDeclarationDate"
                                            required={false}
                                            label='Date of down payment done'
                                            validation={this.state.required}
                                            item={{ label: 'Date of down payment done', value: this.state.downPaymentEffected.buyerDeclarationDate.value ? moment(this.state.downPaymentEffected.buyerDeclarationDate.value) : null, required: true }}
                                            onSelect={(name, date) => this.setField(name, date, 'downPaymentEffected')}
                                            isClearable={false}
                                            minDate={moment(new Date(this.trade.createdAt))}
                                            maxDate={moment(new Date())}
                                        />

                                    </div>
                                    <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormTextareaField
                                                name="referenceComment"
                                                type="text"
                                                value={{ label: 'Additional Information', value: this.state.downPaymentEffected.referenceComment.value }}
                                                onChange={(event) => this.setField(event.target.name, event.target.value, 'downPaymentEffected')}
                                                inlineStyle={{ 'height': '120px' }} />
                                        </div>
                                    </div>
                                    {this.state.required && Object.keys(this.state.required).length > 0 && (
                                        <p className="trades-dtls__error-message">
                                            Please, Complete all required fields before submitting
                                        </p>
                                    )}
                                    <div className="modal-footer">
                                        <button className="btn-popup btn-cancel" onClick={this.closeModal}>
                                            <FontAwesomeIcon icon={faTimes} /> <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="btn-popup btn-submit">
                                            <Preloader loadingStyle="dots" loading={this.props.loadingProformaInvoice}>
                                                <FontAwesomeIcon icon={faCheck} /> <span>Submit</span>
                                            </Preloader>    
                                        </button>   
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case PROFORMA_INVOICE_OPERATIONS.VIEW_DOWNPAYMENT_EFFECTED:
                return (
                    <div className="modal__container">

                        <form className="modal__wrapper px-0 py-0 authcode" onSubmit={this.closeModal}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Proforma Invoice: Date of down payment</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* <div className="form-group">
                                <div className="create-req__wrapper mb-0">
                                    <FormInputField 
                                        name="referenceNo"
                                        type="text" 
                                        value={{label: 'Reference No', value: this.props.trade.proformaInvoice.referenceNo, required: true}}
                                        disabled={true}/>
                                </div>
                            </div> */}
                                    <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormInputField
                                                name="buyerDecalarationDate"
                                                type="text"
                                                value={{ label: 'Date of down payment done', value: moment(this.props.trade.proformaInvoice.buyerDeclarationDate).format(DATEFORMAT), required: true }}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="create-req__wrapper mb-0">
                                            <FormTextareaField
                                                name="referenceComment"
                                                type="text"
                                                value={{ label: 'Additional Information', value: this.props.trade.proformaInvoice.referenceComment }}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn-popup btn-cancel">
                                            <FontAwesomeIcon icon={faTimes} /> <span>Cancel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case PROFORMA_INVOICE_OPERATIONS.PREVIEW_PAYMENT_RECIEPT:
                return (
                    <PreviewDocumentsModal
                        files={this.props.trade.proformaInvoice.files}
                        name='Proforma Invoice Payment Proofs'
                        onClose={this.closeModal}
                        buyerDeclarationDate={this.props.trade.proformaInvoice.buyerDeclarationDate}
                        remark={this.props.trade.proformaInvoice.remark}
                    />
                );
            case FINAL_PAYMENT_OPERATIONS.PREVIEW_INVOICE_PAYMENT_PROOFS:
                if (this.props.trade.invoicePaymentProofs && this.props.trade.invoicePaymentProofs.length > 0) {
                    return (
                        <PreviewDocumentsModal
                            files={this.props.trade.invoicePaymentProofs}
                            name='Commercial Invoice Payment Proofs'
                            onClose={this.closeModal}
                        />
                    );
                } else {
                    return (
                        <div className="modal__container">
                            <div className="modal__wrapper px-0 py-0 authcode">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                modal: FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT
                                            })
                                        }} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            The buyer has not yet uploaded the proof for the Commercial Invoice Payment
                                            </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn-popup btn-submit" onClick={() => {
                                            this.setState({
                                                showModal: true,
                                                modal: FINAL_PAYMENT_OPERATIONS.CONFIRM_FINAL_PAYMENT
                                            })
                                        }}>
                                            <FontAwesomeIcon icon={faTimes} /> Back
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            case 'FINAL_QUOTE_REQUIRED':
                return (

                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();

                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text">
                                        The final Quote is not yet accepted/approved.
                                        Kindly accept the final quote to proceed further.
                                                       </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                                </button>
                                    {/* <button type="submit" className="btn-popup btn-submit">
                                               
                                                  <img src={image} width="17px"/>&nbsp;Confirm
                                </button> */}

                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'SELECT_ORIGIN_AND_SIGN':
                return(
                    <SelectOriginAndSignModal
                        trade={this.tradeRequest}
                        onClose={this.closeModal}
                        user={this.props.account.user}
                        traderType={this.whoItIs()}
                        onSign={(data) => this.signContract(data)} 
                    />
                )
            default:
                break;
        }
    };


    renderModalTwo = () => {
                return (<div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0 authcode"
                        onSubmit={e => {
                            e.preventDefault();
                            this.closeModalTwo();
                        }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModalTwo} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </Link>
                            </div>
                            <div className="modal-body">
                                <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                            </div>
                            <div className="modal-footer">
                                <Link to="/" className="btn-popup btn-cancel"><FontAwesomeIcon icon={faTimes} />Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>);
    };


    closeModalTwo = () => {
        this.setState({
            showModalTwo: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        let trade = this.props.trade.items.single;
        let info = this.props.trade.requestInfo;
        let user = this.props.account.user;
        let company = this.props.company;
        let status = this.tradeStatus;
        let shippingCompanyList = this.props.shipmentQuote.shipping;
        let isTradeBuyer = false;
        let isTradeSeller = false;
        var isInspectionSeller = false;
        let isShipping = "";
        if (this.props.trade.items.single !== undefined && this.props.trade.items.single !== null) {
            if (this.props.account.user.companyId === this.props.trade.items.single.sellerId && (this.props.trade.items.single.incoterm === "CIF" || this.props.trade.items.single.incoterm === "CFR")) {
                isTradeSeller = true;
            }
            if (this.props.account.user.companyId === this.props.trade.items.single.sellerId &&  this.props.trade.items.single.inspection!=="1") {
                isInspectionSeller = true;
            }
            if (this.props.account.user.companyId === this.props.trade.items.single.buyerId && this.props.trade.items.single.incoterm === "FOB") {
                isTradeBuyer = true;
            }
            if (this.props.trade.items.single.shipping === "VESSEL") {
                isShipping = "PROVISIONAL_QUOTEVESSEL";
            }
            else {
                isShipping = "PROVISIONAL_QUOTECONTAINER";
            }
        }
        if (viewTradeListPermission() === 0) {
            return (
                <div>
                    {this.state.showModalTwo && this.renderModalTwo()}
                </div>
            );
        }

        else {
            return (
                <React.Fragment>
                    <Header />
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loadingRequest || !trade || !info} />
                    {trade && info ? (
                        <div className="container-fluid trades-dtls__padding-fix">
                            {this.state.showModal && this.renderModal(this.state.modal)}
                            <div className="trades-dtls">
                                <a href="#/" className="trades-dtls__back" onClick={(event) => {
                                    event.preventDefault();
                                    this.props.history.goBack();
                                }}>Back</a>
                                <div className="trades-dtls__header l" id="trade-info">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-5 col-12 pb-1">
                                                    <span className="field-name">Trade Ref Id </span>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-7 col-12 pb-1">
                                                    <span className="field-value">{trade.tradeRefId || trade.id}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-lg-4 col-md-4 col-sm-5 col-12  pb-1">
                                                    <span className="field-name">Creation Date </span>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-7 col-12  pb-1">
                                                    <span className="field-value">{moment(trade.createdAt).format(DATEFORMAT)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4"><div className="trades-dtls__price mr-auto trades-dtls__buttons-wrapper"><AccessControl user={this.props.account.user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                        <NumberFormat value={trade.price} displayType={'text'} thousandSeparator={true} prefix={trade.currency === 'USD' || trade.currency === 'US$' ? ' USD ' : ' EUR '} suffix={" "+this.props.trade.items.single.incoterm} />
                                        </AccessControl></div> </div>

                                        {/* 
                                            <span className="trades-dtls__id">Trade Ref Id {trade.tradeRefId || trade.id }</span>
                                            <span className="trades-dtls__date">Creation Date {moment(trade.createdAt).format(DATEFORMAT)}</span> 
                                        */}
                                        {/* 
                                            <AccessControl user={this.props.account.user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                                <span className="trades-dtls__price ml-auto">{trade.price} {trade.currency === 'USD' ? 'US$' : trade.currency}</span>
                                            </AccessControl> 
                                        */}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-5 col-12 pb-1">
                                                    <span className="field-name">Buyer</span>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-7 col-12 pb-1">
                                                    <span className="field-value">{trade.buyer} ({trade.buyerUser}) {this.whoItIs() === 'buyer' ? <span>(You)</span> : null}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-5 col-12 mt-1 pb-1">
                                                    <span className="field-name">Seller</span>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-7 col-12  mt-1  pb-1">
                                                    <span className="field-value">{trade.seller} ({trade.sellerUser}) {this.whoItIs() === 'seller' ? <span>(You)</span> : null}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-4 mt-1"><div className="trades-dtls__buttons-wrapper"> <AccessControl user={this.props.account.user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                            {this.renderStatusButton(status)}
                                        </AccessControl></div> </div>
                                        {/* <div className="col-md-4 col-sm-5 col-12 flex-column">
                                            <div className="trades-dtls__buyer">{trade.buyer} ({trade.buyerUser})</div>
                                            <div className="trades-dtls__seller">{trade.seller} ({trade.sellerUser})</div>
                                        </div> */}
                                        {/* <div className="col-md-8 col-sm-7 col-12">
                                            <div className="trades-dtls__buttons-wrapper">
                                                <AccessControl user={this.props.account.user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                                    {this.renderStatusButton(status)}
                                                </AccessControl>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row mt-2">
                                        <div className="trades-dtls__status-wrapper">
                                            <span
                                                className={`trades-dtls__status-line trades-dtls__status-line_${this.returnStatusNumber(status)}`} />
                                            <div className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                TRADE_STATUS.DEAL,
                                                status
                                            )}`}>
                                                Pending Signature
                                        </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.SIGNED,
                                                    status
                                                )}`}>
                                                Proforma Invoice
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.PROFORMA_INVOICE,
                                                    status
                                                )}`}>
                                                Vessel Nomination
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.VESSEL_NOMINATED,
                                                    status
                                                )}`}>
                                                Documentary instructions required
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.INSTRUCTIONS,
                                                    status
                                                )}`}>
                                                Shipping advice pending
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.ADVICE,
                                                    status
                                                )}`}>
                                                Documents required
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.DOCUMENTS,
                                                    status
                                                )}`}>
                                                Payment required
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.PAYED,
                                                    status
                                                )}`}>
                                                Pending Completion
                                            </div>
                                            <div
                                                className={`trades-dtls__status-stage ${this.returnStatusCompletement(
                                                    TRADE_STATUS.CLOSED,
                                                    status
                                                )}`}>
                                                Closed
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="trades-dtls__info">
                                    <div className="trades-dtls__tabs tab_responsive">
                                        <NavLink
                                            exact
                                            to={`/trades/details/${this.props.match.params.id}`}
                                            className="trades-dtls__tab trades-dtls__tab_active">
                                            General Info
                                        </NavLink>
                                        <AccessControl user={this.props.account.user} isPermissionExist={viewContractPermission()}>
                                            <NavLink to={`/trades/details/${this.props.match.params.id}/contract`}
                                                className="trades-dtls__tab">
                                                Contract
                                            </NavLink>
                                        </AccessControl>
                                        <AccessControl user={this.props.account.user} isPermissionExist={viewBagmarkingPermission()}>
                                            <NavLink to={`/trades/details/${this.props.match.params.id}/bagmarking`}
                                                className="trades-dtls__tab">
                                                Bagmarkings
                                            </NavLink>
                                        </AccessControl>
                                        <NavLink to={`/trades/details/${this.props.match.params.id}/nominatedVesselInfo`}
                                            className="trades-dtls__tab">
                                            Vessel Info
                                        </NavLink>
                                        <AccessControl user={this.props.account.user} isPermissionExist={viewDocInstructionsPermission()}>
                                            <NavLink to={`/trades/details/${this.props.match.params.id}/instructions`}
                                                className="trades-dtls__tab">
                                                Documentary Instructions
                                            </NavLink>
                                        </AccessControl>
                                        <NavLink to={`/trades/details/${this.props.match.params.id}/advice`}
                                            className="trades-dtls__tab">
                                            Shipping Advice
                                            </NavLink>
                                        <NavLink to={`/trades/details/${this.props.match.params.id}/documents`}
                                            className="trades-dtls__tab">
                                            Documents
                                         </NavLink>
                                        <NavLink to={`/trades/details/${this.props.match.params.id}/inspection-reports`}
                                            className="trades-dtls__tab">
                                            Daily Inspection Reports
                                         </NavLink>
                                        <AccessControl user={this.props.account.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN, UserTypes.KYC_USER]} companyTypes={[CompanyTypes.IMPORTER, CompanyTypes.EXPORTER, CompanyTypes.DISTRIBUTOR, CompanyTypes.TRADER]}>
                                            <NavLink to={`/trades/details/${this.props.match.params.id}/messages`}
                                                className="trades-dtls__tab">
                                                Messages
                                            </NavLink>
                                        </AccessControl>
                                        <AccessControl user={this.props.account.user} excludeCompanyTypes={[INSPECTION_COMPANY]}>
                                            <NavLink to={`/trades/details/${this.props.match.params.id}/audit`}
                                                className="trades-dtls__tab">
                                                Audit Log
                                            </NavLink>
                                        </AccessControl>
                                    </div>
                                </div>
                                {/* <div id="contract-text" style={{ display: 'none' }}>
                                    <Contract trade={trade} info={info} user={user}  showMultiOriginPrices={this.tradeRequest.multipleOriginAccept && !this.props.trade.requestInfo.signBuyer && !this.props.trade.requestInfo.signSeller}/>
                                </div> */}
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}`}
                                    render={() => <Info trade={trade} info={info} user={user}
                                        getPriceQuoteContainer={this.props.getPriceQuoteContainer}
                                        ProvisionalQuoteVessel={this.props.ProvisionalQuoteVessel}
                                        isTradeBuyer={isTradeBuyer}
                                        isTradeSeller={isTradeSeller}
                                        isShipping={isShipping}
                                        shippingCompanyList={shippingCompanyList}
                                        inspectionsCompany={this.props.trade.inspections}
                                        PostInspectionQuote={this.props.PostInspectionQuote}
                                        InpectionQuoteStatus={this.props.inspectionQuote.isInspectionCreated}
                                        isInspectionSeller={isInspectionSeller}
                                        shippingQuoteStatus={this.props.shipmentQuote.isShipmentCreated}
                                        inspectionCompanies={this.props.trade.inspectionCompanies}
                                        shippingCompanies ={this.props.shipmentQuote.shippingCompanies}
                                        sendEmail={()=>this.props.sendEmail(this.props.trade.items.single.id)}
                                    />}
                                />
                                <Route
                                    path={`/trades/details/${this.props.match.params.id}/contract`}
                                    render={() => <Contract trade={trade} 
                                            info={info} 
                                            user={user}
                                            isVisible={true}
                                            inspectionCompanies={this.props.trade.inspections} 
                                            showMultiOriginPrices={this.tradeRequest.multipleOriginAccept && !this.props.trade.requestInfo.signBuyer && !this.props.trade.requestInfo.signSeller}/>}
                                />
                                <Route
                                    path={`/trades/details/${this.props.match.params.id}/bagmarking`}
                                    render={() => <Bagmarkings 
                                        match={this.props.match}
                                        bagmarkings={this.props.trade.bagmarkings}
                                        trade={trade}
                                        user={this.props.account.user}
                                        canUploadBagmarking={this.props.account.user.companyId === trade.buyerId || this.props.account.user.companyId === trade.sellerId}
                                        postBagmarking={(id, params, cb) => {
                                            this.props.postBagmarking(id, params, cb);
                                        }} 
                                        approveBagmarking={(id, brandID, bagmarkingID, cb) => {
                                            this.props.approveBagmarking(id, brandID, bagmarkingID, cb);
                                        }} 
                                        rejectBagmarking={(id, brandID, bagmarkingID, cb) => {
                                            this.props.rejectBagmarking(id, brandID, bagmarkingID, cb);
                                        }} />}
                                />
                                <Route
                                    path={`/trades/details/${this.props.match.params.id}/nominatedVesselInfo`}
                                    render={() => <NominatedVesselInfo
                                        tradeId={this.props.match.params.id}
                                        inspectionName={trade.inspectionName}
                                        whoItIs={this.whoItIs()}
                                        />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents`}
                                    render={() => (
                                        <Documents
                                            UpdateDocument={(id, shipmentId, params, docId) =>
                                                this.props.UpdateDocument(id, shipmentId, params, docId)
                                            }
                                            OpenDocument={(id, fileId, newTab) => this.props.OpenDocument(id, fileId, newTab)}
                                            PostDocumentFile={this.props.PostDocumentFile}
                                            GetTradeInvoice={this.props.GetTradeInvoice}
                                            GetTradeInvoiceByShipmentId={this.props.GetTradeInvoiceByShipmentId}
                                            GetTradeDocuments={this.props.GetTradeDocuments}
                                            GetTradeBill={this.props.GetTradeBill}
                                            ApproveDocument={this.props.ApproveDocument}
                                            RejectDocument={this.props.RejectDocument}
                                            ReleaseDocument={this.props.ReleaseDocument}
                                            GetDocumentComments={this.props.GetDocumentComments}
                                            PostDocumentComment={this.props.PostDocumentComment}
                                            loadingDocumentComments={this.props.loadingDocumentComments}
                                            comments={this.props.trade.documentComments}
                                            shipments={this.props.trade.shipments}
                                            state={this.state.billOfLading}
                                            bills={this.props.trade.bills}
                                            invoice={this.props.trade.invoice}
                                            proformaInvoice={this.props.trade.proformaInvoice}
                                            trade={trade}
                                            info={info}
                                            account={this.props.account}
                                            company={company}
                                            documents={this.props.trade.shipmentDocuments}
                                            match={this.props.match}
                                            whoItIs={() => this.whoItIs()}
                                            tradeStatus={status}
                                            loadingDocuments={this.props.loadingDocuments}
                                            locationSearch={window.location.search}
                                            userPermission={this.props.usermanagment.userPermission}
                                            openProformaOperationModal={this.openProformaOperationModal}
                                            loadingProformaInvoice={this.props.loadingProformaInvoice}
                                            documentaryInstructions={this.props.trade.instructions ? this.props.trade.instructions.documentaryInstructions : null}
                                        />
                                    )}
                                />
                                <Route
                                    path={`/trades/details/${this.props.match.params.id}/audit`}
                                    render={() => {
                                        return <AuditLog id={this.props.match.params.id} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/invoice`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        isPreview={false} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/invoice/update`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        isUpdate={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/invoice/preview`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        isPreview={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/invoice`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isPreview={false} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/invoice/preview`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isPreview={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/invoice/update`}
                                    render={(props) => <Invoice id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isUpdate={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/proformaInvoice`}
                                    render={(props) => <ProformaInvoice id={this.props.match.params.id}
                                        isPreview={false} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/proformaInvoice/update`}
                                    render={(props) => <ProformaInvoice id={this.props.match.params.id}
                                        isUpdate={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/documents/proformaInvoice/preview`}
                                    render={(props) => <ProformaInvoice id={this.props.match.params.id}
                                        isPreview={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/bill`}
                                    render={(props) => <BillOfLading id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isPreview={false} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/bill/preview`}
                                    render={(props) => <BillOfLading id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isPreview={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/shipment/:shipmentId/documents/bill/update`}
                                    render={(props) => <BillOfLading id={this.props.match.params.id}
                                        shipmentId={props.match.params.shipmentId}
                                        isUpdate={true} />}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/advice`}
                                    render={() => (
                                        <DocumentShippingAdvice
                                            bills={this.props.trade.bills}
                                            trade={this.props.trade.items.single}
                                            userName={this.props.trade.requestInfo.sellerUserName}
                                            status={this.tradeStatus}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/instructions`}
                                    render={() => (
                                        this.props.trade.instructions
                                            ? <DocumentaryInstructions
                                                data={this.props.trade.instructions}
                                                previewMode={true}
                                                trade={this.props.trade.items.single}
                                            />
                                            : (
                                                <div className="di-form">
                                                    Documentary instructions have not been issued.
                                                </div>
                                            )
                                    )}
                                />
                                <Route
                                    exact
                                    path={`/trades/details/${this.props.match.params.id}/inspection-reports`}
                                    render={() => (
                                        <InspectionReports
                                            match={this.props.match}
                                            reports={this.props.trade.reports}
                                            user={this.props.account.user}
                                            isBuyer={this.props.account.user.companyId === trade.buyerId }
                                            PostInspectionReport={this.props.PostInspectionReport}
                                               approveInspectionReport={(id) => {
                                                this.props.approveInspectionReport(this.props.match.params.id, id);
                                            }}
                                                rejectInspectionReport={(id) => {
                                                this.props.rejectInspectionReport(this.props.match.params.id, id);
                                            }}   
                                            isNonInspection={trade.inspection==="1"}
                                           
                                        />
                                    )}
                                />
                                <Route
                                    path={`/trades/details/${this.props.match.params.id}/messages`}
                                    render={() => {
                                        return <TradeComments id={this.props.match.params.id} tradeRefId={this.props.trade.items.single.tradeRefId} />
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}

                    <Footer />
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        company: state.account.token,
        account: state.account,
        loadingStatus: state.loading.loadingStatus,
        loadingDocuments: state.loading.loadingDocuments,
        loadingDocumentComments: state.loading.loadingDocumentComments,
        loadingRequest: state.loading.requestLoading,
        usermanagment: state.usermanagment,
        shipmentQuote: state.shipmentQuote,
        inspectionQuote: state.inspectionQuote,
        loadingProformaInvoice: state.loading.loadingProformaInvoice,
        loadingCommercialInvoiceProofs: state.loading.loadingCommercialInvoiceProofs,
        loadingCommercialInvoiceIndicator: state.loading.loadingCommercialInvoiceIndicator
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            AutoupdateTriggered,
            loadRequestDetails,
            GetTradeDocuments,
            GetShipments,
            GetTradeBill,
            GetTradeInvoice,
            GetTradeInvoiceByShipmentId,
            GetTradePerformaInvoice,
            LoadRequestInfo,
            PostTradeDocument,
            PostTradeDocumentInvoice,
            UploadProformaPaymenntReciept,
            UploadInvoicePaymentProof,
            ReuploadInvoicePaymentProof,
            ConfirmInvoicePayment,
            GetProformaPaymentReciepts,
            GetInvoicePaymentProofs,
            PostDownpaymentEffected,
            PostDocumentFile,
            UpdateDocument,
            ApproveDocument,
            RejectDocument,
            ReleaseDocument,
            OpenDocument,
            PayStatusFlow,
            Sign: SmartTrade.Sign,
            updateSignedLocally,
            updateVesselNominated,
            updatePayedLocally,
            LoadingStatus,
            updateTradeDocumentLocally,
            UpdateCloseLocally: UpdateCloseLocally,
            sendShippingAdvice,
            sendInstructions,
            getDocInstructions,
            GetInspectionReports,
            PostInspectionReport,
            approveInspectionReport,
            rejectInspectionReport,
            getBagmarkings,
            postBagmarking,
            approveBagmarking,
            rejectBagmarking,
            preloadInspectionCompanies,
            GetDocumentComments,
            PostDocumentComment,
            ClearSingleTrade,
            SetTradeStatus,
            UpdateRequest,
            sendEmail,
            getPriceQuoteContainer,
            ProvisionalQuoteVessel,
            getShippingCompanyList,
            PostInspectionQuote,
            getInpectionStatus,
            getshippingStatus,
            updateLaycanDateChange,
            GetVesselNomination,
            updatePayedFalse,
            UpdateCommercialInvoiceType,
            ClearSubstituteNominationInfo,
            navigate: path => push(path),
            navigateDocs: path => push(`/trades/details/${path}/documents`),
            navigateRequests: path => push('/requests' + path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TradesDetails);