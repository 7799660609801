import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import moment from 'moment';

//API service
import { getKycJoiningRequestList } from '../../../modules/module.platformAdmin';

//style
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { DATEFORMATHOURS } from '../../../services/service.values';

//component
import { EnumsService } from "../../../services/service.utils";
import AdminNav from './AdminNav';

const Countries = EnumsService.countries();

class JoiningRequestList extends Component {
    componentWillMount = () => {
        this.props.getKycJoiningRequestList();
    }

    returnStatusLabel = (status) => {

        switch (status) {

            case 'PENDING':
                return 'APPROVED'

            case 'REQUEST_REJECTED':
                return 'REJECTED'

            case 'NEW':
                return 'NEW'

            default:
                break;
        }
    };

    render() {
        let classOnDasboardKyc = '';
        if(!this.props.showIconLabel){
            classOnDasboardKyc = 'maximum';
        }
        return (
            <React.Fragment>
                <AdminNav />
                <div className={"dashboard-kyc " + classOnDasboardKyc}>
                    <div className="content">
                        <h3 className="mb-1">Joining Request List</h3>
                        <nav className="mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">

                                    <NavLink exact to="/" id="drop-menu">
                                        <a href="#/">Dashboard</a>
                                    </NavLink>

                                </li>
                                <li className="breadcrumb-item active">Joining Requests</li>
                            </ol>
                        </nav>
                        <div className="content-wrappper-kyc box-shadow">

                            <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                                <MaterialTable
                                    columns={[
                                        { title: 'Registered Name', field: 'name' },
                                        { title: 'Registration No', field: 'regNumber' },
                                        {
                                            title: 'Country', field: 'country', render: rowData =>
                                                <span className="exchange__overflow">{`${Countries[rowData.country]}`}</span>
                                        },
                                        { title: 'Type of Company', field: 'companyType' },
                                        { title: 'Status', field: 'kycStatus', render: rowData => <span>{this.returnStatusLabel(rowData.kycStatus)} </span> },
                                        {
                                            title: 'Created', field: 'createdAt', defaultSort: 'desc',
                                            render: rowData =>
                                                <div >
                                                    <span
                                                        className="trades__overflow">{moment(rowData.createdAt).format(DATEFORMATHOURS)}
                                                    </span>
                                                </div>
                                        },
                                        {
                                            title: 'Approved', field: 'joiningApprovedAt',
                                            render: rowData =>
                                                <div >
                                                    <span
                                                        className="trades__overflow">{rowData.joiningApprovedAt === "0001-01-01T00:00:00Z" || !rowData.joiningApprovedAt ? '' : moment(rowData.joiningApprovedAt).format(DATEFORMATHOURS)}
                                                    </span>
                                                </div>
                                        },
                                        { title: 'Reason for Rejection', field: 'rejectionReason' },
                                        {
                                            title: '', sorting: false, render: rowData => <span>

                                                <a href="#/" className="btn-circle-trasnsparent transparent" data-toggle="tooltip"
                                                    data-placement="top" title="View Detail"><FontAwesomeIcon icon={faEllipsisV} /></a>

                                            </span>
                                        }
                                    ]}
                                    data={this.props.kycJoiningRequestList ? this.props.kycJoiningRequestList.companies : []}
                                    onRowClick={(event, rowData) => this.props.navigate(`/company/${rowData.ID}/joiningDetails`)}
                                    icons={{
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward,
                                    }}
                                    options={{
                                        pageSize: 10,
                                        search: false,
                                        pageSizeOptions: [10, 20, 30, 40, 50],
                                        rowStyle: (rowData, index) => {
                                            const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                            return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                        },
                                        thirdSortClick: false
                                    }}
                                />
                            </Preloader>
                        </div>

                    </div>
                </div>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        kycJoiningRequestList: state.platformAdmin.kycJoiningRequestList,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getKycJoiningRequestList,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(JoiningRequestList);