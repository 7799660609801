import React from 'react';
import { Tooltip } from '@material-ui/core';
import { faExclamationTriangle, faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SHIPPINGTYPES } from './service.values';

export const formatIncoterm = (value, serie, index, series) => {
    const incotermChanged = index > 0 && series[index - 1].data.incoterm !== serie.data.incoterm;
    return incotermChanged ? (
        <>
            {value}&nbsp;
            <Tooltip title="Incoterm has changed">
                <span>
                    <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />
                </span>
            </Tooltip>
        </>
    ) : value
}

export const formatShipping = (value, serie, index, series) => {
    const shippingChanged = index > 0 && series[index - 1].data.shipping !== serie.data.shipping;
    return shippingChanged ? (
        <>
            {SHIPPINGTYPES[value] || value}&nbsp;
            <Tooltip title="Shipping has changed">
                <span>
                    <FontAwesomeIcon className="text-danger" icon={faBiohazard} />
                </span>
            </Tooltip>
        </>
    ) : SHIPPINGTYPES[value] || value
}