import React, { Component } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CompanyProfileInfo from './CompanyProfileInfo';
import {loadCompanyDetails} from '../../../modules/module.account'
import '../trades/trades.scss';
import './company-profile.scss';
import Preloader from "../../components/preloader/Preloader";

class CompanyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {}
        }
    }

    loadCompanyDetails = () => {
        if (this.props.match.params.id !== "companyInfo") {
            this.props.loadCompanyDetails(this.props.match.params.id);
        } else {
            this.props.loadCompanyDetails(this.props.account.token.companyId);
        }
    }

    componentDidMount() {
        //    this.props.loadCompanyDetails(this.props.account.token.companyId);
        this.props.loadCompanyDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.match.params.id !== this.props.match.params.id){
            this.props.loadCompanyDetails(this.props.match.params.id);
        }
        if (prevProps.account !== this.props.account) {
            this.setState({
                company: this.props.account.companyProfile.company,
                sellingCapability:this.props.account.companyProfile.company,
                buyingPreference:this.props.account.companyProfile.company
            });
        }
    }
  
    render() {
        return (
            <React.Fragment>
            <Header />
            <div className="container-fluid">
                <div className="admin-magmt"  style={{minHeight:"700px"}}>
                <div className="profile__back" onClick={() => {
                            this.props.history.goBack()
                        }}>Back</div>
                   <h3>Company Details</h3>
                    <nav className="mb-2">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/" id="drop-menu">
                                    Dashboard
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                    Company Profile
                            </li>
                        </ol>
                    </nav>
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                        <CompanyProfileInfo 
                            user={this.props.user} 
                            company={this.state.company}
                            profileId={this.props.match.params.id} 
                            sellingCapability={this.state.sellingCapability && this.state.sellingCapability.sellingCapability} 
                            buyingPreference={this.state.buyingPreference && this.state.buyingPreference.buyingPreference}/>
                    </Preloader>
                </div>
                <Footer />
            </div>
        </React.Fragment>
            
        );
    }
}
const mapStateToProps = state => {
    return {
        account: state.account,
        loading: state.loading.groupActionLoading,
        user: state.account.user,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadCompanyDetails
        },
        dispatch
    );

export default connect(mapStateToProps,mapDispatchToProps)(CompanyProfile);
