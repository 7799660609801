import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { 
    faTachometerAlt, 
    faExchangeAlt, 
    faInbox, 
    faArchive, 
    faHandshake, 
    faUser, 
    faSignOutAlt, 
    faUsersCog, 
    faUserEdit, 
    faIdCard, 
    faQuestionCircle, 
    faFileInvoice, 
    faQuestion,
    faUsers 
} from "@fortawesome/free-solid-svg-icons";

//Styles
import './header.scss';
import { connect } from 'react-redux';
import { logout } from '../../../modules/module.account';
import { push } from 'react-router-redux';
import NotificationMenu from './NotificationMenu';
import AccessControl, { INSPECTION_COMPANY, SHIPPING_COMPANY } from '../AccessControl';
import { UserTypes } from '../../../utils/userTypes';
import { PERMISSION_VALUE } from '../../../services/service.values';
import { getUserPermission } from '../../../modules/module.adminManagement';

import "../../admin/dashboard/index.scss";
import logo from '../../../static/img/logo.png';
import logoSmall from '../../../static/img/logo_small.png';
import { faTimes, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';


class Header extends Component {
    state = {
        showDropDown: false,
        showMenu: false,
        showModal: false,
        modal: '',
        modalData: {},
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (event.target.id !== 'drop-menu') {
            this.setState({
                showDropDown: false,
                showMenu: false
            });
        }

    };

    permissionCheck = (name) => {
        var permissionArray = {};
        let userPermission = this.props.usermanagment.userPermission;
        if (userPermission.length > 0) {
            permissionArray = Object.entries(userPermission).map(([i, value]) => {
                return userPermission[i].permissions;
            })

            for (var j = 0, len = permissionArray.length; j < len; j++) {

                if (name in permissionArray[j]) {
                    return 1;
                }
            }
            return 0;
        }
    }

    showMenu = () => {
        if (window.innerWidth < 992) {
            this.setState({
                showMenu: !this.state.showMenu,
                showDropDown: false
            });
        } else {
            this.setState({
                showDropDown: !this.state.showDropDown,
                showMenu: false
            });
        }
    };
    renderModal = (whatModal) => {
         if(whatModal==="PERMISSION_REQUIRED")
            {
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6>
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</button>
                                </div>
                            </div>
                        </form>

                    </div>
                );
        }
    };
    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };


    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        var permissionExchange = this.permissionCheck(PERMISSION_VALUE.VIEW_EXCHANGE);
        var isExchange = false;
        if (permissionExchange === 1) {
            isExchange = true;
        }
        var permissionTradeRequest = this.permissionCheck(PERMISSION_VALUE.VIEW_TRADEREQUEST);
        var isTradeRequest = false;
        if (permissionTradeRequest === 1) {
            isTradeRequest = true;
        }
        var permissionTradeList = this.permissionCheck(PERMISSION_VALUE.VIEW_TRADE_LIST);
        var isTradeList = false;
        if (permissionTradeList === 1) {
            isTradeList = true;
        }
        var permissionCreateTrade = this.permissionCheck(PERMISSION_VALUE.CREATE_TRADEREQUEST);
        var isCreateTrade = false;
        if (permissionCreateTrade === 1) {
            isCreateTrade = true;
        }
        var permissionViewCompany = this.permissionCheck(PERMISSION_VALUE.VIEW_COMPANY_PROFILE);
        var isViewCompany = false;
        if (permissionViewCompany === 1) {
            isViewCompany = true;
        }
        var permissionViewTenderWhitelisting = this.permissionCheck(PERMISSION_VALUE.MANAGE_TENDER_WHITELIST);
        var isViewTenderWhitelisting = false;
        if(permissionViewTenderWhitelisting === 1){
            isViewTenderWhitelisting = true;
        }
        var permissionViewInspection = this.permissionCheck(PERMISSION_VALUE.VIEW_INSPECTION_QUOTE);
        var isViewInspection = false;
        if (permissionViewInspection === 1) {
            isViewInspection = true;
        }
        var permissionViewShipping = this.permissionCheck(PERMISSION_VALUE.VIEW_SHIPPPING_QUOTE);
        var isViewShipping = false;
        if (permissionViewShipping === 1) {
            isViewShipping = true;
        }
        return (
            <React.Fragment>
                {/* {checkPemission === 0  && <Redirect to="/" />} */}
                {this.state.showModal && this.renderModal(this.state.modal)}
                <header className="header header-usr_magmt justify-content-start" id="header">
                    <nav className="navbar navbar-expand-xl navbar-light bg-white ricx-header">
                        <a className="navbar-brand" href="/#">
                            <img src={logoSmall} alt="Logo small" className="d-lg-none" />
                            <img src={logo} alt="Logo large" className="d-none d-lg-block" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav top-menu mr-auto">
                                <li className="nav-item">
                                    <AccessControl user={this.props.user} >
                                        <NavLink exact to="/" id="drop-menu" className="nav-link" >
                                            <FontAwesomeIcon icon={faTachometerAlt} className="dashboard-icon" /> 
                                            
                                            Dashboard<span className="sr-only">(current)</span>                                        </NavLink>
                                    </AccessControl>
                                </li>
                                <li className="nav-item">
                                    <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
                                        {isExchange ? (
                                            <NavLink exact={true} to="/exchange" id="drop-menu" className="nav-link">
                                                <FontAwesomeIcon icon={faExchangeAlt} className="public-req" />
                                            Public Requests
                                            </NavLink>
                                        ) : (
                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} id="drop-menu" className="nav-link">
                                                    <FontAwesomeIcon icon={faExchangeAlt} className="public-req" />

                                                    Public Requests
                                                </a>
                                            )}
                                    </AccessControl>
                                </li>
                                <li className="nav-item">
                                    <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
                                        {isTradeRequest ? (
                                            <NavLink exact={true} to="/requests" id="drop-menu" className="nav-link">
                                                <FontAwesomeIcon icon={faInbox} className="op-req" /> 
                                                
                                                My Open Requests
                                            </NavLink>
                                        ) : (
                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} id="drop-menu" className="nav-link">
                                                    <FontAwesomeIcon icon={faInbox} className="op-req" /> My Open Requests
                                                </a>
                                            )}
                                    </AccessControl>
                                </li>
                                <li className="nav-item">
                                    <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[SHIPPING_COMPANY]}>
                                        {isTradeList ? (
                                            <NavLink exact={true} to="/trades" id="drop-menu" className="nav-link">
                                                <FontAwesomeIcon icon={faHandshake} className="contract-icon" /> 
                                                
                                                Live Contracts
                                            </NavLink>
                                        ) : (
                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} id="drop-menu" className="nav-link">
                                                    <FontAwesomeIcon icon={faHandshake} className="contract-icon" /> Live Contracts
                                                </a>
                                            )}

                                    </AccessControl>
                                </li>
                                <li className="nav-item ">
                                    <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[SHIPPING_COMPANY]} >
                                        {isTradeList ? (
                                            <NavLink exact={true} to="/pasttrades" id="drop-menu" className="nav-link" >
                                                <FontAwesomeIcon icon={faArchive} className="past-icon" /> 
                                                
                                                Past Contracts
                                            </NavLink>
                                        ) : (
                                                <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} id="drop-menu" className="nav-link" >
                                                    <FontAwesomeIcon icon={faArchive} className="past-icon" /> Past Contracts
                                                </a>
                                            )}

                                    </AccessControl>
                                </li>
                                <li className="nav-item dropdown">
                                    <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} >
                                        <NavLink 
                                            isActive={()=>['/inspectionQuoteOverview/', '/shippingQuoteOverview/'].includes(this.props.history.location.pathname)}
                                            to="#" 
                                            className="nav-link dropdown-toggle1" 
                                            id="navbarDropdown" 
                                            role="button" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"  >
                                            <FontAwesomeIcon icon={faQuoteLeft} className="past-icon" /> 
                                            
                                            Quotes
                                           </NavLink>
                                    </AccessControl>

                                    <div className="dropdown-menu drop-menu-position" aria-labelledby="navbarDropdown">
                                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]}>
                                            {isViewInspection ? (
                                                <NavLink to={`/inspectionQuoteOverview/`} className="dropdown-item">Inspection Quote</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item" >
                                                        Inspection Quote  </a>
                                                )}
                                            {isViewShipping ? (
                                                <NavLink to={`/shippingQuoteOverview/`} className="dropdown-item">Shipping Quote</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item">
                                                        Shipping Quote  </a>
                                                )}
                                        </AccessControl>
                                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} companyTypes={[INSPECTION_COMPANY]}>
                                            {isViewInspection ? (
                                                <NavLink to={`/provisionalInspectionList/`} className="dropdown-item">Provisional Quote</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item" >
                                                        Provisional Quote  </a>
                                                )}
                                            {isViewInspection ? (
                                                <NavLink to={`/finalInspectionList/`} className="dropdown-item">Final Offer</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item" >
                                                        Final Offer  </a>
                                                )}

                                        </AccessControl>
                                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} companyTypes={[SHIPPING_COMPANY]}>
                                            {isViewShipping ? (
                                                <NavLink to={`/provisionalShippingList/`} className="dropdown-item">Provisional Quote</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item" >
                                                        Provisional Quote  </a>
                                                )}
                                            {isViewShipping ? (
                                                <NavLink to={`/finalShippingList/`} className="dropdown-item">Final Offer</NavLink>
                                            ) : (
                                                    <a href="#/" onClick={() => this.openModal('PERMISSION_REQUIRED')} className="dropdown-item" >
                                                        Final Offer  </a>
                                                )}
                                        </AccessControl>
                                    </div>


                                </li>
                            </ul>
                        </div>
                        <div className="head-btn d-flex ml-auto mr-4">
                            <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
                                {isCreateTrade ? (
                                    <Link to='/requests/create' replace={(this.props.history.location.pathname === '/requests/create')}>
                                        <button type="button" className="btn btn-primary text-uppercase btn-ctr">Create New Trade Request</button>
                                    </Link>
                                ) : (
                                        <button type="button" onClick={() => this.openModal('PERMISSION_REQUIRED', this.state.data)} className="btn btn-primary text-uppercase btn-ctr">Create New Trade Request</button>
                                    )}

                            </AccessControl>
                        </div>
                        <NotificationMenu/>

                        <ul className="navbar-nav flex-row ml-xs-auto">
                            <li className="nav-item dropdown nav-help">
                                <a className="nav-item nav-link" href="#/" id="bd-versions" data-toggle="dropdown" aria-haspopup="true" alt="Help" aria-expanded="false"><FontAwesomeIcon icon={faQuestionCircle} />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right py-0 " aria-labelledby="bd-versions">
                                    <a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/6/topic/125a7529-dff8-40b1-a386-7eb7a0a39eaf" target="_blank" rel="noopener noreferrer" className="dropdown-item"><FontAwesomeIcon icon={faQuestion} /> FAQ</a>
                                    <a href="https://fujitsubic.atlassian.net/servicedesk/customer/portal/6/topic/71a44d10-0317-4475-a5a6-367ebad4a219" target="_blank" rel="noopener noreferrer" className="dropdown-item"><FontAwesomeIcon icon={faFileInvoice} /> How To</a>
                                </div>
                            </li>

                            <li className="nav-item dropdown user_name">
                                <a className="nav-item nav-link dropdown-toggle " href="#/" id="bd-versions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon={faUser} /> <span className="user-hide">{this.props.user && this.props.user.name}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right py-0 user-menu" aria-labelledby="bd-versions">
                                    {/* <a className="dropdown-item" href="#/"><FontAwesomeIcon icon={faUserTie}/> Customer Server</a>
                    <a className="dropdown-item " href="#/"><FontAwesomeIcon icon={faQuestion}/>  Help</a>
                    <a className="dropdown-item" href="#/"><FontAwesomeIcon icon={faUserCog}/>Manual</a> */}
                                    {/* <AccessControl user={this.props.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN]} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY]}> */}
                                        <Link 
                                            className="dropdown-item" 
                                            to='/profile'>
                                                <FontAwesomeIcon icon={faUserEdit} /> Edit Profile
                                        </Link>

                                    {/* <AccessControl user={this.props.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN]} userTypes={[UserTypes.COMPANY_ADMIN]} excludeCompanyTypes={[INSPECTION_COMPANY]} > */}
                                    {isViewCompany &&
                                        <Link 
                                            className="dropdown-item" 
                                            to={`/company-profile/${this.props.user.companyId}`}>
                                                <FontAwesomeIcon icon={faIdCard} /> Company Profile
                                        </Link>
                                    }
                                    {isViewTenderWhitelisting && <Link
                                        className="dropdown-item"
                                        to="/tender-whitelisting">
                                            <FontAwesomeIcon icon={faUsers}/>&nbsp;Tender Whitelist Group
                                    </Link>}
                                    {/* </AccessControl> */}
                                    <AccessControl user={this.props.user} excludeUserTypes={[UserTypes.PLATFORM_ADMIN]} userTypes={[UserTypes.COMPANY_ADMIN]} >
                                        <Link 
                                            className="dropdown-item" 
                                            to='/adminManagement/user-groups'>
                                            <FontAwesomeIcon icon={faUsersCog} /> Admin Management
                                        </Link>
                                    </AccessControl>
                                    <a className="dropdown-item" href="#/" onClick={() => {
                                        this.props.logout();
                                        this.props.navigate();
                                    }}
                                    ><FontAwesomeIcon icon={faSignOutAlt} /> Log Out</a>
                                </div>
                            </li>
                        </ul>

                    </nav>
                </header>


            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.account.user,
    profile: state.account.permission,
    usermanagment: state.usermanagment,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logout,
            getUserPermission,
            navigate: () => push('/'),
            navigateTo: path => push(path)
        },
        dispatch
    );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header);
