import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
//COMPONENTS
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import TradeForm from './TradeForm';
import { createTradeRequestPermission } from '../../../../services/service.permission';

//ACTIONS
import { 
    CreateTradeRequest, 
    UpdateTradeRequest, 
    loadRequestDetails,
    GetTradeTemplates,
    DeleteTemplate,
    RenameTemplate
} from '../../../../modules/module.trade';

import Preloader from '../../../components/preloader/Preloader';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSelectDropdown from '../../../components/form/FormSelectDropdown';
import { deleteTemplateModal, templateManagerModal } from './tradeTemplates/templateManagerModals';
import TradeTemplateModal from './tradeTemplates/TradeTemplateModal';


class CreateRequest extends Component {

    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        isInvalidClassName: "is-invalid select_error",
        isValidClassName: 'is-valid',
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }

    getInitialState = () => ({
        hasChanged: false,
        request: {},
        submiting: false,
        showConfirmClear: false,
        showModal: true,
        modal: '',
        modalData: {},
        templateFromDropdown: false,
        selectedTradeTemplate: {
            label: '',
            required: false,
            value: '',
            details: null
        },
        templateToUpdateDelete: null
    })


    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = this.getInitialState()
    }


    componentDidUpdate(prevProps, prevState) {

        // RDEV-1856 If the page is refreshed we ask if the user wants to start a new trade request of continue with the current form
        if(prevProps.history.location.key !== this.state.locationKey) {
            this.setState(state => ({
                locationKey: prevProps.history.location.key,
                showConfirmClear: state.hasChanged
            }))       
        }
        // if it was update trade earlier and now it is create
        if (prevProps.match.params.id !== undefined && !this.isUpdate) {
            this.props.ClearSingleTrade();
        }
        // if it was create trade and now it is update
        if (prevProps.match.params.id === undefined && this.isUpdate) {
            this.setState({ loaded: false });
            this.loadTradeRequest();
        }
    }

    componentDidMount() {
        if(!this.state.locationKey)
            this.setState({locationKey: this.props.history.location.key});
        this.props.GetTradeTemplates();
    }

    loadTradeRequest() {
        const { match, trade } = this.props;

        const request = trade.items.single;
        if (!request) {
            this.props.loadRequestDetails(match.params.id);
        } else {
            //this.updateState(request);
            this.setState(prevState => ({
                loadedRequest: request,
            }));
        }
    }

    get requestId() {
        return this.props.match.params.id;
    }

    get isUpdate() {
        return this.requestId !== undefined;
    }

    trackAction(action) {
        ReactGA.event({
            category: 'Trade',
            action
        })
    }

    selectInitialTemplate = () => {
        const initialTemplate = this.props.trade.tradeTemplates.templates[0].templateId
        this.setState(prevState => ({
            selectedTradeTemplate: {
                templateFromDropdown: false,
                ...prevState.selectedTradeTemplate,
                value: initialTemplate,
            }
        }))
    }

    onSubmit(tradeRequest) {
        this.setState({ submiting: true });
        if(this.isUpdate){
            this.props.UpdateTradeRequest(this.props.match.params.id, tradeRequest, (response) => {
                if(response.data.success){
                    this.props.navigate('/');
                    this.trackAction('Update Request');
                }else{
                    this.setState({
                        systemError:response.data.errorMessage,
                        submiting: false
                    })
                }
            });
        }else{
            this.props.CreateTradeRequest(tradeRequest, (response) => {
                if(response.data.id){
                    this.props.navigate('/');
                    this.trackAction('Create Request');
                }else{
                    this.setState({
                        systemError:response.data.errorMessage,
                        submiting: false
                    })
                }
            });
        }
    }

    cancel() {
        this.props.history.goBack();
    }


    previousStep() {
        for (let key in this.state.steps) {
            if(this.state.steps[key].active){
                this.activateStep(this.state.steps[key].prev);
                break;
            }
        }
    }

    renderModal = () => {
                return ( 
                    <div className="modal__container">
                     <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();                    
                            }}>
                                          <div className="modal-content">
                                              <div className="modal-header">   
                                              <Link to="/"  className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                                      <span aria-hidden="true">&times;</span>    
                                                  </Link>      
                                              </div>          
                                              <div className="modal-body">
                                                   <p className="center-text"><h6>You are not authorized to perform this action.</h6>
                                                   </p>                                        
                                              </div>     
                                              <div className="modal-footer">           
                            <Link to="/" className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                                              </div>
                                          </div>
                                          </form>
                                      </div>
                           );
    };
   

    deleteConfirmationHandler = () => {
        if(this.state.selectedTradeTemplate.value === this.state.templateToUpdateDelete){
            this.setState({
                templateFromDropdown: true,
                selectedTradeTemplate: {
                    label: '',
                    required: false,
                    value: '',
                    details: null
                },
                showModal: false,
                modal: '',
                modalData: {},
                templateToUpdateDelete: null
            })
        }else{
            this.closeModal();
        }
    }

    renderTemplateManager = (whatModal) => {
        switch(whatModal){
            case 'TRADE_TEMPLATES_MANAGER':
                return templateManagerModal({
                    tableData: this.props.trade.tradeTemplates.templates,
                    onDelete: (templateId) => this.setState({
                        showModal: true,
                        modal: 'DELETE_TEMPLATE',
                        templateToUpdateDelete: templateId
                    }),
                    onEdit: (templateId) => {
                        this.setState({
                            showModal: true,
                            modal: 'UPDATE_TEMPLATE_NAME',
                            templateToUpdateDelete: templateId
                        })
                    },
                    closeModal: this.closeModal,
                    loading: this.props.loadingTemplates['FETCHING']
                });
            case 'DELETE_TEMPLATE':
                return deleteTemplateModal({
                    deleteCancel: () => this.setState({
                        showModal: true,
                        modal: 'TRADE_TEMPLATES_MANAGER',
                        templateToUpdateDelete: null
                    }),
                    deleteConfirm: () => {
                        this.props.DeleteTemplate(this.state.templateToUpdateDelete, this.deleteConfirmationHandler)
                    },
                    loading: this.props.loadingTemplates['DELETING']
                })
            case 'UPDATE_TEMPLATE_NAME':
                const template = this.props.trade.tradeTemplates.templates.find(item => item.templateId === this.state.templateToUpdateDelete);
                return(
                    <TradeTemplateModal 
                        isRenaming={true}
                        templateId={this.state.templateToUpdateDelete}
                        templateName={template.templateName}
                        submitHandler={this.props.RenameTemplate}
                        cancelhandler={() => this.setState({
                            showModal: true,
                            modal: 'TRADE_TEMPLATES_MANAGER',
                            templateToUpdateDelete: null
                        })}
                        templateCount={this.props.trade.tradeTemplates.count}
                        closeModal={this.closeModal}
                        loading={this.props.loadingTemplates['UPDATING']}
                        templates={this.props.trade.tradeTemplates.templates}/>
                )
            default:
                return null;
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {},
            templateToUpdateDelete: null
        });
    };

    selectTemplateHandler = (event) => {
        const targetValue = event.target.value;
        this.setState(prevState => {
            return {
                hasChanged: true,
                templateFromDropdown: true,
                selectedTradeTemplate: {
                    ...prevState.selectedTradeTemplate,
                    value: targetValue
                }
            }
        })
    }

    render() {
        const {loading} = this.props;
        if(createTradeRequestPermission() === 0 && (document.location.pathname.indexOf("/requests/create")===0 || document.location.pathname.indexOf("/requests/update")===0))
        { 
             
            return (
                <div>     
                     {this.state.showModal && this.renderModal()}          
                </div>
            );
            
        }
       
       
        else {

        return (
            <React.Fragment>
                <Header />
                { this.state.showModal ? this.renderTemplateManager(this.state.modal) : null}
                <Preloader loading={this.state.submiting} loadingStyle="overflow-spinner" />


                <section className="create-req-section">
                    <div className="create-req-box wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="create-req-trade__heading text-left">{this.isUpdate ? 'Update Request' : 'Create New Request'}</h3>
                                <p> Make your selections for the trade you wish to enter into</p>
                            </div>
                            <div className="col-md-6">
                                <div className="manageTemplate">
                                    <div className="selectTemplate">
                                        <FormSelectDropdown 
                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                            className="select"
                                            name="tradeTemplates"
                                            items={this.props.trade.tradeTemplates.templateOptions}
                                            onSelect={this.selectTemplateHandler}
                                            value={this.state.selectedTradeTemplate}
                                            sortKeys={false}
                                            defaultLabel="Select Template"
                                            disabled={this.props.loadingCounterParties || this.props.loadingInspectionCompanies || this.props.loadingTemplates['FETCHING']}/>
                                        <div className="templateBtn">
                                            <button 
                                                type="button" 
                                                className="btn btn-primary"
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    modal: 'TRADE_TEMPLATES_MANAGER'
                                                })}>
                                                    <span>Manage</span>   
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TradeForm
                            createTrade="true"
                            initialForm={this.state.request}
                            loading={loading}
                            steps={this.state.steps}
                            type={this.isUpdate ? "UPDATE" : "CREATE"}
                            onChange={ e => this.setState({hasChanged: true})}
                            onSubmit={this.onSubmit}
                            onCancel={this.cancel}
                            systemError={this.state.systemError}
                            selectedTemplate={this.state.selectedTradeTemplate.value}
                            selectInitialTemplate={this.selectInitialTemplate}
                            templateSelected={this.state.templateFromDropdown}/>
                    </div>
                </section>

                {this.state.showConfirmClear && 
                <div className="modal__container">
                    <div className='modal__wrapper px-0 py-0' style={{maxWidth: '400px'}}>
                        <div className="modal-header">
                            <h5 className="modal-title w-100">Create a New Trade Request </h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => this.setState({showConfirmClear: false})} aria-label="Close">
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className='modal-body py-0'>
                            You are about to create a new trade request. All unsaved modifications on the current trade request will be lost. Do you want to continue ?
                        </div>
                        <div className="modal-footer">
                            <button className='btn-popup btn-cancel' type="button" onClick={ e => this.setState({showConfirmClear: false})}>Back</button>
                            <button className='btn-popup btn-submit'type="button" onClick={ e => this.setState({
                                ...this.getInitialState(), 
                                locationKey: this.state.locationKey, 
                                request: {resetAt: new Date()}})}>Continue</button>
                        </div>
                    </div>
                </div>}


                <Footer />
            </React.Fragment>
        );
        }
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        request: state.trade.request,
        loading: state.loading.requestCreation,
        loadingTemplates: state.loading.loadingTemplates,
        account: state.account,
        loadingCounterParties: state.loading.loadingCounterParties,
        loadingInspectionCompanies: state.loading.loadingInspectionCompanies
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: path => push('/requests' + path),
            CreateTradeRequest,
            UpdateTradeRequest,
            loadRequestDetails,
            GetTradeTemplates,
            DeleteTemplate,
            RenameTemplate
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequest);
