import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { logout } from '../../../modules/module.account';
import { push } from 'react-router-redux';
//style
import '../admin.scss';
import logo from '../../../static/img/logo.png';
import logoSmall from '../../../static/img/logo_small.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faTachometerAlt, faListAlt, faHandshake, faFlask, faClipboardList, faBars, faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { changeShowIconLabel, changeShowOverlayMenu } from '../../../modules/module.platformAdmin';


//component
import { UserTypes } from '../../../utils/userTypes';
import AccessControl from '../../components/AccessControl';


class AdminNav extends Component {
  render() {
    let logoToRender = null;
    let classOnSideMenu = '';
    let classOnDasboardKyc = '';
    if (this.props.showIconLabel) {
      logoToRender = <img src={logo} alt="Logo large" className="d-lg-block biglogo" />
    } else {
      logoToRender = <img src={logoSmall} alt="Logo small" className="d-lg-block smalllogo " />
      classOnSideMenu = 'minimize';
      classOnDasboardKyc = 'maximum'
    }

    return (
      <React.Fragment>
        <div className={'overlay' + (this.props.showOverlayMenu ? ' show' : '')} onClick={() => {this.props.changeShowOverlayMenu(false)}}></div>
        <div className={'side-menu ' + (this.props.showOverlayMenu ? 'show ' : '') + classOnSideMenu}>
          <div className="dashboard-logo">
            {logoToRender}
            <a href="#/" className="close_menu" onClick={() => {this.props.changeShowOverlayMenu(false)}}>X</a>
          </div>
          <ul className="menu-items">
            <li onClick={() => {this.props.changeShowOverlayMenu(false)}}>
              <AccessControl user={this.props.user} userTypes={[UserTypes.PLATFORM_ADMIN]}>
                <NavLink exact to="/" id="drop-menu">
                  <FontAwesomeIcon icon={faTachometerAlt} />{this.props.showIconLabel ? ' Dashboard' : null}
                </NavLink>
              </AccessControl>
            </li>
            <li onClick={() => {this.props.changeShowOverlayMenu(false)}}>
              <AccessControl user={this.props.user} userTypes={[UserTypes.PLATFORM_ADMIN]}>
                <NavLink exact to="/company/joiningList" id="drop-menu" className="active">
                  <FontAwesomeIcon icon={faHandshake} />{this.props.showIconLabel ? ' Joining Requests' : null}
                </NavLink>
              </AccessControl>
            </li>
            <li onClick={() => {this.props.changeShowOverlayMenu(false)}}>
              <AccessControl user={this.props.user} userTypes={[UserTypes.PLATFORM_ADMIN]}>
                <NavLink exact to="/company/list" id="drop-menu">
                  <FontAwesomeIcon icon={faListAlt} />{this.props.showIconLabel ? ' Company List' : null}
                </NavLink>
              </AccessControl>
            </li>
            <li onClick={() => {this.props.changeShowOverlayMenu(false)}}>
              <AccessControl user={this.props.user} userTypes={[UserTypes.PLATFORM_ADMIN]}>
                <NavLink exact to="/company/kycPendingList" id="drop-menu">
                  <FontAwesomeIcon icon={faClipboardList} />{this.props.showIconLabel ? ' Registration Pending List' : null}
                </NavLink>
              </AccessControl>
            </li>
            <li onClick={() => {this.props.changeShowOverlayMenu(false)}}>
              <AccessControl user={this.props.user} userTypes={[UserTypes.PLATFORM_ADMIN]}>
                <NavLink exact to="/log" id="drop-menu">
                  <FontAwesomeIcon icon={faFlask} />{this.props.showIconLabel ? ' Trade Audit Log' : null}
                </NavLink>
              </AccessControl>
            </li>
          </ul>
        </div>
        <div className={"dashboard-kyc " + classOnDasboardKyc}>
          <div className="header-kyc">
            <div className="float-left">
              <a href="#/" className="menu-bars">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => { this.props.changeShowIconLabel(this.props.showIconLabel) }} /></a>
              <a href="#/" className="menu-bars-sm">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => { this.props.changeShowOverlayMenu(true) }} /></a>
            </div>
            <ul className="float-right mt-2 dropdown nav">
              <li className="nav-item">
                <a className="nav-item nav-link dropdown-toggle mr-md-2"
                  href="#/" id="bd-versions" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <FontAwesomeIcon icon={faUser} />
                  {this.props.user && this.props.user.name}
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                  <a className="dropdown-item" href="#/" onClick={() => {
                    this.props.logout();
                    this.props.navigate();
                  }}
                  ><FontAwesomeIcon icon={faSignOutAlt} />Log Out</a>
                </div>
              </li>
            </ul>
          </div></div>

      </React.Fragment>
    );
  }
}


const mapStateToProps = state => ({
  user: state.account.user,
  showIconLabel: state.platformAdmin.showIconLabel,
  showOverlayMenu: state.platformAdmin.showOverlayMenu
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      changeShowIconLabel,
      changeShowOverlayMenu,
      navigate: () => push('/'),
      navigateTo: path => push(path)
    },
    dispatch
  );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AdminNav);

