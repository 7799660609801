import React from 'react';
import PropTypes from 'prop-types';
import RxNumberFormat from './RxNumberFormat';
import { CURRENCYOPT } from '../../../services/service.values.js';

/**
 * @author Jean-Marc Cheynier <jean-marc.cheynier@fujitsu.com>
 * @version RDEV-2063
 * Default component renderer for trade price in RicexProject.
 * @param  {object} Trade - Trade Object to render the price
 * @component
 */
const TradePriceFormatter = ({ trade, displayType, ...props }) => {
	
	return (
		<RxNumberFormat
			value={trade.price}
			displayType={displayType}
			prefix={`\u00A0${CURRENCYOPT[trade.currency] || "USD"} `}
			{...props}
		/>)
}

TradePriceFormatter.propTypes = {
	trade: PropTypes.object.isRequired,
	displayType: PropTypes.oneOf(['text', 'input']),
}

TradePriceFormatter.defaultProps = {
	displayType: 'text',
}

export default TradePriceFormatter;