import React from 'react';
import { DATEFORMATHOURS, DATEFORMAT } from '../../../services/service.values';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import './FormDateField.scss';

const defaultStyle = {
    labelClassName :"label",
    wrapperClassName : "form-date",
    inputClassName : "input",
    isValidClassName: "",
    isInvalidClassName: "input_error"
};  

export default ({
        name,
        item,
        onSelect,
        label,
        placeholder = 'Select a date',
        disabled = false,
        time = true,
        dateFormat = DATEFORMATHOURS,
        popperPlacement,
        minDate = moment().format(DATEFORMATHOURS),
        maxDate,
        selectsStart,
        selectsEnd,
        customStyle,
        validation,
        ...rest
    }) => {
    const isInvalid = validation && validation.hasOwnProperty(name);
    customStyle = { ...defaultStyle, ...customStyle};

    /* React Date Picker v2+ uses js Date Objects */
    const momentToDate = (obj) => {
        if(moment.isMoment(obj)){
            return obj.toDate();
        }
        return obj
    }

    /* React Date Picker 3.3.0 does not accept format DD -> dd */
    const reacDatePickerFormat = {
        [DATEFORMATHOURS]: "dd MMM yyyy HH:mm",
        [DATEFORMAT]: "dd MMM yyyy",
        default: "dd MMM yyyy"
    }

    const CustomInput = React.forwardRef(({ value = "", onClick, className }, ref) => ( 
        <input ref={ref} type="text" value={value} onClick={onClick} className={className} onChange={e => e.preventDefault()}/>
    ))

    
    if(!item){
        return false;
    }else{
        return (
            <div className={customStyle.wrapperClassName}>
                <label className={customStyle.labelClassName}>
                    {label ? item.label : label} 
                    {label && item.required && <span className="req-field">&nbsp;*</span>}
                </label>
                <div className="date">
                    <DatePicker
                        popperClassName="test"
                        name={name}
                        className={`${customStyle.inputClassName} ${isInvalid ? customStyle.isInvalidClassName :  item.touched ? customStyle.isValidClassName : ''}`}
                        onChange={date => onSelect(name, moment(date))}
                        {...rest}
                        customInput={<CustomInput/>}
                        popperPlacement={popperPlacement}
                        minDate={momentToDate(minDate)}
                        maxDate={momentToDate(maxDate)}
                        selected={momentToDate(item.value)}
                        placeholderText={placeholder}
                        dateFormat={reacDatePickerFormat[dateFormat] || reacDatePickerFormat.default}
                        showTimeSelect={time}
                        disabled={disabled}
                        autoComplete="off"
                        selectsEnd={momentToDate(selectsEnd)}
                        selectsStart={momentToDate(selectsStart)}
                        startDate={momentToDate(selectsStart)}
                        endDate={momentToDate(selectsEnd)}
                    />
                </div>
            </div>
        );
    }
              
};
