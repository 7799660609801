import React, { Component } from 'react';
import { EnumsService } from '../../../services/service.utils';

import {
    FormRow,
    VirtualDropdown,
} from '../../components/Form';
import { Link } from "react-router-dom";
import { COMPANY_TYPES_REG } from '../../../services/service.values';
import Preloader from '../../components/preloader/Preloader';
import FormInputField from '../../components/form/FormInputField';
import FormPhoneField from '../../components/form/FormPhoneField';
import FormTextareaField from '../../components/form/FormTextareaField';

const Countries = EnumsService.countries();
const countriesOptions = Object.keys(Countries).map((key) => ({
    value: key,
    label: Countries[key]
}));
const companyTypeOptions = Object.keys(COMPANY_TYPES_REG).map(key => ({
    value: key,
    label: COMPANY_TYPES_REG[key]
}));

export class RegisterCompany extends Component {
    state = {
        companyType: ''
    };

    render() {

        let { state, setField, validation, onComplete, isRegistering } = this.props;
        return (
            <div className="col-12">
                <form onSubmit={e => onComplete(e)}>
                    <FormRow>
                        <div className="col-12 col-md-6 mb-3">
                            <FormInputField
                                validation={validation}
                                name="name"
                                maxLength="50"
                                value={state.name}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <FormInputField
                                validation={validation}
                                name="regNumber"
                                label="Registration No*"
                                required={true}
                                maxLength="75"
                                value={state.regNumber}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-12 col-md-6 mb-3">
                            <VirtualDropdown
                                validation={validation}
                                name="companyType"
                                label="Company Type"
                                required={true}
                                items={companyTypeOptions}
                                onChange={val => {
                                    setField('companyType', val.value);
                                }}
                                value={{ value: state.companyType.value, label: COMPANY_TYPES_REG[state.companyType.value] }}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <VirtualDropdown
                                validation={validation}
                                name="country"
                                label="Country"
                                items={countriesOptions}
                                required={true}
                                onChange={
                                    val => {
                                        setField('country', val.value);
                                    }
                                }
                                value={{ value: state.country.value, label: Countries[state.country.value] }}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-12 col-md-6 mb-3">
                            <FormInputField
                                validation={validation}
                                name="contact"
                                label="Contact Person"
                                required={true}
                                maxLength="50"
                                value={state.contact}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <FormInputField
                                validation={validation}
                                name="email"
                                required={true}
                                value={state.email}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-12 col-md-6 mb-3">
                            <FormPhoneField
                                value={state.phone}
                                name="phone"
                                required={true}
                                validation={validation}
                                onChange={value => setField('phone', value)}
                            />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <FormInputField
                                validation={validation}
                                name="site"
                                maxLength="50"
                                value={state.site}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-12 mb-3 form-input">
                            <FormTextareaField
                                validation={validation}
                                name="about"
                                maxLength="300"
                                rows="3"
                                cols="70"
                                value={state.about}
                                onChange={e => setField(e.target.name, e.target.value)}
                            />
                        </div>
                    </FormRow>

                    <div className="row">
                        <div className="col-12 justify-content-center">
                            <span className="register__note">
                                Please ensure that the above information is accurate. It will not be possible to edit it later without contacting Fertilizer Exchange customer support.
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="register-required register-required_company">*Required fields</span>
                            <button
                                disabled={isRegistering}
                                onClick={e => onComplete(e)}
                                className="btn btn--blue register-button-company lh-1">
                                <Preloader loadingStyle="dots" loading={isRegistering}>
                                    <span>Submit Request</span>
                                </Preloader>
                            </button>
                        </div>
                    </div>
                    <div className="register-required register-required_company">
                    <p className="login-text">
                              Already have an account?
                              <Link to="/account/login" className="fp-link login-forgot"> Login</Link>
                        </p>
                        </div>
                </form>
            </div>
        );
    }
}
