import React from 'react';
import Preloader from '../../../../components/preloader/Preloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline'; 
import { DATEFORMAT } from '../../../../../services/service.values';
import CustomMaterilaPreloader from '../../../../components/preloader/CustomMaterialPreloader';

export const templateManagerModal = ({ tableData, onDelete, onEdit, closeModal, loading }) => {
    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <h5 className="modal-title create-heading">Trade Templates List</h5>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        </div>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">                                                                                                                                                                                                                                                                    
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <MaterialTable 
                            columns={[
                                { title: 'Template Name', field: 'templateName' },
                                { title: 'Creation Date', field: 'createdAt', render: rowData => <span className="trades__overflow">{moment(rowData.createdAt).format(DATEFORMAT)}</span>},
                                { title: 'Request Type', field: 'requestType' },
                                { title: 'Privacy', field: 'privacyType'},
                                {title: 'Action', sorting: false, render: rowData => (
                                    <div className="action-btn">
                                        <Tooltip title="Delete Template" placement="top-start" arrow >
                                            <span className="btn-icon btn-circle-icon btn-danger btn-icon-res"
                                                onClick={() => onDelete(rowData.templateId)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Rename Template" placement="top-start" arrow>
                                            <span className="btn-icon btn-circle-icon btn-submit btn-icon-res"
                                                onClick={() => onEdit(rowData.templateId)}> 
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            ]}
                            data={tableData}
                            isLoading={loading}
                            components={{
                                OverlayLoading: CustomMaterilaPreloader,
                            }}
                            title=''
                            options={{
                                pageSize: 5,
                                search: false,
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2 === 0) ? '#00ff301c':'#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                thirdSortClick: false
                            }}
                            icons={{
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                SortArrow: ArrowUpward,
                                DeleteOutline: DeleteOutline
                            }}/>
                            <br/><span className="req-field">*</span> <b>Note</b> : Max 15 templates are allowed per user
                    </div>
                </div>
            </div>
        </div>
    )
}

export const deleteTemplateModal = ({ deleteCancel, deleteConfirm, loading}) => {
    return(
        <div className="modal__container">
            <div className="modal__wrapper px-0 py-0">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center">
                            <p className="modal-title create-heading">
                                Are you sure you want to delete this template?
                            </p>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        </div>
                    </div>
                    <div className="modal-footer pt-0">
                        <button type="button" onClick={deleteCancel} className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                        <button type="button" className="btn-popup btn-submit" onClick={deleteConfirm}>
                            <Preloader loadingStyle="dots" loading={loading}>
                                <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Ok</span>
                            </Preloader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}