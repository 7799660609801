import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import moment from 'moment-timezone';
import 'chartjs-plugin-colorschemes';
import { renderToString } from 'react-dom/server'
// import a particular color scheme
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {getPrices} from '../../../../modules/module.tender';

import {DATEFORMATHOURS, CURRENCYOPT} from '../../../../services/service.values';
import {EnumsService} from '../../../../services/service.utils';
import NumberFormat from 'react-number-format';

const CompareGraphOwnerView =  ({bids, account}) => {
    // RDEV-1976
    const data = useMemo(() => {
        const series = {};
        const datasets = [];

        // RDEV-1976 - RDEV-1862 Display series of price formultiple origins
        if(bids && bids.length !== 0 && bids[0].multipleOriginPrice && bids[0].multipleOriginPrice.length !== 0) {
            // Case multiple origins and prices
            bids.filter(bid => (bid.fromCompanyID !== account.token.companyId && bid.status === 'NEW' && moment().isBefore(bid.bidValidateDate))).forEach((bid) => {
                if(bid.multipleOriginPrice) {
                    bid.multipleOriginPrice.forEach(item => {
                        // Ignore prices undefined or '0'
                        if(item.price) {
                            // If this serie of the dataset does not exist then we initialize it.
                            if(!series[item.origin]) {
                                series[item.origin] = {
                                    label: EnumsService.countries()[item.origin],
                                    data: [],
                                    showLine: true,
                                    pointRadius: 6,
                                    xAxisID: 'time-x-axis'
                                }
                            }
                            // Add point to the array of data 
                            series[item.origin].data.push({
                                t:moment(bid.createdAt).format(DATEFORMATHOURS),
                                y: item.price,
                                // RDEV-2490
                                // Ternary expression used to avoid undefined bug with old data 
                                freight: (item.priceBreakdown) ? item.priceBreakdown.freight : "-", 
                                fob: (item.priceBreakdown) ? item.priceBreakdown.fob : "-", 
                                incoterm: (bid.incoterm) ? bid.incoterm : "-",
                                insurance: (item.priceBreakdown) ? item.priceBreakdown.insurance : "-", 
                                origin: EnumsService.countries()[item.origin],
                                companyName: bid.fromCompanyName,
                                currency: bid.currency
                            })
                        }
                    })
                }
            })
            for (const value of Object.values(series)) {
                datasets.push(value) 
            }
        }else{
            // Case single origin and price
            let euroPrices = bids
                .filter(item => (item.currency === "EURO" && item.fromCompanyID !== account.token.companyId && item.status === 'NEW' && moment().isBefore(item.bidValidateDate)))
                .map(item => ({
                    t:moment(item.createdAt).format(DATEFORMATHOURS),
                    y: item.price, 
                    freight: (item.priceBreakdown) ? item.priceBreakdown.freight : "-", 
                    fob: (item.priceBreakdown) ? item.priceBreakdown.fob : "-", 
                    incoterm: (item.incoterm) ? item.incoterm : "-",
                    insurance: (item.priceBreakdown) ? item.priceBreakdown.insurance : "-", 
                    companyName: item.fromCompanyName,
                    currency: item.currency
                }));
            let usdPrices = bids
                .filter(item => (item.currency === "US$" && item.fromCompanyID !== account.token.companyId && item.status === 'NEW' && moment().isBefore(item.bidValidateDate)))
                .map(item => ({
                    t:moment(item.createdAt).format(DATEFORMATHOURS),
                    y: item.price,  
                    freight: (item.priceBreakdown) ? item.priceBreakdown.freight : "-", 
                    fob: (item.priceBreakdown) ? item.priceBreakdown.fob : "-", 
                    incoterm: (item.incoterm) ? item.incoterm : "-",
                    insurance: (item.priceBreakdown) ? item.priceBreakdown.insurance : "-", 
                    companyName: item.fromCompanyName,
                    currency: item.currency
                }));
                
            if(euroPrices.length !== 0) {
                datasets.push({
                    label:"Euro Prices",
                    data: euroPrices,
                    showLine: true,
                    pointRadius: 6,
                    xAxisID: 'time-x-axis'
                })
            }
            if(usdPrices.length !== 0) {
                datasets.push({
                    label:"USD Prices",
                    data: usdPrices,
                    showLine: true,
                    pointRadius: 6,
                    xAxisID: 'time-x-axis'
                })
            } 
        }

        return { datasets };
    }, [bids, account.token.companyId]);

    return <>
        <div className="chart-wrapper" style={{"height":"350px"}}>
            {bids.length !==0?
                <Line type="line"
                    offset={true}
                    pointStyle="circle"
                    data={data}
                    
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                title: (tooltipItems) => {
                                    return `Received on ${tooltipItems[0].xLabel}`
                                },
                                beforeLabel:  (tooltipItem, chartData) => {
                                    const datasetIndex = tooltipItem.datasetIndex;
                                    const pointIndex = tooltipItem.index;
                                    const pointData = chartData.datasets[datasetIndex].data[pointIndex];
                                    return `From ${pointData.companyName} `;
                                }, 
                                label: (tooltipItem, chartData) => { 
                                    const datasetIndex = tooltipItem.datasetIndex;
                                    const pointIndex = tooltipItem.index;
                                    const pointData = chartData.datasets[datasetIndex].data[pointIndex];
                           
                                    // RDEV-2698 Extra condition needed to avoid 0 getting displayed as the priceBreakdown value (fob = 0, freight = 0, insurance = 0) when the counterpart was doing a counter a second time 
                                    let priceBreakdown = "";
                                    if(pointData.incoterm !== "FOB" && (pointData.fob + pointData.freight + pointData.insurance) > 0){
                                        if(pointData.incoterm === "CIF"){
                                            priceBreakdown = `(fob: ${CURRENCYOPT[pointData.currency] || "USD" + " " + pointData.fob} + freight: ${CURRENCYOPT[pointData.currency] || "USD" + " " + pointData.freight} + insurance: ${CURRENCYOPT[pointData.currency] || "USD" + " " + pointData.insurance})`}
                                        else{
                                            priceBreakdown = `(fob: ${CURRENCYOPT[pointData.currency] || "USD" + " " + pointData.fob} + freight: ${CURRENCYOPT[pointData.currency] || "USD" + " " + pointData.freight})`           
                                        }
                                    }
                                    
                                    return renderToString(
                                            <NumberFormat 
                                                displayType={'text'} 
                                                renderText={text => text}
                                                thousandSeparator={true} 
                                                value={tooltipItem.yLabel}
                                                prefix={`${CURRENCYOPT[pointData.currency] || "USD"} `}
                                                suffix={` ${priceBreakdown}`}
                                            />
                                        )
                                }     
                            }
                        },
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'time'
                                },
                                id: 'time-x-axis',
                                type: 'time',
                                time: {
                                    parser: DATEFORMATHOURS,
                                    tooltipFormat: 'll',
                                    displayFormats: {
                                        'millisecond': DATEFORMATHOURS, //I have tried without the 'a' too, same result
                                        'second': DATEFORMATHOURS,
                                        'minute': DATEFORMATHOURS,
                                        'hour': DATEFORMATHOURS,
                                        'day': DATEFORMATHOURS,
                                        'week': DATEFORMATHOURS,
                                        'month': DATEFORMATHOURS,
                                        'quarter': DATEFORMATHOURS,
                                        'year': DATEFORMATHOURS,
                                    }
                                },
                                distribution: 'series',
                                adapters: {
                                    date: 'moment,'
                                },
                                ticks: {
                                    source: 'auto',
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Price (per MT)'
                                },
                            }]
                        },
                        plugins: {
                            colorschemes: {
                                scheme: Tableau20
                            }
                        }
                    }}/>
                :<div className="text-center">No Offer To Display</div>    
            }
        </div>
    </>
}

CompareGraphOwnerView.propTypes = {
    bids: PropTypes.arrayOf(PropTypes.any),
    account: PropTypes.object
}

const mapStateToProps = state => {
    return {
        account: state.account
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({getPrices}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompareGraphOwnerView);


