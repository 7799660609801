import React, { Component } from "react";
import { RicexCustomFileLabel } from '../../../../components/form/FormFileUpload.module.scss';
import { companyLogoUpload } from '../../../../../modules/module.account';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ViewCompanyLogo from './ViewCompanyLogo';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CompanyLogo extends Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        const initialLogo = this.props.account.companyProfile && this.props.account.companyProfile.company.companyLogo;

        this.state = {
            url: null,
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            images: initialLogo,
            previews: {},
            btnhide: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    onFilesAdded(e) {
        if (e.target.files.length !== 0 && this.isValid(e.target.files[0])) {
            e.persist()
            this.setState(prevState => {
                return {
                    file: e.target.files[0],
                }
            }
            );
        }
    }

    onCancel = () => {
        this.input.current.value = "";
        this.setState(prevState => {
            // delete prevState.images;
            return {
                file: "",
                images: prevState.images,
                fileError: undefined
            }
        }
        );
    }

    uploadFile(e) {
        e.preventDefault();
        e.persist();
        if (this.isValid(this.state.file)) {
            this.setState({ uploading: true });
            const formData = new FormData();
            formData.append("upload", this.state.file, this.state.file.name);
            this.props.companyLogoUpload(formData, this.props.companyId, res => {
                this.input.current.value = "";
                if (res.status === 200) {
                    this.setState(prevState => {
                        delete prevState.images;
                        return {
                            uploading: false,
                            file: "",
                            images: res.data.document
                        }
                    },
                        () => {
                            this.setState({ images: res.data.document, btnhide: false });
                        }
                    );
                } else {
                    this.setState({
                        uploading: false,
                        fileError: "An error occured while uploading the image"
                    });
                }
            })
        }
    }

    isValid(image) {
        const fileType = image.type;
        let fileSize = image.size;
        fileSize = fileSize / (1024 * 1024);
        let result = true;
        if (!["image/png", "image/jpeg"].includes(fileType)) {
            this.setState({ fileError: "Please upload file only with *.png, *.jpeg" });
            result = false;
        } else if (fileSize > 15.36) {
            this.setState({ fileError: "File size can't exceed 15 MB" });
            result = false;
        } else {
            this.setState({ fileError: undefined });
        }
        return result;
    }

    removeImage(imageHash) {
        this.setState(prevState => {
            delete prevState.images[imageHash]
            return { images: prevState.images }
        }
        );
    }

    onEditShow = () => {
        this.setState({ btnhide: true });
    }

    render() {

        const { validation, name } = this.props;
        const isInvalid = validation && validation.hasOwnProperty(name);
        return (
            <div>
                <div className="mt-2">
                    <h4>Company Logo </h4>
                </div>
                <div>
                    <ViewCompanyLogo image={this.state.images} />
                </div>
                <div className="col-sm-6 offset-sm-3 mb-3">
                    <div className="custom-file-container">
                        {this.state.btnhide
                            ?
                            <div className="form-row">
                                <div className="col">
                                    <div className="custom-file">
                                        <input type="file" ref={this.input}
                                            className={`custom-file-input`}
                                            id="inputImage"
                                            aria-describedby="inputImage"
                                            disabled={this.state.uploading}
                                            accept=".jpg,.png,.jpeg,.tif, image/*"
                                            onChange={this.onFilesAdded} />
                                        <label className={`custom-file-label ${RicexCustomFileLabel}`} htmlFor="inputImage">{this.state.file ? this.state.file.name : 'Choose file'}</label>
                                    </div>
                                </div>
                                <div className="">
                                    {(this.state.file || this.state.fileError) &&
                                        <button type="button" className="btn btn-cancel ml-1"
                                            disabled={this.state.uploading}
                                            onClick={this.onCancel}>
                                            Cancel
                                </button>}
                                    {(this.state.file && this.state.fileError === undefined) &&
                                        <button type="button" className="btn btn-upload ml-1"
                                            disabled={this.state.uploading}
                                            onClick={this.uploadFile}>
                                            <span>{this.state.uploading ? "Uploading..." : "Upload"}</span>
                                        </button>}
                                </div>
                            </div>
                        :
                        (this.props.account.user.companyId === this.props.account.companyProfile.company.ID) 
                         && <div className="text-center">
                            <button type="button" className="btn btn-edit-Link" onClick={this.onEditShow}>
                                <FontAwesomeIcon icon={faEdit}/> Edit
                            </button>
                            </div>}
                    </div>
                    {isInvalid &&
                        <small id="helpFile" className="form-text text-danger">This image is not uploaded yet</small>}
                    {this.state.fileError ?
                        <small id="helpFile" className="form-text text-danger">{this.state.fileError}</small> :
                        <small id="helpFile" className="form-text text-muted"></small>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        account: state.account,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            companyLogoUpload
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLogo);
