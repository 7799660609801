import React from 'react'

import FormSelectDropdown from './FormSelectDropdown'
//import { DOCUMENT_UPLOADER } from './../../admin/trades/services/documents.service';


/* RDEV-1777 Create form element to handle Trade form mandatory and optional document selection.
*/
const defaultOptions = {
    showDescription: true,
}

const FormTradeDocumentSelect = ({document, uploaderOptions, onChange, id='doc-select', disableDocumentSelection=false}, options)=>{

    const _options = {...defaultOptions, ...options}
    /** onDocumentChecked notify parent component */
    const onDocumentChecked = e => {
        onChange({...document, selected: !document.selected });
    }

    /** onUploaderChange notify parent component */
    const onUploaderChange = e => {
        document.uploadedBy = e.target.value;
        onChange(document);
    }

    return(<div className="form-inline form-row my-1">
        <div className='col'>
            <div className="form-check-inline">
                <input className='form-check-input'
                    id={id}
                    type="checkbox"
                    disabled={document.required || disableDocumentSelection}
                    checked={document.selected === true}
                    onChange={onDocumentChecked}
                />

                <label className="form-check-label" htmlFor={id}>
                    {`${document.title} ${(_options.showDescription && document.description )?document.description:''}`} 
                </label>

            </div>
        </div>
        {/* RDEV-1777 Uploaded by to be available only for specific documents with 'uploadedBy' field */}       
        {(document.selected && document.enableUploaderSelection) &&
        <div className="col-sm-6">
            <FormSelectDropdown
                name='uploadedBy'
                value={{
                    label: 'Uploaded By ',
                    value: document.uploadedBy
                }}
                onSelect={onUploaderChange}
                items={uploaderOptions}
                customStyle= {{
                    wrapperClassName: 'form-inline float-right',
                    labelClassName: 'col-form-label-sm mr-2',
                    selectClassName: 'custom-select custom-select-sm'
                }}
                disabled={disableDocumentSelection}/>
        </div>}   
    </div>)
}

export default FormTradeDocumentSelect;