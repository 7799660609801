import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAuthorizedUsers, addNewProvider } from '../../modules/module.adminManagement';
import { push } from 'react-router-redux';

import './adminManagement-style.scss';
import '../admin/requests/create/create-trade-request.scss'
import '../components/form/FormInputField.scss';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import SaveIcon from '@material-ui/icons/Save';
import createTheme from '@material-ui/core/styles/createTheme'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

class AuthorizedUsersList extends Component {
  constructor(props) {
    super(props)
    this.theme = createTheme({
      palette: {
        primary: {
          main: '#b6bdf0',
        },
        secondary: {
          main: '#5465dd',
        },
      },
    })
  }

  componentWillMount = () => {
    this.fetchFunction()
  }

  fetchFunction = () => {
    this.props.getAuthorizedUsers(this.props.companyID)
  }

  getItems = () => {
    let users = this.props.usermanagment.activeUserList
    return users
  }

  render() {
    return (
      <React.Fragment>
        <section className="rbac-body">
          <MuiThemeProvider theme={this.theme}>
            <MaterialTable
              columns={[
                { title: 'First Name', field: 'firstName' },
                { title: 'Last Name', field: 'lastName' },
                { title: 'Telephone No. ', field: 'phone' },
                { title: 'Email', field: 'email' },
              ]}
              data={this.getItems()}
              title=" "
              icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                SortArrow: ArrowUpward,
                ResetSearch: Clear,
                SaveIcon: SaveIcon,
              }}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5,
                rowStyle: (rowData, index) => {
                  const backgroundColor =
                    index % 2 === 0 ? '#00ff301c' : '#FBFBFB'
                  return {
                    borderColor: '#c3e6cb',
                    backgroundColor: backgroundColor,
                  }
                },
                thirdSortClick: false,
              }}
            />
          </MuiThemeProvider>
        </section>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    usermanagment: state.usermanagment,
    loading: state.loading.requestLoading
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAuthorizedUsers,
      addNewProvider,
      navigate: path => push('/addProvider' + path)
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedUsersList);
