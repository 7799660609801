import axios from 'axios';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query'

let isAbsoluteURLRegex = /^(?:\w+:)\/\//
export const API_URL = process.env.REACT_APP_API_URL + '/v1/api'

axios.interceptors.request.use(function (config) {
    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = API_URL + config.url;
    }
    return config;
});

export const TradeApi = {
    list: (params) => axios.get(`/trade/`, { ...authHeader(params) }),
    getPage: (data, params) => axios.get(`/trade/pagination`, { ...authHeader(params, { 'Cache-Control': 'no-cache' }), params: data }),
    get: id => axios.get('/trade/' + id, authHeader()),
    patch: (id, requestBody) => axios.patch('/trade/' + id, requestBody, authHeader()),
    getInspectionTradeList: (data, params) => axios.get('/inspection/trades', {
        ...authHeader(params),
        params: tradeListParams(data)
    }),
    getRequestInfo: id => axios.get('/trade/' + id + '/info', authHeader()),
    getShipments: id => axios.get('/trade/' + id + '/shipments', authHeader()),
    getTradeDocuments: (tradeId, shipmentId) => axios.get(`/trade/${tradeId}/shipment/${shipmentId}/documents`, authHeader()),
    getTradeBill: (tradeId, shipmentId) => axios.get(`/trade/${tradeId}/shipment/${shipmentId}/bill`, authHeader()),
    getTradeInvoice: id => axios.get('/trade/' + id + '/invoice', authHeader()),
    getTradeInvoiceByShipmentId: (tradeId, shipmentId) => axios.get(`/trade/${tradeId}/shipment/${shipmentId}/multisetInvoice`, authHeader()),
    getTradePerformaInvoice: id => axios.get('/trade/' + id + '/proformaInvoice', authHeader()),
    postDocument: (id, shipmentId, params) => axios.post(`/trade/${id}/shipment/${shipmentId}/bill`, params, authHeader()),
    updateBill: (id, shipmentId, params) => axios.put(`/trade/${id}/shipment/${shipmentId}/bill`, params, authHeader()),
    updateInvoice: (id, params) => axios.put(`/trade/${id}/invoice`, params, authHeader()),
    updateMultisetInvoice: (id, shipmentId, params) => axios.put(`/trade/${id}/shipment/${shipmentId}/multisetInvoice`, params, authHeader()),
    postDocumentInvoice: (id, params) => axios.post(`/trade/${id}/invoice`, params, authHeader()),
    postTradeMultisetInvoice: (id, shipmentId, params) => axios.post(`/trade/${id}/shipment/${shipmentId}/multisetInvoice`, params, authHeader()),
    postDocumentPerformaInvoice: (id, params) => axios.post(`/trade/${id}/proformaInvoice`, params, authHeader()),
    updateDocumentPerformaInvoice: (id, params) => axios.put(`/trade/${id}/proformaInvoice`, params, authHeader()),
    postTradeImage: (params) => axios.post('/trade/trade_doc', params, authHeader()),
    getTradeDocument: (params) => axios.get('/trade/trade_doc', { ...authHeader(params), responseType: 'blob' }),
    updateDocumentFile: (tradeId, shipmentId, params, docId) => axios.put(`/trade/${tradeId}/shipment/${shipmentId}/document/${docId}`, params, authHeader()),
    postDocumentFile: (tradeId, shipmentId, params) => axios.post(`/trade/${tradeId}/shipment/${shipmentId}/upload`, params, authHeader()),
    uploadProformaPaymentReciept: (tradeId, params) => axios.put(`/trade/${tradeId}/proformaInv/uploadDoc`, params, authHeader()),
    uploadInvoicePaymentProof: (tradeId, params) => axios.post(`/trade/${tradeId}/invPayment`, params, authHeader()),
    reuploadInvoicePaymentProof: (tradeId, params) => axios.put(`/trade/${tradeId}/invPayment/uploadDoc`, params, authHeader()),
    postDownPaymentEffected: (tradeId, proformaInvoiceId, status, params) => axios.put(`/trade/${tradeId}/proformaInvoice/${proformaInvoiceId}/status/${status}`, params, authHeader()),
    confirmInvoicePayment: (tradeId, invoicePaymentId, status, params) => axios.put(`/trade/${tradeId}/invPayment/${invoicePaymentId}/status/${status}`, params, authHeader()),
    getDocumentFile: (tradeId, shipmentId, fileId, responseType = 'arraybuffer') => axios.get(`/trade/${tradeId}/shipment/${shipmentId}/file/${fileId}`, {
        ...authHeader(),
        responseType
    }),
    getProformaInvoiceReciept: (tradeId, proformaInvoiceId, responseType = 'arraybuffer') => axios.get(`/trade/${tradeId}/proformaInv/${proformaInvoiceId}/downloadDoc`, authHeader()),
    getInvoicePaymentProofs: (tradeId, invoicePaymentId, responseType = 'arraybuffer') => axios.get(`/trade/${tradeId}/invPayment/${invoicePaymentId}/downloadDoc`, authHeader()),
    approveDocument: (tradeId, shipmentId, docId, params) => axios.put(`/trade/${tradeId}/shipment/${shipmentId}/document/${docId}/approve`, params, authHeader()),
    rejectDocument: (tradeId, shipmentId, docId, params) => axios.put(`/trade/${tradeId}/shipment/${shipmentId}/document/${docId}/reject`, params, authHeader()),
    releaseDocument: (tradeId, shipmentId, docId, params) => axios.put(`/trade/${tradeId}/shipment/${shipmentId}/document/${docId}/release`, params, authHeader()),
    getInspectionReports: id => axios.get(`/trade/${id}/report_inspection`, authHeader()),
    postInspectionReport: (id, params) => axios.post(`/trade/${id}/report_inspection`, params, authHeader()),
    getBagmarkings: id => axios.get(`/trade/${id}/bagmarkings`, authHeader()),
    postBagmarking: (id, params) => axios.post(`/trade/${id}/bagmarking`, params, authHeader()),
    approveBagmarking: (id, brandID, bagmarkingID) => axios.put(`/trade/${id}/brand/${brandID}/bagmarking/${bagmarkingID}/approve`, null, authHeader()),
    rejectBagmarking: (id, brandID, bagmarkingID) => axios.put(`/trade/${id}/brand/${brandID}/bagmarking/${bagmarkingID}/reject`, null, authHeader()),
    approveInspectionReport: (id, reportID) => axios.put(`/trade/${id}/report_inspection/${reportID}/approve`, null, authHeader()),
    rejectInspectionReport: (id, reportID) => axios.put(`/trade/${id}/report_inspection/${reportID}/reject`, null, authHeader()),
    create: params => axios.post('/trade/', params, authHeader()),
    update: (id, params) => axios.put('/trade/' + id, params, authHeader()),
    getCounterparties: search => axios.get('/company', authHeader()),
    getInspectionCompanies: () => axios.get('/company/inspections', authHeader()),
    getMessages: id => axios.get(`/trade/${id}/comments`, authHeader()),
    getTradeMessages: id => axios.get(`/trade/${id}/trade_comments`, authHeader()),
    postMessage: (id, params) => axios.post(`/trade/${id}/comments`, params, authHeader()),
    postDocumentComment: (tradeId, shipmentId, documentId, params) => axios.post(
        `/trade/${tradeId}/shipment/${shipmentId}/document/${documentId}/comment`, params, authHeader()
    ),
    getDocumentComments: (tradeId, shipmentId, documentId) => axios.get(
        `/trade/${tradeId}/shipment/${shipmentId}/document/${documentId}/comments`, authHeader()
    ),
    setRequestStatus: (id, params) => axios.post(`/trade/${id}/status`, params, authHeader()),
    getInspectionReportTemplate: () => axios.get('/templates/inspectionReport', {
        ...authHeader(),
        responseType: 'blob'
    }),
    postBid: (id, counterpartyId, params) => axios.post(`/trade/${id}/bid/counterparty/${counterpartyId}`, params, authHeader()),
    getBids: id => axios.get(`/trade/${id}/bid/`, authHeader()),
    acceptBid: (id, counterpartyId, data=null) => axios.put(`/trade/${id}/bid/accept/counterparty/${counterpartyId}`, data, authHeader()),
    declineBid: (id, counterpartyId, params) => axios.put(`/trade/${id}/bid/decline/counterparty/${counterpartyId}`, params, authHeader()),
    smart: id => smartApi(`/trade/${id}/smart`),
    verifyAuthorisationCode: (params) => axios.post(`/company/authCode/verify`, params, authHeader()),
    sendEmail: tradeId => axios.put(`/trade/${tradeId}/notify_inActive_inspection`, null, authHeader()),
    updateCommercialInvoiceType: (tradeId, params) => axios.put(`/trade/${tradeId}/smart/instructions/indicatorUpdate`, params, authHeader()),
    getBidInfo:bidId => axios.get(`/trade/bidInfo/${bidId}`, authHeader()),
    getTradeTemplates: () => axios.get(`/trade/template`, authHeader()),
    deleteTemplate: (templateId) => axios.delete(`/trade/template/${templateId}`, authHeader()),
    renameTemplate: (templateId, templateName) => axios.patch(`/trade/template/${templateId}`, {templateName}, authHeader()),
    createTemplate: (templateBody) => axios.post(`/trade/template`, templateBody, authHeader()),
    updateTemplate: (templateId, templateBody) => axios.put(`/trade/template/${templateId}`, templateBody, authHeader()),
    createPaymentTermText: (url, params) => axios.post(url, params, authHeader()),
    updatePaymentTermText: (url, params) => axios.put(url, params, authHeader()),
    uploadPaymentTermDoc: (url, params) => axios.post(url + '/uploadDoc', params, authHeader()),
    getPaymentTermDocument: (url) => axios.get(url, authHeader()),
    confirmPaymentTerm: (url, params) => axios.put(url, params, authHeader()),
    downloadPaymentTermDoc: (url, responseType = 'arraybuffer') => axios.get(url, authHeader())
};

export const smartApi = url => {
    return {
        action: (state, params) => axios.post(url + '/' + state, params, authHeader()),
        nominateVessel: params => axios.put(url + '/nominate_vessel', params, authHeader()),
        vesselAccept: params => axios.put(url + '/accept_vessel_nomination', params, authHeader()),
        vesselAcceptNewLaycanDates: params => axios.put(url + '/accept_newLaycanDate', params, authHeader()),
        vesselReject: (rejectMessage) => axios.put(url + '/reject_vessel_nomination', rejectMessage, authHeader()),
        getInstructions: () => axios.get(url + '/instructions', authHeader()),
        vesselMessage: () => axios.get(url + '/vessel_nomination', authHeader()),
        instructions: params => axios.post(url + '/instructions', params, authHeader()),
        advice: params => axios.post(url + '/advice', params, authHeader()),
        updateTradeDocument: params => axios.put(url + '/update', params, authHeader()),
        docConfirm: () => axios.post(url + '/confirm_documents', null, authHeader()),
        processPayment: text => axios.post(url + '/payment', { text }, authHeader()),
        confirmPayment: () => axios.post(url + '/confirm_payment', null, authHeader()),
        closeTrade: () => axios.post(url + '/close', null, authHeader()),
        log: () => axios.get(url + '/log', authHeader()),
    };
};

export const ExchangeApi = {
    get: (skip, take) => axios.get(`/exchange/?skip=${skip}&take=${5}`, authHeader())
};

export const KycApi = {
    get: () => axios.get('/company/kyc/', authHeader()),
    getById: (id) => axios.get(`/company/${id}/detail/`, authHeader()),
    getAll: params => axios.get('/kyc/getKYCs', { ...authHeader(), params }),
    save: params => axios.post('/kyc/editing/', params, authHeader()),
    submitForReview: params => axios.post('/kyc/save/', params, authHeader())
};

export const AccountApi = {
    token: () => sessionStorage.getItem('jwt'),
    updateJWTToken: () => axios.get('/auth/refresh-token', authHeader()),
    setToken: token => sessionStorage.setItem('jwt', token),
    login: params => axios.post('/auth/login/', params),
    register: params => axios.post('/auth/register/', params),
    createJoiningRequest: params => axios.post('/company/', params),
    logout: () => sessionStorage.removeItem('jwt'),
    forgot: params => axios.post('/auth/forgot/', params),
    profile: () => axios.get('/auth/', authHeader()),
    updatePassword: (code, params) => axios.post(`/auth/update/` + code, params),
    validateChangePasswordCode: (code) => axios.get(`/auth/update/` + code),
    getProfile: () => axios.get('/account/profile', authHeader()),
    getNotifications: params => axios.get('/notifications/', { ...authHeader(), params }),
    markNotificationAsRead: id => axios.put(`/notifications/${id}`, null, authHeader()),
    markAllNotificationsAsRead: id => axios.put(`/notifications/`, null, authHeader()),
    deleteNotification: id => axios.delete(`/notifications/${id}`, authHeader()),
    deleteAllNotifications: () => axios.delete(`/notifications/`, authHeader()),
    userProfile: () => axios.get('/userProfile/', authHeader()),
    userProfileUpdatePhone: params => axios.put('/userProfile/', params, authHeader()),
    userDashboard: () => axios.get(`/dashboard/`, authHeader()),
    updateComapanyLogo:(companyId,params)=>axios.patch(`/company/${companyId}`,params, authHeader()),
    updateComapanyCapabilities:(companyId,params)=>axios.post(`/company/${companyId}`,params, authHeader()),
    companyLogoUpload: (params) => axios.post(`/company_logo/upload`, params, authHeader()),
};

export const PlatformAdminApi = {
    getCompanyList: () => axios.get(`/company/list/`, authHeader()),
    getCompanyDetails: (id) => axios.get(`/company/${id}/detail/`, authHeader()),
    getKycJoiningRequestList: () => axios.get(`/company/requests/`, authHeader()),
    getKycPendingList: () => axios.get(`/company/kyc/list/`, authHeader()),
    approveRequest: (id) => axios.put(`/company/${id}/request/approve/`, null, authHeader()),
    reapproveRequest: (id) => axios.put(`/company/${id}/request/reapprove/`, null, authHeader()),
    rejectRequest: (id, params) => axios.put(`/company/${id}/request/reject/`, params, authHeader()),
    submitKYC: (id, params) => axios.post(`/company/${id}/kyc/`, params, authHeader()),
    saveKYCDraft: (id, params) => axios.put(`/company/${id}/kycDraft`, params, authHeader()),
    changeCompanyType: (id, params) => axios.put(`/company/${id}`, params, authHeader()),
    changeRegNumber: (id, params) => axios.put(`/company/${id}/regNumber`, params, authHeader()),
    approveKYC: (id) => axios.put(`/company/${id}/kyc/approve/`, null, authHeader()),
    holdKYC: (id) => axios.put(`/company/${id}/kyc/hold/`, null, authHeader()),
    unholdKYC: (id) => axios.put(`/company/${id}/kyc/unhold/`, null, authHeader()),
    sendKYCReminder: (id) => axios.put(`/company/${id}/kyc/reminder/`, null, authHeader()),
    rejectKYC: (id, params) => axios.put(`/company/${id}/kyc/reject/`, params, authHeader()),
    resubmitKYC: (id, params) => axios.put(`/company/${id}/kyc/resubmit/`, params, authHeader()),
    blockCompany: (id, params) => axios.post(`/company/${id}/block/`, params, authHeader()),
    unblockCompany: (id, params) => axios.post(`/company/${id}/unblock/`, params, authHeader()),
    getDocumentUrl: (params) => axios.put(`/kyc_document/url`, params, authHeader()),
    getKYCDetails: (id) => axios.get(`/company/${id}/kyc/`, authHeader()),
    getLogForAdmin: () => axios.get(`/logs/`, authHeader()),
    kycSave: (id, params) => axios.post(`/company/${id}/kyc/`, params, authHeader()),
    kycDocumentUpload: (params) => axios.post(`/kyc_document/upload`, params, authHeader()),
}

export const SystemApi = {
    getCities: country => axios.get(`/system/cities?country=${country}`, authHeader())
};

export const getFileUrl = (fileId) => API_URL + `/file/${fileId}`;

export const getDocumentFileUrl = (tradeId, shipmentId, fileId) => `${API_URL}/trade/${tradeId}/shipment/${shipmentId}/file/${fileId}`;

export const downloadInspectionReportTemplate = () => {
    TradeApi.getInspectionReportTemplate()
        .then(response => {
            FileSaver.saveAs(response.data, 'inspection-report');
        })
        .catch(error => console.error('something went wrong during file download', error))
};

export const downloadDocumentFile = (tradeId, shipmentId, fileId, docName) => {
    TradeApi.getDocumentFile(tradeId, shipmentId, fileId, 'blob')
        .then(response => {
            FileSaver.saveAs(response.data, docName + '.pdf');
        })
        .catch(error => console.error('something went wrong during file download', error))
};

export const tradeListParams = (data) => {
    return {
        skip: data.skip,
        take: data.take,
        type: data.type || '',
        page: data.page || '',
        status: data.status || '',
        sort: data.sort,
        order: data.order
    }
};

export const authHeader = (params, header) => {
    let result = {
        headers: header ? header : {},
        params: params
    };

    let token = sessionStorage.getItem('jwt');
    if (token !== undefined) {
        result.headers = { ...result.header, Authorization: 'Bearer '.concat(token) };
    }
    return result;
};

export const AdminManagementApi = {
    getGroupListByCompany: (companyid) => axios.get(`/groups/${companyid}`, authHeader()),
    adduserGroup: params => axios.post('/group', params, authHeader()),
    editgroup: params => axios.put('/group', params, authHeader()),
    getPermission: (id) => axios.get(`/group/${id}/permissions`, authHeader()),
    getGroupUserList: (id) => axios.get(`/group/${id}/users`, authHeader()),
    deleteGroup: (id) => axios.delete(`/group/${id}`, authHeader()),
    savePermissions: (id, params) => axios.post(`/group/${id}/permissions`, params, authHeader()),
    getUserList: () => axios.get(`/users`, authHeader()),
    inviteUser: params => axios.post(`/user/invite`, params, authHeader()),
    getUserPermission: (id) => axios.get(`/group/users/${id}/permissions`, authHeader()),
    cloneGroup: (id, params) => axios.post(`/group/${id}/clone`, params, authHeader()),
    deleteGroupUser: (id, params) => axios.delete(`/group/${id}/users/${params}`, authHeader()),
    getActiveUserList: () => axios.get(`/users/active`, authHeader()),
    activeUser: (id) => axios.get(`/user/${id}/activate`, authHeader()),
    inactiveUser: (id) => axios.get(`/user/${id}/inactivate`, authHeader()),
    insertUserToGroup: (id, params) => axios.post(`/group/${id}/users`, params, authHeader()),
    getProvidersByCompany: (id) => axios.get(`/company/${id}/providers/`, authHeader()),
    getProviderList: () => axios.get(`/providers`, authHeader()),
    addNewProvider: (id, params) => axios.post(`/company/${id}/providerKyc`, params, authHeader()),
    getProviderKycList: (id) => axios.get(`/company/${id}/providerKycs`, authHeader()),
    rejectProviderKyc: (id, params) => axios.put(`/company/${id}/providerKyc/reject`, params, authHeader()),
    approveProviderKyc: (id, params) => axios.put(`/company/${id}/providerKyc/approve`, params, authHeader()),
    deactivateProvider: (id, params) => axios.put(`/company/${id}/provider/deactivate`, params, authHeader()),
    activateProvider: (id, params) => axios.put(`/company/${id}/provider/activate`, params, authHeader()),
    changeAuthorisationCode: (params) => axios.post(`/company/authCode/save`, params, authHeader()),
    getAuthorizedUsers: (id) => axios.get(`/company/${id}/users`, authHeader()),
    changeAdditionalEmails: (params) => axios.post(`/company/additionalEmails`, params, authHeader()),
    getAdditionalEmails: () => axios.get(`/company/additionalEmails`, authHeader())
};

export const ShipmentQuoteApi = {
    getPriceQuoteContainer: (depatureDate, origin, destination, tradeId, jsonBody) => axios.post(`/price-quotes/offers/depaturedate/${depatureDate}/origin/${origin}/destination/${destination}/${tradeId}`, jsonBody, authHeader()),
    getMaerskBookingReq: (priceQuoteId) => axios.get(`/trade/shippingQuote/priceQuoteId/${priceQuoteId}`, authHeader()),
    getMaerskBookingReqFinal: (priceQuoteId, priceId) => axios.get(`/trade/shippingQuote/priceQuoteId/${priceQuoteId}/${priceId}`, authHeader()),
    postMaerskBookingReq: (tradeId, params) => axios.post(`/trade/${tradeId}/shippingQuote/maerskBookingReq`, params, authHeader()),
    ProvisionalQuoteVessel: (tradeId, quoteType, comapnyId, params) => axios.post(`/trade/${tradeId}/shippingQuote/${quoteType}/tocompany/${comapnyId}`, params, authHeader()),
    getQuoteStatus: (Id) => axios.get(`/quote/status/${Id}`, authHeader()),
    getQuoteOverviewList: (quoteType) => axios.get(`/shippingQuotes/trader/${quoteType}`, authHeader()),
    getQuoteDetails: (Id, shipmentQuoteId) => axios.get(`/trade/${Id}/getQuoteDetails/${shipmentQuoteId}`, authHeader()),
    getShippingCompanyList: () => axios.get(`/shippingQuote/shipping`, authHeader()),
    getServiceProviderQuoteList: (quoteType) => axios.get(`/shippingQuotes/serviceProvider/${quoteType}`, authHeader()),
    getShippingMessages: (shipmentQuoteId) => axios.get(`/trade/shippingQuote/comment/${shipmentQuoteId}/comments`, authHeader()),
    postShippingMessage: (shipmentQuoteId, counterparty, params) => axios.post(`/trade/shippingQuote/comment/${shipmentQuoteId}/comments/counterparty/${counterparty}`, params, authHeader()),
    shippingDocumentUpload: (tradeid, quoteType, companyid, params) => axios.put(`/trade/${tradeid}/shippingQuote/uploadDoc/${quoteType}/${companyid}`, params, authHeader()),
    updateQuoteStatus: (shipmentQuoteId, status, params) => axios.put(`/trade/shippingQuote/updateStatus/${shipmentQuoteId}/status/${status}`, params, authHeader()),
    getshippingStatus: (tradeId, quoteType, companyId) => axios.get(`/trade/${tradeId}/shippingQuote/quoteType/${quoteType}`, authHeader()),
    downloadShippingDoc: (tradeId, shipmentQuoteId) => axios.get(`/trade/${tradeId}/shippingQuote/downloadDoc/${shipmentQuoteId}`, authHeader()),
    downlaodMaerskDocument: (tradeId, shipmentQuoteId) => axios.get(`/trade/${tradeId}/shippingQuote/downlaodMaerskDocument/${shipmentQuoteId}`, authHeader()),

};

export const InspectionQuoteApi = {
    PostInspectionQuote: (tradeId, params) => axios.post(`/trade/${tradeId}/inspectionQuote/create`, params, authHeader()),
    inspectionList: (quoteType) => axios.get(`/trade/2/inspectionQuotes/trader/${quoteType}`, authHeader()),
    getServiceProviderQuoteList: (quoteType) => axios.get(`/trade/2/inspectionQuotes/serviceProvider/${quoteType}`, authHeader()),
    getInspectionDetails: (Id, InspectionQuoteId) => axios.get(`/trade/${Id}/inspectionQuote/quoteDetailsById/${InspectionQuoteId}`, authHeader()),
    updateQuoteStatus: (InspectionQuoteId, status, params) => axios.put(`/trade/inspectionQuote/${InspectionQuoteId}/updateStatus/${status}`, params, authHeader()),
    inspectionDocumentUpload: (tradeid, quoteType, companyid, params) => axios.put(`/trade/${tradeid}/inspectionQuote/uploadDoc/${quoteType}/${companyid}`, params, authHeader()),
    getInspectionMessages: (inspectionQuoteId) => axios.get(`/trade/inspectionQuote/comment/${inspectionQuoteId}/comments`, authHeader()),
    postInspectionMessage: (inspectionQuoteId, counterparty, params) => axios.post(`/trade/inspectionQuote/comment/${inspectionQuoteId}/comments/counterparty/${counterparty}`, params, authHeader()),
    getInpectionStatus: (tradeId, quoteType, companyId) => axios.get(`/trade/${tradeId}/inspectionQuote/quoteType/${quoteType}/${companyId}`, authHeader()),
    downloadInspectionDoc: (tradeId, inspectionQuoteId) => axios.get(`/trade/${tradeId}/inspectionQuote/downloadDoc/${inspectionQuoteId}`, authHeader()),
};

export const ContractApi={
    postContractRequest: (tradeId, counterparty,params) => axios.post(`/trade/${tradeId}/contractAmendmentRequest/counterparty/${counterparty}`, params, authHeader()),
    getContractTerms: (tradeId) => axios.get(`/trade/${tradeId}/contractAmendmentRequest`, authHeader()),
    acceptContract: (tradeId, counterpartyId) => axios.put(`/trade/${tradeId}/contractAmendmentRequest/accept/counterparty/${counterpartyId}`, null, authHeader()),
    declineContract: (tradeId, counterpartyId, params) => axios.put(`/trade/${tradeId}/contractAmendmentRequest/decline/counterparty/${counterpartyId}`, params, authHeader()),
};

export const tenderApi = {
    subscribe: (tradeId) => axios.post(`/tender/${tradeId}/subscribe`, null, authHeader()),
    approve: (tradeId, subscriptionId) => axios.put(`/tender/${tradeId}/subscription/${subscriptionId}/approve`, null, authHeader()),
    reject: (tradeId, subscriptionId) => axios.put(`/tender/${tradeId}/subscription/${subscriptionId}/reject`, null, authHeader()),
    getCompanySubscriptions: () => axios.get(`/tender/subscriptions`, authHeader()),
    getTenderSubscriptions: (tradeId) => axios.get(`/tender/${tradeId}/subscriptions`, authHeader()),
    getPrices: (tradeId) => axios.get(`/tender/${tradeId}/bid/prices`, authHeader()),
    getWhitelistedCompanies: () => axios.get(`/whitelistGroup`, authHeader()),
    removeWhitelistedCompany: (groupId, companyId) => axios.put(`/whitelistGroup/${groupId}/company/${companyId}/remove`, null, authHeader()),
    addNewWhitelistedCompany: (company) => axios.post(`/whitelistGroup/company/add`, company, authHeader())
}
export const SubstituteNominationApi = {
    substituteNominateVessel: (tradeId,params) => axios.put(`/trade/${tradeId}/substitute/nominate_vessel`, params, authHeader()),
    getSubstituteNominateVessel:(tradeId) => axios.get(`/trade/${tradeId}/substitute/nominate_vessel`, authHeader()),
    substituteAcceptNewLaycanDates: (tradeId,params) => axios.put(`/trade/${tradeId}/substitute/accept_newLaycanDate`, params, authHeader()),
    substituteVesselReject: (tradeId,params) => axios.put(`/trade/${tradeId}/substitute/reject_vessel_nomination`, params, authHeader()),
    substituteVesselAccept: (tradeId,params) => axios.put(`/trade/${tradeId}/substitute/accept_vessel`, params, authHeader()),
    substitueAuditLogs:(vesselId) => axios.get(`/trade/substitute/last_two_vessel/${vesselId}`, authHeader()),
}

export const useApi = (url) =>
  useQuery(
    url,
    async () => {
      const response = await fetch(API_URL + '/' + url, authHeader())
      return response.json()
    },
    {
      placeholderData: [],
      staleTime: 60 * 1000, // 1 minute
    }
  )