import React from 'react';
import { handleError, showErrorModal } from './module.app';
import { TradeApi, KycApi } from '../services/service.api';
import {
    LoadingRequestData,
    LoadingBidData,
    LoadingVesselDetails,
    LoadingCreateRequest,
    LoadingDocuments,
    LoadingDocumentComments,
    LoadingAllDocuments,
    LoadingTradeMessages,
    LoadingProformaInvoiceOperation,
    LoadingCommercialInvoiceProofs,
    LoadingCommercialInvoiceIndicator,
    LoadingTemplates,
    LoadingInspectionCompanies,
    LoadingCounterParties,
    LoadingBankDetails,
    LoadingBidInfo,
    LoadingPTProcess,
    LoadingTradeUpdate
} from './module.loading';
import { TRADE_STATUS, NON_INTEGRATED_INSPECTORS } from '../services/service.values';
import moment from 'moment';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { DOCUMENT_TYPES, LOADING_PT_PROCESS } from '../app/admin/trades/services/documents.service';
import { NOTIFICATION_NEW } from './module.notifications';
import { TradeAPIErrors } from "../services";

export const SET_LAST_UPDATE = 'trade/LAST_UPDATE';
export const FETCH_TRADES_PENDING = 'trade/FETCH_PENDING';
export const FETCH_TRADES_SUCCESS = 'trade/FETCH_SUCCESS';
export const FETCH_TRADES_ERROR = 'trade/FETCH_ERROR';

export const FETCH_NEW_TRADES_PENDING = 'trade/FETCH_NEW_PENDING';
export const FETCH_NEW_TRADES_SUCCESS = 'trade/FETCH_NEW_SUCCESS';
export const FETCH_NEW_TRADES_ERROR = 'trade/FETCH_NEW_ERROR';
export const ACTION_ERROR = 'trade/ACTION_ERROR';
export const DISMISS_ACTION_ERROR = 'trade/DISMISS_ACTION_ERROR';
export const CREATE_REQUEST = 'trade/CREATE_REQUEST';
export const LOAD_REQUESTS = 'trade/LOAD_REQUESTS';
export const UPDATE_TRADES = 'trade/UPDATE_TRADES';
export const BOOKMARK = 'trade/BOOKMARK';
export const LOAD_COMPANIES = 'trade/LOAD_COMPANIES';
export const LOADED_INSPECTION_COMPANIES = 'trade/LOAD_INSPECTION_COMPANIES';
export const UPDATE_BILL = 'trade/UPDATE_BILL';
export const UPDATE_DOCUMENT = 'trade/UPDATE_DOCUMENT';
export const UPDATE_INVOICE = 'trade/UPDATE_INVOICE';
export const UPDATE_REQUEST = 'trade/UPDATE_REQUEST';
export const GET_MESSAGES = 'trade/GET_MESSAGES';
export const POST_MESSAGE = 'trade/POST_MESSAGE';
export const REPLY_MESSAGE = 'trade/REPLY_MESSAGE';
export const LOAD_REQUEST_DETAILS = 'trade/LOAD_REQUEST_DETAILS';
export const SET_STATUS = 'trade/SET_STATUS';
export const LOAD_REQUEST_INFO = 'trade/LOAD_REQUEST_INFO';
export const GET_TRADE_DOCUMENTS = 'trade/GET_TRADE_DOCUMENTS';
export const GET_TRADE_BILL = 'trade/GET_TRADE_BILL';
export const POST_TRADE_DOCUMENT = 'trade/POST_TRADE_DOCUMENT';
export const GET_TRADE_INVOICE = 'trade/GET_TRADE_INVOICE';
export const GET_TRADE_PERFORMA_INVOICE = 'trade/GET_TRADE_PERFORMA_INVOICE';
export const POST_DOCUMENT_FILE = 'trade/POST_DOCUMENT_FILE';
export const OPEN_DOCUMENT_FILE = 'trade/OPEN_DOCUMENT_FILE';
export const UPDATE_SIGNED = 'trade/UPDATE_SIGNED';
export const PAY_TRADE = 'trade/PAY_TRADE';
export const UPDATE_FLOW_DOC = 'tradeUPDATE_FLOW_DOC';
export const UPDATE_PAYED = 'trade/UPDATE_PAYED';
export const UPDATE_TRADE_DOCUMENT = 'trade/UPDATE_TRADE_DOCUMENT';
export const UPDATE_CLOSE = 'trade/UPDATE_CLOSE';
export const SEND_SHIPPING_ADVICE = 'trade/SEND_SHIPPING_ADVICE';
export const SEND_INSTRUCTIONS = 'trade/SEND_INSTRUCTIONS';
export const GET_INSTRUCTIONS = 'trade/GET_INSTRUCTIONS';
export const UPDATE_NOMINATED = 'trade/UPDATE_NOMINATED';
export const POST_INSPECTION_REPORT = 'trade/POST_INSPECTION_REPORT';
export const GET_INSTRUCTIONS_ERROR = 'trade/GET_INSTRUCTIONS_ERROR';
export const GET_INSPECTION_REPORTS = 'trade/GET_INSPECTION_REPORTS';
export const GET_BAGMARKINGS = 'trade/GET_BAGMARKINGS';
export const LOAD_INSPECTION_TRADES = 'trade/LOAD_INSPECTION_TRADES';
export const GET_SHIPMENTS = 'trade/GET_SHIPMENTS';
export const GET_DOCUMENT_COMMENTS = 'trade/GET_DOCUMENT_COMMENTS';
export const POST_DOCUMENT_COMMENTS = 'trade/POST_DOCUMENT_COMMENTS';
export const UPDATE_DOCUMENT_STATUS = 'trade/UPDATE_DOCUMENT_STATUS';
export const CLEAR_TRADE_STATE = 'trade/CLEAR_STATE';
export const CLEAR_SINGLE = 'trade/CLEAR_SINGLE';
export const GET_VESSEL_NOMINATION = 'trade/GET_VESSEL_NOMINATION';
export const GET_BIDS = 'trade/GET_BIDS';
export const AUTOUPDATE_TRIGGERED = 'trade/AUTOUPDATE_TRIGGERED';
export const PROFORMA_INVOICE_STATUS = 'trade/PROFORMA_INVOICE_STATUS';
export const GET_PROFORM_PAYMENT_RECIEPTS = 'trade/GET_PROFORM_PAYMENT_RECIEPTS';
export const UPDATE_REQUEST_INFO = 'trade/UPDATE_REQUEST_INFO';
export const UPDATE_PAYED_FALSE = 'trade/UPDATE_PAYED_FALSE';
export const GET_INVOICE_PAYMENT_PROOFS = 'trade/GET_INVOICE_PAYMENT_PROOFS';
export const UPDATE_COMMERCIAL_INVOICE_TYPE = 'trade/UPDATE_COMMERCIAL_INVOICE_TYPE';
export const GET_BID_INFO = 'trade/GET_BID_INFO';
export const GET_CONTRACTUSER_INFO = 'trade/GET_CONTRACTUSER_INFO';
export const LOAD_TRADE_TEMPLATES = 'trade/LOAD_TRADE_TEMPLATES';
export const DELETE_TEMPLATE = 'trade/DELETE_TEMPLATE';
export const RENAME_TEMPLATE = 'trade/RENAME_TEMPLATE';
export const CREATE_TEMPLATE = 'trade/CREATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'trade/UPDATE_TEMPLATE';
export const AUTOUPDATE_CONTRACT='trade/AUTOUPDATE_CONTRACT';
export const AUTOUPDATE_NOMINATION='trade/AUTOUPDATE_NOMINATION';

export const CREATE_PT = 'trade/CREATE_PT';
export const UPDATE_PT = 'trade/UPDATE_PT';
export const GET_PT = 'trade/GET_PT';
export const DOWNLOAD_PT_DOC = 'trade/DOWNLOAD_PT_DOC';
export const CONFIRM_PT = 'trade/CONFIRM_PT';
export const UPDATE_REQUEST_SUCCESS = 'trade/UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_ERROR = 'trade/UPDATE_REQUEST_ERROR';
export const UPDATE_REQUEST_RESET = 'trade/UPDATE_REQUEST_RESET';

const arrayToTree = require('array-to-tree');
const actionsToAutoUpdate = [
    'TRADE_REQUEST_APPROVED_NOTIF',
    'TRADE_BID_ACCEPTED',
    'COMMENT_ADDED_NOTIF',
    'TRADE_REQUEST_REJECTED_NOTIF',
    'TRADE_COUNTERED_NOTIF',
    'TRADE_BID_SUBJECT_CONFIRM',
    'TRADE_BID_DECLINED_NOTIF',
    'TENDER_SUBSCRIPTION_REQUEST_NOTIF',
    'TENDER_SUBSCRIPTION_APPROVE_NOTIF',
    'TENDER_SUBSCRIPTION_REJECT_NOTIF',
    'TRADE_BID_BANK_DETAILS_SENT',
    'TRADE_BID_BANK_DETAILS_REJECTED',
    'CONTRACT_AMENDMENTS_REQUEST_ACCEPT_NOTIFY',
    'TRADE_BID_RECOUNTER_PRICE_IMPROVED'
];
const contractActionsToAutoupdate = [
    'CONTRACT_AMENDMENTS_REQUEST_NOTIFY',
    'CONTRACT_AMENDMENTS_REQUEST_ACCEPT_NOTIFY',
    'CONTRACT_AMENDMENTS_REQUEST_DECLINE_NOTIFY',
];
const nominationActionsToAutoupdate = [
    'SUBSTITUTE_VESSEL_NOMINATED_NOTIF',
    'SUBSTITUTE_VESSEL_NOMINATION_APPROVED_NOTIF',
    'SUBSTITUTE_VESSEL_NOMINATION_LAYCANDATECHANGE_NOTIF',
    'SUBSTITUTE_VESSEL_NOMINATION_REJECTED_NOTIF'
];

function getInitialState() {
    return {
        items: {
            all: [],
            sell: [],
            buy: [],
            single: null
        },
        paginatedItems: {
            LIVE: {
                pending: false
            },
            OPEN: {
                pending: false
            },
            PUBLIC_RTS: {
                pending: false
            },
            PUBLIC_RTB: {
                pending: false
            },
            EXPIRED: {
                pending: false
            },
            DECLINED: {
                pending: false
            },
            CANCELED: {
                pending: false
            },
            CLOSED: {
                pending: false
            },
            OTHER: {
                pending: false
            }
        },
        pageSize: 20,
        pageSizeNew: 10,
        currentPage: 0,
        pending: false,
        pendingNew: false,
        companies: [],
        inspections: { companies: [] },
        inspectionCompanies: [],
        messages: [],
        requestInfo: '',
        documents: [],
        reports: [],
        shipments: [],
        documentComments: [],
        bills: {},
        shipmentDocuments: {},
        invoice: null,
        vesselNomination: {},
        bids: [],
        shouldTriggerTradeUpdate: false,
        invoicePaymentProofs: null,
        bagmarkings: {},
        ContractCompanyinfo: {},
        bidInfo: {},
        tradeTemplates: {
            templateOptions: {},
            templates: [],
            count: 0
        },
        triggerContractUpdate:false,

        triggerNominationUpdate:false,
        ptDocument: null

    };
}


export default (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case FETCH_TRADES_PENDING:
            state.paginatedItems[action.payload.request].pending = true;
            return {
                ...state
            }
        case FETCH_TRADES_SUCCESS:
            state.paginatedItems[action.payload.request].pending = false;
            state.paginatedItems[action.payload.request].bookMark = action.payload.bookMark;
            if (action.payload.request === "DECLINED") {
                return {
                    ...state,
                    declinedTradeMap: { ...state.declinedTradeMap, ...action.payload.tradeMap }
                }
            } else {
                return {
                    ...state,
                    tradeMap: { ...state.tradeMap, ...action.payload.tradeMap }
                }
            }

        case FETCH_TRADES_ERROR:
            state.paginatedItems[action.payload.request].pending = false;
            state.paginatedItems[action.payload.request].error = action.error;
            return {
                ...state
            }
        case FETCH_NEW_TRADES_PENDING:
            return {
                ...state,
                pendingNew: true
            }
        case FETCH_NEW_TRADES_SUCCESS:
            if (action.payload.request === "DECLINED") {
                return {
                    ...state,
                    pendingNew: false,
                    declinedTradeMap: { ...state.declinedTradeMap, ...action.payload.tradeMap }
                }
            } else {
                return {
                    ...state,
                    pendingNew: false,
                    tradeMap: { ...state.tradeMap, ...action.payload.tradeMap },
                }
            }

        case FETCH_NEW_TRADES_ERROR:
            return {
                ...state,
                pendingNew: false,
                error: action.error
            }
        case ACTION_ERROR:
            return {
                ...state,
                actionError: action.error
            }
        case DISMISS_ACTION_ERROR:
            return {
                ...state,
                actionError: null
            }

        case SET_LAST_UPDATE:
            return {
                ...state,
                lastUpdate: action.payload.updatedAt,
            }

        case BOOKMARK:
            return {
                ...state,
                ...action.payload
            };

        case LOAD_INSPECTION_TRADES:
        case LOAD_REQUESTS:
            switch (action.payload.type) {
                case 'sell':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            sell: action.payload.items
                        },
                        countsSell: action.payload.counts
                    };
                case 'buy':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            buy: action.payload.items
                        },
                        countsBuy: action.payload.counts
                    };
                case 'inbound':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            inbound: action.payload.items
                        },
                        countsInbound: action.payload.counts
                    };
                case 'outbound':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            outbound: action.payload.items
                        },
                        countsOutbound: action.payload.counts
                    };
                case 'expired':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            expired: action.payload.items
                        },
                        countsExpired: action.payload.counts
                    };
                case 'canceled':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            canceled: action.payload.items
                        },
                        countsCanceled: action.payload.counts
                    };
                case 'declined':
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            declined: action.payload.items
                        },
                        countsDeclined: action.payload.counts
                    };
                default:
                    return {
                        ...state,
                        items: {
                            ...state.items,
                            all: action.payload.items
                        },
                        counts: action.payload.counts
                    };
            }
        // case UPDATE_REQUEST:
        //     return {
        //         ...state,
        //         items: {
        //             ...state.items,
        //             single: {
        //                 ...state.items.single,
        //                 ...action.payload
        //             }
        //         }
        //     };
        case UPDATE_REQUEST_ERROR: 
        return {
            ...state,
            items: {
                ...state.items,
                single: {
                    ...state.items.single,
                    updateError: action.payload,
                    updateSuccess: false,
                }
            }
        }

        case UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    single: {
                        ...state.items.single,
                        ...action.payload.tradeUpdate,
                        updateError: undefined,
                        updateSuccess: true,
                    }
                }
            }
        case UPDATE_REQUEST_RESET:
            return {
                ...state,
                items: {
                    ...state.items,
                    single: {
                        ...state.items.single,
                        updateError: undefined,
                        updateSuccess: false,
                    }
                }
            }

        case CREATE_REQUEST:
            return {
                ...state
            };
        case LOAD_COMPANIES:

            return {
                ...state,
                companies: action.payload
            };
        case LOADED_INSPECTION_COMPANIES:
            let companies = {};
            if (action.payload) {
                action.payload.companies.reduce((accumulator, value) => {
                    accumulator[String(value.ID)] = value.name;
                    return accumulator;
                }, companies)
            }

            // action.payload.companies
            //
            return {
                ...state,
                inspections: companies,
                inspectionCompanies: action.payload.companies
            };
        case LOAD_REQUEST_DETAILS:
            return {
                ...state,
                items: {
                    ...state.items,
                    single: action.payload.items
                }
            };
        case GET_MESSAGES:
            action.payload.forEach(val => {
                val.isReplying = false;
            });
            return {
                ...state,
                messages: action.payload
            };
        case POST_MESSAGE:
            return { ...state, messages: [...state.messages, action.payload] };
        case REPLY_MESSAGE:
            const onReplyMessage = mes => {
                if (mes.children) {
                    mes.children.forEach(value => {
                        if (value.ID === action.payload.id) {
                            value.isReplying = !value.isReplying;
                        }
                        onReplyMessage(value);
                    })
                }
            };
            let repliedMessages = state.messages;
            repliedMessages.forEach(value => {
                if (value.ID === action.payload.id) {
                    value.isReplying = !value.isReplying;
                }
                onReplyMessage(value);
            });
            return {
                ...state,
                messages: repliedMessages
            };
        case LOAD_REQUEST_INFO:
            return {
                ...state,
                requestInfo: action.payload.info
            };
        case GET_TRADE_DOCUMENTS: {
            const { shipmentId, ...docs } = action.payload;
            return {
                ...state,
                shipmentDocuments: {
                    ...state.shipmentDocuments,
                    [shipmentId]: docs
                }
            };
        }
        case POST_TRADE_DOCUMENT: {
            // put in block, so var declaration 'const {data}' not conflict with same var declaration in another case
            const { data, response, shipmentId } = action.payload;
            const docName = Object.keys(response)[0];
            const docValue = response[Object.keys(response)[0]];

            if (docName === 'BillID') {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        [docName]: docValue
                    },
                    bill: {
                        quantCleanOnBoard: data.quantCleanOnBoard,
                        packGoodsDescript: data.packGoodsDescript,
                        vessVoyage: data.vessVoyage,
                        createdAt: moment()._d
                    }
                };
            } else {
                return {
                    ...state,
                    shipmentDocuments: {
                        ...state.shipmentDocuments,
                        [shipmentId]: {
                            ...state.shipmentDocuments[shipmentId],
                            [docName]: docValue
                        }
                    }
                };
            }
        }
        case GET_TRADE_BILL:
            return {
                ...state,
                bills: {
                    ...state.bills,
                    [action.payload.shipmentId]: action.payload.data
                }
            };
        case GET_TRADE_INVOICE:
            return {
                ...state,
                invoice: action.payload
            };
        case GET_TRADE_PERFORMA_INVOICE:
            return {
                ...state,
                proformaInvoice: action.payload
            }
        case POST_DOCUMENT_FILE:
        case UPDATE_DOCUMENT: {
            const { shipmentId, data } = action.payload;
            return {
                ...state,
                shipmentDocuments: {
                    ...state.shipmentDocuments,
                    [shipmentId]: {
                        ...state.shipmentDocuments[shipmentId],
                        [data.type]: {
                            ...(state.shipmentDocuments[shipmentId][data.type] ? state.shipmentDocuments[shipmentId][data.type] : {}),
                            ID: data.documentID,
                            status: data.status,
                            approvedByBuyer: false,
                            rejectedByBuyer: false,
                            Files: [
                                ...(state.shipmentDocuments[shipmentId][data.type] ? state.shipmentDocuments[shipmentId][data.type].Files : []),
                                {
                                    name: data.file,
                                    type: data.type,
                                    ID: data.fileID,
                                    DocumentID: data.documentID,
                                    docUrl: data.docUrl
                                }
                            ]
                        }
                    }
                }
            };
        }
        case UPDATE_FLOW_DOC:
            return {
                ...state,
                trade: {
                    ...state.trade,
                    items: {
                        ...state.trade.items,
                        single: [...state.trade.items.single]
                    }
                }
            };
        case UPDATE_SIGNED:
            if (action.payload === 'seller') {
                return {
                    ...state,
                    ...state.trade,
                    requestInfo: {
                        ...state.requestInfo,
                        signSeller: true
                    }
                };
            } else {
                return {
                    ...state,
                    ...state.trade,
                    requestInfo: {
                        ...state.requestInfo,
                        signBuyer: true
                    }
                };
            }
        case UPDATE_NOMINATED:
            return {
                ...state,
                ...state.trade,
                requestInfo: {
                    ...state.requestInfo,
                    vesselNominated: action.payload
                }
            };
        case UPDATE_PAYED:
        case PAY_TRADE:
            return {
                ...state,
                requestInfo: {
                    ...state.requestInfo,
                    payed: true
                }
            };
        case UPDATE_PAYED_FALSE:
            return {
                ...state,
                requestInfo: {
                    ...state.requestInfo,
                    payed: action.payload
                }
            };
        case UPDATE_TRADE_DOCUMENT:
            if (action.payload.docName === TRADE_STATUS.ADVICE) {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        shippingAdviceID: action.payload.text
                    }
                };
            } else {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        docInstructionsID: action.payload.text
                    }
                };
            }
        case UPDATE_CLOSE:
            if (action.payload === 'seller') {
                return {
                    ...state,
                    requestInfo: {
                        ...state.requestInfo,
                        sellerClose: true
                    }
                };
            } else {
                return {
                    ...state,
                    requestInfo: {
                        ...state.requestInfo,
                        buyerClose: true
                    }
                };
            }
        case UPDATE_BILL: {
            return state;
        }
        case SEND_SHIPPING_ADVICE:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    shippingAdviceID: action.payload
                }
            };
        case SEND_INSTRUCTIONS:
            return state;
        case GET_INSTRUCTIONS:
            return {
                ...state,
                instructions: action.payload,
                shipments: action.payload.shipments.map(shipment => {
                    return { amount: shipment.amount, id: shipment.ID };
                })
            };
        case GET_INSTRUCTIONS_ERROR:
            return {
                ...state,
                instructions: false,
                shipments: false
            }

        case GET_INSPECTION_REPORTS:
            return {
                ...state,
                reports: action.payload
            };
        case GET_BAGMARKINGS:
            return {
                ...state,
                bagmarkings: action.payload
            };
        case GET_SHIPMENTS:
            return {
                ...state,
                shipments: action.payload
            };
        case GET_DOCUMENT_COMMENTS:
            action.payload.data.forEach(val => {
                val.isReplying = false;
            });
            const comments = action.payload.data.slice().sort((a, b) => {
                return moment.utc(a.CreatedAt).diff(moment.utc(b.CreatedAt))
            });
            let commentsTree = arrayToTree(comments, {
                parentProperty: 'ParentID',
                customID: 'ID'
            });
            return {
                ...state,
                documentComments: {
                    ...state.documentComments,
                    [action.payload.documentId]: {
                        data: commentsTree,
                        count: action.payload.data.length
                    }
                }
            };
        case UPDATE_DOCUMENT_STATUS: {
            if (action.payload.type === DOCUMENT_TYPES.INVOICE) {
                return {
                    ...state,
                    invoice: {
                        ...state.invoice,
                        document: {
                            ...state.invoice.document,
                            status: action.payload.status
                        }
                    }
                }
            }
            return {
                ...state,
                shipmentDocuments: {
                    ...state.shipmentDocuments,
                    [action.payload.shipmentId]: {
                        ...state.shipmentDocuments[action.payload.shipmentId],
                        [action.payload.type]: {
                            ...state.shipmentDocuments[action.payload.shipmentId][action.payload.type],
                            status: action.payload.status
                        }
                    }
                },
                bills: action.payload.type !== DOCUMENT_TYPES.BILL ? state.bills : {
                    ...state.bills,
                    [action.payload.shipmentId]: {
                        ...state.bills[action.payload.shipmentId],
                        document: {
                            ...state.bills[action.payload.shipmentId].document,
                            status: action.payload.status
                        }
                    }
                }
            };
        }
        case GET_VESSEL_NOMINATION: {
            return {
                ...state,
                vesselNomination: action.payload
            }
        }
        case SET_STATUS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    single: {
                        ...state.items.single,
                        ...(action.updatedFields || {}),
                        status: action.payload,
                        completionAt: action.payload !== TRADE_STATUS.PAYED ? state.items.single.completionAt : moment().add(90, 'd').format()
                    }
                }
            }
        }
        case GET_BIDS:
            return {
                ...state,
                bids: action.payload
            };
        case AUTOUPDATE_TRIGGERED:
            return {
                ...state,
                shouldTriggerTradeUpdate: action.payload
            };
        case NOTIFICATION_NEW:
            const jData = action.payload.data && JSON.parse(action.payload.data);
            const tradeId = jData && jData.tradeID;
            let newState = {...state};
            if (state.items.single && state.items.single.id === tradeId && actionsToAutoUpdate.includes(action.payload.type)) {
                newState = {
                    ...newState,
                    shouldTriggerTradeUpdate: action.payload.type
                };
            }
            if (state.items.single && state.items.single.id === tradeId && contractActionsToAutoupdate.includes(action.payload.type)) {
                newState =  {
                    ...newState,
                    triggerContractUpdate: true
                };
            }
            if (state.items.single && state.items.single.id === tradeId && nominationActionsToAutoupdate.includes(action.payload.type)) {
                newState = {
                    ...newState,
                    triggerNominationUpdate: true
                };
            }
            return newState;
        case CLEAR_SINGLE:
            return {
                ...getInitialState(),
                paginatedItems: state.paginatedItems,
                declinedTradeMap: state.declinedTradeMap,
                tradeMap: state.tradeMap,
                lastUpdate: state.lastUpdate
            }
        case CLEAR_TRADE_STATE:
            return getInitialState()
        case UPDATE_REQUEST_INFO:
            return {
                ...state,
                requestInfo: {
                    ...state.requestInfo,
                    isLaycanDateChanged: action.payload
                }
            }
        case PROFORMA_INVOICE_STATUS:
            return {
                ...state,
                proformaInvoice: {
                    ...state.proformaInvoice,
                    status: action.payload
                }
            }
        case GET_PROFORM_PAYMENT_RECIEPTS:
            return {
                ...state,
                proformaInvoice: {
                    ...state.proformaInvoice,
                    files: action.payload
                }
            }
        case GET_INVOICE_PAYMENT_PROOFS:
            return {
                ...state,
                invoicePaymentProofs: action.payload
            }
        case UPDATE_COMMERCIAL_INVOICE_TYPE:
            return {
                ...state,
                instructions: {
                    ...state.instructions,
                    documentaryInstructions: {
                        ...state.instructions.documentaryInstructions,
                        commercialInvInd: action.payload
                    }
                }
            }

        case GET_BID_INFO:
            return {
                ...state,
                bidInfo: action.payload
            }
        case GET_CONTRACTUSER_INFO:
            return {
                ...state,
                ContractCompanyinfo: {
                    companyAddress: action.payload.companyKYC.registeredAddress,
                    companyName: action.payload.name
                }
            }

        case LOAD_TRADE_TEMPLATES:
            return {
                ...state,
                tradeTemplates: {
                    templateOptions: action.payload.templateOptions,
                    templates: action.payload.templates,
                    count: action.payload.count
                }
            }
        case DELETE_TEMPLATE:
            let updatedTemplates = [...state.tradeTemplates.templates];
            let templateIndex = updatedTemplates.findIndex(template => template.templateId === action.payload);
            updatedTemplates.splice(templateIndex, 1);
            let updatedTemplateOptions = {};
            updatedTemplates.forEach(template => {
                updatedTemplateOptions[template.templateId] = template.templateName
            })
            return {
                ...state,
                tradeTemplates: {
                    templateOptions: updatedTemplateOptions,
                    templates: updatedTemplates,
                    count: state.tradeTemplates.count - 1
                }
            }
        case RENAME_TEMPLATE:
            let updatedTemplates1 = [...state.tradeTemplates.templates];
            let templateIndex1 = updatedTemplates1.findIndex(template => template.templateId === action.payload.templateId);
            updatedTemplates1[templateIndex1].templateName = action.payload.templateName;
            let updatedTemplateOptions1 = {};
            updatedTemplates1.forEach(template => {
                updatedTemplateOptions1[template.templateId] = template.templateName
            })
            return {
                ...state,
                tradeTemplates: {
                    ...state.tradeTemplates,
                    templateOptions: updatedTemplateOptions1,
                    templates: updatedTemplates1
                }
            }
        case CREATE_TEMPLATE:
            let updatedTemplates2 = [...state.tradeTemplates.templates];
            updatedTemplates2.unshift(action.payload);
            let updatedTemplateOptions2 = {};
            updatedTemplates2.forEach(template => {
                updatedTemplateOptions2[template.templateId] = template.templateName
            })
            return {
                ...state,
                tradeTemplates: {
                    templateOptions: updatedTemplateOptions2,
                    templates: updatedTemplates2,
                    count: state.tradeTemplates.count + 1
                }
            }
        case UPDATE_TEMPLATE:
            let updatedTemplates3 = [...state.tradeTemplates.templates];
            let templateIndex3 = updatedTemplates3.findIndex(template => template.templateId === action.payload.templateId);
            updatedTemplates3[templateIndex3] = action.payload;
            let updatedTemplateOptions3 = {};
            updatedTemplates3.forEach(template => {
                updatedTemplateOptions3[template.templateId] = template.templateName
            })
            return {
                ...state,
                tradeTemplates: {
                    ...state.tradeTemplates,
                    templateOptions: updatedTemplateOptions3,
                    templates: updatedTemplates3
                }
            }

            case AUTOUPDATE_NOMINATION:
                return {
                    ...state,
                    triggerNominationUpdate: action.payload
                };    
    
        case AUTOUPDATE_CONTRACT:
            return {
                ...state,
                triggerContractUpdate: action.payload
            };    
        case CREATE_PT:
        case UPDATE_PT:
        case GET_PT: {
            const {
                lcText, 
                adText, 
                confirmedLcText, 
                lcType, 
                confirmedLcType, 
                adType, 
                letterCreditID, 
                avalizedDraftID, 
                confirmedLcID, 
                files, 
                ...rest
            } = action.payload;
            const updatedPtDoc = {
                ...rest,
                files: files? files.reverse() : files,
                ptDocId: letterCreditID || confirmedLcID || avalizedDraftID,
                ptText: lcText || confirmedLcText || adText,
                ptType: lcType || confirmedLcType || adType
            }
            return {
                ...state,
                ptDocument: updatedPtDoc
            }
        }
        case DOWNLOAD_PT_DOC: {
            let updatedPT = { ...state.ptDocument}
            updatedPT.files = action.payload
            return {
                ...state,
                ptDocument: updatedPT
            }
        }
        case CONFIRM_PT:
            return {
                ...state,
                ptDocument: {
                    ...state.ptDocument,
                    status: action.payload.status,
                    requestedChanges: action.payload.requestedChanges
                }
            }
        default:
            return state;
    }
};

export const sendInstructions = message => {
    return dispatch => {
        dispatch({
            type: SEND_INSTRUCTIONS,
            payload: message
        });
    };
};

export const getDocInstructions = id => {
    return dispatch => {
        TradeApi.smart(id)
            .getInstructions()
            .then(response => {
                dispatch({
                    type: GET_INSTRUCTIONS,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_INSTRUCTIONS_ERROR
                });
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            })
    }
};

export const sendShippingAdvice = message => {
    return dispatch => {
        dispatch({
            type: SEND_SHIPPING_ADVICE,
            payload: message
        });
    };
};

export const UpdateCloseLocally = trader => {
    return dispatch => {
        dispatch({
            type: UPDATE_CLOSE,
            payload: trader
        });
    };
};

export const PostDocumentFile = (id, shipmentId, params, cb) => {
    return dispatch => {
        LoadingDocuments(params.get('docType'), true, dispatch);
        TradeApi.postDocumentFile(id, shipmentId, params)
            .then(r => {
                LoadingDocuments(params.get('docType'), false, dispatch);
                dispatch({
                    type: POST_DOCUMENT_FILE,
                    payload: { data: r.data, shipmentId }
                });
                if (cb) {
                    cb(r.data.status);
                }
            })
            .catch(err => {
                LoadingDocuments(params.get('docType'), false, dispatch);
                console.log(err);
            });
    };
};

export const GetInspectionReports = id => {
    return dispatch => {
        TradeApi.getInspectionReports(id)
            .then(response => {
                dispatch({
                    type: GET_INSPECTION_REPORTS,
                    payload: response.data.reports.map(item => item.file).sort((a, b) => {
                        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                    })
                });
            })
            .catch(error => {
                console.error(error);
            })
    }
};

export const PostInspectionReport = (id, params ,cb) => {
    return dispatch => {
        TradeApi.postInspectionReport(id, params)
            .then(response => {
                if (cb) {
                    cb();
                }
                GetInspectionReports(id)(dispatch);
                
            })
            .catch(error => {
                console.error(error);
            })
    }
};

export const approveInspectionReport = (id, reportID) => {
    return dispatch => {
        TradeApi.approveInspectionReport(id, reportID)
            .then(response => {
               
                GetInspectionReports(id)(dispatch);
            })
            .catch(error => {
                console.error(error);
            })
    }
};

export const rejectInspectionReport = (id, reportID) => {
    return dispatch => {
        TradeApi.rejectInspectionReport(id, reportID)
            .then(response => {
                GetInspectionReports(id)(dispatch);
            })
            .catch(error => {
                console.error(error);
            })
    }
};

export const getBagmarkings = id => {
    return dispatch => {
        TradeApi.getBagmarkings(id)
            .then(response => {
                dispatch({
                    type: GET_BAGMARKINGS,
                    // payload: response.data.reports.map(item => item.file).sort((a, b) => {
                    //     return new Date(b.createdAt) - new Date(a.createdAt);
                    // })
                    payload: response.data.bagmarkings
                });
            })
            .catch(error => {
                console.error(error);
            })
    }
};

export const postBagmarking = (id, params, cb) => {
    return dispatch => {
        TradeApi.postBagmarking(id, params)
            .then(response => {
                getBagmarkings(id)(dispatch);
                if (cb) {
                    cb();
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
};

export const approveBagmarking = (id, brandID, bagmarkingID, cb) => {
    return dispatch => {
        TradeApi.approveBagmarking(id, brandID, bagmarkingID)
            .then(response => {
                getBagmarkings(id)(dispatch);
                if (cb) {
                    cb();
                }
            })
            .catch(error => {
                if (cb) {
                    cb();
                }
            })
    }
};

export const rejectBagmarking = (id, brandID, bagmarkingID, cb) => {
    return dispatch => {
        TradeApi.rejectBagmarking(id, brandID, bagmarkingID)
            .then(response => {
                getBagmarkings(id)(dispatch);
                if (cb) {
                    cb();
                }
            })
            .catch(error => {
                if (cb) {
                    cb();
                }
            })
    }
};

export const OpenDocument = (id, shipmentId, imgId, myWindow) => {
    return dispatch => {
        TradeApi.getDocumentFile(id, shipmentId, imgId)
            .then(r => {
                dispatch({
                    type: OPEN_DOCUMENT_FILE,
                    payload: r.data
                });

                myWindow.location.href = `${process.env.REACT_APP_API_URL}/${r.data.file.source}`;
                myWindow.focus();
            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const GetTradeDocuments = (tradeId, shipmentId) => {
    return dispatch => {
        LoadingAllDocuments(true, dispatch);
        TradeApi.getTradeDocuments(tradeId, shipmentId)
            .then(r => {
                const defaultValue = {
                    CERT_OF_QUALITY: null,
                    QUALITY_APPEARANCE_CERT: null,
                    CERT_OF_WEIGHT: null,
                    CERT_OF_PACKING: null,
                    CERT_OF_FUMIGATION: null,
                    PHYTOSANITARY: null,
                    EXPORT_DECLARATION: null,
                    INSURANCE: null,
                };
                LoadingAllDocuments(false, dispatch);
                dispatch({
                    type: GET_TRADE_DOCUMENTS,
                    payload:
                        r.data.shipmentDocuments.length === 0
                            ? { ...defaultValue, shipmentId }
                            : r.data.shipmentDocuments.reduce((acc, curr) => {
                                acc[curr.type] = curr;
                                return acc;
                            }, { ...defaultValue, shipmentId })
                });
            })
            .catch(err => {
                console.log(err);
            });
    };
};

export const GetShipments = (id) => {
    return dispatch => {
        TradeApi.getShipments(id)
            .then(response => {
                dispatch({
                    type: GET_SHIPMENTS,
                    payload: response.data.shipments.map(shipment => {
                        GetTradeBill(id, shipment.ID)(dispatch);
                        GetTradeDocuments(id, shipment.ID)(dispatch);
                        return { amount: shipment.amount, id: shipment.ID };
                    })
                });
            })
            .catch(error => console.error(error));
    }
};

export const PayStatusFlow = () => {
    return dispatch => {
        dispatch({
            type: PAY_TRADE,
            payload: null
        });
    };
};

export const UpdateBill = (id, shipmentId, params, cb) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.BILL, true, dispatch);
        TradeApi.updateBill(id, shipmentId, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.BILL, false, dispatch);
            dispatch({
                type: UPDATE_BILL,
                payload: { response: r.data, data: params }
            });
            cb();
        });
    };
};

export const UpdateDocument = (id, shipmentId, params, docId, cb) => {
    return dispatch => {
        LoadingDocuments(params.get('docType'), true, dispatch);
        TradeApi.updateDocumentFile(id, shipmentId, params, docId).then(r => {
            LoadingDocuments(params.get('docType'), false, dispatch);
            dispatch({
                type: UPDATE_DOCUMENT,
                payload: { data: r.data, shipmentId }
            });
            if (cb) {
                cb();
            }
        });
    };
};


export const ApproveDocument = (id, shipmentId, documentId, docName, params) => {
    return dispatch => {
        LoadingDocuments(docName, true, dispatch);
        TradeApi.approveDocument(id, shipmentId, documentId, params)
            .then(response => {
                LoadingDocuments(docName, false, dispatch);
                SetTradeStatus(response.data.tradeStatus)(dispatch);
                dispatch({
                    type: UPDATE_DOCUMENT_STATUS,
                    payload: {
                        type: docName,
                        status: response.data.documentStatus,
                        shipmentId
                    }
                });
            })
            .catch(error => {
                LoadingDocuments(docName, false, dispatch);
                console.log(error);
            });
    };
};

export const RejectDocument = (id, shipmentId, documentId, docName, params) => {
    return dispatch => {
        LoadingDocuments(docName, true, dispatch);
        TradeApi.rejectDocument(id, shipmentId, documentId, params)
            .then(response => {
                LoadingDocuments(docName, false, dispatch);
                dispatch({
                    type: UPDATE_DOCUMENT_STATUS,
                    payload: {
                        type: docName,
                        status: response.data.documentStatus,
                        shipmentId
                    }
                });
            })
            .catch(error => {
                LoadingDocuments(docName, false, dispatch);
                console.log(error);
            });
    };
};

export const ReleaseDocument = (id, shipmentId, documentId, docName, params) => {
    return dispatch => {
        LoadingDocuments(docName, true, dispatch);
        TradeApi.releaseDocument(id, shipmentId, documentId, params)
            .then(response => {
                LoadingDocuments(docName, false, dispatch);
                dispatch({
                    type: UPDATE_DOCUMENT_STATUS,
                    payload: {
                        type: docName,
                        status: response.data.documentStatus,
                        shipmentId
                    }
                });
            })
            .catch(error => {
                LoadingDocuments(docName, false, dispatch);
                console.log(error);
            });
    };
};

export const UpdateInvoice = (id, params, cb) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.INVOICE, true, dispatch);
        TradeApi.updateInvoice(id, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
            dispatch({
                type: UPDATE_INVOICE,
                payload: r.data
            });
            if (cb) {
                cb(r.data);
            }
        })
            .catch(() => {
                LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
                if (cb) {
                    cb();
                }
            });
    };
};

export const UpdateMultisetInvoice = (id, params, cb, shipmentId) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.INVOICE, true, dispatch);
        TradeApi.updateMultisetInvoice(id, shipmentId, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
            dispatch({
                type: UPDATE_INVOICE,
                payload: r.data
            });
            if (cb) {
                cb(r.data);
            }
        })
            .catch(() => {
                LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
                if (cb) {
                    cb();
                }
            });
    };
};

export const GetTradeBill = (tradeId, shipmentId) => {
    return dispatch => {
        TradeApi.getTradeBill(tradeId, shipmentId)
            .then(r => {
                dispatch({
                    type: GET_TRADE_BILL,
                    payload: { data: r.data, shipmentId }
                });
            })
            .catch(() => {
            });
    };
};

export const GetVesselNomination = (tradeId) => {
    return dispatch => {
        LoadingVesselDetails(dispatch, true);
        TradeApi.smart(tradeId).vesselMessage()
            .then(r => {
                LoadingVesselDetails(dispatch, false);
                dispatch({
                    type: GET_VESSEL_NOMINATION,
                    payload: r.data.vesselNomination
                });
            })
            .catch(() => {
                LoadingVesselDetails(dispatch, false);
                dispatch({
                    type: GET_VESSEL_NOMINATION,
                    payload: {}
                });
            });
    }
};

export const GetTradeInvoice = id => {
    return dispatch => {
        TradeApi.getTradeInvoice(id)
            .then(r => {
                dispatch({
                    type: GET_TRADE_INVOICE,
                    payload: { ...r.data.invoice, document: r.data.document }
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TRADE_INVOICE,
                    payload: null
                });
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });
    };
};

export const GetTradeInvoiceByShipmentId = (tradeId, shipmentId) => {
    return dispatch => {
        TradeApi.getTradeInvoiceByShipmentId(tradeId, shipmentId)
            .then(r => {
                dispatch({
                    type: GET_TRADE_INVOICE,
                    payload: { ...r.data.invoice, document: r.data.document }
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TRADE_INVOICE,
                    payload: null
                });
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });
    };
};

export const PostTradeDocument = (id, shipmentId, params, cb) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.BILL, true, dispatch);
        TradeApi.postDocument(id, shipmentId, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.BILL, false, dispatch);
            GetTradeBill(id, shipmentId)(dispatch);
            dispatch({
                type: POST_TRADE_DOCUMENT,
                payload: { response: r.data, data: params, shipmentId }
            });
            cb();
        });
    };
};

export const PostTradeDocumentInvoice = (id, params, cb) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.INVOICE, true, dispatch);
        TradeApi.postDocumentInvoice(id, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
            GetTradeInvoice(id)(dispatch);
            if (cb) {
                cb(r.data);
            }
        })
            .catch(() => {
                LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
                if (cb) {
                    cb();
                }
            });
    };
};

export const PostTradeMultisetInvoice = (id, params, cb, shipmentId) => {
    return dispatch => {
        LoadingDocuments(DOCUMENT_TYPES.INVOICE, true, dispatch);
        TradeApi.postTradeMultisetInvoice(id, shipmentId, params).then(r => {
            LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
            GetTradeInvoiceByShipmentId(id, shipmentId)(dispatch);
            if (cb) {
                cb(r.data);
            }
        })
            .catch(() => {
                LoadingDocuments(DOCUMENT_TYPES.INVOICE, false, dispatch);
                if (cb) {
                    cb();
                }
            });
    };
};

export const SmartTrade = {
    Cancel: (id, cb) => tradeAction(id, 'cancel', cb),
    Rejcet: (id, cb) => tradeAction(id, 'reject', cb),
    Accept: (id, cb) => tradeAction(id, 'accept', cb),
    Sign: (id, params, cb) => tradeAction(id, 'sign', cb, params)
};

export const updateSignedLocally = trader => {
    return dispatch => {
        dispatch({
            type: UPDATE_SIGNED,
            payload: trader
        });
    };
};

export const updateVesselNominated = nominated => {
    return dispatch => {
        dispatch({
            type: UPDATE_NOMINATED,
            payload: nominated
        });
    };
};

export const updatePayedLocally = () => {
    return dispatch => {
        dispatch({
            type: UPDATE_PAYED,
            payload: null
        });
    };
};

export const updatePayedFalse = () => {
    return dispatch => {
        dispatch({
            type: UPDATE_PAYED_FALSE,
            payload: false
        });
    };
};

export const updateNominationStatus = trader => {
    return dispatch => {
        dispatch({
            type: UPDATE_SIGNED,
            payload: trader
        });
    };
};

const tradeAction = (id, action, cb, params) => {
    return dispatch => {
        TradeApi.smart(id, action)
            .action(action, params)
            .then(r => {
                if (r.data.status) {
                    dispatch({
                        type: SET_STATUS,
                        payload: r.data.status,
                        updatedFields: {...params}
                    });
                }
                if (cb) {
                    cb(r.data);
                }
            })
            .catch(error => {
                cb();
            });
    };
};

export const ReplyMessage = (id, userId) => {
    return dispatch => {
        dispatch({
            type: REPLY_MESSAGE,
            payload: { id, userId }
        });
    };
};
/* POST tradeMessage and update state.trade.messages with the new message*/
export const postMessage = (reqId, message, callback) => {
    return dispatch => {
        LoadingTradeMessages(true, dispatch);
        TradeApi.postMessage(reqId, message).then(r => {
            dispatch({
                type: POST_MESSAGE,
                payload: r.data.comment
            });
            LoadingTradeMessages(false, dispatch);
            if (callback) {
                callback(null, r.data);
            }
        }).catch(error => {
            LoadingTradeMessages(false, dispatch);
            console.error(error);
            if (callback) {
                callback(error, null);
            }
        });
    };
};

export const updateRequestReset = () => {
    return dispatch => {
        dispatch({
            type : UPDATE_REQUEST_RESET
        })
    }
}

// @param {object} tradeUpdate - should contain all information tu update the trade     
// ie : { "validateDate": "2021-04-14T00:00:00+05:30" }
export const PatchTradeRequest = (tradeId, tradeUpdate, cb) => {
    return dispatch => {
        LoadingTradeUpdate(dispatch, true)
        TradeApi.patch(tradeId, tradeUpdate)
            .then(() => {
                dispatch({
                    type: UPDATE_REQUEST_SUCCESS,
                    payload: {
                        tradeId,
                        tradeUpdate
                    }
                });
                if(cb){cb()}
            })
            .catch(e => {
                console.error(e);
                dispatch({
                    type: UPDATE_REQUEST_ERROR,
                    payload: 'Could not update'
                })
            })
            .finally(() => {
                LoadingTradeUpdate(dispatch, false)
            })
    }
}

export const CreateTradeRequest = (request, cb) => {
    return dispatch => {
        LoadingCreateRequest(dispatch, true);
        TradeApi.create(request)
            .then((response) => {
                dispatch({
                    type: CREATE_REQUEST,
                    payload: {}
                });
                LoadingCreateRequest(dispatch, false);
                cb(response);
            })
            .catch(e => {
                LoadingCreateRequest(dispatch, false);
                console.error('catch', e);
                handleError(dispatch, e);
            });
    };
};

function setLastUpdate(updatedAt) {
    return {
        type: SET_LAST_UPDATE,
        payload: {
            updatedAt: updatedAt
        }
    }
}

function fetchTradesPending(request) {
    return {
        type: FETCH_TRADES_PENDING,
        payload: {
            request: request
        }
    }
}

function fetchNewTradesPending() {
    return {
        type: FETCH_NEW_TRADES_PENDING,
    }
}

function fetchTradesSuccess(tradeMap, bookMark, request) {
    return {
        type: FETCH_TRADES_SUCCESS,
        payload: {
            bookMark: bookMark,
            tradeMap: tradeMap,
            request: request
        }
    }
}

function fetchNewTradesSuccess(tradeMap, request) {
    return {
        type: FETCH_NEW_TRADES_SUCCESS,
        payload: {
            tradeMap: tradeMap,
            request: request
        }
    }
}

function fetchTradesError(error, request) {
    return {
        type: FETCH_TRADES_ERROR,
        payload: { request: request },
        error: error
    }
}

function fetchNewTradesError(error) {
    return {
        type: FETCH_NEW_TRADES_ERROR,
        error: error
    }
}

export const PAGINATED_REQUESTS = {
    PUBLIC_RTS: { requestType: 'sell', privacy: 'public' },
    PUBLIC_RTB: { requestType: 'buy', privacy: 'public' },
    OPEN: { status: 'open', privacy: 'participant' },
    LIVE: { status: 'live', privacy: 'participant' },
    CANCELED: { status: 'canceled', privacy: 'participant' },
    EXPIRED: { status: 'expired', privacy: 'participant' },
    CLOSED: { status: 'closed', privacy: 'participant' },
    OTHER: {},
    DECLINED: { type: 'declined', page: 'openRequest' }
}
export const FetchTrade = (options) => {
    const defaultParams = {
        pageSize: '50'
    };
    let params = {};
    let request = 'OTHER';
    return (dispatch, getState) => {
        if (typeof options === "string") {
            request = options;
            if (PAGINATED_REQUESTS.hasOwnProperty(options)) {
                params = { ...PAGINATED_REQUESTS[options] };
                let currentTradeState = getState().trade
                if (currentTradeState.paginatedItems[request]) {
                    params.bookMark = currentTradeState.paginatedItems[request].bookMark;
                }

            } else {
                params = { ...PAGINATED_REQUESTS.OTHER };
            }
        } else {
            params = options;
        }
        params = { ...defaultParams, ...params }
        dispatch(fetchTradesPending(request));
        if (request === "DECLINED") {
            TradeApi.list(params)
                .then(r => {
                    //const tradeMap = _.keyBy(r.data.items, 'id');
                    const tradeMap = r.data.items;
                    const bookMark = r.data.bookMark;
                    if (!params.bookMark && r.data.items.length !== 0) {
                        dispatch(setLastUpdate(r.data.items[0].updatedAt))
                    }
                    dispatch(fetchTradesSuccess(tradeMap, bookMark, request));
                })
                .catch(e => {
                    dispatch(fetchTradesError(e, request));
                    console.error('catch', e)
                });

        } else {
            TradeApi.getPage(params)
                .then(r => {
                    const tradeMap = _.keyBy(r.data.items, 'id');
                    const bookMark = r.data.bookMark;
                    if (!params.bookMark && r.data.items.length !== 0) {
                        dispatch(setLastUpdate(r.data.items[0].updatedAt))
                    }
                    dispatch(fetchTradesSuccess(tradeMap, bookMark, request));
                })
                .catch(e => {
                    dispatch(fetchTradesError(e, request));
                    console.error('catch', e)
                });
        }
    }
};

export const FetchNewTrade = (options = {}) => {
    const defaultParams = {
        pageSize: '10',
    }
    var request = (typeof options === "string") ? options : "OTHER";
    let args = PAGINATED_REQUESTS[request] || options;

    const params = { ...defaultParams, ...args }
    return async (dispatch, getState) => {
        dispatch(fetchNewTradesPending());
        var tradeMapBuffer = {};
        var bookMark;
        var upToDate = false;

        const success = r => {
            tradeMapBuffer = { ...tradeMapBuffer, ..._.keyBy(r.data.items, 'id') };
            bookMark = r.data.bookMark;
            let lastUpdate = getState().trade.lastUpdate
            if (moment(r.data.items.peek().updatedAt).isBefore(lastUpdate, 'second')) {
                upToDate = true;
            }
            if (bookMark && r.data.items.length !== 0) {
                dispatch(setLastUpdate(r.data.items[0].updatedAt))
            }
        }

        const error = e => {
            dispatch(fetchNewTradesError(e));
            upToDate = true;
        }

        while (!upToDate) {
            if (request === "DECLINED") {
                await TradeApi.list({ ...params, bookMark: bookMark })
                    .then(success)
                    .catch(error);
            } else {
                await TradeApi.getPage({ ...params, bookMark: bookMark })
                    .then(success)
                    .catch(error);
            }
        }
        dispatch(fetchNewTradesSuccess(tradeMapBuffer, request));

    };
};

export const LoadInspectionTradeList = (data, callback) => {
    return dispatch => {
        TradeApi.getInspectionTradeList(data)
            .then(response => {
                dispatch({
                    type: LOAD_INSPECTION_TRADES,
                    payload: { items: response.data.items, counts: response.data.counts }
                });
                if (callback) {
                    callback();
                }
            })
            .catch(error => console.error(error));
    };
};

export const LoadRequestInfo = (id, cb) => {
    return dispatch => {
        TradeApi.getRequestInfo(id)
            .then(r => {
                dispatch({
                    type: LOAD_REQUEST_INFO,
                    payload: r.data
                });
                if (cb) {
                    cb();
                }
            })
            .catch(e => {
                console.error('catch', e);
            });
    };
};

export const loadRequestDetails = (id, cb) => {
    return async dispatch => {
        LoadingRequestData(dispatch, true);
        TradeApi.get(id)
            .then(r => {
                let request = {
                    ...r.data.request,
                    inspectionName: r.data.request.inspection === "1" ? NON_INTEGRATED_INSPECTORS[r.data.request.inspectionName] : r.data.request.inspectionName
                }
                // TEST TO SIMULATE STARTING DATE : request.tenderStartDate = "2020-11-10T12:51:00+01:00"
                /** To Be Handeled in BE **/
                if (request.tenderStartDate && request.tenderStartDate.match("^0001-01-01*"))
                    request.tenderStartDate = null;
                /** END To Be Handeled in BE **/

                LoadingRequestData(dispatch, false);
                dispatch({
                    type: LOAD_REQUEST_DETAILS,
                    payload: { items: request }
                });
                if (cb) {
                    cb();
                }
            })
            .catch(e => {
                LoadingRequestData(dispatch, false);
                const currentLocation = window.location.pathname.split('/').filter(path => !!path)[0];
                let pushAction;
                if (['requests', 'trades'].includes(currentLocation)) {
                    pushAction = push(`/${currentLocation}`);
                } else {
                    pushAction = push('/');
                }
                dispatch(pushAction);
                let errorText;
                let errorCode = (e.response && e.response.status) ? e.response.status : "NO_RESPONSE";
                switch (errorCode) {
                    case 401:
                        errorText = 'Trade is no longer available and can now be found under “Declined Request“ Tab. ';
                        //Clear the loaded trade to prevent remaining data on the screen
                        dispatch({ type: LOAD_REQUEST_DETAILS, payload: { items: null } });
                        break;
                    case 403:
                        //Clear the loaded trade to prevent remaining dat aon the screen
                        dispatch({ type: LOAD_REQUEST_DETAILS, payload: { items: null } });
                        if (e.response.data && e.response.data.tenderWhitelistError) {
                            errorText = 'This tender is not available to you';
                        } else {
                            errorText = 'Trade is no longer available ';
                        }
                        break;
                    case "NO_RESPONSE":
                        errorText = 'Something when wrong. Server did not reply. Please try again a few moments later or contact the support team.';
                        break;
                    default:
                        errorText = 'Something went wrong. Please try again a few moments later or contact the support team.';
                        break;
                }
                showErrorModal(<h4 className="text-center">{errorText}</h4>)(dispatch);
            });
    };
};

export const UpdateTradeRequest = (id, params, cb) => {
    return async function (dispatch) {
        LoadingCreateRequest(dispatch, true);
        try {
            await TradeApi.update(id, params).then((response) => { cb(response) });
            LoadingCreateRequest(dispatch, false);
        } catch (e) {
            LoadingCreateRequest(dispatch, false);
            console.error('catch', e);
        }
    };
};

export const updateTradeDocumentLocally = (docName, text) => {
    return dispatch => {
        dispatch({
            type: UPDATE_TRADE_DOCUMENT,
            payload: { docName, text }
        });
    };
};

export const updateLaycanDateChange = (value) => {
    return dispatch => {
        dispatch({
            type: UPDATE_REQUEST_INFO,
            payload: value
        })
    }
}

export const searchCompanies = search => {
    return dispatch => {
        LoadingCounterParties(dispatch, true);
        TradeApi.getCounterparties(search)
            .then(r => {
                r.data.items.sort(function (a, b) {
                    if (a.name.toUpperCase() < b.name.toUpperCase()) {
                        return -1;
                    }
                    if (a.name.toUpperCase() > b.name.toUpperCase()) {
                        return 1;
                    }
                    return 0;
                });
                dispatch({
                    type: LOAD_COMPANIES,
                    payload: { companies: r.data.items }
                });
                LoadingCounterParties(dispatch, false);
            })
            .catch(e => {
                console.error('catch', e);
                LoadingCounterParties(dispatch, false);
            });
    };
};

export const preloadInspectionCompanies = search => {
    return dispatch => {
        LoadingInspectionCompanies(dispatch, true);
        TradeApi.getInspectionCompanies(search)
            .then(r => {
                dispatch({
                    type: LOADED_INSPECTION_COMPANIES,
                    payload: { companies: r.data.companies }
                });
                LoadingInspectionCompanies(dispatch, false);
            })
            .catch(e => {
                console.error('catch', e);
                LoadingInspectionCompanies(dispatch, false);
            });
    };
};


export const getMessage = (id, cb) => {
    return dispatch => {
        LoadingTradeMessages(true, dispatch);
        TradeApi.getMessages(id).then(r => {
            LoadingTradeMessages(false, dispatch);
            dispatch({
                type: GET_MESSAGES,
                payload: r.data.comments
            });
            if (cb) {
                cb();
            }
        }).catch(() => {
            LoadingTradeMessages(false, dispatch);
        });
    };
};

export const sorMessageByDate = (messages) => messages.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1);



export const getTradeMessages = (id, cb) => {
    return dispatch => {
        LoadingTradeMessages(true, dispatch);
        TradeApi.getTradeMessages(id).then(r => {
            LoadingTradeMessages(false, dispatch);
            dispatch({
                type: GET_MESSAGES,
                payload: r.data.comments
            });
            if (cb) {
                cb();
            }
        }).catch(() => {
            LoadingTradeMessages(false, dispatch);
        });
    };
};

export const sendEmail = tradeId => {
    return () => {
        TradeApi.sendEmail(tradeId)
        .catch(e => {
            console.error(e)
        });
    }
};

export const GetDocumentComments = (tradeId, shipmentId, documentId) => {
    return dispatch => {
        LoadingDocumentComments(true, documentId, dispatch);
        TradeApi.getDocumentComments(tradeId, shipmentId, documentId)
            .then(response => {
                LoadingDocumentComments(false, documentId, dispatch);
                dispatch({
                    type: GET_DOCUMENT_COMMENTS,
                    payload: { data: response.data.comments || [], documentId }
                })
            }).catch(e => {
                console.log(e);
            })
    }
};

export const PostDocumentComment = (tradeId, shipmentId, documentId, params) => {
    return dispatch => {
        LoadingDocumentComments(true, documentId, dispatch);
        TradeApi.postDocumentComment(tradeId, shipmentId, documentId, params)
            .then(response => {
                LoadingDocumentComments(false, documentId, dispatch);
                GetDocumentComments(tradeId, shipmentId, documentId)(dispatch);
            })
            .catch(error => console.error(error))
    }
};

export const ClearDocumentComments = (documentId) => {
    return dispatch => {
        dispatch({
            type: GET_DOCUMENT_COMMENTS,
            payload: { data: [], documentId }
        });
    }
};

export const SetTradeStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_STATUS,
            payload: status
        });
    }
};


export const ClearSingleTrade = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_SINGLE,
        })
    }
};

export const ClearTradeState = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_TRADE_STATE,
        })
    }
};

export const UpdateRequest = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_REQUEST,
            payload: data
        })
    }
};

export const actionError = (error) => {
    return {
        type: ACTION_ERROR,
        error: error
    }
}

export const dismissError = () => {
    return {
        type: DISMISS_ACTION_ERROR,
    }
}

export const CounterTrade = (tradeId, counterpartyId, params, callback) => {
    return dispatch => {
        LoadingRequestData(dispatch, true);
        TradeApi.postBid(tradeId, counterpartyId, params)
            .then((response) => {
                LoadingRequestData(dispatch, false);
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadTradeBids(tradeId)(dispatch);
                            dispatch(dismissError());
                            if (callback) { callback(null, response) }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        }
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        break;
                }

            })
            .catch((error) => {
                LoadingRequestData(dispatch, false);
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                if (callback) {
                    callback(error, null)
                }

            });
    }
};

export const counterBankDetails = (tradeId, counterPartyId, params, afterAuthorization, callback1, callback2) => {
    return dispatch => {
        afterAuthorization ? LoadingRequestData(dispatch, true) : LoadingBankDetails(dispatch, true);
        TradeApi.postBid(tradeId, counterPartyId, params)
            .then((response) => {
                afterAuthorization ? LoadingRequestData(dispatch, false) : LoadingBankDetails(dispatch, false);
                    if(response.status === 200){
                        if (response.data.success) {
                            LoadTradeBids(tradeId)(dispatch);
                            dispatch(dismissError());
                            if (callback1) { callback1(null, response) }
                        } else {
                            if (callback2) { callback2() }
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        }
                    }else{
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                    }                        
            })
            .catch((error) => {
                LoadingBankDetails(dispatch, false);
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                if (callback1) {
                    callback1(error, null)
                }
            });
    }
}

export const LoadTradeBids = tradeId => {
    return dispatch => {
        LoadingBidData(dispatch, true);
        TradeApi.getBids(tradeId)
            .then(response => {
                dispatch({
                    type: GET_BIDS,
                    payload: response.data.bids || []
                });
                LoadingBidData(dispatch, false);
            })
            .catch(() => {
                LoadingBidData(dispatch, false);
            });
    }
};

export const AcceptTradeBid = (tradeId, counterPartyId, callback, data) => {
    return dispatch => {
        LoadingRequestData(dispatch, true);
        TradeApi.acceptBid(tradeId, counterPartyId, data)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadingRequestData(dispatch, false);
                            if (callback) {
                                callback();
                            }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                            LoadingRequestData(dispatch, false);
                        }
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        LoadingRequestData(dispatch, false);
                        break;
                }
            })
            .catch(() => {
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                LoadingRequestData(dispatch, false);
            });
    }
};

export const DeclineTradeBid = (tradeId, counterpartyId, params, callback) => {
    return dispatch => {
        LoadingRequestData(dispatch, true);
        TradeApi.declineBid(tradeId, counterpartyId, params)
            .then((response) => {
                LoadingRequestData(dispatch, false);
                switch (response.status) {
                    case 200:
                        if (response.data.success) {
                            LoadTradeBids(tradeId)(dispatch);
                            dispatch(dismissError());
                            if (callback) { callback(null, response) }
                        } else {
                            dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        }
                        break;
                    case 401:
                    case 402:
                    case 403:
                        dispatch(actionError(TradeAPIErrors[response.data.errorCode] || TradeAPIErrors.DEFAULT_ERROR));
                        break;
                    default:
                        dispatch(actionError(TradeAPIErrors.DEFAULT_ERROR));
                        break;
                }
            })
            .catch(() => {
                dispatch(actionError(TradeAPIErrors.HTTP_500));
                LoadingRequestData(dispatch, false);
            });
    }
};

export const AutoupdateTriggered = (value = false) => {
    return dispatch => {
        dispatch({
            type: AUTOUPDATE_TRIGGERED,
            payload: value
        })
    }
};

export const verifyAuthorisationCode = (params) => {
    return TradeApi.verifyAuthorisationCode(params);
};

export const GetTradePerformaInvoice = (id, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.getTradePerformaInvoice(id)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                dispatch({
                    type: GET_TRADE_PERFORMA_INVOICE,
                    payload: { ...r.data }
                });
                if (cb) {
                    cb()
                }
            })
            .catch((error) => {
                LoadingProformaInvoiceOperation(dispatch, false);
                dispatch({
                    type: GET_TRADE_PERFORMA_INVOICE,
                    payload: null
                });
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
            });
    };
};

export const PostTradeDocumentPerformaInvoice = (id, params, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.postDocumentPerformaInvoice(id, params).then(r => {
            LoadingProformaInvoiceOperation(dispatch, false);
            if (cb) {
                cb(r.data);
            }
        }).catch(() => {
            LoadingProformaInvoiceOperation(dispatch, false);
        });
    };
};

export const UpdateTradeDocumentPerformaInvoice = (id, params, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.updateDocumentPerformaInvoice(id, params).then(r => {
            LoadingProformaInvoiceOperation(dispatch, false);
            if (cb) {
                cb(r.data);
            }
        })
            .catch(() => {
                LoadingProformaInvoiceOperation(dispatch, false);
                if (cb) {
                    cb();
                }
            });
    };
};

export const UploadProformaPaymenntReciept = (id, params, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.uploadProformaPaymentReciept(id, params)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                dispatch({
                    type: PROFORMA_INVOICE_STATUS,
                    payload: 'UPLOADED'
                });
                if (cb) {
                    cb(r.data.status);
                }
            })
            .catch(err => {
                LoadingProformaInvoiceOperation(dispatch, false);
                console.log(err);
            });
    };
};


export const UploadInvoicePaymentProof = (id, params, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.uploadInvoicePaymentProof(id, params)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                if (cb) {
                    cb();
                }
            })
            .catch(err => {
                LoadingProformaInvoiceOperation(dispatch, false);
                console.log(err);
            });
    };
};

export const ReuploadInvoicePaymentProof = (id, params, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.reuploadInvoicePaymentProof(id, params)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                if (cb) {
                    cb();
                }
            })
            .catch(err => {
                LoadingProformaInvoiceOperation(dispatch, false);
                console.log(err);
            });
    };
};

export const PostDownpaymentEffected = (tradeId, proformaInvoiceId, status, jsonBody, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.postDownPaymentEffected(tradeId, proformaInvoiceId, status, jsonBody)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                dispatch({
                    type: PROFORMA_INVOICE_STATUS,
                    payload: status
                });
                if (status === 'PAYMENT_CONFIRMED') {
                    dispatch({
                        type: SET_STATUS,
                        payload: 'PROFORMA_INVOICE'
                    })
                }
                if (cb) {
                    cb(r.data.status);
                }
            })
            .catch(err => {
                LoadingProformaInvoiceOperation(dispatch, false);
                console.log(err);
            });
    };
};

export const ConfirmInvoicePayment = (tradeId, invoicePaymentId, status, jsonBody, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.confirmInvoicePayment(tradeId, invoicePaymentId, status, jsonBody)
            .then(r => {
                LoadingProformaInvoiceOperation(dispatch, false);
                if (status === 'PAYMENT_CONFIRMED') {
                    dispatch({
                        type: UPDATE_PAYED,
                        payload: null
                    })
                    dispatch({
                        type: SET_STATUS,
                        payload: 'PAYED'
                    })
                } else {
                    dispatch({
                        type: UPDATE_PAYED_FALSE,
                        payload: false
                    });
                    dispatch({
                        type: SET_STATUS,
                        payload: 'DOCUMENTS'
                    });
                }
                if (cb) {
                    cb();
                }
            })
            .catch(err => {
                LoadingProformaInvoiceOperation(dispatch, false);
                console.log(err);
            });
    };
};

export const GetProformaPaymentReciepts = (tradeId, proformaInvoiceId, cb) => {
    return dispatch => {
        LoadingProformaInvoiceOperation(dispatch, true);
        TradeApi.getProformaInvoiceReciept(tradeId, proformaInvoiceId).then(response => {
            LoadingProformaInvoiceOperation(dispatch, false);
            let files = response.data;
            let updatedFiles = files.map(file => {
                return {
                    url: file.docUrl,
                    id: file.fileID ? file.fileID : '',
                    remark: file.remark
                }
            });
            dispatch({
                type: GET_PROFORM_PAYMENT_RECIEPTS,
                payload: updatedFiles.reverse()
            })
            if (cb) {
                cb();
            }
        });
    }
}


export const GetInvoicePaymentProofs = (tradeId, invoicePaymentId, cb) => {
    return dispatch => {
        LoadingCommercialInvoiceProofs(dispatch, true);
        TradeApi.getInvoicePaymentProofs(tradeId, invoicePaymentId).then(response => {
            LoadingCommercialInvoiceProofs(dispatch, false);
            let files = response.data;
            let updatedFiles = files.map(file => {
                return {
                    url: file.docUrl,
                    id: file.fileID ? file.fileID : '',
                    buyerAdditionalInfo: file.buyerAdditionalInfo,
                    buyerDeclarationDate: file.buyerDeclarationDate
                }
            });
            dispatch({
                type: GET_INVOICE_PAYMENT_PROOFS,
                payload: updatedFiles.reverse()
            })
            if (cb) {
                cb();
            }
        }).catch(() => {
            LoadingCommercialInvoiceProofs(dispatch, false);
        });
    }
}

export const UpdateCommercialInvoiceType = (tradeId, params, cb) => {
    return dispatch => {
        LoadingCommercialInvoiceIndicator(dispatch, true);
        TradeApi.updateCommercialInvoiceType(tradeId, params).then(() => {
            dispatch({
                type: UPDATE_COMMERCIAL_INVOICE_TYPE,
                payload: params.commercialInvInd
            })
            if (cb) {
                cb();
            }
        }).catch(() => {
            LoadingCommercialInvoiceIndicator(dispatch, false);
        });
    }
}


export const GetBidInfo = (BidId, cb) => {
    return dispatch => {
        LoadingBidInfo(dispatch, true);
        TradeApi.getBidInfo(BidId)
            .then(response => {
                const {tradeBidInfo} = response.data;
                dispatch({
                    type: GET_BID_INFO,
                    payload: {
                        ...tradeBidInfo,
                        inspectionName: tradeBidInfo.inspectionId === "1" ? NON_INTEGRATED_INSPECTORS[tradeBidInfo.inspectionName] : tradeBidInfo.inspectionName
                    }
                });
                if (cb) {
                    cb();
                }
                LoadingBidInfo(dispatch, false);
            })
            .catch(() => {
                LoadingBidInfo(dispatch, false);
            });
    }
};

export const GetContractUserInfo = companyId => {
    return dispatch => {
        KycApi.getById(companyId)
            .then(response => {
                dispatch({
                    type: GET_CONTRACTUSER_INFO,
                    payload: response.data.company
                });
            })
            .catch(() => {
                //  LoadingRequestData(dispatch, false);
            });
    }
};

export const GetTradeTemplates = () => {
    return dispatch => {
        const options = {};
        LoadingTemplates(dispatch, true, 'FETCHING');
        TradeApi.getTradeTemplates().then(response => {
            response.data.templates.forEach(template => {
                options[template.templateId] = template.templateName;
            })
            dispatch({
                type: LOAD_TRADE_TEMPLATES,
                payload: {
                    templateOptions: options,
                    templates: [...response.data.templates],
                    count: response.data.count
                }
            })
            LoadingTemplates(dispatch, false, 'FETCHING');
        }).catch(() => {
            LoadingTemplates(dispatch, false, 'FETCHING');
        })
    }
}

export const DeleteTemplate = (templateId, cb) => {
    return dispatch => {
        LoadingTemplates(dispatch, true, 'DELETING');
        TradeApi.deleteTemplate(templateId).then(() => {
            dispatch({
                type: DELETE_TEMPLATE,
                payload: templateId
            })
            LoadingTemplates(dispatch, false, 'DELETING');
            if (cb) { cb() }
        }).catch(() => {
            LoadingTemplates(dispatch, false, 'DELETING');
        })
    }
}

export const RenameTemplate = (templateId, templateName, cb) => {
    return dispatch => {
        LoadingTemplates(dispatch, true, 'UPDATING');
        TradeApi.renameTemplate(templateId, templateName).then(() => {
            dispatch({
                type: RENAME_TEMPLATE,
                payload: { templateId, templateName }
            })
            LoadingTemplates(dispatch, false, 'UPDATING');
            if (cb) { cb() }
        }).catch(() => {
            LoadingTemplates(dispatch, false, 'UPDATING');
        });
    }
}

export const CreateTemplate = (templateBody, cb) => {
    return dispatch => {
        LoadingTemplates(dispatch, true, 'CREATING');
        TradeApi.createTemplate(templateBody).then(response => {
            dispatch({
                type: CREATE_TEMPLATE,
                payload: response.data.data
            });
            LoadingTemplates(dispatch, false, 'CREATING');
            if (cb) { cb() }
        }).catch(() => {
            LoadingTemplates(dispatch, false, 'CREATING');
        })
    }
}

export const UpdateTemplate = (templateId, templateBody, cb) => {
    return dispatch => {
        LoadingTemplates(dispatch, true, 'UPDATING');
        TradeApi.updateTemplate(templateId, templateBody).then(response => {
            dispatch({
                type: UPDATE_TEMPLATE,
                payload: response.data.data
            })
            LoadingTemplates(dispatch, false, 'UPDATING');
            if (cb) { cb() }
        }).catch(() => {
            LoadingTemplates(dispatch, false, 'UPDATING');
        })
    }
}
export const AutoupdateContract = (value = false) => {
    return dispatch => {
        dispatch({
            type: AUTOUPDATE_CONTRACT,
            payload: value
        })
    }
};


export const AutoupdateNomination = (value = false) => {
    return dispatch => {
        dispatch({
            type: AUTOUPDATE_NOMINATION,
            payload: value
        })
    }
};

export const createPTDoc = (url, params, lcType, cb) => {
    return dispatch => {
        LoadingPTProcess(dispatch, LOADING_PT_PROCESS.CREATE);   
        if(lcType === 'text'){
            TradeApi.createPaymentTermText(url, params).then(response => {
                LoadingPTProcess(dispatch, '');
                dispatch({
                    type: CREATE_PT,
                    payload: response.data
                });
                if(cb){cb()}
            }).catch(() => {
                LoadingPTProcess(dispatch, '');
            })
        }else{
            TradeApi.uploadPaymentTermDoc(url, params).then(response => {
                LoadingPTProcess(dispatch, '');
                dispatch({
                    type: CREATE_PT,
                    payload: response.data.data
                });
                if(cb){cb()}
            }).catch(() => {
                LoadingPTProcess(dispatch, '');
            })
        }
    }
}

export const updatePaymentTermDoc = (url, params, lcType, cb) => {
    return dispatch => {
        LoadingPTProcess(dispatch, LOADING_PT_PROCESS.UPDATE);
        if(lcType === 'text'){
            TradeApi.updatePaymentTermText(url, params).then(response => {
                LoadingPTProcess(dispatch, '');
                dispatch({
                    type: UPDATE_PT,
                    payload: response.data
                });
                if(cb){cb()}
            }).catch(() => {
                LoadingPTProcess(dispatch, '');
            })
        }else{
            TradeApi.uploadPaymentTermDoc(url, params).then(response => {
                LoadingPTProcess(dispatch, '');
                dispatch({
                    type: UPDATE_PT,
                    payload: response.data.data
                });
                if(cb){cb()}
            }).catch(() => {
                LoadingPTProcess(dispatch, '');
            })
        }
    }
}

export const getPTDocument = (url) => {
    return dispatch => {
        LoadingPTProcess(dispatch, LOADING_PT_PROCESS.GET);
        TradeApi.getPaymentTermDocument(url).then(response => {
            LoadingPTProcess(dispatch, '');
            dispatch({
                type: GET_PT,
                payload: response.data
            })
        }).catch(() => {
            LoadingPTProcess(dispatch, '');
        })
    }
}

export const confirmPTDoc = (url, params, status, cb) => {
    return dispatch => {
        LoadingPTProcess(dispatch, LOADING_PT_PROCESS.CONFIRM);
        TradeApi.confirmPaymentTerm(url, params).then(response => {
            LoadingPTProcess(dispatch, '');
            let data = response.data.lcResp || response.data.adResp || response.data.cLcResp
            dispatch({
                type: CONFIRM_PT,
                payload: {status, requestedChanges: data.requestedChanges}
            })
            if(cb){cb()}
        }).catch(() => {
            LoadingPTProcess(dispatch, '');
        })
    }
}

export const downloadPaymentTermDoc = (url, type, cb) => {
    return dispatch => {
        LoadingPTProcess(dispatch, type);
        TradeApi.downloadPaymentTermDoc(url).then(response => {
            LoadingPTProcess(dispatch, '');
            let files = response.data;
            let updatedFiles = files.map(file => {
                return {
                    url: file.docUrl,
                    id: file.fileID ? file.fileID : '',
                    name: file.fileName,
                    remark: file.remark,
                    createdAt:file.createdAt
                }
            });
            dispatch({
                type: DOWNLOAD_PT_DOC,
                payload: updatedFiles.reverse()
            })
            if(cb){cb()}
        }).catch(() => {
            LoadingPTProcess(dispatch, '');
        })
    }

}

