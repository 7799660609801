import React, { Component } from 'react';

import PriceDetails from './PriceDetails';

class ScheduleDetails extends Component {
    
    render() {
        const {routeScheduleDetails={}, options=""} = this.props;
        const price = routeScheduleDetails.price;
        const importDnDConditions = routeScheduleDetails.importDnDConditions;
        const penaltyFees = routeScheduleDetails.penaltyFees;
        if(!price){ 
            return false
        }

        return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 col-lg-3">
                    <strong>{options}</strong><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://terms.maersk.com/terms-spot-booking">Term & Conditions</a>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <strong>Key features</strong><br/>
                    <ul>
                        <li>Fixed price at booking</li>
                        <li>Loading guarantee</li>
                        <li>Changes and cancellations possible for a fee</li>
                    </ul>
                </div>
                <div className="col-sm-12 col-lg-6">
{/*                     <strong>Rollable</strong><br/>
                    <div>Select rollable for non time sensitive cargo on the review page before you submit the booking 
                        request and help us to always deliver loading guarantee when you need it. Rolled cargo will be 
                        compensated.</div> */}
                </div>
            </div>
            <PriceDetails price={price} />
            <div className="row">
                { (importDnDConditions && importDnDConditions.length !==0) && 
                <div className="col-lg-12">
                    <strong>DnD fees</strong>
                    <table className="table table-striped table-bordered table-hover table-sm">                    
                        <thead>
                            <tr>
                                <th><strong></strong></th>
                                <th scope="col" >Start event</th>
                                <th scope="col">Validity Period</th>
                                <th scope="col">Cost perDay</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importDnDConditions.map(condition => (
                            <React.Fragment>
                                <tr>
                                    <th scope="row" rowspan={1+condition.chargePerDiemAfterFreetime.length}>{`${condition.direction} ${condition.chargeType}`}</th>
                                    <td rowspan={1+condition.chargePerDiemAfterFreetime.length}>{`${condition.freetimeStartEvent}`}</td>
                                    <td>{`Day 1 - Day ${condition.freetimeGrantInDays}`}</td>
                                    <td>{`Free`}</td>
                                </tr>
                                {condition.chargePerDiemAfterFreetime.map((item, index) => (
                                <tr>
                                    <td>{`Day ${item.startDayOfCharge}`}</td>
                                    <td>{`${item.currencyOfCharge} ${item.chargePerDiem}`}</td>
                                </tr>))}
                            </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>}
                { penaltyFees && penaltyFees.map(penaltyFee => (
                <div className="col-lg-12">
                    <div><strong>Penalty Fees</strong></div>
                    <table className="table table-striped table-bordered table-hover table-sm">                    
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Fee</th>
                            </tr>
                        </thead>
                        <tbody>  
                            { penaltyFee.charges.map(charge => ( 
                            <tr>
                                <td>{charge.displayName}</td>
                                <td>{`${penaltyFee.currency} ${charge.chargeFee}`}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>))}
            </div>
            <div className="row">
                <div className="col-sm-6">
                    Please refer to your&nbsp;
                    <a href="https://assets.maerskline.com/combined-pricing-assets/maeu/dnd/free_time_offer_for_Maersk_SPOT.xlsx">DnD free time per diem here.</a><br/>
                </div>
                <div className="col-sm-6">
                    <strong>Rates are subject to change at time of booking</strong><br/>
                </div>
            </div>
        </div>)
    }
}

export default ScheduleDetails;