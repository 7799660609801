import React, {useEffect, useMemo, useState} from 'react';
import {Line} from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';
// import a particular color scheme
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import moment from 'moment-timezone';
import { renderToString } from 'react-dom/server'
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {getPrices} from '../../../../modules/module.tender';
import Preloader from '../../../components/preloader/Preloader.js'

import {DATEFORMATHOURS} from '../../../../services/service.values'
import { EnumsService } from '../../../../services/service.utils'

/**
 * CompareGraphDutchAuction displays anonymous prices. This component is available for counterparties of Tender trades with 'dutch auction' 
 * 
 */
const CompareGraphDutchAuction =  ({trade, getPrices, tender}) => {

    const [lastUpdate , setLastUpdate] = useState();

    useEffect(() => {
        getPrices(trade.items.single.id, true)
        let priceLoop;
        if(trade.items.single && !priceLoop){         
            priceLoop = setInterval(() => {
                setLastUpdate(new Date());
                getPrices(trade.items.single.id)}, 7000);    
        } 
        return () => { clearInterval(priceLoop)};     
    }, 
    [trade.items.single, getPrices]);

    
    // RDEV-1976
    const data = useMemo(() => {
        const series = {};
        const datasets = [];

        // RDEV-1976 - RDEV-1862 Display series of price formultiple origins
        if(tender.prices[0] && tender.prices[0].multipleOriginPrice.length !== 0) {
            // Case multiple origins and prices
            tender.prices.forEach((bidPrice, index) => {
                bidPrice.multipleOriginPrice.forEach(item => {
                    // Ignore prices undefined or '0'
                    if(item.price) {
                        // If this serie of the dataset does not exist then we initialize it.
                        if(!series[item.origin]) {
                            series[item.origin] = {
                                label: EnumsService.countries()[item.origin],
                                data: [],
                                showLine: false,
                                pointRadius: 6,
                                xAxisID: 'time-x-axis'
                            }
                        }
                        // Add point to the array of data 
                        series[item.origin].data.push({
                            t:moment(bidPrice.date).format(DATEFORMATHOURS),
                            y: item.price,
                            origin: EnumsService.countries()[item.origin],
                        })
                    }
                })
            })
            for (const value of Object.values(series)) {
                datasets.push(value) 
            }
        }else{
            // Case single origin and price
            let euroPrices = tender.prices.filter(item => item.currency === "EURO").map(item => ({t:moment(item.date).format(DATEFORMATHOURS) ,y: item.price}));
            let usdPrices = tender.prices.filter(item => item.currency === "US$").map(item => ({t:moment(item.date).format(DATEFORMATHOURS) ,y: item.price}));
            if(euroPrices.length !== 0) {
                datasets.push({
                    label:"Euro Prices",
                    data: euroPrices,
                    showLine: false,
                    pointRadius: 6,
                    xAxisID: 'time-x-axis'
                })
            }
            if(usdPrices !== 0) {
                datasets.push({
                    label:"USD Prices",
                    data: usdPrices,
                    showLine: false,
                    pointRadius: 6,
                    xAxisID: 'time-x-axis'
                })
            }
        }


        return {
            datasets  
        };
    }, [tender.prices]);  

    const validateDate = moment(trade.items.single.validateDate)
    const now = moment(new Date())
    const remainingTime = moment.duration(validateDate.diff(now))
    const days = Math.floor(remainingTime.asDays());
    const hours = remainingTime.hours();
    const minutes = remainingTime.minutes();

        
    return <>
        <div className="text-right" style={{minHeight: '30px'}}>
            <Preloader loadingStyle="spinner" loading={tender.getPricesPending === true}>
                <small>Last update at {moment(lastUpdate).format("HH:mm:ss")}</small>
            </Preloader>
        </div>
        <div className="chart-wrapper" style={{"height":"350px"}}>
            <Preloader loadingStyle="swirl" loading={tender.getPricesPending === 'init'}>
                {tender.prices.length !==0?
                <Line type="line"
                    offset={true}
                    pointStyle="circle"
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                               
                                label: function(tooltipItem, chartData) { 
                                  
                                   const datasetIndex = tooltipItem.datasetIndex;
                                   const pointData = chartData.datasets[datasetIndex]
                                    return `${pointData.label}: ` + renderToString(<NumberFormat 
                                                displayType={'text'} 
                                                renderText={text => text}
                                                thousandSeparator={true} 
                                                value={tooltipItem.yLabel}
                                                />)
                                }       
                            }
                        },
                        scales: {
                            xAxes: [{
                                id: 'time-x-axis',
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        year: DATEFORMATHOURS
                                    }
                                },
                                distribution: 'series',
                                ticks: {
                                    source: 'data',
                                }
                            }],
                        },
                        plugins: {
                            colorschemes: {
                                scheme: Tableau20
                            }
                        }
                    }
                }/>
                :
                <div className="text-center">No Offer To Display</div>    
                }
            </Preloader>
        </div>
        { trade.items.single && 
        <div className="text-center">
            {validateDate.isSameOrAfter(new Date())?
            <span>
                {`Tender closing in ${(days !== 0)?''.concat(days).concat(`${days===1?' day':' days'},`):''} ${(hours !== 0)?''.concat(hours).concat(`${hours===1?' hour':' hours'}`):''} ${(minutes !== 0)?''.concat(minutes).concat(`${minutes===1?' minute':' minutes'}`):''}`}
            </span>
            :
            <span>Tender is closed</span>}
        </div> }
    </>
}


const mapStateToProps = state => {
    return {
        tender: state.tender,
        trade: state.trade,
        account: state.account,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({getPrices}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompareGraphDutchAuction);


