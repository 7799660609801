import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
//import { LoadInspectionTradeList, LoadRequests } from '../../modules/module.trade';
import { preloadGrouplistByComapny, addUserGroup, editUserGroup, deleteGroup, cloneGroup } from '../../modules/module.adminManagement';
import { push } from 'react-router-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
//COMPONENTS

import './adminManagement-style.scss';
import { DATEFORMAT } from '../../services/service.values';
import Preloader from '../components/preloader/Preloader';
// import '../../static/fontawesome-free-5.2.0-web/css/all.scss';
import '../admin/requests/create/create-trade-request.scss'
import '../components/form/FormInputField.scss';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Tooltip from '@material-ui/core/Tooltip';

class UserGroups extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  state = {
    showModal: false,
    data: {
      id: "",
      name: "",
      description: "",
      companyID: ""

    },
    errors: [],

  };

  handleChangeInput = e => {
    this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } });
  };

  validate(data) {
    const errors = {};
    let filterData = {};

    filterData = {


      name: data.name,
      description: data.description,
      companyID: this.props.company.companyId
    }
    for (let key in filterData) {
      if (filterData[key].length === 0) errors[key] = `${key} is required.`;
    }

    return errors;
  }

  validateUpdate(data) {
    const errors = {};
    let filterData = {};
    filterData = {
      id: data.id,
      name: data.name,
      description: data.description,
      companyID: data.companyID
    }
    for (let key in filterData) {
      if (filterData[key].length === 0) errors[key] = `${key} is required.`;
    }

    return errors;
  }


  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    const userData =
    {

      name: this.state.data.name,
      description: this.state.data.description,
      companyID: this.props.company.companyId
    }

    if (Object.keys(errors).length === 0) {
      this.props.addUserGroup(userData);
      this.closeModal();

    }
  };

  handleSubmitUpdate = e => {
    e.preventDefault();
    const errors = this.validateUpdate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.props.editUserGroup(this.state.data);
      this.closeModal();
    }
  };

  componentWillMount = () => {
    this.fetchFunction();
  };

  openModal = (name, data) => {

    this.setState({ showModal: true, modal: name, data: data });

  };
  handleClone = (id) => {
    let params = {};
    this.props.cloneGroup(id, params);

  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modal: '',
      input: '',
      modalData: {},
      errors: [],
      signatureModal: {}
    });
  };

  fetchFunction = () => {

    this.props.preloadGrouplistByComapny(this.props.company.companyId);     
    this.setState({});
  };

  getItems = () => {
    let groupList = this.props.usermanagment.groups;
    return groupList;

  };
  renderModal = (whatModal) => {
    switch (whatModal) {

      case 'ADD_GROUPS':
        return (
          <div className="modal__container">

            <form
              className="modal__wrapper px-0 py-0 authcode"
              onSubmit={this.handleSubmit

              }>
              <div className="modal-content">

                <div className="modal-header">

                  <div className="modal-header-center"> <h5 className="modal-title">Add Group</h5></div>
                  <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="create-req__wrapper">
                    <div className="form-input">
                      <label className="label">Name</label>

                      <input
                        id="name"
                        name="name"
                        placeholder="Name"
                        className={`input input_higher ${(this.state.errors.name || "Error") &&
                          "input_error"}`}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="create-req__wrapper" > {
                    this.state.errors.name && < p className="counterparty__error" > {
                      this.state.errors.name
                    } </p>}
                  </div>
                  <div className="create-req__wrapper">
                    <div className="form-input">
                      <label className="label">Description</label>

                      <input
                        id="description"
                        name="description"
                        placeholder="Description"
                        className={`input input_higher ${(this.state.errors.description || "Error") &&
                          "input_error"}`}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="create-req__wrapper">

                    {this.state.errors.description && <p className="counterparty__error">{this.state.errors.description} </p>}

                  </div>

                </div>

                <div className="modal-footer">
                  <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                    <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                  <button type="submit" className="btn-popup btn-submit">
                    <FontAwesomeIcon icon={faCheck} /> Submit
                           </button>

                </div>
              </div>
            </form>

          </div>
        );
      case 'EDIT_GROUPS':
        return (
          <div className="modal__container">

            <form
              className="modal__wrapper px-0 py-0 authcode"
              onSubmit={this.handleSubmitUpdate
              }>
              <div className="modal-content">

                <div className="modal-header">


                  <div className="modal-header-center">  <h5 className="modal-title">Edit Group</h5></div>

                  <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">

                    <span aria-hidden="true">&times;</span>

                  </button>

                </div>

                <div className="modal-body">


                  <div className="create-req__wrapper">
                    <div className="form-input">
                      <label className="label">Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={this.state.data.name}
                        placeholder="name"
                        className={`input input_higher ${(this.state.errors.name || "Erooo") &&
                          "input_error"}`}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="create-req__wrapper " > {
                    this.state.errors.name && < p className="counterparty__error" > {
                      this.state.errors.name
                    } </p>}
                  </div>
                  <div className="create-req__wrapper">
                    <div className="form-input">
                      <label className="label">Description</label>
                      <input
                        id="description"
                        name="description"
                        type="text"
                        value={this.state.data.description}
                        placeholder="description"
                        className={`input input_higher ${(this.state.errors.description || "Error") &&
                          "input_error"}`}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="create-req__wrapper">

                    {this.state.errors.description && <p className="counterparty__error">{this.state.errors.description} </p>}

                  </div>

                </div>

                <div className="modal-footer">
                  <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                    <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                  <button type="submit" className="btn-popup btn-submit">
                    <FontAwesomeIcon icon={faCheck} /> Submit
       </button>

                </div>
              </div>
            </form>

          </div>
        );
      case 'DELETE_GROUPS':

        return (
          <div className="modal__container">
            <form
              className="modal__wrapper px-0 py-0 authcode"
              onSubmit={e => {
                e.preventDefault();
                this.closeModal();
                this.props.deleteGroup(this.state.data.id);

              }}>
              <div className="modal-content">

                <div className="modal-header">
                <div className="modal-header-center">  <h5 className="modal-title">Delete Group</h5></div>
                  <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <p className="center-text">Are you sure you want to delete this group? <br />
                   Press back to return to previous screen or press confirm to continue.
                   </p>
                </div>
                <div className="modal-footer">
                  <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                    <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                  <button type="submit" className="btn-popup btn-submit">
                    <FontAwesomeIcon icon={faCheck} /> Confirm
                            </button>

                </div>
              </div>
            </form>

          </div>
        );

      case 'CLONE_GROUPS':

        return (
          <div className="modal__container">
            <form
              className="modal__wrapper px-0 py-0 authcode"
              onSubmit={e => {
                e.preventDefault();
                this.closeModal();
                this.handleClone(this.state.data.id);

              }}>
              <div className="modal-content">

                <div className="modal-header">
                <div className="modal-header-center"><h5 className="modal-title">Duplicate Group</h5></div>
                  <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="center-text">Are you sure you want to clone for this group ? <br />
                    Press back to return to previous screen or press confirm to continue.
                      </p>
                </div>
                <div className="modal-footer">
                  <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                    <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                  <button type="submit" className="btn-popup btn-submit">
                    <FontAwesomeIcon icon={faCheck} /> Confirm
                            </button>

                </div>
            </div>
            </form>
          </div>
        );
      default:
        break;
    }
  };
  
  makeStyles = (theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })(Tooltip);



  render() {
    return (
      <React.Fragment>

        <section className="rbac-body">
          {this.state.showModal && this.renderModal(this.state.modal)}


          <button type="button" className="trades-dtls__verify-docs" onClick={() => this.openModal('ADD_GROUPS', this.state.data)}>Add Group</button>
          <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
            <MaterialTable

              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Created By', field: 'createdBy' },
                {
                  title: 'Created', field: 'createdAt', defaultSort: 'desc',
                  render: rowData =>
                    <div>
                      <span
                        className="trades__overflow">{moment(rowData.createdAt).format(DATEFORMAT)}</span>
                    </div>

                },
                { title: 'Description', field: 'description' },
                {
                  title: 'Action',
                  sorting: false,
                  render: rowData => <div className="action-btn">
                    <Tooltip title="Manage Users" placement="top-start" arrow >
                      <Link className="btn-icon btn-circle-icon btn-accept" to={`/adminManagement/group-userlist/${rowData.id}/${rowData.name}`}>
                        <FontAwesomeIcon icon={faUserEdit} />

                      </Link>
                    </Tooltip>
                    <Tooltip title="Manage Permissions" placement="top-start" arrow >
                      <Link className="btn-icon btn-circle-icon btn-info" to={`/adminManagement/permission/${rowData.id}/${rowData.name}`}>
                        {/* <i className="fas fa-key"></i> */}
                        <FontAwesomeIcon icon={faKey} />


                      </Link>
                    </Tooltip>
                    <Tooltip title="Edit Group" placement="top-start" arrow >

                      <a className="btn-icon btn-circle-icon btn-warning" href="#/" onClick={() => this.openModal('EDIT_GROUPS', rowData)}><FontAwesomeIcon icon={faEdit} /></a>

                    </Tooltip>

                    <Tooltip title="Delete Group" placement="top-start" arrow  >
                      <a className="btn-icon btn-circle-icon btn-danger btn-icon-res" href="#/" onClick={() => this.openModal('DELETE_GROUPS', rowData)}><FontAwesomeIcon icon={faTrash} /></a>
                    </Tooltip>
                    <Tooltip title="Duplicate" placement="top-start" arrow  >
                      <a className="btn-icon btn-circle-icon btn-secondary btn-icon-res" href="#/" onClick={() => this.openModal('CLONE_GROUPS', rowData)}> <FontAwesomeIcon icon={faClone} /></a>
                    </Tooltip>
                  </div>
                }

              ]}
              data={this.getItems()}
              title="Groups"
              icons={{
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                SortArrow: ArrowUpward,
                Add: Add,
                Edit: Edit,
                DeleteOutline: DeleteOutline,
                ResetSearch: Clear,
                SupervisorAccountIcon: SupervisorAccountIcon,
              }}
              options={{
                rowStyle: (rowData, index) => {
                  const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                  return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                },
                pageSize: 10,
                thirdSortClick: false
              }}
            />
          </Preloader>
        </section>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    usermanagment: state.usermanagment,
    loading: state.loading.groupLoading,
    company: state.account.token
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      preloadGrouplistByComapny,
      addUserGroup,
      editUserGroup,
      deleteGroup,
      cloneGroup,

      navigate: path => push('/user-groups' + path)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
