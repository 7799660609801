import React from 'react';
import PropTypes from 'prop-types';
import DetailTableRow from './DetailTableRow';
import DetailTableHead from './DetailTableHead';

import moment from 'moment';

/**
 * Short description. (use period)
 *
 * @since  05/02/20
 * @access public
 *
 * @type     React.Component
 * @property {type} key Description.
 *
 * @member   {type} realName
 * @memberof className
 */

const defaultOptions = {
    showHeader: true,
    firstColumnHeader: "Field name",
    fixWidth: true,
    highlightDiff: true,
}

class DetailTable extends React.Component{

    constructor(props){
        super(props);
        this.getRow = this.getRow.bind(this);
        this.arraysEqual = this.arraysEqual.bind(this);
        this.datesEqual = this.datesEqual.bind(this);
        this.specificationsEqual = this.specificationsEqual.bind(this);
    }

    /**
     * Iterates over the series of data and return an array of 'Cell' objects ({content:<Contet of the cell>, className:<className to apply on the cell>>})
     * The cell contains the formatted value of the field 'field' given in parameter
     * @param {String} field name of the field
     */
    getCellList(field){
        let cellList = [{ content : field.label, className: (typeof field.tdClass === "string")?field.tdClass:"" }];
        /* Fill row with the values of each series */
        this.props.series.forEach((serie, index) => {
            let content = "-";
            let className = "cell-default";
            let rawValue = "";
            if(serie.data){
                rawValue = serie.data[field.key];
                if(typeof field.format === "function"){
                    content = field.format(rawValue, serie, index, this.props.series);
                }else{
                    content = (rawValue !== undefined)?rawValue:"";
                }
                //CSS class of the cell
                if(typeof field.tdClass === "function"){
                    className = field.tdClass(rawValue, serie);
                }else if(field.tdClass){
                    className = field.tdClass;
                }
            }
            cellList.push({content, rawValue, className}); 
        });
        return cellList;
    }
    //optionalDocument difference highlight if exist
    arraysEqual(arr1, arr2) {
        if(arr1 !== undefined && arr2 !==undefined && arr1 !== null && arr2 !==null){
            if(arr1.length !== arr2.length)
                return false;
            for(var i = arr1.length; i--;) {
                if(arr1[i].title !== arr2[i].title)
                    return false;
            }
        }
       return true;
    }

    specificationsEqual(current, initial){
        if(initial !== undefined && current !==undefined && initial !== null && current !==null){
            if(initial.length !== current.length) return false;
            const diffItems = [];
            for(let i = 0; i < current.length; i++) {
                let spec = null
                if(current[i].name !== 'custom'){
                    spec = initial.find(initObj => initObj.name === current[i].name);
                }else{
                    spec = initial.find(initObj => initObj.label === current[i].label);
                }
                if(!spec || (spec && spec.value !== current[i].value)) diffItems.push(current[i]);
            }
            return diffItems.length === 0;
        }
       return true;
    }

    datesEqual(currentValue, initialValue){
        if((!initialValue && currentValue) || (initialValue && !currentValue)) return false;
        if(currentValue && initialValue){
            return moment(currentValue).isSame(initialValue);
        }
        return true;
    }

    getRow(field){
        const cellList = this.getCellList(field);
        let className = "";

        /* Highlight differences */
        if(field.highlightDiff !== false){
            let i = cellList.length-1;
            while(i>1){
                if( cellList[i].rawValue!==cellList[1].rawValue){
                    className = "table-info";
                    // To Refactor
                    if(field.key==="optionalDocuments"){
                        if(this.arraysEqual(cellList[i].rawValue,cellList[1].rawValue)){
                            className = "";
                        }else{
                            className = "table-info"; 
                        }
                    }
                    if(field.key==="specifications"){
                        if(this.specificationsEqual(cellList[i].rawValue, cellList[1].rawValue)){
                            className = "";
                        }else{
                            className = "table-info"; 
                        }
                    }
                    if(field.key==="bidValidateDate"){
                        if(this.datesEqual(cellList[i].rawValue, this.props.series[0].data.validateDate)){
                            className = "";
                        }
                    }
                   break;
                }
                i--;
            }
        }else if(field.trClass){
            className = field.trClass;
        }
        return { cellList , className };
    }


    render(){
        const {fieldList, series, options } = this.props;
        const {fixWidth, showHeader, firstColumnHeader} = { ...defaultOptions, ...options}

        return (
            <div className="trades-dtls__table-section px-0">
                <table className="table table-sm table-hover mb-1 table-light table-striped">
                    {showHeader &&
                    <thead>
                        <tr>
                            <DetailTableHead content={firstColumnHeader}/>
                            {series.map((serie,index)  => <DetailTableHead key={index} content={serie.title}/>)}
                        </tr>
                    </thead>}
                    <tbody>
                        { fieldList.map((field, index) => {
                            const { cellList, className } = this.getRow(field);
                            return <DetailTableRow key={index}
                                index = { index }
                                fixWidth = {fixWidth}
                                cellList = {cellList}
                                className = {className}/>
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

DetailTable.propTypes = {
    options: PropTypes.object,
    series: PropTypes.array.isRequired,
    fieldList: PropTypes.arrayOf( PropTypes.shape({
        field: PropTypes.string,
        name: PropTypes.string
    }))
};

export default DetailTable;