import React from 'react';
import { DATEFORMAT, TRADE_STATUS, STEPS } from '../../../../services/service.values';
import { FertilizerService } from '../../../../services/service.fertilizer';
import moment from 'moment-timezone';
import { EnumsService, PortService } from '../../../../services/service.utils';
import NumberFormat from 'react-number-format';

import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';

const COUNTRIES = EnumsService.countries();
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

const renderPortLabel = value => {
    let label = "";
    if (value) {
        label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
    }
    return label;
}

const DocumentShippingAdvice = ({ trade, bills, userName, isModal, status }) => {
    status = status || trade.status;
    const showError = !isModal && STEPS.indexOf(status) < STEPS.indexOf(TRADE_STATUS.ADVICE);
    return (
        <div className={`shipping-advice-form${isModal ? ' shipping-advice-form1--modal' : ''}`}>
            {showError
                ? 'Shipping advice has not been issued yet'
                : Object.keys(bills).map(shipmentId => (
                    <React.Fragment key={shipmentId}>
                        <h3 className="modal__heading pb-2">Bill Of Lading
                                No. {bills[shipmentId].BillNumber}</h3>
                        <div className="modal__label1 text-center pb-3" style={{ lineHeight: 'initial' }}>
                            Contract No.{' '}
                            <b>{trade.tradeRefId || trade.id}{' '}</b>
                            dated {' '}
                            <b>{moment(trade.acceptedAt).format(DATEFORMAT)}{' '}</b>
                            covering{' '}
                            <TradeQuantityFormatter trade={trade} />{' '}
                            <React.Fragment>
                                <span></span>{FERTILIZER_TYPE[trade.fertilizerType] || trade.fertilizerType}<br />
                            </React.Fragment>

                        </div>
                        <div className="modal__textarea modal__textarea_dark textarea"
                            id={`shipment-advice-text-${shipmentId}`}>
                            <p>
                                <span className="bold">Dear Sir</span>,
                                </p>
                            <p>
                                U.U.R. and in complete fulfilment of the captioned contract we tender
                                hereby as follows:<br />
                                <span
                                    className="bold bold--underline"><NumberFormat value={bills[shipmentId].QuantCleanOnBoard} displayType={'text'} thousandSeparator={true}/></span> MT
                                    of{' '}
                                <span
                                    className="bold bold--underline">{bills[shipmentId].PackGoodsDescript}</span> shipped
                            on
                                    MV <span
                                    className="bold bold--underline">{bills[shipmentId].VessVoyage}</span> dated{' '}
                                <span className="bold bold--underline">
                                    {moment(bills[shipmentId].ShippedOnBoard).format(DATEFORMAT)}
                                </span>{' '}
                                shipped from{' '}
                                {trade.incoterm === 'FOB' ? <span className="bold bold--underline">{renderPortLabel(trade.loadPort)}, {COUNTRIES[trade.loadCountry]}</span> : <span className="bold bold--underline">{COUNTRIES[trade.origin]}</span>}{' '}
                                to{' '}
                                <span className="bold bold--underline">
                                    {renderPortLabel(trade.destPort)}, {COUNTRIES[trade.destCountry]}
                                </span>
                            </p>

                            {trade.incoterm === 'FOB' && (
                                <p>Kindly cover the marine insurance for this parcel.</p>
                            )}
                            <p>With kind regards.</p>
                            <p>
                                <span className="bold">{userName}</span>
                            </p>
                        </div>
                    </React.Fragment>
                ))
            }
        </div>
    );
};

export default DocumentShippingAdvice;