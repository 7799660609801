import React from 'react';
import Preloader from '../../../../components/preloader/Preloader';
import './confirmModal.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";


/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 */
class ConfirmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            show: false,
        }
    }

    handleOnShow = (e) => {
        if (this.props.buttonHandler) {
            this.props.buttonHandler()
        }
        if (this.props.showCondition) {
            this.setState({ show: this.props.showCondition() });
        } else {
            this.setState({ show: true });
        }
    }

    handleOnCancel = (e) => {
        this.setState({ show: false });
        if (this.props.onCancel) {
            this.props.onCancel();

        }

    }

    handleOnConfirm = (e) => {
        this.setState({ disabled: true });
        const { closeOnSubmit = true } = this.props;
        if (typeof this.props.onConfirm === 'function') {
            let confirmResult = this.props.onConfirm();
            if (confirmResult instanceof Promise) {
                confirmResult
                    .then(d => {
                        closeOnSubmit && this.setState({
                            show: false,
                            disabled: false
                        });
                    }).catch(() => {
                        this.setState({ disabled: false });
                    })
            } else {
                this.setState({ disabled: false });
                closeOnSubmit && this.setState({ show: false });
            }
        } else {
            this.setState({
                show: false,
                disabled: false
            });
        }
    }


    render() {
        const {
            disabled,
            title,
            children,
            loading = false,
            modalWrapperClass = "",
            modalBodyClass = "",
            buttonClass = "btn btn-primary",
            buttonLabel = "default button",
            buttonConfirmClass = "btn--blue",
            buttonConfirmLabel = <React.Fragment><FontAwesomeIcon icon={faCheck} />&nbsp;Confirm</React.Fragment>,
            showButtonConfirm = true,
            buttonCancelClass = "btn--grey",
            buttonCancelLabel = <React.Fragment><FontAwesomeIcon icon={faTimes} />&nbsp;Back</React.Fragment>,
            showCancelButton = true,
            modalFootarClass = ""
        } = this.props;

        return (<React.Fragment>
            <button disabled={disabled} className={buttonClass} type="button" onClick={this.handleOnShow}>{buttonLabel}</button>
            {(this.state.show || this.props.show) && <div className="modal__container confirm-modal">
                <div className={`modal__wrapper px-0 py-0 modal-scroll ${modalWrapperClass}`}>
                    <div className="modal-header">
                        {title && <h5 className="modal-title w-100">{title}</h5>}
                        <button type="button" className="close" data-dismiss="modal" onClick={this.handleOnCancel} aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className={`modal-body ${modalBodyClass}`}>

                        {children}
                    </div>
                    <div className={`modal-footer ${modalFootarClass}`}>
                        {showCancelButton &&
                            <button className={`btn btn-confirm-modal ${buttonCancelClass}`} type="button" onClick={this.handleOnCancel} >
                                {buttonCancelLabel}
                            </button>}
                        {showButtonConfirm &&
                            <button className={`btn btn-confirm-modal ${buttonConfirmClass}`} disabled={this.state.disabled} type="button" onClick={this.handleOnConfirm} >
                                <Preloader loadingStyle="dots" loading={loading}>
                                    <span>{buttonConfirmLabel}</span>
                                </Preloader>
                            </button>}
                    </div>
                </div>
            </div>}
        </React.Fragment>)
    }

}

export default ConfirmModal;