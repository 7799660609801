import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserList, inviteUser, activeUser, inactiveUser } from '../../modules/module.adminManagement';
import { push } from 'react-router-redux';

import './adminManagement-style.scss';
import Preloader from '../components/preloader/Preloader';
import '../components/form/FormInputField.scss';
import ViewUserPermission from './ViewUserPermission';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

class UserList extends Component {
    state = {
        showModal: false,
        userID: "",
        data: {},
        errors: [],
        inProgress: false
    };

    componentWillMount = () => {
        this.fetchFunction();
    };

    fetchFunction = () => {
        this.props.getUserList();
        this.setState({});
    };

    getItems = () => {
        let userList = this.props.usermanagment.userList;
        return userList;
    };
    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, data: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            input: '',
            modalData: {},
            signatureModal: {},
            errors: []
        });
    };
    handleChangeInput = e => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value },errors: [] });
    };
    validate(data) {
        const regexEmail = /^([A-Za-z0-9_\-.+-])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        const errors = {};

        if (regexEmail.test(data.email) === false) {
            errors["email"] = "Incorrect email";
        }
        // let userList = this.props.usermanagment.userList;
        // if (userList.some(u => u.email === data.email)) {
        //     errors["email"] = "This user already exists in the system.";
        // }

        for (let key in data) {
            if (data[key].length === 0) errors[key] = `${key} is required.`;
        }

        return errors;
    }

    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({ errors });

        if (Object.keys(errors).length === 0) {
            this.setState({ inProgress: true });
            this.props.inviteUser(this.state.data, response => {
                if (response.data.error === "Duplicate User") {
                    errors["email"] = "This user already exists in the system.";
                    this.setState({ errors });
                } else {
                    this.closeModal();
                }
                this.setState({ inProgress: false });
            });

        }
    };
    handleStatus = (userData) => {
        if (userData.status === 'ACTIVE') {
            this.props.inactiveUser(userData.userID);
        }
        else {
            this.props.activeUser(userData.userID);
        }

    };

    renderModal = (whatModal) => {
        switch (whatModal) {

            case 'INVITE_USER':
                return (

                    <div className="modal__container">

                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={this.handleSubmit

                            }>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Invite New User</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>

                                <div className="modal-body">
                                    <div className="create-req__wrapper">
                                        <div className="form-input">
                                            <label className="label">Email</label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                                className={`input input_higher ${this.state.errors.email  && "input_error"}`}
                                                onChange={this.handleChangeInput}
                                            />
                                        </div>
                                    </div>

                                    <div className="create-req__wrapper">
                                        {this.state.errors.email && <div className="counterparty__error">{this.state.errors.email} </div>}
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                            </button>

                                    <button type="submit" onClick={this.handleSubmit} className="btn-popup btn-submit">
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <span><FontAwesomeIcon icon={faCheck} /> Submit</span>
                                        </Preloader>

                                    </button>


                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'VIEW_PERMISSION':

                return (

                    <div className="modal__container">

                        <form className="modal__wrapper px-0 py-0">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <div className="modal-header-center"><h5 className="modal-title">View User Permission</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <ViewUserPermission id={this.state.data.userID} />
                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'CHANGE_STATUS':

                return (

                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.handleStatus(this.state.data)

                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                </div>
                                <div className="modal-body">
                                    <p className="center-text">If you click on Confirm, User will become Active/Inactive and will have no more login access to Fertx platform in case of Inactive and can access Fertx Platform in case of Active.<br />

                                                      Are you sure, you want to Active/Inactive this user?
                                                   </p>
                                </div>

                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                            </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        <FontAwesomeIcon icon={faCheck} /> Confirm
                            </button>

                                </div>
                            </div>
                        </form>

                    </div>
                );

            default:
                break;
        }
    };

    returnStatusNumber = (status) => {

        switch (status) {

            case 'ACTIVE':
                return 'badge badge-accepted'

            case 'INACTIVE':
                return 'badge badge-rejected'

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return 'badge badge-new'

            default:
                break;
        }
    };

    returnStatusClassChange = (status) => {

        switch (status) {

            case 'ACTIVE':
                return 'btn-icon btn-circle-icon btn-warning'

            case 'INACTIVE':
                return 'btn-icon btn-circle-icon btn-warning'

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return 'diaplay:none'

            default:
                break;
        }
    };

    returnStatusiconChange = (status) => {

        switch (status) {

            case 'ACTIVE':
                return faUserPlus

            case 'INACTIVE':
                return faUserPlus

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return ''

            default:
                break;
        }
    };

    returnStatusClassViewPermission = (status) => {

        switch (status) {

            case 'ACTIVE':
                return 'btn-icon btn-circle-icon btn-accept'

            case 'INACTIVE':
                return 'diaplay:none'

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return 'diaplay:none'

            default:
                break;
        }
    };

    returnStatusiconViewPermission = (status) => {

        switch (status) {

            case 'ACTIVE':
                return faEye

            case 'INACTIVE':
                return ''

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return ''

            default:
                break;
        }
    };


    returnLabel = (status) => {

        switch (status) {

            case 'ACTIVE':
                return 'ACTIVE'

            case 'INACTIVE':
                return 'INACTIVE'

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return 'NOT VERIFIED'

            default:
                break;
        }
    };
    returnLabel2 = (status) => {

        switch (status) {

            case 'ACTIVE':
                return 'ACTIVE'

            case 'INACTIVE':
                return 'INACTIVE'

            case 'WAITING_FOR_EMAIL_VERIFICATION':
                return ''

            default:
                break;
        }
    };

    render() {

        return (
            <React.Fragment>

                <section className="rbac-body">
                    {this.state.showModal && this.renderModal(this.state.modal)}

                    <button type="button" className="trades-dtls__verify-docs" onClick={() => this.openModal('INVITE_USER', this.state.sendInviteUser)}>Invite User</button>
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                        <MaterialTable
                            columns={[
                                { title: 'First Name', field: 'firstName' },
                                { title: 'Last Name', field: 'lastName' },
                                { title: 'Email', field: 'email' },
                                { title: 'Role', field: 'userType' },
                                {
                                    title: 'Status', field: 'status',
                                    render: rowData => <div className="action-btn">
                                        <span className={this.returnStatusNumber(rowData.status)} >{this.returnLabel(rowData.status)}
                                        </span> &nbsp;
                                    </div>
                                },
                                {
                                    title: 'Action',
                                    sorting: false,
                                    render: rowData => <div className="action-btn">

                                        <Tooltip title="View Permission " placement="top-start" arrow >
                                            <span className={this.returnStatusClassViewPermission(rowData.status)} onClick={() => this.openModal('VIEW_PERMISSION', rowData)}>
                                                {rowData.status === 'ACTIVE' ? <FontAwesomeIcon icon={faEye} /> : null}</span>
                                        </Tooltip>

                                        <Tooltip title="Change User Status " placement="top-start" arrow >

                                            <span className={this.returnStatusClassChange(rowData.status)} onClick={() => this.openModal('CHANGE_STATUS', rowData)}>
                                                {rowData.status === 'ACTIVE' || rowData.status === 'INACTIVE' ? <FontAwesomeIcon icon={faUserPlus} /> : null} </span>
                                        </Tooltip>

                                    </div>
                                }
                            ]}
                            data={this.getItems()}
                            title="Users"
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                Add: Add,
                                Edit: Edit,
                                DeleteOutline: DeleteOutline,
                                ResetSearch: Clear,
                                SaveIcon: SaveIcon
                            }}
                            options={{
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                },
                                pageSize: 10,
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </section>

            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        usermanagment: state.usermanagment,
        loading: state.loading.groupLoading
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            inviteUser,
            getUserList,
            activeUser,
            inactiveUser,
            navigate: path => push('/user-list' + path)
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(UserList);
