import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { push } from "react-router-redux";
import { Layout } from "../index";
import { login } from "../../../modules/module.account";
import { checkIndexVersion } from "../../../services/service.utils";
import "./login.scss";
import logo from "../../../static/img/logo_login.png";

import FaEnvelopeO from "react-icons/lib/fa/envelope-o";
import FaLock from "react-icons/lib/fa/lock";
import Preloader from "../../components/preloader/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

class LoginClass extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        email: localStorage.fertxEmail || '',
        password: "",
        rememberEmail: localStorage.fertxRememberEmail === "true" ? true : false
      },
      errors: [],
      showPassword: false
    };
  }

  componentDidMount = () => {
    checkIndexVersion();
  }

  handleChangeInput = e => {
    const name = e.target.name;
    const value = name === "rememberEmail" ? e.target.checked : e.target.value;
    this.setState({ 
      data: { 
        ...this.state.data,
        [name]: value 
      }
    });
  };

  togglePasswordVisibility = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  validate(data) {
    const regexEmail = /^([A-Za-z0-9_\-.+-])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    const errors = {};

    if (regexEmail.test(data.email) === false) {
      errors["email"] = "Incorrect email";
    }

    for (let key in data) {
      if (data[key].length === 0) errors[key] = `${key} is required.`;
    }

    return errors;
  }

  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.login();
    }
  };

  login = () => {
    this.props.login(this.state.data, d => {
      if (d !== null) {
        if(this.state.data.rememberEmail){
          localStorage.fertxEmail = this.state.data.email;
          localStorage.fertxRememberEmail = "true";
        }else{
          localStorage.fertxEmail = "";
          localStorage.fertxRememberEmail = "false";
        }
        this.props.navigate("/");
      }
    });
  };

  render() {
    const serverError = this.props.account.error.errors;
    return (
      <Layout {...this.props} className="login-container">
        <div className="login">
          <ul className="account-tabs">
            <li className="account-tab active">Login</li>
          </ul>
          <div className="login-wrapper">
            <div className="col-12 login-logo">
              <img src={logo} alt="Fertilizer exchange logo" />
            </div>
            <p className="login-text">Please enter Email Address and Password to sign in</p>
            <ul className="errors">
              {serverError && <li>{serverError} </li>}
              {this.state.errors.email && <li>{this.state.errors.email} </li>}
              {this.state.errors.password && <li>{this.state.errors.password} </li>}
            </ul>
            <div className="form-container">
              <form onSubmit={this.handleSubmit} autoComplete='off'>
                <div className="form-input login-input-wrapper">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={this.state.data.email}
                    placeholder="Email"
                    className={`input input_higher ${(this.state.errors.email || serverError) && "input_error"}`}
                    onChange={this.handleChangeInput}
                  />
                  <FaEnvelopeO className="login-input-icon" />
                </div>
                <div className="form-input login-input-wrapper">                
                  <input
                    id="password"
                    name="password"
                    value={this.state.data.password}
                    type= {this.state.showPassword ? "text":"password"}
                    placeholder="Password"
                    className={`input input_higher ${(this.state.errors.password || serverError) &&
                      "input_error"}`}
                    onChange={this.handleChangeInput}
                  />
                  <i
                    className="login-input-password-icon"
                    onClick={this.togglePasswordVisibility}
                    title={this.state.showPassword ? "Hide password" : "Show password"}
                  >
                    {this.state.showPassword? eye : eyeSlash}
                  </i>
                  <FaLock className="login-input-icon" />
                </div>
                <div>
                  <input 
                    id="rememberEmail"
                    name="rememberEmail"
                    checked={this.state.data.rememberEmail}
                    type="checkbox"
                    onChange={this.handleChangeInput}
                  />{' '}
                  <label htmlFor="rememberEmail">Remember Email (Not recommended on shared computers)</label>
                </div>
                <div className="form-group" style={{ textAlign: "right" }}>
                  <p>
                    <Link to="/account/forgot" className="fp-link login-forgot">
                      Forgot Password?
                    </Link>
                  </p>
                  <button type="submit" className="btn btn--blue">
                    <Preloader loadingStyle="dots" loading={this.props.loading}>
                      <span>Login</span> to the platform
                    </Preloader>
                  </button>
                  <p className="login-text">
                    Don't have an account?
                    <Link to="/account/register" className="fp-link login-forgot"> Register</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
  loading: state.loading.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      navigate: path => push(path)
    },
    dispatch
  );

export const Login = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginClass);
