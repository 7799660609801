import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
//API service
import { getCompany } from '../../../modules/module.platformAdmin';
import { approveRequest } from '../../../modules/module.platformAdmin';
import { reapproveRequest } from '../../../modules/module.platformAdmin';
import { rejectRequest } from '../../../modules/module.platformAdmin';


import AdminNav from './AdminNav';
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';
import FormTextareaField from '../../components/form/FormTextareaField';
import { EnumsService } from "../../../services/service.utils";

const Countries = EnumsService.countries();

class CompanyJoiningDetail extends Component {

    state = {
        showModal: false,
        modal: "",
        saveData: {
            reason: ""
        },
        errors: {},
        inProgress: false
    }

    componentWillMount = () => {
        this.props.getCompany(this.props.match.params.id);
    }

    openModal = (modal) => {
        this.setState({ showModal: true, modal: modal });
    };

    renderModal = () => {
        return (
            <div className="modal__container">

                <form className="modal__wrapper px-0 py-0"
                    onSubmit={e => {
                        e.preventDefault();
                        if (this.validate()) {
                            this.rejectRequest();
                        }
                    }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">Reject Joining Request</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row mb-4 mt-4">
                                <div className="col-md-3 offset-md-1">
                                    <label className="control-label">Enter Rejection Reason<span className="reqfield">*</span></label>
                                </div>
                                <div className="col-md-7">
                                    <FormTextareaField
                                        validation={this.state.errors}
                                        name="reason"
                                        maxLength="300"
                                        rows="10"
                                        value={this.state.saveData.reason}
                                        onChange={this.saveReason}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-center mb-4">
                            <a href="#/" className="btn btn-reject lh-1" data-dismiss="modal" onClick={this.closeModal} ><FontAwesomeIcon icon={faTimes} /> Close</a>
                            <button type="submit" disabled={this.state.inProgress} className="btn btn-approve lh-1">
                                <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                    <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Reject</span>
                                </Preloader> </button>
                        </div>
                    </div>
                </form>
            </div>
        );

    };

    closeModal = () => {
        this.setState({
            showModal: false,
            saveData: { reason: "" },
            errors: {}
        });
    };

    saveReason = e => {
        this.setState({ saveData: { reason: e.target.value } });
    };

    validate = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { reason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    approveRequest = () => {
        this.setState({ inProgress: true });
        this.props.approveRequest(this.props.match.params.id, response => {
            this.setState({ inProgress: false });
            if(response && response.success){
                this.props.navigate('/company/joiningList');
            }
        })
    }

    reapproveRequest = () => {
        this.setState({ inProgress: true });
        this.props.reapproveRequest(this.props.match.params.id, response => {
            this.setState({ inProgress: false });
            if(response && response.success){
                this.props.navigate('/company/joiningList');
            }
        })
    }

    rejectRequest = () => {
        this.setState({ inProgress: true });
        this.props.rejectRequest(this.props.match.params.id, this.state.saveData, response => {
            this.setState({ inProgress: false });
            if (!response || !response.success) {
                this.closeModal();
            } else {
                this.props.navigate('/company/joiningList');
            }
        })
    }

    render() {
        let classOnDasboardKyc = '';
        if(!this.props.showIconLabel){
            classOnDasboardKyc = 'maximum';
        }
        let company = this.props.company ? this.props.company.company : this.props.company;
        return (
            <React.Fragment>
                {this.state.showModal && this.renderModal()}
                <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                    <AdminNav />
                    {company ? (
                        <div className={"dashboard-kyc " + classOnDasboardKyc}>
                            <div className="content">
                                <h3 className="mb-1">Company Joining Request</h3>
                                <nav className="mb-2">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink exact to="/" id="drop-menu">
                                                Dashboard
                                    </NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink exact to="/company/joiningList" id="drop-menu">
                                                Joining Requests
                                    </NavLink>
                                        </li>
                                        <li className="breadcrumb-item active">Request Detail</li>
                                    </ol>
                                </nav>
                                <div className="content-wrappper box-shadow">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Company Registered Name
                            </div>
                                                <div className="detail-text">
                                                    {company.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Company Registration No
                            </div>
                                                <div className="detail-text">
                                                    {company.regNumber}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Country
                            </div>
                                                <div className="detail-text">
                                                    {`${Countries[company.country]}`}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Type of Company
                            </div>
                                                <div className="detail-text">
                                                    {company.companyType}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Registration Contact Name
                            </div>
                                                <div className="detail-text">
                                                    {company.contact}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Registration Contact Email
                            </div>
                                                <div className="detail-text">
                                                    {company.email}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Registration Contact Telephone No.
                            </div>
                                                <div className="detail-text">
                                                    {company.phone}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Company Website
                            </div>
                                                <div className="detail-text">
                                                    {company.site ? company.site : '--'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="details-wrapper">
                                                <div className="detail-heading">
                                                    Brief Company Profile
                            </div>
                                                <div className="detail-text">
                                                    {company.about}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 mb-3">
                                        <button className="btn btn-back lh-1" onClick={() => {this.props.history.goBack()}} id="drop-menu">
                                            <FontAwesomeIcon icon={faAngleLeft} /> Back
                                        </button>
                                        {company.kycStatus === "NEW" ?
                                            (
                                                <React.Fragment>
                                                    <a href="#/"
                                                        onClick={() => this.openModal(company)}
                                                        className="btn btn-reject lh-1" >
                                                        <FontAwesomeIcon icon={faTimes} /> Reject
                                                    </a>
                                                    <a disabled={this.state.inProgress} href="#/" onClick={this.approveRequest} className="btn btn-approve lh-1">
                                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                            <span><FontAwesomeIcon icon={faCheck} className="mr-0" /> Approve</span>
                                                        </Preloader>
                                                    </a>
                                                </React.Fragment>)
                                            : ''}
                                        {company.kycStatus === "REQUEST_REJECTED" ?
                                            (
                                                <React.Fragment>
                                                    <a disabled={this.state.inProgress} href="#/" onClick={this.reapproveRequest} className="btn btn-reapprove lh-1">
                                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                                            <span><FontAwesomeIcon icon={faRedoAlt} className="mr-0" /> Re-approve</span>
                                                        </Preloader>
                                                    </a>
                                                </React.Fragment>)
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''
                    }
                </Preloader>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.platformAdmin.company,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompany, approveRequest, reapproveRequest, rejectRequest,
            navigate: path => push(path)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyJoiningDetail);
