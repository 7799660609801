import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLogForAdmin } from '../../../modules/module.platformAdmin';
import { DATEFORMATHOURS } from '../../../services/service.values';
import { NavLink } from 'react-router-dom';
import { DOCUMENT_NAMES } from '../../admin/trades/services/documents.service';
import moment from 'moment-timezone';

//style
import '../admin.scss';

//component
import AdminNav from './AdminNav';
import Footer from '../../components/footer/Footer';
import Preloader from '../../components/preloader/Preloader';

//material table
import MaterialTable from 'material-table';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

class AdminAuditLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentWillMount = () => {
        this.props.getLogForAdmin();
    };

    setLoading = loading => {
        this.setState(prevState => ({
            ...prevState,
            loading
        }));
    };

    displayActionBy = item => {
        let parts = item.comment.split('@');
        switch (parts[0]) {
            case 'trade.contract.created':
            case 'trade.signed':
            case 'trade.confirmDocuments':
            case 'trade.instructions':
            case 'trade.advice':
            case 'trade.closed':
                return <span className="audit__overflow">System</span>;
            default:
                return (
                    <span className="audit__overflow">
                        {' '}
                        {item.authorName}
                        <br />
                        <small>{item.companyName}</small>
                    </span>
                );
        }
    };

    getDocumentsLink(docType, text, shipmentId = this.props.trade.shipments[0].id) {
        const docName = DOCUMENT_NAMES[docType] ? DOCUMENT_NAMES[docType] : docType;
        return <React.Fragment>
            {(docType === 'DocInstructionsID') ? (
                <React.Fragment>
                    {docName}
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        {docName}
                    </React.Fragment>
                )
            }
            {' '}
            {text}
        </React.Fragment >
    }

    displayAction(item) {
        if (item.comment) {
            let parts = item.comment.split('@');
            switch (parts[0]) {
                case 'trade.cancel':
                    return <React.Fragment>Trade is cancelled</React.Fragment>;
                case 'trade.created':
                    return (
                        <React.Fragment>
                            Trade created from Trade Request{' '}
                            {item.tradeRefID ? item.tradeRefID : item.trade}
                        </React.Fragment>
                    );
                case 'trade.accepted':
                    return <React.Fragment> Trade {item.tradeRefID ? item.tradeRefID : item.trade} is accepted by {item.companyName}</React.Fragment>;
                case 'trade.sign':
                    return <React.Fragment> Contract is signed by {item.companyName}</React.Fragment>;
                case 'trade.contract.created':
                    return (
                        <React.Fragment>
                            Contract
                            is Created
                        </React.Fragment>
                    );
                case 'trade.advice':
                    return <React.Fragment>Trade status changed from Shipping Advice to Documents
                        Required</React.Fragment>;
                case 'trade.instructions':
                    return (
                        <React.Fragment>
                            Trade status changed from
                            {' '}Documentary Instructions{' '}
                            Required to Shipping Advice Pending
                        </React.Fragment>
                    );
                case 'trade.documents':
                    return (
                        <React.Fragment>Trade status changed from Shipping Advice Pending to Documents
                            Required</React.Fragment>
                    );
                case 'trade.confirmDocuments':
                    return <React.Fragment>Trade status changed from Documents Required to Payment
                        Required</React.Fragment>;
                case 'trade.close':
                    return <React.Fragment>{item.author} close a trade</React.Fragment>;
                case 'trade.signed':
                    return (
                        <React.Fragment>
                            Contract is signed by all
                        </React.Fragment>
                    );
                case 'trade.payment':
                    return <React.Fragment>{item.author} notifies that payment is done</React.Fragment>;
                case 'trade.acceptPayment':
                    return <React.Fragment>{item.author} viewed payment</React.Fragment>;
                case 'trade.closed':
                    return <React.Fragment>Trade is closed</React.Fragment>;
                case 'trade.vesselNominated':
                    return <React.Fragment>Vessel nomination is created</React.Fragment>;
                case 'trade.vesselAccepted':
                    return <React.Fragment>Vessel nomination is accepted</React.Fragment>;
                case 'trade.vesselAcceptedWithNewLaycanDate':
                    return <React.Fragment>Vessel nomination is accepted with new laycan dates</React.Fragment>;
                case 'trade.vesselFinalised':
                    return <React.Fragment>Vessel nomination is finalised</React.Fragment>;
                case 'trade.vesselNominatedComplete':
                    return <React.Fragment>Vessel nomination is approved</React.Fragment>;
                case 'trade.laycanDatesChanged':
                    return <React.Fragment>Laycan dates had changed</React.Fragment>;
                case 'trade.vesselRejected':
                    return <React.Fragment>Vessel nomination is rejected</React.Fragment>;
                case 'trade.substituteVesselNominated':
                    return <React.Fragment>Substitute Vessel nomination is created</React.Fragment>;
                case 'trade.substituteVesselAccepted':
                    return <React.Fragment>Substitute Vessel nomination is accepted</React.Fragment>;
                case 'trade.substituteVesselAcceptedWithNewLaycanDate':
                    return <React.Fragment>Substitute Vessel nomination is accepted with new laycan dates</React.Fragment>;
                case 'trade.substituteVesselFinalised':
                    return <React.Fragment>Substitute Vessel nomination is Finalised</React.Fragment>;
                case 'trade.substituteVesselNominatedComplete':
                    return <React.Fragment>Substitute Vessel nomination is approved</React.Fragment>;
                case 'trade.substituteVessellaycanDatesChanged':
                    return <React.Fragment>Laycan dates had changed for Substitute Vessel</React.Fragment>;
                case 'trade.substituteVesselRejected':
                    return <React.Fragment>Substitute Vessel nomination is rejected</React.Fragment>;    
                case 'shipment.created':
                    return <React.Fragment>Shipment is created</React.Fragment>
                case 'trade.document.upload': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is filled in', shipmentId);
                }
                case 'trade.instructions.indicator.updated':
                    return <React.Fragment> Trade document instruction had been updated to {parts[2]==="GLOBAL" ?"GLOBAL set BOL" : "Multiple set Bol"}  </React.Fragment>;
                case 'trade.document.upload.updated': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'has been updated', shipmentId);
                }
                case 'trade.document.approve': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is approved', shipmentId);
                }
                case 'trade.document.reject': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is rejected', shipmentId);
                }
                case 'trade.document.releaseForBuyer': {
                    const docType = parts[1];
                    const shipmentId = (parts[2] && parts[2] !== '0') ? parts[2] : null;
                    return this.getDocumentsLink(docType, 'is shared for review with the buyer', shipmentId);
                }
                case 'trade.document.invoice': {
                    const docName = DOCUMENT_NAMES.INVOICE;
                    return (
                        <React.Fragment>
                            {docName}
                            {' is filled in'}
                        </React.Fragment>
                    )
                }
                case 'trade.inspectionQuote.created': {
                    const quoteType = parts[1].toLowerCase();
                    return <React.Fragment>New request for {quoteType} inspection quote is created</React.Fragment>;
                }

                case 'trade.inspectionQuote.upload': {
                    const quoteType = parts[1];

                    return <React.Fragment>{quoteType === "PROVISIONAL" ? "Provisional" : "Final"} inspection quote file is uploaded</React.Fragment>;
                }
                case 'trade.inspectionQuote.status': {
                    const quoteType = parts[1];
                    const status = parts[3];
                    return <React.Fragment>{quoteType === "PROVISIONAL" ? "Provisional" : "Final"} inspection quote status updated as {status}</React.Fragment>;
                }
                case 'trade.proformaInvoice.created':
                    return <React.Fragment>Proforma invoice has been issued by {item.companyName}</React.Fragment>
                case 'trade.proformaInvoice.upload':
                    return <React.Fragment>Downpayment proof has been uploaded by {item.companyName}</React.Fragment>
                case 'trade.proformaInvoice.status.updated':
                    let lastIndex = parts.length - 1;
                    if (parts[lastIndex] === 'PAYMENT_NOT_CONFIRMED') {
                        return <React.Fragment>Downpayment not confirmed by {item.companyName}</React.Fragment>
                    } else if (parts[lastIndex] === 'PAYMENT_CONFIRMED') {
                        return <React.Fragment>Downpayment confirmed by {item.companyName}</React.Fragment>
                    } else if (parts[lastIndex] === 'EFFECTED') {
                        return <React.Fragment>Downpayment date confirmed by {item.companyName}</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.created':
                    if (parts[1] === 'PROVISIONAL') {
                        if (parts[3] === 'SPOT_OFFER') {
                            return <React.Fragment>New provisional shipping quote requested using Maersk offer api</React.Fragment>
                        } else if (parts[3] === '') {
                            return <React.Fragment>Provosional shipping quote requested for vessel</React.Fragment>
                        }
                    } else if (parts[1] === 'FINAL') {
                        return <React.Fragment>Freight booking requested</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.ediFIle':
                    if (parts[3] === 'ACK') {
                        return <React.Fragment>Frieght request processed by Maersk</React.Fragment>
                    } else if (parts[3] === 'BOOKCONF') {
                        return <React.Fragment>Your freight booking request is confirmed by maersk</React.Fragment>
                    }else if (parts[4] === 'WAITING_CONFIRMATION') {
                        return <React.Fragment>Shipment booking is waiting to get confirmed by maersk</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.upload':
                    if (parts[1] === 'PROVISIONAL') {
                        return <React.Fragment>Provisional shipping quote has been uploaded by service provider</React.Fragment>
                    } else if (parts[1] === 'FINAL') {
                        return <React.Fragment>Final shipping offer has been uploaded by service provider</React.Fragment>
                    }
                    break;
                case 'trade.shippingQuote.status':
                    if (parts[1] === 'PROVISIONAL') {
                        if (parts[4] === 'REJECTED') {
                            return <React.Fragment>Shipping quote is rejected</React.Fragment>
                        } else if (parts[4] === 'CANCELLED') {
                            return <React.Fragment>Shipping quote is canceled</React.Fragment>
                        } else if (parts[4] === 'ACCEPTED') {
                            return <React.Fragment>Shipping quote is accepted</React.Fragment>
                        }

                    } else if (parts[1] === 'FINAL') {
                        if (parts[4] === 'REJECTED') {
                            return <React.Fragment>Final shipping offer is rejected</React.Fragment>
                        } else if (parts[4] === 'CANCELLED') {
                            return <React.Fragment>Final shipping offer is canceled</React.Fragment>
                        } else if (parts[4] === 'ACCEPTED') {
                            return <React.Fragment>Final shipping offer is accepted</React.Fragment>
                        } else if (parts[4] === 'COMPLETED') {
                            return <React.Fragment>Final shipping offer is completed</React.Fragment>
                        }
                    }
                    break;
                    case 'trade.uncleanInspectionReport.approved':
                        return <React.Fragment>Unclean Daily Inspection Report is approved</React.Fragment>;
                    case 'trade.uncleanInspectionReport.rejected':
                        return <React.Fragment>Unclean Daily Inspection Report is rejected</React.Fragment>;
                case 'trade.repayment':
                    return <React.Fragment>{item.companyName} has requested to reconfirm the commercial invoice payment</React.Fragment>
                case 'contractAmendment.created':
                    return <React.Fragment>Contract amendment term request submitted</React.Fragment>;
                case 'contractAmendment.accepted':
                    return <React.Fragment> Contract amendment term accepted </React.Fragment>;
                case 'contractAmendment.declined':    
                    return <React.Fragment>Contract amendment term rejected</React.Fragment>;
                case 'trade.letterCredit.created':
                    return <React.Fragment>L/C Draft has been issued by {item.companyName}</React.Fragment>
                case 'trade.letterCredit.updated':
                    return <React.Fragment>L/C Draft has been updated by {item.companyName}</React.Fragment>
                case 'trade.letterCredit.uploaded':
                    return <React.Fragment>L/C Draft has been uploaded by {item.companyName}</React.Fragment>
                case 'trade.letterCredit.reupload':
                    return <React.Fragment>L/C Draft has been again uploaded by {item.companyName}</React.Fragment>
                case 'trade.letterCredit.status.updated':
                    let lastIndex1 = parts.length - 1;
                    if (parts[lastIndex1] === 'CONFIRMED') return <React.Fragment>L/C Draft has been confirmed by {item.companyName}</React.Fragment>
                    if (parts[lastIndex1] === 'REJECTED') return <React.Fragment>Changes has been requested by {item.companyName} in L/C Draft</React.Fragment>
                    break;
                case 'trade.avalizedDraft.created':
                    return <React.Fragment>Avalised Draft has been issued by {item.companyName}</React.Fragment>
                case 'trade.avalizedDraft.updated':
                    return <React.Fragment>Avalised Draft has been updated by {item.companyName}</React.Fragment>
                case 'trade.avalizedDraft.uploaded':
                    return <React.Fragment>Avalised Draft has been uploaded by {item.companyName}</React.Fragment>
                case 'trade.avalizedDraft.reupload':
                    return <React.Fragment>Avalised Draft has been again uploaded by {item.companyName}</React.Fragment>
                case 'trade.avalizedDraft.status.updated':
                    let lastIndex2 = parts.length - 1;
                    if (parts[lastIndex2] === 'CONFIRMED') return <React.Fragment>Avalised Draft confirmed by {item.companyName}</React.Fragment>
                    if (parts[lastIndex2] === 'REJECTED') return <React.Fragment>Changes has been requested by {item.companyName} in Avalised Draft</React.Fragment>
                    break;  
                case 'trade.confirmedLC.created':
                    return <React.Fragment>Confirmed L/C Draft has been issued by {item.companyName}</React.Fragment>
                case 'trade.confirmedLC.updated':
                    return <React.Fragment>Confirmed L/C Draft has been updated by {item.companyName}</React.Fragment>
                case 'trade.confirmedLC.uploaded':
                    return <React.Fragment>Confirmed L/C Draft has been uploaded by {item.companyName}</React.Fragment>
                case 'trade.confirmedLC.reupload':
                    return <React.Fragment>Confirmed L/C Draft has been again uploaded by {item.companyName}</React.Fragment>
                case 'trade.confirmedLC.status.updated':
                    let lastIndex3 = parts.length - 1;
                    if (parts[lastIndex3] === 'CONFIRMED') return <React.Fragment>Confirmed L/C Draft confirmed by {item.companyName}</React.Fragment>
                    if (parts[lastIndex3] === 'REJECTED') return <React.Fragment>Changes has been requested by {item.companyName} in Confirmed L/C Draft</React.Fragment>
                    break;
                default:
                    break;
            }
        }
        return item.comment;
    }

    render() {
        let classOnDasboardKyc = '';
        if (!this.props.showIconLabel) {
            classOnDasboardKyc = 'maximum';
        }
        return (
            <React.Fragment>
                <AdminNav />
                <div className={"dashboard-kyc " + classOnDasboardKyc}>
                    <div className="content">
                        <h3 className="mb-1">Audit Log</h3>
                        <nav className="mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">

                                    <NavLink exact to="/" id="drop-menu">
                                        <a href="#/">Dashboard</a>
                                    </NavLink>

                                </li>
                                <li className="breadcrumb-item active">Audit Log</li>
                            </ol>
                        </nav>
                        <div className="content-wrappper-kyc box-shadow">
                            <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'TxHash', field: 'transactionId', sorting: false,
                                            render: rowData =>
                                                <span className="audit__overflow transactionId">{rowData.transactionId}</span>
                                        },
                                        {
                                            title: 'Date', field: 'date', defaultSort: 'desc',
                                            render: rowData =>
                                                <span className="audit__overflow">{moment(rowData.date).format(DATEFORMATHOURS)}</span>
                                        },
                                        {
                                            title: 'Action', field: 'action',
                                            render: rowData => <span className="audit__overflow">{this.displayAction(rowData)}</span>
                                        },
                                        {
                                            title: 'Action By', field: 'actionBy',
                                            render: rowData => <span className="audit__overflow">{this.displayActionBy(rowData)}</span>
                                        }
                                    ]}
                                    data={this.props.log ? this.props.log.items : []}

                                    title={<nav className="mb-4">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">

                                                <NavLink exact to="/" id="drop-menu">
                                                    <a href="#/">Dashboard</a>
                                                </NavLink>

                                            </li>
                                            <li className="breadcrumb-item active">Audit Log</li>
                                        </ol>
                                    </nav>}

                                    icons={{
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward,
                                    }}

                                    options={{
                                        pageSize: 10,
                                        search: false,
                                        pageSizeOptions: [10, 20, 30, 40, 50],
                                        rowStyle: (rowData, index) => {
                                            const backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                            return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                        },
                                        thirdSortClick: false
                                    }}
                                />
                            </Preloader>
                        </div>
                    </div>
                </div>
                <div className={"footer-left dashboard-kyc " + classOnDasboardKyc}>
                    <Footer />
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.requestLoading,
        log: state.platformAdmin.log,
        isloading: state.loading.kycLoading,
        showIconLabel: state.platformAdmin.showIconLabel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getLogForAdmin
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AdminAuditLog);