import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import account from './module.account';
import app from './module.app';
import trade from './module.trade';
import tender from './module.tender';
import loading from './module.loading'
import monitoring from './module.monitoring'
import notifications from './module.notifications'
import usermanagment from './module.adminManagement';
import platformAdmin from './module.platformAdmin';
import shipmentQuote from './module.shipmentQuote';
import inspectionQuote from './module.inspectionQuote';
import contract from './module.contract';
import nomination from './module.nomination';

const appReducer = combineReducers({
    routing: routerReducer,
    account,
    app,
    trade: trade,
    tender,
    loading,
    monitoring,
    notifications,
    usermanagment,
    platformAdmin,
    shipmentQuote,
    inspectionQuote,
    contract,
    nomination
});

const rootReducer = (state, action) => {

    return appReducer(state, action);
}

export default rootReducer;
