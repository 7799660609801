import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { PlatformAdminApi } from '../../../services';
import { KYC_STATUS } from '../../../services/service.values';
import logo from '../../../static/img/logo.png';
import { AppErrors } from '../../components/errors';
import { LoadingRegisterData } from '../../../modules/module.loading';
import Preloader from '../../components/preloader/Preloader';

import { CompanyDetails } from '../../components/kyc/form/CompanyDetails';
import { BankDetails } from '../../components/kyc/form/BankDetails';
import { CompanyAdminDetails } from '../../components/kyc/form/CompanyAdminDetails';
import { UboDetails } from '../../components/kyc/form/UboDetails';
import { ShareholderDetails } from '../../components/kyc/form/ShareholderDetails';
import { DirectorDetails } from '../../components/kyc/form/DirectorDetails';
import { AssociatedCompanies } from '../../components/kyc/form/AssociatedCompanies';
import { TradeReferences } from '../../components/kyc/form/TradeReferences';
import { Documents } from '../../components/kyc/form/Documents';
import { logout } from '../../../modules/module.account';
import { getCompany } from '../../../modules/module.account';
import Footer from '../../components/footer/Footer';

import '../admin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // it's weight is 200 kb
import { faCheck, faChevronRight, faChevronLeft, faUser, faSignOutAlt, faSave } from "@fortawesome/free-solid-svg-icons";

export const STEP1 = "step_1";
export const STEP2 = "step_2";
export const STEP3 = "step_3";

class KycCompanyWizard extends Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);
        let isEmpty = value => !value || value === undefined || value === '';
        let isEmail = value => {
            // eslint-disable-next-line
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(String(value).toLowerCase().trim());
        };

        let company = {
            registeredAddress: {},
            mailingAddress: {},
            isMailingAddressDifferent: false,
            ubos: [],
            bank: {},
            companyAdmin: {},
            shareholders: [],
            directors: [],
            associatedCompanies: [],
            tradeReferences: []
        };

        this.initField(company, 'name', 'Registered Company Name', '', isEmpty);
        this.initField(company, 'regNumber', 'Company Registration No.', '', isEmpty);
        this.initField(company, 'companyType', 'Company Type', '', isEmpty);
        this.initField(company, 'tax', 'TAX/VAT Number ', '', isEmpty);
        this.initField(company, 'site', 'Company Web Site', '', '');
        this.initField(company, 'email', 'Registration Contact Email', '', isEmail);
        this.initField(company, 'phone', 'Registration Contact Telephone No', '', isEmpty);
        this.initField(company, 'addressProof', 'Upload Proof of Company Address', null, isEmpty);
        this.initField(company, 'certificateOfIncorporation', 'Certificate of Incorporation/Registry Extract', null, isEmpty);
        this.initField(company, 'articlesOfIncorporation', 'Articles of Incorporation (By laws)', null, isEmpty);

        this.initField(company.registeredAddress, 'line1', 'Registered Address Line 1', '', isEmpty);
        this.initField(company.registeredAddress, 'line2', 'Registered Address Line 2', '', '');
        this.initField(company.registeredAddress, 'city', 'Registered Address City', '', isEmpty);
        this.initField(company.registeredAddress, 'postal', 'Registered Address Zip Code', '', '');
        this.initField(company.registeredAddress, 'country', 'Registered Address Country', '', isEmpty);

        this.initField(company.mailingAddress, 'line1', 'Mailing Address Line 1', '', isEmpty);
        this.initField(company.mailingAddress, 'line2', 'Mailing Address Line 2', '', '');
        this.initField(company.mailingAddress, 'city', 'Mailing Address City', '', isEmpty);
        this.initField(company.mailingAddress, 'postal', 'Mailing Address Zip Code', '', '');
        this.initField(company.mailingAddress, 'country', 'Mailing Address Country', '', isEmpty);


        ///Bank Reference
        this.initField(company.bank, 'name', 'Bank Name', '', isEmpty);
        this.initField(company.bank, 'managerName', 'Relationship Manager Name', '', isEmpty);
        this.initField(company.bank, 'email', 'Email', '', isEmail);
        this.initField(company.bank, 'address', 'Address', '', isEmpty);
        this.initField(company.bank, 'phone', 'Phone No.', '', isEmpty);

        ///Company Admin details
        this.initField(company.companyAdmin, 'name', 'Name', '', isEmpty);
        this.initField(company.companyAdmin, 'title', 'Position', '', isEmpty);
        this.initField(company.companyAdmin, 'email', 'Email', '', isEmail);
        this.initField(company.companyAdmin, 'phone', 'Phone No.', '', isEmpty);
        this.initField(company.companyAdmin, 'mobile', 'Mobile No.', '', isEmpty);
        this.initField(company.companyAdmin, 'addressProofType', 'Select Proof of Address', '', isEmpty);
        this.initField(company.companyAdmin, 'addressProof', ' Upload Proof of Address', null, isEmpty);
        this.initField(company.companyAdmin, 'passport', 'Upload Passport', null, isEmpty);
        this.initField(company.companyAdmin, 'boardResolution', 'Corporate Authorisation for Company Admin', null, isEmpty);


        this.initDynamicField(company, "ubos");
        this.initDynamicField(company, "shareholders");
        this.initDynamicField(company, "directors");
        this.initDynamicField(company, "associatedCompanies");
        this.initDynamicField(company, "tradeReferences");

        this.state = {
            showModal: false,
            modal: "",
            company: company,
            isDeclarationSigned: false,
            canSubmit: false,
            canSave: false,
            required: {
                registeredAddress: {},
                mailingAddress: {},
                ubos: [],
                bank: {},
                companyAdmin: {},
                shareholders: [],
                directors: [],
                tradeReferences: []
            },
            serverError: '',
            shareholdersPercentError: '',
            ubosPercentError: '',
            singleShareholdersPercentError: '',
            singleUboPercentError: '',
            showGreetings: false,
            activeStep: "step_1",
            name: '',
            noShareholder: false,
            isSaveloading: false
        };
    }

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }

    initDynamicField(company, fieldName) {
        let isEmpty = value => !value || value === undefined || value === '';
        // let isEmail = value => {
        //     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //     return !re.test(String(value).toLowerCase());
        // };
        switch (fieldName) {
            case "shareholders": {
                company.shareholders.push({});
                this.initField(company.shareholders[company.shareholders.length - 1], 'name', 'Shareholder Name (Ownership >10%)', '', isEmpty);
                this.initField(company.shareholders[company.shareholders.length - 1], 'percentage', 'Owner Percentage (10-100%)', '', isEmpty);
                break;
            }
            case "ubos": {
                company.ubos.push({});
                this.initField(company.ubos[company.ubos.length - 1], 'name', 'Name of Ultimate Beneficial Owner', '', isEmpty);
                this.initField(company.ubos[company.ubos.length - 1], 'percentage', 'UBO Percentage (0-100%)', '', isEmpty);
                this.initField(company.ubos[company.ubos.length - 1], 'passport', 'UBO Passport', null, isEmpty);
                break;
            }
            case "directors": {
                company.directors.push({});
                this.initField(company.directors[company.directors.length - 1], 'name', 'Name', '', isEmpty);
                this.initField(company.directors[company.directors.length - 1], 'title', 'Position', '', isEmpty);
                this.initField(company.directors[company.directors.length - 1], 'addressProofType', 'Select Proof of Address', '', isEmpty);
                this.initField(company.directors[company.directors.length - 1], 'addressProof', 'Upload Proof of Address', null, isEmpty);
                this.initField(company.directors[company.directors.length - 1], 'passport', 'Upload Passport', null, isEmpty);
                break;
            }
            case "associatedCompanies": {
                // if()
                company.associatedCompanies.push({});
                this.initField(company.associatedCompanies[company.associatedCompanies.length - 1], 'name', 'Registered Company Name', '', '');
                this.initField(company.associatedCompanies[company.associatedCompanies.length - 1], 'country', 'Registered Country', '', '');
                this.initField(company.associatedCompanies[company.associatedCompanies.length - 1], 'relationship', 'Company Relationship', '', '');
                this.initField(company.associatedCompanies[company.associatedCompanies.length - 1], 'otherRelationship', 'If Other,(Specify)', '', '');
                break;
            }
            case "tradeReferences": {
                company.tradeReferences.push({});
                this.initField(company.tradeReferences[company.tradeReferences.length - 1], 'name', 'Company Name', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1], 'contact', 'Contact Name', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1], 'phone', 'Phone No.', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1], 'email', 'Email', '', '');
                company.tradeReferences[company.tradeReferences.length - 1].address = {}
                this.initField(company.tradeReferences[company.tradeReferences.length - 1].address, 'line1', 'Address Line 1', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1].address, 'line2', 'Address Line 2', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1].address, 'city', 'City', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1].address, 'postal', 'Zip Code', '', '');
                this.initField(company.tradeReferences[company.tradeReferences.length - 1].address, 'country', 'Country', '', '');
                break;
            }
            default:
                return null;
        }
    }

    addDynamicField(fieldName) {
        let company = this.state.company;
        this.initDynamicField(company, fieldName);
        this.setState({ company: company });
    }

    removeDynamicField(fieldName, index) {
        let company = this.state.company;
        company[fieldName].splice(index, 1);
        this.setState({ company: company });
    }

    componentWillMount = () => {
        this.props.getCompany(this.props.account.user.companyId, (company) => {
            this.updateState(company);
        });
    }

    componentDidMount() {
    }

    updateState(comp) {
        if (comp) {
            let company = this.state.company;
            if (comp.companyKYC) {
                company = this.getState(company, comp.companyKYC);
            } else {
                company.name.value = comp.name;
                company.regNumber.value = comp.regNumber;
                company.companyType.value = comp.companyType;
                company.site.value = comp.site;
                company.email.value = comp.email;
                company.phone.value = comp.phone;
                company.companyAdmin.name.value = comp.contact;
                company.companyAdmin.phone.value = comp.phone;
                company.companyAdmin.email.value = comp.email;
            }

            this.setState({ company: company });

            if (comp.kycStatus === KYC_STATUS.PENDING || comp.kycStatus === KYC_STATUS.EDITING || comp.kycStatus === KYC_STATUS.DRAFT) {
                this.setState({ canSubmit: true });
            }

            if (comp.kycStatus === KYC_STATUS.PENDING || comp.kycStatus === KYC_STATUS.DRAFT) {
                this.setState({ canSave: true });
            }
        }
    }

    getState(company, container) {
        Object.keys(container).map(key => {
            if (key === "isMailingAddressDifferent") {
                company[key] = container[key];
            } else if (company[key]) {
                if (typeof (container[key]) == "object") {
                    if (Array.isArray(container[key])) {
                        container[key].map((k, i) => {
                            if (!company[key][i]) {
                                this.initDynamicField(company, key);
                            }
                            company[key][i] = this.getState(company[key][i], k);
                            return k;
                        });

                    } else {
                        if (key === "addressProof" || key === "passport" ||
                            key === "certificateOfIncorporation" || key === "articlesOfIncorporation"
                            || key === "boardResolution") {
                            company[key].value = container[key]
                        } else {
                            let objValue = this.getState(company[key], container[key]);
                            company[key] = objValue;
                        }

                    }
                } else {
                    company[key]["value"] = container[key];
                }
            }
            return false;

        });
        if (!this.props.company.companyKYC.shareholders[0].name) {
            this.setState({
                noShareholder: true
            })
        }
        return company;
    }

    setCompanyField(name, value, state, index, subState) {
        if (name !== '') {
            let required = {
                registeredAddress: {},
                mailingAddress: {},
                ubos: [],
                bank: {},
                companyAdmin: {},
                shareholders: [],
                directors: [],
                tradeReferences: []
            };

            if (Object.keys(required).length > 0) {
                this.setState({ required: required });
            }
            let company = this.state.company;
            let field;
            if (state) {
                if (index !== undefined) {
                    if (subState) {
                        field = company[state][index][subState][name];
                    } else {
                        field = company[state][index][name];
                    }
                } else {
                    field = company[state][name];
                }
            } else {
                field = company[name];
            }
            if (name === 'percentage' && value.includes('-', 0)) {
                field.value = ''
            } else if (value < 0) {
                field.value = Math.abs(value);
            } else {
                field.value = value;
            }
            this.setState({ company: company });
        }
    }

    setMailingAddress(value) {
        let company = this.state.company;
        company.isMailingAddressDifferent = value;
        this.setState({ company: company });
    }

    stepChange = (step) => {
        window.scrollTo(0, 0);
        if (!this.validate(this.state.company, false)) {
            return true;
        }

        let currentStep = this.state.activeStep;
        currentStep = currentStep.split("_")[1];
        let activeStepStr;


        if (step === 'plus') {
            activeStepStr = "step_" + (parseInt(currentStep) + 1);
            this.setState({ activeStep: activeStepStr });
        }
        if (step === 'minus') {
            activeStepStr = "step_" + (parseInt(currentStep) - 1);
            this.setState({ activeStep: activeStepStr });
        }
    }


    validate(container, submitFlag) {
        let required = {
            registeredAddress: {},
            mailingAddress: {},
            ubos: [],
            bank: {},
            companyAdmin: {},
            shareholders: [],
            directors: [],
            tradeReferences: [],
            percentageFlag: false,
            returnFlag: true
        };
        let sharePercent = 0;
        let ubosPercent = 0;
        this.setState({
            shareholdersPercentError: '',
            singleShareholdersPercentError: '',
            singleUboPercentError: '',
            ubosPercentError: '',
            required: required,
            serverError: ''
        });
        if (submitFlag) {
            required = this.validateStepFirst(container, required, sharePercent, ubosPercent);
            required = this.validateStepTwo(container, required, sharePercent, ubosPercent);
            required = this.validateStepThree(container, required, sharePercent, ubosPercent);
        } else if (this.state.activeStep === STEP1) {
            required = this.validateStepFirst(container, required, sharePercent, ubosPercent);
        } else if (this.state.activeStep === STEP2) {
            required = this.validateStepTwo(container, required);
        } else if (this.state.activeStep === STEP3) {
            required = this.validateStepThree(container, required);
        }
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
        }
        if (!required["returnFlag"]) {
            this.setState({ serverError: "Note that there is a validation error on the form. Please check the information provided in various steps and correct any items highlighted in red" });
            window.scrollTo(0, 0);
        }
        if (required["percentageFlag"]) {
            required["returnFlag"] = false;
            window.scrollTo(0, 0);
        }
        return required["returnFlag"];
    }

    validateStepFirst = (container, required, sharePercent, ubosPercent) => {

        Object.keys(container).map(key => {
            if (key === "associatedCompanies" ||
                key === "tradeReferences" ||
                key === "certificateOfIncorporation" ||
                key === "articlesOfIncorporation" ||
                key === "companyAdmin" ||
                key === "bank" ||
                key === "directors") {
                return null;
            } else {
                if (key === "mailingAddress" ||
                    key === "isMailingAddressDifferent") {
                    if (key === "mailingAddress" && container.isMailingAddressDifferent) {
                        Object.keys(container[key]).map(childKey => {
                            let v = container[key][childKey];
                            if (v && v.required && v.required(v.value)) {
                                required[key][childKey] = v;
                                required["returnFlag"] = false;
                            }
                            return childKey;
                        });
                    }
                } else {
                    if (Array.isArray(container[key])) {
                        container[key].map((k, i) => {
                            required[key].push({});
                            Object.keys(container[key][i]).map(inKey => {
                                if (key === "shareholders" && this.state.noShareholder) {

                                } else {
                                    let v = container[key][i][inKey];
                                    if (v && v.required && v.required(v.value)) {
                                        required[key][i][inKey] = container[key][i][inKey];
                                        required["returnFlag"] = false;
                                    } else {
                                        if (key === "shareholders" && inKey === "percentage") {
                                            let percent = container[key][i][inKey].value
                                            if (parseFloat(percent) < 10) {
                                                this.setState({
                                                    singleShareholdersPercentError: "Shareholder's percentage should be minimum 10%"
                                                });
                                                required["percentageFlag"] = true;
                                            }
                                            sharePercent = parseFloat(sharePercent) + parseFloat(percent);
                                            if (sharePercent > 100) {
                                                this.setState({
                                                    shareholdersPercentError: "Shareholders total percentage should not be more than 100%"
                                                });
                                                required["percentageFlag"] = true;
                                            }
                                        }
                                        if (key === "ubos" && inKey === "percentage") {
                                            let percent = container[key][i][inKey].value;
                                            if (parseFloat(percent) <= 0) {
                                                this.setState({
                                                    singleUboPercentError: "UBO's percentage should be more than 0%"
                                                });
                                                required["percentageFlag"] = true;
                                            }
                                            ubosPercent = parseFloat(ubosPercent) + parseFloat(percent);
                                            if (ubosPercent > 100) {
                                                this.setState({
                                                    ubosPercentError: "UBOs  total percentage should not be more than 100%"
                                                });
                                                required["percentageFlag"] = true;
                                            }
                                        }
                                    }
                                }
                                return inKey;
                            });
                            return k;
                        });

                    } else {
                        if (key === "name" || key === "regNumber" ||
                            key === "companyType" || key === "tax" ||
                            key === "email" || key === "phone" ||
                            key === "addressProof"
                        ) {
                            let v = container[key];
                            if (v && v.required && v.required(v.value)) {
                                required[key] = v;
                                required["returnFlag"] = false;
                            }
                        } else {
                            Object.keys(container[key]).map(childKey => {
                                let v = container[key][childKey];
                                if (v && v.required && v.required(v.value)) {
                                    required[key][childKey] = v;
                                    required["returnFlag"] = false;
                                }
                                return childKey;
                            });
                        }
                    }
                }
            }
            return key;
        });

        return required
    }

    validateStepTwo = (container, required) => {

        Object.keys(container).map(key => {
            if (key === "associatedCompanies" ||
                key === "tradeReferences" ||
                key === "shareholders" ||
                key === "ubos" ||
                key === "tradeReferences" ||
                key === "registeredAddress" ||
                key === "mailingAddress") {

            }
            else {
                if (Array.isArray(container[key])) {
                    container[key].map((k, i) => {
                        required[key].push({});
                        Object.keys(container[key][i]).map(inKey => {
                            let v = container[key][i][inKey];
                            if (v && v.required && v.required(v.value)) {
                                required[key][i][inKey] = container[key][i][inKey];
                                required["returnFlag"] = false;
                            }
                            return inKey;
                        });
                        return k;
                    });

                } else {
                    if (key === "certificateOfIncorporation" ||
                        key === "articlesOfIncorporation") {
                        let v = container[key];
                        if (v && v.required && v.required(v.value)) {
                            required[key] = v;
                            required["returnFlag"] = false;
                        }
                    } else {
                        Object.keys(container[key]).map(childKey => {
                            let v = container[key][childKey];
                            if (v && v.required && v.required(v.value)) {
                                required[key][childKey] = v;
                                required["returnFlag"] = false;
                            }
                            return childKey;
                        });
                    }
                }
            }
            return key;
        });

        return required
    }

    validateStepThree = (container, required) => {
        let isEmail = value => {
            // eslint-disable-next-line
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(String(value).toLowerCase());
        };
        Object.keys(container).map(key => {
            if (key === "tradeReferences") {
                container[key].map((k, i) => {
                    required[key].push({});
                    let v = container[key][i]["email"];
                    if (v.value && isEmail(v.value)) {
                        required[key][i]["email"] = v;
                        required["returnFlag"] = false;
                    }
                    return k;
                });
            }
            return key;
        });
        return required
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            if (typeof (container[key]) == "object") {
                if (Array.isArray(container[key])) {
                    result[key] = []
                    container[key].map((k, i) => {
                        result[key][i] = this.getStateValue(k);
                        return k;
                    });

                } else {
                    if (container[key].hasOwnProperty("value")) {
                        result[key] = container[key].value;
                    } else {
                        let objValue = this.getStateValue(container[key]);
                        result[key] = objValue;
                    }
                }
            }
            return false;
        });

        result["isMailingAddressDifferent"] = container["isMailingAddressDifferent"];

        if (container["isMailingAddressDifferent"] === false) {
            result["mailingAddress"] = result["registeredAddress"]
        }

        return result;
    }

    submit() {
        this.props.LoadingRegisterData(true);
        PlatformAdminApi.submitKYC(this.props.account.token.companyId, {
            kyc: this.getStateValue(this.state.company),
            email: this.props.account.user.email
        })
            .then((res) => {
                this.props.LoadingRegisterData(false);
                if (res.data.success) {
                    this.setState({
                        showGreetings: true
                    });
                } else {
                    this.setState({
                        serverError: AppErrors["admin_email_exist"]
                    });
                }
            }, () => {
                this.props.LoadingRegisterData(false);
                this.setState({
                    serverError: AppErrors["kyc_submit_error"]
                });
            })
            .catch(error => {
                this.props.LoadingRegisterData(false);
                this.setState({
                    serverError: AppErrors["kyc_submit_error"]
                });
            });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    onComplete = e => {
        e.preventDefault();

        if (this.validate(this.state.company, true)) {
            this.submit();
        }
    };

    onSaveDraft = e => {
        e.preventDefault();
        this.setState({ isSaveloading: true });
        PlatformAdminApi.saveKYCDraft(this.props.account.token.companyId, {
            kyc: this.getStateValue(this.state.company)
        })
            .then((res) => {
                this.setState({ isSaveloading: false });
                if (res.data.success) {
                    this.openModal("SAVE_DRAFT");
                }
            }, () => {
                this.setState({ serverError: AppErrors["kyc_save_draft_error"], isSaveloading: false });
            })
            .catch(() => {
                this.setState({ serverError: AppErrors["kyc_save_draft_error"], isSaveloading: false });
            });
    };

    fileUploadValidate = errorMessage => {
        this.setState({
            serverError: errorMessage
        });
    }

    setNoShareholder(value) {
        this.setState(prevState => {
            prevState.noShareholder = value;
            prevState.company["shareholders"].map((k, i) => {
                Object.keys(prevState.company["shareholders"][i]).map(inKey => {
                    prevState.company["shareholders"][i][inKey].value = "";
                    return inKey;
                });
                return k;
            });
            return prevState;
        })
    }

    openModal = (modal) => {
        this.setState({ showModal: true, modal: modal });
    };

    renderModal = (modalName) => {
        if (modalName === 'SHOW_TNC') {
            return (
                <div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title w-100">Fertilizer Exchange Terms and Conditions of Use</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeModal(false)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body terms-cond">
                                <h5> Fertilizer Exchange Terms and Conditions of Use	</h5>
                                <h5>
                                    About Fertilizer Exchange
				</h5>
                                <p>Fertilizer Exchange (“<b>Fertilizer Exchange</b>”) is a digital trading platform and online marketplace for buyers and sellers of physical fertilizer, and their service providers, operated by Fertx Pte Ltd Singapore , a company registered in Singapore under company number 201840509R and with our registered office at 4 Battery Road, Bank of China Building No 25-01, Singapore 049908 (“Fertx”). For the avoidance of doubt, all references in these Terms and Conditions of Use to “Fertilizer Exchange” or “Fertx” as the case may be refer to the same entity, i.e. Fertx Pte Ltd.
				</p>
                                <p>Fertilizer Exchange allows its registered users (“Authorized Users” and/or a “Company Person”) to conclude contracts for the sale of physical fertilizer through an online portal (the “<b>Fertilizer Exchange Platform</b>”).
				</p>
                                <h5>
                                    About these Terms and Conditions of Use ("Terms”)
				</h5>
                                <p>
                                    These Terms govern Authorized Users and/or any Company and/or any Company Person (hereafter, “you” or “your”) use of the Fertilizer Exchange Platform and their relationship with Fertx (hereafter “Fertx”, “Fertilizer Exchange”, “we” or “us”).
				</p>
                                <p>
                                    By registering for a Fertilizer Exchange ID or to become an Authorized User of the Fertilizer Exchange Platform you are entering into a legally binding contract subject to these Terms between you and Fertx (trading as Fertilizer Exchange). By using the Fertilizer Exchange Platform or registering a Fertilizer Exchange ID or becoming an Authorized User and/or Company Person you agree to accept these Terms, including but not limited to the Warranties and any disclaimers and Policies which appear on the Fertilizer Exchange Platform. If you do not agree to these Terms and the Warranties, you must not use the Fertilizer Exchange Platform. If you have any questions on the Terms, please contact support@Fertx.io
				</p>
                                <h5>Definitions</h5>
                                <p><b>"Agreement"</b> means these Terms.</p>
                                <p><b>"Audit Log"</b> means the audit trail of events produced pursuant to every transaction concluded on the Fertilizer Exchange Platform.
				</p>
                                <p><b>"Authorized User"</b> means each person identified to have access to the Fertilizer Exchange Platform by a Company who is the holder of a User ID. For the avoidance of doubt, Authorized Users include Buyers, Sellers and Service Providers, and their respective employees, delegates, servants and agents.
				</p>
                                <p>
                                    <b>"Bid"</b> means the price and conditions at which a Buyer offers to buy fertilizer made via a public or private Trade Request posted on the Fertilizer Exchange Platform. This also includes any "Counter Bid".
				</p>
                                <p>
                                    <b>"Buyer"</b> means any individual or business entity who is an Authorised User who buys or intends to buy fertilizer using the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Company"</b> also referred to as "Companies” means the entity you are registering through the Fertx Registration Process for access to the Fertilizer Exchange Platform;
				</p>
                                <p>
                                    <b>"Company Person"</b> means each of the Company’s subsidiaries, affiliates, directors, officers, agents, employees or other persons acting on the Company’s behalf;
				</p>
                                <p>
                                    <b>"Contract"</b> means the contract of sale which is concluded on the terms and specifications listed on the Fertilizer Exchange Platform and becomes binding on both parties (without any need for further signature or execution) once a Buyer’s Bid or Seller’s Offer is accepted by the relevant counterparty clicking the "Accept” button on the relevant screen of the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Exchange Fee"</b> means the payment(s) made by Fertilizer Exchange Authorized Users or Companies with a Fertilizer Exchange ID to Fertx for using the Fertilizer Exchange Platform to facilitate the conclusion of Contracts or the provision of services to Buyers and Sellers.
				</p>
                                <p>
                                    <b>"Goods"</b> means physical fertilizer.
				</p>
                                <p>
                                    <b>"Intellectual Property Rights"</b> means any and all copyright, database rights, trade marks, know-how and other intellectual property rights, with registered or unregistered, in any jurisdiction in or relating to Fertilizer Exchange, the Fertilizer Exchange Platform, its site, system or service.
				</p>
                                <p>
                                    <b>"Market"</b> means the set facilities contained on the Fertilizer Exchange Platform through which Buyers and Sellers can buy and sell fertilizer.
				</p>
                                <p>
                                    <b>"Offer"</b> means the price and conditions at which a Seller wishes to sell fertilizer made via a public or private Trade Request posted on the Fertilizer Exchange Platform. This also includes any “Counter Offer”.
				</p>
                                <p>
                                    <b>"Policy" or "Policies"</b> means any separate set of terms or Policies which also apply to the use of our Fertilizer Exchange Platform. These are our Cookies Policy, Data Protection Policy and any other set of terms or disclaimers which may appear on the site from time to time.
				</p>
                                <p>
                                    <b>"Fertilizer Exchange User ID"</b> or <b>"User ID"</b> means the account set up when a Company or Authorised User is registered for access to the Fertilizer Exchange Platform via the Fertx Registration Process</p>
                                <p>
                                    <b>"Fertilizer Exchange Password"</b> means the unique sequence of characters used to authenticate a Fertilizer Exchange User ID on the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Fertilizer Exchange Company Administrator"</b> means the person designated by any corporate entity using the Fertilizer Exchange Platform who can administer the User IDs of employees or associates of that entity including but not limited to addition, activation, deletion and deactivation of User IDs, passwords and restriction or variation of permissions.
				</p>
                                <p>
                                    <b>"Fertx Registration Process"</b> – the process to gain access to the Fertx Exchange Platform, which includes the Warranties.
				</p>
                                <p>
                                    <b>"Seller"</b> means any individual or business entity who is a Fertilizer Exchange Authorized User who sells or intends to sell fertilizer using the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Service"</b> means the service provided to Fertilizer Exchange Authorized Users or holders of a Fertilizer Exchange IDs by Fertx by way of the Fertilizer Exchange Platform or any combination or component thereof.
				</p>
                                <p>
                                    <b>"Service Providers"</b> are Fertilizer Exchange Users who are not Buyers or Sellers, but who provide services to the fertilizer trade, including, but not limited to, banks, shipping companies, freight forwarders, surveyors, and insurers. For the avoidance of doubt, where there is no agreement as to the applicability of any other terms, these Terms and Conditions shall apply, insofar as they may be relevant, to a Service Provider’s use of or participation on the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Smart Contract"</b> means the document setting out the terms of the binding contract of sale which is automatically generated once a Buyer’s Bid or Seller’s Offer is accepted by the relevant counterparty clicking the “Accept” button on the relevant screen of the Fertilizer Exchange Platform.
				</p>
                                <p>
                                    <b>"Trading Information"</b> means any information which may be provided by either a Seller or a Buyer or Service Provider in relation to their status, capacity to trade, fertilizer being sold and bought and any other information or documents exchanged between Seller and Buyer and/or any Service Provider, whether such information or document is displayed on or uploaded to the Fertilizer Exchange Platform or otherwise passed between Seller and Buyer.
				</p>
                                <p>
                                    <b>"Trade Request"</b> means the information contained in a Buyer’s Bid/Buyers’ buying interest or Seller’s Offer as the case may be.
				</p>
                                <p>
                                    <b>"Warranties"</b> means the “Company / Authorised User Warranties” set forth immediately following these Terms, which are incorporated herein by reference as if fully set forth herein and are subject to these Terms in all respects.
				</p>
                                <h5>
                                    Your use of the Fertilizer Exchange Platform
				</h5>
                                <ol start="1">
                                    <li>The Fertilizer Exchange Platform is provided to you for use subject to these Terms, Warranties and our Policies. Accordingly, by using the Fertilizer Exchange Platform you agree to be bound by these Terms, Warranties, our Policies and any Disclaimers.</li>
                                </ol>
                                <h5>Amendments to these Terms</h5>
                                <ol start="2">
                                    <li>We may update these Terms and the Warranties from time to time for legal or regulatory reasons or to facilitate the proper operation of the Fertilizer Exchange Platform. Any changes will be notified to you via the e-mail address provided by you on registration or via a suitable announcement on the Fertilizer Exchange Platform. If you continue to use the Fertilizer Exchange Platform after the date on which the change comes into effect, your use of the Fertilizer Exchange Platform will be deemed to constitute your agreement to be bound by the new Terms and Warranties.</li>
                                </ol>
                                <h5>Registration</h5>
                                <ol start="3">
                                    <li>To register on the Fertilizer Exchange Platform you must provide Fertx with all information and details as prompted by the registration screen during the Fertx Registration Process together with the Warranties and such further information as the Fertx may, in its absolute discretion request. You must ensure that the details provided by you on registration or at any time are correct and complete.</li>
                                    <li>You shall be solely liable for any losses, damage or claims arising out of your failure to provide and maintain accurate details or information.</li>
                                    <li>All personal data we collect from you, or that you provide to us, will be processed by us. Personal data is and shall at all times be dealt with as set out in our Data Protection Policy. We reserve the right to monitor all data, activities, communications including but not limited to the Audit Log for any purpose we deem fit in line with our Data Protection Policy.</li>
                                    <li>By registering as an Authorised User and/or Company Person and agreeing to the Warranties you warrant you have the consent and authority to register on behalf of yourself, or if employed as an employee or agent for another third party or organisation, you have the authority to trade on behalf of such third party or organisation and, to the extent applicable, to provide the required details.</li>
                                    <li>If you provide us with any information which is not true, complete or current or if we suspect that such information is not true or accurate, we have the right to terminate or suspend your account at our sole discretion.</li>
                                    <li>You must inform us immediately of any changes to the information that you provided when registering by updating your details so that we can communicate with you effectively.</li>
                                    <li>You are solely responsible for maintaining and updating your registration information, Warranties and User ID.</li>
                                    <li>If you wish to cancel your registration and terminate this Agreement then you may do so at any time (provided that you are not engaged in any transaction or dispute relating to a transaction, and/or providing that no exchange fees owed to Fertx are outstanding) by notifying your Fertilizer Exchange Company Administrator. Fertx may take up to ten (10) business days to complete the cancellation process.</li>
                                </ol>
                                <h5>Unique ID and Security</h5>
                                <ol start="11">
                                    <li>On completion of the Fertx Registration Process you will be allocated a User ID and you will create a password.</li>
                                    <li>In order to prevent fraud, it is a condition of this Agreement that you keep your unique User ID and password confidential and you agree not to disclose it or share it with anyone. If you know or suspect that someone else knows your User ID or password you undertake to notify your Fertilizer Exchange Company Administrator immediately. We will not be liable for any loss occasioned by any unauthorised user accessing your account.</li>
                                    <li>You shall ensure that all your employees comply with this Agreement and the Warranties and if you wish to withdraw any permissions granted to one or more of your employees or prevent an employee from accessing a particular User ID you must advise your Fertilizer Exchange Company Administrator who will take the necessary steps to deactivate that particular employee(s) via the Management Tool available to him. You will remain responsible for all actions carried out on the Fertilizer Exchange Platform by any such employee until such time as their permissions have been withdrawn or their user account has been deactivated by your Fertilizer Exchange Company Administrator and thereafter in respect of any activity on or in connection with the Fertilizer Exchange Platform.</li>
                                    <li>You are solely responsible for all actions taken on or in connection with the Fertilizer Exchange Platform using the User ID allocated to you or your employees, whether or not that action was taken by you or a person authorised by you, and whether or not that action has been carried out fraudulently, negligently or otherwise.</li>
                                    <li>If we have reason to believe that there has been or is likely to be a breach of security or misuse of the Fertilizer Exchange Platform, we may require you to change your User ID and/or password or we or your Fertilizer Exchange Company Administrator may suspend or de-activate your User ID or the User ID of your employees, servant or agents</li>
                                </ol>
                                <h5>Fertx’s right to cancel Contracts in the event of fraud, illegality or misuse</h5>
                                <ol start="16">
                                    <li>Fertx  may, in our absolute discretion, cancel or amend Contracts concluded via the Fertilizer Exchange Platform if we believe that this is necessary to maintain fair and orderly trading and/or the integrity of the Platform or where we are satisfied that the Contract concerned was illegal, concluded through fraud or through the misuse of a User ID. This will only be done in rarely and in exceptional circumstances, and the fact that a trade was made by mistake or in error will not usually be, by itself, a basis for cancelling or amending a Contract.</li>
                                    <li>Any request to us for a cancellation by an Authorised User and/or Company Person must be made as quickly as possible after the relevant Contract has been concluded to the following email address admin@Fertx.io. Any delay in making such a request will significantly reduce the likelihood that Fertx will cancel or amend the Contract.</li>
                                    <li>For the avoidance of doubt no Authorised User and/or Company Person will have the right to cancel or amend any Contract concluded on the Fertilizer Exchange Platform unilaterally. However, for the avoidance of doubt, nothing in this clause is to be taken as affecting any Authorised User’s and/or Company Person’s and/or Company’s contractual or legal rights of rejection or termination, or any other rights that user may legitimately have to treat itself as discharged from a Contract pursuant to its governing law.</li>
                                </ol>
                                <h5>Intellectual Property</h5>
                                <ol start="19">
                                    <li>The software and technology associated with and the content of the Fertilizer Exchange Platform is protected by copyright, trademarks, database rights and all other Intellectual Property Rights. You may retrieve, display the content of the Fertilizer Exchange Platform on a computer screen for your own legitimate business use, and/or print this, pursuant to your ordinary use of the Platform, provided you keep intact and respect all and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the Fertilizer Exchange Platform without written permission from Fertx. </li>
                                    <li>You must not (and must not permit any third party to) attempt to copy, adapt, reverse engineer, decompile, disassemble, modify, adapt or make error corrections to the Fertilizer Exchange Platform or to any software, coding or programming used on the Platform. </li>
                                    <li>Fertx reserves to itself and is the owner of all proprietary information and data relating to Authorised Users, Companies or Company Persons (“Customer Data”) and you are expressly forbidden from procuring or attempting to procure, collect or make any use of Customer Data. The prohibitions contained in this clause 21 do not preclude you from being able to use data from the Fertilizer Exchange Platform to contact other Authorised Users via the Fertilizer Exchange Platform for the purposes of  making Bids, Offers, or Trade Requests or concluding Contracts on the Fertilizer Exchange Platform.</li>
                                </ol>
                                <h5>Code of Conduct governing Use of the Fertilizer Exchange Platform</h5>
                                <ol start="22">
                                    <li>You hereby acknowledge that acceptance of an Offer or Bid on the Fertilizer Exchange Platform gives rise to a binding contractual obligation, on (i) the Buyer to buy the relevant parcel(s) of fertilizer and (ii) the Seller to sell the relevant parcel(s) of fertilizer, on the terms of the Contract. Accordingly, to preserve the reputation and integrity of the Fertilizer Exchange Platform you hereby agree to perform your obligations under any Contract concluded on it when these arise or when you are legitimately called upon by your counterparty to do so. However, for the avoidance of doubt, nothing in this clause or these Terms is to be taken as affecting your contractual or legal rights of rejection or termination, or any other rights you may legitimately have not to perform, or to suspend or withhold performance under the Contract or pursuant to its governing law.</li>
                                    <li>You shall take all proper care to avoid making misrepresentations to your counterparty in any Bid, Offer, Trade Request or negotiations, (whether conducted via the Fertilizer Exchange Platform’s Instant Chat functionality or otherwise). You represent, warrant and agree that you have obtained all the necessary copyright, trademark, trade secret, patent licences or permissions as may be required in for any material or information posted on or authorised to appear on the Fertilizer Exchange Platform.</li>
                                    <li>You agree to comply with all applicable regulatory, consumer, statutory, fiscal or other requirements associated with the trade including but not limited to those at the port of shipment and/or port of discharge as well as bribery, sanctions, anti-corruption, anti-competition and any other financial crime laws, in any applicable jurisdiction, and you undertake to notify us immediately if you become aware of or have concerns about any transaction or if you are in breach of your obligations under this clause in this regard.</li>
                                    <li>You may not use the Fertilizer Exchange Platform for any of the following purposes:
                                    <ol type="a">
                                            <li>disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material or otherwise breaching any laws in any relevant or applicable jurisdiction;</li>
                                            <li>facilitating conduct that constitutes a criminal offence, results in civil liability or otherwise breaches any applicable laws, regulations or code of practice, including but not limited to sanctions, laws relating to bribery and corruption and laws relating to money laundering and criminal activity and anti-competition;</li>
                                            <li>placing incorrect, misleading, false or otherwise fraudulent offers, bids or trades which may create or are likely to create a false or misleading impression as to the market for or price of fertilizer and/or making any misrepresentations in relation to your identity, legal entitlement or capacity to trade e.g. by making a Trade Request with no intention to conclude a Contract;</li>
                                            <li>interfering with any other Authorised Users’ use of the Fertilizer Exchange Platform; or </li>
                                            <li>making, transmitting or storing electronic copies of materials protected by copyright without the permission of the Fertilizer Exchange.</li>
                                        </ol></li>
                                    <li start="26">You will be responsible for any and all liabilities, whether direct, indirect or consequential arising out of your misuse of the Fertilizer Exchange Platform and/or breach of clauses 22-25 above, the Warranties, including any losses or costs incurred by Fertilizer Exchange in relation to the same.</li>
                                    <li>You may not use user information procured from the Fertilizer Exchange Platform to contact other Authorised Users other than via the Fertilizer Exchange Platform including but not limited to the purposes of making Bids, Offers, or Trade Requests or concluding Contracts other than through the Fertilizer Exchange Platform. Service Providers may contact Authorised Users via alternative means with the express consent of Fertilizer Exchange.</li>
                                    <li>Authorised Users can leave comments, feedback, and ratings to let other users know about their experience. You have 90 days from the date of completion of your transaction to leave your rating and comments. Before you leave a critical rating you should resolve disputes with us or other users amicably first. The Fertilizer Exchange Platform reserves the right to review feedback before it is posted, remove feedback that isn't directly related to the trade or violates our Terms. If your comments include any promotional content, obscene or abusive language, personal information your feedback is potentially subject to removal. If you breach this Code of Conduct the Fertilizer Exchange shall be entitled, in its sole discretion, to suspend you from the Fertilizer Exchange Platform and from further use of its system.</li></ol>
                                <h5>Availability of the Fertilizer Exchange Platform</h5>
                                <ol start="29">
                                    <li>Although Fertx aim to offer you the best service possible, we give no undertaking or warranty that the facilities offered by the Fertilizer Exchange Platform will meet your requirements. We also cannot guarantee that the service will be fault-free. Should you experience any difficulty in using the Platform you should report this to us at support@Fertx.io or the “GET SUPPORT” button and we will attempt to address the problem as soon as we reasonably can.</li>
                                    <li>Your access to the Fertilizer Exchange Platform may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore the service as soon as we reasonably can.</li>
                                    <li>In order to prevent unfair or disorderly trading, Fertx at its sole discretion, has the right to suspend all trading on the Fertilizer Exchange Platform or impose such conditions on trading as it may deem appropriate.</li>
                                </ol>
                                <h5>Exchange Fee</h5>
                                <ol start="32">
                                    <li>The Exchange Fees and any other applicable charges will be those as determined by Fertx from time to time. You agree to promptly pay all applicable Exchange Fees. Exchange Fees are not subject to set off or reduction and in the event that these are not paid within the appropriate timeframe, interest may be charged at 5% on any sums outstanding. </li>
                                </ol>
                                <h5> Your Liability</h5>
                                <ol start="33">
                                    <li>To the extent not otherwise set out in these Terms and Warranties, you shall be liable for all your activity in relation to your User ID and use of the Fertilizer Exchange Platform and you shall indemnify us to the fullest extent permitted by law for any and all losses, damage or claims whether direct, indirect or consequential arising out of your failure to comply with these Terms and Warranties or otherwise.</li>
                                    <li>You are fully and solely liable for all your activity as Seller or Buyer or Service Provider as the case may be and any and all liabilities arising between those Sellers or Buyers or Service Providers with whom you negotiate, trade or otherwise interact with on the Fertilizer Exchange Platform shall be solely for your account and shall never be or become the responsibility of Fertx.</li>
                                    <li>The performance, payment and execution of a Contract concluded via the Fertilizer Exchange Platform is entirely your own responsibility and the responsibility of your counterparty. Fertx is not in any way responsible for the performance, payment or execution of any Contract.</li>
                                    <li>Without limiting the generality of anything in paragraph 35, you warrant that whether as a Seller or Buyer or Service Provider you have the full legal authority and capacity to make and/or accept Bids, Offers and Trade Requests, conclude Contracts, provide services and/or to make payments and you hereby undertake to fully indemnify us against any and all claims that may arise from other Authorised Users as a result of your failure to comply with these Terms and Warranties or otherwise. </li>
                                    <li>You warrant that any activity (including but not limited to Bid, Offer, Trade Request, Contract, etc.) you may engage in or conclude is lawful and will not place you, your counterparty or Fertx in breach of any relevant law, including but not limited to:
					            <ol type="a">
                                            <li>any sanctions imposed by the United States of America, the United Nations, the European Union, Singapore, the United Kingdom, Switzerland, or any other national or supranational body having authority or jurisdiction over any relevant activities or parties;</li>
                                            <li>any laws or regulations relating to the prevention of bribery and corruption; </li>
                                            <li>any laws or regulations relating to money laundering, financing terrorism, and the proceeds of criminal activity. </li>
                                        </ol>
                                    </li>
                                    <li>If, at any stage, you become aware of circumstances which may place you, your counterparty or Fertx in breach of any relevant law, including but not limited to statutes and regulations of the type detailed above in clause 37, you will immediately provide us with full details of the same. In the event Fertx considers that any such laws have or may have been infringed it may, in addition to any other remedy it may have, suspend all affected Fertilizer Exchange accounts forthwith and/or cancel or amend any affected Contracts concluded via the Fertilizer Exchange Platform.</li>
                                    <li>You hereby undertake not to sue Fertx or any of its servants, agents, employees, transferees or assignees, in respect of any breach or alleged breach by any party of any Contract or other transaction entered into via the Fertilizer Exchange Platform and you hereby agree to co-operate fully with all requests from us in connection with such an event or claim (including in the defence of such event or claim.)</li>
                                </ol>
                                <h5>Fertilizer Exchange's Liability</h5>
                                <ol start="40">
                                    <li>Save as may be expressly stated in these Terms and Warranties, Fertx shall have no liability in relation to any Authorised User who has entered into a transaction with another Authorised User, or who has received or provided any other service or document or Trading Information, through the Fertilizer Exchange Platform. We do not make any representation nor do we give any warranties or undertakings whatsoever in relation to the content, use and/or operation of the Fertilizer Exchange Platform or any Trading Information. Any representation, condition or warranty which might be implied or incorporated into these Terms by statute, common law or otherwise is excluded to the fullest extent permitted by law.</li>
                                    <li>We (including our directors, agents and employees) shall not be liable to you in contract, tort (including negligence) or otherwise for any business losses, such as loss of data, profits, revenue, business, opportunity, goodwill, reputation or business interruption or any special, indirect or consequential damages for any losses arising, directly or indirectly from:
			                	<ol type="a">
                                            <li>your use of, or your inability to use, our Service;</li>
                                            <li>your use of or any reliance on any content displayed on or document uploaded to the Fertilizer Exchange Platform or any Trading Information;</li>
                                            <li>any delays or disruptions in our Service;</li>
                                            <li>viruses or other malicious software obtained by accessing, or linking to, our Service;</li>
                                            <li>glitches, bugs, errors or inaccuracies of any kind in our Service;</li>
                                            <li>damage to your hardware device from use of our Service;</li>
                                            <li>the information, content, actions or omissions of third parties, even if these arise by way of your use of our Service;</li>
                                            <li>a suspension or other action taken with respect to your account as a result of your breach of these Terms; </li>
                                        </ol></li>
                                    <li>Fertx acts solely as a provider of the Fertilizer Exchange Platform and it is not in any way a principal in relation to, or responsible for the performance or non-performance of, any Contract concluded on or using the Fertilizer Exchange Platform.</li>
                                    <li>The commercial purpose of the Fertilizer Exchange Platform is to provide the global fertilizer trade with a portal which allows prospective Buyers and Sellers to negotiate directly the transaction which best meets their respective needs without the need for or expense of an intermediary. Accordingly, when a Seller and a Buyer conclude a <br />Contract using the Fertilizer Exchange Platform, those two parties’ contract with each other directly and the Fertilizer Exchange is not a vendor, purchaser, broker, agent or any other category of intermediary. Accordingly, no Seller or Buyer will ever enter into any express or implied Contract with us by bidding for or offering fertilizer for sale. The Fertilizer Exchange Platform is not a regulated entity, nor is it a provider of financial, trading, investment, legal or advice of any other nature. All trading and investment decisions are made by Users at their own risk. Buyers and Sellers shall remain fully responsible for their activity and decisions made in connection with or during use of the Fertilizer Exchange Platform.</li>
                                    <li>We cannot confirm, and we are not in any way responsible for ensuring, the accuracy or truthfulness of any Authorised User’s and or Company and/or Company<br /> Person’s purported identity, credit-worthiness, capacity to trade, nor the accuracy of any information which any user may provide to us or post on the Fertilizer Exchange Platform. It is the sole obligation of each Authorised User to bear, and satisfy itself as to, any counterparty risk posed by another Authorised User and/or to decide (i) whether to contract with that other Authorised User and if so, (ii) on what terms. Fertx accepts no liability whatsoever in relation to this.</li>
                                    <li>As an Authorised User of the Fertilizer Exchange Platform you are hereby acknowledging and warranting that you have fulfilled any and all regulatory, consumer, food safety, statutory, fiscal or other requirements associated with the trade including but not limited to those and the port of shipment and/or port of discharge. The Fertilizer Exchange Platform is not in any way liable for ensuring that Users fulfil their own aforementioned regulatory requirements and will not be held responsible for any failures of Users to meet such requirements.</li>
                                    <li>Fertilizer Exchange also accepts no liability for (i) the making or processing of any payments by a Buyer to a Seller, or (ii) the shipment of any physical fertilizer bought, and/or (iii) the execution or other performance of any Contract concluded on the Fertilizer Exchange and you expressly acknowledge and disclaim any liability of and towards Fertx in this regard.</li>
                                    <li>If,notwithstanding the above, we are found to be liable to you, our liability to you or any third party (whether in contract, tort, negligence, strict liability in tort, by statute or otherwise) is limited to 100 United States Dollars (USD 100) per transaction.</li>
                                    <li>Nothing in this Agreement shall limit or exclude our liability for fraudulent misrepresentation, for death or personal injury resulting from our negligence or the negligence of our agents or employees or for any other liability that cannot be limited or excluded by law.</li>
                                    <li>For the avoidance of doubt Fertx also does not exclude or restrict any duty or liability which we may owe under any regulatory system imposed by any relevant and applicable statute or governmental regulation.</li>
                                </ol>
                                <h5>Disclaimers</h5>
                                <ol start="50">
                                    <li>Whilst we shall use reasonable endeavours to ensure that the information on the Fertilizer Exchange Platform provided by Fertx is correct, we do not warrant the accuracy and completeness of any content on the Fertilizer Exchange Platform provided by us, any Fertilizer Exchange User or any third party. We may make changes to the content on the Fertilizer Exchange Platform at any time without notice.</li>
                                    <li>Accordingly, all content on the Fertilizer Exchange Platform provided by Fertx is provided on a “without guarantee” and “as is” basis, without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, the Service we provide with the Fertilizer Exchange Platform is provided on the basis that we exclude all representations, warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose and the use of reasonable care and skill) which, but for this legal notice, might have effect in relation to the Fertilizer Exchange Platform.</li>
                                    <li>Any agreement you enter into with a third-party Service Provider is subject to the terms and conditions you have entered with that provider and stands alone from the activities and Terms of this Platform. The Fertilizer Exchange Platform has no liability for performance of or fulfilment of any contract by any Service Provider and you remain responsible for paying for any such services. Any dispute between you a Service Provider must be directly resolved with them in accordance with the applicable terms and conditions and the Fertilizer Exchange Platform shall have no duty to intervene to bring about resolution.</li>
                                </ol>
                                <h5>Applicable Law</h5>
                                <ol start="53">
                                    <li>This Agreement will be subject to English Law. We will try to solve any disagreements quickly and efficiently. Any dispute or claim arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, and any dispute or claim arising out of or in connection with its subject matter or formation (including non-contractual disputes or claims), will be referred to and finally resolved by an arbitration administered by the Singapore<br />International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of SIAC then in force, which rules will be deemed to be incorporated by reference into this Agreement. The seat of the arbitration will be Singapore. The tribunal will consist of one arbitrator to be appointed by the Chairman of SIAC. The language of the arbitration will be English. The award of the arbitrator will be final and binding upon the parties to this Agreement.</li>
                                </ol>
                                <h5>Disputes between Buyers, Sellers or Service Providers</h5>
                                <ol start="54">
                                    <li>All trades will be marked "closed” by the Fertilizer Exchange Platform 90 days after the Buyer has effected payment to the Seller. </li>
                                    <li>You shall hold harmless and indemnify Fertilizer Exchange in relation to all Disputes.</li>
                                    <li>For the avoidance of doubt, nothing in clause 54 and/or above shall affect the operation of any time bar or an Authorised User’s rights and/or obligations under any dispute resolution clause, choice of law or jurisdiction or forum clause or any arbitral provision contained in any Contract concluded using the Fertilizer Exchange Platform.</li>
                                    <li>However, where a Buyer or Seller fails to resolve or respond to a Dispute in a commercial and professional manner, Fertx shall be entitled, in its sole discretion, to suspend one or both parties from the Fertilizer Exchange Platform and from further use of its system.</li>
                                </ol>
                                <h5>Miscellaneous</h5>
                                <ol start="58">
                                    <li>You may not transfer any of your rights under these Terms to any other person unless approved in writing by Fertx. We may transfer our rights under these Terms to another business where we reasonably believe your rights will not be affected.</li>
                                    <li>There shall be no waiver of Fertx’s rights. If you breach these Terms and Fertx chooses to ignore this, Fertx will still be entitled to use its rights and remedies at a later date or in any other situation where you breach the Terms.</li>
                                    <li>Fertx shall not be responsible for any breach of these Terms or Warranties caused by circumstances beyond its reasonable control. </li>
                                    <li> To the extent not otherwise provided on the Fertilizer Exchange Platform, all notices<br />(“Notices”) to us under this Agreement shall be in writing and sent to 4 Battery Road, Bank of China Building No 25-01, Singapore 049908. All Notices we send to you shall be sent via email to the email address you provided on registration or may be published on the Fertilizer Exchange Platform.</li>
                                    <li>The provisions of this Agreement are severable. If any part of these Terms is determined, by court or a relevant authority, to be legally invalid or unenforceable, such provision will be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms will continue in full force and effect. </li>
                                    <li>This Agreement is only between you and us. No other person shall have any rights to enforce any of its Terms, whether under the Contracts (Rights of Third Parties Act) 1999 or otherwise.</li>
                                    <li>The provisions of the United Nations Convention on the International Sale of Goods shall not apply to this Agreement, nor, unless the parties expressly agree otherwise, shall this Convention apply to any Contract concluded on or via the Fertilizer Exchange Platform. </li>
                                    <li>These Terms, the Warranties and any other policies referred to, form the entire agreement between you and us concerning the use of our Service and supersede all previous agreements. No agency, partnership, joint venture, employer-employee, franchise-franchisee is intended or created by this Agreement.</li>
                                </ol>
                                <h5>Termination</h5>
                                <ol start="66">
                                    <li>Fertx shall have the right to immediately suspend or terminate your registration, use and access to the Fertilizer Exchange Platform without cause for any reason it sees fit.</li>
                                    <li>If you wish to terminate this Agreement then you may do so by cancelling your registration in accordance with clause 10 above.</li>
                                    <li>These Terms and our Policies will continue to govern the relationship between you and Fertx after Termination.</li>
                                </ol>
                                <h5>Language</h5>
                                <ol start="69">
                                    <li>We will communicate with you in English only.
                                    </li>
                                    <li>The Fertilizer Exchange Platform and these Terms are concluded in English only. Any translation of the Fertilizer Exchange Platform or these Terms by you, your search engine, a professional translator or otherwise is provided solely for your convenience and is not intended to modify the contents of this Agreement. In the event of a conflict between the English version of the Fertilizer Exchange Platform or these Terms and a version in a language other than English, the English version shall be the definitive version.</li>
                                </ol>
                                <p className="text-right"> February 2021</p>
                                <h5>Company / Authorised User Warranties</h5>
                                <p>These Company / Authorised User Warranties (<b>“Warranties”</b>) are incorporated by reference into the Fertilizer Exchange Terms and Conditions of Use (the <b>“Terms”</b>) as if fully set forth therein and are subject to the Terms in all respects. All capitalized terms appearing in these Warranties have the meanings assigned to them in the Terms.</p>
                                <p>By ticking the box below, and each time you access the Fertilizer Exchange Platform, your Company and you as Authorised User jointly and severally represent and warrant that each of the following Warranties is true, accurate, complete and not misleading in all respects as it applies to your Company and to you as Authorised User. If any part of these Warranties is not true, accurate, complete and not misleading, you have an affirmative duty promptly to notify Fertilizer Exchange before further accessing the Fertilizer Exchange Platform; any failure to so notify will breach the Terms and may result in additional consequences. Authorised Users are deemed to make these Warranties with respect to their Company and Company Persons to the best of their knowledge and belief, upon diligent and careful inquiry.</p>
                                <ol start="1">
                                    <li>Neither Company / Authorised User nor any Company Person: (i) is an individual or entity that is currently the subject of any sanctions administered or enforced by (A) the U.S. Department of State or the U.S. Department of the Treasury (including the Office of Foreign Assets Control (<b>“OFAC”</b>)), in the U.S., (B) Her Majesty's Treasury in the United Kingdom, (C) the United Nations Security Council, (D) the European Union or (E) any other relevant sanctions authority (collectively, <b>“Sanctions”</b>); (ii) is an individual or entity whose name appears on the list of Specially Designated Nationals and Blocked Persons published by OFAC (or any similar list maintained by, or any sanctions designation made by, the U.S. Department of State or any other U.S. government entity) (a “<b>Listed Person</b>”); (iii) is an agent, department or instrumentality of, or is otherwise beneficially owned by, controlled by or acting on behalf of, directly or indirectly, (x) any Listed Person or (y) any individual or entity, organization, foreign country or regime that is the subject of any Sanctions; or (iv) engages in any dealings or transactions with any such person described in subsections (i), (ii) or (iii) of this paragraph.</li>
                                    <li>Neither Company / Authorised User nor any Company Person: (i) has been found in violation of any Sanctions or charged with or convicted of a violation of any Sanctions; (ii) is under investigation by any governmental authority for possible violation of any Sanctions; or (iii) has been assessed civil penalties under any Sanctions. Company has adopted policies and procedures designed to promote and achieve compliance on the part of Company and all Company Persons with all Sanctions.</li>
                                    <li>No legal, governmental or regulatory investigations, actions, suits or proceedings are pending or threatened against Company / Authorised User or any Company Person, and Company / Authorised User has never been arrested, charged with or indicted for a misdemeanor, felony or other crime or been convicted of any misdemeanor, felony or other crime.</li>
                                    <li>Company and each of its subsidiaries have been duly incorporated or organized and are validly existing and, where applicable, in good standing under the laws of their respective jurisdictions of incorporation or organization, are duly qualified to do business and, where applicable, are in good standing in each jurisdiction in which their respective ownership or lease of property or the conduct of their respective businesses requires such qualification and have all power and authority necessary to own or hold their respective properties and to conduct their respective businesses.</li>
                                    <li>Company and its subsidiaries possess all licenses, certificates, permits and other authorizations issued by, and have made all declarations and filings with, the appropriate governmental or regulatory authorities that are necessary for the ownership or lease of their respective properties and the conduct of their respective businesses; and neither Company / Authorised User nor any of its subsidiaries has received notice of any revocation or modification of any such license, certificate, permit or authorization or has any reason to believe that any such license, certificate, permit or authorization will not be renewed in the ordinary course. </li>
                                    <li>Neither Company / Authorised User nor any Company Person: (a) has violated (or has taken any action, directly or indirectly, that would result in a violation of): (x) any anti-bribery laws and / or anti-corruption laws, rules or regulations of any jurisdiction, including, but not limited to, the U.S. Foreign Corrupt Practices Act of 1977, as amended (including the rules and regulations thereunder), the UK Bribery Act of 2010, as amended (including the rules and regulations thereunder), and any other law, rule or regulation of similar purpose and scope (collectively, <b>“Anti-Corruption Laws”</b>); or (y) any applicable laws, regulations or government guidance regarding anti-money laundering (collectively, “<b>Anti-Money Laundering Laws</b>”); (b) has been charged with or convicted of a violation of, or has been or is now a party to any administrative or civil litigation or has been or is now under any administrative, civil or criminal investigation, charge or indictment relating to its compliance with, Anti-Corruption Laws or Anti-Money Laundering Laws, and no such action is threatened; or (c) has been assessed civil or criminal penalties under any Anti-Corruption Laws or Anti-Money Laundering Laws. Company has adopted policies and procedures designed to promote and achieve compliance on the part of Company and all Company Persons with all Anti-Corruption Laws and Anti-Money Laundering Laws.</li>
                                    <li>Neither Company / Authorised User nor any Company Person has taken any action in furtherance of an offer, payment, promise to pay or authorization or approval of the payment or giving of money, property, gifts or anything else of value, directly or indirectly, to any government official (including any officer or employee of a government or government-owned or controlled entity or of a public international organization, or any person acting in an official capacity for or on behalf of any of the foregoing, or any political party or party official or candidate for political office) to influence official action or secure an improper advantage or has otherwise made any bribe, payoff, influence payment, kickback or other unlawful payment.</li>
                                    <li>Company / Authorised User understands and acknowledges that trades on the Fertilizer Exchange Platform may involve a high degree of risk and may constitute speculative activity; Company / Authorised User is experienced in trading on and investing in international fertilizer, soft commodity, agricultural and similar markets and possesses such knowledge and experience in commercial, trading, financial and business matters as to be capable of evaluating the merits and risks of trading on the Fertilizer Exchange Platform; Company is solvent and has the ability to bear the economic risk of trading on the Fertilizer Exchange Platform, has adequate means of providing for its current and contingent needs, has no need for liquidity with respect to trades on the Fertilizer Exchange Platform and is able to sustain a complete loss in connection with its activities on the Fertilizer Exchange Platform; and, in availing the Fertx Registration Process and accessing the Fertilizer Exchange Platform, Company / Authorised User is acting solely for Company’s own account as principal and not as proxy or agent for any other person or entity.</li>
                                    <li>Company has insurance covering its properties, operations, personnel and businesses, including business interruption insurance, which insurance is in amounts and insures against such losses and risks as are prudent and customary for the businesses in which it is engaged; and neither Company / Authorised User nor any Company Person has any reason to believe that it will not be able to renew its existing insurance coverage as and when such coverage expires or to obtain similar coverage at reasonable cost from similar insurers as may be necessary to continue its business.</li>
                                </ol>
                                <p>By ticking the box below, and each time you access the Fertilizer Exchange Platform, your Company and you as Authorised User jointly and severally represent and warrant, undertake, consent to, agree and acknowledge pursuant to each of the following additional Warranties:</p>
                                <ul>
                                    <li>All Company / Authorised User data may be used and disseminated by Fertx to the fullest extent permitted under applicable data protection, privacy and other laws and regulations.</li>
                                    <li>Fertilizer Exchange may conduct investigations into Company / Authorised User and any Company Person, which may include contacting any person referenced in the Fertx Registration Process as well as trade references, affiliates, banks and financial institutions, licensing authorities, credit rating agencies, governmental and regulatory authorities and other persons.</li>
                                    <li>All information Company / Authorised User provides Fertilizer Exchange as part of the Fertx Registration Process or otherwise is true, accurate, complete and not misleading in all respects, and all supporting documentation Company / Authorised User provides is genuine and complete;</li>
                                    <li>Any untrue, inaccurate, incomplete or misleading submission to Fertilizer Exchange may result in a denial of access to the Fertilizer Exchange Platform, a referral to governmental or regulatory authorities, legal claims and other sanctions and penalties.</li>
                                    <li>Company / Authorised User has an affirmative duty to continually amend and update its registration so as to ensure that all Company / Authorised User information (including these Warranties) and supporting documentation provided to Fertilizer Exchange is and remains true, accurate, complete and not misleading in all respects. Company / Authorised User will promptly notify Fertx if any such information or supporting documentation is or becomes untrue, inaccurate, incomplete or misleading in any respect.</li>
                                    <li>Company / Authorised User will promptly comply in good faith with all Fertilizer Exchange requests for additional information and supporting documentation.</li>
                                    <li>Company has taken all action (corporate or other) required to be taken for the due authorization of (i) Company / Authorised User to complete the Fertx Registration Process; (ii) you to serve as an Authorised User and (iii) the Company / Authorised User accessing the fertilizer Exchange Platform and all Company’s / Authorised User’s activities thereon. By accessing the Fertilizer Exchange Platform, Company and all participating Company Persons are also acting with full apparent authority with respect to all their activities thereon. These Warranties have been duly authorised, executed and delivered by Company / Authorised User.</li>
                                    <li>Company / Authorised User has reviewed the Terms in detail and has no reason to believe that it or its registration on the Fertilizer Exchange Platform, its accessing the Fertilizer Exchange Platform, its activities thereon or its business or operations conflicts with or breaches the Terms in any respect. Company / Authorised User undertakes to abide by the Terms at all times and not to breach the Terms in any respect.</li>
                                </ul>
                                <p>Confirmed by Authorised User on behalf of itself and the Company</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={e => this.closeModal(true)} className="btn-popup btn-submit lh-1 ">
                                    <FontAwesomeIcon icon={faCheck} /> I Agree
		                        </button>
                            </div>
                        </div>
                    </form>
                </div>
            );

        } else if (modalName === 'SAVE_DRAFT') {
            return (
                <div className="modal__container">
                    <div className="modal-content modal__wrapper px-0 py-0 authcode">

                        <div className="modal-header">
                            <h5 className="modal-title w-100">Success</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={e => this.closeModal(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                        Your application has been saved and you can return to it at any time. You will shortly receive an email with a link that will take you back to your application.
                        </div>
                        <div className="modal-footer">
                            <button onClick={e => this.closeModal(false)} className="btn-popup btn-submit lh-1 ">
                                <FontAwesomeIcon icon={faCheck} /> Close
		                    </button>
                        </div>
                    </div>
                </div>
            )
        }
    };

    closeModal(isAgreed) {
        this.setState({
            showModal: false,
            isDeclarationSigned: isAgreed
        });
    }


    render() {
        return (
            <Fragment>

                <div className="header">
                    <div className="float-left pt-2 pl-2 pb-2 fertx_logo">
                        <img src={logo} alt="Fertx Logo" />
                    </div>
                    <ul className="float-right dropdown nav">
                        <li className="nav-item">
                            <a className="nav-item nav-link dropdown-toggle mr-md-2"
                                href="#/" id="bd-versions" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faUser} />
                                {this.props.account.user && this.props.account.user.name}
                            </a>

                            <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#/" onClick={() => {
                                    this.props.logout();
                                    this.props.navigate();
                                }}
                                ><FontAwesomeIcon icon={faSignOutAlt} /> &nbsp; Log Out</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <Fragment>
                    <div className="container">
                        <Preloader loadingStyle="overflow-spinner" loading={this.props.isloading}>
                            {this.state.showModal && this.renderModal(this.state.modal)}
                            <div className="content-part">
                                {this.state.showGreetings ? (
                                    <div className="content-wrappper  box-shadow thankyou-box mt-3">
                                        <div className="thankyou-icon">
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                        <div className="thanku text-center">
                                            <h4>Thank you</h4>
                                            <div className="thanku-text">Your Company Registration has been submitted and a confirmation email should arrive shortly.</div>

                                        </div>
                                    </div>) :
                                    (
                                        <form onSubmit={e => this.onComplete(e)} encType="multipart/form-data">
                                            <div className="trades-req-quotes__heading mb-3">
                                                <h3>Registration Details Wizard</h3></div>
                                            <div className="content-wrappper box-shadow">

                                                <div className="kyc-details-wizard">
                                                    <ul>
                                                        <li className={this.state.activeStep === STEP1 ? 'active' : ''}><a href="#/" onClick={e => this.setState({ activeStep: "step_1" })} >Step 1</a></li>
                                                        <li className={this.state.activeStep === STEP2 ? 'active' : ''}><a href="#/" onClick={e => this.setState({ activeStep: "step_2" })}>Step 2</a></li>
                                                        <li className={this.state.activeStep === STEP3 ? 'active' : ''}><a href="#/" onClick={e => this.setState({ activeStep: "step_3" })}>Step 3</a></li>
                                                    </ul>
                                                </div>
                                                {this.state.serverError && <div className="alert alert-danger mt-3" role="alert">
                                                    <span>{this.state.serverError}</span>
                                                </div>}
                                                {this.state.shareholdersPercentError && <div className="alert alert-danger mt-3" role="alert">
                                                    <span>{this.state.shareholdersPercentError}</span>
                                                </div>}
                                                {this.state.singleUboPercentError && <div className="alert alert-danger mt-3" role="alert">
                                                    <span>{this.state.singleUboPercentError}</span>
                                                </div>}
                                                {this.state.ubosPercentError && <div className="alert alert-danger mt-3" role="alert">
                                                    <span>{this.state.ubosPercentError}</span>
                                                </div>}
                                                {this.state.singleShareholdersPercentError && <div className="alert alert-danger mt-3" role="alert">
                                                    <span>{this.state.singleShareholdersPercentError}</span>
                                                </div>}
                                                {this.state.activeStep === STEP1 ? (
                                                    <div className="kyc-details-form">
                                                        <h4>Company Details</h4>
                                                        <div className="form">
                                                            <CompanyDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state) => this.setCompanyField(name, value, state)}
                                                                validation={this.state.required}
                                                                setMailing={(value) => this.setMailingAddress(value)}
                                                                canSubmit={!this.state.canSubmit}
                                                                fileUploadValidate={this.fileUploadValidate}
                                                            />
                                                        </div>
                                                        <h4>Company Shareholders</h4>
                                                        <div className="form">
                                                            <ShareholderDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state, index) => this.setCompanyField(name, value, state, index)}
                                                                validation={this.state.required.shareholders}
                                                                add={() => this.addDynamicField("shareholders")}
                                                                remove={(index) => this.removeDynamicField("shareholders", index)}
                                                                setNoShareholder={(value) => this.setNoShareholder(value)}
                                                                noShareholder={this.state.noShareholder}
                                                            />
                                                        </div>
                                                        <h4 className="mt-4 mb-2">Company UBO</h4>
                                                        <div className="form">
                                                            <UboDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state, index) => this.setCompanyField(name, value, state, index)}
                                                                validation={this.state.required.ubos}
                                                                add={() => this.addDynamicField("ubos")}
                                                                remove={(index) => this.removeDynamicField("ubos", index)}
                                                                canSubmit={!this.state.canSubmit}
                                                                fileUploadValidate={this.fileUploadValidate}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : ''}
                                                {this.state.activeStep === STEP2 ? (
                                                    <div className="kyc-details-form">
                                                        <h4>Details of Senior Manager(s)</h4>
                                                        <div className="form ">
                                                            <DirectorDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state, index) => this.setCompanyField(name, value, state, index)}
                                                                validation={this.state.required.directors}
                                                                add={() => this.addDynamicField("directors")}
                                                                remove={(index) => this.removeDynamicField("directors", index)}
                                                                canSubmit={!this.state.canSubmit}
                                                                fileUploadValidate={this.fileUploadValidate}
                                                            />
                                                        </div>
                                                        <h4>Authorized Company Admin</h4>
                                                        <div className="form ">
                                                            <CompanyAdminDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state) => this.setCompanyField(name, value, state)}
                                                                validation={this.state.required.companyAdmin}
                                                                canSubmit={!this.state.canSubmit}
                                                                fileUploadValidate={this.fileUploadValidate}
                                                            />
                                                        </div>
                                                        <h4>Bank Reference</h4>
                                                        <div className="form ">
                                                            <BankDetails
                                                                state={this.state.company}
                                                                setField={(name, value, state) => this.setCompanyField(name, value, state)}
                                                                validation={this.state.required.bank}
                                                            />
                                                        </div>
                                                        <h4>Documents</h4>
                                                        <div className="form ">
                                                            <Documents
                                                                state={this.state.company}
                                                                setField={(name, value, state) => this.setCompanyField(name, value, state)}
                                                                validation={this.state.required}
                                                                canSubmit={!this.state.canSubmit}
                                                                fileUploadValidate={this.fileUploadValidate}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : ''}
                                                {this.state.activeStep === STEP3 ? (
                                                    <div className="kyc-details-form">
                                                        <h4>Parent company and/or affiliated/subsidiary company(ies)</h4>
                                                        <div className="form ">
                                                            <AssociatedCompanies
                                                                state={this.state.company}
                                                                setField={(name, value, state, index) => this.setCompanyField(name, value, state, index)}
                                                                //validation={this.state.required.associatedCompanies}
                                                                add={() => this.addDynamicField("associatedCompanies")}
                                                                remove={(index) => this.removeDynamicField("associatedCompanies", index)}
                                                            />
                                                        </div>
                                                        <h4>Trade References</h4>
                                                        <div className="form ">
                                                            <TradeReferences
                                                                state={this.state.company}
                                                                setField={(name, value, state, index, subState) => this.setCompanyField(name, value, state, index, subState)}
                                                                validation={this.state.required.tradeReferences}
                                                                add={() => this.addDynamicField("tradeReferences")}
                                                                remove={(index) => this.removeDynamicField("tradeReferences", index)}
                                                            />
                                                        </div>

                                                        <div className="form-checkbox form-group mt-4 mb-3">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" name="declaration"
                                                                    className="custom-control-input"
                                                                    id="declaration"
                                                                    checked={this.state.isDeclarationSigned}
                                                                    onChange={val => {
                                                                        this.setState({ isDeclarationSigned: val.target.checked });
                                                                        if (val.target.checked) {
                                                                            this.openModal("SHOW_TNC");
                                                                        }
                                                                    }} />
                                                                <label className="custom-control-label" htmlFor="declaration">
                                                                    I, {this.state.company.companyAdmin.name.value !== '' ? this.state.company.companyAdmin.name.value : '(name of admin)'} as empowered by {this.state.company.name.value !== '' ? this.state.company.name.value : '(company name)'} hereby confirm and declare that the information provided is
                                                                                complete, true and correct. I further authorize and grant consent to Fertilizer Exchange and
                                                                                its officers to verify the information provided and conduct checks on our directors,
                                                                    shareholders, owners, partners and trade references.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''}

                                                <div className="text-right mt-3 kyc-form-btn">
                                                    {this.state.canSave &&
                                                        <button
                                                            onClick={e => this.onSaveDraft(e)}
                                                            disabled={this.state.isSaveloading}
                                                            className="btn btn-resubmit mt-xs-1 lh-1" >
                                                            <Preloader loadingStyle="dots" loading={this.state.isSaveloading}>
                                                                <span><FontAwesomeIcon icon={faSave} />
                                                                &nbsp; Save as Draft</span>
                                                            </Preloader>
                                                        </button>
                                                    }
                                                    {this.state.activeStep === STEP1 ? ''
                                                        : (
                                                            <button type="button" className="btn btn_previous mt-xs-1 lh-1" onClick={() => { this.stepChange("minus") }} >
                                                                <FontAwesomeIcon icon={faChevronLeft} />
                                                                &nbsp; Previous</button>
                                                        )}

                                                    {this.state.activeStep === STEP3 ? ''
                                                        : (
                                                            <button type="button" className="btn btn-approve mt-xs-1 lh-1" onClick={() => { this.stepChange("plus") }}>
                                                                <FontAwesomeIcon icon={faChevronRight} />&nbsp; Next </button>
                                                        )}
                                                    <button
                                                        onClick={e => this.onComplete(e)}
                                                        value="submit"
                                                        disabled={!this.state.isDeclarationSigned || this.props.loading || !this.state.canSubmit}
                                                        className="btn btn-resubmit mt-xs-1 lh-1" >
                                                        <Preloader loadingStyle="dots" loading={this.props.loading}>
                                                            <span><FontAwesomeIcon icon={faCheck} />
                                                            Submit</span>
                                                        </Preloader>
                                                    </button>
                                                </div>

                                            </div>

                                        </form>
                                    )}
                            </div>
                        </Preloader>
                    </div>

                </Fragment>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    company: state.account.company,
    loading: state.loading.registerLoading,
    isloading: state.loading.kycLoading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompany,
            logout,
            navigate: () => push('/'),
            LoadingRegisterData
        },
        dispatch
    );

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(KycCompanyWizard);