import React, {useState, useEffect} from 'react';
import { TradeApi } from '../../services/service.api';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageViewer = ({image}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [url, setUrl] = useState("")

    const downLoadImage = (imagevalue) => {
        setIsLoading(true);
        TradeApi.getTradeDocument(imagevalue).then(res => {
            const fetch_url = window.URL.createObjectURL(new Blob([res.data]));
            setUrl(fetch_url);
            setIsLoading(false);
        })
        .catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        downLoadImage(image);
    }, [image])

    return (<div className="img-fluid" style={{ width: '80px'}}>
            {url && !isLoading?
            <img className="rounded mx-auto d-block" width="100%" src={url} alt="Fertilizer example" />
            :
            <div className="rounded m-auto d-block w-100 h-100 text-center align-middle">
                <div className="m-auto align-middle"><FontAwesomeIcon size="3x" icon={faSpinner} spin={true} /></div>
            </div>}
        </div>)
}

export default ImageViewer;