import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar} from 'material-table';
import createTheme from "@material-ui/core/styles/createTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAdditionalEmails, changeAdditionalEmails } from '../../modules/module.adminManagement';
import CustomMaterialPreloader from '../components/preloader/CustomMaterialPreloader';

import {
    ChevronLeft, 
    ChevronRight,
    FirstPage,
    LastPage, 
    FilterList, 
    ArrowUpward, 
    Search, 
    Delete, 
    Cancel,
    Edit, 
    Check,
    PersonAdd,
    Clear }
    from '@material-ui/icons';

const AdditionalEmails = props => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emptyListCode = ",";
    const theme = createTheme({
        palette: {
          primary: {
            main: '#b6bdf0',
          },
          secondary: {
            main: '#5465dd',
          },
        },
  
      });

    const [initialList, setInitialList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [hasChanged, setHasChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //Init function addRow. To be bound with MTable addRow() function.
    let addRow = () => console.warn("No Binding");

    //Utility : from list to string
    const stringToList = (string) => string?string.split(",").map(email => ({email: email})):[];
    const listToString = (list) => list.map(item => item.email).toString()

    //Handle on cancel : discardChanges
    const handleOnCancel = () => {
        setEmailList(stringToList(initialList));
        setHasChanged(false);
    }

    //Handle on submit : Update email list
    const handleOnSubmit = () => {
        setIsLoading(true);
        const newEmails = listToString(emailList.filter(item => item.status !== 'DELETE'));
        changeAdditionalEmails({emails: (newEmails)?newEmails:emptyListCode})
        .then(res => {
            setInitialList(newEmails);
            setEmailList(stringToList(newEmails));
            setIsLoading(false);
            setHasChanged(false);
        }).catch( e => {
            console.error(e);
            setIsLoading(false);
        })
        setIsLoading(true);

    }

    useEffect(() => {
        setIsLoading(true);
        getAdditionalEmails()
        .then(res => {
            setInitialList(res.data.emails);
            setEmailList(stringToList(res.data.emails));
            setIsLoading(false);
            setHasChanged(false);
        })
        .catch(e => {
            console.error(e);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if(initialList !== listToString(emailList)){
            setHasChanged(true);
        }
    }, [initialList, emailList, setHasChanged]);

    //Custom componant for MaterialTable editable Row
    const CustomEdit = props => {
        const [isEmail, setIsEmail] = useState(false);
        const [exists, setExists] = useState(false);

        useEffect(()=>{
            setIsEmail(emailRegex.test(props.value));
            setExists(emailList.find(item => item.email === props.value) !== undefined);
        }, [props.value]);

        return <form>
            <input className={`form-control ${ (isEmail && !exists)?'is-valid':'is-invalid'}`} 
                required
                value={props.value}
                onChange={e => props.onChange(e.target.value)}/>
            {!isEmail && 
            <small id="emailHelp" className="form-text text-danger">Please enter a valid email</small>}
            {exists && 
            <small id="emailExistsHelp" className="form-text text-danger">This email is already in the list</small>}      
        </form>
    }

    return <>
        <section className="rbac-body">
            <button type="button" className="trades-dtls__verify-docs" onClick={e => addRow()}>Add Email</button>
            { hasChanged && <div className="mx-3">Please save your changes once modifications are done</div>}
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                title="CC Emails"
                columns={[{ 
                    title: 'Email Address', 
                    field: 'email',
                    editComponent: CustomEdit,
                    initialEditValue: "",
                }]}
                isLoading={isLoading}
                data={emailList}
                components={{
                    OverlayLoading: CustomMaterialPreloader,
                    Toolbar: props => {
                        /* Trick to bind action 'add row' */
                        props.actions[0].position = "";
                        addRow = props.actions[0].onClick;
                        return <MTableToolbar {...props} />
                    }
                }}
                options={{
                    rowStyle: rowData => {
                        switch(rowData.status){
                            case "NEW":
                            case "UPDATE":
                                return {backgroundColor: 'rgb(212, 237, 218)'};
                            case "DELETE":
                                return {backgroundColor: 'rgb(248, 215, 218)', textDecoration: 'line-through'};
                            default:
                                break;
                        }
                    },
                    thirdSortClick: false
                }}  
                editable={{
                    isDeleteHidden: rowData => rowData.status === 'DELETE',
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            if(emailRegex.test(newData.email) && emailList.find(item => item.email === newData.email) === undefined){
                                newData.status = 'NEW';
                                setEmailList([...emailList, newData]);
                                resolve();
                            }else{
                                reject()
                            }
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            if( emailRegex.test(newData.email) && 
                                emailList.find(item => item.email === newData.email) === undefined){
                                const dataUpdate = [...emailList];
                                const index = oldData.tableData.id;
                                newData.status = "UPDATE";
                                dataUpdate[index] = newData;
                                setEmailList([...dataUpdate]);
                                setHasChanged(true);
                                resolve();              
                            }else{
                                reject()
                            }
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            const dataDelete = [...emailList];
                            const index = oldData.tableData.id;
                            dataDelete[index].status = "DELETE";
                            setEmailList([...dataDelete]);
                            setHasChanged(true);
                            resolve();
                        }),
                }}
                icons= {{
                    Filter: FilterList,
                    FirstPage: FirstPage,
                    LastPage: LastPage,
                    NextPage: ChevronRight,
                    PreviousPage: ChevronLeft,
                    SortArrow: ArrowUpward,
                    Search: Search,
                    Edit: Edit,
                    Check: Check,
                    Delete: Delete,
                    Add: () => <PersonAdd color="primary"/>,
                    Cancel: Cancel,
                    ResetSearch: Clear,
                    Clear: Clear,
                    ThirdStateCheck: Cancel
                }}/>
            </MuiThemeProvider>
                <div className="row">
                    <div className="col my-3 text-center">
                        {(hasChanged && !isLoading) && <>
                        <button type="button" onClick={handleOnCancel} className="trades-dtls__verify-docs whsp-nowrap mt-xxs-10 btn-cancel">
                            <FontAwesomeIcon icon={faTimes}/>&nbsp;Cancel
                        </button>
                        <button type="button" onClick={handleOnSubmit} className="trades-dtls__verify-docs whsp-nowrap mt-xxs-10 btn--blue">
                            <FontAwesomeIcon icon={faCheck}/>&nbsp;Save
                        </button>
                        </>}
                    </div>
                </div>

        </section>
    </>
}

export default AdditionalEmails