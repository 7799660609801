import React from 'react';
import ConfirmModal from './ConfirmModal';
import Contract from '../../../trades/components/Contract';
import Preloader from '../../../../components/preloader/Preloader';
import { ERROR_MESSAGES } from '../../../../../services/service.values';

/**
 * Display confirm modal 
 *
 * @since  x.x.x
 * @access public
 *
 * @type     {React.Component}
 * @property {content}
 * @property {title}
 * @property {onCancel}
 * @property {onConfirm}
 * @property {show}
 * @property {onCancel}
 */


class ConfirmModalAcceptAll extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isDeclaration: false,
            showError: false,
            errorMessage: "",
            isOtherInspector:!this.props.isOtherInspector
        }
    }
    
    onCancel = () => {
        this.setState({ 
            isDeclaration: false, 
            errorMessage:"",
            isOtherInspector:!this.props.isOtherInspector
        });  
    }

    showErrorMessage = (errorMessage) => {
        this.setState({ 
            showError: true, 
            errorMessage
        });
    }

    onConfirm = () => {
        if(this.state.isDeclaration && this.state.isOtherInspector){
            this.props.onConfirm(null);
        }else{
            this.showErrorMessage(ERROR_MESSAGES.CONFIRM_BID_TERMS)
        }
    }

    render() {
        const { 
            loading = false,
            isCounter = true,
            fromCompanyName,
            buttonClass,
            trade,
            user,
            loadingBidInfo = false,
            bidInfoHandler = () => {}
        } = this.props;


        return(<ConfirmModal loading={loading}
            title={`You are about to accept a ${isCounter?'counter':'offer'} from ${fromCompanyName}`}
            buttonClass = {buttonClass}
            buttonLabel = "Accept"
            modalBodyClass = "py-0"
            onConfirm = {this.onConfirm}
            closeOnSubmit={(this.state.isDeclaration && this.state.isOtherInspector) ? this.isTenderWithMultiOrigin ? this.state.selectedOrigin : true : false}
            onCancel={this.onCancel}
            buttonHandler={bidInfoHandler}>
                <Preloader loading={loadingBidInfo} loadingStyle="swirl">
                    <div className="contract-modal">  
                        <Contract trade={trade}  user={user} />
                    </div>
                </Preloader>
                <div className="pl-3 pt-2">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" 
                            name="declaration" 
                            className="custom-control-input" id="declaration"
                            checked={this.state.isDeclaration}
                            onChange={val => {
                                this.setState({ isDeclaration: val.target.checked, errorMessage:"" });
                            }}/>
                        <label className="custom-control-label" htmlFor="declaration">
                            I confirm having reviewed all terms and conditions, 
                            which are hereby accepted and furthermore acknowledge that 
                            I am entering into a legally binding contract independent whether I do sign the contract later or not. 
                            Please click Back to go back or Confirm to continue.
                        </label>
                    </div>
                    {this.props.isOtherInspector &&
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" 
                            name="inspector" 
                            className="custom-control-input" id="inspector"
                            checked={this.state.isOtherInspector}
                            onChange={val => {
                                this.setState({ isOtherInspector: val.target.checked, errorMessage:"" });
                            }}/>
                        <label className="custom-control-label" htmlFor="inspector">
                        I understand that the contractual inspector for this trade is not an integrated inspector on the platform 
                        and that therefore certain functionalities are not available.
                        </label>
                    </div>}
                    {this.state.showError && <div className="counterparty__error text-left">{this.state.errorMessage}</div>}
                </div>  
        </ConfirmModal>)
    }
}

export default ConfirmModalAcceptAll;