import React, { Component } from 'react';
import { connect } from 'react-redux';
import './forgot.scss';
import { withRouter, Link } from 'react-router-dom';
import { AccountApi } from "../../../services";
import { Layout } from "../index";
import { push } from "react-router-redux";

import { bindActionCreators, compose } from 'redux';
import { AppErrors } from "../../components/errors";

import logo from '../../../static/img/logo.png';
import Preloader from "../../components/preloader/Preloader";
import { LoadingRegisterData } from '../../../modules/module.loading';

class ForgotPasswordComponent extends Component {
  state = {
    data: {
      email: ''
    },
    errors: {},
    isLoading:false
  };

  validate(data) {
    const regexEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    const errors = {};

    if (regexEmail.test(data.email) === false) {
      errors["email"] = "Incorrect email";
    }

    if (data.email.length === 0) {
      errors["email"] = "Email is required.";
    }

    return errors;
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.LoadingRegisterData(true);
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.sendRequest();
    }

  }

  sendRequest = () => {
    AccountApi.forgot({ email: this.state.data.email }).then(() => {
      this.props.LoadingRegisterData(false);
      this.props.navigate('/account/forgot/success')
    }, () => {
      this.setState({
        serverError: AppErrors['forgot_password']
      })
      this.props.LoadingRegisterData(false);
    }).catch(error => {
      this.setState({
        serverError: AppErrors[error.response.data.status]
      })
      this.props.LoadingRegisterData(false);
    })
  }

  render() {
    const serverError = this.state.serverError
    return (
      <Layout {...this.props}>
        <div className="forgot-container">
          <div className="content-fp">
            {!this.props.allowRestorePassword ? (
              <React.Fragment>
                <ul className="account-tabs forgot-tab">
                  <li className="account-tab active">
                    Forgot Password
                  </li>
                </ul>


                <p className="forgot-text">
                  <img className="forgot-logo" src={logo} alt="Fertilizer exchange logo" />
                  Please enter Email Address to reset your password.
                  <br />
                  You will receive an Email with instructions on how to reset your password.

                  <ul className="errors">
                    {serverError && <li>{serverError} </li>}
                    {this.state.errors.email && <li>{this.state.errors.email} </li>}
                  </ul>
                </p>
                <div className="action-block">
                  <div className="form-input">
                    <input
                      type="email"
                      placeholder="Email"
                      className={`input input_higher forgot-mail ${(this.state.errors.email || serverError) && "input_error"}`}
                      id="email"
                      onChange={input => {
                        this.setState({ data: { email: input.target.value } });
                      }}
                    />
                    <Link to={'/account/login'} className="forgot-link">
                      Back to Sign in
                    </Link>
                  </div>
                </div>
                
                <button
                  className="btn btn--blue forgot-send lh-1"
                  onClick={this.handleSubmit}
                >
                  <Preloader loadingStyle="dots" loading={this.props.loading}>
                  Request New Password
                  </Preloader>
                </button>
               
              </React.Fragment>
            ) : (
                <React.Fragment>
                  <h3 className="text-lg">
                    Success! Check your Email, we send you link for restore
                    password!
                </h3>
                </React.Fragment>
              )}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    allowRestorePassword: state.account.allow,
    loading: state.loading.registerLoading
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    navigate: (path) => push(path),
    LoadingRegisterData
  },
    dispatch
  );

export const ForgotPassword = compose(
  withRouter,

  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPasswordComponent);
