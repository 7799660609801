//Dates
export const DATEFORMATHOURS = 'DD MMM YYYY HH:mm';
export const DATEFORMATZONE = 'DD MMM YYYY HH:mm Z';
export const DATEFORMAT = 'DD MMM YYYY';

//contract creation items
export const MEASUREMENTS = {
    'TONS': 'MT',
    'CWT': 'Cwt',
    //TODO remove this entry for prod
    'MT': 'MT',
};

export const THAILAND_RICE = {
    'TWR 100 pct grade b': 'TWR 100 pct grade b',
    'TWR 5 pct': 'TWR 5 pct',
    'TWR 10 pct': 'TWR 10 pct',
    'TWR 15 pct': 'TWR 15 pct',
    'TWR 25 pct': 'TWR 25 pct',
    'A1 super brokens': 'A1 super brokens',
    'A1 special brokens': 'A1 special brokens',
    'A1 Fragrant brokens': 'A1 Fragrant brokens',
    'Thai Homail rice grade A': 'Thai Homail rice grade A',
    'Thai Glutinous rice': 'Thai Glutinous rice',
    'Thai Parboiled rice 100 pct': 'Thai Parboiled rice 100 pct',
    'Thai Parboiled rice 5 pct sortexed': 'Thai Parboiled rice 5 pct sortexed',
    'Thai Fragrant rice': 'Thai Fragrant rice'
};

export const VIETNAM_RICE = {
    'Vietnam WR wm dp 5 pct': 'Vietnam WR wm dp 5 pct',
    'Vietnam WR 10 pct': 'Vietnam WR 10 pct',
    'Vietnam WR 15 pct': 'Vietnam WR 15 pct',
    'Vietnam WR 20 pct': 'Vietnam WR 20 pct',
    'Vietnam brokens 100 pct': 'Vietnam brokens 100 pct',
    'Vietnam Jasmin rice 5 pct': 'Vietnam Jasmin rice 5 pct',
    'Vietnam Jasmin brokens': 'Vietnam Jasmin brokens',
    'Vietnam Fragrant rice 5 pct': 'Vietnam Fragrant rice 5 pct',
    'Vietnam Parboiled rice 100 pct': 'Vietnam Parboiled rice 100 pct'
};

export const MYANMAR_RICE = {
    'Emata LG 100 pct': 'Emata LG 100 pct',
    'Emata LG 5 pct': 'Emata LG 5 pct',
    'Emata LG 10 pct': 'Emata LG 10 pct',
    'Emata LG 15 pct': 'Emata LG 15 pct',
    'Emata LG 20 pct': 'Emata LG 20 pct',
    'Emata LG 25 pct': 'Emata LG 25 pct',
    'Zeera LG 100 pct': 'Zeera LG 100 pct',
    'Zeera LG 5 pct': 'Zeera LG 5 pct',
    'Zeera LG 10 pct': 'Zeera LG 10 pct',
    'Zeera LG 15 pct': 'Zeera LG 15 pct',
    'Zeera LG 20 pct': 'Zeera LG 20 pct',
    'Zeera LG 25 pct': 'Zeera LG 25 pct',
    'Nga Sein 5 pct': 'Nga Sein 5 pct',
    'Nga Sein 10 pct': 'Nga Sein 10 pct',
    'Nga Sein 15 pct': 'Nga Sein 15 pct',
    'Nga Sein 20 pct': 'Nga Sein 20 pct',
    'Nga Sein 25 pct': 'Nga Sein 25 pct',
    'Paw San M&GG 100 pct': 'Paw San M&GG 100 pct',
    'Paw San M&GG 5 pct': 'Paw San M&GG 5 pct',
    'Paw San M&GG 10 pct': 'Paw San M&GG 10 pct',
    'Parboiled Rice LGPR 100 pct': 'Parboiled Rice LGPR 100 pct',
    'Parboiled Rice LGPR 5 pct': 'Parboiled Rice LGPR 5 pct',
    'Parboiled Rice LGPR 10 pct': 'Parboiled Rice LGPR 10 pct',
    'Brown L 5 pst': 'Brown L 5 pst',
    'Broken Rice 100 pct': 'Broken Rice 100 pct'
};

export const PAKISTAN_RICE = {
    'WHITE LONG GRAIN (RAW) 5%': 'WHITE LONG GRAIN (RAW) 5%',
    'WHITE LONG GRAIN (RAW) 10%': 'WHITE LONG GRAIN (RAW) 10%',
    'WHITE LONG GRAIN (RAW) 15%': 'WHITE LONG GRAIN (RAW) 15%',
    'WHITE LONG GRAIN (RAW) 25%': 'WHITE LONG GRAIN (RAW) 25%',
    'WHITE LONG GRAIN (RAW) 100%': 'WHITE LONG GRAIN (RAW) 100%',
    'BASMATI LONG GRAIN WHITE (RAW) 5%': 'BASMATI LONG GRAIN WHITE (RAW) 5%',
    'BASMATI LONG GRAIN WHITE (RAW) 10%': 'BASMATI LONG GRAIN WHITE (RAW) 10%',
    'BASMATI LONG GRAIN WHITE (RAW) 100%': 'BASMATI LONG GRAIN WHITE (RAW) 100%',
    'BASMATI EXTRA LONG GRAIN BROWN (CARGO) 5%': 'BASMATI EXTRA LONG GRAIN BROWN (CARGO) 5%',
    'BASMATI EXTRA LONG GRAIN BROWN (CARGO) 10%': 'BASMATI EXTRA LONG GRAIN BROWN (CARGO) 10%',
    'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 5 pct broken': 'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 5 pct broken',
    'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 10 pct broken': 'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 10 pct broken',
    'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 100 pct broken': 'BASMATI EXTRA LONG GRAIN MILLED PARBOILED RICE (SELA) 100 pct broken'
};

export const TYPES_OF_RICE = {
    VN: VIETNAM_RICE,
    TH: THAILAND_RICE,
    MM: MYANMAR_RICE,
    PK: PAKISTAN_RICE
};

export const PAYMENTTERMSOPT = {
    'Documentary presentation': 'Documentary Presentation',
    'Letter of credit': 'Letter of Credit',
    'Confirmed letter of credit': 'Confirmed letter of Credit',
    'CAD': 'CAD',
    'Avalised Draft': 'Avalised Draft'
};

export const PAYMENTPERIODOPT = {
    'At sight': 'At sight',
    '30 days': '30 days from bill of lading date',
    '60 days': '60 days from bill of lading date',
    '90 days': '90 days from bill of lading date',
    '120 days': '120 days from bill of lading date',
    '150 days': '150 days from bill of lading date',
    '180 days': '180 days from bill of lading date'
};

export const DOWNPAYMENTPERIODOPT = {
    '1 working day': '1 working day',
    '2 working days': '2 working days',
    '3 working days': '3 working days',
    '4 working days': '4 working days',
    '5 working days': '5 working days'
};

export const DOWNPAYMENT = {
    '0': '0%',
    '5': '5%',
    '10': '10%',
    '15': '15%',
    '20': '20%',
    '25': '25%',
    '30': '30%',
    '35': '35%',
    '40': '40%',
    '45': '45%',
    '50': '50%',
    '55': '55%',
    '60': '60%',
    '65': '65%',
    '70': '70%',
    '75': '75%',
    '80': '80%',
    '85': '85%',
    '90': '90%',
    '95': '95%',
    '100': '100%'
};

export const getDischargeLabel = (incoterms) => {
    if (DISCHARGE_LABEL[incoterms]) {
        return DISCHARGE_LABEL[incoterms];
    }
    return "";
}

export const getDischargeRateLabel = (incoterms, discharge) => {
    if (DISCHARGE_RATE_LABEL[incoterms] && DISCHARGE_RATE_LABEL[incoterms][discharge]) {
        return DISCHARGE_RATE_LABEL[incoterms][discharge];
    }
    return "";
}

export const DISCHARGE_RATE_LABEL = {
    CIF: {
        'CQD': 'Demurrage rate /Weather working day in USD',
        'Discharge rate': 'Discharge rate details'
    },
    CFR: {
        'CQD': 'Demurrage rate/Weather working day in USD',
        'Discharge rate': 'Discharge rate details'
    },
    FOB: {
        'CQD load': 'Demurrage rate /Weather working day in USD',
        'loadrate': 'Load rate details'
    }
}

export const DISCHARGE_LABEL = {
    CIF: 'Discharge Terms',
    CFR: 'Discharge Terms',
    FOB: 'Load Terms'
}

export const INCOTERMOPT = {
    CIF: 'CIF',
    FOB: 'FOB',
    CFR: 'CFR'
};

export const DISCHARGE_VALUES = {
    'CQD load': 'CQD',
    loadrate: 'Load Rate',
    'CQD': 'CQD',
    'Discharge rate': 'Discharge Rate'
};

export const DISCHARGEFOB = {
    'CQD load': 'CQD Load',
    loadrate: 'Load Rate'
};
export const DISCHARGECIF = {
    'CQD': 'CQD Discharge',
    'Discharge rate': 'Discharge Rate'
};
export const PACKAGING = {
    BAGGED_PALLET_25: 'Bagged and Pallet - 25 kg',
    BAGGED_PALLET_50: 'Bagged and Pallet - 50 kg',
    BAGGED_PALLET_500: 'Bagged and Pallet - 500 kg',
    BAGGED_PALLET_600: 'Bagged and Pallet - 600 kg',
    BAGGED_PALLET_1000: 'Bagged and Pallet - 1000 kg',
    BAGGED_PALLET_1200: 'Bagged and Pallet - 1200 kg',
    BAGGED_PALLET_OTHER: 'Bagged and Pallet - other',
    BAGGED_25: 'Bagged - 25 kg',
    BAGGED_50: 'Bagged - 50 kg',
    BAGGED_500: 'Bagged - 500 kg',
    BAGGED_600: 'Bagged - 600 kg',
    BAGGED_1000: 'Bagged - 1000 kg',
    BAGGED_1200: 'Bagged - 1200 kg',
    BAGGED_OTHER: 'Bagged - other',
    BULK: 'Bulk',
    // OTHER: 'Oher'
};
export const REQUESTTYPES = {
    BUY: 'Buy',
    SELL: 'Sell'
};

export const OFFER_TYPES = {
    'FIRM': 'Firm',
    'SUBJECT_CONFIRMATION': 'Subject Confirmation'
}

export const REQUEST_PRIVACY_TYPE = {
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    MULTI_PRIVATE: "Multi-Party Private",
    TENDER: 'Tender'
};
//Create array for order list in privacy and basis of request type
export const requestPrivacyType = (requestType) => {
    var PRIVACY_TYPE = [];
    if (requestType === "SELL") {
        PRIVACY_TYPE = [
            { label: 'Public', value: "PUBLIC" },
            { label: 'Private', value: "PRIVATE" }
        ]
    }
    else {
        PRIVACY_TYPE = [
            { label: 'Public', value: "PUBLIC" },
            { label: 'Private', value: "PRIVATE" },
            { label: 'Multi-Party Private', value: "MULTI_PRIVATE" },
            { label: 'Tender', value: "TENDER" },
        ]
    }
    return PRIVACY_TYPE;
}
export const SHIPPINGTYPES = {
    VESSEL: 'Vessel',
    CONTAINER: 'Container'
};
export const COMPANYTYPES = [
    'Exporter',
    'Importer',
    'Distributor',
    'Trader',
    'Bank',
    'Insurance Company',
    'Inspection Company',
    'Fumigation Company',
    'Stevedoring Company',
    'Shipping Company',
    'Customs Broker'
];

//trade status
export const STEPS = [
    'DEAL',
    'SIGNED',
    'PROFORMA_INVOICE',
    'VESSEL_NOMINATED',
    'INSTRUCTIONS',
    'ADVICE',
    'DOCUMENTS',
    'PAYED',
    'PENDING COMPLETION',
    'CLOSING',
    'CLOSED'
];

export const TRADE_STATUS = {
    DEAL: 'DEAL',
    SIGNED: 'SIGNED',
    PROFORMA_INVOICE: 'PROFORMA_INVOICE',
    VESSEL_NOMINATED: 'VESSEL_NOMINATED',
    INSTRUCTIONS: 'INSTRUCTIONS',
    ADVICE: 'ADVICE',
    DOCUMENTS: 'DOCUMENTS',
    PAYMENT: 'PAYMENT',
    PAYED: 'PAYED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED'
};

export const TRADE_STATUS_HUMANIZE = {
    DEAL: 'PENDING SIGNATURE',
    SIGNED: 'PROFORMA INVOICE',
    PROFORMA_INVOICE: 'VESSEL NOMINATION REQUIRED',
    REJECTED: 'VESSEL NOMINATION REJECTED',
    VESSEL_NOMINATED: 'DOCUMENTARY INSTRUCTIONS REQUIRED',
    INSTRUCTIONS: 'SHIPPING ADVICE PENDING',
    ADVICE: 'DOCUMENTS REQUIRED',
    DOCUMENTS: 'PAYMENT REQUIRED',
    PAYMENT: 'PAYMENT REQUIRED',
    PAYED: 'PENDING COMPLETION',
    CLOSED: 'CLOSED'
};

export const YES_NO = {
    YES: true,
    NO: false
}

export const COMPANY_TYPES = {
    EXPORTER: "Exporter",
    IMPORTER: "Importer",
    DISTRIBUTOR: "Distributor",
    TRADER: "Trader",
    BANK: "Bank",
    INSURANCE: "Insurance Company",
    INSPECTION: "Inspection Company",
    FUMIGATION: "Fumigation Company",
    STEVEDORING: "Stevedoring Company",
    SHIPPING: "Shipping Company",
    CUSTOMS_BROKER: "Customs Broker",
    OTHER: "Other"
};

export const COMPANY_TYPES_REG = {
    EXPORTER: "Exporter",
    IMPORTER: "Importer",
    DISTRIBUTOR: "Distributor",
    TRADER: "Trader",
    OTHER: "Other"
};

export const KYC_STATUS = {
    PENDING: "PENDING",
    EDITING: "EDITING",
    DRAFT: "DRAFT"
};

export const ADDRESSPROOF_TYPES = {
    CREDITCARD: "Credit Card Statement",
    BANKSTATEMENT: "Bank Statement",
    ELECTRICITYBILL: "Electricity Bill",
    WATERGASBILL: "Water/Gas Bill",
    TAXLETTERBILL: "Tax letter/Bill"
};

export const RELATIONSHIP_TYPES = {
    PARENT: "Parent",
    SUBSIDARY: "Subsidary",
    OTHER: "Other",
};

export const DESTINATION_ZONE = {
    AFRICA: 'Africa',
    OTHER: 'Other'
};

export const INSURANCECOVER_TYPES = {
    FPA: 'FPA',
    ALL_RISK: 'ALL RISK'
};

export const CURRENCYOPT = {
    US$: "USD",
    EURO: "EUR"
}

export const QUOTETYPE = {
    FINAL: "FINAL",
    PROVISIONAL: "PROVISIONAL"
}
export const QUOTESTATUS = {
    NEW: "NEW",
    CANCELLED: "CANCELLED",
    COMPLETE: "COMPLETE",
    COMPLETED: "COMPLETED",
    REJECTED: "REJECTED",
    INPROGRESS: "INPROGRESS",
    WAITING_CONFIRMATION: "WAITING_CONFIRMATION"
}
export const PERMISSION_VALUE = {
    CREATE_TRADEREQUEST: "createTradeRequest",
    VIEW_TRADEREQUEST: "viewTradeRequest",
    VIEW_EXCHANGE: "viewExchange",
    NEGOTAITE_TRADEREQUEST: "negotiateTradeRequest",
    REQUEST_SHIPPPING_QUOTE: "requestShippingQuote",
    VIEW_SHIPPPING_QUOTE: "viewShippingQuote",
    VIEW_TRADE_LIST: "viewTradeList",
    VIEW_COMPANY_PROFILE: "viewCompanyProfile",
    NEGOTIATE_TRADEREQUEST: "negotiateTradeRequest",
    VIEW_INSPECTION_QUOTE: "viewInspectionQuote",
    MANAGE_TENDER_WHITELIST: "manageTenderWhitelist"
}

export const PERMISSION_NAME = {
    VIEW_EXCHANGE: "View Exchange",
    VIEW_TRADEREQUEST: "View Trade Request",
    CREATE_TRADEREQUEST: "Create Trade Request",
}

export const BRAND_SCAC = {
    mcpu: "MaerskLine",
    sejj: "Safmarine",
    maeu: "Sealand Asia",
    seau: "Sealand Europe",
    safm: "Sealand Americas"
}

export const TENDER_SUBSCRIPTIONS_STATUS = {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected'
}

export const ERROR_MESSAGES = {
    CONFIRM_BID_TERMS: "Please review the above terms and conditions and check the checkbox that you acknowledge them prior to confirming",
    SELECT_ONE_BID_ORIGIN: "Please select an origin for which you want to submit price"
}

export const COUNTER_TYPES = {
    NORMAL: 'Normal Counter',
    BANK_ONLY: 'Bank Only Counter',
    IMPROVE_PRICE: 'Improving Price Counter',
    SUBJECT_CONFIRMATION: 'Accept Offer with Subject Confirmation'
}

export const INSPECTOR_COUNTRY_MAP = {
    // "US":{
    //     USDA: "USDA"
    // },
    "ALL":{
        SELLER_SUPPLIED: "Seller Supplied Inspection Company"
    }
}

export const NON_INTEGRATED_INSPECTORS = {
    ...(Object.values(INSPECTOR_COUNTRY_MAP).reduce((init, value) => {
        return {...init, ...value}
    }, {}))
}

export const getNonIntegratedInspector = (entry) => {
    const isArrayEntered = Array.isArray(entry);
    if((isArrayEntered && entry.length === 1) || typeof entry === "string"){ 
        return {
            ...(INSPECTOR_COUNTRY_MAP[isArrayEntered ? entry[0] : entry] || {}),
            ...(INSPECTOR_COUNTRY_MAP["ALL"])
        }
    } 
    return INSPECTOR_COUNTRY_MAP["ALL"];
}

export const TOOLTIP_TITLE = {
    NON_INSPECTOR_TITLE: "This inspection company is not an integrated inspector on the platform.Consequently following functionality will not be available: Automatic nomination and notifications, daily inspection reports, verification of the uploading party, and verification of authenticity of the reports or certificates rendered",
    NON_INSPECTOR_NOMINATION_TITLE: "This inspection company is not an integrated on the platform and therefore the inspector can not receive automatic notifications.Therefore you need to inform them of the nomination by email or other means"
}
export const quoteModalsReactSelectStyle = {
    control: (provided) => ({ ...provided, height: 34, minHeight: 'initial' }),
    placeholder: (provided) => ({ ...provided, marginTop: -2 }),
    singleValue: (provided) => ({ ...provided, marginTop: -2 }),
    input: (provided) => ({ ...provided, marginTop: -5 }),
    indicatorSeparator: (provided) => ({ ...provided, marginTop: 5, marginBottom: 8 }),
    dropdownIndicator: (provided) => ({ ...provided, paddingTop: 0 }),
    menu: (provided) => ({ ...provided, width: '150%', border: '1px solid black' }),
    menuList: (provided) => ({ ...provided, maxHeight: 250 }),
    option: (provided) => ({ ...provided, padding: '0 12px', "&:hover": '0,12px' })
}

export const TERM_SHIPPING = [
    { label: 'SHEX UU ', value: "SHEX UU" },
    { label: 'SHEX EIU', value: "SHEX EIU" },
    { label: 'SSHEX UU', value: "SSHEX UU" },
    { label: 'SSHEX EIU', value: "SSHEX EIU" },
    { label: 'FHEX UU', value: "FHEX UU" },
    { label: 'FHEX EIU', value: "FHEX EIU" },
    { label: 'SHINC', value: "SHINC" },
    { label: 'FHINC', value: "FHINC" },
]

export const COMMISSION_SHIPPING = [
    { label: '0%', value: 0 },
    { label: '1.25%', value: 1.25 },
    { label: '2.5%', value: 2.5 },
    { label: '3.75%', value: 3.75 },
    { label: '5%', value: 5 }
]

export const REGION_OF_ORIGIN={
    NORTH_AMERICA:"North America",
    SOUTH_AMERICA:"South America",
    RUSSIA_FSU_BALTIC:"Russia/FSU/Baltic",
    EUROPE:"Europe",
    AG_MENA:"AG/MENA",
    AFRICA:"Africa",
    INDIA_SUB_CONTINENT:"India Sub Continent",
    ASIA:"Asia & Pacific",
    OCEANIA:"Oceania",
    OPEN:"OPEN",
}

export const BID_STATUS = {
    NEW: 'NEW',
    REVIEW: 'REVIEW',
    DECLINED: 'DECLINED',
    COUNTERED: 'COUNTERED',
    IMPROVED: 'IMPROVED',
    COUNTER: 'COUNTER',
    BANK_ONLY_NEW: 'BANK_ONLY_NEW',
    BANK_ONLY_COUNTERED: 'BANK_ONLY_COUNTERED',
    PEND_CONFIRM_NEW: 'PEND_CONFIRM_NEW',
    PEND_CONFIRM_COUNTERED: 'PEND_CONFIRM_COUNTERED',
    ORIG_CONTRACT_DATA: 'ORIG_CONTRACT_DATA',
    ORIG_TRADE_DATA: 'ORIG_TRADE_DATA',
    DEAL: 'DEAL',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED'
}


export const TOLERANCE = {
    '0':'Min/Max',
    '5':'+/- 5%',
    '10':'+/- 10%',
    'OTHER':'Other'
}