import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { Layout } from '../index';
import './register.scss';
import { RegisterUser } from './RegisterUser';
import { AccountApi } from '../../../services';
import logo from '../../../static/img/logo.png';
import { AppErrors } from '../../components/errors';
import { LoadingRegisterData } from '../../../modules/module.loading';
import { ValidateChangePasswordCode } from '../../../modules/module.account';

class RegisterUserByInvite extends Component {
    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);

        let user = {};
        let isEmpty = value => !value || value === undefined || value === '';

        this.initField(user, 'firstName', 'First Name', '', isEmpty);
        this.initField(user, 'lastName', 'Last Name', '', isEmpty);
        this.initField(user, 'companyRole', 'Company Role', '', isEmpty);
        this.initField(user, 'phone', 'Phone', '', isEmpty);
        this.initField(user, 'password', 'Password', '', isEmpty);
        this.initField(user, 'confirmPassword', 'Confirm Password', '', isEmpty);

        this.state = {
            user: user,
            required: null,
            serverError: '',
            showGreetings: false,
            errors: {}
        };
    }

    UNSAFE_componentWillMount() {
        this.validateCode();
    }

    validateCode = () => {
        this.props.ValidateChangePasswordCode(this.props.match.params.code, response => {
            if (!response || !response.canConfirm) {
                this.props.navigate('/');
            }
        });
    };

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }

    componentDidMount() {
    }

    setUserField(name, value) {
        let user = this.state.user;
        let field = user[name];
        field.value = value;
        this.setState({ user, required: null });
    }

    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    validatePassword() {
        const errors = {};
        let { password, confirmPassword } = this.state.user;

        if (!password.value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*])(?=.{8,})"))
            errors['password'] = `Please enter valid password`;

        if (password.value !== confirmPassword.value) errors['password'] = `Password and confirm password doesn't match.`;

        return errors;
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            result[key] = container[key].value;
            return false;
        });
        return result;
    }

    submit() {
        this.props.LoadingRegisterData(true);

        const errors = this.validatePassword();
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            let userData = this.getStateValue(this.state.user);
            userData.code = this.props.match.params.code;
            AccountApi.register({
                user: userData
            })
                .then(() => {
                    this.props.LoadingRegisterData(false);
                    this.setState({
                        showGreetings: true
                    });
                }, () => {
                    this.props.LoadingRegisterData(false);
                    this.setState({
                        serverError: AppErrors["internal_server_error"]
                    });
                })
                .catch(error => {
                    this.props.LoadingRegisterData(false);
                    this.setState({
                        serverError: AppErrors["internal_server_error"]
                    });
                });
        }
    }

    render() {
        return (
            <Layout {...this.props}>
                <div className="register">
                    <ul className="account-tabs">
                        <li className="account-tab active">Register User</li>
                    </ul>
                    {this.state.showGreetings ? (
                        <div className="user-greetings">
                            <h3 className="text-lg">
                                Please check your e-mail to find confirmation of your registration.
                            </h3>
                            <Link className="fp-link login-forgot" to="/">
                                Sign In
                            </Link>
                        </div>
                    ) : (
                            <div className="register-wrapper">
                                <div className="register-logo">
                                    <img src={logo} alt="Fertilizer exchange logo" />
                                </div>
                                <div className="row">
                                    <ul className="errors">
                                        {this.state.errors.password && <li>{this.state.errors.password} </li>}
                                        {this.state.serverError && <li>{this.state.serverError}</li>}</ul>
                                    <RegisterUser
                                        isRegistering={this.props.loading}
                                        state={this.state.user}
                                        setField={(name, value) => this.setUserField(name, value)}
                                        validation={this.state.required}
                                        onNext={e => {
                                            e.preventDefault();
                                            if (this.validate(this.state.user)) {
                                                this.submit();
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                        )}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    loading: state.loading.registerLoading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: () => push('/'),
            LoadingRegisterData,
            ValidateChangePasswordCode
        },
        dispatch
    );

export const RegisterByInvite = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegisterUserByInvite);
