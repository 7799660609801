import socketIOClient from "socket.io-client";
import { AccountApi } from '../services';
import { WS } from "../modules/module.ws";
import { call, take, put, delay, fork, cancel, cancelled } from 'redux-saga/effects'
import { eventChannel } from "redux-saga";

export const WS_URL = process.env.REACT_APP_API_URL;
const WsNamespace = '/ws'

function createEventChannel(socket) {
    return eventChannel(emit => {
        socket.on('connect', () => {
            //console.log("On connect");
        });
        socket.on('disconnect', (reason) => {
            //console.log("On disconnect");
        });
        socket.on('error', (error) => {
            //console.log('On error : ', error);
        });
        socket.on('notification', (msg) => {
            //console.log('On message : ', msg);
            emit(msg);
        });
        socket.on('logout', () => {
            //console.log("On logout");
            socket.disconnect();
            AccountApi.logout();
            window.location.href = '/';
        });
        return () => {
            socket.close();
            //console.log('On Socket closed..');
        };
    });
}

function* initializeNotifyWSChannel() {
    let socket;
    let channel;
    try {
        //console.log("Connecting :", WS_URL);
        //console.log('Jwt token :', sessionStorage.getItem("jwt"));
        socket = socketIOClient(WS_URL + WsNamespace, {
            query: {
                token: sessionStorage.getItem("jwt")
            }
            /*,forceNew: true , transports: ["websocket"]*/
        });
        channel = yield call(createEventChannel, socket);
        while (true) {
            const msg = yield take(channel);
            //console.log("msg", msg);
            const jsonMsg = JSON.parse(msg);
            yield checkMsgAndPut(jsonMsg);
        }
    } catch (e) {
        console.log(e);
    } finally {
        if (yield cancelled()) {
            //console.log('Logout');
            // close the channel
            channel.close();

            // close the WebSocket connection
            socket.close();
        } else {
            yield fork(reconnect);
        }
    }
}

const checkMsgAndPut = msg => {
    return put({
        type: "notification/NEW",
        payload: msg
    });
};

function* reconnect() {
    //console.log('reconnecting...');

    yield delay(1000);
    //const createWebSocketTask = yield fork(initializeNotifyWSChannel);
    yield take(WS.Type.Stop);
    //yield cancel(createWebSocketTask)
}

export function* WSSaga() {
    // yield takeEvery(WS.Type.Start, initializeNotifyWSChannel);
    while (yield take(WS.Type.Start)) {
        const createWebSocketTask = yield fork(initializeNotifyWSChannel);
        yield take(WS.Type.Stop);
        yield cancel(createWebSocketTask);
    }
}