import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
//import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
//import CustomMaterialPreloader from '../../components/preloader/CustomMaterialPreloader';

import { FetchNewTrade, FetchTrade, UpdateRequest } from '../../../modules/module.trade';

import { DATEFORMAT, INCOTERMOPT, SHIPPINGTYPES, REQUEST_PRIVACY_TYPE } from '../../../services/service.values';
import { FertilizerService } from '../../../services/service.fertilizer';
import { viewExchangePermission } from '../../../services/service.permission';
import { EnumsService, renderSelectedOrigins } from "../../../services/service.utils";

import './exchange.scss';
import { faTimes, faHistory, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import FirstPage from '@material-ui/icons/FirstPage';
// import LastPage from '@material-ui/icons/LastPage';
// import FilterList from '@material-ui/icons/FilterList';
// import ArrowUpward from '@material-ui/icons/ArrowUpward';
// import Search from '@material-ui/icons/Search';
import NumberFormat from 'react-number-format';
import TradeQuantityFormatter from '../../components/Formatter/TradeQuantityFormatter';

import ExchangeView from './ExchangeView';

const Countries = EnumsService.countries();

const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

//Render functions
const sortRef = (a, b) => {
    let refA = a.tradeRefId || a.id;
    let refB = b.tradeRefId || b.id;
    return refA.localeCompare(refB);
};
const sortQuantity = (a, b) => (parseInt(a.measure) - parseInt(b.measure));
const renderOverflow = (content) => <span className="exchange__overflow">{content}</span>
const renderOrigin = rowData => renderSelectedOrigins(rowData.origin);
const renderPort = data => {
    if (data.incoterm === INCOTERMOPT.FOB) {
        return renderOverflow((data.loadPort && data.loadCountry) ? `${data.loadPort}, ${Countries[data.loadCountry]}` : '-');
    } else {
        return renderOverflow((data.destPort && data.destCountry) ? `${data.destPort}, ${Countries[data.destCountry]}` : '-');

    }
}
const renderPrice = rowData => {
    if(rowData.bestPrice) return 'Best Price';
    return(
        <NumberFormat 
            value={rowData.price} 
            displayType={'text'} 
            thousandSeparator={true} 
            prefix={rowData.currency === 'USD' || rowData.currency === 'US$' ? 'USD ' : 'EUR '} 
            renderText={value => <span className="exchange__overflow">{value}</span>} 
        />
    )
}

//TODO TO EXTERNALIZE IN A TRADE SERVICE
const columns = {
    REF: { title: 'Trade Ref Id', field: 'tradeRefId', type: 'string', customSort: sortRef, render: rowData => rowData.tradeRefId || rowData.id },
    STATUS: { title: 'Status', field: 'status' },
    BUYER: { title: 'Buyer', field: 'buyer', render: rowData => renderOverflow(rowData.buyer) },
    SELLER: { title: 'Seller', field: 'seller', render: rowData => renderOverflow(rowData.seller) },
    UPDATED_AT: { title: 'Updated At', field: 'updatedAt', type: 'date', hidden: true, defaultSort: 'desc' },
    SHIPPING_TYPE: { title: 'Vessel/ container', field: 'shipping', render: rowValue => SHIPPINGTYPES[rowValue.shipping] },
    QUANTITY: { title: 'Quantity(MT)', filed: 'measure', customSort: sortQuantity, render: rowData => <TradeQuantityFormatter trade={rowData} /> },
    PRICE: { title: 'Price', field: 'price', type: 'numeric', render: rowData => renderPrice(rowData) },
    ORIGIN: { title: 'Origin', field: 'origin', render: renderOrigin },
    INCOTERM: { title: 'Incoterm', field: 'incoterm' },
    PORT: { title: 'Port of load/ Destination', field: 'destPort', render: rowData => renderPort(rowData) },
    FERTILIZER_TYPE: { title: 'Fertilizer Type', field: 'fertilizerType', render: rowData => renderOverflow(FERTILIZER_TYPE[rowData.fertilizerType] || rowData.fertilizerType) },
    SHIPPING_FROM: { title: 'Shipping/ Delivery Period Start', field: 'deliveryStartDate', render: rowData => renderOverflow(moment(rowData.deliveryStartDate).format(DATEFORMAT)) },
    SHIPPING_TO: { title: 'Shipping/ Delivery Period End', field: 'deliveryEndDate', render: rowData => renderOverflow(moment(rowData.deliveryEndDate).format(DATEFORMAT)) },
    PRIVACY_TYPE: { title: 'Privacy', field: 'privacyType', render: rowData => renderOverflow(REQUEST_PRIVACY_TYPE[rowData.privacyType]) }
}

//MATERIAL TABLE WRITES IN THE COLUMN OBJECT, this object has to be copy and not a reference to the generic columns -> spread operator
const columnsLightBuy = [{ ...columns.UPDATED_AT }, { ...columns.FERTILIZER_TYPE }, { ...columns.SHIPPING_FROM }, { ...columns.SHIPPING_TO }, { ...columns.QUANTITY }, { ...columns.PRICE }, { ...columns.PRIVACY_TYPE }];
const columnsLightSell = [{ ...columns.UPDATED_AT }, { ...columns.FERTILIZER_TYPE }, { ...columns.SHIPPING_FROM }, { ...columns.SHIPPING_TO }, { ...columns.QUANTITY }, { ...columns.PRICE }, { ...columns.PRIVACY_TYPE }];
const columnsFullBuy = [{ ...columns.UPDATED_AT }, { ...columns.REF }, { ...columns.BUYER }, { ...columns.SHIPPING_TYPE }, { ...columns.QUANTITY }, { ...columns.PRICE },
{ ...columns.ORIGIN }, { ...columns.INCOTERM }, { ...columns.PORT }, { ...columns.FERTILIZER_TYPE }, { ...columns.SHIPPING_FROM }, { ...columns.SHIPPING_TO }, { ...columns.PRIVACY_TYPE }];
const columnsFullSell = [{ ...columns.UPDATED_AT }, { ...columns.REF }, { ...columns.SELLER }, { ...columns.SHIPPING_TYPE }, { ...columns.QUANTITY }, { ...columns.PRICE },
{ ...columns.ORIGIN }, { ...columns.INCOTERM }, { ...columns.PORT }, { ...columns.FERTILIZER_TYPE }, { ...columns.SHIPPING_FROM }, { ...columns.SHIPPING_TO }, { ...columns.PRIVACY_TYPE }];

const StyledMTableToolbar = withStyles({
    root: {
        paddingLeft: '0px',
        background: 'rgb(246, 246, 248)',
    }
})(MTableToolbar);

class Exchange extends React.Component {
    constructor(props) {
        super(props);
        this.highLightOwner = this.highLightOwner.bind(this);

    }
    state = {
        showModal: true,
        modal: '',
        modalData: {},
        tenderOnly: false
    };

    componentDidMount = () => {
        if (this.props.trade.lastUpdate) {
            this.props.FetchNewTrade();
        }
    };

    renderModal = () => {
        return (<div className="modal__container">
            <form className="modal__wrapper px-0 py-0 authcode"
                onSubmit={e => { e.preventDefault(); this.closeModal(); }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </Link>
                    </div>
                    <div className="modal-body">
                        <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} />Cancel</Link>
                    </div>
                </div>
            </form>
        </div>);
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    highLightOwner = (rowData, companyId) => {
        if (rowData.ownerId === companyId) {
            return { backgroundColor: '#00ff301c' }
        } else {
            return { backgroundColor: '#FFF' }
        }
    }

    render() {
        const { navigate } = this.props
        
        const { trade, user } = this.props;
        const toggleLayoutSellLabel = this.state.layoutSellFull ? 'Show less infos' : 'Show more infos';
        const toggleLayoutBuyLabel = this.state.layoutBuyFull ? 'Show less infos' : 'Show more infos';

        if (viewExchangePermission() === 0) {
            return (<div>{this.state.showModal && this.renderModal()}</div>);
        } else {
            return (
              <React.Fragment>
                <Header />
                {window.location.pathname.includes('exchange') && (
                  <ExchangeView {...{ navigate, user }} />
                )}
                {/*
                <div className="container-fluid exchange">
                   <div className="row">
                            <div className="col-sm-12">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input"
                                        onChange={e => this.setState(({ tenderOnly }) => ({ tenderOnly: !tenderOnly }))} id="customSwitch1" />
                                    <label className="custom-control-label" htmlFor="customSwitch1">Tenders Only</label>
                                </div>
                            </div>
                            <div id="ExchangeBuyingIntrest" hidden={this.state.layoutSellFull} className={`${this.state.layoutBuyFull ? 'col-md-12' : 'col-lg-6'} pgn buying`}>
                                <MaterialTable
                                    key={
                                    this.state.layoutBuyFull ? 'expanded' : 'collapsed'
                                    }
                                    title={
                                    <span style={{ fontSize: '2em' }}>
                                        Public Request: <b>Buying Interest</b>
                                    </span>
                                    }
                                    isLoading={
                                    trade.paginatedItems.PUBLIC_RTB.pending ||
                                    trade.pendingNew
                                    }
                                    columns={
                                    this.state.layoutBuyFull
                                        ? columnsFullBuy
                                        : columnsLightBuy
                                    }
                                    data={_.chain(trade.tradeMap)
                                    .values()
                                    .filter(
                                        (item) =>
                                        item.requestType === 'BUY' &&
                                        item.status === 'NEW' &&
                                        [
                                            'TENDER',
                                            ...(!this.state.tenderOnly ? ['PUBLIC'] : []),
                                        ].includes(item.privacyType)
                                    )
                                    .value()}
                                    onRowClick={(e, rowData) => {
                                        this.props.UpdateRequest(rowData);
                                        this.props.navigate(`/exchange/details/${rowData.id}`)
                                    }}
                                    options={{
                                        rowStyle: (rowData) => { return this.highLightOwner(rowData, user.companyId) },
                                        search: false,
                                        sorting: true,
                                        pageSize: 10,
                                        paginationType: 'normal',
                                        pageSizeOptions: [10, 20, 30],
                                        thirdSortClick: false
                                    }}
                                    components={{
                                        OverlayLoading: CustomMaterialPreloader,
                                        Toolbar: StyledMTableToolbar
                                    }}
                                    icons={{
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward,
                                        Search: Search,
                                    }}
                                    actions={[
                                        {
                                            icon: () => <FontAwesomeIcon icon={faSyncAlt} />,
                                            tooltip: 'Refresh Trades',
                                            isFreeAction: true,
                                            onClick: (event) => { this.props.FetchNewTrade("PUBLIC_RTB") }
                                        },
                                        {
                                            icon: () => <FontAwesomeIcon icon={faHistory} />,
                                            tooltip: 'Load More Trades',
                                            isFreeAction: true,
                                            onClick: (event) => { this.props.FetchTrade("PUBLIC_RTB") }
                                        },
                                        {
                                            icon: () => { return this.state.layoutBuyFull ? <ChevronLeft /> : <ChevronRight /> },
                                            tooltip: toggleLayoutBuyLabel,
                                            isFreeAction: true,
                                            onClick: (event) => {
                                                this.setState(prevState => ({ layoutBuyFull: !prevState.layoutBuyFull }))
                                            }
                                        }]} />
                            </div>
                            <div id="ExchangeSellingIntrest" hidden={this.state.layoutBuyFull} className={`${this.state.layoutSellFull ? 'col-md-12' : 'col-lg-6'} pgn selling`}>
                                <MaterialTable
                                    key={
                                    this.state.layoutSellFull ? 'expanded' : 'collapsed'
                                    }
                                    title={
                                    <span style={{ fontSize: '2em' }}>
                                        Public Request: <b>Selling Interest</b>
                                    </span>
                                    }
                                    isLoading={
                                    trade.paginatedItems.PUBLIC_RTS.pending ||
                                    trade.pendingNew
                                    }
                                    columns={
                                    this.state.layoutSellFull
                                        ? columnsFullSell
                                        : columnsLightSell
                                    }
                                    data={_.chain(trade.tradeMap)
                                    .values()
                                    .filter(
                                        (item) =>
                                        item.requestType === 'SELL' &&
                                        item.status === 'NEW' &&
                                        [
                                            'TENDER',
                                            ...(!this.state.tenderOnly ? ['PUBLIC'] : []),
                                        ].includes(item.privacyType)
                                    )
                                    .value()}
                                    onRowClick={(e, rowData) =>
                                    this.props.navigate(`/exchange/details/${rowData.id}`)
                                    }
                                    options={{
                                        rowStyle: (rowData) => { return this.highLightOwner(rowData, user.companyId) },
                                        search: false,
                                        sorting: true,
                                        pageSize: 10,
                                        paginationType: 'normal',
                                        pageSizeOptions: [10, 20, 30],
                                        thirdSortClick: false
                                    }}
                                    components={{
                                        OverlayLoading: CustomMaterialPreloader,
                                        Toolbar: StyledMTableToolbar
                                    }}
                                    icons={{
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        SortArrow: ArrowUpward
                                    }}
                                    actions={[
                                        {
                                            icon: () => <FontAwesomeIcon icon={faSyncAlt} />,
                                            tooltip: 'Refresh Trades',
                                            isFreeAction: true,
                                            onClick: (event) => { this.props.FetchNewTrade("PUBLIC_RTS") }
                                        },
                                        {
                                            icon: () => <FontAwesomeIcon icon={faHistory} />,
                                            tooltip: 'Load More Trades',
                                            isFreeAction: true,
                                            onClick: (event) => { this.props.FetchTrade("PUBLIC_RTS") }
                                        },
                                        {
                                            icon: () => { return this.state.layoutSellFull ? <ChevronLeft /> : <ChevronRight /> },
                                            tooltip: toggleLayoutSellLabel,
                                            isFreeAction: true,
                                            onClick: (event) => { this.setState(prevState => ({ layoutSellFull: !prevState.layoutSellFull })) }
                                        }
                                    ]}
                                    style={{
                                        MuiToolbarRoot: { backgroundColor: 'rgb(246, 246, 248)' }
                                    }} />
                            </div>
                                </div>
                            </div> */}
                <Footer />
              </React.Fragment>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        user: state.account.user,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            UpdateRequest,
            navigate: path => push(path),
            FetchTrade,
            FetchNewTrade
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);