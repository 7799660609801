import React from 'react';
import { TradeApi } from '../../../../../services';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../requests/components/TradeImagePreview.scss';
import Companylogo from '../../../../../static/img/CompanyDefaultlogo.png';
import Preloader from "../../../../components/preloader/Preloader";
class ViewCompanyLogo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url:""
            loadingImage: false
        }
    }

    componentDidMount() {
        this.downLoadPreview(this.props.image);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.image !== this.props.image) {
            this.downLoadPreview(this.props.image)
        }
    }

    downLoadPreview = (image) => {
        TradeApi.getTradeDocument(image).then(res => {
            this.setState({
                loadingImage: true
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            this.setState({
                url: url,
                loadingImage: false
            });
        })
            .catch(e => {
                console.error(e)
            })
    }

    render() {
        return <React.Fragment>
            <div className="company-logo">
                {this.props.image ?
                    <React.Fragment>
                        <Preloader loadingStyle="swirl" loading={this.state.url === undefined ? true : false}>
                            <img className="rounded-circle mx-auto d-block" height="100" src={this.state.url} alt="Fertilizer example" />
                        </Preloader>
                    </React.Fragment>
                    :
                    <div className="rounded m-auto d-block w-100 h-100 text-center align-middle">
                        {/* {this.state.url &&
                        <div className="m-auto align-middle"><FontAwesomeIcon size="3x" icon={faSpinner} spin={this.state.loadingImage} /></div>} */}
                        <img className="rounded-circle mx-auto d-block" height="100" src={Companylogo} alt="Fertilizer example" />
                    </div>}
            </div>
        </React.Fragment>
    }
}
export default ViewCompanyLogo;