import React, { Component } from "react";
import {RicexCustomFileLabel} from "../FormFileUpload.module.scss";


class DropzoneCustom extends Component {
  constructor(props) {
    super(props);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.state = {
      file: ""
    }
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    this.setState({file: files[0]});
    if (this.props.onFilesAdded) {
      this.props.onFilesAdded(files);
    }
  }

  render() {
    return (
      <div className='form-row' >
        <div className="col">
          <div className="custom-file uploadbtn-browse">
            <input   
              id="inputFile" 
              type="file"
              onChange={this.onFilesAdded}
              className={'custom-file-input' + (this.props.validation && this.props.validation.hasOwnProperty(this.props.name) ? ' input_error ' : '')}
            />
            <label className={`custom-file-label ${RicexCustomFileLabel}`} htmlFor="inputFile">{ this.state.file?this.state.file.name : 'Choose file'}</label> 
          </div>
          <div className="pull-right">
            <button
              className="btn btn-upload mt-xxs-1 lh-1"
              disabled={this.props.files && this.props.files[0] ? false : true}
              onClick={e => this.props.uploadFiles(e)} >
                <span>Upload</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DropzoneCustom;
