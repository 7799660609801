import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import MdBell from 'react-icons/lib/md/notifications-none';
import Notification from './Notification';
import {
    DeleteAllNotifications,
    DeleteNotification,
    GetNotifications,
    MarkAllAsRead,
    MarkRead
} from '../../../modules/module.notifications';
import Preloader from '../preloader/Preloader';

import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

import './NotificationMenu.scss';

function NotificationMenu({
    messages,
    deleteNotification,
    deleteAllNotifications,
    markAllAsRead,
    getNotifications,
    markRead,
    navigate,
    loading }) {

    const [isOpen, setOpen] = useState(false);
    const wrapperRef = useRef(null)

    const handleClickOutside = (e) => {
        if (wrapperRef && !wrapperRef.current.contains(e.target)) {
            setOpen(false);
        }
    }
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside );
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef])

    const getRequestIdFromPath = (path) => {
        const splitParams = path.split('/');
        const detailsIndex = splitParams.findIndex(splitedPath => splitedPath === 'details');
        if (detailsIndex > -1) {
            return splitParams[detailsIndex + 1];
        }
    };
    
    const onNotificationClick = (notificationId, type) => {
        return path => {
            markRead(notificationId);
            setOpen(false)
            const requestIdFromLink = getRequestIdFromPath(path);
            const requestId = getRequestIdFromPath(window.location.pathname);
            switch (type) {
                case "INSPECTION_QUOTE_FILLED":
                case "INSPECTION_QUOTE_REJECTED":
                case "INSPECTION_QUOTE_ACCEPTED":
                case "INSPECTION_QUOTE_CANCELLED":
                    path = "/provisionalInspectionList/";
                    break;

                case "FINAL_INSPECTION_QUOTE_FILLED_NOTIF":
                case "FINAL_INSPECTION_QUOTE_REJECTED":
                case "FINAL_INSPECTION_QUOTE_CANCELLED":
                case "FINAL_INSPECTION_QUOTE_ACCEPTED":
                    path = "/finalInspectionList/";
                    break;

                case "INSPECTION_QUOTE_RESPONDED":
                case "FINAL_INSPECTION_QUOTE_RESPONDED":
                    path = "/inspectionQuoteOverview/";
                    break;

                case "PROVISIONAL_SHIPPING_QUOTE_FILLED_NOTIF":
                case "PROVISIONAL_SHIPPING_QUOTE_REJECTED":
                case "PROVISIONAL_SHIPPING_QUOTE_CANCELLED":
                case "PROVISIONAL_SHIPPING_QUOTE_ACCEPTED":
                    path = "/provisionalShippingList/";
                    break;


                case "FINAL_SHIPPING_QUOTE_RESPONDED":
                case "PROVISIONAL_SHIPPING_QUOTE_RESPONDED":
                    path = "/shippingQuoteOverview/";
                    break;

                case "FINAL_SHIPPING_QUOTE_ACCEPTED":
                case "FINAL_SHIPPING_QUOTE_REJECTED":
                case "FINAL_SHIPPING_QUOTE_CANCELLED":
                case "FINAL_SHIPPING_QUOTE_FILLED_NOTIF":
                case "MAERSK_BOOKING_NOTIF":
                    path = "/finalShippingList/";
                    break;

                default:
                    break;
            }

            if (requestId === requestIdFromLink) {
                path += `${path.includes('?') ? '&' : '?'}forceUpdate=true`;
            }
            navigate(path);
        }
    };

    const messageList = messages.filter(item => !item.deletePending)
    const newMessages = messageList.reduce((counter, { read }) => !read ? counter + 1 : counter, 0);
    const count = messages.length;

    return (
        <div ref={wrapperRef} className="notification-menu flex-column flex-lg-row">
            <div className="menu-container mt-lg-0 ">
                <div className="notification-wrapper px-2" onClick={() => setOpen(!isOpen)}>
                    <MdBell className="bell-pic header-icon" id="drop-menu" />
                    {(newMessages > 0) && (<span className="badge">{newMessages} </span>)}
                </div>
                {messages &&
                    <div className={`drop-menu ${isOpen ? 'enter-class' : 'outer-class'}`}>
                        <div className="notification-list-container" style={{ justifyContent: loading ? 'center' : 'initial' }}>
                            <Preloader loadingStyle="swirl" loading={loading}>
                                <TransitionGroup className="todo-list">
                                    {messageList.map((notification) => (
                                        // <CSSTransition
                                        <div
                                            key={notification.id}
                                            timeout={500}
                                            className={'drop-menu__item'}
                                            classNames="item">
                                            <div>
                                                <Notification
                                                    {...notification}
                                                    onDelete={() => deleteNotification(notification.id)}
                                                    onClick={onNotificationClick(notification.id, notification.type)}

                                                />
                                            </div>
                                            </div>
                                        // </CSSTransition>
                                    ))}
                                </TransitionGroup>
                                {(count === 0) && 
                                    <div className="notification-empty">
                                        <MdBell className="bell-pic notification-empty__icon" />
                                        <div className="notification-empty__text">
                                            Notifications list is empty now
                                        </div>
                                    </div>
                                }
                            </Preloader>
                        </div>
                        {count > 0 && (
                            <div className="notification-controls">
                                <div className={`notification-controls__item ${loading ? 'notification-controls__item--disabled' : ''}`}
                                    onClick={deleteAllNotifications}
                                >
                                    Clear All Notifications
                                    </div>
                                <div
                                    className={`notification-controls__item ${loading ? ' notification-controls__item--disabled' : ''}`}
                                    onClick={markAllAsRead}
                                >
                                    Mark All As Read
                                    </div>
                            </div>
                        )}
                    </div>}
            </div>
        </div>
    );
}


const mapStateToProps = ({ notifications, loading }) => {
    return {
        messages: notifications.messages.list,
        loading: loading.loadingNotifications,
        loadingMore: loading.loadingMoreNotifications,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: path => push(path),
            markRead: MarkRead,
            getNotifications: GetNotifications,
            markAllAsRead: MarkAllAsRead,
            deleteAllNotifications: DeleteAllNotifications,
            deleteNotification: DeleteNotification,
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);


