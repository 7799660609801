import React from 'react';
import { FertilizerService } from '../../../services/service.fertilizer';
import FormNumberGroupField from './FormNumberGroupField';
import FormInputField from './FormInputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FormFertilizerSpecification = ({
    fertilizerType,
    onChange,
    fieldData,
    validated,
    isCountering,
    disabled,
    customStyle={}
}) => {
    /*  RDEV-2618
        Remove the fieldData.value.length > 0 condition to enable custom specifications
    */
    if(fieldData.value){
        const defaultSpecs = fieldData.value.filter(spec => spec.name !== 'custom');
        const customSpecs = fieldData.value.filter(spec => spec.name === 'custom');
        const isEmpty = value => value === null || value === undefined || value === '';

        const handleCustomSpecChange = (event, type, data, index) => {
            event.preventDefault();
            let updatedCustomSpecs = customSpecs.map(item => ({...item}));
            if(type === 'ADD'){
                updatedCustomSpecs.push(data);
            }else if(type === 'REMOVE'){
                updatedCustomSpecs.splice(index, 1);
            }else{
                updatedCustomSpecs[index] = data;
            }
            onChange('specifications', [...defaultSpecs, ...updatedCustomSpecs]);
        }
    
        const handleDefaultSpecChange = (name, value) => {
            const updatedSpecs = fieldData.value.map((spec) => {
                if(spec.name === name){
                    return {
                        ...spec,
                        value: value,
                        touched: true
                    }
                }
                return {...spec};
            });
            onChange('specifications', updatedSpecs);
        }

        const checkDefaultSpecValidity = (item) => {
            if(['maxParticleSize', 'maxInhibitor', 'maxPH', 'maxFreeAmmonia'].includes(item.name)){
                const minValueName = 'min' + item.name.slice(3);
                const minObj = defaultSpecs.find(data => data.name === minValueName);
                return (item.touched || validated) && (FertilizerService.validateSpec(item) || parseFloat(item.value) < parseFloat(minObj.value)) 
            }
            return (item.touched || validated) &&  FertilizerService.validateSpec(item);
        }

        const renderSpecifications = () => {
            return defaultSpecs.map((item) => {
                const wrapperClass = FertilizerService.specWrapperClass(item.name, item.inputType)
                const validation = {};
                const specField = {
                    name: item.name,
                    label: item.label,
                    required: true,
                    touched: item.touched || validated,
                    value: item.value
                }
                if(checkDefaultSpecValidity(item)) validation[item.name] = specField;
                return(
                    <React.Fragment key={`${fertilizerType}_${item.name}`}>
                        {(item.inputType === 'number' && item.name === 'granulometry') && (<div className="w-100"></div>)}
                        <div className={wrapperClass}>
                            {(item.inputType === 'number') && (
                                <FormNumberGroupField
                                    customStyle={customStyle}
                                    name={item.name}
                                    apValue={item.unit ? FertilizerService.enums.UNITS[item.unit] : null}
                                    validation={validation}
                                    value={specField}
                                    onChange={(value) => handleDefaultSpecChange(item.name, value)}
                                    thousandSeparator={true}
                                    decimalScale={item.name === 'chlorides' ? 3 : 2}
                                    allowNegative={false}
                                    isAllowed={(values) => {
                                        const {floatValue, value  } = values;
                                        return value  === "" || floatValue <= item.maxValue;
                                    }}
                                    disabled={item.disabled || disabled} 
                                />
                            )}
                            {(item.inputType === 'input') && (
                                <FormInputField
                                    customStyle={customStyle}
                                    name={item.name}
                                    apValue={item.unit ? FertilizerService.enums.UNITS[item.unit] : null}
                                    validation={validation}
                                    value={specField}
                                    onChange={e => handleDefaultSpecChange(e.target.name, e.target.value)}
                                    disabled={item.disabled || disabled}
                                />
                            )}
                        </div>
                    </React.Fragment>
                )
            })
        }

        return(
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col-md-6 col-sm-6">
                        <h6 className="modal-title">{fieldData.label}</h6>
                    </div>
                    <div className="col-md-6 col-sm-6 text-right rounded">
                        <button
                            type="button"
                            className="btn btn-success btn-sm rounded" 
                            onClick={(event) => handleCustomSpecChange(event, 'ADD', {
                                    name: 'custom', 
                                    label: '', 
                                    value: '', 
                                    unit: '', 
                                    labelTouched: isCountering, 
                                    valueTouched: isCountering
                            }, null)}
                            disabled={disabled}>
                                Add Specification
                        </button>
                    </div>
                </div>
                <div className="row pt-2" style={{background: "hsl(0deg 0% 95%)"}}>
                    {renderSpecifications()}
                </div>
                {customSpecs.map((spec, index) => (
                    <div className="row p-2" key={`custom${index}`} style={{background: "hsl(0deg 0% 95%)"}}>
                        <div className="col-md-12 text-right" style={{background: 'white'}}>
                            <span style={{cursor: 'pointer'}} onClick={(event) => handleCustomSpecChange(event, 'REMOVE', null, index)}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </span>
                        </div>
                        <div className="col-md-6" style={{background: 'white'}}>
                            <FormInputField
                                customStyle={customStyle}
                                name={'specLabel'+index}
                                validation={{
                                    ...(((spec.labelTouched || validated) && isEmpty(spec.label)) ? {['specLabel'+index]: true} : {})
                                }}
                                value={{
                                    value: spec.label,
                                    label: 'Specification Name',
                                    required: true,
                                    touched: spec.labelTouched || validated
                                }}
                                onChange={(event) => handleCustomSpecChange(event, 'UPDATE', {...spec, label: event.target.value, labelTouched: true}, index)}
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-md-6" style={{background: 'white'}}>
                            <FormInputField
                                customStyle={customStyle}
                                name={'specValue'+index}
                                validation={{
                                    ...(((spec.valueTouched || validated) && isEmpty(spec.value)) ? {['specValue'+index]: true} : {})
                                }}
                                value={{
                                    value: spec.value,
                                    label: 'Specification Value',
                                    required: true,
                                    touched: spec.valueTouched || validated
                                }}
                                onChange={(event) => handleCustomSpecChange(event, 'UPDATE', {...spec, value: event.target.value, valueTouched: true}, index)}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                ))}
                {(fertilizerType !== "UREA_AMMONIUM_NITRATE") && (
                <div className="row pt-2" style={{background: "hsl(0deg 0% 95%)"}}>
                    <div className="col-md-12">
                        <label className="label">Free flowing, free from harmful substances</label>
                    </div>
                </div>)}
            </React.Fragment>
        )
    }
    return null;
}

export default FormFertilizerSpecification;