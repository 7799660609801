import { TradeApi } from '../services/service.api';
import { AdminManagementApi } from '../services/service.api';
import {
  LoadingPermissionData, 
  LoadingRBACGrouptData, 
  LoadingonGroupAction, 
  LoadingonSavePermission,
  LoadingProviderList
} from './module.loading';

export const LOADED_INSPECTION_COMPANIES1 = 'adminManagement/LOADED_INSPECTION_COMPANIES1';
export const LOAD_USERGRUOPS = 'adminManagement/LOAD_USERGRUOPS';
export const ADD_USERGROUP = 'adminManagement/ADD_USERGROUP';
export const EDIT_USERGROUP = 'adminManagement/EDIT_USERGROUP';
export const LOAD_PERMISSION = 'adminManagement/LOAD_PERMISSION';
export const LOAD_GROUPUSER_LIST = 'adminManagement/LOAD_GROUPUSER_LIST';
export const DELETE_GROUP = 'adminManagement/DELETE_GROUP';
export const SAVE_PERMISSION = 'adminManagement/SAVE_PERMISSION';
export const LOAD_USERLIST = 'adminManagement/LOAD_USERLIST';
export const USER_PERMISSION = 'adminManagement/USER_PERMISSION';
export const ACTIVE_USERS = 'adminManagement/ACTIVE_USERS';
export const USER_STATUS = 'adminManagement/USER_STATUS';
export const INSERT_USERTOGROUP = 'adminManagement/INSERT_USERTOGROUP';
export const INVITE_USER = 'adminManagement/INVITE_USER';

//provider const
export const COMPANY_PROVIDERS = 'COMPANY_PROVIDERS';
export const PROVIDER_LIST = 'PROVIDER_LIST';
export const ADD_PROVIDER = 'ADD_PROVIDER';
export const PROVIDER_KYC_LIST = 'PROVIDER_KYC_LIST';
export const DEACTIVATE_PROVIDER = 'DEACTIVATE_PROVIDER';
export const ACTIVATE_PROVIDER = 'ACTIVATE_PROVIDER';
export const AUTHORIZED_USER_LIST = 'AUTHORIZED_USER_LIST';



const initialState = {
  companies: [],
  groups: [],
  permission: [],
  groupUserList: [],
  userList: [],
  userPermission: [],
  activeUserList: [],
  userStatus: [],
  inviteError: null,
  companyProviders: [],
  providerList: [],
  providerKycList: []
};


export default (state = initialState, action= {}) => {
  switch (action.type) {
    case LOADED_INSPECTION_COMPANIES1:
      return {
        ...state,
        companies: action.payload.companies
      };
    case LOAD_USERGRUOPS:
      return {
        ...state,
        groups: action.payload.groups
      };
    case ADD_USERGROUP:
      return state;
    case EDIT_USERGROUP:
      return state;
    case LOAD_PERMISSION:
      return {
        ...state,
        permission: action.payload.permission

      };
    case LOAD_GROUPUSER_LIST:
      return {
        ...state,
        groupUserList: action.payload.groupUserList
      };

    case LOAD_USERLIST:
      return {
        ...state,
        userList: action.payload.userList
      };
    case ACTIVE_USERS:
      return {
        ...state,
        activeUserList: action.payload.activeUserList

      };
    case USER_PERMISSION:
      sessionStorage.setItem("setPermission", JSON.stringify(action.payload.userPermission));
      return {
        ...state,
        userPermission: action.payload.userPermission
      };
    case USER_STATUS:
      return {
        ...state,
        userStatus: action.payload.userStatus
      };
    case DELETE_GROUP:
      return state;
    case INSERT_USERTOGROUP:
      return state;
    case INVITE_USER:
      return {
        ...state,
        inviteError: action.payload
      };
    case SAVE_PERMISSION:
      return state;
    case COMPANY_PROVIDERS:
      return {
        ...state,
        companyProviders: action.payload.data.providers
      };
    case PROVIDER_LIST:
      return {
        ...state,
        providerList: action.payload.data.providers
      };
    case ADD_PROVIDER:
      return state;
    case PROVIDER_KYC_LIST:
      return {
        ...state,
        providerKycList: action.payload.data.companies
      };
    case AUTHORIZED_USER_LIST:
      return {
        ...state,
        activeUserList: action.payload.data.users
      };
    case DEACTIVATE_PROVIDER:
      return state;
    case ACTIVATE_PROVIDER:
      return state;
    default:
      return state;
  }
};

export const preloadInspectionCompanies1 = search => {
  return dispatch => {
    TradeApi.getInspectionCompanies(search)
      .then(r => {
        dispatch({
          type: LOADED_INSPECTION_COMPANIES1,
          payload: { companies: r.data.companies }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const preloadGrouplistByComapny = (companyid) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getGroupListByCompany(companyid)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_USERGRUOPS,
          payload: { groups: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const addUserGroup = (params, cb) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.adduserGroup(params).then(r => {
     LoadingonGroupAction(dispatch, false);
      dispatch({
        type: ADD_USERGROUP,
        payload: r.data
      });
      cb();
    })
      .catch(e => {
        console.log('catch', e);
      });

  };

};

export const editUserGroup = (params, cb) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.editgroup(params).then(r => {
      LoadingonGroupAction(dispatch, false);
      dispatch({
        type: EDIT_USERGROUP,
        payload: r.data
      });
      cb();
    })
      .catch(e => {
        console.log('catch', e);
      });

  };

};

export const getPermissionListByGroup = (id) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getPermission(id)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_PERMISSION,
          payload: { permission: r.data }
        });
      })
      .catch(e => {

        console.log('catch', e);
      });
  };
};

export const getGroupUserList = (id) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getGroupUserList(id)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_GROUPUSER_LIST,
          payload: { groupUserList: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const deleteGroup = (id) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.deleteGroup(id)
      .then(r => {
        LoadingonGroupAction(dispatch, false);
        dispatch({
          type: DELETE_GROUP,
          payload: r.data
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};
export const savePermissions = (id, params) => {
  return dispatch => {
    LoadingonSavePermission(dispatch, true);
    AdminManagementApi.savePermissions(id, params).then(r => {
      LoadingonSavePermission(dispatch, false);
      dispatch({
        type: SAVE_PERMISSION,
        payload: r.data
      });
    })
      .catch(e => {
        console.log('catch', e);
      });

  };

};
export const getUserList = () => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getUserList()
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: LOAD_USERLIST,
          payload: { userList: r.data }
        });
      })
      .catch(e => {
        console.error('catch', e);
      });
  };
};
export const inviteUser = (params, cb) => {
  return dispatch => {
    AdminManagementApi.inviteUser(params)
      .then((r) => {
        LoadingonGroupAction(dispatch, true);
        LoadingonGroupAction(dispatch, false);
      })
      .catch((r) => {
        dispatch({
          type: INVITE_USER,
          payload: { inviteError: r.response }
        })
        cb({ ...r.response });
        LoadingonGroupAction(dispatch, false);
      });
  }
};

export const getUserPermission = (id) => {
  return dispatch => {
    LoadingPermissionData(dispatch, true);
    AdminManagementApi.getUserPermission(id)
      .then(r => {
        LoadingPermissionData(dispatch, false);
        dispatch({
          type: USER_PERMISSION,
          payload: { userPermission: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};
export const cloneGroup = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.cloneGroup(id, params)
      .then(() => {
        LoadingonGroupAction(dispatch, false);
      })
      .catch(() => {
        LoadingonGroupAction(dispatch, false);
      });
  }
};
export const deleteGroupUser = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.deleteGroupUser(id, params)
      .then(r => {
        LoadingonGroupAction(dispatch, false);
        dispatch({
          type: DELETE_GROUP,
          payload: r.data
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};
export const getActiveUserList = () => {
  return dispatch => {
    AdminManagementApi.getActiveUserList()
      .then(r => {
        dispatch({
          type: ACTIVE_USERS,
          payload: { activeUserList: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};
export const activeUser = (userID) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.activeUser(userID)
      .then(r => {
        LoadingonGroupAction(dispatch, false);
        dispatch({
          type: USER_STATUS,
          payload: { userStatus: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const inactiveUser = (userID) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.inactiveUser(userID)
      .then(r => {
        LoadingonGroupAction(dispatch, false);
        dispatch({
          type: USER_STATUS,
          payload: { userStatus: r.data }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};
export const insertUserToGroup = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.insertUserToGroup(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
      dispatch({
        type: INSERT_USERTOGROUP,
        payload: r.data
      });

    })
      .catch(e => {
        console.log('catch', e);
      });

  };

};

export const getProviderListByComapny = (companyID) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getProvidersByCompany(companyID)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: COMPANY_PROVIDERS,
          payload: { data: r.data }
        });
      }, () => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: COMPANY_PROVIDERS,
          payload: { data: {} }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const getProviderList = () => {
  return dispatch => {
    LoadingProviderList(dispatch, true);
    AdminManagementApi.getProviderList()
      .then(r => {
        LoadingProviderList(dispatch, false);
        dispatch({
          type: PROVIDER_LIST,
          payload: { data: r.data }
        });
      }, () => {
        LoadingProviderList(dispatch, false);
        dispatch({
          type: PROVIDER_LIST,
          payload: { data: {} }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const addNewProvider = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.addNewProvider(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
      dispatch({
        type: ADD_PROVIDER,
        payload: r.data
      });
    }, () => {
      LoadingonGroupAction(dispatch, false);

    })
      .catch(e => {
        LoadingonGroupAction(dispatch, false);
      });
  };
};

export const getProviderKycList = (id) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getProviderKycList(id)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: PROVIDER_KYC_LIST,
          payload: { data: r.data }
        });
      }, () => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: PROVIDER_KYC_LIST,
          payload: { data: {} }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const getAuthorizedUsers = (id) => {
  return dispatch => {
    LoadingRBACGrouptData(dispatch, true);
    AdminManagementApi.getAuthorizedUsers(id)
      .then(r => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: AUTHORIZED_USER_LIST,
          payload: { data: r.data }
        });
      }, () => {
        LoadingRBACGrouptData(dispatch, false);
        dispatch({
          type: AUTHORIZED_USER_LIST,
          payload: { data: {} }
        });
      })
      .catch(e => {
        console.log('catch', e);
      });
  };
};

export const rejectProviderKyc = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.rejectProviderKyc(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
    }, () => {
      LoadingonGroupAction(dispatch, false);
    })
      .catch(e => {
        LoadingonGroupAction(dispatch, false);
      });
  };
};

export const approveProviderKyc = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.approveProviderKyc(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
    }, () => {
      LoadingonGroupAction(dispatch, false);
    })
      .catch(e => {
        LoadingonGroupAction(dispatch, false);
      });
  };
};

export const deactivateProvider = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.deactivateProvider(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
      dispatch({
        type: ADD_PROVIDER,
        payload: r.data
      });
    }, () => {
      LoadingonGroupAction(dispatch, false);
    })
      .catch(e => {
        LoadingonGroupAction(dispatch, false);
      });
  };
};

export const activateProvider = (id, params) => {
  return dispatch => {
    LoadingonGroupAction(dispatch, true);
    AdminManagementApi.activateProvider(id, params).then(r => {
      LoadingonGroupAction(dispatch, false);
      dispatch({
        type: ADD_PROVIDER,
        payload: r.data
      });
    }, () => {
      LoadingonGroupAction(dispatch, false);
    })
      .catch(e => {
        LoadingonGroupAction(dispatch, false);
      });
  };
};

export const changeAuthorisationCode = (params) => {
  return AdminManagementApi.changeAuthorisationCode(params);
};

export const changeAdditionalEmails = (params) => {
  return AdminManagementApi.changeAdditionalEmails(params);
};

export const getAdditionalEmails = () => {
  return AdminManagementApi.getAdditionalEmails();
};


