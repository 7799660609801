import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';


import { EnumsService, PortService, renderPackaging, getLocalTimezone } from '../../../../services/service.utils';

import DetailTable from '../../requests/detail/DetailTable';
import { faTimes, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../../../components/preloader/Preloader';
import FormDateField from '../../../components/form/FormDateField';
import FormRadioLabelVerticalTrueFalse from '../../../components/form/FormRadioLabelVerticalTrueFalse';
import FormSelectDropdown from '../../../components/form/FormSelectDropdown';
import FormAsyncSelectPort from '../../../components/form/FormAsyncSelectPort';
import FormInputField from '../../../components/form/FormInputField';
import FormSelectDropdownList from '../../../components/form/FormSelectDropdownList';
import AccessControl, { INSPECTION_COMPANY, SHIPPING_COMPANY } from '../../../components/AccessControl';
import { UserTypes } from '../../../../utils/userTypes';
import { viewInspectionQuotePermission, viewShippingQuotePermission, negotiateShippingQuotePermission, negotiateInspectionQuotePermission } from '../../../../services/service.permission';
import Tooltip from '@material-ui/core/Tooltip';
import { RxXsFab } from './../../../components/custom/customFab';
import { DocumentFactory } from './../services/documents.service';

import RequestBookingModal from '../../quotes/maersk/RequestBookingModal';
import RequestModal from '../../quotes/RequestModal';
import NumberFormat from 'react-number-format';
//values
import {
    DISCHARGE_VALUES,
    PAYMENTPERIODOPT,
    DATEFORMAT,
    DATEFORMATHOURS,
    getDischargeRateLabel,
    getDischargeLabel,
    PACKAGING,
    MEASUREMENTS,
    quoteModalsReactSelectStyle,
    TERM_SHIPPING,
    COMMISSION_SHIPPING,
    OFFER_TYPES,
    CURRENCYOPT,
} from '../../../../services/service.values';
import { FertilizerService } from '../../../../services/service.fertilizer';

import { 
    renderDocumentsTitle, 
    renderDocuments, 
    renderFertilizerSpecifications, 
    renderInspectionCompany,
    renderCountry, 
    renderGoverningCountry, 
    renderTolerance
} from './../../requests/detail/detailTable/Renderers';
import TradeQuantityFormatter from '../../../components/Formatter/TradeQuantityFormatter';

const Countries = EnumsService.countries();
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

class Info extends React.Component {

    FORM_GROUP_WRAPPER_CLASS = "col-md-4";
    FORM_CREATE_WRAPPER_CLASS = "col-md-6";
    CUSTOM_COMPONENT_STYLE = {
        wrapperClassName: "form-group",
        labelClassName: "label",
        inputClassName: "form-control",
        selectClassName: "custom-select",
        radioColLayout: { left: "col-sm-6 col-md-6 col-lg-6 col-xl-4", right: "col-auto" }
    }

    constructor(props) {
        super(props);
        this.validate = this.validate.bind(this);

        let isEmpty = value => !value || value === undefined || value === '';
        let shippingQuote = {};
        const dischargerate = this.props.trade.dischargeRate || '';
        this.initField(shippingQuote, 'dischargeRate', `${this.props.trade.incoterm === "FOB" ? "Load Rate" : "Discharge Rate"}`, dischargerate, isEmpty);
        this.initField(shippingQuote, 'term', 'Term', '', isEmpty);
        this.initField(shippingQuote, 'addressCommision', 'Address Commission', '', isEmpty);
        this.initField(shippingQuote, 'shipping', 'Shipping Company', '', isEmpty);
        const startDate = moment(this.props.trade.deliveryStartDate).startOf('day');
        this.initField(shippingQuote, 'deliveryStartDate', 'Shipping / Delivery Period (from)', startDate, isEmpty);
        const endDate = (this.props.trade.deliveryEndDate) ? moment(this.props.trade.deliveryEndDate) : undefined;
        this.initField(shippingQuote, 'deliveryEndDate', 'Shipping / Delivery Period (to)', endDate, isEmpty);
        this.initField(shippingQuote, 'destinationCountry', 'Destination Country', '', isEmpty);
        this.initField(shippingQuote, 'destinationPort', 'Destination Port', '', isEmpty);
        //Fields for step 1 for inpection quote
        let inspectionQuote = {};
        // this.initField(inspectionQuote, 'inspection', 'Inspection Company', '', isEmpty);
        const deliveryStartDate = moment(this.props.trade.deliveryStartDate).startOf('day');
        this.initField(inspectionQuote, 'deliveryStartDate', 'Shipping / Delivery Period (from)', deliveryStartDate, isEmpty);
        const deliveryEndDate = (this.props.trade.deliveryEndDate) ? moment(this.props.trade.deliveryEndDate) : undefined;
        this.initField(inspectionQuote, 'deliveryEndDate', 'Shipping / Delivery Period (to)', deliveryEndDate, isEmpty);
        this.initField(inspectionQuote, 'destinationCountry', 'Destination Country', '', isEmpty);
        this.initField(inspectionQuote, 'destinationPort', 'Destination Port', '', isEmpty);
        //Fields for step 2 for inpection quote
        let inspectionQuoteNext = {};
        this.initField(inspectionQuoteNext, 'preShipmentInfo', 'PSI (pre-shipment inspection)', true, false);
        this.initField(inspectionQuoteNext, 'preShipmentLocation', 'Location of PSI', '', '');
        this.initField(inspectionQuoteNext, 'draftSurvey', 'Draft Survey', true, false);
        this.initField(inspectionQuoteNext, 'tally', 'Tally', true, false);
        this.initField(inspectionQuoteNext, 'sampling', 'Sampling', true, false);
        this.initField(inspectionQuoteNext, 'markingsCheck', 'Markings Check', true, false);
        const labelSealing = this.props.trade.shipping === "VESSEL" ? 'Sealing of Holds' : 'Sealing of containers';
        this.initField(inspectionQuoteNext, 'sealing', labelSealing, true, false);
        this.initField(inspectionQuoteNext, 'hoseTestRequired', 'Hose Test Required', true, false);
        this.initField(inspectionQuoteNext, 'holdsInspectionReport', 'Holds Inspection Report', true, false);
        this.initField(inspectionQuoteNext, 'fumigation', 'Fumigation', true, false);
        const labelLocation = this.props.trade.shipping === "VESSEL" ? 'Location of Loading' : 'Location of Stuffing container';
        this.initField(inspectionQuoteNext, 'location', labelLocation, '', isEmpty);
        this.initField(inspectionQuoteNext, 'sampleDistribution', 'Sample Distribution', '', isEmpty);
        this.initField(inspectionQuoteNext, 'sampleRetention', 'Number of samples to be retained', '', isEmpty);
        this.initField(inspectionQuoteNext, 'requiredAnalysis', 'Required Analysis', '', '');
        this.initField(inspectionQuoteNext, 'others', 'Special Request/Special Documents', '', '');
        this.initField(inspectionQuoteNext, 'desiccants', 'Desiccants (Calcium Chloride base only : for voyage upto 60 days min 4KG, over 60 days min 6KG)', '', isEmpty);
        this.initField(inspectionQuoteNext, 'preparationKraftPaper', 'Preparation: Kraft Paper', 'On all sides and on top of cargo', isEmpty);

        this.state = ({
            inspectionQuote: inspectionQuote,
            inspectionQuoteNext: inspectionQuoteNext,
            required: null,
            isshippingType: this.props.trade.shipping === "VESSEL" ? true : false,
            isThankyouHeader: false,
            shippingDate: {
                name: "shippingDate",
                label: "Shipping Date",
                required: false,
                value: moment(this.props.trade.deliveryStartDate),
            },
            vesselData: {
                id: "",
                name: "",
                remark: "",
            },
            shippingQuote: shippingQuote,
            serverError: '',
            countryError: '',
            companyError: '',
        });
    }

    getInitialFieldList = () => {
        let initialList = [
            ...((this.props.trade.requestType === "SELL" && this.props.trade.offerType) ? [{key: 'offerType', label: 'Offer Type', tdClass: this.tdClass, format: value => OFFER_TYPES[value]}] : []),
            { key: "fertilizerType", label: "Fertilizer Type", format: value => (FERTILIZER_TYPE[value] || value) },
            { key: "specifications", label: "Fertilizer Specifications", format: (specs, serie) => renderFertilizerSpecifications(specs, serie.data.fertilizerType, false), tdClass: this.tdClass},
            { key: "incoterm", label: "Incoterms" },
            {
                key: "shipping", label: "Shipping", format: (value) => {
                    return (
                        <AccessControl user={this.props.user} userTypes={[UserTypes.COMPANY_ADMIN, UserTypes.COMPANY_EMPLOYEE]} excludeCompanyTypes={[INSPECTION_COMPANY, SHIPPING_COMPANY]} >
                            <div className="request-area__section">
                                {this.state.showModal && this.renderModal(this.state.modal)}
                                {this.state.showModalTwo && this.renderModalTwo(this.state.modalTwo)}
                                <span className="mr-auto pt-2">{value}</span>
                                {(this.props.isTradeSeller || this.props.isTradeBuyer || this.props.isInspectionSeller) && <div>
                                    {(this.props.trade.status === "SIGNED" || this.props.trade.status === "DEAL" || this.props.trade.status === "VESSEL_NOMINATED" || this.props.trade.status === "PROFORMA_INVOICE") &&
                                        <div className="dropdown">
                                            <button className="btn trades-request-btn btn-success" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Request Final Offer
                                                </button>
                                            <div className="dropdown-menu btn-dropdownmenu" aria-labelledby="dropdownMenuButton">
                                                {this.props.isInspectionSeller && <div>
                                                    {this.isNegotiateInspection() ? (<div>
                                                        {this.props.inspectionQuote.isInspectionCreated && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal("PROVISIONAL_INSPECTION_VESSEL", this.props.trade)}>Final Inspection Offer</button></div>}</div>
                                                    ) : (
                                                        <div>{this.props.inspectionQuote.isInspectionCreated && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}>Final Inspection Offer</button></div>} </div>
                                                    )}
                                                    {this.isViewInspection() ? (<div>
                                                        {!this.props.inspectionQuote.isInspectionCreated && <div> <NavLink className="dropdown-item" to={`/inspectionQuoteOverview/`} >View Inspection Offer</NavLink></div>}</div>
                                                    ) : (
                                                        <div>{!this.props.inspectionQuote.isInspectionCreated && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}>View Inspection Offer</button></div>} </div>
                                                    )}
                                                </div>}
                                                {this.isNegotiateShipping() ? (<div>
                                                    {this.props.shipmentQuote.isShipmentCreated && <div>
                                                        {this.props.isTradeSeller && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal(this.props.isShipping, this.props.trade)}> Final Shipping Offer</button></div>}
                                                        {this.props.isTradeBuyer && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal(this.props.isShipping, this.props.trade)}> Final Shipping Offer</button></div>}
                                                    </div>}
                                                </div>
                                                ) : (
                                                    <div>
                                                        {this.props.shipmentQuote.isShipmentCreated && <div>
                                                            {this.props.isTradeSeller && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}> Final Shipping Offer</button></div>}
                                                            {this.props.isTradeBuyer && <div className="pr-2 pb-1"> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}> Final Shipping Offer</button></div>}
                                                        </div>}
                                                    </div>
                                                )}
                                                {this.isViewShipping() ? (<div>
                                                    {!this.props.shipmentQuote.isShipmentCreated && <div>
                                                        {this.props.isTradeSeller && <div> <NavLink className="dropdown-item" to={`/shippingQuoteOverview/`}> View Shipping Offer</NavLink></div>}
                                                        {this.props.isTradeBuyer && <div> <NavLink className="dropdown-item" to={`/shippingQuoteOverview/`}> View Shipping Offer</NavLink></div>}
                                                    </div>}
                                                </div>
                                                ) : (
                                                    <div>
                                                        {!this.props.shipmentQuote.isShipmentCreated && <div>
                                                            {this.props.isTradeSeller && <div> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}> View Shipping Offer</button></div>}
                                                            {this.props.isTradeBuyer && <div> <button className="dropdown-item" onClick={() => this.openModal("PERMISSION_REQUIRED")}> View Shipping Offer</button></div>}
                                                        </div>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                        </AccessControl>
                    )
                }
            },
            { key: "inspectionName", label: "Inspection Company", format: (value, serie) => renderInspectionCompany("inspectionName", null, value, serie) },
            { key: "measure", label: "Quantity (expressed in MT)", format: (value, serie) => <TradeQuantityFormatter trade={serie.data} /> },
            { key: "tolerance", label: "Contractual Tolerance", format: renderTolerance, tdClass: this.tdClass },
            { key: "packaging", label: "Packaging", format: this.renderPackaging, tdClass: this.tdClass },
            ...((this.props.trade.multipleOriginAccept && !this.props.info.signBuyer && !this.props.info.signSeller) ?
                [{
                    key: "multipleOriginPrice", label: "Country of Load (Port of Load)", format: (value, serie) => {
                        return value.map(item => item.isPriceCheck && serie.data.incoterm === 'FOB' ? <div key={item.origin}>{Countries[item.loadCountry]} ({this.renderPortLabel(item.loadPort)})</div> : <div>-</div>)
                    }, tdClass: this.tdClass
                }] :
                [{ key: "origin", label: "Origin", format: renderCountry },
                { key: "loadCountry", label: "Country of Load", format: renderCountry },
                { key: "loadPort", label: "Port of Load", format: this.renderPortLabel }]),
            { key: "destCountry", label: "Country of Destination", format: renderCountry },
            { key: "destPort", label: "Port of Destination", format: this.renderPortLabel },
        ];
        /*Discharge/Load terms can be null in case of shipping type 'CONTAINER' in that case, the lines are not displayed */
        if (this.props.trade.discharge) {
            initialList = [...initialList,
            { key: "discharge", label: getDischargeLabel(this.props.trade.incoterm), format: this.renderDischarge, tdClass: this.tdClass },
            { key: "dischargeRate", label: getDischargeRateLabel(this.props.trade.incoterm, this.props.trade.discharge), format: (value) => <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />, tdClass: this.tdClass },
            ];
        }

        initialList = [...initialList,
            { key: "payment", label: "Payment Terms" },
            ...((this.props.trade && ["Letter of credit", "Confirmed letter of credit"].includes(this.props.trade.payment)) ? [{ key: "LCOpeningDate", label: "Letter of Credit to be Opened Latest On", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass }] : []),
            ...((this.props.trade && this.props.trade.payment === "Avalised Draft") ? [{ key: "ADIssuingDate", label: "Avalised Draft to be issued latest on", format: value => value ? moment(value).format(DATEFORMAT) : "", tdClass: this.tdClass }] : []),
            { key: "paymentPeriod", label: "Payment Period", format: value => PAYMENTPERIODOPT[value], tdClass: this.tdClass },
            { key: "downPaymentPercentage", label: "Down Payment", format: (value) => (value) ? `${value}%` : "NO", tdClass: this.tdClass },
            { key: "downPaymentPeriod", label: "Down Payment Period" },
            { key: "deliveryStartDate", label: "Delivery Start Date", format: (value) => moment(value).format(DATEFORMAT) },
            { key: "deliveryEndDate", label: "Delivery End Date", format: (value) => moment(value).format(DATEFORMAT) },
            { key: "validateDate", label: `Expiry Date (${getLocalTimezone()})`, format: value => moment(value).format(DATEFORMATHOURS) },
            { key: "optionalDocuments", label: renderDocumentsTitle(), format: renderDocuments, tdClass: this.tdClass },
            ...(this.props.trade && this.props.trade.privacyType !== "TENDER" ? [{key : "governingCountry", label: "Governing law and Arbitration Country", format: renderGoverningCountry, tdClass: this.tdClass}] : []),
            { key: "SpecialRequest", label: "Special Request", format: value => "", tdClass: "bg-light font-weight-bold" },
            { key: "specialRequest", label: "Content", format: value => value ? value : "NO", tdClass: this.tdClass },
        ];

        return initialList;
    }

    renderDischarge = (value, serie) => {
        const formatedValue = DISCHARGE_VALUES[value];
        return formatedValue;
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    renderPackaging = (value, serie) => {
        //Format default
        if (PACKAGING[value]) {
            return PACKAGING[value]
        } else {
            let prefix = '';
            let packagingQuantity = '';
            if (value.includes('BAGGED_PALLET_OTHER')) {
                prefix = 'BAGGED_PALLET_OTHER - '
                packagingQuantity = value.slice(prefix.length)
                return `Bagged and Pallet - ${packagingQuantity}`
            }
            if (value.includes('BAGGED_OTHER')) {
                prefix = 'BAGGED_OTHER - ';
                packagingQuantity = value.slice(prefix.length)
                return `Bagged - ${packagingQuantity}`
            }
            return '-';
        }
    }

    renderDocuments = (value, serie) => {
        const docs = DocumentFactory.getFEDocumentList(serie.data)
        if (Array.isArray(docs) && docs.length !== 0) {
            return (docs.map(doc =>
                <div className='row'>
                    <div className='col' key={doc.title}>
                        {doc.title}
                    </div>
                    <div className='col-auto'>
                        {doc.uploadedBy === 'SELLER' &&
                            <Tooltip title='To be uploaded by Seller' aria-label='To be uploaded by Seller' placement="top-start" arrow>
                                <RxXsFab color="primary">
                                    <i>S</i>
                                </RxXsFab>
                            </Tooltip>}
                        {doc.uploadedBy === 'CONTRACTUAL_INSPECTOR' &&
                            <Tooltip title="To be uploaded by Contractual Inspector" placement="top-start" arrow>
                                <RxXsFab classes={{ root: 'bg-danger text-white' }}>
                                    <i>I</i>
                                </RxXsFab>
                            </Tooltip>}
                    </div>
                </div>))
        } else {
            return "-";
        }
    }

    state = {
        showModal: false,
        showModalTwo: false,
        classApplied: false,
        classResponseError: false,
        responseMessage: "Please press 'Confirm to Start'",
        inProgress: false,
        classResponseData: false,

        errors: [],
    };

    initField(state, name, label, value, required) {
        state[name] = {
            value: value,
            required: required,
            label: label
        };
        return state;
    }
    setInspectionField(name, value) {
        if (name !== '') {
            let inspectionQuote = this.state.inspectionQuote;
            let field = inspectionQuote[name];
            field.value = value;
            this.setState({ inspectionQuote: inspectionQuote, required: null, serverError: '', countryError: '', companyError: '' });
        }
    }
    setShippingField(name, value) {
        if (name !== '') {
            let shippingQuote = this.state.shippingQuote;
            let field = shippingQuote[name];
            field.value = value;
            this.setState({ shippingQuote: shippingQuote, required: null, companyError: '' });
        }
    }
    
    setInspectionFieldNext(name, value) {
        if (name !== '') {
            switch (name) {
                case 'tally':
                case 'sealing':
                case 'markingsCheck':
                case 'holdsInspectionReport':
                case 'hoseTestRequired':
                case 'sampling':
                case 'draftSurvey':
                case 'fumigation':
                case 'preShipmentInfo': {
                    let inspectionQuoteNext = this.state.inspectionQuoteNext;
                    inspectionQuoteNext[name] = {
                        ...inspectionQuoteNext[name],
                        value: (value === 'true')
                    };
                    this.setState({
                        inspectionQuoteNext: inspectionQuoteNext,
                        required: null
                    });
                    break;
                }
                case '':
                    break;
                default: {
                    let inspectionQuoteNext = this.state.inspectionQuoteNext;
                    inspectionQuoteNext[name] = {
                        ...inspectionQuoteNext[name],
                        value: value
                    };
                    this.setState({
                        inspectionQuoteNext: inspectionQuoteNext,
                        required: null
                    });
                    break;
                }
            }
        }
    }


    isViewInspection = () => {
        var isViewInspection = false;
        if (viewInspectionQuotePermission() === 1) {
            isViewInspection = true;
        }
        return isViewInspection;
    }
    isViewShipping = () => {
        var isViewShipping = false;
        if (viewShippingQuotePermission() === 1) {
            isViewShipping = true;
        }
        return isViewShipping;
    }
    isNegotiateInspection = () => {
        var isNegotiateInspection = false;
        if (negotiateInspectionQuotePermission() === 1) {
            isNegotiateInspection = true;
        }
        return isNegotiateInspection;
    }
    isNegotiateShipping = () => {
        var isNegotiateShipping = false;
        if (negotiateShippingQuotePermission() === 1) {
            isNegotiateShipping = true;
        }
        return isNegotiateShipping;
    }


    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            if (this.state.data.shipping === "VESSEL" && (key !== "desiccants" && key !== "preparationKraftPaper")) {

                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                }
            }
            if (this.state.data.shipping === "CONTAINER") {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                }

            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    validateFinalOffer(container) {
        let required = {};
        Object.keys(container).map(key => {
            if (this.state.data.incoterm === "FOB") {
                let v = container[key];
                if (v && v.required && v.required(v.value)) {
                    required[key] = v;
                    if (key === "destinationCountry") {
                        this.validateCountry(" country.");
                    }
                    if (key === "destinationPort") {
                        this.inspectionValidate(" port.");
                    }

                }
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }

        return true;
    }
    inspectionValidate = errorMessage => {
        this.setState({
            serverError: errorMessage
        });
    }
    validateCountry = errorMessage => {
        this.setState({
            countryError: errorMessage
        });
    }

    companyValidate = errorMessage => {
        this.setState({
            companyError: errorMessage
        });
    }



    onSubmitNextInspection = (e) => {
        e.preventDefault();
        if (this.validateFinalOffer(this.state.inspectionQuote)) {
            if (this.state.data.inspection) {
                let selectedInspectionCompany = this.props.inspectionCompanies.find(company => company.ID === this.state.data.inspection);
                if (selectedInspectionCompany && selectedInspectionCompany.serviceProviderFlag === false) {
                    this.companyValidate("The inspection company is not on the list of approved service providers. Please contact your company administrator to add service providers.");
                    this.props.sendEmail();
                }

                else {
                    this.openModal("PROVISIONAL_INSPECTION_VESSEL_NEXT", this.state.data);
                }
            }
        }
    }

    onSubmitInspection = (e) => {
        e.preventDefault();
        if (this.validate(this.state.inspectionQuoteNext)) {
            var inspectionInfo = this.getStateValue(this.state.inspectionQuoteNext);
            // var destCountry=(this.state.data.incoterm==="CIF"||"CFR")?this.state.data.destCountry:this.state.data.loadCountry;
            var destCountry = (this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") ? this.state.data.destCountry : this.state.inspectionQuote.destinationCountry.value;
            var destPort = (this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") ? this.state.data.destPort : this.state.inspectionQuote.destinationPort.value;
            const quoteData =
            {
                packaging: renderPackaging(this.state.data.packaging),
                tradeRefID: this.state.data.tradeRefId,
                tradeType: this.state.data.buyerId && this.state.data.sellerId ? "Private" : "Public",
                incoterm: this.state.data.incoterm,
                toCompanyId: this.state.data.inspection,
                toCompanyName: this.state.data.inspectionName,
                shippingType: this.state.data.shipping,
                status: "NEW",
                rejectReason: this.state.vesselData.remark,
                inspectionQuoteType: "FINAL",
                originPort: this.state.data.origin,
                originCountry: this.state.data.origin,
                destinationPort: destPort,
                destinationCountry: destCountry,
                tonnage: (this.state.data.measure) + " " + MEASUREMENTS[this.state.data.measurement],
                deliveryDateTo: this.state.inspectionQuote.deliveryStartDate.value.format('YYYY-MM-DD'),
                deliveryDateFrom: this.state.inspectionQuote.deliveryEndDate.value.format('YYYY-MM-DD'),
                vatNumber: "",
                quoteRequestDate: "",
                inspectionInfo,

            }
            this.setState({ inProgress: true });
            this.props.PostInspectionQuote(this.state.data.id, quoteData, this.props.account.user.companyId, response => {
                if (response.inspectionQuoteId !== undefined) {
                    this.setState({ inProgress: false, isThankyouHeader: true });
                    this.openModalTwo("VESSEL_THANKYOU_NOTE", this.state.isThankyouHeader)
                    this.closeModalThree();
                }
            });
        }
    }

    getStateValue(container) {
        let result = {};
        Object.keys(container).map(key => {
            if (this.state.data.shipping === "VESSEL") {
                switch (key) {
                    case 'desiccants':
                    case 'preparationKraftPaper':
                        result[key] = "";
                        break;
                    default:
                        result[key] = container[key].value;
                        break;
                }
            }
            else {
                if (key) {
                    result[key] = container[key].value;
                }
            }
            return false;
        });
        return result;
    }


    getCounterpartyCompany() {
        let counterparyCompany = "";
        const trade = this.props.trade.items.single;
        if (trade.requestType === "BUY") {
            if (this.isTradeOwner) {
                counterparyCompany = trade.seller;
            } else {
                counterparyCompany = trade.buyer;
            }
        } else {
            if (this.isTradeOwner) {
                counterparyCompany = trade.buyer;
            } else {
                counterparyCompany = trade.seller;
            }
        }
        this.setState({ counterparyCompany: counterparyCompany })
    }

    onSubmitVessel = (tradID, quoteType) => {
        var data = this.props.shippingCompanyList;
        var CompanyName = "";
        var toCompanyId = String(this.state.shippingQuote.shipping.value);
        Object.keys(data).forEach(function eachKey(key) {
            if (toCompanyId === key) {
                CompanyName = data[key];
            }
        });
        this.setState({ inProgress: true });
        // var destCountry=(this.state.data.incoterm==="CIF"|| this.state.data.incoterm==="CFR")?this.state.data.destCountry:this.state.data.loadCountry;
        // var destPort=(this.state.data.incoterm==="CIF"|| this.state.data.incoterm==="CFR")?this.state.data.destPort:this.state.data.loadPort;

        var destCountry = (this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") ? this.state.data.destCountry : this.state.shippingQuote.destinationCountry.value;
        var destPort = (this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") ? this.state.data.destPort : this.state.shippingQuote.destinationPort.value;
        const quoteData =
        {
            tradeRefID: this.state.data.tradeRefId,
            tradeType: this.state.data.buyerId && this.state.data.sellerId ? "Private" : "Public",
            toCompanyId: this.state.shippingQuote.shipping.value ? String(this.state.shippingQuote.shipping.value) : null,
            companyName: CompanyName,
            vatNumber: "",
            quantity: this.state.data.measure,
            mode: "Vessel(Manual)",
            originCountry: this.state.data.origin,
            originPort: this.state.data.origin,
            destinationCountry: destCountry,
            destinationPort: destPort,
            shipping: this.state.data.shipping,
            incoterm: this.state.data.incoterm,
            packaging: renderPackaging(this.state.data.packaging),
            quoteType: quoteType,
            status: "NEW",
            remarks: this.state.vesselData.remark,
            deliveryDateTo: this.state.shippingQuote.deliveryStartDate.value.format('YYYY-MM-DD'),
            deliveryDateFrom: this.state.shippingQuote.deliveryEndDate.value.format('YYYY-MM-DD'),
            discharge: this.state.data.discharge,
            dischargeRate: this.state.data.dischargeRate,
            vesselShippingRate : this.state.shippingQuote.term.value,
            addressCommision: parseFloat(this.state.shippingQuote.addressCommision.value),
            tolerance: parseFloat(this.state.data.tolerance) || 0,
            fertilizerType: FERTILIZER_TYPE[this.state.data.fertilizerType]
        }
        let selectedInspectionCompany = this.props.shippingCompanies.find(company => company.ID === toCompanyId);
        if (selectedInspectionCompany && selectedInspectionCompany.serviceProviderFlag === false) {
            this.companyValidate("The Shipping company is not on the list of approved service providers. Please contact your company administrator to add service providers.");
            this.setState({ inProgress: false });
        }
        else {
            this.props.ProvisionalQuoteVessel(tradID, quoteType, toCompanyId, quoteData, response => {
                if (response.shippingQuoteID !== undefined) {
                    this.setState({ inProgress: false });
                    // this.openModal("VESSEL_THANKYOU_NOTE")
                    this.openModal("SHIPPING_NOTE")
                }
            });
        }
    };

    handleChangeInput = e => {
        this.setState({ vesselData: { ...this.state.vesselData, [e.target.name]: e.target.value } });
    };

    setField = (name, value) => {
        if (typeof value === 'object' && value !== null) {
            this.setState({
                shippingDate: { ...this.state.shippingDate, value: value }
            });
        }
    };
    // shipmentCreated=()=>{
    //     this.setState({  isShipmentCreated: false });
    // }

    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'PROVISIONAL_QUOTE_DETAILS':
                return <RequestBookingModal
                    onClose={e => this.setState({ modal: 'PROVISIONAL_QUOTECONTAINER' })}
                    allowBooking={true}
                    // shipmentCreated= {this.shipmentCreated}   
                    onCloseAll={e => this.setState({ showModal: false })}
                />
            case 'PROVISIONAL_QUOTECONTAINER':
                return <RequestModal
                    onClose={this.closeModal}
                    onRequestMoreDetails={e => this.setState({ modal: 'PROVISIONAL_QUOTE_DETAILS' })}
                    quoteType="FINAL" />;
            case 'PROVISIONAL_QUOTEVESSEL':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                this.onSubmitVessel(this.state.data.id, "FINAL");
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Request a Final Offer for Shipment via Vessel</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote">
                                    <p> Please provide some background information on your request and click 'Confirm' to continue or click 'Back' to return previous screen.
                                Note that the information listed next to the text box is the information that is automatically added to your request..</p>
                                    <div className="row mt-3">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInputField
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                name={'dischargeRate'}
                                                validation={this.state.required}
                                                value={this.state.shippingQuote.dischargeRate}
                                                onChange={e => this.setShippingField("dischargeRate", e.target.value)}
                                                placeholder="Rate"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormSelectDropdownList
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                name={"addressCommision"}
                                                items={COMMISSION_SHIPPING}
                                                required={true}
                                                onSelect={e => {
                                                    this.setShippingField('addressCommision', e.target.value);
                                                }}
                                                value={this.state.shippingQuote.addressCommision}
                                                showDefault={true}
                                            />
                                        </div>
                                    </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Buyer
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.buyer}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Seller
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.seller}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Quantity (MT)
                                                </div>
                                                <div className="trades-header-value">
                                                    <span >
                                                        <TradeQuantityFormatter trade={this.state.data} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Tolerance
                                            </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.tolerance || 0}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Packaging
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.renderPortLabel(renderPackaging(this.state.data.packaging))}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Incoterm
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.incoterm}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Shipment
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.shipping}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Product
                                            </div>
                                                <div className="trades-header-value">
                                                    {FERTILIZER_TYPE[this.state.data.fertilizerType]}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Origin Country
                                                </div>
                                                <div className="trades-header-value">
                                                    {renderCountry(this.state.data.origin)}
                                                </div>
                                            </div>

                                            {(this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Country
                                                    </div>

                                                    <div className="trades-header-value">
                                                        {renderCountry(this.state.data.destCountry)}
                                                    </div>
                                                </div>}
                                            {this.state.data.incoterm === "FOB" &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Country <span className="req-field">&nbsp;*</span>
                                                    </div>
                                                    <div className="trades-header-value">
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            validation={this.state.required}
                                                            className="select"
                                                            name="destinationCountry"
                                                            items={Countries}
                                                            onSelect={e => {
                                                                this.setShippingField("destinationCountry", e.target.value);
                                                                this.setShippingField("destinationPort", "");
                                                            }}
                                                            value={this.state.shippingQuote.destinationCountry}
                                                            placeholder="Country"
                                                        />
                                                        {this.state.countryError &&
                                                            <div className="counterparty__error">{this.state.countryError}</div>}
                                                    </div>
                                                </div>}
                                            {(this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Port
                                                    </div>
                                                    <div className="trades-header-value">
                                                        {this.renderPortLabel(this.state.data.destPort)}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.data.incoterm === "FOB" &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Port <span className="req-field">&nbsp;*</span>
                                                    </div>
                                                    <div className="trades-header-value">
                                                        <FormAsyncSelectPort 
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            fieldData={this.state.shippingQuote.destinationPort}
                                                            onChange={(name, value, fromDropdown) => {
                                                                this.setShippingField("destinationPort", value);
                                                                if(fromDropdown){
                                                                    this.setShippingField("destinationCountry", value.slice(0, 2));
                                                                }
                                                            }}
                                                            country={this.state.shippingQuote.destinationCountry.value}
                                                            menuPlacement="top"
                                                            styles={quoteModalsReactSelectStyle}
                                                        />
                                                        {this.state.portError &&
                                                            <div className="counterparty__error">{this.state.portError}</div>}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-md-6">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <FormSelectDropdownList
                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                validation={this.state.required}
                                                name={"term"}
                                                items={TERM_SHIPPING}
                                                required={true}
                                                onSelect={e => {
                                                    this.setShippingField('term', e.target.value);
                                                }}
                                                value={this.state.shippingQuote.term}
                                                showDefault={true}
                                            />
                                        </div>
                                    </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {this.props.shippingCompanyList &&
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name="shipping"
                                                            items={this.props.shippingCompanyList}
                                                            validation={this.state.required}
                                                            ///  validation={`input ${this.state.required}`}
                                                            value={this.state.shippingQuote.shipping}
                                                            onSelect={e => this.setShippingField('shipping', e.target.value)}
                                                            placeholder={this.state.shippingQuote.shipping.value}
                                                            //   required={true}
                                                            showDefault={true}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FormDateField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        dateFormat={DATEFORMAT}
                                                        required={true}
                                                        validation={this.state.required}
                                                        name="deliveryStartDate"
                                                        minDate={moment()}
                                                        item={this.state.shippingQuote.deliveryStartDate}
                                                        time={false}
                                                        label={true}
                                                        showTimeSelect={false}
                                                        onSelect={(name, date) => this.setShippingField(name, date)}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <FormDateField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        dateFormat={DATEFORMAT}
                                                        required={true}
                                                        validation={this.state.required}
                                                        name="deliveryEndDate"
                                                        minDate={moment()}
                                                        item={this.state.shippingQuote.deliveryEndDate}
                                                        time={false}
                                                        label={true}
                                                        showTimeSelect={false}
                                                        onSelect={(name, date) => this.setShippingField(name, date)}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="remark" onChange={this.handleChangeInput} placeholder="Additional Information." className="form-control text-color" rows="5">{this.state.vesselData.remark}</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.companyError &&
                                        <div className="popupCompany__error">{this.state.companyError}</div>}
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> <span>Back</span>
                                    </button>
                                    <button type="submit" className="btn-popup btn-submit" disabled={this.state.inProgress}>
                                        <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                            <FontAwesomeIcon icon={faCheck} /> <span>Confirm</span>
                                        </Preloader>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'PROVISIONAL_INSPECTION_VESSEL':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                                // if (this.validate(this.state.inspectionQuote)) {
                                //     this.onSubmitNextInspection();
                                // }             
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Request a Final Offer for Inspection via {this.state.isshippingType ? "Vessel" : "Container"}</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModalTwo} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote">
                                    <p> Please provide some background information on your request and click 'Confirm' to continue or click 'Back' to return previous screen.
                                     Note that the information listed next to the text box is the information that is automatically added to your request..</p>
                                    <div className="row mt-3">
                                        <div className="col-md-6">

                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Inspection Company
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.inspectionName}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Buyer
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.buyer}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Seller
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.seller}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Quantity (MT)
                                                </div>
                                                <div className="trades-header-value">
                                                    <span >
                                                        <TradeQuantityFormatter trade={this.state.data} />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Shipment
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.shipping}
                                                </div>

                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Packaging
                                                </div>
                                                <div className="trades-header-value">
                                                    {renderPackaging(this.state.data.packaging)}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Incoterm
                                                </div>
                                                <div className="trades-header-value">
                                                    {this.state.data.incoterm}
                                                </div>
                                            </div>
                                            <div className="trades-header-wrapper">
                                                <div className="trades-header-label">
                                                    Origin Country
                                                </div>
                                                <div className="trades-header-value">
                                                    {renderCountry(this.state.data.origin)}
                                                </div>
                                            </div>

                                            {(this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Country
                                                    </div>

                                                    <div className="trades-header-value">
                                                        {renderCountry(this.state.data.destCountry)}
                                                    </div>
                                                </div>}
                                            {this.state.data.incoterm === "FOB" &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Country <span className="req-field">&nbsp;*</span>
                                                    </div>
                                                    <div className="trades-header-value">
                                                        <FormSelectDropdown
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            validation={this.state.required}
                                                            className="select"
                                                            name="destinationCountry"
                                                            items={Countries}
                                                            onSelect={e => {
                                                                this.setInspectionField("destinationCountry", e.target.value);
                                                                this.setInspectionField("destinationPort", "");
                                                            }}
                                                            value={this.state.inspectionQuote.destinationCountry}
                                                            placeholder="Country"
                                                        />
                                                        {this.state.countryError &&
                                                            <div className="counterparty__error">Please select{this.state.countryError}</div>}
                                                    </div>
                                                </div>}
                                            {(this.state.data.incoterm === "CIF" || this.state.data.incoterm === "CFR") &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Destination Port
                                                    </div>
                                                    <div className="trades-header-value">
                                                        {this.renderPortLabel(this.state.data.destPort)}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.data.incoterm === "FOB" &&
                                                <div className="trades-header-wrapper">
                                                    <div className="trades-header-label">
                                                        Port of Load <span className="req-field">&nbsp;*</span>
                                                    </div>
                                                    <div className="trades-header-value">
                                                        <FormAsyncSelectPort 
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            fieldData={this.state.inspectionQuote.destinationPort}
                                                            onChange={(name, value, fromDropdown) => {
                                                                this.setInspectionField("destinationPort", value);
                                                                if(fromDropdown){
                                                                    this.setInspectionField("destinationCountry", value.slice(0, 2));
                                                                }
                                                            }}
                                                            country={this.state.inspectionQuote.destinationCountry.value}
                                                            menuPlacement="top"
                                                            styles={quoteModalsReactSelectStyle}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.state.serverError &&
                                                <div className="counterparty__error">Please select{this.state.serverError}</div>}

                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FormDateField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        dateFormat={DATEFORMAT}
                                                        required={true}
                                                        validation={this.state.required}
                                                        name="deliveryStartDate"
                                                        minDate={moment()}
                                                        item={this.state.inspectionQuote.deliveryStartDate}
                                                        time={false}
                                                        label={true}
                                                        showTimeSelect={false}
                                                        onSelect={(name, date) => this.setInspectionField(name, date)}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FormDateField
                                                        customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                        dateFormat={DATEFORMAT}
                                                        required={true}
                                                        validation={this.state.required}
                                                        name="deliveryEndDate"
                                                        minDate={moment()}
                                                        item={this.state.inspectionQuote.deliveryEndDate}
                                                        time={false}
                                                        label={true}
                                                        showTimeSelect={false}
                                                        onSelect={(name, date) => this.setInspectionField(name, date)}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="remark" onChange={this.handleChangeInput} placeholder="Additional Information." className="form-control text-color" rows="7">{this.state.vesselData.remark}</textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {this.state.companyError &&
                                        <div className="popupCompany__error">{this.state.companyError}</div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.closeModalTwo} className="btn-popup btn-cancel">
                                    <FontAwesomeIcon icon={faTimes} /> <span>Back</span></button>
                                <button type="submit" onClick={(e) => this.onSubmitNextInspection(e)} className="btn-popup btn-submit">
                                    <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                        <FontAwesomeIcon icon={faCheck} /> <span>Next</span>
                                    </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );
            case 'PROVISIONAL_INSPECTION_VESSEL_NEXT':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Request a Final Offer for Inspection via {this.state.isshippingType ? "Vessel" : "Container"} </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModalThree} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote">
                                    <div >
                                        <div >
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            name={'location'}
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.location}
                                                            onChange={e => this.setInspectionFieldNext("location", e.target.value)}
                                                            placeholder="Location"
                                                            type="text"
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.preShipmentLocation}
                                                            onChange={e => this.setInspectionFieldNext("preShipmentLocation", e.target.value)}
                                                            placeholder="Location of PSI"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            name={'sampleRetention'}
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.sampleRetention}
                                                            onChange={e => this.setInspectionFieldNext("sampleRetention", e.target.value)}
                                                            placeholder="Sample Retention"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            name={'sampleDistribution'}
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.sampleDistribution}
                                                            onChange={e => this.setInspectionFieldNext(e.target.name, e.target.value)}
                                                            placeholder="Sample distribution"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {!this.state.isshippingType &&
                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <div className="form-group">
                                                            <FormInputField
                                                                name={'desiccants'}
                                                                validation={this.state.required}
                                                                value={this.state.inspectionQuoteNext.desiccants}
                                                                onChange={e => this.setInspectionFieldNext("desiccants", e.target.value)}
                                                                placeholder="Desiccants"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div className="form-group">
                                                            <FormInputField
                                                                name={'preparationKraftPaper'}
                                                                validation={this.state.required}
                                                                value={this.state.inspectionQuoteNext.preparationKraftPaper}
                                                                onChange={e => this.setInspectionFieldNext("preparationKraftPaper", e.target.value)}
                                                                placeholder="preparation KraftPaper"
                                                                type="text"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            name={'requiredAnalysis'}
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.requiredAnalysis}
                                                            onChange={e => this.setInspectionFieldNext("requiredAnalysis", e.target.value)}
                                                            placeholder="Required Analysis"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <FormInputField
                                                            name={'others'}
                                                            validation={this.state.required}
                                                            value={this.state.inspectionQuoteNext.others}
                                                            onChange={e => this.setInspectionFieldNext("others", e.target.value)}
                                                            placeholder="Special Request/Special Documents"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="radio__label">
                                                        <FormRadioLabelVerticalTrueFalse
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={"preShipmentInfo"}
                                                            value={this.state.inspectionQuoteNext.preShipmentInfo}
                                                            onChange={e => this.setInspectionFieldNext("preShipmentInfo", e.target.value)}
                                                        /> </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="radio__label">
                                                        <FormRadioLabelVerticalTrueFalse
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={"markingsCheck"}
                                                            value={this.state.inspectionQuoteNext.markingsCheck}
                                                            onChange={e => this.setInspectionFieldNext("markingsCheck", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.isshippingType &&

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="radio__label">
                                                            <FormRadioLabelVerticalTrueFalse
                                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                                name={"draftSurvey"}
                                                                value={this.state.inspectionQuoteNext.draftSurvey}
                                                                onChange={e => this.setInspectionFieldNext("draftSurvey", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="radio__label">
                                                            <FormRadioLabelVerticalTrueFalse
                                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                                name={"tally"}
                                                                value={this.state.inspectionQuoteNext.tally}
                                                                onChange={e => this.setInspectionFieldNext("tally", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            {this.state.isshippingType &&
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="radio__label">
                                                            <FormRadioLabelVerticalTrueFalse
                                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                                name={"hoseTestRequired"}
                                                                value={this.state.inspectionQuoteNext.hoseTestRequired}
                                                                onChange={e => this.setInspectionFieldNext("hoseTestRequired", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="radio__label">
                                                            <FormRadioLabelVerticalTrueFalse
                                                                customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                                name={"holdsInspectionReport"}
                                                                value={this.state.inspectionQuoteNext.holdsInspectionReport}
                                                                onChange={e => this.setInspectionFieldNext("holdsInspectionReport", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="radio__label">
                                                        <FormRadioLabelVerticalTrueFalse
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={"sampling"}
                                                            value={this.state.inspectionQuoteNext.sampling}
                                                            onChange={e => this.setInspectionFieldNext("sampling", e.target.value)}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="col-md-6">
                                                    <div className="radio__label">
                                                        <FormRadioLabelVerticalTrueFalse
                                                            customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                            name={"sealing"}
                                                            value={this.state.inspectionQuoteNext.sealing}
                                                            onChange={e => this.setInspectionFieldNext("sealing", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="radio__label">
                                                <FormRadioLabelVerticalTrueFalse
                                                    customStyle={this.CUSTOM_COMPONENT_STYLE}
                                                    name={"fumigation"}
                                                    value={this.state.inspectionQuoteNext.fumigation}
                                                    onChange={e => this.setInspectionFieldNext("fumigation", e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.openModal("PROVISIONAL_INSPECTION_VESSEL", this.state.data)} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} /> <span>Back</span>
                                </button>
                                <button type="submit" onClick={(e) => this.onSubmitInspection(e)} disabled={this.state.inProgress} className="btn-popup btn-submit">
                                    <Preloader loadingStyle="dots" loading={this.state.inProgress}>
                                        <FontAwesomeIcon icon={faCheck} /> <span>Confirm</span>
                                    </Preloader>
                                </button>
                            </div>
                        </form>
                    </div>
                );
            case 'SHIPPING_NOTE':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0"
                            onSubmit={e => {
                                e.preventDefault();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center">
                                        <h5 className="modal-title">Request a Final Offer for Shipment via Vessel </h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body provisional-Quote">
                                    <div className="text-center">
                                        <div className="big-dot"><FontAwesomeIcon icon={faCheckCircle} /></div>
                                        <h5 className="thanks-message text-center">Thank you</h5>
                                    </div>
                                    <p className="mt-2 px-2 text-center">
                                        Your request has been submitted to the service provider. Note that any information is
                                        provisional and subject to a final quotation by the Service Provider at a later stage during the
                                        process.
                                    </p>
                                    <p className="mt-1 px-1 text-center">
                                        You will receive a notification once the service provider has processed your request. Via your
                                        'Offer
                                        Requests' Overview screen you have the opportunity to add comments or ask additional questions.
                                        Click the Close button to return to the previous screen.
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                    <FontAwesomeIcon icon={faTimes} /> <span>Close</span>
                                </button>
                            </div>
                        </form>
                    </div>
                );

            case 'PERMISSION_REQUIRED':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                );

            default:
                break;
        }
    };

    renderModalTwo = (whatModal) => {
        if (whatModal === 'VESSEL_THANKYOU_NOTE') {
            return (
                <div className="modal__container">
                    <form
                        className="modal__wrapper px-0 py-0"
                        onSubmit={e => {
                            e.preventDefault();
                        }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-header-center">
                                    <h5 className="modal-title">Request a Final Offer for {this.state.isThankyouHeader ? " Inspection " : " Shipment"} via {this.state.isshippingType ? "Vessel" : "Container"}</h5></div>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button></div>
                            <div className="modal-body provisional-Quote">
                                <div className="text-center">
                                    <div className="big-dot"><FontAwesomeIcon icon={faCheckCircle} /></div>
                                    <h5 className="thanks-message text-center">Thank you</h5>
                                </div>
                                <p className="mt-2 px-2 text-center">
                                    Your request has been submitted to the service provider. Note that any information is
                                    provisional and subject to a final quotation by the service provider at a later stage during the
                                    process.
                               </p>
                                <p className="mt-1 px-1 text-center">
                                    You will receive a notification once the service provider has processed your request. Via your
                                    'offer
                                    Requests' Overview screen you have the opportunity to add comments or ask additional questions.
                                    Click the Back button to return to the previous screen.
                              </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                <FontAwesomeIcon icon={faTimes} />&nbsp;Close
                           </button>
                        </div>
                    </form>
                </div>
            );
        }
    };


    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, data: data })
    };

    openModalTwo = (name, data) => {
        this.setState({
            showModalTwo: true, modalTwo: name, data: data,
        });
    };

    closeModal = () => {
        let shippingQuote = this.state.shippingQuote;
        shippingQuote['shipping'] = {
            ...shippingQuote['shipping'],
            value: ''
        };
        shippingQuote['destinationCountry'] = {
            ...shippingQuote['destinationCountry'],
            value: ''
        };
        shippingQuote['destinationPort'] = {
            ...shippingQuote['destinationPort'],
            value: ''
        };
        this.setState({
            showModal: false,
            showModalTwo: false,
            classApplied: false,
            classResponseError: false,
            responseMessage: "",
            classResponseData: false,
            modal: '',
            input: '',
            modalData: {},
            shippingDate: {
                name: "shippingDate",
                label: "Shipping Date",
                required: false,
                value: moment(this.props.trade.deliveryStartDate),
            },
            isThankyouHeader: false,
            shippingQuote: shippingQuote,
            vesselData: {
                id: "",
                name: "",
                remark: "",
            },
            companyError: ""
        });
    };


    closeModalTwo = () => {
        let isEmpty = value => !value || value === undefined || value === '';
        let inspectionQuote = {};
        // this.initField(inspectionQuote, 'inspection', 'Inspection Company', '', isEmpty);
        const deliveryStartDate = moment(this.props.trade.deliveryStartDate).startOf('day');
        this.initField(inspectionQuote, 'deliveryStartDate', 'Shipping / Delivery Period (from)', deliveryStartDate, isEmpty);
        const deliveryEndDate = (this.props.trade.deliveryEndDate) ? moment(this.props.trade.deliveryEndDate) : undefined;
        this.initField(inspectionQuote, 'deliveryEndDate', 'Shipping / Delivery Period (to)', deliveryEndDate, isEmpty);
        this.initField(inspectionQuote, 'destinationCountry', 'Destination Country', '', isEmpty);
        this.initField(inspectionQuote, 'destinationPort', 'Destination Port', '', isEmpty);
        this.setState({
            inspectionQuote: inspectionQuote,
            showModal: false,
            modal: '',
            input: '',
            modalData: {},
            vesselData: {
                id: "",
                name: "",
                remark: "",
            },
            serverError: '',
            countryError: '',
            companyError: "",
        });
    };

    closeModalThree = () => {
        let inspectionQuote = this.state.inspectionQuote;
        inspectionQuote['inspection'] = {
            ...inspectionQuote['inspection'],
            value: '',
            require: true
        };
        inspectionQuote['destinationCountry'] = {
            ...inspectionQuote['destinationCountry'],
            value: ''
        };
        inspectionQuote['destinationPort'] = {
            ...inspectionQuote['destinationPort'],
            value: ''
        };
        let inspectionQuoteNext = this.state.inspectionQuoteNext;
        inspectionQuoteNext['location'] = {
            ...inspectionQuoteNext['location'],
            value: '',
            require: true
        };
        inspectionQuoteNext['sampleDistribution'] = {
            ...inspectionQuoteNext['sampleDistribution'],
            value: ''
        };
        inspectionQuoteNext['sampleRetention'] = {
            ...inspectionQuoteNext['sampleRetention'],
            value: ''
        };
        inspectionQuoteNext['requiredAnalysis'] = {
            ...inspectionQuoteNext['requiredAnalysis'],
            value: ''
        };
        inspectionQuoteNext['others'] = {
            ...inspectionQuoteNext['others'],
            value: ''
        };
        inspectionQuoteNext['desiccants'] = {
            ...inspectionQuoteNext['desiccants'],
            value: ''
        };
        inspectionQuoteNext['preShipmentLocation'] = {
            ...inspectionQuoteNext['preShipmentLocation'],
            value: ''
        };
        inspectionQuoteNext['preShipmentInfo'] = {
            ...inspectionQuoteNext['preShipmentInfo'],
            value: true
        };
        inspectionQuoteNext['draftSurvey'] = {
            ...inspectionQuoteNext['draftSurvey'],
            value: true
        };
        inspectionQuoteNext['tally'] = {
            ...inspectionQuoteNext['tally'],
            value: true
        };
        inspectionQuoteNext['sampling'] = {
            ...inspectionQuoteNext['sampling'],
            value: true
        };
        inspectionQuoteNext['markingsCheck'] = {
            ...inspectionQuoteNext['markingsCheck'],
            value: true
        };
        inspectionQuoteNext['sealing'] = {
            ...inspectionQuoteNext['sealing'],
            value: true
        };
        inspectionQuoteNext['hoseTestRequired'] = {
            ...inspectionQuoteNext['hoseTestRequired'],
            value: true
        };
        inspectionQuoteNext['holdsInspectionReport'] = {
            ...inspectionQuoteNext['holdsInspectionReport'],
            value: true
        };
        inspectionQuoteNext['fumigation'] = {
            ...inspectionQuoteNext['fumigation'],
            value: true
        };
        this.setState({
            showModal: false,
            classApplied: false,
            classResponseError: false,
            classResponseData: false,
            responseMessage: "",
            input: '',
            modalData: {},
            inspectionQuoteNext: inspectionQuoteNext,
            inspectionQuote: inspectionQuote,
            vesselData: {
                id: "",
                name: "",
                remark: "",
            }
        });
    };


    render() {
        const trade = { ...this.props.trade };
        const tradeInfo = { ...this.props.info };
        let priceField = []
        const fieldList = this.getInitialFieldList();
        if (trade.price !== undefined && fieldList[0].key !== 'price') {
            if (trade.multipleOriginAccept && !tradeInfo.signBuyer && !tradeInfo.signSeller) {
                priceField.push({
                    key: "multipleOriginPrice",
                    label: "Price per MT (Origin)",
                    "customStyle": "row-price",
                    tdClass: this.tdClass,
                    format: (value, serie) => {
                        return value.map(item => (
                            <NumberFormat
                                key={item.origin}
                                displayType="text"
                                value={item.isPriceCheck ? item.price : '-'}
                                renderText={text => <div>{text}</div>}
                                thousandSeparator={true}
                                prefix={` ${CURRENCYOPT[serie.data.currency] || "USD"}`}
                                suffix={` (${Countries[item.origin]})`} />))
                    }
                })
            } else {
                priceField.push({
                    key: "price",
                    label: "Price",
                    "customStyle": "row-price",
                    format: (value, serie) => {
                        return (
                            <NumberFormat
                                value={value}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={" (per MT)"}
                                prefix={`${CURRENCYOPT[serie.data.currency] || "USD"} `} />
                        )
                    },
                    tdClass: this.tdClass
                })
            }

        }
        let updatedFieldList = [...priceField, ...fieldList];
        return <div className="trades-dtls__contract">
            <DetailTable
                fixWidth={false}
                series={[{ title: 'Field Value', data: trade }]}
                fieldList={updatedFieldList}>
            </DetailTable>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        shipmentQuote: state.shipmentQuote,
        inspectionQuote: state.inspectionQuote,
        account: state.account
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Info);
