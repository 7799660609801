import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInitialCompanyCapabilities } from '../../../../utils/companyCapabilitiesData';
import Preloader from '../../../components/preloader/Preloader';
import { updateComapanyCapabilities } from '../../../../modules/module.account';
import SubmitConfirmModal from '../viewCompanyProfile/components/SubmitConfirmModal';
//ICONS
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class CompanyCapabilities extends Component {
  constructor(props) {
    super(props)
    this.COMPANY_SELLINGINFO = getInitialCompanyCapabilities()

    this.state = {
      ...getInitialCompanyCapabilities(),
      aveTonnage: undefined,
      countryOther: "",
      packingOther: "",
      isOrigin: false,
      isPacking: false,
      isFertilizerType: false,
      ismode: false,
      isIncoterm: false,
      isOriginOther: false,
      isPackingOther: false,
      isAveTonnage: false,
      submitConfirmModal: false,
      isButtonLabel: true,
      popUptitle: ""

    }
  }

  componentDidMount() {
    this.updateInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.capability !== this.props.capability) {
      this.updateInfo();
    }
  }


  updateInfo() {

    var isButtonLabel = true;
    if (this.props.capability !== null) {
      isButtonLabel = false;
    }
    else {
      isButtonLabel = true;
    }
    this.setState({ isButtonLabel: isButtonLabel });

    if (this.props.capability !== undefined) {
      var dataObject = [];
      dataObject = this.props.capability;
      const COMPANY_SELLINGINFO = getInitialCompanyCapabilities()
      var origin = COMPANY_SELLINGINFO.origin;
      var packing = COMPANY_SELLINGINFO.packing;
      var typeOfFertilizer = COMPANY_SELLINGINFO.typeOfFertilizer;
      var modeTransportation = COMPANY_SELLINGINFO.modeTransportation;
      var incoterm = COMPANY_SELLINGINFO.incoterm;
      var countryOther = "";
      var packingOther = "";
      if (this.props.capability !== null) {
        origin.forEach(org => {
          let filterOrigin = dataObject.origin.some(itemOrg => itemOrg === org.value);
          if (filterOrigin) { org.isChecked = true } else { org.isChecked = false }
        })

        packing.forEach(pck => {
          let filterPck = dataObject.packaging.some(itempck => itempck === pck.value);
          if (filterPck) { pck.isChecked = true } else { pck.isChecked = false }
        })

        typeOfFertilizer.forEach(typeFertilizer => {
          let filterfertilizerType = dataObject.fertilizerType.some(fItem => fItem === typeFertilizer.value);
          if (filterfertilizerType) { typeFertilizer.isChecked = true } else { typeFertilizer.isChecked = false }
        })

        modeTransportation.forEach(mode => {
          let filtermode = dataObject.shipping.some(modeItem => modeItem === mode.value);
          if (filtermode) { mode.isChecked = true } else { mode.isChecked = false }
        })

        incoterm.forEach(inco => {
          let filterIncoterm = dataObject.incoterm.some(incoItem => incoItem === inco.value);
          if (filterIncoterm) { inco.isChecked = true } else { inco.isChecked = false }
        })

        countryOther = dataObject.originOther;
        packingOther = dataObject.packagingOther;
        var aveTonnage = dataObject.monthlyCapacity === 0 ? "" : dataObject.monthlyCapacity;
        //change the submit button label on the initail value of data 
        isButtonLabel = dataObject.monthlyCapacity === 0;

      }
      this.setState({
        origin: origin,
        packing: packing,
        typeOfFertilizer: typeOfFertilizer,
        modeTransportation: modeTransportation,
        incoterm: incoterm,
        countryOther: countryOther,
        packingOther: packingOther,
        aveTonnage: aveTonnage,
        isButtonLabel: isButtonLabel

      });
    }
  }


  handleCheckChieldElement = (listName, event) => {
    let itemValue = event.target.value;
    let checked = event.target.checked;
    switch (listName) {
      case "origin":
        let origin = this.state.origin
        origin.forEach(originItem => {
          if (originItem.value === itemValue) {
            originItem.isChecked = checked
          }
          if (itemValue === "other" && !checked) {
            this.setState({ countryOther: "" });
          }
        })
        this.setState({ origin: origin, isOrigin: false, isOriginOther: false })
        break;
      case "packing":
        let packing = this.state.packing
        packing.forEach(packingItem => {
          if (packingItem.value === itemValue) {
            packingItem.isChecked = checked
          }
          if (itemValue === "other" && !checked) {
            this.setState({ packingOther: "" });
          }
        })
        this.setState({ packing: packing, isPacking: false, isPackingOther: false })
        break;
      case "typeOfFertilizer":
        let typeOfFertilizer = this.state.typeOfFertilizer
        typeOfFertilizer.forEach(f => {
          if (f.value === itemValue) {
            f.isChecked = checked
          }
        })
        this.setState({ typeOfFertilizer: typeOfFertilizer, isFertilizerType: false })
        break;
      case "modeTransportation":
        let modeTransportation = this.state.modeTransportation
        modeTransportation.forEach(mode => {
          if (mode.value === itemValue) {
            mode.isChecked = checked
          }
        })
        this.setState({ modeTransportation: modeTransportation, ismode: false })
        break;
      case "incoterm":
        let incoterm = this.state.incoterm
        incoterm.forEach(inco => {
          if (inco.value === itemValue) {
            inco.isChecked = checked
          }
        })
        this.setState({ incoterm: incoterm, isIncoterm: false })
        break;
      default:
    }
  }

  onChangeInput = (itemName, e) => {
    var value = e.target.value;
    switch (itemName) {
      case "countryOther":
        this.setState({ countryOther: value, isOriginOther: false }); break;
      case "aveTonnage":
        this.setState({ aveTonnage: value, isAveTonnage: false }); break;
      case "packingOther":
        this.setState({ packingOther: value, isPackingOther: false }); break;
      default: break;
    }
  }

  saveCapabilities = (e) => {
    e.preventDefault();
    if (this.validated()) {
      var data = {};
      let origin = this.state.origin.filter(itemOrg => itemOrg.isChecked === true).map(itemOrg => itemOrg.value);
      let packing = this.state.packing.filter(itemPac => itemPac.isChecked === true).map(itemPac => itemPac.value);
      let modeTransportation = this.state.modeTransportation.filter(mode => mode.isChecked === true).map(mode => mode.value);
      let typeOfFertilizer = this.state.typeOfFertilizer.filter(itemType => itemType.isChecked === true).map(itemType => itemType.value);
      let incoterm = this.state.incoterm.filter(item => item.isChecked === true).map(item => item.value);
      var dataObject = {
        shipping: modeTransportation,
        origin: origin,
        fertilizerType: typeOfFertilizer,
        incoterm: incoterm,
        packaging: packing,
        monthlyCapacity: parseInt(this.state.aveTonnage),
        originOther: this.state.countryOther,
        packagingOther: this.state.packingOther,
      }
      var popUptitle = "";
      if (this.props.tabData === "sellingInfo") {
        data = {
          sellingCapability: dataObject,
          buyingPreference: this.props.account.companyProfile.company.buyingPreference
        }
        this.props.updateComapanyCapabilities(this.props.account.token.companyId, data, response => {
          if (response.status === 200) {

            if (response.data.data.updateSellerFlag === "True") {
              popUptitle = "Your data has been updated";
            }
            else {
              popUptitle = "Your data has been saved";
            }
            this.setState({ submitConfirmModal: true, popUptitle: popUptitle });
          }
        });
      }
      else {
        data = {
          sellingCapability: this.props.account.companyProfile.company.sellingCapability,
          buyingPreference: dataObject
        }
        this.props.updateComapanyCapabilities(this.props.account.token.companyId, data, response => {
          if (response.status === 200) {
            if (response.data.data.updateBuyerFlag === "True") {
              popUptitle = "Your data has been updated";
            }
            else {

              popUptitle = "Your data has been saved";
            }
            this.setState({ submitConfirmModal: true, popUptitle: popUptitle });
          }
        });
      }

    }
  }

  closeSubmitConfirmModal() {
    this.setState({ submitConfirmModal: false });
  }

  validated() {
    let origin = !this.state.origin.some(itemOrg => itemOrg.isChecked === true);
    let packing = !this.state.packing.some(itemPac => itemPac.isChecked === true);
    let modeTransportation = !this.state.modeTransportation.some(mode => mode.isChecked === true);
    let typeOfFertilizer = !this.state.typeOfFertilizer.some(item => item.isChecked === true);
    let incoterm = !this.state.incoterm.some(item => item.isChecked === true);
    let isPackingOther = this.state.packing.some(isPck => isPck.isChecked === true && isPck.value === "other") && this.state.packingOther === "";
    let originOther = this.state.origin.some(isOrg => isOrg.isChecked === true && isOrg.value === "other") && this.state.countryOther === "";
    let aveTonnage = (this.state.aveTonnage === "" || this.state.aveTonnage === undefined || this.state.aveTonnage === "0") ? true : false;
    let valid = (!origin && !packing && !modeTransportation && !typeOfFertilizer && !incoterm && !typeOfFertilizer && !isPackingOther
      && !originOther && !aveTonnage)

    this.setState({
      isOrigin: origin,
      isPacking: packing,
      isFertilizerType: typeOfFertilizer,
      ismode: modeTransportation,
      isIncoterm: incoterm,
      isOriginOther: originOther,
      isPackingOther: isPackingOther,
      isAveTonnage: aveTonnage
    })

    return valid;
  }
  isotherValueSelected = (list) => {
    const data = [...list];
    let selected = data.findIndex(item => item.value === "other" && item.isChecked === true);
    return selected;
  }

  renderList = (list, listName) => {
    return list.map((item) => {
     return (
        <div className="list-item">
          <input
            key={item.id}
            type="checkbox"
            className="form-check-input"
            name={item.name}
            value={item.value}
            checked={item.isChecked}
            onChange={(e) => this.handleCheckChieldElement(listName, e)}
            disabled={this.props.account.user.companyId === this.props.account.companyProfile.company.ID ? false :true}
          />{item.name} </div>);
  })}

  render() {
    return (
      <div className="content-wrappper">
        <form onSubmit={(e) => this.saveCapabilities(e)}>
          <h4>Capabilities</h4>
          <div className="row">
            <div className="col-md-6">
              <div className="label-Capabalities">Packing <span className="req-field">*</span></div>
              <div className="mt-3">
                <ul className="pl-3">
                  {this.renderList(this.state.packing, "packing")}
                  {this.state.isPacking && <div className="capabalities__error">Please select at least one packing</div>}
                </ul>
              </div>
              {(this.isotherValueSelected(this.state.packing) !== -1) &&
                <div className="mt-3">
                  <input className="input form-control" name="packingOther" value={this.state.packingOther} onChange={(e) => this.onChangeInput("packingOther", e)} />
                  {this.state.isPackingOther && <div className="capabalities__error">Please enter value for other country</div>}
                </div>
              }
              <div className="label-Capabalities">Fertilizer Type <span className="req-field">*</span></div>
              <div className="mt-3">
                <ul className="pl-3">
                  {this.renderList(this.state.typeOfFertilizer, "typeOfFertilizer")}
                  {this.state.isFertilizerType && <div className="capabalities__error">Please select at least one Fertilizer Type</div>}
                </ul>
              </div>
              <div className="label-Capabalities">Mode of Transportation <span className="req-field">*</span></div>
              <div className="mt-3">
                <ul className="pl-3">
                  {this.renderList(this.state.modeTransportation, "modeTransportation")}
                  {this.state.ismode && <div className="capabalities__error">Please select at least one mode of transportation</div>}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="label-Capabalities">Origin <span className="req-field">*</span></div>
              <div className="mt-3">
                <ul className="pl-3">
                  {this.renderList(this.state.origin, "origin")}
                  {this.state.isOrigin && <div className="capabalities__error">Please select at least one origin</div>}
                </ul>
              </div>
              {(this.isotherValueSelected(this.state.origin) !== -1) &&
                <div className="mt-3">
                  <input className="input form-control" name="countryOther" value={this.state.countryOther} onChange={(e) => this.onChangeInput("countryOther", e)} />
                  {this.state.isOriginOther && <div className="capabalities__error">Please enter value for other country</div>}
                </div>
              }
              <div className="label-Capabalities">Incoterm <span className="req-field">*</span></div>
              <div className="mt-3">
                <ul className="pl-3">
                  {this.renderList(this.state.incoterm, "incoterm")}
                  {this.state.isIncoterm && <div className="capabalities__error">Please select at least one incoterm</div>}
                </ul>
              </div>

              <div className="label-Capabalities">Average Monthly Deliverable Tonnage <span className="req-field">*</span></div>
              {(this.props.account.user.companyId === this.props.account.companyProfile.company.ID) ? <div className="mt-3">
                <input className="input form-control" type="number" min="1" step="1" name="aveTonnage" value={this.state.aveTonnage} onChange={(e) => this.onChangeInput("aveTonnage", e)} />
                {this.state.isAveTonnage && <div className="capabalities__error">Please enter value for average monthly deliverable tonnage</div>}
              </div> :
              <div className="mt-3">
                <input className="input form-control" disabled={true} type="number" min="1" step="1" name="aveTonnage" value={this.state.aveTonnage} onChange={(e) => this.onChangeInput("aveTonnage", e)} />
                {this.state.isAveTonnage && <div className="capabalities__error">Please enter value for average monthly deliverable tonnage</div>}
              </div>
              }
            </div>
          </div>
         {(this.props.account.user.companyId === this.props.account.companyProfile.company.ID) &&
          <div className="text-center">
            <button type="submit" className="btn-popup btn-submit" disabled={this.props.loading}>
              <Preloader loadingStyle="dots" loading={this.props.loading}>
                <FontAwesomeIcon icon={faCheck} /><span>&nbsp;{this.state.isButtonLabel ? "Save" : "Update"}</span>
              </Preloader>
            </button>
          </div>}
        </form>
        {this.state.submitConfirmModal && (
          <SubmitConfirmModal
            onClose={(e) => { this.closeSubmitConfirmModal() }}
            title={this.state.popUptitle} />)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account,
    loading: state.loading.kycLoading

  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateComapanyCapabilities
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCapabilities);
