import React from 'react';
import moment from 'moment/moment';
import { DATEFORMAT, DATEFORMATHOURS } from '../../../services/service.values';
import DatePicker from 'react-datepicker';
import './FormDateFieldFromTo.scss';

export default ({
    nameStart,
    nameEnd,
    itemStart,
    itemEnd,
    onSelect,
    placeholder = 'Select a date',
    validation,
    popperPlacement,
    required = false,
    minDate = moment()
}) => {

    /* React Date Picker v2+ uses js Date Objects */
    const momentToDate = (obj) => {
        if(moment.isMoment(obj)){
            return obj.toDate();
        }
        return obj
    }

    /* React Date Picker 3.3.0 does not accept format DD -> dd */
    const reacDatePickerFormat = {
        [DATEFORMATHOURS]: "dd MMM yyyy HH:mm",
        [DATEFORMAT]: "dd MMM yyyy",
        default: "dd MMM yyyy"
    }

    const CustomInput = React.forwardRef(({ value = "", onClick, className }, ref) => ( 
        <input ref={ref} type="text" value={value} onClick={onClick} className={className} onChange={e => e.preventDefault()}/>
    ))

    return (
        <div className="form-date-wrapper pt-2">
            <div className="form-date">
                <div className="label">
                    {itemStart.label}
                    <span className="req-field">{itemStart.required ? '*' : ''}</span>
                </div>
                <div className="date">
                    <DatePicker
                        popperClassName="test"
                        name={nameStart}
                        className={`input date ${validation && validation.hasOwnProperty(nameStart) ? ' input_error ' : ''}`}
                        onChange={date => onSelect(nameStart, moment(date))}
                        required={required}
                        customInput={<CustomInput/>}
                        minDate={momentToDate(minDate)}
                        maxDate={itemEnd.value ? momentToDate(itemEnd.value) : null}
                        selected={momentToDate(itemStart.value)}
                        placeholderText={'Select a date from'}
                        dateFormat={reacDatePickerFormat[DATEFORMAT]}
                        autoComplete="off"
                        popperPlacement={popperPlacement}
                        selectsStart
                        startDate={momentToDate(itemStart.value)}
                        endDate={momentToDate(itemEnd.value)}
                    />
                </div>
            </div>
            <div className="form-date">
                <div className="label">
                    {itemEnd.label}
                    <span className="req-field">{itemEnd.required ? '*' : ''}</span>
                </div>
                <div className="date">
                    <DatePicker
                        popperClassName="test"
                        name={nameEnd}
                        className={`input date ${validation && validation.hasOwnProperty(nameEnd) ? ' input_error ' : ''}`}
                        onChange={date => onSelect(nameEnd, moment(date))}
                        required={required}
                        minDate={itemStart.value ? momentToDate(itemStart.value) : momentToDate(minDate)}
                        selected={momentToDate(itemEnd.value)}
                        placeholderText={''}
                        dateFormat={reacDatePickerFormat[DATEFORMAT]}
                        autoComplete="off"
                        popperPlacement={popperPlacement}
                        selectsEnd
                        selectsStart
                        startDate={momentToDate(itemStart.value)}
                        endDate={momentToDate(itemEnd.value)}
                    />
                </div>
            </div>
        </div>
    );
};
