export const getInitialCompanyCapabilities = () => ({
    "origin": [
        {
            "id": 1,
            "value": "AR",
            "name": "Argentina",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "BR",
            "name": "Brazil",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 3,
            "value": "KH",
            "name": "Cambodia",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 4,
            "value": "CN",
            "name": "China, People\"s Republic of",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 5,
            "value": "EG",
            "name": "Egypt",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 6,
            "value": "GR",
            "name": "Greece",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 7,
            "value": "IN",
            "name": "India",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 8,
            "value": "IT",
            "name": "Italy",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 9,
            "value": "MM",
            "name": "Myanmar",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 10,
            "value": "PK",
            "name": "Pakistan",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 11,
            "value": "PY",
            "name": "Paraguay",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 12,
            "value": "ES",
            "name": "Spain",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 13,
            "value": "UY",
            "name": "Uruguay",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 14,
            "value": "SR",
            "name": "Suriname",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 15,
            "value": "TH",
            "name": "Thailand",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 16,
            "value": "VT",
            "name": "Vietnam",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 17,
            "value": "US",
            "name": "United States",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 18,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "packing": [
        {
            "id": 1,
            "value": "BULK",
            "name": "Bulk",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "LINER_BAGS",
            "name": "Liner Bags",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 3,
            "value": "BIG_BAGS_1_TON",
            "name": "Big bags of 1 ton",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 4,
            "value": "PP_BAGS_50_KILOS",
            "name": "PP bags 50 Kilos",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 5,
            "value": "PP_BAGS_25_KILOS",
            "name": "PP bags 25 Kilos",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 6,
            "value": "RETAIL_PACKS",
            "name": "Retail Packs",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 7,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "qualityStandard": [
        {
            "id": 1,
            "value": "SINGLE_POLISHED",
            "name": "Single Polished",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "DOUBLE_POLISHED",
            "name": "Double Polished",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 3,
            "value": "SILKY_POLISHED",
            "name": "Silky Polished",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 4,
            "value": "SORTEXED",
            "name": "Sortexed",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 5,
            "value": "other",
            "name": "Other",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "typeOfFertilizer": [
        {
            "id": 1,
            "value": "AMMONIUM_SULPHATE_CAPRO",
            "name": "Ammonium Sulphate - Capro",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "AMMONIUM_SULPHATE_GRANULAR",
            "name": "Ammonium Sulphate - Granular",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 3,
            "value": "AMMONIUM_SULPHATE_OTHER",
            "name": "Ammonium Sulphate - Other",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 4,
            "value": "AMMONIUM_NITRATE",
            "name": "Ammonium Nitrate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 5,
            "value": "CALCIUM_AMMONIUM_NITRATE",
            "name": "Calcium Ammonium Nitrate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 6,
            "value": "CALCIUM_NITRATE_GRANULAR",
            "name": "Calcium Nitrate - Granular",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 7,
            "value": "CALCIUM_NITRATE_CRYSTAL",
            "name": "Calcium Nitrate - Crystal",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 8,
            "value": "DI_AMMONIUM_PHOSPHATE",
            "name": "Di Ammonium Phosphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 9,
            "value": "MONO_AMMONIUM_PHOSPHATE",
            "name": "Mono Ammonium Phosphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 10,
            "value": "MURIATE_OF_POTASH",
            "name": "Muriate of Potash",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 11,
            "value": "MONO_POTASSIUM_PHOSPHATE",
            "name": "Mono Potassium Phosphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 12,
            "value": "MAGNESIUM_SULPHATE",
            "name": "Magnesium Sulphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 13,
            "value": "POTASSIUM_NITRATE",
            "name": "Potassium Nitrate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 14,
            "value": "SULPHATE_OF_POTASH",
            "name": "Sulphate of Potash",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 15,
            "value": "TECHNICAL_MONO_AMMONIUM_PHOSPHATE",
            "name": "Technical Mono Ammonium Phosphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 16,
            "value": "TRIPLE_SUPER_PHOSPHATE",
            "name": "Triple Super Phosphate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 17,
            "value": "UREA_PRILLED",
            "name": "Urea Prilled",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 18,
            "value": "UREA_GRANULAR",
            "name": "Urea Granular",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 19,
            "value": "UREA_AMMONIUN_NITRATE",
            "name": "Urea Ammoniun Nitrate",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 20,
            "value": "x-x-x",
            "name": "x-x-x",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 21,
            "value": "14-34",
            "name": "14-34",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 22,
            "value": "16-44",
            "name": "16-44",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "modeTransportation": [
        {
            "id": 1,
            "value": "CONTAINER",
            "name": "Container",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "CONVENTIONAL_VESSEL",
            "name": "Conventional Vessel",
            "isChecked": false,
            "isDisabled": false
        }
    ],
    "incoterm": [
        {
            "id": 1,
            "value": "FOB",
            "name": "FOB",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "CFR",
            "name": "CFR",
            "isChecked": false,
            "isDisabled": false
        }, {
            "id": 2,
            "value": "CIF",
            "name": "CIF",
            "isChecked": false,
            "isDisabled": false
        }
    ]
})   