import React from 'react';
import PreviewPDF from '../../../../components/previewPDF/PreviewPDF';


const PreviewQuoteModal = ({file, name,rate,referenceId,additionalMessage,eventualAdditionalCondition, onClose}) => {
    const getPreviewWidth = () => {
        const containerWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.95 - 70;
        return containerWidth;
    };
    return (
        <div className="modal__container doc-preview-modal">
            <div className="modal__wrapper px-0 py-0">
            <div className="modal-content">
                    <div className="modal-header">
                 <div className="modal-header-center">
                {/* <span className="modal__close" onClick={onClose}/> */}
                <h5 className='modal__heading'>Preview Quote </h5></div>
                  <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
           <div className="table-responsive mb-4"><table className="table table-bordered table-hover table-striped">
    <thead><tr>
        <th scope="col">Quote Rate in USD</th>
        <th scope="col">Eventual Additional Conditions</th>
        <th scope="col">Reference Id</th>
        <th scope="col">Additional Information </th>
        </tr> 
    </thead>
    <tbody>
        <tr>
        <td> {rate}</td>
        <td> {eventualAdditionalCondition==="" ? "NA":eventualAdditionalCondition}</td>
        <td> {referenceId}</td>
        <td> {additionalMessage}</td>
        </tr>
        </tbody>
    </table></div>
                <div className="d-flex" id="doc-preview-container">
                    {/* {files.map((file, index) => ( */}
                       
                        <div className="modal__preview-file">
                            <div className="doc-preview-modal__version"></div>

                            <PreviewPDF width={getPreviewWidth()} file={file}/>
                        </div>
                </div>
                </div>
        </div>
            </div>
        </div>
    )
};

export default PreviewQuoteModal;
