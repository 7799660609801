import React, { Component } from 'react';
import moment from 'moment';
import DetailTable from '../../requests/detail/DetailTable';
import { DATEFORMAT } from '../../../../services/service.values';
import { GetVesselNomination } from '../../../../modules/module.trade';
import { substituteNominateVessel,getSubstituteNominateVessel,substituteAcceptNewLaycanDates,substituteVesselReject,substituteVesselAccept } from '../../../../modules/module.nomination';
import { AutoupdateNomination } from '../../../../modules/module.trade';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Preloader from '../../../components/preloader/Preloader';
import '../trades.scss';
import {
    nominateVessel,
    rejectNominateVessel,
    acceptNominatVesselWithNewLayacan,
    approveNominatedVessel
} from '../../trades/modals/VesselModal';
import {viewSubstitueVesselInfo} from '../../trades/modals/ViewSubstitueVesselInfo';
import OtherInspectorFormatter from '../../../components/Formatter/OtherInspectorFormatter';

class NominatedVesselInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modal: '',
            modalData: {},
            vesselNomination: '',
            vesselDateFrom: null,
            vesselDateTo: null,
            vesselInspector: '',
            vesselName: '',
            showVesselError:'',
            vesselRejectReason: '',
            isSubstituteStatus:false,
            substituteVesselId:'',
            newVesselDateFrom:null,
            newVesselDateTo:null
        };
    }

    componentDidMount(){
        this.props.GetVesselNomination(this.props.trade.items.single.id);
        this.props.getSubstituteNominateVessel(this.props.trade.items.single.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.triggerNominationUpdate && this.props.triggerNominationUpdate) {
            this.props.AutoupdateNomination();
            this.props.getSubstituteNominateVessel(this.props.trade.items.single.id);
            this.props.GetVesselNomination(this.props.trade.items.single.id);
        }
        if (!prevProps.nomination && this.props.nomination) {
            this.props.getSubstituteNominateVessel(this.props.trade.items.single.id);
            this.props.GetVesselNomination(this.props.trade.items.single.id);
        }
        if(this.props.trade.items.single !== prevProps.trade.items.single){
            this.props.getSubstituteNominateVessel(this.props.trade.items.single.id);
            this.props.GetVesselNomination(this.props.trade.items.single.id);
        }
    }


    substituteNominateVessel=()=>{
        const params = {
            message: this.state.vesselNomination,
            laycanDateFrom: this.state.vesselDateFrom,
            laycanDateTo: this.state.vesselDateTo,
            name: this.state.vesselName
        };
        if (this.state.vesselInspector !== null && this.state.vesselInspector !== '' && this.state.vesselInspector !== undefined) {
            params.inspectionCompanyId = this.state.vesselInspector;
        }

        this.props.substituteNominateVessel(this.props.trade.items.single.id,params,(response)=>{
            if(response.status===200)
            {
                this.props.GetVesselNomination(this.props.trade.items.single.id);
                this.closeModal();
            }
        });
        this.setState({isSubstituteStatus:true});

    }

    postAcceptVesselWithNewLaycan = (inspectionCompanyId) => {
        this.props.substituteAcceptNewLaycanDates(this.props.trade.items.single.id,{ inspectionCompanyId, laycanDateFrom: this.state.newVesselDateFrom, laycanDateTo: this.state.newVesselDateTo,vesselId:this.state.substituteVesselId },response =>{
            if(response.status===200)
            {
                this.props.GetVesselNomination(this.props.trade.items.single.id);
                this.closeModal();
            }
        });
    };

    postRejectVesselStatus = () => {
        var vesselInfo = this.props.nomination.substituteInfo;
        if(vesselInfo.length!==0)
        {
            this.props.substituteVesselReject(this.props.trade.items.single.id,{ rejectMessage: this.state.vesselRejectReason,vesselId:vesselInfo[0].vesselId},response=>{
                if(response.status===200)
                {
                    this.props.GetVesselNomination(this.props.trade.items.single.id);
                    this.closeModal();
                }
            }) ;
        } 
       
    };

    postAcceptVesselStatus = () => {
        var vesselInfo = this.props.nomination.substituteInfo;
        if(vesselInfo.length!==0)
        {
          var inspectionCompanyId=vesselInfo[0].inspectionCompanyId;
          var vesselId=vesselInfo[0].vesselId;
        }
        this.props.substituteVesselAccept(this.props.trade.items.single.id,{inspectionCompanyId:inspectionCompanyId,vesselId:vesselId},response=>{
            if(response.status===200)
            {
                this.props.GetVesselNomination(this.props.trade.items.single.id);
                this.closeModal();
            }
        });
    };

    acceptNewLaycanModel=()=>{
        var vesselInfo = this.props.nomination.substituteInfo;
        if(vesselInfo.length!==0)
        {
           this.setState({
            vesselName:vesselInfo[0].name,
            vesselNomination:vesselInfo[0].message,
            vesselDateFrom:vesselInfo[0].laycanDateFrom,
            vesselDateTo:vesselInfo[0].laycanDateTo,
            vesselInspector:vesselInfo[0].inspectionCompanyId,
            substituteVesselId:vesselInfo[0].vesselId,
            newVesselDateFrom:null,
            newVesselDateTo:null
           })
        } 
     this.openModal('NOMINATE_FOB');
    }
   



    viewVesselInfo = () => {
        var vesselInfo = [...this.props.nomination.substituteInfo];
        var series = [];
        if(vesselInfo.length !== 0){
            if(this.props.trade.items.single.incoterm !=="FOB"){
                series = [
                    { title: "Performing Vessel", data: {...vesselInfo[0],inspectionName:this.props.trade.items.single.inspectionName} },
                    { title: "Previous  Vessel", data: {...vesselInfo[1],inspectionName:this.props.trade.items.single.inspectionName} }
                ] 
            }else{
                if(vesselInfo[0].vesselAccepted){
                    let previousInfos = vesselInfo.slice(1)
                    series=[
                        { title: "Performing Vessel", data: {...vesselInfo[0], inspectionName:this.props.trade.items.single.inspectionName} },
                        { title: "Previous Vessel", data: {...previousInfos.find(info => info.vesselAccepted), inspectionName:this.props.trade.items.single.inspectionName} }
                    ]  
                }else{
                    series = [
                        { title: "Performing Vessel", data:  { ...this.props.trade.vesselNomination, inspectionName: this.props.inspectionName} },
                        { title: "Proposed substitute Vessel", data: {...vesselInfo[0],inspectionName:this.props.trade.items.single.inspectionName} }
                    ]  
                }
            }
        }
        return series;
    }


    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'NOMINATE':
                return nominateVessel({
                    dateFrom: this.state.vesselDateFrom || null,
                    dateTo: this.state.vesselDateTo || null,
                    name: this.state.vesselName || '',
                    inspector: this.props.trade.items.single.inspection,
                    inspectors: { ...this.props.trade.inspections, 1: `${this.props.trade.items.single.inspection === "1" ? this.props.trade.items.single.inspectionName : ""}` },
                    onClose: this.closeModal,
                    isSeller: this.props.whoItIs === 'seller',
                    showError: this.state.showVesselError,
                    updateMessage: e => this.setState({ vesselNomination: e.target.value }),
                    updateDateFrom: date => this.setState({ vesselDateFrom: date }),
                    updateDateTo: date => this.setState({ vesselDateTo: date }),
                    LoadingNominationInfo:this.props.LoadingNominationInfo,
                    title:"Nominate Substitute Vessel",
                    onSelectInspection: (inspectionCompanyId) => {
                        this.setState({ vesselInspector: inspectionCompanyId })
                    },
                    onChangeName: name => this.setState({ vesselName: name }),
                    onSubmit: (e) => {
                        e.preventDefault();
                        const form = e.target;
                        const isValid = form.checkValidity();
                        if (!isValid) {
                            this.setState({ showVesselError: true });
                            form.reportValidity();
                            return;
                        }  
                        this.substituteNominateVessel();
                    }
                });
                case 'VIEW_SUBSTITUE_VESSEL':
                return viewSubstitueVesselInfo({
                    onApproveWithNewLaycan: (event) => {
                        event.preventDefault();
                       // this.postAcceptVesselStatus(this.state.vesselInspector);
                       this.postAcceptVesselStatus();
                       // this.closeModal();
                    },
                    onClose: this.closeModal,
                    whoItIs:this.props.whoItIs,
                    incoterm:this.props.trade.items.single.incoterm,
                    LoadingNominationInfo:this.props.LoadingNominationInfo,
                    isLaycanDateChanged:this.props.nomination.substituteInfo.length !==0 ? this.props.nomination.substituteInfo[0].isLaycanDateChanged:false,
                    vesselAccepted:this.props.nomination.substituteInfo.length !==0 ? this.props.nomination.substituteInfo[0].vesselAccepted:false,
                    onReject: () => {
                        this.setState({
                            modal: 'REJECT_VESSEL_WITH_REASON'
                        });
                        this.renderModal(this.state.modal);
                    },
                    vesselInfo: this.viewVesselInfo(),
                    inspectionName: this.props.trade.items.single.inspectionName,
                    inspector: this.props.trade.items.single.inspection,
                });
                case 'REJECT_VESSEL_WITH_REASON':
                    return rejectNominateVessel({
                        rejectReason: this.state.vesselRejectReason,
                        LoadingNominationInfo:this.props.LoadingNominationInfo,
                        onClose: this.closeModal,
                        onRejectReasonChange: (reajectReason) => {
                            this.setState({
                                vesselRejectReason: reajectReason
                            })
                        },
                        onReject: (e) => {
                            e.preventDefault();
                            const form = e.target;
                            const isValid = form.checkValidity();
                            if (!isValid) {
                                this.setState({ showVesselError: true });
                                form.reportValidity();
                                return;
                            }
                            this.postRejectVesselStatus();
                           // this.closeModal();
                        },
                        onRejectCancel: () => {
                            if (this.props.trade.requestInfo.isLaycanDateChanged) {
                                this.closeModal();
                            } else {
                                this.setState({
                                    modal: 'NOMINATE_ACCEPTING',
                                    showVesselError: false
                                })
                                this.renderModal(this.state.modal);
                            }
                        },
                        showError: this.state.showVesselError
                    });
                    case 'NOMINATE_FOB':
                        return approveNominatedVessel({
                            inspectors: { ...this.props.trade.inspections, 1: `${this.props.trade.items.single.inspection === "1" ? this.props.trade.items.single.inspectionName : ""}` },
                            inspector: this.props.trade.items.single.inspection,
                            name: this.state.vesselName,
                            nomination: this.state.vesselNomination,
                            dateFrom: this.state.vesselDateFrom,
                            dateTo: this.state.vesselDateTo,
                            title:"Substitute Vessel Nominatation",
                            onClose: this.closeModal,
                            showError: this.state.showVesselError,
                            LoadingNominationInfo:this.props.LoadingNominationInfo,
                            onSelectInspection: (inspectionCompanyId) => {
                                this.setState({ vesselInspector: inspectionCompanyId })
                            },
                            onApprove: (e) => {
                                e.preventDefault();
                                const form = e.target;
                                const isValid = form.checkValidity();
                                if (!isValid) {
                                    this.setState({ showVesselError: true });
                                    form.reportValidity();
                                    return;
                                }
                                this.postAcceptVesselStatus();
                               // this.closeModal();
                            },
                            onReject: () => {
                                this.setState({
                                    modal: 'REJECT_VESSEL_WITH_REASON'
                                });
                                this.renderModal(this.state.modal);
                            },
                            onAcceptWithNewLaycan: () => {
                                this.setState({
                                    modal: 'ACCEPT_VESSEL_WITH_NEW_LAYCAN'
                                });
                                this.renderModal(this.state.modal);
                            }
                        });
                        case 'ACCEPT_VESSEL_WITH_NEW_LAYCAN':
                            return acceptNominatVesselWithNewLayacan({
                                dateFrom: this.state.newVesselDateFrom || null,
                                dateTo: this.state.newVesselDateTo || null,
                                updateDateFrom: date => this.setState({ newVesselDateFrom: date }),
                                updateDateTo: date => this.setState({ newVesselDateTo: date }),
                                LoadingNominationInfo:this.props.LoadingNominationInfo,
                                onApproveWithNewLaycan: (e) => {
                                    e.preventDefault();
                                    const form = e.target;
                                    const isValid = form.checkValidity();
                                    if (!isValid) {
                                        this.setState({ showVesselError: true });
                                        form.reportValidity();
                                        return;
                                    }
                                    this.postAcceptVesselWithNewLaycan(this.state.vesselInspector);
                                   // this.closeModal();
                                },
                                onClose: this.closeModal,
                                onCancel: () => {
                                    this.setState({
                                        modal: 'NOMINATE_ACCEPTING',
                                        showVesselError: false
                                    })
                                    this.renderModal(this.state.modal);
                                },
                                showError: this.state.showVesselError
                            });    
                default:
                    break;
            }
        }
     openModal = (name, data) => {
       this.setState({ showModal: true, modal: name, modalData: data });
     };
     closeModal=()=>{
        this.setState({ showModal: false,
                vesselName:'',
                vesselDateFrom:null,
                vesselDateTo:null,
                newVesselDateFrom:null,
                newVesselDateTo:null
        });
     }  
     
     getInspectionCompanyName = (companyName, serie) => {
        if (serie.data.inspectionCompanyId === "1") {
            return <OtherInspectorFormatter inspector={companyName}/>
        }
        return companyName;
     };
     
     renderAction(){
        let infoFOB=this.props.nomination.substituteInfo;
        if(infoFOB.length !==0)
        {
            return  (
                <div>
                {this.props.whoItIs === 'buyer' && 
              <div className="float-right pt-2 pb-2">
                 {(!infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                 {(!infoFOB[0].vesselAccepted && !infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                 {(infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                  {(!infoFOB[0].vesselAccepted && !infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('NOMINATE')}>Nominate Substitute Vessel</button>}
                  {(infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('NOMINATE')}>Nominate Substitute Vessel</button>}
                  
             </div>}
             {this.props.whoItIs === 'seller' &&
              <div className="float-right pt-2 pb-2">
                 {(!infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated && !infoFOB[0].isLaycanDateChanged) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.acceptNewLaycanModel()}>View Substitute Vessel</button>}
                 {(!infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated && infoFOB[0].isLaycanDateChanged) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                 {(!infoFOB[0].vesselAccepted && !infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                 {(infoFOB[0].vesselAccepted && infoFOB[0].vesselNominated ) &&  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}  
             </div>}
             </div> )
        }
         else{
            return  (
            <div>
                {this.props.whoItIs === 'buyer' && 
              <div className="float-right pt-2 pb-2">
                  <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('NOMINATE')}>Nominate Substitute Vessel</button>
             </div>}
            </div> )
        }
     }

    render(){
        let vesselNominationInfo = { ...this.props.trade.vesselNomination, inspectionName: this.props.inspectionName }
        let fieldList = [
            {key: 'name', label: 'Vessel Name'},
            {key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
            {key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
            {key: 'inspectionName', label: "Inspection Company",format:this.getInspectionCompanyName},
            {key: 'message', label: 'Vessel Details'},
        ]
         let title=this.props.trade.vesselNomination.isVesselSubstituted ? `Performing Vessel (Substituted on ${moment(this.props.trade.vesselNomination.vesselSubstituteDate).format(DATEFORMAT)})`: this.props.trade.vesselNomination.vesselAccepted ? 'Performing Vessel':'Nominated Vessel (Pending Approval/Rejected)';
        if(vesselNominationInfo.rejectMessage){
            fieldList.push({key: 'rejectMessage', label: 'Reason to reject vessel'})
        }
        let isFOB = (this.props.trade.items.single.incoterm ==="FOB");
        
        const isAccessble= ( this.props.trade.items.single.status==="VESSEL_NOMINATED" ||this.props.trade.items.single.status==="INSTRUCTION")  ? true:false ;
        return(
            <React.Fragment>
                <Preloader loadingStyle="swirl" loading={this.props.loading}>
                
                    { Object.keys(this.props.trade.vesselNomination).length > 0 ?
                     <div className="trades-dtls__contract">
                       {this.state.showModal && this.renderModal(this.state.modal)}
                     { isAccessble  && <div>
                    { !isFOB &&    
                    <div>
                       {this.props.whoItIs === 'seller' && 
                     <div className="float-right pt-2 pb-2">
                      {this.props.trade.vesselNomination.isVesselSubstituted && <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                      <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('NOMINATE')}>Nominate Substitute Vessel</button>
                    </div>}
                    {this.props.whoItIs === 'buyer'  &&
                     <div className="float-right pt-2 pb-2">
                       {this.props.trade.vesselNomination.isVesselSubstituted && <button className="btn trades-request-btn btn-success" onClick={()=>this.openModal('VIEW_SUBSTITUE_VESSEL')}>View Substitute Vessel</button>}
                    </div>}
                    </div>}
                    { isFOB &&    
                    <div>
                        {this.renderAction()}
                    </div>
                    }
                    </div>}
                      <DetailTable
                        fixWidth={false}
                        series={[{ title:title , data: vesselNominationInfo }]}
                        fieldList={fieldList}>
                    </DetailTable></div> : 
                    <div className="di-form">
                        No Vessel Nominated
                    </div> }
                </Preloader>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        nomination:state.nomination,
        loading: state.loading.loadingVesselDetails,
        LoadingNominationInfo: state.loading.loadingNomination,
        triggerNominationUpdate: state.trade.triggerNominationUpdate,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            GetVesselNomination,
            substituteNominateVessel,
            getSubstituteNominateVessel,
            substituteAcceptNewLaycanDates,
            substituteVesselReject,
            substituteVesselAccept,
            AutoupdateNomination
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NominatedVesselInfo);