import React from 'react';
import { DATEFORMAT,TOOLTIP_TITLE } from '../../../../services/service.values';
import moment from 'moment-timezone';
import FormDateFieldFromTo from '../../../components/form/FormDateFieldFromTo';
import FormInputField from '../../../components/form/FormInputField';
import { faTimes, faCalendarCheck, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailTable from '../../requests/detail/DetailTable';
import Preloader from '../../../components/preloader/Preloader';
import { Tooltip } from '@material-ui/core';
import OtherInspectorFormatter from '../../../components/Formatter/OtherInspectorFormatter';

export const nominateVessel = ({ dateFrom, dateTo, onClose, updateMessage, updateDateFrom, updateDateTo, onSubmit, inspectors, onSelectInspection, inspector, isSeller, name, onChangeName, showError, vesselInspector,title,LoadingNominationInfo }) => (
    <React.Fragment>
        <div className='modal__container vessel_modal'>
            <form
                noValidate={true}
                className='modal__wrapper px-0 py-0'
                onSubmit={onSubmit}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-header-center"> <h5 className="modal-title">{title}</h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                        <div className="col-md-12">
                        <textarea
                            onChange={updateMessage}
                            className='modal__textarea'
                            placeholder={`Please specify vessel nomination instruction`}
                            required
                        />
                        </div>
                        </div>
                        <div className="row">
                            <div className='col-sm-6'>
                                <FormInputField
                                    value={{ label: 'Vessel name', value: name, required: true }}
                                    onChange={e => onChangeName(e.target.value)}
                                    name="name"
                                    type="text"
                                />
                                {isSeller && (
                                    <div className="form-select">
                                        <div className="label">
                                            {inspector !== "1" ?
                                                <span>
                                                    Nominate to <span className="req-field">*</span>
                                                </span>
                                                :
                                                <span>
                                                    <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_NOMINATION_TITLE} placement="top-start" arrow>
                                                        <span>Inspection company to be Nominated by you <span className="req-field">* </span> </span>
                                                    </Tooltip>
                                                </span>}
                                        </div>
                                        <div className="wrapper">
                                            <select className="select"
                                                name='select-inspector'
                                                id='select-inspector'
                                                disabled={!!inspector}
                                                onChange={e => {
                                                    onSelectInspection(e.target.value);
                                                }}
                                                value={!!inspector ? inspector : vesselInspector}
                                                required={true}
                                            >
                                                <option value=''>Select Inspection Company</option>
                                                {Object.keys(inspectors).map(i => {
                                                    return (
                                                        <option key={i} value={i}>
                                                            {inspectors[i]}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-6">
                                <FormDateFieldFromTo
                                    minDate={moment()}
                                    required={true}
                                    nameStart="validateDateFrom"
                                    itemStart={{ value: dateFrom, label: 'Laycan Date From', required: true }}
                                    onSelect={(fieldName, date) => fieldName === 'validateDateFrom' ? updateDateFrom(date) : updateDateTo(date)}
                                    nameEnd="validateDateTo"
                                    itemEnd={{ value: dateTo, label: 'Laycan Date To', required: true }}
                                    popperPlacement="top-end"
                                />
                            </div>
                        </div>
                        {showError && (
                            <div className="trades-dtls__error-message text-center">
                                Please, complete all required fields before submitting
                    </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type='submit' className="btn-popup btn-submit">
                        <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                            Submit
                        </Preloader>
                </button>
               
                    </div>
                </div>
            </form>
        </div>
    </React.Fragment>
);

export const approveNominatedVessel = ({ nomination, name, dateFrom, dateTo, inspector, inspectors, onSelectInspection, onApprove, onReject, onClose, showError, vesselInspector, onAcceptWithNewLaycan,title,LoadingNominationInfo }) => {
    return (
        <React.Fragment>
            <div className='modal__container vessel_modal'>
                <form className='modal__wrapper py-0 px-0' onSubmit={onApprove} noValidate={true}>
                    {/* <span className='modal__close' onClick={onClose} />
                    <h3 className='modal__heading'>Nomination Approval</h3> */}
                    <div className="modal-content">
                    <div className="modal-header">
                <div className="modal-header-center"> <h5 className="modal-title">{title}</h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                        <textarea
                            readOnly={true}
                            style={{ minHeight: '150px' }}
                            className='modal__textarea'
                            value={nomination}
                            required
                            row="3"
                        /></div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div>Vessel Name: <strong>{name}</strong></div>
                                <div>Laycan Date: <strong>From {dateFrom && moment(dateFrom).format(DATEFORMAT)} to {dateTo && moment(dateTo).format(DATEFORMAT)}</strong></div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form-select">
                                    <div className="label">
                                    {inspector !== "1" ?
                                    <span>
                                        Nominate to <span className="req-field">*</span>
                                    </span>
                                    :
                                    <span>
                                        <Tooltip title={TOOLTIP_TITLE.NON_INSPECTOR_NOMINATION_TITLE} placement="top-start" arrow>
                                            <span>Inspection company to be Nominated by you <span className="req-field">* </span></span>
                                        </Tooltip>
                                    </span>}
                                </div>
                                    <div className="wrapper">
                                        <select className="select"
                                            name='select-inspector'
                                            id='select-inspector'
                                            disabled={!!inspector}
                                            onChange={e => {
                                                onSelectInspection(e.target.value);
                                            }}
                                            value={!!inspector ? inspector : vesselInspector}
                                            required={inspector ? null : true}
                                        >
                                            <option value=''>Select Inspection Company</option>
                                            {Object.keys(inspectors).map(i => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {inspectors[i]}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showError && (
                            <div className="trades-dtls__error-message text-center">
                                Please, complete all required fields before submitting
                        </div>
                        )}
                        <div className='row mb-3'>
                            <div className="col-sm-12">
                                <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
                                    <li>- Click <strong> ACCEPT </strong> to accept the nomination</li>
                                    <li>- Click <strong>ACCEPT WITH NEW LAYCAN</strong> to accept the nomination with new proposed laycan</li>
                                    <li>- Click <strong> REJECT </strong> and enter reason for rejection</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <button type="button" className="btn-popup btn-reject-vessel" onClick={onReject}>
                                <FontAwesomeIcon icon={faTimes} /> REJECT
                        </button>
                            <button type="button" className="btn-popup btn-laycan-vessel" onClick={onAcceptWithNewLaycan}>
                                <FontAwesomeIcon icon={faCalendarCheck} /> ACCEPT WITH NEW LAYCAN
                        </button>
                            <button type="submit" className="btn-popup btn-submit-vessel mt-xxs-1">
                            <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                                <FontAwesomeIcon icon={faCheck} /> ACCEPT
                             </Preloader>
                        </button>
                        </div>
                       </div>
                </form>
            </div>
        </React.Fragment>

            )
        };
        
export const rejectNominateVessel = ({rejectReason, onClose, onRejectReasonChange, onReject, onRejectCancel, showError,LoadingNominationInfo }) => {
    return (
        <React.Fragment>
                <div className='modal__container vessel_modal'>
                    <form className='modal__wrapper px-0 py-0 authcode' onSubmit={onReject} noValidate={true}>
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-header-center"> <h5 className="modal-title">Nomination Approval</h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        {/* <span className='modal__close' onClick={onClose} />
                        <h3 className='modal__heading'>Nomination Approval</h3> */}

                        <div className="modal-body">
                            <div className="row">
                            <div className="col-md-12">
                        <label className='control-label'>Reason for Rejection <span className='req-field'>*</span></label>
                        <textarea
                            className='modal__textarea'
                            value={rejectReason}
                            onChange={event => onRejectReasonChange(event.target.value)}
                            placeholder={`Please specify reason for vessel nomination rejection`}
                            required
                            style={{ minHeight: '150px' }}
                        />
                        </div>  </div>
                        {showError && (
                            <div className="trades-dtls__error-alert text-center">
                                Please, enter reason for rejecting before submitting
                        </div>
                        )}
                        </div>
                        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <button type="button" className="btn-popup btn-reject-vessel" onClick={onRejectCancel}>
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                            <button type="submit" className="btn-popup btn-submit-vessel">
                            <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                                <FontAwesomeIcon icon={faCheck} /> Submit
                                </Preloader>
                        </button>
                        </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
            )
        };
        
export const acceptNominatVesselWithNewLayacan = ({onApproveWithNewLaycan, onClose, onCancel, updateDateFrom, updateDateTo, dateFrom, dateTo, showError,LoadingNominationInfo}) => {
    return (
        <React.Fragment>
                <div className='modal__container vessel_modal accpet_with_new_laycan'>
                    <form className='modal__wrapper px-0 py-0 laycan-calendar modal__wrapper_show-Calender' onSubmit={onApproveWithNewLaycan} noValidate={true}>
                        {/* <span className='modal__close' onClick={onClose} />
                        <h4 className='modal__heading mt-4 mb-3'>Nomination Approval</h4> */}
                        <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-header-center"> <h5 className="modal-title">Nomination Approval</h5></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                       
                       
                       <div className='modal-body py-0' >
                        {/* <div className=' mt-5 mb-5'>
                            <div className='laycan-text'> */}
                                <p>
                                Please note that you can propose a new laycan in case the one tendered to you by the counterparty is deemd to be outside the contractual laycan period or the required pre-advice period was not respected.
                                Rejecting a  valid nomination can lead to a contractual dispute. You can cancel this action and return to the previous page if you like to discard this action.
                                </p>
                                                           
                        {/* </div>
                        </div> */}
                        <div className='row mt-3'>
                            <div className='col-md-12 px-5'>
                                <FormDateFieldFromTo
                                    minDate={moment()}
                                    required={true}
                                    nameStart="startDate"
                                    itemStart={{ value: dateFrom, label: 'Start Date', required: true }}
                                    onSelect={(name, date) => name === 'startDate' ? updateDateFrom(date) : updateDateTo(date)}
                                    nameEnd="endDate"
                                    itemEnd={{ value: dateTo, label: 'End Date', required: true }}
                                    popperPlacement="top-end"
                                />
                            </div>
                        </div>
                       
                        </div>
                        {showError && (
                            <div className="trades-dtls__error-message text-center">
                                Please, complete all required fields before submitting
                        </div>
                        )}
                        <div className='modal-footer'>
                            <button type="button" className="btn-popup btn-cancel" onClick={onCancel}>
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>

                          
                            <button type="submit" className="btn-popup btn-submit-vessel">
                                <Preloader loadingStyle="dots" loading={LoadingNominationInfo}>
                                <FontAwesomeIcon icon={faCheck} /> Submit
                                </Preloader>
                        </button>
                        </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
            )
        };
        
export const approveVesselByBuyer = ({onApproveWithNewLaycan, onClose, onReject, vesselInfo, inspectionName,inspector }) => {
                let vesselNominationInfo = {...vesselInfo, inspectionName: inspectionName,inspectionCompanyId:inspector  }
            let fieldList = [
        {key: 'name', label: 'Vessel Name'},
        {key: 'laycanDateFrom', label: 'Laycan Date From', format: (value) => moment(value).format(DATEFORMAT)},
        {key: 'laycanDateTo', label: 'Laycan Date To', format: (value) => moment(value).format(DATEFORMAT)},
        {key: 'inspectionName', label: "Inspection Company",format:getInspectionCompanyName},
        {key: 'message', label: 'Vessel Details'},
        ]
        return (
        <React.Fragment>
                <div className='modal__container vessel_modal accpet_with_new_laycan'>
                    <form className='modal__wrapper px-0 py-0 authcode laycan-calendar' onSubmit={onApproveWithNewLaycan} noValidate={true}>
                    <div className="modal-content">
                    <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Nomination Approval</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                               
                        {/* <span className='modal__close' onClick={onClose} />
                        <h4 className='modal__heading mt-4 mb-3'>Nomination Approval</h4> */}
                        
                        <div className='modal-body'>
                            <p>
                            Please note that laycan date has been changed by seller. Please approve the vessel.
                            </p>
                   
                        <div className='pt-1'>
                            <DetailTable
                                fixWidth={false}
                                series={[{ title: 'Field Value', data: vesselNominationInfo }]}
                                fieldList={fieldList}>
                            </DetailTable>
                        </div>
                        </div>
                        </div>
                        <div className='modal-footer' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <button type="button" className="btn-popup btn-reject-vessel" onClick={onClose}>
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                            <button type="button" className="btn-popup btn-reject-vessel" onClick={onReject}>
                                <FontAwesomeIcon icon={faTimes} /> Reject
                        </button>
                            <button type="submit" className="btn-popup btn-submit-vessel">
                                <FontAwesomeIcon icon={faCheck} /> Accept
                        </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
            )
};

const  getInspectionCompanyName = (companyName, serie) => {
    if (serie.data.inspectionCompanyId === "1") {
        return <OtherInspectorFormatter inspector={serie.data.inspectionName}/>
    }
    return companyName;
}