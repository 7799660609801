import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getProviderKycList, rejectProviderKyc, approveProviderKyc } from '../../modules/module.adminManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faInfo, faUser } from '@fortawesome/free-solid-svg-icons';

import './adminManagement-style.scss';
import Preloader from '../components/preloader/Preloader';
import '../admin/requests/create/create-trade-request.scss'
import '../components/form/FormInputField.scss';
import FormTextareaField from '../components/form/FormTextareaField';
import AuthorizedUsersList from './AuthorizedUsersList';

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Tooltip from '@material-ui/core/Tooltip';

class ProviderKycList extends Component {

    state = {
        showModal: false,
        modalData: {
            companyID: ""
        },
        saveData: {
            reason: "",
        },
        errors: {}
    };

    componentDidMount = () => {
        this.fetchFunction();
        window.scrollTo(0, 0);
    };

    openModal = (name, data) => {
        this.setState({ showModal: true, modal: name, modalData: data });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            input: '',
            modalData: {},
            errors: {}
        });
    };

    fetchFunction = () => {
        this.props.getProviderKycList(this.props.company.companyId);
        this.setState({});
    };

    getItems = () => {
        let providersKycs = this.props.usermanagment.providerKycList;
        return providersKycs;
    };

    renderModal = (whatModal) => {
        switch (whatModal) {
            case 'AUTHORIZED_USERS':
                return (
                    <div className="modal__container">
                        <form className="modal__wrapper px-0 py-0">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Authorized Users Of {this.state.modalData.companyName}</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <AuthorizedUsersList companyID={this.state.modalData.companyID} />
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel ">
                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            case 'REJECT_KYC':
                return (
                    <div className="modal__container"
                        onSubmit={e => {
                            e.preventDefault();
                            if (this.validate()) {
                                this.closeModal();
                                this.props.rejectProviderKyc(this.state.modalData.companyID, { providerCompanyID: this.props.company.companyId, reason: this.state.saveData.reason });
                            }
                        }}>
                        <form className="modal__wrapper px-0 py-0 authcode">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-header-center"> <h5 className="modal-title">Reject KYC</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                <div className="create-req__wrapper">
                                        <div className="form-input">
                                            <label className="control-label">Enter Rejection Reason<span className="reqfield">*</span></label>
                                            <FormTextareaField
                                                validation={this.state.errors}
                                                name="reason"
                                                rows="5"
                                                value={this.state.saveData}
                                                onChange={this.saveReason}
                                            />
                                        </div>
                                        {/* <div className="col-md-7">
                                           
                                        </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                    </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        {/* <img src={image} width="17px" /> */}
                                        <FontAwesomeIcon  icon={faCheck} /> Reject
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                );
            case 'APPROVE_KYC':
                return (
                    <div className="modal__container">
                        <form
                            className="modal__wrapper px-0 py-0 authcode"
                            onSubmit={e => {
                                e.preventDefault();
                                this.closeModal();
                                this.props.approveProviderKyc(this.state.modalData.companyID, { providerCompanyID: this.props.company.companyId });
                            }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                <div className="modal-header-center"><h5 className="modal-title">Approve KYC</h5></div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.modalData.kycStatus === "REJECTED" ? (
                                        <p className="center-text">
                                            You are about to set the status of <b>{this.state.modalData.companyName}</b> from <b>REJECTED TO APPROVED.</b>
                                        <br />
                                            Press back to return to previous screen or press confirm to continue.
                                        </p>
                                    ) :
                                        (
                                            <p className="center-text">
                                                You are about to set the status of <b>{this.state.modalData.companyName}</b> to <b>APPROVED</b>.
                                            <br />
                                                Press back to return to previous screen or press confirm to continue.
                                            </p>
                                        )}
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn-popup btn-cancel">
                                        <FontAwesomeIcon icon={faTimes} /> Back
                                            </button>
                                    <button type="submit" className="btn-popup btn-submit">
                                        {/* <img src={image} width="17px" /> */}
                                        
                                        <FontAwesomeIcon icon={faCheck} /> Confirm
                          </button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            default:
                break;
        }
    };

    saveReason = e => {
        this.setState({ saveData: { reason: e.target.value } });
    };

    validate = () => {
        if (this.state.saveData.reason === "") {
            this.setState({ errors: { reason: this.state.saveData.reason } });
            return false;
        }
        this.setState({ errors: {} });
        return true;
    }

    render() {
        return (
            <React.Fragment>

                <section className="rbac-body">
                    {this.state.showModal && this.renderModal(this.state.modal)}
                    <Preloader loadingStyle="overflow-spinner" loading={this.props.loading}>
                        <MaterialTable
                            columns={[
                                { title: 'Company Name', field: 'companyName', },
                                { title: 'Registeration Number', field: 'regNumber' },
                                { title: 'TAX/VAT Number', field: 'tax' },
                                { title: 'Country', field: 'country' },
                                { title: 'Kyc Status', field: 'kycStatus' },
                                { title: 'Rejection Reason', field: 'rejectionReason' },
                                {
                                    title: 'Action',
                                    sorting: false,
                                    render: rowData => <div className="action-btn">
                                        <Tooltip title="KYC Info" placement="top-start" arrow >
                                            <Link className="btn-icon btn-circle-icon btn-warning" to={`/adminManagement/company/${rowData.companyID}/kyc-info`}>
                                                <FontAwesomeIcon icon={faInfo} />
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title="Authorized Users" placement="top-start" arrow >
                                            <span className="btn-icon btn-circle-icon btn-info btn-icon-res" onClick={() => this.openModal('AUTHORIZED_USERS', rowData)}>
                                                <FontAwesomeIcon icon={faUser} /></span>
                                        </Tooltip>
                                        {rowData.kycStatus === "PENDING" || rowData.kycStatus === "APPROVED" ?
                                            (
                                                <React.Fragment>
                                                    <Tooltip title="Reject KYC" placement="top-start" arrow >
                                                        <span className="btn-icon btn-circle-icon btn-danger btn-icon-res" onClick={() => this.openModal('REJECT_KYC', rowData)}>
                                                            <FontAwesomeIcon icon={faTimes} /></span>
                                                    </Tooltip>
                                                </React.Fragment>)
                                            : ''}
                                        {rowData.kycStatus === "PENDING" || rowData.kycStatus === "REJECTED" ?
                                            (
                                                <React.Fragment>
                                                    <Tooltip title="Approve KYC" placement="top-start" arrow >
                                                        <span className="btn-icon btn-circle-icon btn-accept btn-icon-res" onClick={() => this.openModal('APPROVE_KYC', rowData)}><FontAwesomeIcon icon={faCheck} /></span>
                                                    </Tooltip>
                                                </React.Fragment>)
                                            : ''}
                                    </div>
                                }
                            ]}
                            data={this.getItems()}
                            title="KYC Company List"
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                SortArrow: ArrowUpward,
                                ResetSearch: Clear,
                            }}
                            options={{
                                pageSize: 10,
                                rowStyle: (rowData, index) => {
                                    const backgroundColor = (index%2===0)?'#00ff301c':'#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor}
                                },
                                thirdSortClick: false
                            }}
                        />
                    </Preloader>
                </section>

            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        usermanagment: state.usermanagment,
        loading: state.loading.groupLoading,
        company: state.account.token
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            approveProviderKyc,
            getProviderKycList,
            rejectProviderKyc
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProviderKycList);
