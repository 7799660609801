import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment-timezone';

import { preloadInspectionCompanies,LoadTradeBids } from '../../../../modules/module.trade';
import {LoadContractTerms} from '../../../../modules/module.contract';
import Preloader from '../../../components/preloader/Preloader';
import DetailedInfos from './DetailedInfos';


class ContractInfoWrapper extends React.Component{
    componentDidMount(){
		this.props.LoadTradeBids(this.props.trade.items.single.id);
        this.props.LoadContractTerms(this.props.trade.items.single.id);
        this.props.preloadInspectionCompanies(); 
    }
    
    getSeries(){
        let series = [];
		const dataset = [];
		const mLogDate = moment.utc(this.props.logDate)


		//Initilize original trade data with a copy of the trade
		let originalTrade = {...this.props.trade.items.single};
		//Init contract terms array with a copy
		let contractTerms = [...this.props.contract.terms];

		//Order array by created date descending
		contractTerms = contractTerms.sort((a,b) => moment.utc(b.createdAt).isBefore(moment.utc(a.createdAt))?-1:1);

		//Get the original trade data. (The original trade data is the first trade copy with status 'ORIG_CONTRACT_DATA')
		if(contractTerms.length !==0) {
			for(let i = contractTerms.length-1; i>=0; i--) {
				let currentTerm = contractTerms[i]
				if(currentTerm.status === 'ORIG_CONTRACT_DATA') {
					originalTrade = {...currentTerm};
					break;
				}
			}
		} 

		// Select the terms registered before the log date. (that are not created by the system)
		contractTerms = contractTerms.filter(item => (
			moment.utc(item.createdAt).isBefore(mLogDate)
			&& item.status !== 'ORIG_CONTRACT_DATA'
		));	

		if(contractTerms.length !== 0) {
			// Add the first contract term of the selected timeline to the dataset
			const firstContractTerm = contractTerms.shift()
			dataset.push(firstContractTerm)

			const previousDeal = contractTerms.find(item => (item.status === 'ACCEPTED'));
			const start = previousDeal? {...previousDeal} : {...originalTrade};
      
			for(let term of contractTerms) {
				// Break loop condition : Find the end of a previous amendment negotiation
				if(term.status === 'ACCEPTED' || term.declinedByCompanyId !== '') { 
					break;
				}
				dataset.push(term)
			}
            start.status = 'ORIG_CONTRACT_DATA';
			dataset.push(start)
		}

        
        series = dataset.reverse().map((term,index) => ({ title: index, data: term}));
        return series;
    }

    render() {
        const {trade, contractLoading,contract } = this.props;

        var series = this.getSeries(trade, contract);
  
        return (<Preloader loadingStyle="swirl" loading={contractLoading}>
                { series.length !== 0 && 
                <DetailedInfos series={series} {...this.props}/>}
            </Preloader>)

    }

}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        contract:state.contract,
        contractLoading: state.loading.contractLoading,
        user: state.account.user
    };
};

const mapDispatchToProps = dispatch => 
    bindActionCreators({
	     	 LoadTradeBids,
             LoadContractTerms,
             preloadInspectionCompanies
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ContractInfoWrapper);