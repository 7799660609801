import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import moment from 'moment';
import {
    DATEFORMAT,
    DATEFORMATHOURS,
    INCOTERMOPT,
    SHIPPINGTYPES
} from '../../../services/service.values';
import { FertilizerService } from '../../../services/service.fertilizer';
import { FetchNewTrade, FetchTrade, UpdateRequest } from '../../../modules/module.trade';
import { getCompanySubscriptions } from '../../../modules/module.tender';

//COMPONENTS
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import CustomMaterilaPreloader from '../../components/preloader/CustomMaterialPreloader';
import Tab from './components/Tab';

import './requests.scss';
import { EnumsService, renderSelectedOrigins } from '../../../services/service.utils'
import { viewTradeRequestPermission } from '../../../services/service.permission'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHistory, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import FilterList from '@material-ui/icons/FilterList';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import MdMoreVert from "react-icons/lib/md/more-vert";
import NumberFormat from 'react-number-format';
import TradeQuantityFormatter from '../../components/Formatter/TradeQuantityFormatter';
const Countries = EnumsService.countries();

const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);


//Render functions
const sortRef = (a, b) => {
    let refA = a.tradeRefId || a.id;
    let refB = b.tradeRefId || b.id;
    return refA.localeCompare(refB);
};
const sortQuantity = (a, b) => (parseInt(a.quantity) - parseInt(b.quantity));
const renderOverflow = (content, className) => <div className={`trades__overflow ${className}`}>{content ? content : '-'}</div>;
const renderOrigin = rowData => rowData && rowData.origin ? renderSelectedOrigins(rowData.origin) : renderOverflow("");
const renderRequestType = rowData => rowData.requestType === "BUY" ? 'Bid' : 'Offer';
const renderStatus = (status) => (<React.Fragment>
    <div className={`requests__row_${status}`}>
        <span className="status">{status}</span>
    </div>
</React.Fragment>);
const renderAction = (rowData) => (<React.Fragment>
    <span className="requests__more-Action"><MdMoreVert className="more-icon" /></span>
</React.Fragment>);

const renderPrice = rowData => {
    if(rowData.bestPrice) return 'Best Price';
    return(
        <NumberFormat 
            value={rowData.price} 
            displayType={'text'} 
            thousandSeparator={true} 
            prefix={rowData.currency === 'USD' || rowData.currency === 'US$' ? 'USD ' : 'EUR '} 
            renderText={value => <span className="exchange__overflow">{value}</span>} 
        />
    )
}

const columns = {
    REF: { title: 'Trade Ref Id', field: 'tradeRefId ', type: 'string', customSort: sortRef, render: rowData => rowData.tradeRefId || rowData.id },
    REQUEST_TYPE: { title: 'Type', field: 'type', render: renderRequestType },
    UPDATED_AT: { title: 'Updated At ', field: 'updatedAt', type: 'date', hidden: true, defaultSort: 'desc' },
    CREATED_AT: { title: 'Creation Date ', field: 'createdAt', render: rowData => renderOverflow(moment(rowData.createdAt).format(DATEFORMAT)) },
    SHIPPING_TYPE: { title: 'Vessel/ container', field: 'shipping', render: rowValue => SHIPPINGTYPES[rowValue.shipping] },
    QUANTITY: { title: 'Quantity (MT)', filed: 'measure', customSort: sortQuantity, render: rowData => <TradeQuantityFormatter trade={rowData} /> },
    PRICE: { title: 'Price', field: 'price', type: 'numeric', render: rowData => renderPrice(rowData) },
    ORIGIN: { title: 'Origin', field: 'origin', render: renderOrigin },
    INCOTERM: { title: 'Incoterm', field: 'incoterm' },
    PORT: {
        title: 'Port of load/ Destination', field: 'destPort', render: rowData => renderOverflow((rowData.incoterm === INCOTERMOPT.FOB) ?
            `${rowData.loadPort}, ${Countries[rowData.loadCountry]}` : `${rowData.destPort}, ${Countries[rowData.destCountry]}`)
    },
    FERTILIZER_TYPE: { title: 'Fertilizer Type', field: 'fertilizerType', render: rowData => renderOverflow(FERTILIZER_TYPE[rowData.fertilizerType] || rowData.fertilizerType) },
    SHIPPING_FROM: { title: 'Shipping/ Delivery Period Start', field: 'deliveryStartDate', render: rowData => renderOverflow(moment(rowData.deliveryStartDate).format(DATEFORMAT)) },
    SHIPPING_TO: { title: 'Shipping/ Delivery Period End', field: 'deliveryEndDate', render: rowData => renderOverflow(moment(rowData.deliveryEndDate).format(DATEFORMAT)) },
    STATUS: { title: 'Request Status', field: 'status', render: rowData => renderStatus(rowData.requestStatus.toLowerCase()) },
    ACTION: { title: '', field: '', render: renderAction, sorting: false },
}

const tenderColumns = {
    REF: { title: 'Tender Ref Id', field: 'tradeRefID', type: 'string', customSort: sortRef },
    OWNER: { title: 'Company Name', field: 'ownerCompanyName' },
    CREATED_ON: { title: 'Tender Created On', field: 'tenderCreationDate', type: 'date', hidden: true, defaultSort: 'desc' },
    SUBSCRIBED_ON: { title: 'Subscribed On', field: 'subscribedAt', render: rowData => renderOverflow(moment(rowData.tenderCreationDate).format(DATEFORMATHOURS)) },
    OPENING: { title: 'Tender Opening On', field: 'tenderStartDate', render: rowData => renderOverflow(moment(rowData.tenderStartDate).format(DATEFORMATHOURS)) },
    CLOSING: { title: 'Tender Closing On', field: 'tenderEndDate', render: rowData => renderOverflow(moment(rowData.tenderEndDate).format(DATEFORMATHOURS)) },
    STATUS: { title: 'Subscription Status', field: 'status', render: rowData => renderStatus(rowData.status.toLowerCase()) }
}

class Requests extends Component {

    columnWithContext = {
        BUYER: { title: 'Buyer', field: 'buyer', render: rowData => renderOverflow(rowData.buyer, rowData.ownerId === rowData.buyerId ? "highlight" : "") },
        SELLER: { title: 'Seller', field: 'seller', render: rowData => renderOverflow(rowData.privacyType === "MULTI_PRIVATE" ? (this.props.user.companyId === rowData.ownerId ? "MULTIPLE" : this.props.user.companyName) : rowData.seller, rowData.ownerId === rowData.sellerId ? "highlight" : "") }
    }

    defaultColumnList = [
        columns.REF,
        columns.UPDATED_AT,
        columns.REQUEST_TYPE,
        this.columnWithContext.BUYER,
        this.columnWithContext.SELLER,
        columns.SHIPPING_TYPE,
        columns.QUANTITY,
        columns.PRICE,
        columns.ORIGIN,
        columns.FERTILIZER_TYPE,
        columns.SHIPPING_FROM,
        columns.SHIPPING_TO,
        columns.CREATED_AT,
        columns.STATUS,
        columns.ACTION,
    ];

    subscriptionColumnList = [
        tenderColumns.REF,
        tenderColumns.OWNER,
        tenderColumns.SUBSCRIBED_ON,
        tenderColumns.OPENING,
        tenderColumns.CLOSING,
        tenderColumns.STATUS
    ];

    constructor(props) {
        super(props);
        this.tabs = [{
            id: 1,
            title: 'My Requests',
            columns: this.defaultColumnList,
            request: 'OPEN',
            loading: () => this.props.trade.paginatedItems["OPEN"].pending,
            getData: () => this.props.trade.tradeMap,
            filter: trade => (['NEW', 'DEAL'].includes(trade.requestStatus) && (trade.sellerId === this.props.user.companyId || trade.buyerId === this.props.user.companyId || (trade.privacyType === "PUBLIC" && trade.counterParties.includes(this.props.user.companyId)) || (trade.privacyType === "MULTI_PRIVATE" && trade.multiPrivateParties.filter(item => item.id === this.props.user.companyId).length))),
            active: true
        },
        {
            id: 7,
            title: 'My Tender Subscriptions',
            columns: this.subscriptionColumnList,
            request: 'SUBSCRIPTION',
            loading: () => this.props.tender.getCompanySubscriptionsPending,
            getData: () => this.props.tender.companySubscriptions,
            filter: () => true,
        },
        {
            id: 2,
            title: 'Inbound',
            columns: this.defaultColumnList,
            request: 'OPEN',
            loading: () => this.props.trade.paginatedItems["OPEN"].pending,
            getData: () => this.props.trade.tradeMap,
            filter: trade => (['NEW', 'DEAL'].includes(trade.requestStatus)
                && trade.ownerId !== this.props.user.companyId),
                //&& (trade.sellerId === this.props.user.companyId || trade.buyerId === this.props.user.companyId)),
        },
        {
            id: 3,
            title: 'Outbound',
            columns: this.defaultColumnList,
            request: 'OPEN',
            loading: () => this.props.trade.paginatedItems["OPEN"].pending,
            getData: () => this.props.trade.tradeMap,
            filter: trade => (['NEW', 'DEAL'].includes(trade.requestStatus) && trade.ownerId === this.props.user.companyId),
        },
        {
            id: 4,
            title: 'Expired',
            columns: this.defaultColumnList,
            request: 'EXPIRED',
            loading: () => this.props.trade.paginatedItems["EXPIRED"].pending,
            getData: () => this.props.trade.tradeMap,
            filter: trade => trade.requestStatus === 'EXPIRED',
        },
        {
            id: 5,
            title: 'Cancelled',
            columns: this.defaultColumnList,
            request: 'CANCELED',
            loading: () => this.props.trade.paginatedItems["CANCELED"].pending,
            getData: () => this.props.trade.tradeMap,
            filter: trade => trade.requestStatus === 'CANCELED',
        },
        {
            id: 6,
            title: 'Declined',
            columns: this.defaultColumnList,
            request: 'DECLINED',
            loading: () => this.props.trade.paginatedItems["DECLINED"].pending,
            getData: () => this.props.trade.declinedTradeMap,
            filter: trade => true,
        }];

        this.state = {
            activeTab: this.tabs[0],
            showModal: true,
            modal: '',
            modalData: {},
        };

    }

    componentDidMount = () => {
        if (this.props.trade.lastUpdate) {
            this.props.FetchNewTrade();
        }
    }

    setActiveTab = tab => {
        switch (tab.request) {
            case 'DECLINED':
            case 'CANCELED':
            case 'EXPIRED':
                if (!this.props.trade.paginatedItems[tab.request].bookMark) {
                    this.props.FetchTrade(tab.request);
                } else {
                    this.props.FetchNewTrade(tab.request);
                }
                break;
            case 'SUBSCRIPTION':
                this.props.getCompanySubscriptions();
                break;

            default:
                break;
        }
        this.setState(prevState => {
            prevState.activeTab.active = false;
            tab.active = true;
            return { activeTab: tab }
        });
    };

    handleOnRefresh = e => {
        if (this.state.activeTab.request === "SUBSCRIPTION") {
            this.props.getCompanySubscriptions();
        } else {
            this.props.FetchNewTrade(this.state.activeTab.request);
        }

    }

    handleOnLoadMore = e => {
        if (this.state.activeTab.request === "SUBSCRIPTION") {
            this.props.getCompanySubscriptions();
        } else {
            this.props.FetchTrade(this.state.activeTab.request);
        }

    }

    handleRowClicked = (e, rowData) => {
        let tradeId;
        if (this.state.activeTab.request === 'SUBSCRIPTION') {
            tradeId = rowData.tradeID;
            this.props.navigate(`/requests/details/${tradeId}`);
        } else {
            tradeId = rowData.id;
            let path = (rowData.requestStatus === 'DEAL') ? 'trades' : 'requests';
            //this.props.UpdateRequest(rowData);
            this.props.navigate(`/${path}/details/${tradeId}`);
        }
    };

    //TODO : To externalize in a separate component to handle modals
    renderModal = () => {
        return (<div className="modal__container">
            <form className="modal__wrapper px-0 py-0 authcode"
                onSubmit={e => {
                    e.preventDefault();
                    this.closeModal();
                }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <Link to="/" className="close" data-dismiss="modal" onClick={this.closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </Link>
                    </div>
                    <div className="modal-body">
                        <p className="center-text"><h6>You are not authorized to perform this action.</h6></p>
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn-popup btn-cancel "><FontAwesomeIcon icon={faTimes} /> Cancel</Link>
                    </div>
                </div>
            </form>
        </div>);
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modal: '',
            modalData: {}
        });
    };

    render() {
        const { trade } = this.props;
        if (viewTradeRequestPermission() === 0) {
            return (<div>{this.state.showModal && this.renderModal()}</div>);
        } else {
            return (<div>
                <Header />
                <section className="requests">
                    <div className="row">
                        <div className="col"><h2 className="trades__heading">My Open Requests</h2></div>
                        <div className="col text-right">
                            <Tooltip title="Refresh"><IconButton onClick={this.handleOnRefresh}><FontAwesomeIcon icon={faSyncAlt} /></IconButton></Tooltip>
                            <Tooltip title="Load more"><IconButton onClick={this.handleOnLoadMore}><FontAwesomeIcon icon={faHistory} /></IconButton></Tooltip>
                        </div>
                    </div>

                    <div className="requests__tabs">
                        {this.tabs.map((tab, index) =>
                            <Tab key={index}
                                content={tab.title}
                                isActive={tab.active}
                                onActiveTab={() => this.setActiveTab(tab)} />
                        )}
                    </div>
                    <div className="requests__table">
                        <MaterialTable
                            key={this.state.activeTab.id}
                            columns={this.state.activeTab.columns}
                            isLoading={this.state.activeTab.loading() || trade.pendingNew}
                            data={_.chain(this.state.activeTab.getData())
                                .values()
                                .filter(this.state.activeTab.filter)
                                .value()}
                            onRowClick={this.handleRowClicked}
                            icons={{
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                SortArrow: ArrowUpward,
                            }}
                            components={{
                                OverlayLoading: CustomMaterilaPreloader,
                                Toolbar: () => false
                            }}
                            options={{
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 20, 30],
                                rowStyle: (rowData, index) => {
                                    let backgroundColor = (index % 2 === 0) ? '#00ff301c' : '#FBFBFB';
                                    return { borderColor: '#c3e6cb', backgroundColor: backgroundColor }
                                },
                                sorting: true,
                                thirdSortClick: false
                            }} />
                    </div>
                </section>
                <Footer />
            </div>);
        }
    }
}

const mapStateToProps = state => {
    return {
        tender: state.tender,
        trade: state.trade,
        user: state.account.user
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            navigate: path => push(path),
            getCompanySubscriptions,
            FetchNewTrade,
            FetchTrade,
            UpdateRequest
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Requests);