import React from 'react';
import NumberFormat from 'react-number-format';

const defaultStyle = {
    labelClassName :"label",
    wrapperClassName : "form-date",
    inputClassName : "input",
    isValidClassName: "",
    isInvalidClassName: "input_error"
};  

const FormNumberGroupField = ({
        name,
        prepValue,
        apValue,
        value,
        validation,
        onChange,
        disabled,
        captionText,
        customStyle = {
            wrapperClassName : "form-input",
            labelClassName :"label",
            inputClassName : "input-field",
            inputGroupTable : "input-group-table",
            isInvalidClassName: "input_error",
            isValidClassName: ''
    
        },
        ...rest
    }) => {
    
        const style = { ...defaultStyle, ...customStyle};
        const isInvalid = validation && validation.hasOwnProperty(name);
        const inputValue = value.value;
        return (
            <div className={style.wrapperClassName}>
                {value.label &&
                <label htmlFor={name} className={style.labelClassName}>
                    {value.label}
                    {value.required && <span className="req-field">*</span>}
                </label>}
                <div className={`input-group ${customStyle.inputGroupTable}`}>
                    {prepValue && 
                        <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">{prepValue}</span>
                        </div>}
                    
                    <NumberFormat value={inputValue} 
                        displayType={'input'} 
                        className={`${style.inputClassName} ${isInvalid ? style.isInvalidClassName :  value.touched || value.validated ? style.isValidClassName : ''}`}
                        id={name}
                        name={name}
                        onValueChange={values => onChange(values.value)}
                        required={!!value.required}
                        disabled={disabled}
                        {...rest}/>
                    {apValue && 
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon1">{apValue}</span>
                        </div>}
                </div>
                { captionText && <small id={`${name}Help`} className="form-text text-muted">{captionText}</small>}
            </div>)
        };

export default FormNumberGroupField;
