import React, {useMemo} from "react";
import { DATEFORMATHOURS } from "../../../services/service.values";
import moment from 'moment';
import { DOCUMENT_NAMES } from '../../admin/trades/services/documents.service';
import MdDelete from 'react-icons/lib/md/delete';


const Notifications = {
    NEW_OPEN_TRADE_REQUEST_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has created open trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    NEW_TRADE_REQUEST_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent you a private trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_APPROVED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span><span>. Your trade can now be found under Live Contracts</span></React.Fragment>,
    TRADE_REQUEST_REJECTED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_CANCELED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has cancelled trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TENDER_REQUEST_CANCELED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has cancelled tender <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_SUBJECT_CONFIRM: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent the acceptance for your confirmation <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_SIGNED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has signed trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_ORIGIN_SELECT_NOTIFY: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has selected a contractual origin <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_VESSEL_NOMINATED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has nominated vessel for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_VESSEL_NOMINATION_APPROVED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved vessel nomination for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_VESSEL_NOMINATION_LAYCANDATECHANGE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has changed laycan dates of nominated vessel for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_VESSEL_NOMINATION_REJECTED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected vessel nomination for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,

    SUBSTITUTE_VESSEL_NOMINATED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has nominated substitute vessel for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    SUBSTITUTE_VESSEL_NOMINATION_APPROVED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved substitute  vessel nomination for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    SUBSTITUTE_VESSEL_NOMINATION_LAYCANDATECHANGE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has changed laycan dates of substitute vessel for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    SUBSTITUTE_VESSEL_NOMINATION_REJECTED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected substitute vessel nomination for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,

    INSPECTION_WAS_CHOOSED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has nominated inspection company for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_REQUEST_DOCUMENT_INSTRUICTIONS_SENT_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent documentary instructions for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    SHIPPING_ADVICE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has issued shipping advice for trade <span className="notification-link">{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_UPLOADED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded document {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_BILL_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has filled in {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_INVOICE_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has filled in {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_APPROVED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved document {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_REJECTED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected document {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    COMMENT_ADDED_TO_DOC_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added comment to the document {data.docName} for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    COMMENT_ADDED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added message to the trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_COMMENT_ADDED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added message to the trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DocumentCommentRejected: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected comment to the document <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    WatermarkLifted: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has lifted watermark for document <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DocumentFilled: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has filled in document for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    ShipmentInstructionsSent: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent shipping instructions for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PaymentConfirmed: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has confirmed payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TradeClosed: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has closed trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_RELEASED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has released document {data.docName} for buyer review for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_PAYMENT_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has issued payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_PAYED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has confirmed payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_CLOSE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has closed trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_DECLINED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has declined the counter proposal for trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_COUNTERED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has countered your offer for trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_RECOUNTER_PRICE_IMPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has improved the price for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_ACCEPTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted your offer for trade request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested a provisional inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_RESPONDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has responded to provisional inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected the  provisional inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_ACCEPTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted the provisional inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_CANCELLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has cancelled the provisional inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_INSPECTION_QUOTE_FILLED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested a final inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_INSPECTION_QUOTE_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected the  final inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_INSPECTION_QUOTE_CANCELLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has cancelled the final inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_INSPECTION_QUOTE_ACCEPTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted the final inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_INSPECTION_QUOTE_RESPONDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has responded to final inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROFORMA_INVOICE_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has filled the proforma invoice for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    UPLOADED_PAYMENT_RECIPT: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the payment receipt for proforma invoice for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROFORMA_INVOICE_EDIT: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has editted the proforma invoice for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOWNPAYMENT_EFFECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has initiated downpayment effected for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRM_PAYMENT: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has confirmed payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PAYMENT_NOT_CONFIRMED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has not confirmed payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROVISIONAL_SHIPPING_QUOTE_FILLED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested a provisional freight quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROVISIONAL_SHIPPING_QUOTE_RESPONDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has responded to the provisional shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_SHIPPING_QUOTE_RESPONDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has responded to the final shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_SHIPPING_QUOTE_FILLED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested a final freight quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROVISIONAL_SHIPPING_QUOTE_ACCEPTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted the provisional shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PROVISIONAL_SHIPPING_QUOTE_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected the provisional shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_SHIPPING_QUOTE_ACCEPTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted the final shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    FINAL_SHIPPING_QUOTE_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected the final shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    PAYMENT_INITIATE_REQ: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested for the payment for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    INSPECTION_QUOTE_COMMENT_ADDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added comment to inspection quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    SHIPPING_QUOTE_COMMENT_ADDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added comment to shipping quote for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DAILY_INSPECTION_REPORT_UPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded daily inspection report for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    NEW_BAGMARKING_ADDED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has added a new bagmarking to trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    BAG_MARKING_APPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved bagmarking for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    BAG_MARKING_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected bagmarking for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DAILY_INSPECTION_REPORT_APPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved daily inspection report for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DAILY_INSPECTION_REPORT_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected daily inspection report for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    DOCUMENT_BILL_EDITED_ADVICE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has updated Bill of lading for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span>. Please check amendments in Shipping Advice also</React.Fragment>,
    DOCUMENT_BILL_EDITED_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has updated Bill of lading for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_BANK_DETAILS_SENT: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent the bank details for acceptance <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_BID_BANK_DETAILS_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected the bank details <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    //BOOKING_REQUEST_SUCCESS:(initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) booking request has been successfuly sent <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>
    CONTRACT_AMENDMENTS_REQUEST_NOTIFY: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has sent a contract amendment request for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONTRACT_AMENDMENTS_REQUEST_ACCEPT_NOTIFY: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has accepted the contract amendment request for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONTRACT_AMENDMENTS_REQUEST_DECLINE_NOTIFY: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has declined the contract amendment request for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    NEW_TENDER_REQUEST_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has created a new tender request <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TENDER_SUBSCRIPTION_REQUEST_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has submitted subscription request for tender <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TENDER_SUBSCRIPTION_APPROVE_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved your subscription request for tender <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TENDER_SUBSCRIPTION_REJECT_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has rejected your subscription request for tender <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has submitted the L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_UPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the L/C draft  <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_EDITED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has updated the L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_REUPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the L/C draft again <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_APPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved the L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    LC_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested changes to the L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    
    CONFIRMED_LC_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has submitted the confirmed L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRMED_LC_UPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the confirmed L/C draft  <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRMED_LC_EDITED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has updated the confirmed L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRMED_LC_REUPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded confirmed L/C draft again <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRMED_LC_APPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved the confirmed L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    CONFIRMED_LC_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested changes to the confirmed L/C draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,

    AD_FILLED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has submitted the avalised draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    AD_UPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the avalised draft  <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    AD_EDITED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has updated the avalised draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    AD_REUPLOADED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has uploaded the avalised draft again <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    AD_APPROVED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has approved the avalised draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    AD_REJECTED: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has requested changes to the avalised draft <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    
    PROFORMA_INVOICE_UPDATE: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) Pro-forma invoice has been updated following amendments to the contract for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    MAERSK_FINAL_BOOKING_NOTIF: (initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) Your booking request has been sent to Maersk please wait while Maersk review and respond via Acknowledgement file followed by booking confirmation file. To check the status of booking request please go to shipping quotes overview screen - Final offer section <span className='notification-link'>{data.tradeRefId || data.tradeID}</span></React.Fragment>,
    TRADE_EXTENDVALIDITY_NOTIF:(initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has prolonged the expiry date of <span className='notification-link'>{data.tradeRefId || data.tradeID}</span> trade request</React.Fragment>,
    DOCUMENT_INVOICE_INSPEC_NOTIF:(initiator, data, initiatorCompName) => <React.Fragment>{initiator} ({initiatorCompName}) has issued commercial invoice for trade <span className='notification-link'>{data.tradeRefId || data.tradeID}</span> and your documents are required</React.Fragment>,
};

const path = (type, data) => {
    switch (type) {
        case 'NEW_OPEN_TRADE_REQUEST_NOTIF':
        case 'NEW_TENDER_REQUEST_NOTIF':
            return "/exchange/details/" + (data.id || data.tradeID);
        case 'DocumentCommentRejected':
        case 'NEW_TRADE_REQUEST_NOTIF':
        case "COMMENT_ADDED_NOTIF":
        case 'TRADE_REQUEST_REJECTED_NOTIF':
        case 'TRADE_COUNTERED_NOTIF':
        case 'TRADE_BID_SUBJECT_CONFIRM':
        case 'TRADE_BID_DECLINED_NOTIF':
        case 'TRADE_REQUEST_CANCELED_NOTIF':
        case 'TENDER_REQUEST_CANCELED_NOTIF':
        case 'TENDER_SUBSCRIPTION_REQUEST_NOTIF':
        case 'TENDER_SUBSCRIPTION_APPROVE_NOTIF':
        case 'TENDER_SUBSCRIPTION_REJECT_NOTIF':
        case 'TRADE_BID_RECOUNTER_PRICE_IMPROVED':
            return `/requests/details/${(data.id || data.tradeID)}${data.initiatorId ? '/counterparty/'.concat(data.initiatorId) : ""}`;
        case 'COMMENT_ADDED_TO_DOC_NOTIF':
        case 'DOCUMENT_UPLOADED_NOTIF':
        case 'DOCUMENT_APPROVED_NOTIF':
        case 'DOCUMENT_REJECTED_NOTIF':
        case 'DOCUMENT_RELEASED_NOTIF':
            return `/trades/details/${data.tradeID}/documents?shipmentId=${data.shipmentID}`;
        case 'DOCUMENT_BILL_FILLED':
        case 'DOCUMENT_BILL_EDITED_ADVICE_NOTIF':
        case 'DOCUMENT_BILL_EDITED_NOTIF':
            return `/trades/details/${data.tradeID}/shipment/${data.shipmentID}/documents/bill/preview`;
        case 'DOCUMENT_INVOICE_FILLED':
            if (data.shipmentID !== '0') {
                return `/trades/details/${data.tradeID}/shipment/${data.shipmentID}/documents/invoice/preview`;
            } else {
                return `/trades/details/${data.tradeID}/documents/invoice/preview`;
            }
        case 'SHIPPING_ADVICE_NOTIF':
            return `/trades/details/${data.tradeID}/advice`;
        case 'TRADE_REQUEST_DOCUMENT_INSTRUICTIONS_SENT_NOTIF':
            return `/trades/details/${data.tradeID}/instructions`;
        case 'TRADE_COMMENT_ADDED_NOTIF':
            return `/trades/details/${data.id || data.tradeID}/messages`;
        case 'PROFORMA_INVOICE_UPDATE' :
            return `/trades/details/${data.tradeID}/documents`;
        case 'PROFORMA_INVOICE_FILLED':
        case 'UPLOADED_PAYMENT_RECIPT':
        case 'PROFORMA_INVOICE_EDIT':
        case 'DOWNPAYMENT_EFFECTED':
        case 'CONFIRM_PAYMENT':
        case 'PAYMENT_NOT_CONFIRMED':
        case 'LC_FILLED':
        case 'LC_UPLOADED':
        case 'LC_REUPLOADED':
        case 'LC_EDITED':
        case 'LC_APPROVED':
        case 'LC_REJECTED':
        case 'CONFIRMED_LC_FILLED':
        case 'CONFIRMED_LC_UPLOADED':
        case 'CONFIRMED_LC_REUPLOADED':
        case 'CONFIRMED_LC_EDITED':
        case 'CONFIRMED_LC_APPROVED':
        case 'CONFIRMED_LC_REJECTED':
        case 'AD_FILLED':
        case 'AD_UPLOADED':
        case 'AD_REUPLOADED':
        case 'AD_EDITED':
        case 'AD_APPROVED':
        case 'AD_REJECTED':
            return `/trades/details/${data.tradeID}/documents`;
        case 'INSPECTION_QUOTE_FILLED':
        case 'INSPECTION_QUOTE_RESPONDED':
        case 'FINAL_INSPECTION_QUOTE_RESPONDED':
            return `/inspectionQuoteOverview/`;
        case 'INSPECTION_QUOTE_REJECTED':
        case 'INSPECTION_QUOTE_ACCEPTED':
        case 'INSPECTION_QUOTE_CANCELLED':
            return `/provisionalInspectionList/`;
        case 'FINAL_INSPECTION_QUOTE_FILLED_NOTIF':
        case 'FINAL_INSPECTION_QUOTE_REJECTED':
        case 'FINAL_INSPECTION_QUOTE_CANCELLED':
        case 'FINAL_INSPECTION_QUOTE_ACCEPTED':
            return `/finalInspectionList/`;
        case "PROVISIONAL_SHIPPING_QUOTE_FILLED_NOTIF":
        case "PROVISIONAL_SHIPPING_QUOTE_REJECTED":
        case "PROVISIONAL_SHIPPING_QUOTE_CANCELLED":
        case "PROVISIONAL_SHIPPING_QUOTE_ACCEPTED":
            return `/provisionalShippingList/`
        case "FINAL_SHIPPING_QUOTE_ACCEPTED":
        case "FINAL_SHIPPING_QUOTE_REJECTED":
        case "FINAL_SHIPPING_QUOTE_CANCELLED":
        case "FINAL_SHIPPING_QUOTE_FILLED_NOTIF":
            return `/finalShippingList/`
        case "FINAL_SHIPPING_QUOTE_RESPONDED":
        case "PROVISIONAL_SHIPPING_QUOTE_RESPONDED":
            return `/shippingQuoteOverview/`
        case "DAILY_INSPECTION_REPORT_UPLOADED":
        case "DAILY_INSPECTION_REPORT_APPROVED":
        case "DAILY_INSPECTION_REPORT_REJECTED":
            return `/trades/details/${data.tradeID}/inspection-reports`
        case "INSPECTION_QUOTE_COMMENT_ADDED":
            return `/inspectionQuoteDetails/${data.tradeID}/${data.inspectionID}`
        // case "BOOKING_REQUEST_SUCCESS":    
        case "SHIPPING_QUOTE_COMMENT_ADDED":
            return `/ShippingQuoteDetails/${data.tradeID}/${data.shippingQuoteID}`
        case "NEW_BAGMARKING_ADDED":
        case "BAG_MARKING_APPROVED":
        case "BAG_MARKING_REJECTED":
            return `/trades/details/${data.tradeID}/bagmarking`
        case "CONTRACT_AMENDMENTS_REQUEST_NOTIFY":
        case "CONTRACT_AMENDMENTS_REQUEST_ACCEPT_NOTIFY":
        case "CONTRACT_AMENDMENTS_REQUEST_DECLINE_NOTIFY":
            return `/trades/details/${data.tradeID}/contract`
        case "SUBSTITUTE_VESSEL_NOMINATED_NOTIF":
        case "SUBSTITUTE_VESSEL_NOMINATION_APPROVED_NOTIF":
        case "SUBSTITUTE_VESSEL_NOMINATION_LAYCANDATECHANGE_NOTIF":
        case "SUBSTITUTE_VESSEL_NOMINATION_REJECTED_NOTIF":
            return `/trades/details/${data.tradeID}/nominatedVesselInfo`
        case "MAERSK_FINAL_BOOKING_NOTIF":
            return "/trades/details/" + (data.id || data.tradeID);
        default:
            return "/trades/details/" + (data.id || data.tradeID);
    }
};



function Notification({ type, initiator, date, data, onClick, read, onDelete, deletePending, initiatorCompName, initiatorId }) {
    
    const jData = useMemo(() => {
        let jsonData;
        if (data) {
            jsonData = JSON.parse(data);
            if (initiatorId) {
                jsonData.initiatorId = initiatorId;
            }
            if (jsonData.docType)
                jsonData.docName = DOCUMENT_NAMES[jsonData.docType] ? DOCUMENT_NAMES[jsonData.docType] : jsonData.docType;
        } 
        return jsonData;

    }, [data, initiatorId])
    
    const template = Notifications[type];

    return (

        <div className='notification-item' onClick={onClick && (() => onClick(path(type, jData)))}>
            <div className='n-header'>
                <span>{date && moment(date).format(DATEFORMATHOURS)}</span>
                {onDelete && (
                    <div className="n-header__controls">
                        <div className="n-delete" title="Delete notification" onClick={e => {
                            e.stopPropagation();
                            onDelete();
                        }}>
                            <MdDelete />
                        </div>
                    </div>
                )}
            </div>
            <div className='n-content'>
                {!read && <div className="n-new-badge"/>}
                {template && <span>{template(initiator, jData, initiatorCompName)}</span>}
            </div>
            <div className="n-controls">
            </div>
        </div>)
        


}

export default Notification;
