import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
    loadRequestDetails,
    LoadRequestInfo,
    GetTradeBill,
    GetTradeInvoice,
    GetTradeInvoiceByShipmentId,
    PostTradeDocumentInvoice,
    PostTradeMultisetInvoice,
    UpdateInvoice, UpdateMultisetInvoice, GetVesselNomination
} from '../../../../modules/module.trade';

import moment from 'moment';
import { EnumsService, PortService, renderPackaging } from '../../../../services/service.utils';
import { FertilizerService } from '../../../../services/service.fertilizer';
import { DATEFORMAT, INCOTERMOPT, CURRENCYOPT } from '../../../../services/service.values';
import Dropzone from 'react-dropzone';
import Preloader from '../../../components/preloader/Preloader';
import { DOCUMENT_TYPES } from '../services/documents.service';
import { KycApi } from '../../../../services/service.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import converter from 'number-to-words';
import ImageViewer from '../../../components/ImageViewer';
import { renderFertilizerSpecifications } from '../../requests/detail/detailTable/Renderers';

const Countries = EnumsService.countries();
const FERTILIZER_TYPE = FertilizerService.getAllFertilizers(true);

//bol -- Bill of Lading
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice: {},
            initialized: false,
            postData: null,
            isUpdate: false,
            showModal: false,
            signatureModal: {
                file: null,
                privateKey: null,
                loading: false
            },
            companyDetails: {}
        };
    }

    initialize() {
        let isEmpty = value => !value || value === undefined || value === '';
        this.initField('invoice', 'invoiceNo', 'Invoice No', '', isEmpty);
        this.initField('invoice', 'vesselName', 'Vessel\'s Name', '', isEmpty);
        this.initField('invoice', 'bankRequisites', 'Bank Requisites', '', isEmpty);
        this.initField('invoice', 'additionalInformation', 'Aditional Information', '', false);
    }

    get firstBill() {
        return this.bills[0];
    }

    get commercialInvInd() {
        return this.props.trade.instructions ? this.props.trade.instructions.documentaryInstructions.commercialInvInd : null;
    }

    get bills() {
        //return Object.values(this.props.trade.bills);
        if (this.commercialInvInd === 'GLOBAL') {
            return Object.values(this.props.trade.bills);
        } else {
            return [this.props.trade.bills[this.props.shipmentId]]
        }
    }

    get trade() {
        return this.props.trade.items.single;
    }

    get totalDownpayment() {
        return (this.trade.downPaymentAmount);
    }

    get downpaymentPerBill() {
        if (this.commercialInvInd === 'GLOBAL') {
            return this.totalDownpayment;
        } else {
            let setQuantity = 0;
            for (let i = 0; i < this.props.trade.shipments.length; i++) {
                if (this.props.trade.shipments[i].id === this.props.shipmentId) {
                    setQuantity = this.props.trade.shipments[i].amount;
                    break;
                }
            }
            return this.totalDownpayment * setQuantity / this.trade.measure;
        }
    }

    get totalAmount() {
        const quantity = this.bills.reduce((acc, bill) => acc + bill.QuantCleanOnBoard, 0);
        if (this.trade.downPaymentPercentage > 0) {
            return (quantity * this.props.trade.items.single.price) - this.downpaymentPerBill;
        } else {
            return quantity * this.props.trade.items.single.price;
        }
    }

    get overAllAmount() {
        const quantity = this.bills.reduce((acc, bill) => acc + bill.QuantCleanOnBoard, 0);
        return quantity * this.props.trade.items.single.price;
    }

    get totalQuantity() {
        return this.bills.reduce((acc, bill) => acc + bill.QuantCleanOnBoard, 0);
    }

    renderPortLabel = value => {
        let label = "";
        if (value) {
            label = PortService.getJSON()[value] ? PortService.getJSON()[value].name : value;
        }
        return label;
    }

    UNSAFE_componentWillMount = () => {
        this.initialize();
        this.props.loadRequestDetails(this.props.id);
        this.props.LoadRequestInfo(this.props.id);
        this.props.GetVesselNomination(this.props.id);
    };

    componentDidMount() {
        if (this.props.isPreview || this.props.isPreview === undefined) {
            if (this.props.shipmentId && this.commercialInvInd !== 'GLOBAL') {
                this.props.GetTradeInvoiceByShipmentId(this.props.id, this.props.shipmentId)
            } else {
                this.props.GetTradeInvoice(this.props.id);
            }
        }
        if (this.props.trade.items.single) {
            KycApi.getById(this.props.trade.items.single.sellerId)
                .then(res => {
                    this.setState({ companyDetails: res.data.company })
                })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.trade.invoice && !this.state.initialized) {
            this.updateState(nextProps.trade.invoice);
            this.setState({ initialized: true });
        }
        if (this.props.isPreview === false && !this.state.invoice.vesselName.value && this.props.trade.vesselNomination !== nextProps.trade.vesselNomination) {
            this.setState(prevState => ({
                invoice: {
                    ...prevState.invoice,
                    vesselName: {
                        ...prevState.invoice.vesselName,
                        value: nextProps.trade.vesselNomination.name
                    }
                }
            }));
        }
    }

    initField(document, name, label, value, required) {

        this.setState(prevState => {
            prevState[document][name] = {
                name: name,
                label: label,
                required: required,
                value: value
            };
            return prevState;
        });
    }

    getValueToPostUpdate = state => {
        let result = {};
        Object.keys(state).map((k, i) => {
            result[k] = state[k].value;
            return k;
        });
        result.id = this.props.trade.invoice.ID;
        result['totalAmount'] = this.totalAmount;
        return result;
    };

    setField = (name, value) => {
        let documentValue = this.state.invoice;
        documentValue[name] = {
            ...documentValue[name],
            value: value
        };
        this.setState({
            invoice: documentValue
        });
    };

    submitForm = e => {
        if (this.props.trade.invoice) {
            this.submitFormUpdate(e);
            return;
        }
        e.preventDefault();
        if (this.validate(this.state.invoice)) {
            this.setState({
                isUpdate: false
            });
            let postData = this.getValueToPost(this.state.invoice);
            this.signContract(postData, false);
        }
    };


    submitFormUpdate = e => {
        e.preventDefault();
        if (this.validate(this.state.invoice)) {
            let postData = this.getValueToPostUpdate(this.state.invoice);
            this.signContract(postData, true);
        }
    };

    getValueToPost = state => {
        let result = {};
        Object.keys(state).map((k, i) => {
            result[k] = state[k].value;
            return k;
        });
        result['totalAmount'] = this.totalAmount;
        return result;
    };

    validate(container) {
        let required = {};
        Object.keys(container).map(key => {
            let v = container[key];
            if (v && v.required && v.required(v.value)) {
                required[key] = v;
            }
            return false;
        });
        if (Object.keys(required).length > 0) {
            this.setState({ required: required });
            return false;
        }
        return true;
    }

    updateState = request => {
        this.setState({
            invoice: {
                ...this.state.invoice,
                invoiceNo: {
                    ...this.state.invoice.invoiceNo,
                    value: request.invoiceNo
                },
                bankRequisites: {
                    ...this.state.invoice.invoiceNo,
                    value: request.bankRequisites
                },
                vesselName: {
                    ...this.state.invoice.vesselName,
                    value: request.vesselName
                },
                additionalInformation: {
                    ...this.state.invoice.additionalInformation,
                    value: request.additionalInformation
                }
            }
        });
    };

    signContract = (postData, isUpdate) => {
        this.setState(prevState => ({
            ...prevState
        }));
        const submitFunc = isUpdate ? this.commercialInvInd === 'GLOBAL' ? this.props.UpdateInvoice : this.props.UpdateMultisetInvoice : this.commercialInvInd === 'GLOBAL' ? this.props.PostTradeDocumentInvoice : this.props.PostTradeMultisetInvoice;
        submitFunc(this.props.id, postData, (result) => {
            if (result) {
                this.props.navigateDocs(this.props.id);
            } else {
                this.setState(prevState => ({
                    ...prevState
                }))
            }
        }, this.props.shipmentId);
    };

    onKeyFileDrop = (filesAccept, filesNotAccept) => {
        const reader = new FileReader();
        const file = filesAccept[0];
        this.setState(prevState => ({
            ...prevState,
            signatureModal: {
                file,
                loading: true,
                privateKey: ''
            }
        }));
        reader.onloadend = event => {
            this.setState(prevState => ({
                ...prevState,
                signatureModal: {
                    ...prevState.signatureModal,
                    privateKey: atob(event.target.result.replace('data:text/plain;base64,', '')),
                    loading: false
                }
            }));
        };
        try {
            reader.readAsDataURL(file);
        } catch (error) {
            this.setState(prevState => ({
                ...prevState,
                signatureModal: {
                    ...prevState.signatureModal,
                    loading: false,
                    error: 'File type is not supported. Make sure you are uploading your private key file.'
                }
            }))
        }
    };

    closeSignatureModal = () => {
        this.setState(prevState => ({
            ...prevState,
            showModal: false,
            signatureModal: {}
        }));
    };

    renderSignatureModal = () => {
        return (
            <div className="modal__container">
                <form className="modal__wrapper" onSubmit={(e) => {
                    e.preventDefault();
                    if (!this.state.signatureModal.privateKey) {
                        return;
                    }
                    this.signContract();
                }}>
                    {!this.state.signatureModal.loading && <span className="modal__close" onClick={this.closeSignatureModal} />}
                    <h3 className="modal__heading">Sign Invoice</h3>
                    <div className="modal__line mb-4 justify-content-center">
                        <Dropzone
                            className="upload"
                            activeClassName="upload__active"
                            accept={'text/plain'}
                            onDrop={(filesAccept, filesNotAccept) => this.onKeyFileDrop(filesAccept, filesNotAccept)}
                        >
                            {this.state.signatureModal.file ? <p>{this.state.signatureModal.file.name}</p> :
                                <p>Choose Keys File (or Drop)</p>}
                        </Dropzone>
                    </div>
                    {this.state.signatureModal.error && (
                        <div className="mb-4 text-danger justify-content-center d-flex">
                            {this.state.signatureModal.error}
                        </div>
                    )}
                    <button type="submit" disabled={!this.state.signatureModal.privateKey}
                        className="modal__button">
                        <Preloader loadingStyle="dots" loading={this.state.signatureModal.loading}>
                            <span>Upload Keys File</span>
                        </Preloader>
                    </button>
                </form>
            </div>
        )
    };

    renderPage = () => {
        // this.setState({

        // })
        let trade = this.props.trade.items.single;
        if (this.props.isPreview === undefined) {
            if (this.props.trade && this.props.trade.invoice !== undefined) {
                return (
                    <form
                        onChange={e => this.setField(e.target.name, e.target.value)}
                        onSubmit={e => this.submitFormUpdate(e)}
                        className="trades-dtls__doc-wrapper trades-dtls__invoice-wrapper"
                    >

                        <div className="img-fluid" style={{ height: '100px' }}>
                            {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo} />}
                        </div>
                        <h4 className="trades-dtls__doc-heading">Commercial Invoice</h4>
                        <div className="trades-dtls__input-wrapper">
                            <input
                                defaultValue={this.props.trade.items.single.seller}
                                className="trades-dtls__doc-input trades-dtls__doc-input_long trades-dtls__doc-input_dark"
                                type="text"
                                id="from"
                                disabled
                            />
                        </div>


                        <div className="container-fluid">
                            <div className="row">
                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label"
                                        htmlFor="to">
                                        To <span className="req-field">*</span>
                                    </label>
                                    <input
                                        disabled
                                        defaultValue={this.props.trade.items.single.buyer}
                                        type="text"
                                        id="to"
                                        className="trades-dtls__doc-input trades-dtls__doc-input_long trades-dtls__doc-input_dark"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label" htmlFor="invoice">
                                        Invoice No. <span className="req-field">*</span>
                                    </label>
                                    <input
                                        disabled={false}
                                        value={this.state.invoice.invoiceNo.value}
                                        className={
                                            'trades-dtls__doc-input' +
                                            (this.state.required && this.state.required.hasOwnProperty('invoiceNo')
                                                ? ' account-input_error input_error '
                                                : '')
                                        }
                                        type="text"
                                        id="invoice"
                                        name="invoiceNo"
                                    />
                                </div>

                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label" htmlFor="invoice-date">
                                        Invoice Date <span className="req-field">*</span>
                                    </label>
                                    <input
                                        defaultValue={
                                            this.props.isPreview === undefined
                                                ? moment(this.props.trade.invoice.CreatedAt).format(DATEFORMAT)
                                                : moment().format(DATEFORMAT)
                                        }
                                        disabled
                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                        type="text"
                                        id="invoice-date"
                                    />
                                </div>

                                {this.trade.incoterm === INCOTERMOPT.FOB && (
                                    <div className="trades-dtls__input-wrapper">
                                        <label className="trades-dtls__doc-label" htmlFor="invoice">
                                            Port of loading <span className="req-field">*</span>
                                        </label>
                                        <input
                                            disabled
                                            defaultValue={`${this.renderPortLabel(this.trade.loadPort)}, ${Countries[this.trade.loadCountry]}`}
                                            className="trades-dtls__doc-input trades-dtls__doc-input_dark trades-dtls__doc-input--long"
                                            type="text"
                                            id="loadingport"
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="row">
                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label" htmlFor="invoice">
                                        Contract/PO No. <span className="req-field">*</span>
                                    </label>
                                    <input
                                        disabled
                                        defaultValue={this.props.trade.items.single.tradeRefId ? this.props.trade.items.single.tradeRefId : this.props.trade.items.single.id}
                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                        type="text"
                                        id="contract"
                                    />
                                </div>

                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label" htmlFor="contract-date">
                                        Contract Date <span className="req-field">*</span>
                                    </label>
                                    <input
                                        disabled
                                        defaultValue={moment(this.props.trade.items.single.createdAt).format(DATEFORMAT)}
                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                        type="text"
                                        id="contract-date"
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="trades-dtls__input-wrapper">
                                    <label className="trades-dtls__doc-label" htmlFor="vesselName">
                                        Vessel's Name <span className="req-field">*</span>
                                    </label>
                                    <input
                                        value={this.state.invoice.vesselName.value}
                                        className={
                                            'trades-dtls__doc-input' +
                                            (this.state.required && this.state.required.hasOwnProperty('vesselName')
                                                ? ' account-input_error input_error '
                                                : '')
                                        }
                                        type="text"
                                        id="vesselName"
                                        name="vesselName"
                                    />
                                </div>
                            </div>

                            {this.bills.map(bill => {
                                let port = ''
                                if (bill.PortOfDischarge) {
                                    port = bill.PortOfDischarge;
                                }

                                return (<React.Fragment>
                                    <div className="row">
                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="billoflading">
                                                Bill of Lading No. <span className="req-field">*</span>
                                            </label>
                                            <input
                                                disabled
                                                value={bill.BillNumber}
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="billoflading"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="billdate">
                                                Bill of Lading Date <span className="req-field">*</span>
                                            </label>
                                            <input
                                                value={moment(bill.CreatedAt).format(DATEFORMAT)}
                                                disabled
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="billdate"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="incoterm">
                                                Incoterm <span className="req-field">*</span>
                                            </label>
                                            <input
                                                value={this.trade.incoterm}
                                                disabled
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="incoterm"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="dischargeport">
                                                Port of discharge*
                                            </label>
                                            <input
                                                disabled
                                                defaultValue={port}
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="dischargeport"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="quantity">
                                                Bill of Lading Quantity<span className="req-field">*</span>
                                            </label>
                                            <input
                                                value={bill.QuantCleanOnBoard.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                disabled
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="quantity"
                                            />
                                        </div>
                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="packaging">
                                                Packaging <span className="req-field">*</span>
                                            </label>
                                            <input
                                                value={renderPackaging(trade.packaging)}
                                                disabled
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="packaging"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                                )
                            })}

                            <div className="row">
                                <div className="trades-dtls__invoice-th">
                                    <div className="trades-dtls__invoice-td">Description of goods</div>
                                    <div className="trades-dtls__invoice-td">Unit price ({CURRENCYOPT[this.trade.currency]})</div>
                                    <div className="trades-dtls__invoice-td">Amount ({CURRENCYOPT[this.trade.currency]})</div>
                                </div>
                                <div className="trades-dtls__invoice-tr">
                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>
                                            <b>Measure : </b> {this.totalQuantity.toLocaleString(undefined, { maximumFractionDigits: 2 })} ({converter.toWords(this.totalQuantity)})
                                        </div>
                                        <div>
                                            <b>Measurement : </b> Metric Ton
                                        </div>
                                        <div>
                                            <b>Origin : </b> {Countries[this.trade.origin]}
                                        </div>
                                        <div>
                                            <b>Fertilizer Type : </b> {FERTILIZER_TYPE[this.trade.fertilizerType] || this.trade.fertilizerType}
                                        </div>
                                        <div>
                                            <b>Fertilizer Specifications : </b>
                                            {renderFertilizerSpecifications(this.trade.specifications, this.trade.fertilizerType, true)}
                                        </div>
                                    </div>
                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>
                                            {trade.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                        <br />
                                        <div>
                                            {
                                                this.trade.incoterm !== 'FOB' ?
                                                    <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.destPort)}, {Countries[this.trade.destCountry]}</b>
                                                    : <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.loadPort)}, {Countries[this.trade.loadCountry]}</b>
                                            }
                                        </div>
                                    </div>
                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                        <div>{this.overAllAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
                                        <br />
                                        <div>
                                            <b>Minus down payment received</b> {this.downpaymentPerBill.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                        <br />
                                        <div>
                                            <b>Balance Amount</b> {(this.overAllAmount - this.downpaymentPerBill).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="trades-dtls__summ-wrapper">
                                        <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                            htmlFor="additionalInformation">
                                            Additional Information
                                        </label>
                                        <textarea
                                            name="additionalInformation"
                                            type="text"
                                            value={this.state.invoice.additionalInformation.value}
                                            rows="4"
                                            className={
                                                'trades-dtls__doc-textarea' +
                                                (this.state.required && this.state.required.hasOwnProperty('additionalInformation')
                                                    ? ' account-input_error input_error '
                                                    : '')
                                            } />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="trades-dtls__summ-wrapper">
                                        <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                            htmlFor="requisiters">
                                            Remit To <span className="req-field">*</span>
                                        </label>
                                        <textarea
                                            disabled={false}
                                            value={this.state.invoice.bankRequisites.value}
                                            className={
                                                'trades-dtls__doc-textarea' +
                                                (this.state.required && this.state.required.hasOwnProperty('bankRequisites')
                                                    ? ' account-input_error input_error '
                                                    : '')
                                            }
                                            name="bankRequisites"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-4">
                                    <div className="trades-dtls__summ-wrapper">
                                        <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                            htmlFor="totalpay">
                                            Total payable <span className="req-field">*</span>
                                        </label>
                                        <input
                                            disabled
                                            className="trades-dtls__doc-input trades-dtls__doc-input_dark trades-dtls__doc-input--black"
                                            type="text"
                                            value={`${CURRENCYOPT[this.trade.currency]} ${this.totalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="trades-dtls__invoice-required"><span className="req-field">*</span> Required fields</div>
                                {this.props.isPreview === undefined && (
                                    <Fragment>
                                        {this.state.required && Object.keys(this.state.required).length > 0 && (
                                            <div className="trades-dtls__error-message text-center">
                                                Please, complete all required fields before submitting
                                            </div>
                                        )}
                                        <div className="trades-dtls__bottom-btn-section">
                                            <Link to={`/trades/details/${this.props.id}/documents`}
                                                className="btn btn-cancel">
                                                <FontAwesomeIcon icon={faTimes} /> Cancel
                                        </Link>
                                            <button type="submit" className="btn btn-save"
                                                disabled={this.props.loading}>
                                                <Preloader loadingStyle="dots" loading={this.props.loading}>
                                                    <FontAwesomeIcon icon={faCheck} /> <span>Save</span>
                                                </Preloader>
                                            </button>

                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </form>
                );
            }
        }
    };

    render() {
        let trade = this.props.trade.items.single;
        if (!this.firstBill || !this.props.trade.vesselNomination || Object.keys(this.props.trade.vesselNomination).length === 0) {
            return (
                <div className="trades-dtls__doc">
                    <Preloader loadingStyle="swirl" loading={true} />
                </div>
            );
        }

        return (
            <div className="trades-dtls__doc">
                <div className="trades-dtls__doc-navigation">
                    <div className="container-fluid">
                        {!this.props.isComponentPrinting ? <div className="row">
                            <div className="col-md-6">
                                <Link to={`/trades/details/${this.props.id}/documents`}>List of Required Documents</Link> / Invoice
                        </div>
                            <div className="col-md-6 trades-dtls__buttons-wrapper">
                                <button type="submit" className="btn btn-save mr-0" onClick={() => {
                                    this.props.navigateDocs(this.props.id)
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> <span style={{ color: '#fff' }}>Back To Documents</span>
                                </button>
                            </div>
                        </div> : null}
                    </div>
                </div>
                {this.props.isPreview === undefined ? (
                    this.renderPage()
                ) : (this.props.isPreview ? this.props.trade.invoice : this.firstBill) &&
                    this.props.isPreview !== null ? (
                            <form
                                onChange={e => this.setField(e.target.name, e.target.value)}
                                onSubmit={e => this.submitForm(e)}
                                className="trades-dtls__doc-wrapper trades-dtls__Commercial-invoice-wrapper"
                            >

                                {this.props.isComponentPrinting ?
                                    <div className="img-fluid" style={{ height: '120px' }}>
                                        {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo} />}
                                    </div> :
                                    <div>
                                        {this.state.companyDetails.companyLogo && <ImageViewer image={this.state.companyDetails.companyLogo} />}
                                    </div>}
                                <h4 className="trades-dtls__doc-heading">Commercial Invoice</h4>
                                <div className="trades-dtls__input-wrapper">
                                    <input
                                        defaultValue={trade.seller}
                                        className="trades-dtls__doc-input trades-dtls__doc-input_long trades-dtls__doc-input_dark"
                                        type="text"
                                        disabled
                                    />
                                </div>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label"
                                                htmlFor="to">
                                                To <span className="req-field">*</span>
                                            </label>
                                            <input
                                                disabled
                                                defaultValue={trade.buyer}
                                                type="text"
                                                id="to"
                                                className="trades-dtls__doc-input trades-dtls__doc-input_long trades-dtls__doc-input_dark"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="invoice">
                                                Invoice No. <span className="req-field">*</span>
                                            </label>
                                            <input
                                                disabled={this.props.isPreview ? 'true' : ''}
                                                value={this.props.isPreview ? this.props.trade.invoice.invoiceNo : this.state.invoice.invoiceNo.value}
                                                className={
                                                    'trades-dtls__doc-input' +
                                                    (this.state.required && this.state.required.hasOwnProperty('invoiceNo')
                                                        ? ' account-input_error input_error '
                                                        : '')
                                                }
                                                type="text"
                                                id="invoice"
                                                name="invoiceNo"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="invoice-date">
                                                Invoice Date <span className="req-field">*</span>
                                            </label>
                                            <input
                                                defaultValue={
                                                    this.props.isPreview
                                                        ? moment(this.props.trade.invoice.createdAt).format(DATEFORMAT)
                                                        : moment().format(DATEFORMAT)
                                                }
                                                disabled
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="invoice-date"
                                            />
                                        </div>

                                        {this.trade.incoterm === INCOTERMOPT.FOB && (
                                            <div className="trades-dtls__input-wrapper">
                                                <label className="trades-dtls__doc-label" htmlFor="invoice">
                                                    Port of loading*
                                        </label>
                                                <input
                                                    disabled
                                                    defaultValue={`${this.renderPortLabel(this.trade.loadPort)}, ${Countries[this.trade.loadCountry]}`}
                                                    className="trades-dtls__doc-input trades-dtls__doc-input_dark trades-dtls__doc-input--long"
                                                    type="text"
                                                    id="loadingport"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="trades-dtls__input-wrapper pt-1">
                                            <label className="trades-dtls__doc-label" htmlFor="contract">
                                                Contract/PO No. <span className="req-field">*</span>
                                            </label>
                                            <input
                                                disabled
                                                defaultValue={this.props.trade.items.single.tradeRefId ? this.props.trade.items.single.tradeRefId : this.props.trade.items.single.id}
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="contract"
                                            />
                                        </div>

                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="contract-date">
                                                Contract Date*
                                            </label>
                                            <input
                                                disabled
                                                defaultValue={moment(trade.createdAt).format(DATEFORMAT)}
                                                className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                type="text"
                                                id="contract-date"
                                                style={{ marginTop: '6px' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="trades-dtls__input-wrapper">
                                            <label className="trades-dtls__doc-label" htmlFor="vesselName">
                                                Vessel's Name <span className="req-field">*</span>
                                            </label>
                                            <input
                                                disabled={this.props.isPreview ? 'true' : ''}
                                                value={this.props.isPreview ? this.props.trade.invoice.vesselName : this.state.invoice.vesselName.value}
                                                className={
                                                    'trades-dtls__doc-input' +
                                                    (this.state.required && this.state.required.hasOwnProperty('vesselName')
                                                        ? ' account-input_error input_error '
                                                        : '')
                                                }
                                                type="text"
                                                id="vesselName"
                                                name="vesselName"
                                            />
                                        </div>
                                    </div>

                                    {this.bills.map(bill => {
                                        let port = ''
                                        if (bill.PortOfDischarge) {
                                            port = bill.PortOfDischarge;
                                        }
                                        return (<React.Fragment key={`${bill.BillNumber}-${bill.billID}`}>
                                            <div className="row">
                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="billoflading">
                                                        Bill of Lading No. <span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        disabled
                                                        value={bill.BillNumber}
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="billoflading"
                                                    />
                                                </div>

                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="billdate">
                                                        Bill of Lading Date <span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        value={moment(bill.CreatedAt).format(DATEFORMAT)}
                                                        disabled
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="billdate"
                                                    />
                                                </div>

                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="incoterm">
                                                        Incoterm <span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        value={this.trade.incoterm}
                                                        disabled
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="billdate"
                                                    />
                                                </div>
                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="dischargeport">
                                                        Port of discharge <span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        disabled
                                                        defaultValue={port}
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="dischargeport"
                                                    />
                                                </div>

                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="quantity">
                                                        Bill of Lading Quantity<span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        value={bill.QuantCleanOnBoard.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        disabled
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="quantity"
                                                    />
                                                </div>
                                                <div className="trades-dtls__input-wrapper">
                                                    <label className="trades-dtls__doc-label" htmlFor="packaging">
                                                        Packaging <span className="req-field">*</span>
                                                    </label>
                                                    <input
                                                        value={renderPackaging(trade.packaging)}
                                                        disabled
                                                        className="trades-dtls__doc-input trades-dtls__doc-input_dark"
                                                        type="text"
                                                        id="packaging"
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        )
                                    })}

                                    <div className="row">
                                        <div className="col-md-12 px-0">
                                            <div className="trades-dtls__input-wrapper">
                                                <div className="trades-dtls__invoice-th">
                                                    <div className="trades-dtls__invoice-td">Description of goods</div>
                                                    <div className="trades-dtls__invoice-td">Unit price ({CURRENCYOPT[this.trade.currency]})</div>
                                                    <div className="trades-dtls__invoice-td">Amount ({CURRENCYOPT[this.trade.currency]})</div>
                                                </div>
                                                <div className="trades-dtls__invoice-tr">
                                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                        <div>
                                                            <b>Measure : </b> {this.totalQuantity.toLocaleString(undefined, { maximumFractionDigits: 2 })} ({converter.toWords(this.totalQuantity)})
                                                </div>
                                                        <div>
                                                            <b>Measurement : </b> Metric Ton
                                                        </div>
                                                        <div>
                                                            <b>Origin : </b> {Countries[this.trade.origin]}
                                                        </div>
                                                        <div>
                                                            <b>Fertilizer Type : </b> {FERTILIZER_TYPE[this.trade.fertilizerType] || this.trade.fertilizerType}
                                                        </div>
                                                        <div>
                                                            <b>Fertilizer Specifications : </b>
                                                            {renderFertilizerSpecifications(this.trade.specifications, this.trade.fertilizerType, true)}
                                                        </div>
                                                    </div>
                                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                        <div>
                                                            {trade.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        </div>
                                                        <br />
                                                        <div>
                                                            {
                                                                this.trade.incoterm !== 'FOB' ?
                                                                    <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.destPort)}, {Countries[this.trade.destCountry]}</b>
                                                                    : <b>{this.trade.incoterm} {this.renderPortLabel(this.trade.loadPort)}, {Countries[this.trade.loadCountry]}</b>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="trades-dtls__invoice-td" style={{ display: 'block' }}>
                                                        <div>{this.overAllAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
                                                        <br />
                                                        <div>
                                                            <b>Minus down payment received</b> {this.downpaymentPerBill.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <b>Balance Amount</b> {(this.overAllAmount - this.downpaymentPerBill).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-6 px-0">
                                            <div className="trades-dtls__input-wrapper">
                                                <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                                    htmlFor="additionalInformation">
                                                    Additional Information
                                                </label>
                                                <textarea
                                                    name="additionalInformation"
                                                    disabled={this.props.isPreview ? 'true' : ''}
                                                    type="text"
                                                    value={this.props.isPreview ? this.props.trade.invoice.additionalInformation : this.state.invoice.additionalInformation.value}
                                                    rows="4"
                                                    className={
                                                        'trades-dtls__doc-textarea' +
                                                        (this.state.required && this.state.required.hasOwnProperty('additionalInformation')
                                                            ? ' account-input_error input_error '
                                                            : '')
                                                    } />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between ">
                                        <div className="col-md-6 px-0">
                                            <div className="trades-dtls__input-wrapper">
                                                <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                                    htmlFor="requisiters">
                                                    Remit To <span className="req-field">*</span>
                                                </label>
                                                <textarea
                                                    disabled={this.props.isPreview ? 'true' : ''}
                                                    value={this.props.isPreview ? this.props.trade.invoice.bankRequisites : this.state.invoice.bankRequisites.value}
                                                    className={
                                                        'trades-dtls__doc-textarea' +
                                                        (this.state.required && this.state.required.hasOwnProperty('bankRequisites')
                                                            ? ' account-input_error input_error '
                                                            : '')
                                                    }
                                                    type="text"
                                                    name="bankRequisites"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-4">
                                            <div className="trades-dtls__input-wrapper input-group">
                                                <label className="trades-dtls__doc-label trades-dtls__doc-label_inline"
                                                    htmlFor="totalpay">
                                                    Total payable <span className="req-field">*</span>
                                                </label>
                                                <div className={`input-group`}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">{CURRENCYOPT[this.trade.currency]}</span>
                                                    </div>
                                                    <input
                                                        disabled
                                                        className="form-control"
                                                        type="text"
                                                        value={this.totalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div></div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="trades-dtls__invoice-required"><span className="req-field">*</span> Required fields</div>
                                        {!this.props.isPreview && (
                                            <Fragment>
                                                {this.state.required && Object.keys(this.state.required).length > 0 && (
                                                    <div className="trades-dtls__error-message text-center">
                                                        Please, complete all required fields before submitting
                                                    </div>
                                                )}
                                                <div className="trades-dtls__bottom-btn-section">
                                                    <Link to={`/trades/details/${this.props.id}/documents`}
                                                        className="btn btn-cancel">
                                                        <FontAwesomeIcon icon={faTimes} /> Cancel
                                                </Link>
                                                    <button type="submit" className="btn btn-save mr-0"
                                                        disabled={this.props.loading}>
                                                        <Preloader loadingStyle="dots" loading={this.props.loading}>
                                                            <FontAwesomeIcon icon={faCheck} /> <span>Save</span>
                                                        </Preloader>
                                                    </button>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </form>
                        ) : null}
                {this.state.showModal && this.renderSignatureModal()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        trade: state.trade,
        loading: state.loading.loadingDocuments[DOCUMENT_TYPES.INVOICE]
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadRequestDetails,
            GetTradeInvoice,
            GetTradeInvoiceByShipmentId,
            GetTradeBill,
            LoadRequestInfo,
            PostTradeDocumentInvoice,
            PostTradeMultisetInvoice,
            UpdateInvoice,
            UpdateMultisetInvoice,
            GetVesselNomination,
            navigateDocs: path => push(`/trades/details/${path}/documents`)
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
