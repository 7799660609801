import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FileUploadModal from './FileUploadModal';
import DocumentCard from './DocumentCard';
import Select from 'react-select';
import moment from 'moment';
import ReactGA from 'react-ga';
import {
    DOCUMENT_TYPES,
    FILTER_ALL, FILTER_COUNTERPARTY_ACTIONS, FILTER_INSPECTION_COMPANY_ACTIONS, FILTER_MY_ACTIONS,
    getDocInfo,
    getFilteredDocuments, getSellerDocuments, getInspectionDocuments, getProformaDocStatus
} from '../services/documents.service';
import VerifyDocumentsModal from '../modals/VerifyDocumentsModal';
import { downloadDocumentFile } from '../../../../services/service.api';
import domtoimage from 'dom-to-image';
import * as jsPDF from 'jspdf';
import BillOfLading from './BillOfLading';
import Invoice from './DocumentInvoice';
import { KycApi } from '../../../../services/service.api';
import { TradeApi } from '../../../../services/service.api';
import PreviewDocumentsModal from '../modals/PreviewDocumentsModal';
import RejectDocumentModal from '../modals/RejectDocumentModal';
// import AccessControl from '../../../components/AccessControl';
import 'formdata-polyfill';
import Preloader from '../../../components/preloader/Preloader';
import {
    issueDocumentPermission,
    uploadDocumentPermission,
    approveDocumentPermission,
    releaseDocumentPermission
} from '../../../../services/service.permission';
import { PROFORMA_INVOICE_OPERATIONS } from '../services/documents.service';
import ProformaInvoice from './DocumentPerformaInvoice';
import PaymentTermDocument from './ptDoc/PaymentTermDocument';
import { MEASUREMENTS } from '../../../../services/service.values';


class Documents extends Component {
    state = {
        modal: false, // SHOULD MODAL APPEROR NOT
        name: '', // name of doc to send to back
        docName: '', // doc name to show in modal
        document: {}, // container for document, that we gonna send to back end
        shareDocWithBuyer: false,
        status: '',
        selectedShipment: null,
        filter: null,
        verifyDocsModal: false,
        previewDocuments: null,
        rejectDocumentModal: {
            docName: '',
            show: false,
            documentId: null
        },
        documentToPrint: {
            component: null,
            shipmentId: null,
            id: null,
        },
        headerText: null,
        exportingData: false,
        basePdfCompanyLogo: ''
    };

    filterOptions = [
        {
            value: FILTER_ALL,
            label: 'Select',
            emptyText: 'There are no documents to upload yet. Documentary instructions have not been issued.'
        },
        {
            value: FILTER_MY_ACTIONS,
            label: 'My actions',
            emptyText: 'Currently you don\'t have any pending actions'
        },
        {
            value: FILTER_COUNTERPARTY_ACTIONS,
            label: 'Counterparty actions',
            emptyText: 'Currently there are no pending counterparty actions'
        },
        {
            value: FILTER_INSPECTION_COMPANY_ACTIONS,
            label: 'Inspection company actions',
            emptyText: 'Currently there are pending no inspection company actions'
        }
    ];

    get documents() {
        return this.state.selectedShipment
            ? this.props.documents[this.state.selectedShipment.value.id]
            : [];
    }

    get billsOfLadingFilled() {
        let filled = true;
        this.props.shipments.forEach(shipment => {
            if (filled) {
                filled = this.props.bills[shipment.id] && this.props.bills[shipment.id].BillNumber;
            }
        });
        return filled;
    }

    get possibleCommentReceivers() {
        return {
            seller: {
                value: this.props.trade.sellerId,
                label: this.props.trade.seller
            },
            buyer: {
                value: this.props.trade.buyerId,
                label: this.props.trade.buyer
            },
            inspection: {
                value: this.props.trade.inspection,
                label: this.props.trade.inspectionName
            }
        }
    }

    constructor(props) {
        super(props);
        if (props.shipments && props.shipments.length > 0) {
            this.selectShipment(this.getOptionFromShipment(this.props.shipments[0], 0));
        }
        if (props.whoItIs() === 'inspection') {
            this.filterOptions.filter(option => option.value !== FILTER_INSPECTION_COMPANY_ACTIONS);
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            filter: this.filterOptions[0]
        });
        this.selectInitialShipment();
        var base64data = '';
        //company logo for PDF in base64 format 
        KycApi.getById(this.props.trade.sellerId)
            .then(res => {
                this.setState({ companyDetails: res.data.company })
                TradeApi.getTradeDocument(res.data.company.companyLogo).then(resp => {
                    var reader = new FileReader();
                    reader.readAsDataURL(new Blob([resp.data]));
                    reader.onloadend = () => {
                        base64data = reader.result;
                        this.setState({ basePdfCompanyLogo: base64data });
                    }
                })
                    .catch(e => {
                        console.error(e);
                    })
            })


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.locationSearch !== prevProps.locationSearch && this.props.shipments && this.props.shipments.length > 0) {
            const params = new URLSearchParams(this.props.locationSearch);
            const shipmentId = params.get('shipmentId');
            if (shipmentId) {
                this.selectShipmentById(shipmentId);
            }
        }
        this.selectInitialShipment();
    }

    trackAction(action) {
        ReactGA.event({
            category: 'Trade',
            action: action + ' by ' + this.props.whoItIs()
        })
    }

    selectInitialShipment = () => {
        if (!this.state.selectedShipment && this.props.shipments && this.props.shipments.length > 0) {
            let shipmentId = new URLSearchParams(this.props.locationSearch).get('shipmentId');
            if (shipmentId) {
                this.selectShipmentById(shipmentId);
            } else {
                this.selectShipmentByIndex(0);
            }
        }
    };

    selectShipmentByIndex = index => {
        this.selectShipment(this.getOptionFromShipment(this.props.shipments[index], index));
    };

    selectShipmentById = id => {
        const shipments = this.props.shipments;
        const index = shipments.findIndex(shipment => shipment.id === id);
        if (index > -1) {
            this.selectShipment(this.getOptionFromShipment(shipments[index], index));
        }
    };

    selectShipment = shipment => {
        this.setState({
            selectedShipment: shipment
        });
        this.props.GetTradeDocuments(this.props.match.params.id, shipment.value.id);
        this.props.GetTradeBill(this.props.match.params.id, shipment.value.id);
        if (this.props.documentaryInstructions && this.props.documentaryInstructions.commercialInvInd) {
            if (this.props.documentaryInstructions.commercialInvInd === 'GLOBAL') {
                this.props.GetTradeInvoice(this.props.match.params.id);
            } else {
                this.props.GetTradeInvoiceByShipmentId(this.props.match.params.id, shipment.value.id);
            }
        }
    };

    getOptionFromShipment = (shipment, index) => {
        const measurement = MEASUREMENTS[this.props.trade.measurement];
        return { value: shipment, label: `Set ${index + 1} - ${shipment.amount} ${measurement}` };
    };

    handleFilterChange = (filter) => {
        this.setState({
            filter
        });
    };

    renderProformaInvoiceAction = ({ info }) => {
        const actions = [];
        if (this.props.account.token.companyId === this.props.trade.sellerId) {
            if (this.props.proformaInvoice) {
                switch (this.props.proformaInvoice.status) {
                    case 'NEW':
                    case 'EDIT':
                        actions.push((
                            <Link
                                key="editProformaInvoice"
                                to={`${this.props.match.url}/documents/${info.url}${this.props.proformaInvoice ? '/update' : ''}`}
                                className="dropdown-item">
                                Edit
                            </Link>
                        ));
                        actions.push((
                            <a href="#/" key="confirmPayment" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.CONFIRM_PAYMENT_RECIEPT)}>Confirm Payment</a>
                        ));
                        break;
                    case 'UPLOADED':
                        actions.push((
                            <a href="#/" key="confirmPayment" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.CONFIRM_PAYMENT_RECIEPT)}>Confirm Payment</a>
                        ));
                        actions.push((
                            <a href="#/" key="downloadPayment" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.PREVIEW_PAYMENT_RECIEPT)}>Preview Payment Receipt</a>
                        ));
                        break;
                    case 'EFFECTED':
                        actions.push((
                            <a href="#/" key="confirmPayment" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.CONFIRM_PAYMENT_RECIEPT)}>Confirm Payment</a>
                        ));
                        actions.push((
                            <a href="#/" key="viewDownpaymentEffected" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.VIEW_DOWNPAYMENT_EFFECTED)}>View Downpayment Effected</a>
                        ));
                        break;
                    case 'PAYMENT_NOT_CONFIRMED':
                        actions.push((
                            <a href="#/" key="confirmPayment" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.CONFIRM_PAYMENT_RECIEPT)}>Confirm Payment</a>
                        ));
                        if (this.props.proformaInvoice.files && this.props.proformaInvoice.files.length > 0) {
                            actions.push((
                                <a href="#/" key="downloadPayment" className="dropdown-item"
                                    onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.PREVIEW_PAYMENT_RECIEPT)}>Preview Payment Receipt</a>
                            ));
                        }
                        break;
                    case 'PAYMENT_CONFIRMED':
                        if (this.props.proformaInvoice.files && this.props.proformaInvoice.files.length > 0) {
                            actions.push((
                                <a href="#/" key="downloadPayment" className="dropdown-item"
                                    onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.PREVIEW_PAYMENT_RECIEPT)}>Preview Payment Receipt</a>
                            ));
                        }
                        break;
                    default:
                        break;
                }
                actions.push(<a href="#/" key="download" className="dropdown-item" onClick={this.handleProformaInvoiceExport}>Download</a>)
            }
        }
        if (this.props.account.token.companyId === this.props.trade.buyerId) {
            if (this.props.proformaInvoice) {
                switch (this.props.proformaInvoice.status) {
                    case 'UPLOADED':
                        actions.push((
                            <a href="#/" key="uploadPaymentReciept" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.UPLOAD_PAYMENT_RECIEPT)}>Upload Down Payment Proof</a>
                        ));
                        break;
                    case 'EFFECTED':
                        actions.push((
                            <a href="#/" key="downpaymentEffected" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.DOWNPAYMENT_EFFECTED)}>Confirm Down Payment Date</a>
                        ));
                        break;
                    case 'PAYMENT_NOT_CONFIRMED':
                    case 'EDIT':
                    case 'NEW':
                        actions.push((
                            <a href="#/" key="uploadPaymentReciept" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.UPLOAD_PAYMENT_RECIEPT)}>Upload Down Payment Proof</a>
                        ));
                        actions.push((
                            <a href="#/" key="downpaymentEffected" className="dropdown-item"
                                onClick={() => this.props.openProformaOperationModal(PROFORMA_INVOICE_OPERATIONS.DOWNPAYMENT_EFFECTED)}>Confirm Down Payment Date</a>
                        ));
                        break;
                    case 'PAYMENT_CONFIRMED':
                        break;
                    default:
                        break;
                }
                actions.push(<a href="#/" key="download" className="dropdown-item" onClick={this.handleProformaInvoiceExport}>Download</a>)
            }
        }
        if (actions.length === 0) {
            return null;
        }
        const isLoading = this.props.loadingProformaInvoice;
        const btnClass = isLoading ? 'trades-dtls__doc-button disabled' : 'trades-dtls__doc-button trades-dropdown-btn dropdown-toggle';
        return (
            <div className="dropdown">
                <button className={btnClass} type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <Preloader loading={isLoading} loadingStyle="dots">
                        <span>Actions</span>
                    </Preloader>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {actions}
                </div>
            </div>
        );
    }

    renderButton = ({ name, info, status, tradeId, shipmentId, documentId }, permissions = {}) => {
        const actions = [];
        if (permissions.canApprove) {
            if (approveDocumentPermission() === 1) {
                actions.push(
                    <a href="#/" key="approve" className="dropdown-item"
                        onClick={() => {
                            this.handleChangeDocStatus(name, this.props.ApproveDocument, { commercialInvInd: this.props.documentaryInstructions.commercialInvInd });
                            this.trackAction(`Approve Document ${name}`)
                        }}>Approve</a>
                )
            }
        }
        if (permissions.canReject) {
            actions.push(<a href="#/" key="reject" className="dropdown-item"
                onClick={() => this.setState({
                    rejectDocumentModal: {
                        show: true,
                        docName: name,
                        documentId
                    }
                })}>Reject</a>)
        }
        if (permissions.canRelease) {
            if (releaseDocumentPermission() === 1) {
                actions.push(
                    <a href="#/" key="release" className="dropdown-item"
                        onClick={() => this.handleChangeDocStatus(name, this.props.ReleaseDocument)}>Release for review to buyer</a>

                )
            }
        }
        if (permissions.canUpload) {
            if (uploadDocumentPermission() === 1) {
                actions.push((
                    <a href="#/" key="upload" className="dropdown-item"
                        onClick={() => this.setModal(info, 'upload')}>Upload</a>
                ))
            }
        }
        if (permissions.canFill && this.state.selectedShipment) {
            const bill = this.props.bills[this.state.selectedShipment.value.id];
            const isInvoice = info.url === 'invoice';
            const isFilled = isInvoice ? !!this.props.invoice : !!bill;
            const commercialInvInd = this.props.documentaryInstructions ? this.props.documentaryInstructions.commercialInvInd : 'GLOBAL';
            if (issueDocumentPermission() === 1) {
                actions.push((
                    <Link
                        key="fill"
                        to={
                            isInvoice
                                ? this.billsOfLadingFilled
                                    ? commercialInvInd === 'GLOBAL' ? `${this.props.match.url}/documents/${info.url}${this.props.invoice ? '/update' : ''}` : `${this.props.match.url}/shipment/${this.state.selectedShipment.value.id}/documents/${info.url}${isFilled ? '/update' : ''}`
                                    : `${this.props.match.url}/documents`
                                : `${this.props.match.url}/shipment/${this.state.selectedShipment.value.id}/documents/${info.url}${bill ? '/update' : ''}`
                        }
                        className="dropdown-item"
                    >
                        {isFilled ? 'Edit' : 'Fill in'}
                    </Link>
                ));
            }
        }
        if (permissions.canDownload) {

            let handler;
            if (name === DOCUMENT_TYPES.BILL) {
                handler = () => this.handleDocExport(BillOfLading, shipmentId, info.name);
            } else if (name === DOCUMENT_TYPES.INVOICE) {
                handler = () => this.handleDocExport(Invoice, shipmentId, info.name);
            } else {
                handler = () => this.handleDocumentDownload(shipmentId, name, info.name);
            }

            let handler1;
            if (name === DOCUMENT_TYPES.BILL) {
                handler1 = () => this.handleDocExport1(BillOfLading, shipmentId, info.name);
            } else if (name === DOCUMENT_TYPES.INVOICE) {
                handler1 = () => this.handleDocExport1(Invoice, shipmentId, info.name);
            }
            else {
                handler1 = () => this.handleDocumentDownload(shipmentId, name, info.name);
            }

            if (name === DOCUMENT_TYPES.BILL || name === DOCUMENT_TYPES.INVOICE) {
                if (this.props.account.token.companyId === this.props.trade.buyerId) {
                    actions.push(<a href="#/" key="downloadDuplicate" className="dropdown-item" onClick={handler1}>Download Duplicate</a>)
                }
                if (this.props.account.token.companyId === this.props.trade.sellerId) {
                    actions.push(<a href="#/" key="download" className="dropdown-item" onClick={handler}>Download</a>)
                    actions.push(<a href="#/" key="downloadDuplicate" className="dropdown-item" onClick={handler1}>Download Duplicate</a>)
                }
            }

        }
        if (actions.length === 0) {
            return null;
        }
        const isLoading = this.props.loadingDocuments[name];
        // Blockchain does not support multiple actions in parallel
        const otherLoading = Object.keys(this.props.loadingDocuments).findIndex(key => key !== name && this.props.loadingDocuments[key]) !== -1;
        const btnClass = isLoading ? 'trades-dtls__doc-button disabled' : 'trades-dtls__doc-button trades-dropdown-btn dropdown-toggle';
        return (
            <div className="dropdown">
                <button className={btnClass} type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={otherLoading}>
                    <Preloader loading={isLoading} loadingStyle="dots">
                        <span>Actions</span>
                    </Preloader>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {actions}
                </div>
            </div>
        );
    };

    closeRejectModal = () => {
        this.setState({
            rejectDocumentModal: {
                docName: '',
                show: false,
                documentId: null
            }
        });
    };

    handleDocExport = (component, shipmentId, fileName) => {
        const document1 = this.props.documents[shipmentId][DOCUMENT_TYPES.BILL];
        var text = "";
        if (document1.status === "APPROVED_BY_SELLER") {
            text = "Final Version"
        }
        else {
            text = "Draft Document for Discussion"
        }
        this.setState({
            documentToPrint: {
                id: this.props.trade.id,
                shipmentId,
                component,
            },
            headerText: text
        });
        var node = document.getElementById('download-as-pdf');
        var img;
        var newImage;
        var imgLogo;
        var newImageLogo;
        domtoimage.toPng(node, { bgcolor: '#fff' })
            .then(dataUrl => {
                this.setState({
                    documentToPrint: null,
                    headerText: ""
                });
                img = new Image();
                img.src = dataUrl;
                newImage = img.src;

                //convert into this format to visible in pdf 
                imgLogo = new Image();
                imgLogo.src = "data:image/jpeg;base64," + this.state.basePdfCompanyLogo.substring(37);
                newImageLogo = imgLogo.src;
                img.onload = function () {
                    var pdfWidth = img.width;
                    var pdfHeight = img.height;
                    var doc;
                    if (pdfWidth > pdfHeight) {
                        doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
                    }
                    else {
                        doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
                    }
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();
                    doc.addImage(newImage, 'PNG', 10, 10, width, height);
                    if (newImageLogo !== "data:image/jpeg;base64," && fileName === "Invoice") {
                        doc.addImage(newImageLogo, 'PNG', 40, 50, 80, 90);
                    }
                    doc.save(`${fileName}.pdf`);
                };
            })
    };

    handleDocExport1 = (component, shipmentId, fileName) => {
        var text = "Duplicate Version";
        this.setState({
            documentToPrint: {
                id: this.props.trade.id,
                shipmentId,
                component,
            },
            headerText: text,
        });
        var node = document.getElementById('download-as-pdf');

        var img;
        var newImage;
        var imgLogo;
        var newImageLogo;
        domtoimage.toPng(node, { bgcolor: '#fff' })
            .then(dataUrl => {
                this.setState({
                    documentToPrint: null,
                    headerText: ""
                });
                img = new Image();
                img.src = dataUrl;
                newImage = img.src;

                //convert into this format to visible in pdf 
                imgLogo = new Image();
                imgLogo.src = "data:image/jpeg;base64," + this.state.basePdfCompanyLogo.substring(37);
                newImageLogo = imgLogo.src;
                img.onload = function () {
                    var pdfWidth = img.width;
                    var pdfHeight = img.height;
                    var doc;
                    if (pdfWidth > pdfHeight) {
                        doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
                    }
                    else {
                        doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
                    }
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();
                    doc.addImage(newImage, 'PNG', 10, 10, width, height);
                    if (newImageLogo !== "data:image/jpeg;base64," && fileName === "Invoice") {
                        doc.addImage(newImageLogo, 'PNG', 40, 50, 80, 90);
                    }
                    doc.save(`${fileName}.pdf`);
                };

            })
    };

    handleProformaInvoiceExport = (event) => {
        event.preventDefault()
        this.setState({
            documentToPrint: {
                id: this.props.trade.id,
                shipmentId: null,
                component: ProformaInvoice,
            },
            headerText: "",
            exportingData: true
        });
        var node = document.getElementById('download-as-pdf');

        var img;
        var newImage;
        var imgLogo;
        var newImageLogo;
        domtoimage.toPng(node, { bgcolor: '#fff' })
            .then(dataUrl => {
                img = new Image();
                img.src = dataUrl;
                newImage = img.src;

                imgLogo = new Image();
                //convert into this format to visible in pdf 
                imgLogo.src = "data:image/jpeg;base64," + this.state.basePdfCompanyLogo.substring(37);
                newImageLogo = imgLogo.src;
                img.onload = () => {
                    var pdfWidth = img.width;
                    var pdfHeight = img.height;
                    var doc;
                    if (pdfWidth > pdfHeight) {
                        doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
                    }
                    else {
                        doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
                    }
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();
                    doc.addImage(newImage, 'PNG', 10, 10, width, height);
                    if (newImageLogo !== "data:image/jpeg;base64,") {
                        doc.addImage(newImageLogo, 'PNG', 40, 30, 80, 90);
                    }
                    doc.save(`Proforma Invoice.pdf`);
                    this.setState({
                        documentToPrint: null,
                        headerText: "",
                        exportingData: false
                    });
                };
            })
    };

    handleDocumentDownload = (shipmentId, docName, fileName) => {
        const document = this.props.documents[shipmentId][docName];
        if (!document.Files || document.Files.length === 0) {
            return;
        }
        const fileId = document.Files.slice().sort((a, b) => {
            return moment.utc(a.CreatedAt).diff(moment.utc(b.CreatedAt))
        })[document.Files.length - 1].ID;
        this.trackAction(`Download Document ${docName}`);
        downloadDocumentFile(this.props.trade.id, shipmentId, fileId, fileName);
    };

    handleChangeDocStatus = (docName, dispatcher, params) => {
        const docID = docName === DOCUMENT_TYPES.INVOICE ? this.props.invoice.document.ID : this.documents[docName].ID;
        if (params) {
            dispatcher(this.props.trade.id, this.state.selectedShipment.value.id, docID, docName, params);
        } else {
            dispatcher(this.props.trade.id, this.state.selectedShipment.value.id, docID, docName);
        }
    };

    onDrop = (filesAccept, filesNotAccept, docName) => {
        if (filesAccept.length !== 0) {
            this.setState(prevState => ({
                document: {
                    ...prevState.document,
                    [docName]: filesAccept
                }
            }));
        }
    };

    setModal = (documentInfo, status) => {
        this.setState({
            name: documentInfo.state, // THIS RECEIVED NAME PROP FROM BUTTON, THAT OPEN MODAL
            docName: documentInfo.name, // THIS RECEIVED DOC NAME PROP FROM BUTTON, THAT OPEN MODAL
            modal: !this.state.modal, // set modal OPEN/CLOSE (TRUE/FALSE)
            status: status,
            document: {}, // set all documents to empty
            shareDocWithBuyer: false
        });
    };

    handlePostDoc = e => {

        const docId = this.props.documents[this.state.selectedShipment.value.id][this.state.name] == null ? null :
            this.props.documents[this.state.selectedShipment.value.id][this.state.name].ID;
        docId !== null ? this.postDocUpdate(this.props.match.params.id, this.state.document[this.state.name][0], e, docId)
            : this.postDoc(this.props.match.params.id, this.state.document[this.state.name][0], e);
    };

    postDocUpdate = (id, doc, e, docId) => {
        e.preventDefault(); // PREVENT LINK DEFAULT BEHAVIOUR
        let newData = new FormData();
        newData.append('upload', doc); // FORMAT TO POST DOC TO BACK
        newData.append('docType', Object.keys(this.state.document)[0]); // Object keys mean, that we gonna get first key of this.state.document object
        const docName = this.state.name;
        const shareWithBuyer = this.state.shareDocWithBuyer;
        this.props.UpdateDocument(id, this.state.selectedShipment.value.id, newData, docId, (result) => {
            this.trackAction(`Update Upload Document ${doc}`);
            if (result && shareWithBuyer) {
                setTimeout(() => this.handleChangeDocStatus(docName, this.props.ReleaseDocument));
            }
        });

        this.setModal({});
    };


    postDoc = (id, doc, e) => {
        e.preventDefault(); // PREVENT LINK DEFAULT BEHAVIOUR
        let newData = new FormData();
        newData.append('upload', doc); // FORMAT TO POST DOC TO BACK
        newData.append('docType', Object.keys(this.state.document)[0]); // Object keys mean, that we gonna get first key of this.state.document object
        const docName = this.state.name;
        const shareWithBuyer = this.state.shareDocWithBuyer;
        this.props.PostDocumentFile(id, this.state.selectedShipment.value.id, newData, (result) => {
            this.trackAction(`Upload Document ${doc}`);
            if (result && shareWithBuyer) {
                setTimeout(() => this.handleChangeDocStatus(docName, this.props.ReleaseDocument));
            }
        });

        this.setModal({});
    };

    toggleVerifyDocsModal = () => {
        this.setState(prevState => ({
            verifyDocsModal: !prevState.verifyDocsModal
        }));

    };

    openPreviewDocument = (tradeId, shipmentId, files, name) => {
        const previewFiles = files.sort((a, b) => {
            return moment.utc(b.CreatedAt).diff(moment.utc(a.CreatedAt))
        }).slice(0, 2);
        this.trackAction(`Review Document ${name}`);
        this.setState({
            previewDocuments: {
                name,
                files: previewFiles.map(file => ({
                    id: file.ID,
                    url: file.docUrl
                    //httpHeaders: authHeader().headers
                }))
            }
        });
    };

    closePreviewModal = () => {
        this.setState({
            previewDocuments: null
        });
    };

    getCommentReceivers(docName) {
        const trader = this.props.whoItIs();
        const possibleCommentReceivers = this.possibleCommentReceivers;
        switch (trader) {
            case 'seller':
                if (getInspectionDocuments(this.props.trade).includes(docName)) {
                    return [possibleCommentReceivers.buyer, possibleCommentReceivers.inspection];
                } else {
                    return [possibleCommentReceivers.buyer];
                }
            case 'buyer':
                return [possibleCommentReceivers.seller];
            case 'inspection':
                if (getInspectionDocuments(this.props.trade).includes(docName)) {
                    return [possibleCommentReceivers.seller];
                } else {
                    return [];
                }
            default:
                break;
        }
    }

    getDocumentAuthor(docName, trade) {
        if (getInspectionDocuments(trade).includes(docName)) {
            return this.props.trade.inspectionName;
        }
        if (getSellerDocuments(trade).includes(docName)) {
            return this.props.trade.seller
        }
    }

    renderDocuments = () => {
        if (!this.documents || this.documents.length === 0) {
            return null;
        }
        const bill = this.state.selectedShipment && this.props.bills[this.state.selectedShipment.value.id];
        const documents = {
            ...this.documents,
            [DOCUMENT_TYPES.BILL]: bill ? bill.document : null,
            [DOCUMENT_TYPES.INVOICE]: this.props.invoice ? this.props.invoice.document : null,
        };
        const trader = this.props.whoItIs();
        return getFilteredDocuments(this.state.filter.value, trader, documents, this.props.trade).map(({ docName, permissions, status, required }) => {
            const documentInfo = getDocInfo(docName, this.props.trade);
            const showBillOfLadingRequired = docName === 'INVOICE' && trader === 'seller' && !this.billsOfLadingFilled;
            let document = documents[docName];
            if (document) {
                document.author = this.getDocumentAuthor(docName, this.props.trade);
            }
            let documentId = document ? document.ID : null;
            return (
                <DocumentCard
                    key={this.state.selectedShipment.value.id + docName}
                    documentParams={{
                        tradeId: this.props.trade.id,
                        shipmentId: this.state.selectedShipment.value.id,
                        documentId
                    }}
                    user={this.props.account.user}
                    required={required}
                    comments={this.props.comments[documentId] || { count: 0, data: [] }}
                    loadingDocumentComments={this.props.loadingDocumentComments[documentId]}
                    GetDocumentComments={this.props.GetDocumentComments}
                    PostDocumentComment={this.props.PostDocumentComment}
                    commentReceivers={this.getCommentReceivers(docName)}
                    openPreviewDocument={this.openPreviewDocument}
                    permissions={permissions}
                    renderButton={this.renderButton}
                    status={status}
                    document={document}
                    documentName={docName}
                    match={this.props.match}
                    documentInfo={documentInfo}
                    showBillOfLadingRequired={showBillOfLadingRequired}
                    userPermission={this.props.userPermission}
                    commercialInvInd={this.props.documentaryInstructions ? this.props.documentaryInstructions.commercialInvInd : null}
                />
            )
        })
    };

    /*this.props.userPermission[0].permissions*/

    render() {
        // documents var is info about documents from back
        const { shipments } = this.props;
        const shipmentOptions = shipments && shipments.length > 0
            ? shipments.map(this.getOptionFromShipment)
            : [];
        const documentsToRender = this.renderDocuments();
        const PrintComponentWrapper = this.state.documentToPrint ? this.state.documentToPrint.component : null;
        return (
            <div className="trades-dtls__doc">
                <Preloader loading={this.state.exportingData} loadingStyle="overflow-spinner" />
                {this.props.proformaInvoice &&
                    <React.Fragment>
                        <hr />
                        <div className="trades-dtls__doc-wrapper">
                            <DocumentCard
                                key={"proformaInvoice"}
                                documentParams={{
                                    tradeId: this.props.trade.id,
                                    documentId: this.props.proformaInvoice.documentID
                                }}
                                user={this.props.account.user}
                                required={true}
                                comments={{ count: 0, data: [] }}
                                loadingDocumentComments={false}
                                GetDocumentComments={this.props.GetDocumentComments}
                                PostDocumentComment={this.props.PostDocumentComment}
                                commentReceivers={this.getCommentReceivers()}
                                openPreviewDocument={this.openPreviewDocument}
                                permissions={{ canPreview: this.props.whoItIs() !== 'inspection' }}
                                renderButton={this.renderProformaInvoiceAction}
                                status={getProformaDocStatus(this.props.proformaInvoice)}
                                document={this.props.proformaInvoice.document}
                                documentName={DOCUMENT_TYPES.PROFORMA_INVOICE}
                                match={this.props.match}
                                documentInfo={getDocInfo(DOCUMENT_TYPES.PROFORMA_INVOICE, this.props.trade)}
                                showBillOfLadingRequired={false}
                                userPermission={this.props.userPermission}
                                previewLink={`/trades/details/${this.props.trade.id}/documents/proformaInvoice/preview`}
                            />
                        </div>
                        <hr />
                        {['Avalised Draft', 'Letter of credit', 'Confirmed letter of credit'].includes(this.props.trade.payment) &&
                            <PaymentTermDocument
                                commentReceivers={this.getCommentReceivers()}
                                match={this.props.match}
                                userPermission={this.props.userPermission}
                                userType={this.props.whoItIs()} />
                        }
                    </React.Fragment>}
                <div className="trades-dtls__doc-controls flex-wrap trades-controls">
                    <div className="trades-dtls__filter ">
                        <div className="trades-dtls__filter__label filter-label">
                            Select Set:
                        </div>
                        <div className="trades-dtls__filter__select trades-filter-select">
                            <Select
                                options={shipmentOptions}
                                value={this.state.selectedShipment}
                                onChange={this.selectShipment}
                            />
                        </div>
                    </div>
                    <div className="trades-dtls__filter">
                        <div className="trades-dtls__filter__label filter-label">
                            Pending:
                        </div>
                        <div className="trades-dtls__filter__select trades-filter-select">
                            <Select
                                options={this.filterOptions}
                                value={this.state.filter}
                                onChange={this.handleFilterChange}
                            />
                        </div>
                    </div>
                    {/* This will be redesigned with new documents screen
                        {Object.keys(this.props.documents).length > 0 && (
                            <button type="button" className="trades-dtls__verify-docs trades-btn-status"
                                onClick={this.toggleVerifyDocsModal}>Status</button>
                        )} */}
                </div>
                <div className="trades-dtls__doc-navigation mt-3">
                    <span>List of required documents</span>
                </div>
                <div className="trades-dtls__doc-wrapper">
                    <Preloader loadingStyle="swirl" loading={this.props.loadingDocuments.all}>
                        {documentsToRender && documentsToRender.length > 0 ? documentsToRender : (
                            <div>{this.state.filter.emptyText}</div>
                        )}
                    </Preloader>
                </div>
                <FileUploadModal
                    visibility={this.state.modal}
                    onDrop={(filesAccept, filesNotAccept, docName) => this.onDrop(filesAccept, filesNotAccept, docName)}
                    onShareChange={this.props.whoItIs() === 'seller' ? e => this.setState({ shareDocWithBuyer: e.target.checked }) : null}
                    shareWithBuyer={this.state.shareDocWithBuyer || false}
                    close={this.setModal}
                    name={this.state.name}
                    docName={this.state.docName}
                    file={this.state.document[this.state.name]}
                    postDoc={this.handlePostDoc}
                    accept={'application/pdf'}
                />
                {this.state.verifyDocsModal && (
                    <VerifyDocumentsModal
                        trade={this.props.trade}
                        documents={this.props.documents}
                        bills={this.props.bills}
                        invoice={this.props.invoice}
                        shipments={shipmentOptions}
                        onClose={this.toggleVerifyDocsModal}
                    />
                )}
                {this.state.previewDocuments && this.state.previewDocuments.files.length > 0 && (
                    <PreviewDocumentsModal
                        files={this.state.previewDocuments.files}
                        name={this.state.previewDocuments.name}
                        onClose={this.closePreviewModal}
                    />
                )}
                {this.state.rejectDocumentModal.show && (
                    <RejectDocumentModal
                        onClose={this.closeRejectModal}
                        onSubmit={text => {
                            const role = this.props.whoItIs();
                            const receiver = role === 'buyer'
                                ? this.possibleCommentReceivers.seller.value
                                : getInspectionDocuments(this.props.trade).includes(this.state.rejectDocumentModal.docName)
                                    ? this.possibleCommentReceivers.inspection.value
                                    : this.possibleCommentReceivers.buyer.value;
                            this.props.PostDocumentComment(
                                this.props.trade.id,
                                this.state.selectedShipment.value.id,
                                this.state.rejectDocumentModal.documentId,
                                { text, receiver }
                            );
                            this.trackAction(`Reject Document ${this.state.rejectDocumentModal.docName}`);
                            this.handleChangeDocStatus(this.state.rejectDocumentModal.docName, this.props.RejectDocument);
                            this.closeRejectModal();
                        }}
                    />
                )}
                <div id="download-as-pdf">
                    {this.state.headerText && <span>{this.state.headerText}</span>}
                    {PrintComponentWrapper && (
                        <PrintComponentWrapper
                            id={this.state.documentToPrint.id}
                            shipmentId={this.state.documentToPrint.shipmentId}
                            isPrint={true}
                            isPreview={true}
                            isComponentPrinting={true}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default Documents;