import React from 'react';
import { TradeApi } from '../../../../services/service.api';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../components/TradeImagePreview.scss';

class ReviewTradeImage extends React.Component {

    constructor(props) {
        super(props);
        this.downLoadPreview(this.props.image);
        this.state = {
        }
    }

    downLoadPreview = (image) => {
        TradeApi.getTradeDocument(image).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            this.setState({
                url: url
            });
        })
        .catch(e => {
            console.error(e)
        })
    }



    render() {
        return <React.Fragment>
            <div className="card preview-card" style={{ minHeight: '60px'}}>
                {this.state.url?
                    <React.Fragment>
                        <img className="rounded mx-auto d-block" width="100%" src={this.state.url} alt="Fertilizer example" />
                    </React.Fragment> :
                    <div className="rounded m-auto d-block w-100 h-100 text-center align-middle">
                        <div className="m-auto align-middle"><FontAwesomeIcon size="3x" icon={faSpinner} spin={true} /></div>
                    </div>}
            </div>
        </React.Fragment>
    }
}
export default ReviewTradeImage;